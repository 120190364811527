import React, { useState } from "react";
import {
  Box,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Typography,
  styled,
  Divider,
} from "@mui/material";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import FilterLable from "../../../../Components/Utils/FilterLable";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import moment from "moment";
import VBSelect from "../../../../Components/Utils/VBSelect";
import ViknRadioGroup from "../../../../Components/Utils/ViknRadioGroup";
import AssistModal from "./Components/AssistModal";
import { useDispatch, useSelector } from "react-redux";
import { CallVbListItem, CallVbReportList } from "../../../../Api/Reports/AccountApi";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { UserRolePermission } from "../../../../Function/Editions";

const VBAssist = () => {
  let Export_view = UserRolePermission("VB Assist Report", "print_permission");
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const appSetting = useSelector((state) => state.appSettingsSclice);
  // const { user_id, } = useSelector((state) => state.user);
  const appSettings = useSelector((state) => state.appSettingsSclice);
  const dispatch = useDispatch()
  const [openFilter, setOpenFilter] = useState(false);;
  const [t, i18n] = useTranslation("common");
  const [openModal, setOpenModal] = useState(false)
  const [reportData, setReportData] = useState([]);
  const [ModalData, setModalData] = useState([])
  const [page_no, setPage_no] = useState(1);
  const [isLoading, setIsLoading] = useState({
    report: false,
  });
  const [filterOptions, setFilterOptions] = useState({
    type: "All",
    status: "All",
    access: "Allowed",
  });
  const [filterData, setFilterData] = useState({
    typeList: ["All","Supplier", "Customer"],
    statusList: ["All","Pending", "Awaiting", "Connected"],
  });

  //Handle functions
  const closeFilter = () => setOpenFilter(false);
  const closeAssistModal = () => setOpenModal(false)

  const handlefilterList = () => {
    let list = [];
    let filterData = filterOptions
    if (filterData?.type !== undefined && filterData?.type !== ''){
      let type = filterOptions?.type
      list.push({ name : t('PartyType'), value:type })
    }
    if (filterData?.access) {
      let access = filterOptions?.access
      list.push({ name : t('Access'), value:access })
    }
    if (filterData?.status) {
      let status = filterOptions?.status
      list.push({ name : t('Status'), value:status })
    }
    return list
  }

  const openAssistModal =  function (e) {
    setOpenModal(true)
    setModalData(e)
    // fetchVBListItem()
  };


  const searchReport = function () {
    fetchReport();
    setPage_no(1);
  };

  const fetchReport = async function () {
    setIsLoading(true);
    const data = await CallVbReportList({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      page_no : page_no,
      items_per_page: appSettings.itemPerPage,
      PartyType : filterOptions.type,
      access : filterOptions.access === 'Allowed' ? true : false,
      status : filterOptions.status === 'Pending' ? 0 : filterOptions.status ===  'Awaiting' ? 2 : filterOptions.status ===  'Connected' ? 1 : 'All'
    })
    console.log(data,'datatatatattatattatatat')
    
    if (data?.StatusCode === 6000) {
      if (filterOptions.status === 'Pending') {
        const pending = data.data.filter(item => item.status.pending > 0);
        setReportData({...reportData, data: pending});
        console.log(pending,'pendingggg');
        if (pending.length === 0) {
          dispatch(
            openSnackbar({
              open : true,
              message : 'No Data Available',
              severity : 'warning',
            })
          )
        }
      } else if (filterOptions.status === 'Awaiting') {
        const awaiting = data.data.filter(item => item.status.awaiting > 0);
        setReportData({...reportData, data: awaiting});
        if (awaiting.length === 0) {
          dispatch(
            openSnackbar({
              open : true,
              message : 'No Data Available',
              severity : 'warning',
            })
          )
        }
      } else if (filterOptions.status === 'Connected') {
        const connected = data.data.filter(item => item.status.connected > 0);
        setReportData({...reportData, data: connected});
        if (connected.length === 0) {
          dispatch(
            openSnackbar({
              open : true,
              message : 'No Data Available',
              severity : 'warning',
            })
          )
        }
      } else {
        setReportData(data);
      }
    } else if (data?.StatusCode === 6001) {
      setReportData();
      dispatch(
        openSnackbar({
          open : true,
          message : data.message,
          severity : 'warning',
        })
      )
    }
    setIsLoading(false);
  };

  const handleDefaultFilter = function () {
    setFilterOptions({
      type: "All",
      status: "All",
      access: "Allowed",
    });
  };
  const getDataForPage = () => {
    const startIndex = (page_no -1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData?.data?.slice(0,reportData?.data?.length)
    return alldata?.slice(startIndex, endIndex) || [];
  }

  useEffect(() => {
    fetchReport();
  }, [page_no]);

  useEffect(() => {
    fetchReport();
  }, [openModal]);

  console.log(reportData,'reportdataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
  console.log(filterOptions.type,'TTTYpppeeeaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500", mr: 2 }}
              variant="h2"
            >
              {t('VBassist Access Status')}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </Box>

          <FlexBox>
            {Export_view ? 
            <ExportBtn />
            : null}
            {/* <PrintBtn 
              
            /> */}
            <Pagenation
              totalItem={reportData?.data?.length}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          // This also change into custom component
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // height: "54px",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
        <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
          {t("Applied Filters: ")}
        </div>
        {handlefilterList(filterOptions).map((value, i) => (
          <FilterLable
            name={value.name}
            value={value.value}
          />
        ))}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t('SI NO')} </VBTableCellHeader>
            <VBTableCellHeader>{t('CUSTOMER/SUPPLIER')} </VBTableCellHeader>
            <VBTableCellHeader>{t('UPDATE DELAY')} </VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign: "center"}}>{t('PENDING')} </VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign: "center"}}>{t('AWAITING')} </VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign: "center"}}>{t('CONNECTED')} </VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign: "center"}}>{t('ACCESS')} </VBTableCellHeader>
          </VBTableHeader>
          {isLoading.report ? (
            <VBSkeletonLoader />
          ) : (
            <TableBody>
              {getDataForPage().map((data, i) => (
                <TableRow
                  key={data.id}
                  sx={{ cursor: "pointer" }}
                  onClick={() => openAssistModal(data)}
                >
                  <VBTableCellBody>{(page_no - 1) * appSettings.itemPerPage + i + 1}</VBTableCellBody>
                  <VBTableCellBody>{data.customer}</VBTableCellBody>
                  <VBTableCellBody>{data.updation_delay} {"Days"}</VBTableCellBody>
                  <VBTableCellBody sx={{color:'red',textAlign: "center",fontWeight: '500'}}>{data.status.pending}</VBTableCellBody>
                  <VBTableCellBody sx={{color:'#FF8C00',textAlign: "center" ,fontWeight: '600'}}>{data.status.awaiting}</VBTableCellBody>
                  <VBTableCellBody sx={{color:'green',textAlign: "center",fontWeight: '500'}}>{data.status.connected}</VBTableCellBody>
                  <VBTableCellBody sx={{textAlign: "center"}}>{data.access ? <CheckCircleIcon sx={{color:'#0A9EF3'}}/>:null}</VBTableCellBody>
                </TableRow>
              ))}
              <TableRow></TableRow>
            </TableBody>  
          )}
        </VBTableContainer>
      </Paper>

      <ViknDrawer
        open={openFilter}
        onFilter={searchReport}
        handleClose={closeFilter}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBSelect
                label={t("Type")}
                value={filterOptions.type}
                options={filterData.typeList}
                handleChange={(e) =>
                  setFilterOptions({ ...filterOptions, type: e.target.value })
                }
              />
            </ListItem>

            <Divider />

            <ListItem>
              <VBSelect
                label={t("Status")}
                placeholder={t("Status")}
                value={filterOptions.status}
                options={filterData.statusList}
                handleChange={(e) =>
                  setFilterOptions({ ...filterOptions, status: e.target.value })
                }
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ViknRadioGroup
                value={filterOptions.access}
                radios={[
                  { label: t("Allowed"), value: "Allowed" },
                  { label: t("No Access"), value: "No Access" },
                ]}
                handleChange={(e) =>
                  setFilterOptions({ ...filterOptions, access: e.target.value })
                }
              />
            </ListItem>
          </List>
        }
      />


      <AssistModal open={openModal} handleClose={closeAssistModal} data={ModalData} />
    </>
  );
};

export default VBAssist;

const FlexBox = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  // width: "25%",
}));

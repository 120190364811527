import React from "react";
import { LinearProgress } from "@mui/material";
import { styled } from "@mui/system";
import Lottie from "react-lottie";
import loader from "../lotties/loader.json";

const LoaderLite = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const Container = styled("div")(({ theme }) => ({
    color: theme.palette.text.primary,
    position: "absolute",
    height: "100vh",
    margin: "0 auto",
    top: 0,
    width: props.ContainerWidth ? props.ContainerWidth : "93%",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  }));

  const BlurComponent = styled("div")(({ theme }) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    background: `radial-gradient(
      circle at center,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.2) 100%
    )`,
    zIndex: 101,
  }));

  const LottieImage = styled("div")(({ theme }) => ({
    width: "100%",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  }));

  const getLottieStyle = () => ({
    width: "100px",
    maxHeight: "100px",
    margin: "0px",
    height: "100px",
    cursor: "unset",
    margin: "auto",
  });

  return (
    <Container>
      <BlurComponent />
      <div
        style={{
          position: "absolute",
          top: props?.fullscreen ? "350px" : "50%",
          left: "50%",
          transform: props.transform ? props.transform : "translate(-70%,-50%)",
          zIndex: "108",
        }}
      >
        {props.lite ? null : (
          <LottieImage>
            <Lottie
              options={defaultOptions}
              isClickToPauseDisabled={true}
              style={getLottieStyle()}
            />
          </LottieImage>
        )}
        <p style={{ fontSize: "15px", fontWeight: "600" }}>
          {props.message ? props.message : "Fetching data, Please wait..."}
        </p>
        {props.progress ? <LinearProgress variant="determinate" value={props.progress} /> : null}
      </div>
    </Container>
  );
};

export default LoaderLite;

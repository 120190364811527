import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  Paper,
  Typography,
  styled
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
  VBTableRowNull,
} from "../../../../Components/ReportTable/ReportTable";
import FilterLable from "../../../../Components/Utils/FilterLable";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import moment from "moment";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { date_range } from "../../../../Function/comonFunction";
import {
  CallLedgerListByGroupLimit,
  CallUsers,
} from "../../../../Api/Reports/CommonReportApi";
import {handleCashLedgers} from "../../../../Function/comonFunction";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import { useDispatch, useSelector } from "react-redux";
import { CallExpenseReport,CallExpenseReportDetails } from "../../../../Api/Reports/AccountApi";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { useTranslation } from "react-i18next";
import { VIKNBOOKS_FRONT_URL } from "../../../../generalVeriable";
import { formatNumber } from "../../../../generalFunction";
import ViknRadioGroup from "../../../../Components/Utils/ViknRadioGroup";
import ExportToExcelButton from "../../../../Components/Utils/ExportToExcelButton";
import { callSearchAccountLedgers } from "../../../../Api/Reports/PurchaseApi";
import { UserRolePermission } from "../../../../Function/Editions";

const ExpensesReport = () => {
  let Export_view = UserRolePermission("Expense Report", "print_permission");
  const dispatch = useDispatch()
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [t] = useTranslation("common");
  const [reportData, setReportData] = useState({
    StatusCode: 0,
    total_rows: 0,
    data: [],
    new_data:[],
    total: {
    tot_sales: 0,
    tot_returns: 0,
    Amount: 0,
    CGSTAmount: 0,
    Discount: 0,
    GrandTotal: 0,
    IGSTAmount: 0,
    SGSTAmount: 0,
    VATAmount: 0,
    VoucherNo: "Total",
    },
  });
  const [loading, setLoading] = useState(false);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,GST,VAT} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const PriceRounding = Number(PriceDecimalPoint);
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoadingLedger, setIsLoadingLedger] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [page_no, setPage_no] = useState(1);
  const [filterOptions, setFilterOptions] = useState({

    userList: [],
    userObj: { customer_name: "" },

    ledgerList: [],
    ledgerValue:[],

    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment(),
    },
    reportType: "summary",
    reportList: [
      { name: "summary", label: t("Summary") },
      { name: "detailed", label: t("Detailed") },
    ],
  });
console.log(filterOptions);
  //Handle functions

  const closeFilter = () => setOpenFilter(false);

  const searchReport = function () {
    fetchReport();
  };
  const handleChange = (e, name) => {
    let { value } = e.target;
    if(name === "reportType"){
      setFilterOptions({ ...filterOptions, [name]: value,ledgerList:[],ledgerValue:[] });
    }else{

      setFilterOptions({ ...filterOptions, [name]: value });
    }
  };
  const handleDeletefilter = (name, index) => {
    console.log(name, index);
    let ledgerValue = [...filterOptions.ledgerValue];
    if (name === "ledgerValue") {
      ledgerValue = ledgerValue.filter((item, idx) => idx !== index);
      setFilterOptions((prevState) => ({
        ...prevState,
        ledgerValue: ledgerValue,
      }));
    } else {
      setFilterOptions((prevState) => ({ ...prevState, [name]: {} }));
    }
    fetchReport(name,ledgerValue)
  };
  const handlefilterList = () => {
    let list = [];
    let filterdata = filterOptions
    if (filterdata.date.FromDate &&filterdata.date.ToDate ) {
      let date = String(filterOptions.date.FromDate.format("DD-MM-YYYY") + "  to " + filterOptions.date.ToDate.format("DD-MM-YYYY"))
      list.push({ name: t("Date"), value: date });
    }
    if (filterdata.reportType !== "") {
      list.push({ name: t("Report Type"), value:filterdata.reportType});
    }
    if (filterdata.ledgerValue && filterdata.ledgerValue.length) {
      filterdata.ledgerValue.forEach((ledger, index) => {
          if (ledger.LedgerName !== "") {
              list.push({
                  name: t("Ledger Name"),
                  value: ledger.LedgerName,
                  handleDelete: () => handleDeletefilter("ledgerValue", index)
              });
          }
      });
    }

    if (filterdata.userObj.customer_name !== undefined && filterdata.userObj.customer_name !== "") {
        list.push({
            name: t("User"),
            value: filterdata.userObj.customer_name,
            handleDelete: () => handleDeletefilter("userObj")
        });
    }
    return list;
  };
  const handleDefaultFilter = async () => {
    setFilterOptions({
      ...filterOptions,
    userObj: { customer_name: "" },

    ledgerValue:[],

    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Custom" },
      FromDate: moment(),
      ToDate: moment(),
    },
    reportType: "summary",
    })
  }
  // search Party or customer
 


  const handleAutoComplete = function (e, value, name) {
    if (value) {
      if (name === "date") {
        console.log(e, value);
        if (value.name === "Custom") {
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: true,
              value: value,
            },
          });
        } else {
          let date = date_range(value.name);
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: false,
              value: value,
              ToDate: date.todate,
              FromDate: date.fromdate,
            },
          });
        }
      } else {
        setFilterOptions({ ...filterOptions, [name]: value });
      }
    }
  };
  // API Fetch data
  const fetchReport = async function (name,ledgerValue) {
    setLoading(true)
    let filterOptionsObj = filterOptions
    let UserID = filterOptions.userObj?.UserID ? filterOptions.userObj.UserID : 0
    if(name === "ledgerValue"){
      filterOptionsObj ={
        ledgerValue:ledgerValue
      }
    }else if(name === "userObj"){
      UserID=0
    }
    let CashLedgers = await handleCashLedgers(filterOptionsObj,true)

    if(filterOptions.reportType==="summary"){
      const data = await CallExpenseReport({
        CompanyID:CompanyID,
        BranchID:BranchID,
        PriceRounding:Number(PriceDecimalPoint),
        page_no: page_no,
        page_size: appSetting.itemPerPage,
        UserID: UserID,
        FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
        ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
        CashLedgers,
        is_ExcludeTotal:true,

      },dispatch);
      console.log(data);
      if (data?.StatusCode === 6000) {
        setReportData(data);
      }else if (data?.StatusCode === 6001){
        dispatch(
          openSnackbar({
            open : true,
            message : data.message,
            severity : 'warning',
          })
        )
        setReportData({
            StatusCode: 0,
            total_rows: 0,
            data: [],
            new_data:[],
            total: {
            tot_sales: 0,
            tot_returns: 0,
            Amount: 0,
            CGSTAmount: 0,
            Discount: 0,
            GrandTotal: 0,
            IGSTAmount: 0,
            SGSTAmount: 0,
            VATAmount: 0,
            VoucherNo: "Total",
            },
        })
      }
    }else{

      const data = await CallExpenseReportDetails({
        CompanyID:CompanyID,
        BranchID:BranchID,
        PriceRounding:Number(PriceDecimalPoint),
        page_no: page_no,
        page_size: appSetting.itemPerPage,
        UserID: filterOptions.userObj?.UserID ? filterOptions.userObj.UserID : 0,
        FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
        ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
        CashLedgers,
        is_ExcludeTotal:true,
      });
      console.log(data);
      if (data?.StatusCode === 6000) {
        setReportData(data);
      }else{
        dispatch(
          openSnackbar({
            open : true,
            message : data.message,
            severity : 'warning',
          })
        )
        setReportData({
          StatusCode: 0,
          total_rows: 0,
          data: [],
          new_data:[],
          total: {
          tot_sales: 0,
          tot_returns: 0,
          Amount: 0,
          CGSTAmount: 0,
          Discount: 0,
          GrandTotal: 0,
          IGSTAmount: 0,
          SGSTAmount: 0,
          VATAmount: 0,
          VoucherNo: "Total",
          },
      })
      }
    }
    closeFilter()
    setLoading(false)

  };
  const handleSearchLedger = async (e, value, name) => {
    if (value) {
      setIsLoadingLedger(true);
      if(filterOptions.reportType === "summary"){
        let ledgerList = await CallLedgerListByGroupLimit({
          items_per_page: 10,
          CompanyID:CompanyID,
          BranchID:BranchID,
          PriceRounding:Number(PriceDecimalPoint),
          CreatedUserID:user_id,
          page_no: 1,
          search: value,
          GroupUnder:29,
        });

        let ledger_data = [];
        if (ledgerList.data) {
          ledger_data = ledgerList.data;
        }
        setFilterOptions({ ...filterOptions, ledgerList: ledger_data });
      }else{
        let length = value.length;
        let LedgersList = await callSearchAccountLedgers({
          CompanyID:CompanyID,
          BranchID:BranchID,
          PriceRounding:Number(PriceDecimalPoint),CreatedUserID:user_id,
          length : length,
          product_name : value,
          param : 'name',
        })
        let ledgerList = [];
        if (LedgersList.data) {
          ledgerList = LedgersList.data
        }
        setFilterOptions({
          ...filterOptions,
          ledgerList,
        })
      }
      setIsLoadingLedger(false);
    } else {
      setFilterOptions({ ...filterOptions, ledgerValue: [] });
    }
    
  };
    //handle Ledgers 
    const handleSearchLedgers = async (e,value) => {
      if (value) {
        
      }
    }

  const filterApis = async function () {
    setIsLoadingUsers(true);
    const userResponse = await CallUsers({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      items_per_page: 10,
      page_no: 1,
      search: "",
      PartyType: "1",
    });
    let userList = [];
    if (userResponse && userResponse.StatusCode === 6000) {
      userList = userResponse.data;
    }
    setIsLoadingLedger(true);
    const LedgerResponse = await CallLedgerListByGroupLimit({
      items_per_page: 10,CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,
      page_no: 1,
      search: "",
      GroupUnder:29,
    });
    let ledgerList = [];
    if (LedgerResponse?.StatusCode === 6000) {
      ledgerList = LedgerResponse.data;
    }

    setFilterOptions({
      ...filterOptions,
      ledgerList,
      userList,
    });
    setIsLoadingLedger(false);
    setIsLoadingUsers(false);
  };

  useEffect(() => {
    filterApis();
  }, []);

  useEffect(() => {
    fetchReport()
  },[page_no])
  const handleTableRowClick = (data) => {
    const destinationURL = `${VIKNBOOKS_FRONT_URL}dashboard/expense-single`;
    const finalURL = `${destinationURL}?id=${data.id}`;
    window.open(finalURL, '_blank');
    // window.location.href = finalURL;

  };
  const getDataForPage = () => {
    const startIndex = (page_no -1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData?.new_data
    return alldata.slice(startIndex, endIndex) || [];
  }
  
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t("Expense Report")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className="right">
            {/* <ExportBtn /> */}
          {Export_view ? 
          <ExportToExcelButton
            ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
            FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
            UserID={filterOptions.userObj?.UserID ? filterOptions.userObj.UserID : 0}
            report_type={"expence"}
            download_type={"PDF"}
            CashLedgers={handleCashLedgers(filterOptions,true)}
            PageType={filterOptions.reportType}
            handleCashLedgers={true}
            filterOptions={filterOptions}
          />
          : null}
            {/* <PrintBtn /> */}
            <Pagenation
              totalItem={reportData?.new_data?.length}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
            alignItems:"center"
          }}
        >
            <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>

            {t("Applied Filters: ")}
            </div>
        {handlefilterList(filterOptions).map((val, i) => (
        <FilterLable
          name={val.name}
          value={val.value}
          handleDelete={val.handleDelete?val.handleDelete:null}
        />
      ))}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("Serial No")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher No")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Date")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Ledger Name")}</VBTableCellHeader>
            {filterOptions.reportType === "detailed"&&
            <VBTableCellHeader>{t("Particulars")} </VBTableCellHeader>}
            {VAT ? <VBTableCellHeader sx={{textAlign:"right"}}>{t("VAT Amount")}</VBTableCellHeader> : null}
              {GST
                ? [
                    <VBTableCellHeader sx={{textAlign:"right"}}>{t("IGST Amount")}</VBTableCellHeader>,
                    <VBTableCellHeader sx={{textAlign:"right"}}>{t("SGST Amount")}</VBTableCellHeader>,
                    <VBTableCellHeader sx={{textAlign:"right"}}>{t("CGST Amount")}</VBTableCellHeader>,
                  ]
                : null}

            <VBTableCellHeader sx={{textAlign:"right"}}>{t("Amount")} </VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t("Discount")} </VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t("Grand Total")} </VBTableCellHeader>
          </VBTableHeader>
          {loading?
          <VBSkeletonLoader/>
          :
          <TableBody>
            {getDataForPage().map((data, i) => (
              <TableRow 
              onClick={()=>{handleTableRowClick(data)}}
              >
                <VBTableCellBody sx={{cursor:"pointer"}}>{(page_no - 1) * appSetting.itemPerPage + i + 1 }</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.VoucherNo}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.Date}</VBTableCellBody>

                <VBTableCellBody sx={{cursor:"pointer"}}>{data.LedgerName}</VBTableCellBody>
                {filterOptions.reportType === "detailed"&&
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.Particulars}</VBTableCellBody>}
                {VAT &&
                  <VBTableCellBody sx={{textAlign:"right",cursor:"pointer"}}>{formatNumber(Number(data.VATAmount))}</VBTableCellBody>
                } 
                {GST
                  ? [
                  <VBTableCellBody sx={{textAlign:"right",cursor:"pointer"}}>{formatNumber(Number(data.IGSTAmount))}</VBTableCellBody>,
                  <VBTableCellBody sx={{textAlign:"right",cursor:"pointer"}}>{formatNumber(Number(data.SGSTAmount))}</VBTableCellBody>,
                  <VBTableCellBody sx={{textAlign:"right",cursor:"pointer"}}>{formatNumber(Number(data.CGSTAmount))}</VBTableCellBody>
                ]
                : null}
                <VBTableCellBody sx={{textAlign:"right",cursor:"pointer"}}> {formatNumber(Number(data.Amount))}</VBTableCellBody>
                <VBTableCellBody sx={{textAlign:"right",cursor:"pointer"}}>{formatNumber(Number(data.Discount))}</VBTableCellBody>
                {filterOptions.reportType === "summary"?     
                <VBTableCellBody sx={{textAlign:"right",cursor:"pointer"}}>{formatNumber(Number(data.GrandTotal))}</VBTableCellBody>
                :
                <VBTableCellBody sx={{textAlign:"right",cursor:"pointer"}}>{formatNumber(Number(data.NetAmount))}</VBTableCellBody>
                }
              </TableRow>
            ))}
            <VBTableRowNull length = { getDataForPage()?.length}/>
          </TableBody>
          }
          <VBTableFooter>
            <TableRow>
              <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              {filterOptions.reportType === "detailed"&&
              <VBTableCellFooter></VBTableCellFooter>}
              <VBTableCellFooter></VBTableCellFooter>

              {GST && <VBTableCellFooter sx={{textAlign:"right"}}>
                {formatNumber(Number(reportData?.total?.IGSTAmount))}
              </VBTableCellFooter>}
              {GST && <VBTableCellFooter sx={{textAlign:"right"}}>
                {formatNumber(Number(reportData?.total?.SGSTAmount))}
              </VBTableCellFooter>}
              {GST && <VBTableCellFooter sx={{textAlign:"right"}}>
                {formatNumber(Number(reportData?.total?.CGSTAmount))}
              </VBTableCellFooter>}

              {VAT && <VBTableCellFooter sx={{textAlign:"right"}}>
                {formatNumber(Number(reportData?.total?.VATAmount))}
              </VBTableCellFooter>}

              <VBTableCellFooter sx={{textAlign:"right"}}>
                {formatNumber(Number(reportData?.total?.Amount))}
              </VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>
                {formatNumber(Number(reportData?.total?.Discount))}
              </VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}} align="right">
                {formatNumber(Number(reportData?.total?.NetAmount?reportData?.total?.NetAmount:reportData?.total?.GrandTotal))}
              </VBTableCellFooter>
            </TableRow>
          </VBTableFooter>
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={searchReport}
        handleDefaultFilter={()=>handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            {/* <ListItem>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Filter Name
                </InputLabel>
                <VBInputField />
              </FormControl>
            </ListItem> */}
            {/* <ListItem>
              <ViknAutoComplete
                value={filterOptions.date.value}
                disableClearable
                options={[
                  { name: "Custom" },
                  { name: "Today" },
                  { name: "This Week" },
                  { name: "This Month" },
                  { name: "Last 90 Days" },
                  { name: "This Year" },
                  { name: "Last Year" },
                ]}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) =>
                  handleAutoComplete(e, newValue, "date")
                }
              />
            </ListItem> */}

            <ListItem>
              <VBDatePiker
              handleAutoComplete={handleAutoComplete}
              dateValue={filterOptions.date.value}
              isDateRange={true}
                fromDate={filterOptions.date.FromDate}
                toDate={filterOptions.date.ToDate}
                // fromReadOnly={filterOptions.date.value.name !== "Custom"}
                // toReadOnly={filterOptions.date.value.name !== "Custom"}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      FromDate: value,
                      value:{ name: "Custom" }
                    },
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      ToDate: value,
                      value:{ name: "Custom" }
                    },
                  })
                }
              />
            </ListItem>
            <ListItem>
              <ViknRadioGroup
                handleChange={handleChange}
                radios={filterOptions.reportList.map((i) => ({
                  label: i.label,
                  value: i.name,
                }))}
                value={filterOptions.reportType}
                valueName={"reportType"}
              />
            </ListItem>
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                placeholder="User"
                filterName={t("User")}
                loading={isLoadingUsers}
                options={filterOptions.userList ? filterOptions.userList : []}
                getOptionLabel={(option) => option.customer_name || ""}
                name="userObj"
                onInputChange={(event, value, reason) => {
                  //   if (reason === "input") {
                  //     handleSearchEmployee(event,value);
                  // }
                  if (reason === "clear") {
                    setFilterOptions({ ...filterOptions, userObj: {} });
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "userObj", -1)}
                value={filterOptions.userObj}
              />
            </ListItem>
            <ListItem>
               <ViknSearchAutoComplete
                freeSolo
                multiple
                placeholder={t("Ledger Name")}
                filterName={t("Ledger Name")}
                loading={isLoadingLedger}
                options={filterOptions.ledgerList ? filterOptions.ledgerList : []}
                getOptionLabel={(option) => option.LedgerName || ""}
                name="ledgerValue"
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    (option.LedgerName && option.LedgerName.toLowerCase().includes(inputValue.toLowerCase())) ||
                    (option.LedgerCode && option.LedgerCode.toLowerCase().includes(inputValue.toLowerCase()))
                  )
                }
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.LedgerCode}</Box>
                    <Box sx={{fontSize: "14px",}}>{option.LedgerName}</Box>
                  </Box>
                )}
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    handleSearchLedger(event,value);
                  }if(reason === "clear"){
                    handleSearchLedger()
                  }
                }}
                onChange={(e, v) =>
                  handleAutoComplete(e, v, "ledgerValue", -1)
                }
                value={filterOptions.ledgerValue}
              />
            </ListItem>


          </List>
        }
      />
    </>
  );
};

export default ExpensesReport;


const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap:"22px",
  },
}));
import { Box, Divider, List, ListItem, Paper, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import FilterLable from "../../../../Components/Utils/FilterLable";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AccordianTable from "./Components/AccordianTable";
import { CallQuickReport } from "../../../../Api/Reports/AccountApi";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import VBSwitch from "../../../../Components/Utils/VBSwitch";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { useTranslation } from "react-i18next";
import { UserRolePermission } from "../../../../Function/Editions";

const QuickReport = () => {
  let Export_view = UserRolePermission("Quick Report", "print_permission");
  const [t] = useTranslation("common");
  const dispatch = useDispatch()
  // Declear state
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [page_no, setPage_no] = useState(1);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint, EnableRoute} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);

  const [filterOptions, setFilterOptions] = useState({
    FromDate: moment(),
    ToDate: moment(),
  });
  const [filterData, setFilterData] = useState({
    routeList: [],
    expensesList: [],
    supplierList: [],
  });
  const [expanded, setExpanded] = useState([]);
  const [expandOneAtATime, setExpandOneAtATime] = useState(true); // State for toggle button

  // Handle fundtions

  const closeFilter = () => setOpenFilter(false);
  const handleChangeExpand = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) =>
      isExpanded && expandOneAtATime
        ? [panel]
        : isExpanded
        ? [...prevExpanded, panel]
        : prevExpanded.filter((item) => item !== panel)
    );
  };

  const handleToggleExpand = () => {
    setExpandOneAtATime(!expandOneAtATime);
    setExpanded([]); // Reset expanded accordions when toggling
  };

  const filterReport = function () {
    fetchReport();
  };

  const defaultFilter = function() {
    setFilterOptions({
      FromDate: moment(),
      ToDate: moment(),
    })
  }

  // Fetch API
  const fetchReport = async function () {
    const res = await CallQuickReport({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      ToDate: filterOptions.ToDate.format("YYYY-MM-DD"),
      FromDate: filterOptions.FromDate.format("YYYY-MM-DD"),
    })
    if (res.StatusCode === 6000) {
      setFilterData({
        expensesList: res.expense_details,
        routeList: res.route_details,
        supplierList: res.supplier_details,
      })
    } else { 
      dispatch(
        openSnackbar({
          open : true,
          message : res?.message || 'Data Not Found',
          severity : 'error',
        })
      )
    }
    
  };

  useEffect(() => {
    fetchReport()
  }, [])
  
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        {/* Top header */}
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t("Quick Report")}
            </Typography>
            {/* <SearchReport /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>
          {Export_view ? 
          <ReportDownload
            download_type={"PDF"}
            invoice_type={"quick_report"}
            ToDate={filterOptions.ToDate.format("YYYY-MM-DD")}
            FromDate={filterOptions.FromDate.format("YYYY-MM-DD")}
          />
          : null}
          {/* <FlexBox> */}
            {/* <ExportBtn /> */}

            {/* <PrintBtn /> */}
            {/* <Pagenation /> */}
          {/* </FlexBox> */}
        </Box>

        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
          {t("Applied Filters: ")}
        </div>
          <FilterLable
            name={t("Date")}
            value={ filterOptions.FromDate.format("DD/MM/YYYY") + " - " + filterOptions.ToDate.format("DD/MM/YYYY") }
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "calc(100% - 126px)",
            p: 2,
            overflowY: "scroll",
          }}
        >
          {EnableRoute && <AccordianTable
            index={1}
            expanded={expanded}
            handleChange={handleChangeExpand}
            data={filterData.routeList}
            title={t("Routes")}
          />}
          <AccordianTable
            index={2}
            expanded={expanded}
            handleChange={handleChangeExpand}
            data={filterData.expensesList}
            title={t("Expenses")}
          />
          <AccordianTable
            index={3}
            expanded={expanded}
            handleChange={handleChangeExpand}
            data={filterData.supplierList}
            title={t("Supplier")}
          />
        </Box>
      </Paper>

      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={filterReport}
        handleDefaultFilter={defaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.FromDate}
                toDate={filterOptions.ToDate}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    FromDate: value,
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    ToDate: value,
                  })
                }
              />
            </ListItem>

            <Divider />

            <ListItem>
              <VBSwitch
                checked={!expandOneAtATime}
                onChange={handleToggleExpand}
                label={
                  expandOneAtATime
                    ? "Now Expand One at a Time"
                    : "Now Expand Multiple"
                }
              />
            </ListItem>
          </List>
        }
      />
    </>
  );
};

export default QuickReport;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // width: "25%",
}));

import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {CircularProgress, FormControlLabel, IconButton, Input, InputBase, Switch, styled} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VBInputField from '../../../../../Components/Utils/VBInputField';
import VBSelect from '../../../../../Components/Utils/VBSelect';
import VBSwitch from '../../../../../Components/Utils/VBSwitch';
import DeleteIcon from '@mui/icons-material/Delete';
import { BorderBottom } from '@mui/icons-material';
import { CallUpdateVbassist, CallVbListItem, CallVbMailCreate } from '../../../../../Api/Reports/AccountApi';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from '../../../../../features/SnackBarslice';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import FormGroup from '@mui/material/FormGroup';
// import FormLabel from '@mui/material/FormLabel';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 500,
  backgroundColor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  padding: "0px",
  maxHeight: '70%',
  overflow: 'scroll'
  // border: "2px solid #000",
  // px: "26px",
  // py: "21px",
};

// let row = [
//   {email: "savadfarooque@vikncodes.com", status: "- Invite Pending", type: "Invite" },
//   {email: "dennisjose@vikncodes.com", status: "- Awaiting Response", type: "Re-Invite" },
//   {email: "rabeeh@vikncodes.com", status: "- Connected", type: "Disconnect" },
// ]

  const AssistModal = ({open, handleClose, data}) => {
    const [newEmail, setnewEmail] = useState({id:[],})
    // const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch()
    const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
    const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
    const [DeletedEmails, setDeletedEmails] = useState([])
    // const [Loading, setLoading] = useState(false)
    const [t, i18n] = useTranslation("common");
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
    const [reportdata, setreportdata] = useState([]);
    const [Existingemail, setExistingemail] = useState([])
    const [stats, setStats] = useState({
      dilayValue: "",
      dilaySelect: "Days",
      access: true,
      upationDelay : 0,
      PartyID: 0,
      DeletedID : 0,
      Type : "Sundry debtor/creditor",
      Name : "Supplier/Customer Name",
      
    })
    
    const handleChangeemail = (e, i) => {
      setnewEmail(prev => ({
        ...prev,
        [reportdata.data.id]: prev[reportdata.data.id].map((item, index) => {
          if (index === i) {
            return { ...item, email: e.target.value };
          }
          return item;
        })
      }));
    };

    const AddEmailRow = (id) => {
      const newRow = { email: '', status: 0, Loading: false};
      setnewEmail((prev) => ({
        ...prev, 
        [id]: [...(prev[id] || []), newRow]
      }));
    };
    console.log("addeds??????????????? new email",newEmail);



    const handleDeleteEmail = (e,index) => {
      let emailRow = newEmail
      let confirmation = window.confirm('Are you sure you want to delete?');

      if (confirmation) {
        if (emailRow[data.id].length >= 0) {
          let FilterEmail = emailRow[data.id].filter((e, i) => i !== index);
          // deletedemails = emailRow.id.filter(e => e.email !== i.email)
          // let correctedEmail = reportdata.data.filter((e, i) => i !== index)
          setnewEmail({
            ...newEmail,
            [data.id]: FilterEmail
          })
          // dispatch(
          //   openSnackbar({
          //     open : true,
          //     message : "Email Deleted Successfully",
          //     severity : 'success',
          //   })
          // )
        } else {
          console.log(e,index,'errror on delete email');
        }
      }
    } 
    const handleChange = (e) => {
      const { name, value } = e.target;
      setStats(prevStats => ({
        ...prevStats,
        [name]: value,
      }));
      // handleUpdate(value);
    };

    const handleChangeSwich = (e) => {
      const { checked } = e.target;
      setStats(prevStats => ({
        ...prevStats,
        access : checked,
      }));
    };

    const handleUpdate = async function (value) {
      const UpdateVbAssist = await CallUpdateVbassist({
        CompanyID: CompanyID,
        BranchID : BranchID,
        DeletedID : stats.DeletedID,
        PartyID : stats.PartyID,
        UpdationDelay : stats.upationDelay ? stats.upationDelay : 0 ,
        IsVbAssist : stats.access,
      })
    }



    // const handleDeleteEmail = (id, index) => {
    //   setnewEmail(prev => {
    //       const updatedEmails = { ...prev };
    //       if (updatedEmails[id] && updatedEmails[id].length > index) {
    //           // updatedEmails[id] = updatedEmails[id].filter((_, i) => i !== index);
    //           updatedEmails[id].splice(index, 1);
    //           console.log('Invalid index or ID:',id, index,);
    //           console.log(updatedEmails,'updatedddddddd',id,index);
    //       } else {
    //           console.log('Invalid index or ID:',id, index,);
    //           console.log(updatedEmails[id],'updatedddddddd');
    //       }
    //       return updatedEmails;
    //   });
    // }


    
    const fetchmodal = async function (id, i) {
      const newEmailValue = newEmail[reportdata.data.id][i]?.email;
      let isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmailValue);
      if (newEmail[data.id].filter((e,i) => e.email === newEmailValue).length > 1) {
        isValidEmail =false
      } 
      else if (reportdata?.data?.email.filter((e,i) => e.mail  === newEmailValue).length > 0) {
        isValidEmail =false
      }

      // console.log(newEmailValue,'entered new email', newEmail[data.id].filter((e,i) => e.email === newEmailValue));
      console.log(reportdata?.data?.email.filter((e,i) => e.mail === newEmailValue),'//////////////',newEmailValue);
      console.log(newEmail,'neemauilstateeeeeeee');
      if (isValidEmail === true) {
        setnewEmail(prev => ({
          ...prev,
          [reportdata.data.id]: prev[reportdata.data.id].map((item, index) => {
            if (index === i) {
              return { ...item, Loading: true };
            }
            return item;
          })
        }));
        const CreateMail = await CallVbMailCreate({
          CompanyID: CompanyID,
          BranchID: BranchID,
          Email: newEmailValue,
          PartyId: reportdata?.data?.id,
          Status: newEmail[reportdata.data.id][i]?.status
        });
        setnewEmail(prev => ({
          ...prev,
          [reportdata.data.id]: prev[reportdata.data.id].map((item, index) => {
            if (index === i) {
              return { ...item, status: CreateMail.Status, };
            }
            return item;
          })
        }));
        if (CreateMail?.StatusCode === 6000) {
          setnewEmail(prev => ({
            ...prev,
            [reportdata.data.id]: prev[reportdata.data.id].map((item, index) => {
              if (index === i) {
                return { ...item, Loading: false };
              }
              return item;
            })
          }));
          dispatch(
            openSnackbar({
              open : true,
              message : "Invitation Sent Successfully!",
              severity : 'success',
            })
          )
        }
      } else {
        dispatch(
          openSnackbar({
            open : true,
            message : "Please enter a valid and unique email address!",
            severity : 'warning',
          })
        )
      }
    };


    const fetchVBListItem = async function() {
      const listdata = await CallVbListItem ({
        CompanyID:CompanyID,
        BranchID:BranchID,
        PriceRounding:Number(PriceDecimalPoint),
        Uid : data.id
      })
      if (listdata?.StatusCode === 6000) {
        setreportdata(listdata);
        setStats({
          ...stats,
          upationDelay : listdata?.data?.updation_delay,
          access : listdata?.data?.access,
          PartyID : listdata?.data?.PartyID,
          Type : listdata?.data?.Party,
          Name : listdata?.data?.customer,
        })
      } else if (listdata?.StatusCode === 6001) {
        setreportdata([]);
        dispatch(
          openSnackbar({
            open : true,
            message : data.message,
            severity : 'warning',
          })
        )
      }
    }


    const fetchExistingEmail = async function(id,i) {
      setreportdata(prevReportData => ({
        ...prevReportData,
        data: {
          ...prevReportData.data,
          email: prevReportData?.data?.email.map((e, ind) => {
            if (i === ind) {
             return {...e, Loading: true}
            }else {
              return e
            }
          }) 
        }
      }));
      // id.Loading = true;
      const UpdateMails = await CallVbMailCreate ({
        CompanyID : CompanyID,
        BranchID : BranchID,
        Email: reportdata?.data?.email[i].mail,
        PartyId: reportdata?.data?.id,
        Status: reportdata?.data?.email[i].status
      })
      if (UpdateMails?.StatusCode === 6000) {
        setreportdata(prevReportData => ({
          ...prevReportData,
          data: {
            ...prevReportData.data,
            email: prevReportData?.data?.email.map((e, ind) => {
              if (i === ind) {
               return {...e, Loading: false}
              }else {
                return e
              }
            }) 
          }
        }));
        setExistingemail(UpdateMails)
        dispatch(
          openSnackbar({
            open : true,
            message : "Invitation Sent Successfully!",
            severity : 'success',
          })
        )
      } else {
        setExistingemail([])
        dispatch(
          openSnackbar({
            open : true,
            message : "Some error occurred",
            severity : 'warning',
          })
        )
      }
    }

    const handleDeletExistingeEmail = (event,index) => {
      let ExistingEmail = reportdata.data.email;
      let confirmation = window.confirm('Are you sure you want to delete?')
      if (confirmation) {
        if (ExistingEmail.length >= 0) {
          let correctedEmail = reportdata.data?.email.filter((e, i) => i !== index )
          setStats({
            ...stats,
            DeletedID : ExistingEmail[index].id,
          })
          setreportdata({
            ...reportdata,
            data: {
              ...reportdata.data,
              email: correctedEmail
            }
          });
          dispatch(
            openSnackbar({
              open : true,
              message : "Email Deleted Successfully",
              severity : 'success',
            })
          )
          console.log(ExistingEmail[index].id,'deletedmailllllllllllll');
        } else {
          console.log(event,index,'errror on delete email');
        }
      }
    } 

    // useEffect(() => {
    //   data?.data?.email?.status && fetchExistingEmail()
    //   data?.data?.email?.status && 
    // }, [data?.data?.email?.status])    
    // console.log(reportdata,'REPortdataattattatatatt');
    console.log(data.id,'dataaaaaaaaaaaaaaattatattatatatatat');
    useEffect(() => {
      data.id && fetchVBListItem()
      setnewEmail([])
    }, [open,Existingemail.Status])
    
    // useEffect(() => {
    //   if (Existingemail) { 
    //     fetchVBListItem(); 
    //   }
    // }, [Existingemail.Status]);

    useEffect(() => {
      handleUpdate()
    }, [stats.upationDelay, stats.access,stats.DeletedID])
    
  
  return (  
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", py: '16px', px: "18px"}}>
        <Box>
          <Typography sx={{ fontSize: "15px", color: "#065C8D"}} >{stats.Name}</Typography>
          <Typography sx={{ fontSize: "15px"}} >{stats.Type}</Typography>
        </Box>
        <Button sx={{ fontSize: "15px", color: "#0A9EF3"}} onClick={handleClose}>{t("CLOSE")}</Button>
      </Box>
      <Divider />

      <Box sx={{ display: "flex", justifyContent: "space-between",  py: '16px', px: "18px", width:"100%"}}>

        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center",width:"100%"}}>
          <Typography sx={{ fontSize: "13px", width:'100%' }}>{t("Updation delay")}:</Typography>
          <InputField  
            type='number'
            placeholder={t("Updation Count")}
            value={stats.upationDelay}
            onChange={handleChange}
            name='upationDelay'
            sx={{marginRight:'15px'}}
          />
          <VBSelect 
            value={stats.dilaySelect} 
            options={["Days", "Month"]}
            disabled={true}
            handleChange={(e) => setStats({...stats, dilaySelect: e.target.value })}
          />
          <FormControlLabel
            value="start"
            control={<Switch color="secondary" checked={stats.access} onChange={handleChangeSwich}  />}
            label={t("Access")}
            labelPlacement="start"
          />
        </Box>

        <Box sx={{ display:"flex", alignItems: "center" }}>
          {/* <Typography sx={{ fontSize: "13px" }}>Access</Typography>
          <VBSwitch /> */}
        </Box>
      </Box>
      <Divider />

      <Box sx={{display: "flex", justifyContent: 'space-between', alignItems: "center", py: '6px', px: "18px"}}>
        <Typography sx={{ fontSize: "13px" }}>{t("Email Address")}</Typography>
        <Button variant='text' sx={{ textTransform: "none", color: "#0F4C8A", fontSize: "13px" }} onClick={() => AddEmailRow(data?.id)}>{t("Add Email")}</Button>
      </Box>
      <Divider />

      <Box>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650, }} aria-label="simple table">
        <TableBody>
          {reportdata?.data?.email.map((row, key) => (
            <TableRow
              key={key}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row" sx={{ fontSize: "13px"}} >
                {key + 1}
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography sx={{display: "inline-block", fontSize: "13px", mr: 1}}>{row.mail}</Typography>
                <Typography sx={{display: "inline-block", fontSize: "13px", color:row.status === 0 ? 'red' : row.status === 1 ? 'green' : row.status === 2 ? 'orange' : 'black'}}> - {row.status === 0 ? "Invite Pending" : row.status === 2 ? "Awaiting Resonse" : "Connected"}</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Button variant='contained' sx={{ width: "100px", textTransform: "none", fontSize: "13px", backgroundColor:row.status === 0 ? 'green' : row.status === 1 ? 'red' : row.status === 2 ? 'rgb(27, 138, 198)' : 'black' ,'&:hover': {backgroundColor: 'none !important',}}} onClick={() => fetchExistingEmail(row,key)} >{row.Loading ?  <CircularProgress style={{width:'24px', height:'24px' , color:'white'}}/> :  row.status === 0 ? 'Invite' : row.status === 1 ? 'Disconnect' : row.status === 2 ? 'Re-Invite' : ''}</Button>
              </StyledTableCell>
              <StyledTableCell align="right">
                <IconButton onClick={() => handleDeletExistingeEmail(row.id,key)}>
                  <DeleteIcon/>
                </IconButton>  
              </StyledTableCell>
            </TableRow>
          ))}
          <TableRow></TableRow>
        </TableBody>
      </Table>
      {newEmail[data?.id]?.map((e,i) => (
      <TableRow>
        <StyledTableCell component="th" scope="row" sx={{ fontSize: "13px"}} >
          {reportdata.data.email.length + i + 1}
        </StyledTableCell>
        <StyledTableCell>
          <MailInputField
          value={e.email}
          placeholder='Enter Email...'
          sx={{mr: 6.5}}
          onChange={(e) => handleChangeemail(e,i)}
          />
          <Typography sx={{display: "inline-block", fontSize: "13px", color:e.status === 0 ? 'red' : e.status === 1 ? 'green' : 'orange'}}> - {e.status === 0 ? "Invite Pending" : e.status === 1 ? 'Connected' : "Awaiting Response"}</Typography>
        </StyledTableCell>
        <StyledTableCell align="right">



            <Button onClick={() => fetchmodal(e,i)}
            
            variant='contained' sx={{ width: "100px", textTransform: "none", fontSize: "13px", backgroundColor:e.status === 1 ? 'red' : e.status === 2 ? 'rgb(27, 138, 198)' :  'green'}} > {e.Loading ?  <CircularProgress style={{width:'24px', height:'24px', color:"white"}}/> :  e.status === 0 ? 'Invite' : e.status === 1 ? 'Disconnect' : 'Re-Invite' }</Button>
        </StyledTableCell>
      <StyledTableCell align="right">
        <IconButton sx={{display:'flex',marginLeft:'6px'}} onClick={() => handleDeleteEmail(data.id,i)}>
          <DeleteIcon />
        </IconButton>  
      </StyledTableCell>
      </TableRow>
      ))}
    </TableContainer>
      </Box>
    </Box>
  </Modal>
  )
}

export default AssistModal


const InputField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  width: "125px",
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 12,
    width: "125px",
    padding: "6px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    //   border: "1px solid",
    // },
  },
  "&.MuiInputBase-root":{
    marginTop: '3px'
  }
}));


const StyledTableCell = styled(TableCell)({
  padding: '10px', // Set padding to zero
});

const MailInputField = styled(Input)(({ }) => ({
  // width: '100%',
  fontSize: '13px',
  // padding: '10px',
  border: 'none',
  // paddingInline:'7px',
  marginLeft: '7px',
  borderBottom: 'none',
  "& .css-109xl16-MuiInputBase-root-MuiInput-root::before":{
    borderBottom: 'none',
    borderBottom: '0px solid transparent',

  },
  ".Mui-focus": {
    borderBottom: "none"
  },
  '&.hover': {
    outline: 'none', 
    border: '1px solid red',
    borderBottom: '0px solid transparent',

  },
}));
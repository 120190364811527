import {
  Box,
  Chip,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import SearchReport from "../../../../Components/Utils/SearchReport";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import PrintBtn from "../../../../Components/Utils/PrintBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import FilterLable from "../../../../Components/Utils/FilterLable";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import {
  CallOpeningStockReport,
  CallStockLedger,
} from "../../../../Api/Reports/InventoryApi";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import { CallWarehouses } from "../../../../Api/Reports/CommonReportApi";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../../../features/SnackBarslice";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { formatNumber } from "../../../../generalFunction";
import { goToInvoice } from "../../../../Function/comonFunction";
import { useTranslation } from "react-i18next";
import { UserRolePermission } from "../../../../Function/Editions";

const OpeningStockReport = () => {
  let Export_view = UserRolePermission("Opening Stock Report", "print_permission");
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [page_no, setPage_no] = useState(1);
  const [clearFilter, setClearFilter] = useState(false)
  const [reportData, setReportData] = useState({
    data: [],
    count: 0
  });
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    wareHouse: [],
    fromDate: moment(),
    toDate: moment(),
  });

  const [filterData, setFilterData] = useState({
    wareHouseList: [],
  });

  //Handle functions
  const closeFilter = () => setOpenFilter(false);

  const searchReport = function () {
    fetchReport();
  };

  const handleDefaultFilter = function () {
    setFilterOptions({
      wareHouse: [],
      fromDate: moment(),
      toDate: moment(),
    });
    setPage_no(1)
    setClearFilter(!clearFilter)
  };

  const goToCreateOpeningStock = function(id) {
    // const url = `${MyUrl}/dashboard/create-opening-stock/?id=${id}`
    // window.open(url, "_blank")
    goToInvoice(id, "Opening Stock")
  }

  // API Fetch data
  const fetchReport = async function () {
    setIsLoading(true);
    const payload = {
      FromDate: filterOptions.fromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.toDate.format("YYYY-MM-DD"),
      WarehouseIds: [],
      page_no: page_no,
      items_per_page: appSetting.itemPerPage,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id
    };
    if (filterOptions.wareHouse.length) {
      payload.WarehouseIds = filterOptions.wareHouse.map(
        (item) => item.WarehouseID
      );
    }
    const data = await CallOpeningStockReport(payload);
    console.log(data);
    if (data.StatusCode === 6000) {
      setReportData({
        data: data.data,
        count: data.count
      });
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: data?.message || "Data Not Found",
          severity: "warning",
        })
      );
      setReportData({
        data: [],
        count: 0
      });
    }
    setIsLoading(false);
  };

  const filterApis = async function () {
    const warehouse = await CallWarehouses({CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      show_all:true
    });
    console.log(warehouse);
    if (warehouse.StatusCode === 6000) {
      setFilterData((prev) => ({ ...prev, wareHouseList: warehouse.data }));
    }
  };

  // const getDataForPage = () => {
  //   const startIndex = (page_no - 1) * appSetting.itemPerPage;
  //   const endIndex = startIndex + appSetting.itemPerPage;
  //   const alldata = reportData;
  //   return alldata.slice(startIndex, endIndex) || [];
  // };

  useEffect(() => {
    filterApis();
  }, []);

  useEffect(() => {
    fetchReport();
  }, [page_no, clearFilter])
  

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500", mr: 2 }}
              variant="h2"
            >
              {t("Opening Stock Report")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            {Export_view ? 
            <ReportDownload
              download_type={"PDF"}
              invoice_type={"opening_stock_report"}
              FromDate={filterOptions.fromDate.format("YYYY-MM-DD")}
              ToDate={filterOptions.toDate.format("YYYY-MM-DD")}
              WarehouseID={
                filterOptions.wareHouse.length
                  ? filterOptions.wareHouse.map((item) => item.WarehouseID)
                  : []
              }
            />
            : null}
            {/* <ExportBtn /> */}
            {/* <PrintBtn /> */}
            <Pagenation
              totalItem={reportData.count}
              page_no={page_no}
              setPage_no={setPage_no}
            />
          </Box>
        </Box>
        {/* Filter Area  */}
        <Box
          // This also change into custom component
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // height: "54px",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
            {t("Applied Filters: ")}
          </div>
          <FilterLable
            name={t("Date")}
            value={
              filterOptions.fromDate.format("DD/MM/YYYY") +
              " - " +
              filterOptions.toDate.format("DD/MM/YYYY")
            }
          />
          {filterOptions.wareHouse.length !== 0 && (
            <FilterLable
              name={t("Warehouse Name")}
              value={filterOptions.wareHouse.map((e, i) => (
                <Chip
                  key={i}
                  label={e.WarehouseName}
                  variant="outlined"
                  sx={{
                    p: "1px",
                    fontSize: "10px",
                    height: "22px",
                    mr: "4px",
                    ".MuiChip-label": {
                      px: "8px",
                    },
                    ".MuiSvgIcon-root": {
                      fontSize: "15px",
                      color: "#ff00006b",
                    },
                  }}
                  onDelete={() =>
                    setFilterOptions({
                      ...filterOptions,
                      wareHouse: filterOptions.wareHouse.filter(
                        (l) => l.WarehouseID !== e.WarehouseID
                      ),
                    })
                  }
                />
              ))}
              handleDelete={() =>
                setFilterOptions({ ...filterOptions, wareHouse: [] })
              }
            />
          )}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("Serial No")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher Number")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Warehouse")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Notes")} </VBTableCellHeader>
            <VBTableCellHeader>{t("User")} </VBTableCellHeader>
            {/* <VBTableCellHeader>Qty </VBTableCellHeader> */}
            <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Total Quantity")} </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Grand Total")} </VBTableCellHeader>
            {/* <VBTableCellHeader>Qty Out </VBTableCellHeader>
            <VBTableCellHeader>Balance </VBTableCellHeader> */}
          </VBTableHeader>
          {isLoading ? (
            <VBSkeletonLoader />
          ) : (
            <>
              <TableBody>
                {reportData.data.map((data, i) => (
                  <TableRow sx={{ cursor: "pointer"}} onClick={ () => goToCreateOpeningStock(data.id, "OS") }>
                    <VBTableCellBody>
                      {(page_no - 1) * appSetting.itemPerPage + i + 1}
                    </VBTableCellBody>
                    <VBTableCellBody>{data.VoucherNo}</VBTableCellBody>
                    {/* <VBTableCellBody>{data.LedgerName}</VBTableCellBody> */}
                    <VBTableCellBody>{data.WareHouseName}</VBTableCellBody>
                    {/* <VBTableCellBody>{data.LedgerName}</VBTableCellBody> */}
                    <VBTableCellBody>{data.Notes}</VBTableCellBody>
                    <VBTableCellBody>{data.UserName}</VBTableCellBody>
                    <VBTableCellBody sx={{ textAlign: "right"}}>{data.TotalQty === 0 ? "-" : formatNumber(data.TotalQty) }</VBTableCellBody>
                    <VBTableCellBody sx={{ textAlign: "right"}}>{data.GrandTotal === 0 ? "-" : formatNumber(data.GrandTotal)}</VBTableCellBody>
                    {/* <VBTableCellBody>{data.Qty}</VBTableCellBody> */}
                    {/* <VBTableCellBody>{data.QtyIn}</VBTableCellBody> */}
                    {/* <VBTableCellBody>{data.QtyOut}</VBTableCellBody>
                <VBTableCellBody>{data.QtyIn}</VBTableCellBody> */}
                  </TableRow>
                ))}
                <TableRow></TableRow>
              </TableBody>
              {/* <VBTableFooter>
                <TableRow>
                  <VBTableCellFooter>Total</VBTableCellFooter>
                  <VBTableCellFooter>
                    {reportData[reportData.length - 1]?.VoucherNo}
                  </VBTableCellFooter>
                  <VBTableCellFooter>
                    {reportData[reportData.length - 1]?.WareHouseName}
                  </VBTableCellFooter>
                  <VBTableCellFooter>
                    {reportData[reportData.length - 1]?.Notes}
                  </VBTableCellFooter>
                  <VBTableCellFooter>
                    {reportData[reportData.length - 1]?.UserName}
                  </VBTableCellFooter>
                  <VBTableCellFooter sx={{ textAlign: "right"}}>
                    {reportData[reportData.length - 1]?.TotalQty}
                  </VBTableCellFooter>
                  <VBTableCellFooter sx={{ textAlign: "right"}}>
                    {formatNumber(reportData[reportData.length - 1]?.GrandTotal)}
                  </VBTableCellFooter>
                </TableRow>
              </VBTableFooter> */}
            </>
          )}
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={() => searchReport()}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.fromDate}
                toDate={filterOptions.toDate}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    fromDate: value,
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    toDate: value,
                  })
                }
              />
            </ListItem>
            <ListItem>
              {/* Product Search AutoComplete */}
              <ViknAutoComplete
                multiple
                label={t("Warehouse")}
                placeholder={t("Search for Warehouse")}
                value={filterOptions.wareHouse}
                options={filterData.wareHouseList}
                onChange={(e, newValue) =>
                  setFilterOptions((prev) => ({ ...prev, wareHouse: newValue }))
                }
                getOptionLabel={(option) => option?.name}
                // isOptionEqualToValue={(option, value) => option?.name === value?.name}
              />
            </ListItem>
            {/* <Divider /> */}
          </List>
        }
      />
    </>
  );
};

export default OpeningStockReport;

// const FlexBox = styled(Box)(() => ({
//   justifyContent: "space-between",
//   alignItems: "center",
//   display: "flex",
//   // width: "25%",
// }));

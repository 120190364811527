import axios from "axios";

export const stock_order_create_api = async function (body) {

    try {
      const { data } = await axios.post("api/v11/stockOrder/create-stockOrder/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
};

export const stock_order_api = async function (body) {

    try {
      const { data } = await axios.post("api/v11/stockOrder/stock-order-pagination/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
};

export const stock_order_edit_api = async function (body) {

    try {
      const { data } = await axios.post(`api/v11/stockOrder/edit/stockOrder/${body.unq_id}/`, {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
};

export const stock_order_delete_api = async function (body) {

    try {
      const { data } = await axios.post("api/v11/stockOrder/delete/stockOrderMaster/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
};
export const stock_order_master_api = async function (body) {

  try {
    const { data } = await axios.post(`api/v11/stockOrder/view/stockOrderMaster/${body.uniq_id}/`, {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};


export const stock_order_prodduct_api = async function (body) {

  try {
    const { data } = await axios.post(`api/v11/stockOrder/stockOrderProductlist/${body.uniq_id}/`, {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};
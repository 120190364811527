import React from "react";
import {
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
} from "../../../../../Components/ReportTable/ReportTable";
import { useTranslation } from "react-i18next";
import { TableBody, TableRow } from "@mui/material";
import { formatNumber } from "../../../../../generalFunction";

const ConsolidateTable = ({data, isLoading}) => {
  const [t] = useTranslation("common");
  console.log(data);
  return (
    <VBTableContainer>
      <VBTableHeader>
        <VBTableCellHeader>{t("Tax Type")}</VBTableCellHeader>
        <VBTableCellHeader>{t("Taxable")}</VBTableCellHeader>
        <VBTableCellHeader>{t("Non Taxable")}</VBTableCellHeader>
        <VBTableCellHeader>{t("Tax Amount")}</VBTableCellHeader>
        <VBTableCellHeader>{t("Net Amount")}</VBTableCellHeader>
      </VBTableHeader>
      <TableBody>
        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{t("Sales Invoice")}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.sales_amount.Taxable)}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.sales_amount.NonTaxable)}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.sales_amount.TaxAmount)}</VBTableCellBody>
          <VBTableCellBody>{formatNumber(data.sales_amount.NetAmount)}</VBTableCellBody>
        </TableRow>
        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{t("Sales Return")}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.sales_return_amount.Taxable)}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.sales_return_amount.NonTaxable)}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.sales_return_amount.TaxAmount)}</VBTableCellBody>
          <VBTableCellBody>{formatNumber(data.sales_return_amount.NetAmount)}</VBTableCellBody>
        </TableRow>
        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6", color: "green", fontWeight: "bold"}}>{t("Total Received")}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.total_received_amount.Taxable)}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.total_received_amount.NonTaxable)}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.total_received_amount.TaxAmount)}</VBTableCellBody>
          <VBTableCellBody>{formatNumber(data.total_received_amount.NetAmount)}</VBTableCellBody>
        </TableRow>
        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{t("Purchase Invoice")}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.purchase_amount.Taxable)}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.purchase_amount.NonTaxable)}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.purchase_amount.TaxAmount)}</VBTableCellBody>
          <VBTableCellBody>{formatNumber(data.purchase_amount.NetAmount)}</VBTableCellBody>
        </TableRow>
        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{t("Purchase Return")}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.purchase_return_amount.Taxable)}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.purchase_return_amount.NonTaxable)}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.purchase_return_amount.TaxAmount)}</VBTableCellBody>
          <VBTableCellBody>{formatNumber(data.purchase_return_amount.NetAmount)}</VBTableCellBody>
        </TableRow>
        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{t("Expenses")}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.expenses_amount.Taxable)}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.expenses_amount.NonTaxable)}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.expenses_amount.TaxAmount)}</VBTableCellBody>
          <VBTableCellBody>{formatNumber(data.expenses_amount.NetAmount)}</VBTableCellBody>
        </TableRow>
        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6", color: "#FF0000", fontWeight: "bold"}}>{t("Total Paid")}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.total_paid_amount.Taxable)}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.total_paid_amount.NonTaxable)}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.total_paid_amount.TaxAmount)}</VBTableCellBody>
          <VBTableCellBody>{formatNumber(data.total_paid_amount.NetAmount)}</VBTableCellBody>
        </TableRow>
        <TableRow>
          <VBTableCellBody>  </VBTableCellBody>
          <VBTableCellBody>  </VBTableCellBody>
          <VBTableCellBody>  </VBTableCellBody>
          <VBTableCellBody>  </VBTableCellBody>
          <VBTableCellBody>  </VBTableCellBody>
        </TableRow>

        <TableRow>
          <VBTableCellBody sx={{backgroundColor: "#000000", color: "#FFFFFF", fontWeight: "bold"}}>{t("Total")}</VBTableCellBody>
          <VBTableCellBody sx={{backgroundColor: "#000000"}}> </VBTableCellBody>
          <VBTableCellBody sx={{backgroundColor: "#000000"}}> </VBTableCellBody>
          <VBTableCellBody sx={{backgroundColor: "#000000"}}> </VBTableCellBody>
          <VBTableCellBody sx={{backgroundColor: "#000000"}}> </VBTableCellBody>
        </TableRow>
        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{t("Paid")}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}> </VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}> </VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.Paid)}</VBTableCellBody>
          <VBTableCellBody> </VBTableCellBody>
        </TableRow>
        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{t("Received")}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}> </VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}> </VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.Recieved)}</VBTableCellBody>
          <VBTableCellBody> </VBTableCellBody>
        </TableRow>
        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6", color: "#0c78c1", fontWeight: "bold"}}>{t("Total payable")}</VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}> </VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}> </VBTableCellBody>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{formatNumber(data.TotalPayable)}</VBTableCellBody>
          <VBTableCellBody> </VBTableCellBody>
        </TableRow>
        <TableRow></TableRow>
      </TableBody>
    </VBTableContainer>
  );
};

export default ConsolidateTable;

import { Accordion, AccordionDetails, AccordionSummary, Box, TableBody, TableRow } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { VBTableCellBody, VBTableCellHeader, VBTableContainer, VBTableHeader } from "../../../../../Components/ReportTable/ReportTable";
import { formatNumber } from "../../../../../generalFunction";
import { useTranslation } from "react-i18next";

const AccordianTable = (props) => {
  const [t, i18n] = useTranslation("common");
    console.log(props.data);
  return (
    <Box sx={{mb: 1}}>
    <Accordion
      expanded={props.expanded.includes(`panel${props.index+1}`)} 
      onChange={props.handleChange(`panel${props.index+1}`)}
      sx={{
        backgroundColor: "#a2c2d9", // Background color
        borderRadius: "12px !important", // Border radius
        boxShadow: "none", // Box shadow
        border: "none",
        "&::before": {
          content: "none", // Remove the ::before content
        },
        "& .MuiAccordionSummary-content": {
          "&::before": {
            borderBottom: "none", // Remove the border bottom
          },
        },
        ".MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
           minHeight: "46px"
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          "& .MuiSvgIcon-root": {
            color: "#000000", // Icon color
            //   margin: "auto 1px",
          },
          ".css-o4b71y-MuiAccordionSummary-content.Mui-expanded": {
            my: '7px',
            minHeight: "45px"
          },
          ".MuiAccordionSummary-content": {
            my: "7px",
          },
        }}
      >
        {props?.title || "Title"}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "16px", // Padding for the details content
          "& .MuiAccordion-root::before": {
            height: "0px !important",
          },
        }}
      >
        <VBTableContainer>
          <VBTableHeader>
            {/* {Object.keys(props?.data[0] || {No_Data: 'vaa'})?.map(key => ( //This is hear because if props.data is null it take this dummey object
                <VBTableCellHeader>{key} </VBTableCellHeader>
            ))} */}
            {props.title !== "Routes" ? 
            <><VBTableCellHeader>{t("Ledger Name")} </VBTableCellHeader>
              <VBTableCellHeader>{t("Total")} </VBTableCellHeader> </> : 
            <>
              <VBTableCellHeader>{t("Route Name")} </VBTableCellHeader>
              <VBTableCellHeader>{t("Credit Sales")} </VBTableCellHeader>
              <VBTableCellHeader>{t("Cash Sales")} </VBTableCellHeader>
              <VBTableCellHeader>{t("Bank Sales")} </VBTableCellHeader>
              <VBTableCellHeader>{t("Receipts")} </VBTableCellHeader>
              <VBTableCellHeader>{t("Total")} </VBTableCellHeader>
            </>}
          </VBTableHeader>
          <TableBody>
            {props?.data.map((e, i) => (
            <TableRow>
               {/* { Object.keys(e).map(key => (
                    <VBTableCellBody>{formatNumber(e[key])} </VBTableCellBody>
                  ))} */}
                  {props.title !== "Routes" ? 
                  <>
                    <VBTableCellBody>{e.LedgerName} </VBTableCellBody>
                    <VBTableCellBody>{e.Total} </VBTableCellBody>
                  </> : 
                  <>
                    <VBTableCellBody>{e.RouteName} </VBTableCellBody>
                    <VBTableCellBody>{e.CreditSales} </VBTableCellBody>
                    <VBTableCellBody>{e.CashSales} </VBTableCellBody>
                    <VBTableCellBody>{e.BankSales} </VBTableCellBody>
                    <VBTableCellBody>{e.Receipt} </VBTableCellBody>
                    <VBTableCellBody>{e.GrandTotal} </VBTableCellBody>
                  </>}
            </TableRow>
            ))}
          </TableBody>
        </VBTableContainer>
      </AccordionDetails>
    </Accordion>
    </Box>
  );
};

export default AccordianTable;

import React, { useEffect, useState } from "react";
import {Box,List,ListItem,Paper,Typography,styled,Chip, Divider} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {VBSkeletonLoader,VBTableCellBody,VBTableCellFooter,VBTableCellHeader,VBTableContainer,VBTableFooter,VBTableHeader,VBTableRowNull,} from "../../../../Components/ReportTable/ReportTable";
import FilterLable from "../../../../Components/Utils/FilterLable";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import moment from "moment";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { date_range } from "../../../../Function/comonFunction";
import {CallBranchsList, CallEmployees,CallParties,CallRoutes,CallUsers,} from "../../../../Api/Reports/CommonReportApi";
import { callSalesReport } from "../../../../Api/Reports/SalesApi";
import ViknRadioGroup from "../../../../Components/Utils/ViknRadioGroup";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import VBDatetimepicker from "../../../../Components/Utils/VBDatetimepicker";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { formatNumber } from "../../../../generalFunction";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import LoaderLite from "../../../../Components/Utils/LoaderLite";
import { VIKNBOOKS_FRONT_URL } from "../../../../generalVeriable";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import { UserRolePermission } from "../../../../Function/Editions";
const StyleLable = function (props) {
  console.log(props,"rrrrrrrrrrrrrrrrrrrrrrrrr");
  return (
    <Typography sx={{ color:props?.name==="Sales"?"red":props?.name==="Returns"?"green": "#0A9EF3", fontSize: "12px" }}>
      { props.name|| ""}:{" "}
      <Typography
        sx={{ color: "#001746", fontSize: "12px", marginLeft:"8px" }}
        component={"span"}
      >
      {props.value}
      </Typography>
    </Typography>
  );
};

const SalesReport = () => {
  let Export_view = UserRolePermission("Sales Report", "print_permission");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const dispatch = useDispatch()
  console.log(appSetting);
  const [reportData, setReportData] = useState({
    StatusCode: 0,
    total_rows: 0,
    data: [],
    total: {},
    tot_sales: 0,
    tot_returns: 0,
  });
  const [loading, setLoading] = useState(false);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,EnableRoute,EnableBranch} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoadingSalesman, setIsLoadingSalesman] = useState(false);
  const [isLoadingParty, setIsLoadingParty] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingBranch, setIsLoadingBranch] = useState(false);
  const [page_no, setPage_no] = useState(1);
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState("Generating Report...");
  const [routes, setRoutes] = useState([0]);
  const [t, i18n] = useTranslation("common");
  const isAdmin = BranchID === 1 ? true : false;
  const [filterOptions, setFilterOptions] = useState({
    salesmanList: [],
    salesmanObj: { LedgerName: "" },

    partyList: [],
    partyObj: { PartyName: "" },

    userList: [],
    userObj: { customer_name: "" },

    routeList: [],
    routeListValue: [],
    RouteLedgers:[0],

    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment(),
    },

    BranchList: [],
    SelectedBranches:[],
    IsSelectedAllBranch:false,

    filterType: "sales",

    invoiceList: [
      { name: "sales", label: t("Sales") },
      { name: "return", label: t("Return") },
      { name: "both", label: t("Both") },
    ],
    filterValue: "all",
    billwiseList: [
      { name: "all", label: t("All") },
      { name: "cash", label: t("Cash/Bank") },
      { name: "credit", label: t("Credit") },
      { name: "partial", label: t("Partial") },
    ],
    
    filterValue: "VoucherDate",
    searchDate: [
      { name: "VoucherDate", label: t("Voucher Date") },
      { name: "CreatedDate", label: t("Created Date") },
    ],



  });
 const [is_voucher,setIsVoucher] = useState(true)
  //Handle functions

  const closeFilter = () => setOpenFilter(false);

  const searchReport = function () {
    fetchReport();
  };

  const handleDeletefilter = (name, index) => {
    console.log(name, index);
    if (name === "routeListValue") {
      let routeListValue = [...filterOptions.routeListValue];
      routeListValue = routeListValue.filter((item, idx) => idx !== index);
      setFilterOptions((prevState) => ({
        ...prevState,
        routeListValue: routeListValue,
      }));
    } else {
      setFilterOptions((prevState) => ({ ...prevState, [name]: {} }));
    }
    // handlefilterList()
  };
  const handlefilterList = () => {
    let list = [];
    let filterdata = filterOptions
    if (filterdata.date.FromDate &&filterdata.date.ToDate ) {
      let date = String(filterOptions.date.FromDate.format("DD-MM-YYYY") + "  to " + filterOptions.date.ToDate.format("DD-MM-YYYY"))
      list.push({ name: t("Date"), value: date });
    }
    if (filterdata?.filterType) {
      list.push({ name: t("Filter Type"), value: filterdata.filterType });
    }

    if (filterdata?.filterValue) {
        list.push({ name: t("Billwise Type"), value: filterdata.filterValue });
    }


    if (filterdata.partyObj?.PartyName !== "" && filterdata.partyObj?.PartyName !==undefined) {
        list.push({
            name: t("Customer"),
            value: filterdata.partyObj.PartyName,
            handleDelete: () => handleDeletefilter("partyObj")
        });
    }

    if (filterdata.userObj.customer_name !== undefined && filterdata.userObj.customer_name !== "") {
        list.push({
            name: t("User"),
            value: filterdata.userObj.customer_name,
            handleDelete: () => handleDeletefilter("userObj")
        });
    }

    if (filterdata.salesmanObj && filterdata.salesmanObj.LedgerName !== undefined && filterdata.salesmanObj.LedgerName !== '') {
      list.push({
          name: t("Salesman"),
          value: filterdata.salesmanObj.LedgerName,
          handleDelete: () => handleDeletefilter("salesmanObj")
      });
    }

    if (filterdata.routeListValue && filterdata.routeListValue.length) {
        filterdata.routeListValue.forEach((route, index) => {
            if (route.RouteName !== "") {
                list.push({
                    name: t("Route"),
                    value: route.RouteName,
                    handleDelete: () => handleDeletefilter("routeListValue", index)
                });
            }
        });
    }
    return list;
  };








  // **************************************************************************
  const handleChange = (e, name) => {

    let { value } = e.target;
    setFilterOptions({ ...filterOptions, [name]: value });
    if (name === 'filterValue') {
      setIsVoucher(value === 'VoucherDate');
    }
  };
  // search Employee
  const handleSearchEmployee = async (e, value, name) => {
    if (value) {
      setIsLoadingSalesman(true);
      let salesmanList = await CallEmployees({
        CompanyID:CompanyID,CreatedUserID:user_id,
        BranchID:BranchID,
        PriceRounding:Number(PriceDecimalPoint),
        items_per_page: 10,
        page_no: 1,
        search: value,
      });

      let salesman_data = [];
      if (salesmanList.data) {
        salesman_data = salesmanList.data;
      }
      setFilterOptions({ ...filterOptions, salesmanList: salesman_data });
      setIsLoadingSalesman(false);
    } else {
      setFilterOptions({ ...filterOptions, salesmanObj: { LedgerName: "" } });
    }
  };
  const handleDefaultFilter = async () => {
    let BranchIDList = filterOptions.BranchList.filter((i) => {
      if (i.BranchID === BranchID) {
        return i;
      }
    });
    let SelectedBranches = [];
    SelectedBranches.push(BranchIDList[0]);

    setFilterOptions({
      ...filterOptions,
      salesmanObj: { LedgerName: "" },
      partyObj: { PartyName: "" },
      userObj: { customer_name: "" },
      routeListValue: [],
      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Today" },
        FromDate: moment(),
        ToDate: moment(),
      },
      filterType: "sales",
      filterValue: "all",
      SelectedBranches,
      IsSelectedAllBranch:false
    })
  }
  // search Party or customer
 
  const handleSearchParty = async (e, value, name) => {
    if (value) {
      setIsLoadingParty(true);
      const partyResponse = await CallParties({CompanyID:CompanyID,
        BranchID:BranchID,
        PriceRounding:Number(PriceDecimalPoint),
        CreatedUserID:user_id,
        items_per_page: 10,
        page_no: 1,
        search: value,
        PartyType: "1",
      });
      let partyList = [];
      if (partyResponse?.StatusCode === 6000) {
        partyList = partyResponse.data;
      }
      setFilterOptions({ ...filterOptions, partyList });
      setIsLoadingParty(false);
    } else {
      setFilterOptions({ ...filterOptions, partyObj: { PartyName: "" } });
    }
  };

  const handleAutoComplete = function (e, value, name) {
    if (value) {
      if (name === "date") {
        console.log(e, value);
        if (value.name === "Custom") {
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: true,
              value: value,
            },
          });
        } else {
          let date = date_range(value.name);
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: false,
              value: value,
              ToDate: date.todate,
              FromDate: date.fromdate,
            },
          });
        }
      }else if (name === "SelectedBranches"){
        setFilterOptions({
          ...filterOptions,
          IsSelectedAllBranch: false,
          [name]: value,
        });
      }
       else {
        setFilterOptions({ ...filterOptions, [name]: value });
      }
    }
    // else {
    //   let date = date_range();
    //   setFilterOptions({
    //     date: {
    //       isActive: false,
    //       value: { name: "Today" },
    //       ToDate: date.todate,
    //       FromDate: date.fromdate,
    //       ...filterOptions.date,
    //     },
    //     ...filterOptions,
    //   });
    // }
  };

  const branchCheckChange = (name) => {
    let SelectedBranches = [];
    if (!filterOptions[name] === true) {
      filterOptions.BranchList.map((i) => {
        if (SelectedBranches.includes(i) === false) {
          SelectedBranches.push(i);
        }
      });

      setFilterOptions({
        ...filterOptions,
        SelectedBranches,
        [name]: !filterOptions[name],
      });
    } else {
      setFilterOptions({
        ...filterOptions,
        [name]: !filterOptions[name],
      });
    }
  };

  const handleRouteList = async (exp) => {
    let list = [0];
    let routeListValue = filterOptions.routeListValue;
    const routeIDs = routeListValue.map(item => item.RouteID);
    list.push(...routeIDs);
    if(exp){
      setRoutes(list)
    }
    console.log(list,"------------------>");

    return list;
  }
  // API Fetch data
  const fetchReport = async function (array) {
    setLoading(true)
    let RouteLedgers = await handleRouteList()
    let BranchIDList = [BranchID]

    //if any branch selected
    let SelectedBranches = array ? array : filterOptions.SelectedBranches;
    if (SelectedBranches.length !== 0 && isAdmin) {
      let BranchList = [];
      console.log(SelectedBranches,"SelectedBranches*************");
      SelectedBranches.map((i) => {
        i.BranchID
          ? BranchList.push(i.BranchID)
          : console.log(`no branchid${i}`);
      });

      BranchIDList = BranchList;
    }
   


   
    console.log(filterOptions.date.FromDate,"==============Printing Filter date");
    const data = await callSalesReport({
      CompanyID:CompanyID,
      BranchID:BranchIDList,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,
      FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD") || null,
      ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD") || null,
      FromDateTime: filterOptions.date.FromDate.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ")|| null,
      ToDateTime : filterOptions.date.ToDate.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ")|| null,
      RouteLedgers,
      UserID: filterOptions.userObj?.UserID ? filterOptions.userObj.UserID : 0,
      EmployeeID: filterOptions.salesmanObj?.EmployeeID
        ? filterOptions.salesmanObj.EmployeeID
        : 0,
      CustomerID: filterOptions.partyObj?.LedgerID
        ? filterOptions.partyObj.LedgerID
        : 0,
      page_no: page_no,
      page_size: appSetting.itemPerPage,
      filterValue: filterOptions.filterValue,
      filter_type: filterOptions.filterType,
      is_voucherDate:is_voucher,
    },dispatch);
    console.log(data,'datatdaadtatdtadaatdadatat');
    if (data?.StatusCode === 6000 ) {
      setReportData(data);
        if (data.total_rows === 0) {
          dispatch(
            openSnackbar({
              open : true,
              message : 'Data Not Found During This Time!',
              severity : 'warning',
            })
          )
        }
      // setPage()
    } else if (data?.StatusCode === 6001){
      setReportData({
        StatusCode: 0,
        total_rows: 0,
        data: [],
        total: {},
        tot_sales: 0,
        tot_returns: 0,
      })
      dispatch(
        openSnackbar({
          open : true,
          message : data.message,
          severity : 'warning',
        })
      )
    }
    setLoading(false)
  };

  const filterApis = async function () {
    setIsLoadingBranch(true);
    let SelectedBranches = [];
    const BranchsListRes = await CallBranchsList({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      show_all: true,
    });
    if (BranchsListRes.StatusCode === 6000) {
      let BranchIDList = BranchsListRes.data.filter((i) => {
        if (i.BranchID === BranchID) {
          return i;
        }
      });

      SelectedBranches.push(BranchIDList[0]);
    } else if (BranchsListRes.StatusCode === 6001) {
      console.log("ERROR - 6001")
    }

    setIsLoadingSalesman(true);
    const SalesmanResponse = await CallEmployees({CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,
      items_per_page: 10,
      page_no: 1,
      search: "",
    });
    let salesmanList = [];
    if (SalesmanResponse?.StatusCode === 6000) {
      salesmanList = SalesmanResponse.data;
    }

    setIsLoadingParty(true);
    const partyResponse = await CallParties({CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,
      items_per_page: 10,
      page_no: 1,
      search: "",
      PartyType: "1",
    });
    let partyList = [];
    if (partyResponse?.StatusCode === 6000) {
      partyList = partyResponse.data;
    }

    setIsLoadingUsers(true);
    const userResponse = await CallUsers({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,
      items_per_page: 10,
      page_no: 1,
      search: "",
      PartyType: "1",
    });
    let userList = [];
    if (userResponse?.StatusCode === 6000) {
      userList = userResponse.data;
    }

    const routeResponse = await CallRoutes({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,
      // items_per_page: 10,
      // page_no: 1,
      search: "",
      PartyType: "1",
    });
    let routeList = [];
    if (routeResponse?.StatusCode === 6000) {
      routeList = routeResponse.data;
    }

    setFilterOptions({
      ...filterOptions,
      BranchList: BranchsListRes.data,
      SelectedBranches,
      partyList,
      salesmanList,
      userList,
      routeList,
    });
    setIsLoadingSalesman(false);
    setIsLoadingParty(false);
    setIsLoadingUsers(false);
    setIsLoadingBranch(false);
  };

  useEffect(() => {
    // fetchExpensesReport()
    filterApis();
  }, []);

  useEffect(() => {
    fetchReport()
  },[page_no])
  const handleTableRowClick = (data) => {
    const destinationURL = filterOptions.filterType === "return" || data.GrandTotal < 0
      ? `${VIKNBOOKS_FRONT_URL}dashboard/sales-return-preview`
      : `${VIKNBOOKS_FRONT_URL}dashboard/sale-invoice-single`;

      const finalURL = `${destinationURL}?id=${data.id}&unq_id=${data.id}`;

    window.open(finalURL, '_blank');
    // window.location.href = finalURL;

  };
  console.log(filterOptions,"---->");
  return (
    <>
    {progress > 0 ?
      <LoaderLite message={((progress<=100)?("Generating Report... " + progress + "%"):"Creating File... ")} progress={progress} fullscreen={true} />
      :null}
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t('Sales Report')}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className="right">
            {Export_view ? 
            <ReportDownload
            download_type={"PDF"}
            invoice_type={
              filterOptions.filterType ==="return"?"sales_return":
              filterOptions.filterType ==="both"?"sales_both":"sales"
            
            }
            filterValue={filterOptions.filterValue}
            is_celery={true}
            setProgress={setProgress}
            FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
            ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
            UserID={ filterOptions.userObj?.UserID ? filterOptions.userObj.UserID : 0}
            EmployeeID={filterOptions.salesmanObj?.EmployeeID
              ? filterOptions.salesmanObj.EmployeeID
              : 0}
            CustomerID={filterOptions.partyObj?.LedgerID
              ? filterOptions.partyObj.LedgerID
              : 0}
            RouteLedgers={routes}
            handleRouteList={handleRouteList}
            BranchID = {`[${filterOptions.SelectedBranches.map((i) => i.BranchID)}]`}

          />
          : null}
            <Pagenation
              totalItem={reportData.total_rows}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
            alignItems:"center",

          }}
        >
            <Chip
              sx={{mr: 1}}
              label={<StyleLable name={t("Sales")} value={reportData.tot_sales} />}
              variant="outlined"
            />
            <Chip
              sx={{mr: 1}}
              label={<StyleLable name={t("Returns")} value={reportData.tot_returns} />}
              variant="outlined"
            />

            <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px",whiteSpace:"nowrap"}}>
            {t("Applied Filters: ")}
            </div>
        {handlefilterList(filterOptions).map((val, i) => (
        <FilterLable
          name={t(val.name)}
          value={t(val.value)}
          handleDelete={val.handleDelete?val.handleDelete:null}
        />
        ))}
         {filterOptions.SelectedBranches.length !== 0 &&
            // BranchData.IsSelectedAllBranch === false &&
            EnableBranch &&
            BranchID === 1 &&
            isAdmin === true && (
              <FilterLable
                name={t("Branch Name")}
                value={filterOptions.SelectedBranches.map((e, i) => (
                  <Chip
                    key={i}
                    label={e.NickName}
                    variant="outlined"
                    sx={{
                      p: "1px",
                      fontSize: "10px",
                      height: "22px",
                      mr: "4px",
                      ".MuiChip-label": {
                        px: "8px",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "15px",
                        color: "#ff00006b",
                      },
                    }}
                    onDelete={() => {
                      let SelectedBranches = filterOptions.SelectedBranches.filter(
                        (l) => l.BranchID !== e.BranchID
                      );
                      setFilterOptions({
                        ...filterOptions,
                        IsSelectedAllBranch: false,
                        SelectedBranches: SelectedBranches,
                      });

                      fetchReport(SelectedBranches);
                    }}
                  />
                ))}
                handleDelete={() => {
                  let get_BranchID = filterOptions.BranchList.filter((i) => {
                    if (i.BranchID === BranchID) {
                      return i;
                    }
                  });

                  let SelectedBranches = [];
                  SelectedBranches.push(get_BranchID[0]);
                  setFilterOptions({
                    ...filterOptions,
                    SelectedBranches,
                    IsSelectedAllBranch: false,
                  });
                  fetchReport(SelectedBranches);
                }}
              />
            )}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t('Serial No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Voucher No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Voucher Date')} </VBTableCellHeader>
            <VBTableCellHeader>{t('Created Date')} </VBTableCellHeader>
            <VBTableCellHeader>{t('Ledger Name')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Sales Man')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Gross Amount')} </VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Total Tax')} </VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Bill Discount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Grand Total')}</VBTableCellHeader>
          </VBTableHeader>
          {loading?
          <VBSkeletonLoader/>
          :
          <TableBody>
            {reportData?.data?.map((data, i) => (
              <TableRow
              // onClick={() => navigate(filterOptions.filterType==="return" || data.GrandTotal < 0 ? VIKNBOOKS_FRONT_URL+"/dashboard/sales-return-preview" :  VIKNBOOKS_FRONT_URL+"/dashboard/sale-invoice-single", { state: { invoice_type: "sales_invoice", id: data.id }, unq_id: data.id })}
              onClick={()=>{handleTableRowClick(data)}}
              >
                <VBTableCellBody sx={{cursor:"pointer"}}>{(page_no - 1) * appSetting.itemPerPage + i + 1}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.VoucherNo}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{ moment(data.Date).format("DD-MM-YYYY")}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.CreatedDate ? moment(data.CreatedDate).format("DD-MM-YYYY") : "-"}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.LedgerName}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.EmployeeName ? data.EmployeeName : '-'}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.GrossAmount)}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.TotalTax)}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.BillDiscount)}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.GrandTotal)}</VBTableCellBody>
              </TableRow>
            ))}
            <VBTableRowNull length = {reportData.data?.length}/>
          </TableBody>
          }
          <VBTableFooter>
            <TableRow>
              <VBTableCellFooter>{t('Total')}</VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>
                {formatNumber(reportData?.total?.GrossAmount)}
              </VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>
                {formatNumber(reportData?.total?.TotalTax)}
              </VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>
                {formatNumber(reportData?.total?.BillDiscount)}
              </VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>
                {formatNumber(reportData?.total?.GrandTotal)}
              </VBTableCellFooter>
            </TableRow>
          </VBTableFooter>
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={searchReport}
        setPage_no={setPage_no}
        handleDefaultFilter={()=>handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            {/* <ListItem>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Filter Name
                </InputLabel>
                <VBInputField />
              </FormControl>
            </ListItem> */}
            {/* <ListItem>
              <ViknAutoComplete
                value={filterOptions.date.value}
                disableClearable
                options={[
                  { name: "Custom" },
                  { name: "Today" },
                  { name: "This Week" },
                  { name: "This Month" },
                  { name: "Last 90 Days" },
                  { name: "This Year" },
                  { name: "Last Year" },
                ]}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) =>
                  handleAutoComplete(e, newValue, "date")
                }
              />
            </ListItem> */}
            <Divider />

          
            <ListItem>
              {/* <VBDatePiker
                handleAutoComplete={handleAutoComplete}
                dateValue={filterOptions.date.value}
                isDateRange={true}
                fromDate={filterOptions.date.FromDate}
                toDate={filterOptions.date.ToDate}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      FromDate: value,
                      value:{ name: "Custom" }
                    },
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      ToDate: value,
                      value:{ name: "Custom" }
                    },
                  })
                }
              /> */}


            {filterOptions.filterValue === "VoucherDate" ? (
              <VBDatePiker
                handleAutoComplete={handleAutoComplete}
                dateValue={filterOptions.date.value}
                isDateRange={true}
                fromDate={filterOptions.date.FromDate}
                toDate={filterOptions.date.ToDate}
                fromOnChange={(value) =>
                  setFilterOptions(prev => ({
                    ...prev,
                    date: {
                      ...prev.date,
                      FromDate: value,
                      value: { name: "Custom" }
                    },
                  }))
                }
                toOnChange={(value) =>
                  setFilterOptions(prev => ({
                    ...prev,
                    date: {
                      ...prev.date,
                      ToDate: value,
                      value: { name: "Custom" }
                    },
                  }))
                }
              />
            ) : (
              <VBDatetimepicker
                handleAutoComplete={handleAutoComplete}
                dateValue={filterOptions.date.value}
                isDateRange={true}
                fromDate={filterOptions.date.FromDate}
                toDate={filterOptions.date.ToDate}
                fromOnChange={(value) =>
                  setFilterOptions(prev => ({
                    ...prev,
                    date: {
                      ...prev.date,
                      FromDate: value,
                      value: { name: "Custom" }
                    },
                  }))
                }
                toOnChange={(value) =>
                  setFilterOptions(prev => ({
                    ...prev,
                    date: {
                      ...prev.date,
                      ToDate: value,
                      value: { name: "Custom" }
                    },
                  }))
                }
              />
            )}

            </ListItem>
            <ListItem>
              <ViknRadioGroup
                handleChange={handleChange}
                radios={filterOptions.searchDate.map((i) => ({
                  label: t(i.label),
                  value: i.name,
                }))}
                value={filterOptions.filterValue}
                valueName={"filterValue"}
              />
            </ListItem>
            <Divider />
            {isAdmin === true && EnableBranch && BranchID === 1 && (
              <ListItem>
                <ViknCheckboxGroup
                  radios={[
                    {
                      label: t("All Branches"),
                      checked: filterOptions.IsSelectedAllBranch,
                      onChange: () => branchCheckChange("IsSelectedAllBranch"),
                    },
                  ]}
                />
              </ListItem>
            )}
            {isAdmin === true && EnableBranch && BranchID === 1 && (
              <ListItem>
                <ViknAutoComplete
                  disabled={filterOptions.IsSelectedAllBranch}
                  multiple
                  value={filterOptions.SelectedBranches}
                  placeholder={"Select Branches"}
                  options={filterOptions.BranchList}
                  getOptionLabel={(option) => option.NickName}
                  onChange={(e, newValue) =>
                    handleAutoComplete(e, newValue, "SelectedBranches")
                  }
                />
              </ListItem>
            )}
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                placeholder={t("Customer")}
                filterName={t("Customer")}
                loading={isLoadingParty}
                options={filterOptions.partyList ? filterOptions.partyList : []}
                getOptionLabel={(option) => option.PartyName || ""}
                name="partyObj"
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    (option.PartyName && option.PartyName.toLowerCase().includes(inputValue.toLowerCase())) ||
                    (option.PartyCode && option.PartyCode.toLowerCase().includes(inputValue.toLowerCase()))
                  )
                }
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.PartyCode}</Box>
                    <Box sx={{fontSize: "14px",}}>{option.PartyName}</Box>
                  </Box>
                )}
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    handleSearchParty(event, value);
                  }
                  if (reason === "clear") {
                    handleSearchParty();
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "partyObj", -1)}
                value={filterOptions.partyObj}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                placeholder={t("User")}
                filterName={t("User")}
                loading={isLoadingUsers}
                options={filterOptions.userList ? filterOptions.userList : []}
                getOptionLabel={(option) => option.customer_name || ""}
                name="userObj"
                onInputChange={(event, value, reason) => {
                  //   if (reason === "input") {
                  //     handleSearchEmployee(event,value);
                  // }
                  if (reason === "clear") {
                    setFilterOptions({ ...filterOptions, userObj: {} });
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "userObj", -1)}
                value={filterOptions.userObj}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                placeholder={t("Sales man")}
                filterName={t("Sales man")}
                loading={isLoadingSalesman}
                options={
                  filterOptions.salesmanList ? filterOptions.salesmanList : []
                }
                getOptionLabel={(option) => option.LedgerName || ""}
                name="salesmanObj"
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    handleSearchEmployee(event, value);
                  }
                  if (reason === "clear") {
                    handleSearchEmployee();
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "salesmanObj", -1)}
                value={filterOptions.salesmanObj}
              />
            </ListItem>
            <Divider />
            {EnableRoute &&
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                multiple
                placeholder={t("Route Name")}
                filterName={t("Route Name")}
                loading={isLoadingSalesman}
                options={filterOptions.routeList ? filterOptions.routeList : []}
                getOptionLabel={(option) => option.RouteName || ""}
                name="routeListValue"
                // onInputChange={(event, value, reason) => {
                //   if (reason === "input") {
                //     handleSearchEmployee(event,value);
                //   }if(reason === "clear"){
                //     handleSearchEmployee()
                //   }
                // }}
                onChange={(e, v) =>
                  handleAutoComplete(e, v, "routeListValue", -1)
                }
                value={filterOptions.routeListValue}
              />
            </ListItem>
            }
            <Divider />
            <ListItem>
              <ViknRadioGroup
                handleChange={handleChange}
                radios={filterOptions.invoiceList.map((i) => ({
                  label: t(i.label),
                  value: i.name,
                }))}
                value={filterOptions.filterType}
                valueName={"filterType"}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknRadioGroup
                handleChange={handleChange}
                radios={filterOptions.billwiseList.map((i) => ({
                  label: t(i.label),
                  value: i.name,
                }))}
                value={filterOptions.filterValue}
                valueName={"filterValue"}
              />
            </ListItem>

            {/* <Divider /> */}
          </List>
        }
      />
    </>
  );
};

export default SalesReport;


const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap:"22px",
    
  },
}));
import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  Paper,
  Typography,
  styled
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
  VBTableRowNull,
} from "../../../../Components/ReportTable/ReportTable";
import FilterLable from "../../../../Components/Utils/FilterLable";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import moment from "moment";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { date_range, handleCashLedgers } from "../../../../Function/comonFunction";
import {
  CallLedgerListByGroup,
} from "../../../../Api/Reports/CommonReportApi";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import ViknRadioGroup from "../../../../Components/Utils/ViknRadioGroup";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import { CallCashBook } from "../../../../Api/Reports/AccountApi";
import { formatNumber } from "../../../../generalFunction";
import VBInputField from "../../../../Components/Utils/VBInputField";
import { useTranslation } from "react-i18next";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import LoaderLite from "../../../../Components/Utils/LoaderLite";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { useDispatch, useSelector } from "react-redux";
import { VIKNBOOKS_FRONT_URL } from "../../../../generalVeriable";
import { UserRolePermission } from "../../../../Function/Editions";

const CashBook = () => {
  let Export_view = UserRolePermission("Cash Book", "print_permission");
  const dispatch = useDispatch()
  const [t] = useTranslation("common");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState("Generating Report...");
  const [reportData, setReportData] = useState({ 
    count:0,
    data:[],
    total:{
      Receipts:0,
      Payments:0,
      Balance:0,
    },
  });
  const [loading, setLoading] = useState(false);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoadingLedger, setIsLoadingLedger] = useState(false);
  const [page_no, setPage_no] = useState(1);
  const [filterOptions, setFilterOptions] = useState({
    ledgerList: [],
    ledgerValue:[],
    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment(),
    },
    filterType: {name: "all_ledger", label: "All"},
    invoiceList: [
      { name: "all_ledger", label: "All" },
      { name: "ledger_wise", label: "Ledger Wise" },
    ],

    openingBalanceObj:{ name: "is_manualOpening", label: "Manual Opening Balance",is_manualOpening:false ,CashLedgers:[0],ManualOpeningBalance:""},
    is_manualOpening:false,
    ManualOpeningBalance:"",
    CashLedgers:[0],
  });

  //Handle functions

  const closeFilter = () => setOpenFilter(false);

  const searchReport = function () {
    fetchReport();
  };

  const handleDeletefilter = (name, index) => {
    if (name === "ledgerValue") {
      let ledgerValue = [...filterOptions.ledgerValue];
      ledgerValue = ledgerValue.filter((item, idx) => idx !== index);
      setFilterOptions((prevState) => ({
        ...prevState,
        ledgerValue: ledgerValue,
      }));
    } else {
      setFilterOptions((prevState) => ({ ...prevState, [name]: {} }));
    }
    // handlefilterList()
  };
  const handlefilterList = () => {
    let list = [];
    let filterdata = filterOptions
    if (filterdata.date.FromDate &&filterdata.date.ToDate ) {
        let date = String(filterOptions.date.FromDate.format("DD-MM-YYYY") + "  to " + filterOptions.date.ToDate.format("DD-MM-YYYY"))
        list.push({ name: t("Date"), value: date });
    }
    if (filterdata?.filterType.label) {
      list.push({ name: t("Filter Type"), value: filterdata.filterType.label });
    }
    if (filterdata.ledgerValue && filterdata.ledgerValue.length) {
      filterdata.ledgerValue.forEach((ledger, index) => {
          if (ledger.LedgerName !== "") {
              list.push({
                  name: t("Ledger Name"),
                  value: ledger.LedgerName,
                  handleDelete: () => handleDeletefilter("ledgerValue", index)
              });
          }
      });
  }
    return list;
  };

  const handleChange = (e, name) => {
    let { value } = e.target;
    if(name === "filterType"){
      let list = filterOptions.invoiceList.filter(i=>i.name ===value)
      setFilterOptions({ ...filterOptions, filterType: list[0] });
    }else if(name === "is_manualOpening"){
      let openingBalanceObj = filterOptions.openingBalanceObj
      openingBalanceObj.is_manualOpening=!openingBalanceObj.is_manualOpening
      openingBalanceObj.ManualOpeningBalance=""
      setFilterOptions({ ...filterOptions, openingBalanceObj});
    }else if(name === "ManualOpeningBalance"){
      let openingBalanceObj = filterOptions.openingBalanceObj
      openingBalanceObj.ManualOpeningBalance=Number(e.target.value)
      setFilterOptions({ ...filterOptions, openingBalanceObj});
    }
  };
  const handleDefaultFilter = async () => {
    setFilterOptions({
      ...filterOptions,
      ledgerValue:[],
      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Custom" },
        FromDate: moment(),
        ToDate: moment(),
      },
      filterType: {name: "all_ledger", label: "All"},

      openingBalanceObj:{ name: "is_manualOpening", label: "Manual Opening Balance",is_manualOpening:false ,CashLedgers:[0],ManualOpeningBalance:""},
      is_manualOpening:false,
      ManualOpeningBalance:"",
      CashLedgers:[0],
    })
  }


  const handleAutoComplete = function (e, value, name) {
    if (value) {
      if (name === "date") {
        if (value.name === "Custom") {
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: true,
              value: value,
            },
          });
        } else {
          let date = date_range(value.name);
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: false,
              value: value,
              ToDate: date.todate,
              FromDate: date.fromdate,
            },
          });
        }
      } else {
        setFilterOptions({ ...filterOptions, [name]: value });
      }
    }
  };
  // API Fetch data
  const fetchReport = async function () {
    setLoading(true)
    let CashLedgers = await handleCashLedgers(filterOptions)
    const data = await CallCashBook({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,
      FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
      CashLedgers,
      ManualOpeningBalance:filterOptions.openingBalanceObj.is_manualOpening?String(filterOptions.openingBalanceObj.ManualOpeningBalance):"",
      is_manualOpening:filterOptions.openingBalanceObj.is_manualOpening,
      VoucherType: filterOptions.filterType.name,
      items_per_page: 15,
      page_no: page_no,
      is_ExcludeTotal:true
    },dispatch);
    console.log("fetchReport===>",data);
    if (data?.StatusCode === 6000) {
      setReportData(data);
      // setPage()
    }else if (data?.StatusCode === 6001){
      setReportData()
      dispatch(
        openSnackbar({
          open : true,
          message : data.message,
          severity : 'warning',
        })
      )
    }
    closeFilter()
    setLoading(false)
  };

  const filterApis = async function () {
    setIsLoadingLedger(true);
    const LedgerResponse = await CallLedgerListByGroup({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,
      items_per_page: 10,
      page_no: 1,
      search: "",
      GroupUnder:9,
    });
    let ledgerList = [];
    if (LedgerResponse?.StatusCode === 6000) {
      ledgerList = LedgerResponse.data;
    }
console.log(LedgerResponse,"LedgerResponse--->");

    setFilterOptions({
      ...filterOptions,
      ledgerList,
    });
    setIsLoadingLedger(false);
  };

  useEffect(() => {
    filterApis();
  }, []);

  useEffect(() => {
    fetchReport()
  },[page_no])
  
  console.log(reportData, "reportData===>");
  console.log(filterOptions, "filterOptions===>");
  const handleTableRowClick = (id, VoucherType, VoucherNo) => {
    let destinationURL;
    let URL;
  
    switch (VoucherType) {
      case "SI":
        URL= `${VIKNBOOKS_FRONT_URL}dashboard/sale-invoice-single`;
       destinationURL = `${URL}?id=${id}`;
        break;
      case "SR":
        URL = `${VIKNBOOKS_FRONT_URL}dashboard/sales-return-preview`;
        destinationURL = `${URL}?&id=${id}`;
        break;
      case "PI":
        URL = `${VIKNBOOKS_FRONT_URL}dashboard/purchase-invoice-single`;
        destinationURL = `${URL}?id=${id}`;
        break;
      case "PR":
        URL = `${VIKNBOOKS_FRONT_URL}dashboard/purchase-return-single`;
        destinationURL = `${URL}?id=${id}`;
        break;
      case "CR":
      case "BR":
        destinationURL = `${VIKNBOOKS_FRONT_URL}dashboard/receipt-single?boo=true&is_print=${true}&id=${id}`;
        break;
      case "CP":
      case "BP":
        destinationURL = `${VIKNBOOKS_FRONT_URL}dashboard/payment-single?boo=true&is_print=${true}&id=${id}`;
        break;
      case "LOB":
        URL = `${VIKNBOOKS_FRONT_URL}dashboard/account-ledgers`;
        destinationURL = `${URL}?unq_id=${id}`;
        break;
      case "OS":
        URL = `${VIKNBOOKS_FRONT_URL}dashboard/create-opening-stock`;
        destinationURL = `${URL}?unq_id=${id}`;
        break;
      case "JL":
        URL = `${VIKNBOOKS_FRONT_URL}dashboard/create-journal`;
        destinationURL = `${URL}?unq_id=${id}`;
        break;
      case "EX":
        URL = `${VIKNBOOKS_FRONT_URL}dashboard/expense-single`;
        destinationURL = `${URL}?&id=${id}`;
        break;
      default:
        URL = `${VIKNBOOKS_FRONT_URL}dashboard/sale-invoice-single`;
        destinationURL = `${URL}?unq_id=${id}`;
    }
  
    window.open(destinationURL, '_blank');
  };
  return (
    <>
    {progress > 0 ?
      <LoaderLite message={((progress<=100)?("Generating Report... " + progress + "%"):"Creating File... ")} progress={progress} fullscreen={true} />
      :null}
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t("Cash Book")}
            </Typography>
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className="right">
            {Export_view ? 
            <ReportDownload
            download_type={"PDF"}
            invoice_type={"cash_book"}
            VoucherType={filterOptions.filterType.name
            }
            is_celery={true}
            setProgress={setProgress}
            setProgressMessage={setProgressMessage}
            FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
            ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
            RouteLedgers={
              filterOptions.CashLedgers
            }
            value={9}
            ManualOpeningBalance={filterOptions.openingBalanceObj.is_manualOpening?filterOptions.openingBalanceObj.ManualOpeningBalance:""}
            is_manualOpening={filterOptions.openingBalanceObj.is_manualOpening}
            // handleRouteList={handleCashLedgers(filterOptions)}
            filterOptions={filterOptions}
            isCashLedgers={true}
          />
          : null}
            <Pagenation
              totalItem={reportData.count}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
            alignItems:"center"
          }}
        >
            <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>

            {t("Applied Filters: ")}
            </div>
        {handlefilterList(filterOptions).map((val, i) => (
        <FilterLable
          name={val.name}
          value={val.value}
          handleDelete={val.handleDelete?val.handleDelete:null}
        />
      ))}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("Serial No")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher No")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher Date")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher Type")}	 </VBTableCellHeader>
            <VBTableCellHeader>{t("Particulars")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Ledger Name")} </VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t("Receipts")} </VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t("Payments")} </VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t("Balance")} </VBTableCellHeader>
          </VBTableHeader>
          {loading?
          <VBSkeletonLoader/>
          :
          <TableBody>
            {reportData?.data?.map((data, i) => (
              <TableRow
              onClick={() =>
                handleTableRowClick(data.id, data.VoucherType, data.VoucherNo)
              }
              >
                <VBTableCellBody sx={{cursor:"pointer"}}>{(page_no - 1) * appSetting.itemPerPage + i + 1 }</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.VoucherNo}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.Date}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.VoucherTypeName}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.Particulars}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.LedgerName}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.Receipts)}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.Payments)}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.Balance)}</VBTableCellBody>
              </TableRow>
            ))}
            <VBTableRowNull length = {reportData.data?.length}/>
          </TableBody>
          }
          <VBTableFooter>
            <TableRow>
              <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>
                {formatNumber(reportData?.total?.Receipts)}
              </VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}> 
                {formatNumber(reportData?.total?.Payments)}
              </VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}} align="right">
                {formatNumber(reportData?.total?.Balance)}
              </VBTableCellFooter>
            </TableRow>
          </VBTableFooter>
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={searchReport}
        setPage_no={setPage_no}
        handleDefaultFilter={()=>handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            {/* <ListItem>
              <ViknAutoComplete
                value={filterOptions.date.value}
                disableClearable
                options={[
                  { name: "Custom" },
                  { name: "Today" },
                  { name: "This Week" },
                  { name: "This Month" },
                  { name: "Last 90 Days" },
                  { name: "This Year" },
                  { name: "Last Year" },
                ]}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) =>
                  handleAutoComplete(e, newValue, "date")
                }
              />
            </ListItem> */}

            <ListItem>
              <VBDatePiker
              handleAutoComplete={handleAutoComplete}
              dateValue={filterOptions.date.value}
              isDateRange={true}
                fromDate={filterOptions.date.FromDate}
                toDate={filterOptions.date.ToDate}
                // fromReadOnly={filterOptions.date.value.name !== "Custom"}
                // toReadOnly={filterOptions.date.value.name !== "Custom"}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      FromDate: value,
                      value:{ name: "Custom" }
                    },
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      ToDate: value,
                      value:{ name: "Custom" }
                    },
                  })
                }
              />
            </ListItem>
            <ListItem>
              <ViknCheckboxGroup
                radios={
                  [{
                    label : t("Manual Opening Balance"),
                    checked:filterOptions.openingBalanceObj.is_manualOpening,
                    onChange:(e)=>handleChange(e,"is_manualOpening")
                  }]
                }
              />
            </ListItem>
            {filterOptions.openingBalanceObj.is_manualOpening === true &&
            <ListItem>
              <VBInputField
                freeSolo
                placeholder={t("Manual Opening Balance")}
                filterName="ManualOpeningBalance"
                name="ManualOpeningBalance"
                type="number"
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  ["e", "E", "-",].includes(e.key) &&
                  e.preventDefault()
                }
                onChange={(e, v) => handleChange(e, "ManualOpeningBalance")}
                value={filterOptions.openingBalanceObj.ManualOpeningBalance}
              />
            </ListItem>
            }
            <ListItem>
              <ViknRadioGroup
                handleChange={(e)=>handleChange(e,"filterType")}
                radios={filterOptions.invoiceList.map((i) => ({
                  label: t(i.label),
                  value: i.name,
                }))}
                value={filterOptions.filterType.name}
                valueName={"filterType"}
              />
            </ListItem>
            {filterOptions.filterType.label !=="All" &&
            <ListItem>
               <ViknSearchAutoComplete
                freeSolo
                multiple
                placeholder={t("Ledger Name")}
                filterName={t("Ledger Name")}
                loading={isLoadingLedger}
                options={filterOptions.ledgerList ? filterOptions.ledgerList : []}
                getOptionLabel={(option) => option.LedgerName || ""}
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    (option.LedgerName && option.LedgerName.toLowerCase().includes(inputValue.toLowerCase())) ||
                    (option.LedgerCode && option.LedgerCode.toLowerCase().includes(inputValue.toLowerCase()))
                  )
                }
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.LedgerCode}</Box>
                    <Box sx={{fontSize: "14px",}}>{option.LedgerName}</Box>
                  </Box>
                )}
                name="ledgerValue"
                // onInputChange={(event, value, reason) => {
                //   if (reason === "input") {
                //     handleSearchEmployee(event,value);
                //   }if(reason === "clear"){
                //     handleSearchEmployee()
                //   }
                // }}
                onChange={(e, v) =>
                  handleAutoComplete(e, v, "ledgerValue", -1)
                }
                value={filterOptions.ledgerValue}
              />
            </ListItem>
            }
          </List>
        }
      />
    </>
  );
};

export default CashBook;


const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap:"22px",
  },
}));
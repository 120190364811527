import axios from "axios";
import { openSnackbar } from "../../features/SnackBarslice";
import { store } from '../../app/store';

const state = store.getState();
const { PriceDecimalPoint } = state.generalSettingsSclice.generalSettings;

const callPurchaseReport = async function (body, dispatch) {
  try {
    const { data } = await axios.post("api/v10/purchases/report/purchases/", {
      
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const callPurchaseOrderReport = async function (body, dispatch) {
  try {
    const { data } = await axios.post(
      "api/v10/purchaseOrders/report/purchaseOrder/",
      {
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const callPurchaseSummary = async function (body, dispatch) {
  try {
    const { data } = await axios.post("api/v11/reports/Purchase-report/", body);
    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const callPurchaseRegister = async function (body, dispatch) {
  try {
    const { data } = await axios.post(
      "api/v10/purchases/purchase-register/report-qry/",
      {
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const callPurchaseReturnRegister = async function (body, dispatch) {
  try {
    const { data } = await axios.post(
      "api/v10/purchaseReturns/purchaseReturn-register/report-qry/",
      {
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const callPurchaseInteratedReport = async function (body, dispatch) {
  try {
    const { data } = await axios.post(
      "api/v10/purchases/purchase/integrated-report/",
      {
       
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const callSupplierVsProduct = async function (body, dispatch) {
  try {
    const { data } = await axios.post(
      "api/v10/sales/supplierVsproduct/report/",
      {
       
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const callSearchAccountLedgers = async function (body, dispatch) {
  try {
    const { data } = await axios.post(
      "api/v10/accountLedgers/search-accountLedgers/",
      {
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    store.dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In callSearchAccountLedgers API",
        severity: "error",
      })
    );
    return error
  }
};
const callSearchAccountLedgersReport = async function (body, dispatch) {
  try {
    const { data } = await axios.post(
      "api/v11/accountLedgers/search-accountLedgers/report/",
      {
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    store.dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In callSearchAccountLedgers API",
        severity: "error",
      })
    );
    return error
  }
};

const callProductCodeSearch = async function (body, dispatch) {
  try {
    const { data } = await axios.post("api/v10/products/productCode-search/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
    return error
  }
};

export {
  callPurchaseInteratedReport,
  callPurchaseOrderReport,
  callPurchaseRegister,
  callPurchaseSummary,
  callPurchaseReturnRegister,
  callPurchaseReport,
  callSearchAccountLedgers,
  callProductCodeSearch,
  callSupplierVsProduct,
  callSearchAccountLedgersReport,
};

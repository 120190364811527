import React from 'react';
import { Typography, Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';

function SummaryDetails({ state }) {
  const { PriceDecimalPoint } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );

  const items = [
    { label: "Number of Products", value: state.TotalProducts },
    { label: "Total QTY", value: state.TotalQty },
    { label: "Grand Total", value: state.GrandTotal.toFixed(PriceDecimalPoint), isGrandTotal: true },
  ];

  return (
    <Box width="300px" p={2} borderRadius="8px">
      <Table>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={index} style={{background:"none"}}>
              <TableCell sx={{ borderBottom: 'none',padding:"5px"}}>
                <Typography
                  sx={{ fontSize: item.isGrandTotal ? "20px" : "13px", fontWeight: "500" }}
                >
                  {item.label}
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none',padding:"5px" }}>
                <Typography
                  sx={{ fontSize: item.isGrandTotal ? "15px" : "13px", fontWeight: "500" }}
                >
                  -
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none',padding:"5px" }}>
                <Typography
                  sx={{ fontSize: item.isGrandTotal ? "15px" : "13px", fontWeight: "500" }}
                >
                  {item.value}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default SummaryDetails;

import './Css/Header.css'
import React, { useRef,useState ,useEffect} from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { useLocation } from "react-router-dom";
import { Box, TextField } from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import { BASE_V11_URL, Report_URL, VIKNBOOKS_FRONT_URL, } from '../../../generalVeriable';
import { selectBranchID, selectCompanyID } from '../../../features/companyDetailsSlice';
import { Icons } from '../../../Assets/AssetLog';

const scrollLoop = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-120%);
  }
`;
const QuickMenu = (props) => {
  const location = useLocation();
  const [t, i18n] = useTranslation("common");
  const nodeRef = useRef(null);
  const { Edition, BusinessTypeName } = useSelector((state) => state.companyDetails);
  const { isAuth, access, user_id } = useSelector((state) => state.user);
  const BranchID = useSelector(selectBranchID);
  const CompanyID = useSelector(selectCompanyID);
  const [ is_Edit, setIs_Edit]  = useState(false)
  const [ is_Remove, setIs_Remove]  = useState(false)
  const [ srcList, setSrcList]  = useState([
      {id:1,Name:"Sales",Src:Icons.Sales},
      {id:2,Name:"Purchase",Src:Icons.PurchaseInvoice},
      {id:3,Name:"Payment",Src:Icons.Payment},
      {id:4,Name:"Estimate",Src:Icons.Estimate},
      {id:5,Name:"Order",Src:Icons.Order},
      {id:6,Name:"Supplier",Src:Icons.Supplier},
      {id:7,Name:"Return",Src:Icons.Return},
      {id:8,Name:"Customer",Src:Icons.Customer},
      {id:9,Name:"Expense",Src:Icons.Expense},
      {id:10,Name:"Journal",Src:Icons.Journal},
      {id:11,Name:"Report",Src:Icons.Report},
      {id:12,Name:"Employee",Src:Icons.Employee},
      {id:13,Name:"Products",Src:Icons.Products},
      {id:14,Name:"OpeningStock",Src:Icons.OpeningStock},
      {id:15,Name:"AccountLedger",Src:Icons.AccountLedger},
      {id:16,Name:"Bank",Src:Icons.Bank},
      {id:17,Name:"TaxCategory",Src:Icons.Tax},
      {id:18,Name:"units",Src:Icons.UNITS},
      {id:19,Name:"Stock Order",Src:Icons.StockOrder},
      {id:20,Name:"sales bt",Src:Icons.SalesBT},
      {id:21,Name:"purchase bt",Src:Icons.PurchaseBT},
      {id:22,Name:"PurchaseReturn",Src:Icons.PurchaseReturn},
      {id:23,Name:"PurchaseOrder",Src:Icons.PurchaseOrder},
      {id:24,Name:"Recipt",Src:Icons.Receipt},
      {id:25,Name:"Manufacturing",Src:Icons.Manufacturing},
      {id:26,Name:"Delivery Note",Src:Icons.DeliveryNote},
      {id:27,Name:"work order",Src:Icons.WorkOrder},
      {id:28,Name:"Stock Transfer",Src:Icons.StockTransfer},
      {id:29,Name:"Stock Management",Src:Icons.StockManagement},
      {id:30,Name:"Opening Stock",Src:Icons.OpeningStock},
  ])
  const [ list, setList]  = useState({
    items:Edition === "Lite"?
    [
      {Link:"/dashboard/create-sale-invoice",Name:"Sales",id:1},
      {Link:"/dashboard/create-purchase-invoice",Name:"Purchase",id:2},
      {Link:"/dashboard/create-payment-voucher",Name:"Payment",id:3},
      {Link:"/dashboard/create-purchase-order",Name:"Purchase Order",id:23},
      {Link:"/dashboard/create-supplier",Name:"Supplier",id:6},
      {Link:"/dashboard/create-sales-return",Name:"Sales Return",id:7},
      {Link:"/dashboard/create-purchase-return",Name:"Purchase Return",id:22},
      {Link:"/dashboard/create-customer",Name:"Customer",id:8},
      {Link:"/dashboard/create-employee",Name:"Employee",id:12},
      {Link:"/dashboard/create-journal",Name:"Journal",id:10},
      {Link:"/dashboard/create-products",Name:"Products",id:13},
      {Link:"/dashboard/create-opening-stock",Name:"Opening Stock",id:14},
      {Link:"/dashboard/account-ledgers",Name:"Account Ledgers",id:15},
      {Link:"/dashboard/banks",Name:"Banks",id:16},
      {Link:"/dashboard/tax-categories",Name:"Tax Categories",id:17},
      {Link:"/dashboard/units",Name:"Units",id:18},
      {Link:"/dashboard/receipts",Name:"Receipt",id:24},
      {Link:"/dashboard/create-opening-stock",Name:"Opening Stock",id:30},
      {Link:"/dashboard/branch-transfer-report",Name:"Branch Transfer Report",id:11},
      {Link:"/dashboard/day-report",Name:"Day Book",id:11},
      {Link:"/dashboard/sales-report",Name:"Sales Report",id:11},
      {Link:"/dashboard/sales-summery-report",Name:"Sales Summery Report",id:11},
      {Link:"/dashboard/",Name:"Report",id:11},
      {Link:"/dashboard/stock-report",Name:"Stock Report",id:11},
      {Link:"/dashboard/product-serialisation-report",Name:"Product Serialisation Report",id:11},
      {Link:"/dashboard/opening-stock-report",Name:"Opening Stock Report",id:11},
      {Link:"/dashboard/day-report",Name:"Day Report",id:11},
      {Link:"/dashboard/cashbook",Name:"Cash Book",id:11},
      {Link:"/dashboard/bankbooks",Name:"Bank Book",id:11},
      {Link:"/dashboard/receipt-report",Name:"Receipt Report",id:11},
      {Link:"/dashboard/payment-report",Name:"Payment Report",id:11},
      {Link:"/dashboard/ledger-report",Name:"Ledger Report",id:11},
      {Link:"/dashboard/tax-summery",Name:"Tax Summary",id:11},
      {Link:"/dashboard/sales-report",Name:"Sales Report",id:11},
      {Link:"/dashboard/purchase-report",Name:"Purchase Report",id:11},
      {Link:"/dashboard/purchase-order-report",Name:"Purchase Order Report",id:11},
      ]
    :
    Edition === "Essential"?[
      {Link:"/dashboard/create-sale-invoice",Name:"Sales",id:1},
      {Link:"/dashboard/create-purchase-invoice",Name:"Purchase",id:2},
      {Link:"/dashboard/create-payment-voucher",Name:"Payment",id:3},
      {Link:"/dashboard/create-sales-estimate",Name:"Sales Estimate",id:4},
      {Link:"/dashboard/create-sales-order",Name:"Sales Order",id:5},
      {Link:"/dashboard/create-purchase-order",Name:"Purchase Order",id:23},
      {Link:"/dashboard/create-supplier",Name:"Supplier",id:6},
      {Link:"/dashboard/create-sales-return",Name:"Sales Return",id:7},
      {Link:"/dashboard/create-purchase-return",Name:"Purchase Return",id:22},
      {Link:"/dashboard/create-customer",Name:"Customer",id:8},
      {Link:"/dashboard/create-employee",Name:"Employee",id:12},
      {Link:"/dashboard/create-expense",Name:"Expense",id:9},
      {Link:"/dashboard/create-journal",Name:"Journal",id:10},
      {Link:"/dashboard/create-products",Name:"Products",id:13},
      {Link:"/dashboard/create-opening-stock",Name:"Opening Stock",id:14},
      {Link:"/dashboard/account-ledgers",Name:"Account Ledgers",id:15},
      {Link:"/dashboard/banks",Name:"Banks",id:16},
      {Link:"/dashboard/tax-categories",Name:"Tax Categories",id:17},
      {Link:"/dashboard/units",Name:"Units",id:18},
      {Link:"/dashboard/receipts",Name:"Receipt",id:24},
      {Link:"/dashboard/create-delivery-note",Name:"Delivery Note",id:26},
      {Link:"/dashboard/create-opening-stock",Name:"Opening Stock",id:30},
      {Link:"/dashboard/trial-balance",Name:"Trial Balance",id:11},
      {Link:"/dashboard/profit-and-loss-account",Name:"profit and loss account",id:11},
      {Link:"/dashboard/balance-sheet",Name:"balance sheet",id:11},
      {Link:"/dashboard/stock-value-report",Name:"Stock Value Report",id:11},
      {Link:"/dashboard/stock-ledger-report",Name:"Stock Ledger Report",id:11},
      {Link:"/dashboard/branch-transfer-report",Name:"Branch Transfer Report",id:11},
      {Link:"/dashboard/day-report",Name:"Day Book",id:11},
      {Link:"/dashboard/expense-report",Name:"expense report",id:11},
      {Link:"/dashboard/daily-report",Name:"Daily Report",id:11},
      {Link:"/dashboard/sales-report",Name:"Sales Report",id:11},
      {Link:"/dashboard/sales-order-report",Name:"sales order report",id:11},
      {Link:"/dashboard/sales-summery-report",Name:"Sales Summery Report",id:11},
      {Link:"/dashboard/",Name:"Report",id:11},
      {Link:"/dashboard/stock-report",Name:"Stock Report",id:11},
      {Link:"/dashboard/product-serialisation-report",Name:"Product Serialisation Report",id:11},
      {Link:"/dashboard/opening-stock-report",Name:"Opening Stock Report",id:11},
      {Link:"/dashboard/day-report",Name:"Day Report",id:11},
      {Link:"/dashboard/cashbook",Name:"Cash Book",id:11},
      {Link:"/dashboard/bankbooks",Name:"Bank Book",id:11},
      {Link:"/dashboard/receipt-report",Name:"Receipt Report",id:11},
      {Link:"/dashboard/payment-report",Name:"Payment Report",id:11},
      {Link:"/dashboard/ledger-report",Name:"Ledger Report",id:11},
      {Link:"/dashboard/tax-summery",Name:"Tax Summary",id:11},
      {Link:"/dashboard/sales-report",Name:"Sales Report",id:11},
      {Link:"/dashboard/purchase-report",Name:"Purchase Report",id:11},
      {Link:"/dashboard/purchase-order-report",Name:"Purchase Order Report",id:11},

    ]
    :
    Edition === "Standard"?[
      {Link:"/dashboard/create-sale-invoice",Name:"Sales",id:1},
      {Link:"/dashboard/create-purchase-invoice",Name:"Purchase",id:2},
      {Link:"/dashboard/create-payment-voucher",Name:"Payment",id:3},
      {Link:"/dashboard/create-sales-estimate",Name:"Sales Estimate",id:4},
      {Link:"/dashboard/create-sales-order",Name:"Sales Order",id:5},
      {Link:"/dashboard/create-purchase-order",Name:"Purchase Order",id:23},
      {Link:"/dashboard/create-supplier",Name:"Supplier",id:6},
      {Link:"/dashboard/create-sales-return",Name:"Sales Return",id:7},
      {Link:"/dashboard/create-purchase-return",Name:"Purchase Return",id:22},
      {Link:"/dashboard/create-customer",Name:"Customer",id:8},
      {Link:"/dashboard/create-employee",Name:"Employee",id:12},
      {Link:"/dashboard/create-expense",Name:"Expense",id:9},
      {Link:"/dashboard/create-journal",Name:"Journal",id:10},
      {Link:"/dashboard/create-products",Name:"Products",id:13},
      {Link:"/dashboard/create-opening-stock",Name:"Opening Stock",id:14},
      {Link:"/dashboard/account-ledgers",Name:"Account Ledgers",id:15},
      {Link:"/dashboard/banks",Name:"Banks",id:16},
      {Link:"/dashboard/tax-categories",Name:"Tax Categories",id:17},
      {Link:"/dashboard/units",Name:"Units",id:18},
      {Link:"/dashboard/create-stock-order",Name:"Stock Order",id:19},
      {Link:"/dashboard/receipts",Name:"Receipt",id:24},
      {Link:"/dashboard/create-sale-invoice-bt",Name:"Sale BT",id:20},
      {Link:"/dashboard/purchase-sale-invoice-bt",Name:"Purchase BT",id:21},
      {Link:"/dashboard/create-delivery-note",Name:"Delivery Note",id:26},
      {Link:"/dashboard/create-work-order",Name:"work order",id:27},
      {Link:"/dashboard/create-stock-transfer",Name:"Stock Transfer",id:28},
      {Link:"/dashboard/create-stock-management",Name:"Stock Management",id:29},
      {Link:"/dashboard/create-opening-stock",Name:"Opening Stock",id:30},
      {Link:"/dashboard/trial-balance",Name:"Trial Balance",id:11},
      {Link:"/dashboard/profit-and-loss-account",Name:"profit and loss account",id:11},
      {Link:"/dashboard/balance-sheet",Name:"balance sheet",id:11},
      {Link:"/dashboard/outstanding-report",Name:"Outstanding Report",id:11},
      {Link:"/dashboard/expense-summary",Name:"expense summary",id:11},
      {Link:"/dashboard/stock-value-report",Name:"Stock Value Report",id:11},
      {Link:"/dashboard/product-summary",Name:"Product Summary Report",id:11},
      {Link:"/dashboard/stock-ledger-report",Name:"Stock Ledger Report",id:11},
      {Link:"/dashboard/branch-transfer-report",Name:"Branch Transfer Report",id:11},
      {Link:"/dashboard/day-report",Name:"Day Book",id:11},
      {Link:"/dashboard/expense-report",Name:"expense report",id:11},
      {Link:"/dashboard/daily-report",Name:"Daily Report",id:11},
      {Link:"/dashboard/billwise-profit-report",Name:"Billwise Profit Report",id:11},
      {Link:"/dashboard/billwise-report",Name:"Billwise Report",id:11},
      {Link:"/dashboard/quick-report",Name:"Quick Report",id:11},
      {Link:"/dashboard/debtor-ageing-report",Name:"Debtor Ageing report",id:11},
      {Link:"/dashboard/creditor-ageing-report",Name:"Creditor Ageing report",id:11},
      {Link:"/dashboard/sales-gst-report",Name:"Sales GST Report",id:11},
      {Link:"/dashboard/gst-purchase-report",Name:"GST Purchase Report",id:11},
      {Link:"/dashboard/sales-report",Name:"Sales Report",id:11},
      {Link:"/dashboard/sales-order-report",Name:"sales order report",id:11},
      {Link:"/dashboard/sales-summery-report",Name:"Sales Summery Report",id:11},
      {Link:"/dashboard/sales-register-report",Name:"Sales Register Report",id:11},
      {Link:"/dashboard/sales-return-register-report",Name:"Sales Return Register Report",id:11},
      {Link:"/dashboard/sales-integrated-report",Name:"Sales Integrated Report",id:11},
      {Link:"/dashboard/purchase-register-report",Name:"Purchase Register Report",id:11},
      {Link:"/dashboard/supplier-vs-product-report",Name:"Supplier Vs Product Report",id:11},
      {Link:"/dashboard/purchase-integrated-report",Name:"Purchase Integrated Report",id:11},
      {Link:"/dashboard/",Name:"Report",id:11},
      {Link:"/dashboard/stock-report",Name:"Stock Report",id:11},
      {Link:"/dashboard/product-serialisation-report",Name:"Product Serialisation Report",id:11},
      {Link:"/dashboard/opening-stock-report",Name:"Opening Stock Report",id:11},
      {Link:"/dashboard/day-report",Name:"Day Report",id:11},
      {Link:"/dashboard/cashbook",Name:"Cash Book",id:11},
      {Link:"/dashboard/bankbooks",Name:"Bank Book",id:11},
      {Link:"/dashboard/receipt-report",Name:"Receipt Report",id:11},
      {Link:"/dashboard/payment-report",Name:"Payment Report",id:11},
      {Link:"/dashboard/ledger-report",Name:"Ledger Report",id:11},
      {Link:"/dashboard/tax-summery",Name:"Tax Summary",id:11},
      {Link:"/dashboard/sales-report",Name:"Sales Report",id:11},
      {Link:"/dashboard/purchase-report",Name:"Purchase Report",id:11},
      {Link:"/dashboard/purchase-order-report",Name:"Purchase Order Report",id:11},
      {Link:"/dashboard/stock-transfer-register-report",Name:"Stock Transfer Register Report",id:11},
    ]
    :
    [
      {Link:"/dashboard/create-sale-invoice",Name:"Sales",id:1},
      {Link:"/dashboard/create-purchase-invoice",Name:"Purchase",id:2},
      {Link:"/dashboard/create-payment-voucher",Name:"Payment",id:3},
      {Link:"/dashboard/create-sales-estimate",Name:"Sales Estimate",id:4},
      {Link:"/dashboard/create-sales-order",Name:"Sales Order",id:5},
      {Link:"/dashboard/create-purchase-order",Name:"Purchase Order",id:23},
      {Link:"/dashboard/create-supplier",Name:"Supplier",id:6},
      {Link:"/dashboard/create-sales-return",Name:"Sales Return",id:7},
      {Link:"/dashboard/create-purchase-return",Name:"Purchase Return",id:22},
      {Link:"/dashboard/create-customer",Name:"Customer",id:8},
      {Link:"/dashboard/create-employee",Name:"Employee",id:12},
      {Link:"/dashboard/create-expense",Name:"Expense",id:9},
      {Link:"/dashboard/create-journal",Name:"Journal",id:10},
      {Link:"/dashboard/create-products",Name:"Products",id:13},
      {Link:"/dashboard/create-opening-stock",Name:"Opening Stock",id:14},
      {Link:"/dashboard/account-ledgers",Name:"Account Ledgers",id:15},
      {Link:"/dashboard/banks",Name:"Banks",id:16},
      {Link:"/dashboard/tax-categories",Name:"Tax Categories",id:17},
      {Link:"/dashboard/units",Name:"Units",id:18},
      {Link:"/dashboard/create-stock-order",Name:"Stock Order",id:19},
      {Link:"/dashboard/receipts",Name:"Receipt",id:24},
      {Link:"/dashboard/create-sale-invoice-bt",Name:"Sale BT",id:20},
      {Link:"/dashboard/purchase-sale-invoice-bt",Name:"Purchase BT",id:21},
      {Link:"/dashboard/create-manufacturing",Name:"Manufacturing",id:25},
      {Link:"/dashboard/create-delivery-note",Name:"Delivery Note",id:26},
      {Link:"/dashboard/create-work-order",Name:"work order",id:27},
      {Link:"/dashboard/create-stock-transfer",Name:"Stock Transfer",id:28},
      {Link:"/dashboard/create-stock-management",Name:"Stock Management",id:29},
      {Link:"/dashboard/create-opening-stock",Name:"Opening Stock",id:30},

      {Link:"/dashboard/trial-balance",Name:"Trial Balance",id:11},
      {Link:"/dashboard/profit-and-loss-account",Name:"profit and loss account",id:11},
      {Link:"/dashboard/balance-sheet",Name:"balance sheet",id:11},
      {Link:"/dashboard/outstanding-report",Name:"Outstanding Report",id:11},
      {Link:"/dashboard/expense-summary",Name:"expense summary",id:11},
      {Link:"/dashboard/stock-value-report",Name:"Stock Value Report",id:11},
      {Link:"/dashboard/product-summary",Name:"Product Summary Report",id:11},
      {Link:"/dashboard/stock-ledger-report",Name:"Stock Ledger Report",id:11},
      {Link:"/dashboard/branch-transfer-report",Name:"Branch Transfer Report",id:11},
      {Link:"/dashboard/day-report",Name:"Day Book",id:11},
      {Link:"/dashboard/expense-report",Name:"expense report",id:11},
      {Link:"/dashboard/daily-report",Name:"Daily Report",id:11},
      {Link:"/dashboard/billwise-profit-report",Name:"Billwise Profit Report",id:11},
      {Link:"/dashboard/bank-reconciliation-report",Name:"Bank Reconciliation Report",id:11},
      {Link:"/dashboard/billwise-report",Name:"Billwise Report",id:11},
      {Link:"/dashboard/quick-report",Name:"Quick Report",id:11},
      {Link:"/dashboard/debtor-ageing-report",Name:"Debtor Ageing report",id:11},
      {Link:"/dashboard/creditor-ageing-report",Name:"Creditor Ageing report",id:11},
      {Link:"/dashboard/sales-gst-report",Name:"Sales GST Report",id:11},
      {Link:"/dashboard/gst-purchase-report",Name:"GST Purchase Report",id:11},
      {Link:"/dashboard/sales-report",Name:"Sales Report",id:11},
      {Link:"/dashboard/sales-order-report",Name:"sales order report",id:11},
      {Link:"/dashboard/sales-summery-report",Name:"Sales Summery Report",id:11},
      {Link:"/dashboard/sales-register-report",Name:"Sales Register Report",id:11},
      {Link:"/dashboard/sales-return-register-report",Name:"Sales Return Register Report",id:11},
      {Link:"/dashboard/sales-integrated-report",Name:"Sales Integrated Report",id:11},
      {Link:"/dashboard/purchase-register-report",Name:"Purchase Register Report",id:11},
      {Link:"/dashboard/purchase-return-register-report",Name:"Purchase Return Register Report",id:11},
      {Link:"/dashboard/supplier-vs-product-report",Name:"Supplier Vs Product Report",id:11},
      {Link:"/dashboard/purchase-integrated-report",Name:"Purchase Integrated Report",id:11},
      {Link:"/dashboard/",Name:"Report",id:11},
      {Link:"/dashboard/stock-report",Name:"Stock Report",id:11},
      {Link:"/dashboard/product-serialisation-report",Name:"Product Serialisation Report",id:11},
      {Link:"/dashboard/opening-stock-report",Name:"Opening Stock Report",id:11},
      {Link:"/dashboard/day-report",Name:"Day Report",id:11},
      {Link:"/dashboard/cashbook",Name:"Cash Book",id:11},
      {Link:"/dashboard/bankbooks",Name:"Bank Book",id:11},
      {Link:"/dashboard/receipt-report",Name:"Receipt Report",id:11},
      {Link:"/dashboard/payment-report",Name:"Payment Report",id:11},
      {Link:"/dashboard/ledger-report",Name:"Ledger Report",id:11},
      {Link:"/dashboard/tax-summery",Name:"Tax Summary",id:11},
      {Link:"/dashboard/sales-report",Name:"Sales Report",id:11},
      {Link:"/dashboard/purchase-report",Name:"Purchase Report",id:11},
      {Link:"/dashboard/purchase-order-report",Name:"Purchase Order Report",id:11},
      {Link:"/dashboard/stock-transfer-register-report",Name:"Stock Transfer Register Report",id:11},
      ],
    shortList:[],
    QuickMenuList:[]
  })
  const checkEdition = (link)=>{
    console.log(link);
    let check =false
    list.items.filter((i)=>{
      if(i.Link===link){
      check = true
      }
      
    })
return check
  }
  let is_sales_estimate = true;
  let is_sales_order = true;
  if (Edition === "Lite") {
    is_sales_estimate = false;
    is_sales_order = false;
  }
  useEffect(()=>{
    if(props.showQuickMenu===false){
      setIs_Edit(false)
    }

  },[props.showQuickMenu])
  const fetchData = async () => {
    try {
      let data = [];
      if(access){
        fetch(`${BASE_V11_URL}quickMenu/list-quick-menu/`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access}`,
            // "accept": "application/json"
          },
          body: JSON.stringify({
            CompanyID: CompanyID,
            BranchID: BranchID,
            User_id:user_id,
          }),
        }).then((response) => response.json())
        .then((response) => {
          if (response.StatusCode === 6000) {
            data=response.data
            let lists=list.items
            setList({
              ...list,
              shortList: lists,
              QuickMenuList:data
            });
          }
          else{
            setList({
              ...list,
              QuickMenuList:[]
            });
            console.log("nolist");
          }
        })
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error if needed
    }
  };
  useEffect(() => {
    props.setQuickMenu(false)
  }, [location.pathname]);
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if(props?.showQuickMenu===false)
   { setIs_Remove(false)}
  }, [props?.showQuickMenu]);

  // const handleSearch = (e) => {
  //   const value = e?.target?.value;
  //   let filteredList = [];
  
  //   if (value) {
  //     filteredList = list.items.filter((item) =>
  //       item.Name.toLowerCase().includes(value.toLowerCase())
  //     ).filter((item) => item.Name !== list.QuickMenuList[0].PageName);
  //   } else {
  //     filteredList = list.items.filter((item) => item.Name !== list.QuickMenuList[0].PageName);
  //   }
  
  //   setList({ ...list, shortList: filteredList });
  // };
  const handleSearch = (e) => {
    const value = e?.target?.value;
    let filteredList = [];
  
    if (value) {
      filteredList = list.items.filter((item) =>
        item.Name.toLowerCase().includes(value.toLowerCase())
      ).filter((item) => {
        for (let i = 0; i < list.QuickMenuList.length; i++) {
          if (item.Name === list.QuickMenuList[i].PageName) {
            return false; // Exclude items with matching PageName
          }
        }
        return true; // Include other items
      });
    } else {
      filteredList = list.items.filter((item) => {
        for (let i = 0; i < list.QuickMenuList.length; i++) {
          if (item.Name === list.QuickMenuList[i].PageName) {
            return false; // Exclude items with matching PageName
          }
        }
        return true; // Include other items
      });
    }
  
    setList({ ...list, shortList: filteredList });
  };
  const handleCreate = (e,value,index) => {
    console.log(e,value,index);
    fetch(`${BASE_V11_URL}quickMenu/create-quick-menu/`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access}`,
        // "accept": "application/json"
      },
      body: JSON.stringify({
        CompanyID: CompanyID,
        BranchID: BranchID,
        User_id:user_id,
        PageName:value.Name,
        PageLink:value.Link,
        SrcId:value.id
      }),
    })
    .then((response) => response.json())
    .then((response) => {
      if (response.StatusCode === 6000) {
        fetchData()
      }
      else{}
    })
    setIs_Edit(false)
  };
  const fetchDataDelete = (e,value,index) => {
    console.log(e,value,index);
    fetch(`${BASE_V11_URL}quickMenu/delete-quick-menu/`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access}`,
        // "accept": "application/json"
      },
      body: JSON.stringify({
        CompanyID: CompanyID,
        BranchID: BranchID,
        User_id:user_id,
        PageName:value.PageName,
        PageLink:value.PageLink,
        SrcId:value.SrcId,
        OrderNo:value.OrderNo,
      }),
    })
    .then((response) => response.json())
    .then((response) => {
      if (response.StatusCode === 6000) {
        fetchData()
      }
      else{}
    })
  };
  // const  handleNavigate= (val) => {
  //   let value = checkEdition(val.PageLink)?val.PageLink:false
  //   if(value){
  //     // window.open(`${Report_URL.slice(0,-1)+val.PageLink}`, "_self");
  //     if(value){
  //       if (val.SrcId === 11||val.SrcId === "11" || val.SrcId ===29 || val.SrcId === "29" ){
  //         const modifiedUrl = Report_URL + val.PageLink.replace('/dashboard/', '');
  //         window.open(modifiedUrl, "_self");
          
  //       }else{
  //         window.open(`${VIKNBOOKS_FRONT_URL.slice(0,-1)+val.PageLink}`, "_self");
  //       }
  //     }
      
  //   }
  // }
  const  handleNavigate = (val) => {
    let modifiedUrl =""
    // window.open(`${Report_URL.slice(0,-1)+val.PageLink}`, "_self");
    if (val){
      console.log(val);
      let value = checkEdition(val.PageLink)?val.PageLink:false
      if(value){
        if (val.SrcId === 11||val.SrcId === "11" || val.SrcId ===29 || val.SrcId === "29" ){
          modifiedUrl = modifiedUrl = Report_URL + val.PageLink.replace('/dashboard/', '');
          
        }else{
          modifiedUrl = `${VIKNBOOKS_FRONT_URL.slice(0,-1)+val.PageLink}`
        }
        }
    }
    return modifiedUrl
  }
  const [urlList, setUrlList] = useState([]);
  // console.log(list.QuickMenuList,"QuickMenuList");
  const user_role_settings = useSelector((state) => state.userRollSettings);
  
  const CheckUserRole = (event,name,item) =>{
    console.log(name,"name");
    let check_name = name
    if (name==="Sales"){
      check_name = "Sales Invoice"
    }
    else if (name==="Purchase"){
      check_name = "Purchase Invoice"
    }
    else if(name==="Products"){
      check_name = "Product"
    }
    else if(name === "Account Ledgers"){
      check_name = "Account Ledger"
    }
    else if(name === "work order"){
      check_name = "Work Order"
    }
    else if(name === "Payment"){
      check_name = "Payment Voucher"
    }
    else if(name === "Receipt"){
      check_name = "Receipt Voucher"
    }
   
    if (item) {
      event.preventDefault();
      let data = user_role_settings.filter((i)=> i.name === check_name) 
      if (data.length <= 0){
        data = [{view_permission:true}]
      }
      console.log(data,"data");
      let view_permission = data[0]?.view_permission??null
      
      
      if (view_permission){
        window.location.href = item;
      }
      else{
         alert("Sorry, You don't have permission to see this page.")
      }
  }
  
  }
  useEffect(() => {
    if(list?.QuickMenuList){
      const updatedUrlList = list.QuickMenuList.map(val => handleNavigate(val));
      setUrlList(updatedUrlList);
    }
  }, [list?.QuickMenuList]);
  return (
    <Container >
    {/* <Container onClick={() => props.setQuickMenu(!props.showQuickMenu)}> */}
    <ButtonContainer onClick={() => props.setQuickMenu(!props.showQuickMenu)}>
      <div 
      style={{display:"flex",alignItems:"center"}}
      // onClick={() => props.setQuickMenu(!props.showQuickMenu)}
      >
        <AppsOutlinedIcon />
      </div>
      <div 
      style={{  whiteSpace: "nowrap", fontSize:"13px"     }}
      >{t("Quick Menu")}
      </div>
      </ButtonContainer>
     { is_Edit===false?
      <CSSTransition
        in={props.showQuickMenu}
        nodeRef={nodeRef}
        timeout={5000}
        classNames="quickMenu"
        unmountOnExit
        // onEnter={}
        // onExited={() => setShowButton(true)}
      >
        <QuickMenuDrawer ref={nodeRef} showQuickMenu={props.showQuickMenu}>
          <EditIconContainer onClick={() => {setIs_Remove(!is_Remove); }}>
            <EditIcon  src ={is_Remove?Icons.Tick:Icons.QuickEdit} />
          </EditIconContainer>
          {list.QuickMenuList.map((val, index) => (
            is_Remove === true ? (
              <RemoveContainer key={index} longText={val.PageName.length > 8}>
                <RemoveImage src={Icons.Remove} onClick={(e) => {fetchDataDelete(e,val,index)}}/>
                <QuickMenuIconContainer>
                  <IconImg src={srcList[val.SrcId - 1]?.Src} />
                </QuickMenuIconContainer>
                <QuickMenuLabel className="QuickMenuLabel">{t(val.PageName)}</QuickMenuLabel>
              </RemoveContainer>
            ) : (
              
              <a 
              style={{textDecoration:"none",cursor: "pointer",position: "relative",display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "center",width: "70px",height: "70px",transition: "all ease-in-out 0.3s",color:" #000",overflow: "hidden",
                "&:hover": 
                val.PageName.length > 8 ? {color: "#000",background: "#AFC3D2",transition: "background ease-in-out 0.3s",borderRadius: "15px", 
                  ".QuickMenuLabel": {borderRadius: "15px",animation: `${scrollLoop} 8s linear infinite`,animationDelay: "-0.5s",},}
                : 
                {color: "#000",background: "#AFC3D2",transition: "background ease-in-out 0.3s",borderRadius: "15px", },
              }}
              key={index} 
              // to={checkEdition(val.PageLink)?val.PageLink:null} 
              // onClick={()=>handleNavigate(val)}
              onClick={(event)=>{
                CheckUserRole(event,val.PageName,urlList[index])
              }}
              href={urlList[index]} 
              longText={val.PageName.length > 8}>
              <QuickMenuIconContainer>
                <IconImg src={srcList[val.SrcId - 1]?.Src} />
              </QuickMenuIconContainer>
              <QuickMenuLabel className="QuickMenuLabel">
                {t(val.PageName)}
              </QuickMenuLabel>
              
            </a>
               
            )
          ))}
          {list.QuickMenuList?.length >11?"":
          <AddButton onClick={() => {handleSearch("e");setIs_Edit(true); }}>
            <PlusIconImg
             src={Icons.Plus} />
          </AddButton>}
        </QuickMenuDrawer>
      </CSSTransition>
       :      
      <CSSTransition
        in={props.showQuickMenu}
        nodeRef={nodeRef}
        timeout={5000}
        classNames="quickMenu"
        unmountOnExit
        // onEnter={}
        // onExited={() => setShowButton(true)}
      >
        <QuickMenuDrawer2 ref={nodeRef} showQuickMenu={props.showQuickMenu}>
        <SearchContainer>
        <StyledInput
          fullWidth
          id="search"
          name="search"
          placeholder={t("Search")}
          size="small"
          variant="outlined"
          onChange={(e) => handleSearch(e)}
        />
        </SearchContainer>
          <SearchItems>
            {list.shortList.map((i,index)=>(
            <ItemsContainer key={index + 1} onClick={(e) => handleCreate(e,i,index)}>
              <SearchItemIconImg src={srcList[(i.id)- 1]?.Src} />
              <ImageLabel>{i?.Name}</ImageLabel>
            </ItemsContainer>))}
          </SearchItems>
        </QuickMenuDrawer2>
      </CSSTransition>} 
    </Container>
  );
};

export default QuickMenu;
const IconImg = styled("img")({
  width: "100%",
  height: "100%",
})
const PlusIconImg = styled("img")({
  width: "10px",
  height: "10px",
})
const EditIcon = styled("img")`
  width: 25px;
  height: 25px;
  cursor: pointer;
`;
const RemoveImage = styled("img")({
  position :"absolute",
  width: "15px",
  height: "15px",
  top: "5px",
  right: "5px",
})
const SearchItemIconImg = styled("img")`
  width: 25px;
  height: 25px;
  cursor: pointer;
`;
const QuickMenuIconContainer = styled(Box)(() => ({
  height: "35px",
  marginBottom: "5px",
}));

const QuickMenuDrawer = styled(Box)(({ theme, showQuickMenu }) => ({
    cursor: "initial",
    boxShadow: "0px 0px 5px 0px #dcd9d9",
    position: "absolute",
    top: "50px",
    left: "30px",
    right: "0",
    background: "#ccdbe5",
    opacity: showQuickMenu ? "1" : "0.5",
    transform: showQuickMenu ? "translateY(0)" : "translateY(-200%)",
    transition: "transform ease-in-out 0.5s",
    display: "grid",
    gridTemplateColumns: "repeat(6, 14%)", // Changed from percentages to repeat function
    gap: "3%", // Changed from individual column and row gaps
    width: "475px",
    height: "190px",
    padding: "25px",
    borderRadius: "25px",
    "& .MuiFormControl-root .MuiTextField-root .sc-hhOBVt .dptpRD .MuiFormControl-fullWidth": {
      width: "250px",
    },
}));
const QuickMenuDrawer2 = styled(Box)(({ theme, showQuickMenu }) => ({
    cursor: "initial",
    boxShadow: "0px 0px 5px 0px #dcd9d9",
    position: "absolute",
    top: "50px",
    left: "30px",
    right: "0",
    background: "#ccdbe5",
    opacity: showQuickMenu ? "1" : "0.5",
    transform: showQuickMenu ? "translateY(0)" : "translateY(-200%)",
    transition: "transform ease-in-out 0.5s",
    display: "grid",
    width:  "max-content",
    backGround: "#ccdbe5",
    padding: "25px",
    borderRadius: "25px",
    '&.MuiFormControl-root MuiTextField-root sc-hhOBVt dptpRD MuiFormControl-fullWidth':{
      width:"250px",
    },
}));

const RemoveContainer = styled(Box)(({ longText }) => ({
  cursor: "pointer",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "70px",
  height: "70px",
  overflow: "hidden",
  transition: "all ease-in-out 0.3s",
  color: "#000",
  "&:hover": 
  longText ? {
    color: "#000",
    background: "#AFC3D2",
    transition: "background ease-in-out 0.3s",
    borderRadius: "15px", 
    // [`& ${SideMenuList}`]: {
    ".QuickMenuLabel": {
      borderRadius: "15px",
      animation: `${scrollLoop} 8s linear infinite`,
      animationDelay: "-0.5s",
    },
  }
  : {
    color: "#000",
    background: "#AFC3D2",
    transition: "background ease-in-out 0.3s",
    borderRadius: "15px", 
  },
}));
const StyledInput = styled(TextField)`
  position: relative;
  input {
    color: #000;
  }
  & textarea {
    min-height: 120px;
    max-height: 120px;
    color: #000;
  }
  & .MuiOutlinedInput-root {
    border-radius: 5px;
    border: 1px solid #C3DEF2;
    width:60%;
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #a4a4a4;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #a4a4a4;
    opacity: 1;
    font-size: 15px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding-top: 3.5px;
    padding-bottom: 3.5px;
    padding-left: 20px;
  }
  &:after {
    content: url("../../images/search.svg");
    position: absolute;
    top: 11px;
    right: 12px;
    transform: scale(1.2);
  }
  input {
    background: #C3DEF2;
    border-radius: inherit;
  }
  @media only screen and (max-width: 500px) {
    .MuiOutlinedInput-inputMarginDense {
      padding-top: 5.5px;
      padding-bottom: 5.5px;
    }
    &:after {
      top: 9px;
      right: 10px;
      transform: scale(1);
    }
  }
`;

const AddButton = styled(Box)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 15px;
  transition: all ease-in-out 0.3s;
  color: #000;
  background: #AFC3D2;
  &:hover {
    color: #000;
    background: #AFC3D2;
    transition: background ease-in-out 0.3s;
    border-radius: 15px;
  }
`;

const Container = styled(Box)`
  cursor: pointer;
  border-radius: 35px;
  align-items: center;
  border: 1px solid #e5e5e5;
  width: 130px;
  min-width: 130px;
  margin: auto;
  height: 35px;
  position: relative;
  z-index: 100;
  font-size:12px;
`;

const ButtonContainer = styled(Box)`
  cursor: pointer;
  border-radius: 35px;
  align-items: center;
  border: 1px solid #e5e5e5;
  width: 130px;
  min-width: 130px;
  margin: auto;
  height: 35px;
  position: relative;
  z-index: 100;
  display: flex;
  padding: 0px 15px;
  justify-content: space-between;
`;

// Similarly convert the rest of the styled components

const SearchContainer = styled(Box)`
  margin-bottom: 20px;
  display: grid;
`;

const SearchItems = styled(Box)`
  display: grid;
  grid-template-columns: auto auto;
  height: 90px;
  min-width: 420px;
  overflow-y: scroll;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar {
    width: 0.5em;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const EditIconContainer = styled(Box)`
  position: absolute;
  right: 0px;
  transform: translate(25%, -25%);
`;





const ItemsContainer = styled(Box)`
  display: flex;
  min-width: 200px;
  max-height: 30px;
  margin-top: 10px;
  cursor: pointer;
`;

const ImageLabel = styled(Box)`
  display: grid;
  align-items: center;
  margin-left: 10px;
`;

const QuickMenuLabel = styled(Box)`
  height: 12px;
  white-space: nowrap;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size:12px;
`;

const QucikMenuSelect = styled(Link)(({ longText }) => ({
  textDecoration:"none",
  cursor: "pointer",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "70px",
  height: "70px",
  transition: "all ease-in-out 0.3s",
  color:" #000",
  overflow: "hidden",
  "&:hover": 
  longText ? {
    color: "#000",
    background: "#AFC3D2",
    transition: "background ease-in-out 0.3s",
    borderRadius: "15px", 
    // [`& ${SideMenuList}`]: {
    ".QuickMenuLabel": {
      borderRadius: "15px",
      animation: `${scrollLoop} 8s linear infinite`,
      animationDelay: "-0.5s",
    },
  }
  : {
    color: "#000",
    background: "#AFC3D2",
    transition: "background ease-in-out 0.3s",
    borderRadius: "15px", 
  },
  
}));
// ${(props) =>
//   props.longText &&
//   css`
//   &:hover ${QuickMenuLabel} {
//     border-radius: 15px,
//     animation: ${scrollLoop} 8s linear infinite;
//     animation-delay: -0.5s;
//   }
// `}
import { Button } from '@mui/material'
import React from 'react'
import PrintIcone from "../../Assets/icons/printer.svg"
import { Icons } from '../../Assets/AssetLog'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

const PrintBtn = () => {
  const [t, i18n] = useTranslation("common");
  return (
    <Button startIcon={<img src={Icons.Print} alt='print' />} sx={{ textTransform: "none", fontWeight: "400"}}>
      {t("Print")}
    </Button>
  )
}

export default PrintBtn
import { Chip, Typography } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';

const StyleLable = function (props) {
  return (
    <Typography sx={{ color: "#0A9EF3", fontSize: "13px" }}>
      { props.name + ":" || "FilterName"}{" "}
      {/* { props.name } */}
      <Typography
        sx={{ color: "#001746", fontSize: "13px" }}
        component={"span"}
      >
      {/* {props.value?props.value.charAt(0).toUpperCase() + props.value.slice(1) : "Value"} */}
      {props.value?props.value : "Value"}
      </Typography>
    </Typography>
  );
};

const FilterLable = (props) => {
  // const handleDelete = () => {
  //   console.info("You clicked the delete icon.");
  // };

  return (
    <Chip
      sx={{mr: 1, background: '#F9F9F9', border: "1px solid #EEEEEE"}}
      label={<StyleLable name={props?.name} value={props?.value} />}
      variant="outlined"
      onDelete={props?.handleDelete}
      deleteIcon={<CloseIcon style={{color:"#0A9EF3", fontSize:"17px"}} />}
    />
  );
};

export default FilterLable;

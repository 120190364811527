
import axios from "axios";
import { openSnackbar } from "../../features/SnackBarslice";







export const create_user_address = async function (body) {

    try {
      const { data } = await axios.post("api/v11/parties/user-address/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
  };
  
  export const update_user_address = async function (body) {

    try {
      const { data } = await axios.post("api/v11/parties/user-address/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
  };
  
  
  
  
  export const list_user_address = async function (body) {

    try {
      const { data } = await axios.post("api/v11/parties/user-address/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
  };
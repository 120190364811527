import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import PrintIcon from "@mui/icons-material/Print";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styled from "@emotion/styled";
import { Doughnut } from "react-chartjs-2";
import { deepOrange } from "@mui/material/colors";
import Pagenation from "../../../../Components/Utils/Pagenation";
// import ImportExportIcon from "@material-ui/icons/ImportExport";
import PrintBtn from "../../../../Components/Utils/PrintBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useSelector } from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { handleNumber, getCookie, formatNumber } from "../../../../generalFunction";
import { CallDailyReportCard, CallDailyReportTopItems } from "../../../../Api/Reports/AccountApi";
import { BASE_V11_URL } from "../../../../generalVeriable";
import { download_excel } from "../../../../Components/ReportTable/ReportTable";
import { accessToken } from "../../../../features/userSlice";
import { Icons } from "../../../../Assets/AssetLog";
import { UserRolePermission } from "../../../../Function/Editions";
import SearchIcon from '@mui/icons-material/Search';

const DailyReport = () => {
  let Export_view = UserRolePermission("Daily Report", "print_permission");
  ChartJS.register(ArcElement, Tooltip, Legend);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const { user_id, } = useSelector((state) => state.user);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { CurrencySymbol, CountryName, VAT, GST } = useSelector(
    (state) => state.companyDetails
  );
  const [t] = useTranslation("common");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [activeSection, setActiveSection] = useState(null);
  const [droapDown, setDroapDown] = useState({
    first: false,
    second: false,
  });
  const [filterOptions, setFilterOptions] = useState({
    date: moment(),
    customer: 5,
    product: 5,
  });

  const [reportData, setReportData] = useState({
    TotalSales: {
      GrandTotal: 0.0,
      CashSales: 0,
      BankSales: 0,
      CreditSales: 0,
      sales_perc: 0,
      Gross: 0,
      Discount: 0,
      TotalTax: 0,
      count: 0,
    },
    TotalPurchase: {
      GrandTotal: 0.0,
      CashPurchase: 0,
      BankPurchase: 0,
      CreditPurchase: 0,
      purchase_perc: 0,
      Gross: 0,
      Discount: 0,
      TotalTax: 0,
      count: 0,
    },
    TotalExpense: {
      CashExpense: 0,
      BankExpense: 0,
      CreditExpense: 0,
      GrandTotal: 0.0,
      expense_perc: 0,
      Gross: 0,
      Discount: 0,
      TotalTax: 0,
      DirectExpences: 0,
      IndirectExpences: 0,
    },
    TotalPayment: {
      GrandTotal: 0,
      payment_perc: 0,
      CashPayment: 0,
      BankPayment: 0,
      Gross: 0,
      Discount: 0,
    },
    TotalReceipt: {
      GrandTotal: 0,
      CashPayment: 0,
      BankPayment: 0,
      receipt_perc: 0,
      CashSales: 0,
      CashReceipt: 0,
      BankReceipt: 0,
      Gross: 0,
      Discount: 0,
    },
    TotalSalesReturn: {
      GrandTotalReturn: 0.0,
      CashSalesReturn: 0,
      BankSalesReturn: 0,
      CreditSalesReturn: 0,
      salesReturn_perc: 0,
      Gross: 0,
      Discount: 0,
      TotalTax: 0,
      count: 0,
      CashSales: 0,
    },
    TotalJournal: {
      GrandTotal: 0,
      CashJournal: 0,
      BankJournal: 0,
    },
    TotalProfit: {
      GrandTotal: 0.0,
      profit_perc: 0,
    },
    TotalPurchaseReturn: {
      GrandTotalReturn: 0.0,
      CashPurchaseReturn: 0,
      BankPurchaseReturn: 0,
      CreditPurchaseReturn: 0,
      purchaseReturn_perc: 0,
      Gross: 0,
      Discount: 0,
      TotalTax: 0,
      count: 0,
    },
    ClosingCashBalance: 0,
    ClosingBankBalance: 0,
    OpeningCashBalance: 0,
    OpeningBankBalance: 0,
    RevenueToday: 0,
    RevenueYesterday: 0,
    revenueIncrementPercentage: 0,

  });

  const [topItems, setTopItems] = useState({
        productItems: [],
    customerItems: [],
    accountsItems: [],
    TransactionsDatas: [],
  })
  
  const [tabvalue, setTabValue] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const refs = useRef([]);

  // Handle Functions ##########

  const handleNumber = function(num){
    if (typeof num === "number") {
      if (num < 0) {
        return formatNumber(num * -1);
      }
      return formatNumber(num);
    } 
    return formatNumber(num)
  }

  const scrollToElement = (index) => {
    if (refs.current[index]) {
      refs.current[index].scrollIntoView({
        behavior: "smooth", // Optional: smooth scroll
        block: "start", // Optional: aligns the element at the start of the visible area
      });
    }
  };

 
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    scrollToElement(newValue)
  };

  const handleCustomer = (e) => { 
    setFilterOptions({...filterOptions, customer: e.target.value});
    // fetchTopDailyItems();
  }

  const handleProduct = (e) => {
    setFilterOptions({...filterOptions, product: e.target.value});
    // fetchTopDailyItems();
  }

  const calPersentage = (value, total) => { 
    if (total === 0) {
      return 0
    }
    return (value / total) * 100
  };

  const ExportDailyReportExcel = (e, type) => {
    let Date = filterOptions.date.format("YYYY-MM-DD");
    // let PriceRounding = PriceDecimalPoint;
    fetch(
      `${BASE_V11_URL + "sales/daily-report-excel/"}?CompanyID=${CompanyID}&BranchID=${BranchID}&Date=${Date}&Type=${type}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCookie("VBID")}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          download_excel(response.url);
        } else {
          console.log("ERROR");
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  
  // Fetch Data
  const fetchReport = async function () {
    setIsLoading(true)
    const res = await CallDailyReportCard({
      CompanyID: CompanyID,
      CreatedUserID : user_id,
      BranchID: BranchID,
      Date: filterOptions.date.format("YYYY-MM-DD"),
      PriceRounding:Number(PriceDecimalPoint),

    });
    setIsLoading(false)
    if (res.StatusCode === 6000) {
      setReportData(prev => ({
        ...prev,
        TotalSales: res.TotalSales,
        TotalPurchase: res.TotalPurchase,
        TotalSalesReturn: res.TotalSalesReturn,
        TotalPurchaseReturn: res.TotalPurchaseReturn,
        TotalExpense: res.TotalExpense,
        TotalPayment: res.TotalPayment,
        Profit: res.TotalProfit,
        TotalJournal: res.TotalJournal,
        OpeningBankBalance: res.OpeningBankBalance,
        OpeningCashBalance: res.OpeningCashBalance,
        ClosingCashBalance: res.ClosingCashBalance,
        ClosingBankBalance: res.ClosingBankBalance,
        RevenueToday: res.RevenueToday,
        RevenueYesterday: res.RevenueYesterday,
        revenueIncrementPercentage: res.revenueIncrementPercentage,
        TotalReceipt:res.TotalReceipt
      }));
    }
  };

  const fetchTopDailyItems = async function() {
    // setIsLoading(true)
    const res = await CallDailyReportTopItems({
      Date: filterOptions.date.format("YYYY-MM-DD"),
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID : user_id,
      PriceRounding:Number(PriceDecimalPoint),
      items_per_page_customer: filterOptions.customer,
      items_per_page_product: filterOptions.product,
    })
    // setIsLoading(false)
    console.log(res);
    
    if (res.StatusCode === 6000) {
      setTopItems(prev =>({
        ...prev,
        productItems: res.productItems,
        customerItems: res.customerItems,
        accountsItems: res.accountsItems,
        TransactionsDatas: res.TransactionsDatas,
      }))
    }
  }

  useEffect(() => {
    fetchTopDailyItems();
  }, [filterOptions.customer, filterOptions.product]);

  useEffect(() => {
    fetchTopDailyItems()
  }, [])

  useEffect(() => {
    // Step 2: Create an IntersectionObserver instance
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Step 3: Update activeSection based on which section is in view
            const sectionId = entry.target.getAttribute("data-section");
            setTabValue(Number(sectionId))
          }
        });
      },
      { threshold: 0.5 } // 50% of the section should be visible to trigger the callback
    );

    // Attach observer to each section
    refs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    // Cleanup the observer on component unmount
    return () => {
      refs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);
  
// console.log(reportData,"-----state");
  return (
    <Paper
      sx={{
        height: "100%",
        // pb: 4,
        width: "100%",
      }}
    >
      <Box>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500", mr: 2 }}
              variant="h2"
            >
              {t("Daily Report")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            {/* <FilterBtn onClick={() => setOpenFilter(true)} /> */}
          </FlexBox>

          <FlexBox className="right">
            {Export_view ? 
          <Button
              startIcon={<img src={Icons.Export} alt="export" />}
              sx={{ color: "#636363 !important", mr: "10px !important", textTransform: "none"}}
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              {t("Export")}
            </Button>
            :null}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{
                width: '400px !important', // Set the width
                // backgroundColor: '#F0F0F0', // Set the background color
                // color: '#333', // Set the text color
                '&:hover': {
                  // backgroundColor: '#E0E0E0', // Change background color on hover
                },
              }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem sx={{ width: '100px !important',display: "block !important", px: "15px !important"}} onClick={(e) => ExportDailyReportExcel(e, "Excel")}>{t("Excel")}</MenuItem>
              <MenuItem sx={{ width: '100px !important',display: "block !important", px: "15px !important"}} onClick={(e) => ExportDailyReportExcel(e, "PDF")}>{t("PDF")}</MenuItem>
              {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
            </Menu>
            {/* <ExportBtn /> */}

            {/* <ReportDownload
              download_type={"PDF"}
              invoice_type={"Debit_Note_report"}
              BranchID={filterOptions.BranchObj?.BranchID ? filterOptions.BranchObj.BranchID : 1}
              FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
              ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
              RouteLedgers={Ledger}
              handleRouteList={
                filterOptions.ledgerValue.length ?
                handleCashLedgerss: 0
              }
              LedgerID={filterOptions.LedgerObj?.LedgerID?filterOptions.LedgerObj.LedgerID : 0}
              UserID={filterOptions.userObj?.UserID?filterOptions.userObj.UserID : 0}
              is_celery={true}
              setProgress={setProgress}
              filterValue = {filterOptions.filterType === "productwise" ? true : false}
              value = {filterOptions.reportType}
            /> */}
            {Export_view ? 
            <PrintBtn />
            : null}
            <FormControl sx={{width: "65%",}}>
              <StyledSelect
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={10}
                disabled
                // label="Age"
                // onChange={handleChange}
              >
                <MenuItem value={10}>All Users</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </StyledSelect>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                value={filterOptions.date}
                onChange={(e) =>
                  setFilterOptions({ ...filterOptions, date: e })
                }
                format="DD-MM-YYYY"
                textField={(params) => <TextField {...params} />}
                sx={{
                  "& .MuiInputBase-root": {
                    bgcolor: "#0B1451",
                    borderRadius: "6px",
                    flexDirection: "row-reverse",
                    "& .MuiInputBase-input": {
                      py: "10px",
                      fontSize: "12px",
                      color: "white",
                      "& .Mui-disabled": {
                        color: "white",
                      },
                    },
                    "& .MuiButtonBase-root ": {
                      padding: "1px",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "18px",
                      color: "white",
                    },
                  },
                }}
              />
            </LocalizationProvider>

            <IconButton 
              disabled={isLoading}
              sx={{ 
                color: "white", 
                bgcolor: "#0B1451 !important", 
                ":hover": { bgcolor: "#0B1451 !important" },
                // "& .MuiSvgIcon-root": { color: "#888888" }

              }}
              onClick={() =>{ 
                fetchReport()
                fetchTopDailyItems()
              }}
            >
              { isLoading ? <CircularProgress sx={{color: "white"}} size={24} thickness={5} />
              : <SearchIcon /> }
            </IconButton>
          </FlexBox>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", px: 4}}>
          <TopBox sx={{ width: "26%"}}>
            <Typography sx={{ fontSize: "18px", fontWeight: "medium"}} >Opening Balance</Typography>
            <Box sx={{ display: "flex", }}>
            <Box sx={{ mr: "36px" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: "regular", color:"#333333"}}>Cash</Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
                {" "}
                <span style={{ fontWeight: "lighter", fontSize: "10px" }}>
                  {CurrencySymbol
                    ? CurrencySymbol
                    : CountryName === "India"
                    ? "Rs"
                    : "SAR"}
                </span>{" "}
                {formatNumber(reportData.OpeningCashBalance)}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "14px", fontWeight: "regular", color:"#333333"}}>Bank</Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
                {" "}
                <span style={{ fontWeight: "lighter", fontSize: "10px" }}>
                  {CurrencySymbol
                    ? CurrencySymbol
                    : CountryName === "India"
                    ? "Rs"
                    : "SAR"}
                </span>{" "}
                {formatNumber(reportData.OpeningBankBalance)}
              </Typography>
            </Box>
            </Box>
          </TopBox>

          <TopBox sx={{ width: "26%"}}>
          <Typography  sx={{ fontSize: "18px", fontWeight: "medium"}} >Closing Balance</Typography>
              <Box sx={{ display: "flex", }}>
                <Box sx={{ mr: "36px" }}>
                  <GrayText>Cash</GrayText>
                  <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
                    {" "}
                    <span style={{ fontWeight: "lighter", fontSize: "10px" }}>
                      {CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}
                    </span>{" "}
                    {formatNumber(reportData.ClosingCashBalance)}
                  </Typography>
                </Box>
                <Box>
                  <GrayText>Bank</GrayText>
                  <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
                    {" "}
                    <span style={{ fontWeight: "lighter", fontSize: "10px" }}>
                      {CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}
                    </span>{" "}
                    {formatNumber(reportData.ClosingBankBalance)}
                  </Typography>
                </Box>
              </Box>
          </TopBox>

          <TopBox sx={{ width: "46%"}}>
            <Box sx={{ display: "flex", justifyContent: "space-between", height: "100%"}}>
          <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: "10px",
                  justifyContent: "space-between",
                  width: "33%",
                }}
              >
                <Box sx={{ mr: "16px" }}>
                  <GrayText>Today's</GrayText>
                  <Typography sx={{ fontWeight: "bold" }}>Revenue</Typography>
                </Box>
                <Box sx={{ mr: "16px" }}>
                  <GrayText> Total</GrayText>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {" "}
                    <span>{CurrencySymbol?CurrencySymbol:CountryName === "India"?"Rs":"SAR"}</span> {handleNumber(reportData.RevenueToday) ?? "00"}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingX: "25px",
                  width: "37%",
                }}
              >
                <Box sx={{ mr: "16px" }}>
                  <GrayText>Yesterday's</GrayText>
                  <Typography sx={{ fontWeight: "bold" }}>Revenue</Typography>
                </Box>
                <Box>
                  <GrayText>Total</GrayText>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {" "}
                    <span>{CurrencySymbol?CurrencySymbol:CountryName ==="India"?"Rs":"SAR"}</span> {handleNumber(reportData.RevenueYesterday) ?? "00"}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "24%",
                }}
              >
                {reportData.revenueIncrementPercentage >= 0 || reportData.RevenueYesterday === 0 ? 
                <ArrowDropUpIcon
                  style={{ fontSize: "40px", color: "#04821E" }}
                /> : 
                <ArrowDropDownIcon
                  style={{ fontSize: "40px", color: "#f2310f" }}
                />}
                {/* <ArrowDropUpIcon
                  style={{ fontSize: "40px", color: "#04821E" }}
                /> */}
                <Box>
                  <Typography sx={{}}>
                    {handleNumber(reportData.revenueIncrementPercentage)} %
                  </Typography>
                  <Typography sx={{ color: reportData.revenueIncrementPercentage >= 0 || reportData.RevenueYesterday === 0 ? "#04821E" : "#f2310f" }}> {reportData.revenueIncrementPercentage >= 0 || reportData.RevenueYesterday === 0  ? "Increase" : "Decrease"}</Typography>
                </Box>
              </Box>
              </Box>
          </TopBox>
        </Box>

        <Box sx={{ width: '100%', bgcolor: 'background.paper', borderBottom: "2px solid #F5F5F5", }}>
          <Tabs sx={{
              color: "#000",
              
              "& .MuiTabs-indicator": {
                backgroundColor: "#0A9EF3",
                borderRadius: "5px 5px 0px 0px",
                height: "5px",
              },
              ".MuiButtonBase-root": {
                textTransform: "none",
                width: "215px"
              }
            }} value={tabvalue} onChange={handleChangeTab} centered>
            <Tab label="Sales overview" />
            <Tab label="Purchase overview" />
            <Tab label="Payments & Receipts" />
            <Tab label="Expenses" />
          </Tabs>
        </Box>
      </Box>

      <Box sx={{ overflowY: "scroll", width: "100%", height: "calc(100vh - 280px)"}}>
        <Box sx={{ padding: "25px 130px"}}>
            <div ref={(el) => (refs.current[0] = el)} data-section="0" />
          <Typography 
            sx={{ fontSize: "20px", fontWeight: "bold", mb: "12px" }} 
          >
            Sales Overview
          </Typography>
          <Paper
            // id='sales'
            elevation={0}
            sx={{
              backgroundColor: "#FBFBFB !important",
              border: "1px solid #E1EAEF",
              borderRadius: "14px",
              display: "flex",
              justifyContent: "space-between",
              p: "12px",
              mb: "15px",
            }}
          >
            <Box sx={{ display: "flex", width: "40%", alignItems: "center" }}>
              <Box sx={{ width: "200px", mr: "10px" }}>
  
                {reportData.TotalSalesReturn?.count !== 0 || reportData.TotalSales.count !== 0 ? <Doughnut
                  data={{
                    datasets: [
                      {
                        // label: ["# of Votes", "Sales Return"],
                        data: [reportData.TotalSales?.count, reportData.TotalSalesReturn?.count],
                        backgroundColor: ["rgb(5 75 216)", "rgb(222 240 252)"],
                        borderWidth: 0,
                      },
                    ],
                    options: {
                      cutoutPercentage: 80,
                    },
                  }}
                /> : (
                  <Doughnut
                    data={{
                      datasets: [
                        {
                          data: [100],
                          backgroundColor: ["#00000029"],
                        },
                      ],
                    }}
                  />
                )}
              </Box>
              <Box>
                <Typography sx={{ fontSize: "13px" }}>
                  Effective Sales
                </Typography>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                  <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName === "India" ? "Rs" : "SAR"}</span>{" "}
                  {handleNumber(reportData.TotalSales.GrandTotal - reportData.TotalSalesReturn.GrandTotalReturn)}
                </Typography>
                <Typography sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      backgroundColor: "#054BD8",
                      borderRadius: "30px",
                      mr: "10px",
                    }}
                  />{" "}
                  No of Sales
                </Typography>
                <Typography>{handleNumber(reportData.TotalSales.count)}</Typography>
                <Typography sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      backgroundColor: "#BFD4FF",
                      borderRadius: "30px",
                      mr: "10px",
                    }}
                  />{" "}
                  No of sales return
                </Typography>
                <Typography>{handleNumber(reportData.TotalSalesReturn.count)}</Typography>
              </Box>
            </Box>

            <Paper
              sx={{
                backgroundColor: "#E4F3EB",
                width: "30%",
                px: "18px",
                py: "10px",
                mr: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "155px" }}>

                  { reportData.TotalSales.TotalTax !==0 || reportData.TotalSales.CashSales !==0 || reportData.TotalSales.BankSales !==0 ? 
                  <Doughnut data={ { datasets: [
                      {
                        data: [reportData.TotalSales.CashSales, reportData.TotalSales.BankSales, reportData.TotalSales.CreditSales],
                        backgroundColor: ["#21C50A", "#054BD8", "#FFAA00"],
                      },
                    ],}} /> : (
                  <Doughnut
                    data={{
                      datasets: [
                        {
                          data: [100],
                          backgroundColor: ["#00000029"],
                        },
                      ],
                    }}
                  />
                ) }
                  <Box sx={{ mt: "10px" }}>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      Sales
                    </Typography>
                    <Typography sx={{ fontSize: "13px", textAlign: "center" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{"  "}
                      {handleNumber(reportData.TotalSales.GrandTotal)}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "11px", color: "#21C50A" }}>
                    Cash
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalSales.CashSales)}
                  </Typography>
                  <Typography sx={{ fontSize: "11px", color: "#054BD8" }}>
                    Bank
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalSales.BankSales)}
                  </Typography>
                  <Typography sx={{ fontSize: "11px", color: "#FFAA00" }}>
                    Credit
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalSales.CreditSales)}
                  </Typography>
                </Box>
              </Box>
            </Paper>

            <Paper
              sx={{
                backgroundColor: "#FFE6D9",
                width: "30%",
                px: "18px",
                py: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "155px" }}>
                { reportData.TotalSalesReturn.CashSalesReturn   !== 0 ||
                  reportData.TotalSalesReturn.BankSalesReturn   !== 0 ||
                  reportData.TotalSalesReturn.CreditSalesReturn !== 0 ? (
                    <Doughnut
                      data={{
                        datasets: [
                          {
                            data: [
                              reportData.TotalSalesReturn.CashSalesReturn,
                              reportData.TotalSalesReturn.BankSalesReturn,
                              reportData.TotalSalesReturn.CreditSalesReturn,
                            ],
                            backgroundColor: [ "#21C50A","#054BD8", "#FFAA00"],
                          },
                        ],
                      }}
                    />
                  ) : (
                    <Doughnut
                      data={{
                        datasets: [
                          {
                            data: [100],
                            backgroundColor: ["#00000029"],
                          },
                        ],
                      }}
                    />
                  )}
                  <Box sx={{ mt: "10px" }}>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      Sales Return
                    </Typography>
                    <Typography sx={{ fontSize: "13px", textAlign: "center" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{"  "}
                      {handleNumber(reportData.TotalSalesReturn.GrandTotalReturn)|| "0.00"}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "11px", color: "#21C50A" }}>
                    Cash
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalSalesReturn.CashSalesReturn)}
                  </Typography>
                  <Typography sx={{ fontSize: "11px", color: "#054BD8" }}>
                    Bank
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalSalesReturn.BankSalesReturn)}
                  </Typography>
                  <Typography sx={{ fontSize: "11px", color: "#FFAA00" }}>
                    Credit
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalSalesReturn.CreditSalesReturn)}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Paper>

          <Paper
            elevation={0}
            sx={{
              backgroundColor: "#FBFBFB !important",
              border: "1px solid #E1EAEF",
              borderRadius: "14px",
              display: "flex",
              justifyContent: "space-between",
              mb: "15px",
              px: "40px",
              py: "20px",
            }}
          >
            <Box>
              <Typography
                sx={{ color: "#0A604B", fontSize: "15px", fontWeight: "bold" }}
              >
                Sales Summary
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ mr: "37px" }}>
                  <Typography>Gross</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalSales.Gross)}
                  </Typography>
                </Box>
                <Box sx={{ mr: "37px" }}>
                  <Typography>Discount</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalSales.Discount)}
                  </Typography>
                </Box>
                <Box sx={{ mr: "37px" }}>
                  <Typography>{VAT?"VAT":GST?"TAX":"Tax Amount"}</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"TAX":"VAT"} </span>{" "}
                    {handleNumber(reportData.TotalSales.TotalTax)}
                  </Typography>
                </Box>
                <Box>
                  <Typography>Total</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalSales.GrandTotal)}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Divider orientation="vertical" variant="middle" flexItem />

            <Box>
              <Typography
                sx={{ color: "#D84141", fontSize: "15px", fontWeight: "bold" }}
              >
                Sales Return Summary
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ mr: "37px" }}>
                  <Typography>Gross</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalSalesReturn.Gross)}
                  </Typography>
                </Box>
                <Box sx={{ mr: "37px" }}>
                  <Typography>Discount</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalSalesReturn.Discount)}
                  </Typography>
                </Box>
                <Box sx={{ mr: "37px" }}>
                  <Typography>{VAT?"VAT":GST?"TAX":"Tax Amount"}</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalSalesReturn.TotalTax)}
                  </Typography>
                </Box>
                <Box>
                  <Typography>Total</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalSalesReturn.GrandTotalReturn)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>

          <div ref={(el) => (refs.current[1] = el)} data-section="1"/>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: "12px" }}>
            Purchase Overview
          </Typography>
          <Paper
            
            id="purchase"
            elevation={0}
            sx={{
              backgroundColor: "#FBFBFB !important",
              border: "1px solid #E1EAEF",
              borderRadius: "14px",
              display: "flex",
              justifyContent: "space-between",
              p: "12px",
              mb: "15px",
            }}
          >
            <Box sx={{ display: "flex", width: "40%", alignItems: "center" }}>
              <Box sx={{ width: "200px", mr: "10px" }}>
              {reportData.TotalPurchaseReturn.count !== 0 || reportData.TotalPurchase.count !== 0 ? <Doughnut
                  data={{
                    datasets: [
                      {
                        // label: "# of Votes",
                        data: [reportData.TotalPurchase.count, reportData.TotalPurchaseReturn.count],
                        backgroundColor: ["rgb(5 75 216)", "rgb(222 240 252)"],
                        borderWidth: 0,
                      },
                    ],
                    options: {
                      cutoutPercentage: 80,
                    },
                  }}
                /> : (
                  <Doughnut
                    data={{
                      datasets: [
                        {
                          data: [100],
                          backgroundColor: ["#00000029"],
                        },
                      ],
                    }}
                  />
                )}
              </Box>
              <Box>
                <Typography sx={{ fontSize: "13px" }}>
                  Effective Purchase
                </Typography>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                  <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName === "India" ? "Rs" : "SAR"}</span>{" "}
                  {handleNumber(reportData.TotalPurchase.GrandTotal - reportData.TotalPurchaseReturn.GrandTotalReturn) ?? "00" }
                </Typography>
                <Typography sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      backgroundColor: "#054BD8",
                      borderRadius: "30px",
                      mr: "10px",
                    }}
                  />{" "}
                  No of Purchase
                </Typography>
                <Typography>{handleNumber(reportData.TotalPurchase.count)}</Typography>
                <Typography sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      backgroundColor: "#BFD4FF",
                      borderRadius: "30px",
                      mr: "10px",
                    }}
                  />{" "}
                  No of Purchase Return
                </Typography>
                <Typography>{handleNumber(reportData.TotalPurchaseReturn.count)}</Typography>
              </Box>
            </Box>

            <Paper
              sx={{
                backgroundColor: "#E4F3EB",
                width: "30%",
                px: "18px",
                py: "10px",
                mr: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "155px" }}>
                  {reportData.TotalPurchase.CashPurchase  !== 0 ||
                  reportData.TotalPurchase.BankPurchase   !== 0 ||
                  reportData.TotalPurchase.CreditPurchase !== 0 ? (
                    <Doughnut
                      data={{
                        datasets: [
                          {
                            data: [
                              reportData.TotalPurchase.CashPurchase,
                              reportData.TotalPurchase.BankPurchase,
                              reportData.TotalPurchase.CreditPurchase,
                            ],
                            backgroundColor: [ "#21C50A","#054BD8", "#FFAA00"],
                          },
                        ],
                      }}
                    />
                  ) : (
                    <Doughnut
                      data={{
                        datasets: [
                          {
                            data: [100],
                            backgroundColor: ["#00000029"],
                          },
                        ],
                      }}
                    />
                  )}
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "11px", color: "#21C50A" }}>
                    Cash
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalPurchase.CashPurchase)}
                  </Typography>
                  <Typography sx={{ fontSize: "11px", color: "#054BD8" }}>
                    Bank
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalPurchase.BankPurchase)}
                  </Typography>
                  <Typography sx={{ fontSize: "11px", color: "#FFAA00" }}>
                    Credit
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalPurchase.CreditPurchase)}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: "10px" }}>
                <Typography
                  sx={{
                    fontSize: "13px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Purchase
                </Typography>
                <Typography sx={{ fontSize: "13px", textAlign: "center" }}>
                  <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                  {handleNumber(reportData.TotalPurchase.GrandTotal) || "0.00"}
                </Typography>
              </Box>
            </Paper>

            <Paper
              sx={{
                backgroundColor: "#FFE6D9",
                width: "30%",
                px: "18px",
                py: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "155px" }}>
                { reportData.TotalPurchaseReturn.CashPurchaseReturn   !== 0 ||
                  reportData.TotalPurchaseReturn.BankPurchaseReturn   !== 0 ||
                  reportData.TotalPurchaseReturn.CreditPurchaseReturn !== 0 ? (
                    <Doughnut
                      data={{
                        datasets: [
                          {
                            data: [
                              reportData.TotalPurchaseReturn.CashPurchaseReturn,
                              reportData.TotalPurchaseReturn.BankPurchaseReturn,
                              reportData.TotalPurchaseReturn.CreditPurchaseReturn,
                            ],
                            backgroundColor: [ "#21C50A","#054BD8", "#FFAA00"],
                          },
                        ],
                      }}
                    />
                  ) : (
                    <Doughnut
                      data={{
                        datasets: [
                          {
                            data: [100],
                            backgroundColor: ["#00000029"],
                          },
                        ],
                      }}
                    />
                  )}
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "11px", color: "#21C50A" }}>
                    Cash
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalPurchaseReturn.CashPurchaseReturn)}
                  </Typography>
                  <Typography sx={{ fontSize: "11px", color: "#054BD8" }}>
                    Bank
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalPurchaseReturn.BankPurchaseReturn)}
                  </Typography>
                  <Typography sx={{ fontSize: "11px", color: "#FFAA00" }}>
                    Credit
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{"  "}
                    {handleNumber(reportData.TotalPurchaseReturn.CreditPurchaseReturn)}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: "10px" }}>
                <Typography
                  sx={{
                    fontSize: "13px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Purchase Return
                </Typography>
                <Typography sx={{ fontSize: "13px", textAlign: "center" }}>
                  <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{"  "}
                  {handleNumber(reportData.TotalPurchaseReturn.GrandTotalReturn)}
                </Typography>
              </Box>
            </Paper>
          </Paper>

          <Paper
            elevation={0}
            sx={{
              backgroundColor: "#FBFBFB !important",
              border: "1px solid #E1EAEF",
              borderRadius: "14px",
              display: "flex",
              justifyContent: "space-between",
              mb: "15px",
              px: "40px",
              py: "20px",
            }}
          >
            <Box>
              <Typography
                sx={{ color: "#0A604B", fontSize: "15px", fontWeight: "bold" }}
              >
                Purchase Summary
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ mr: "37px" }}>
                  <Typography>Gross</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalPurchase.Gross)}
                  </Typography>
                </Box>
                <Box sx={{ mr: "37px" }}>
                  <Typography>Discount</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalPurchase.Discount)}
                  </Typography>
                </Box>
                <Box sx={{ mr: "37px" }}>
                  <Typography>{VAT?"VAT":GST?"TAX":"Tax Amount"}</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalPurchase.TotalTax)}
                  </Typography>
                </Box>
                <Box>
                  <Typography>Total</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalPurchase.GrandTotal)}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Divider orientation="vertical" variant="middle" flexItem />

            <Box id="payment">
              <Typography
                sx={{ color: "#D84141", fontSize: "15px", fontWeight: "bold" }}
              >
                Purchase Return Summary
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ mr: "37px" }}>
                  <Typography>Gross</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalPurchaseReturn.Gross)}
                  </Typography>
                </Box>
                <Box sx={{ mr: "37px" }}>
                  <Typography>Discount</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalPurchaseReturn.Discount)}
                  </Typography>
                </Box>
                <Box sx={{ mr: "37px" }}>
                  <Typography>{VAT?"VAT":GST?"TAX":"Tax Amount"}</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalPurchaseReturn.TotalTax)}
                  </Typography>
                </Box>
                <Box>
                  <Typography>Total</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalPurchaseReturn.GrandTotalReturn)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>

          <Box>
          <div ref={(el) => (refs.current[2] = el)} data-section="2"/>
            <Typography
              sx={{ fontSize: "20px", fontWeight: "bold", mb: "12px" }}
            >
              Payment & Receipts
            </Typography>
            <Box sx={{ display: "flex", mb: "15px" }}>
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: "#FBFBFB !important",
                  border: "1px solid #E1EAEF",
                  borderRadius: "14px",
                  width: "50%",
                  mr: "5px",
                  py: "10px",
                  px: "20px",
                }}
              >
                <Typography
                  sx={{
                    color: "#D84141",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Receipt
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <Box sx={{ mr: "50px" }}>
                    <Typography sx={{ color: "#21C50A" }}>Cash</Typography>
                    <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"} </span>{" "}
                      {handleNumber(reportData.TotalReceipt.CashReceipt)}
                    </Typography>
                  </Box>
                  <Box sx={{ mr: "50px" }}>
                    <Typography sx={{ color: "#054BD8" }}>Bank</Typography>
                    <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"} </span>{" "}
                      {handleNumber(reportData.TotalReceipt.BankReceipt)}
                    </Typography>
                  </Box>
                  <Box sx={{ mr: "15px" }}>
                    <Typography>Total</Typography>
                    <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"} </span>{" "}
                      {handleNumber(reportData.TotalReceipt.GrandTotal)}
                    </Typography>
                  </Box>
                </Box>
                <div
                  style={{
                    display: "flex",
                    height: "6px",
                    width: "100%",
                    borderRadius: "10px",
                    backgroundColor: "#00000029",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#21C50A",
                      height: "100%",
                      width: calPersentage(
                        reportData.TotalReceipt.CashReceipt,
                        reportData.TotalReceipt.CashReceipt +
                        reportData.TotalReceipt.BankReceipt
                      )+"%",
                      borderRadius: "10px 0px 0px 10px",
                    }}
                  ></div>
                  <div
                    style={{
                      backgroundColor: "#054BD8",
                      height: "100%",
                      width: calPersentage(
                        reportData.TotalReceipt.BankReceipt,
                        reportData.TotalReceipt.BankReceipt +
                        reportData.TotalReceipt.CashReceipt
                      )+"%",
                      borderRadius: "0px 10px 10px 0px",
                    }}
                  ></div>
                </div>
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  backgroundColor: "#FBFBFB !important",
                  border: "1px solid #E1EAEF",
                  borderRadius: "14px",
                  width: "50%",
                  mr: "5px",
                  py: "10px",
                  px: "20px",
                }}
              >
                <Typography
                  sx={{
                    color: "#0A604B",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Payment
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ mr: "50px" }}>
                    <Typography sx={{ color: "#21C50A" }}>Cash</Typography>
                    <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"} </span>{" "}
                      {handleNumber(reportData.TotalPayment.CashPayment * -1)}
                    </Typography>
                  </Box>
                  <Box sx={{ mr: "50px" }}>
                    <Typography sx={{ color: "#054BD8" }}>Bank</Typography>
                    <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"} </span>{" "}
                      {handleNumber(reportData.TotalPayment.BankPayment * -1)}
                    </Typography>
                  </Box>
                  <Box sx={{ mr: "15px" }}>
                    <Typography>Total</Typography>
                    <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"} </span>{" "}
                      {handleNumber(reportData.TotalPayment.GrandTotal * -1)}
                    </Typography>
                  </Box>
                </Box>
                <div
                  style={{
                    display: "flex",
                    height: "6px",
                    width: "100%",
                    borderRadius: "15px",
                    backgroundColor: "#00000029",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#21C50A",
                      height: "100%",
                      width: calPersentage(
                        reportData.TotalPayment.BankPayment,
                        reportData.TotalPayment.CashPayment +
                          reportData.TotalPayment.BankPayment
                      )+"%",
                      borderRadius: "10px 0px 0px 10px",
                    }}
                  ></div>
                  <div
                    style={{
                      backgroundColor: "#054BD8",
                      height: "100%",
                      width: calPersentage(
                        reportData.TotalPayment.CashPayment,
                        reportData.TotalPayment.CashPayment +
                          reportData.TotalPayment.BankPayment
                      )+"%",
                      borderRadius: "0px 10px 10px 0px",
                    }}
                  ></div>
                </div>
              </Paper>
            </Box>

            <Paper
              elevation={0}
              sx={{
                backgroundColor: "#FBFBFB !important",
                border: "1px solid #E1EAEF",
                borderRadius: "14px",
                display: "flex",
                justifyContent: "space-between",
                mb: "15px",
                px: "40px",
                py: "20px",
              }}
            >
              <Box sx={{ width: "50%" }}>
                <Typography
                  sx={{
                    color: "#0A604B",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Receipt Summary
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ mr: "37px" }}>
                    <Typography>Gross</Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                      {handleNumber(reportData.TotalReceipt.Gross) || "0.00"}
                    </Typography>
                  </Box>
                  <Box sx={{ mr: "37px" }}>
                    <Typography>Discount</Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                      {handleNumber(reportData.TotalReceipt.Discount) || "0.00"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>Total</Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                      {handleNumber(reportData.TotalReceipt.GrandTotal) || "0.00"}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Divider
                sx={{ mx: "30px" }}
                orientation="vertical"
                variant="middle"
                flexItem
              />

              <Box sx={{ width: "50%" }}>
                <Typography
                  sx={{
                    color: "#D84141",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Payment Summary
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ mr: "37px" }}>
                    <Typography>Gross</Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                      {handleNumber(reportData.TotalPayment.Gross * -1)}
                    </Typography>
                  </Box>
                  <Box sx={{ mr: "37px" }}>
                    <Typography>Discount</Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                      {handleNumber(reportData.TotalPayment.Discount * -1)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>Total</Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                      {handleNumber(reportData.TotalPayment.GrandTotal * -1)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>

          <Box sx={{ mb: "15px", width: "100%" }} id="expenses">
          <div ref={(el) => (refs.current[3] = el)} data-section="3"/>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: "12px" }}>Expenses</Typography>
            <Paper
              elevation={0}
              sx={{
                backgroundColor: "#FBFBFB !important",
                border: "1px solid #E1EAEF",
                borderRadius: "14px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                px: "15px",
                py: "10px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mr: "20px" }}>
                <Box sx={{ width: "200px", mr: "35px" }}>
                {
                reportData.TotalExpense.DirectExpences !== 0 || reportData.TotalExpense.IndirectExpences !== 0 ? 
                <Doughnut
                  data={{
                    datasets: [
                      {
                        // label: "# of Votes",
                        data: [reportData.TotalExpense.DirectExpences, reportData.TotalExpense.IndirectExpences],
                        backgroundColor: ["rgb(5 75 216)", "rgb(222 240 252)"],
                        borderWidth: 0,
                      },
                    ],
                    options: {
                      cutoutPercentage: 80,
                    },
                  }}
                /> :
                reportData.TotalExpense.CashExpense !== 0 || reportData.TotalExpense.BankExpense !== 0 ? <Doughnut
                  data={{
                    datasets: [
                      {
                        label: "# of Votes",
                        data: [reportData.TotalExpense.CashExpense, reportData.TotalExpense.BankExpense],
                        backgroundColor: ["rgb(5 75 216)", "rgb(222 240 252)"],
                        borderWidth: 0,
                      },
                    ],
                    options: {
                      cutoutPercentage: 80,
                    },
                  }}
                /> : (
                  <Doughnut
                    data={{
                      datasets: [
                        {
                          data: [100],
                          backgroundColor: ["#00000029"],
                        },
                      ],
                    }}
                  />
                )}
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "15px" }}>Total</Typography>
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: "bold", mb: "10px" }}
                  >
                    <span style={{ fontSize: "12px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                    {handleNumber(reportData.TotalExpense.GrandTotal * -1)}
                  </Typography>

                  {reportData.TotalExpense.DirectExpences || reportData.TotalExpense.IndirectExpences ? 
                    <> <Typography sx={{ fontSize: "11px", color: "#054BD8" }}>
                      Direct Expenses
                    </Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                      {handleNumber(reportData.TotalExpense.DirectExpences * -1)}
                    </Typography>

                    <Typography sx={{ fontSize: "11px", color: "#FFAA00" }}>
                      Indirect Expenses
                    </Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                      {handleNumber(reportData.TotalExpense.IndirectExpences * -1)}
                    </Typography> </> 
                  : 
                    <> <Typography sx={{ fontSize: "11px", color: "#054BD8" }}>
                      Cash Expenses
                    </Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                      {handleNumber(reportData.TotalExpense.CashExpense * -1)}
                    </Typography>

                    <Typography sx={{ fontSize: "11px", color: "#FFAA00" }}>
                      Bank Expenses
                    </Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                      {handleNumber(reportData.TotalExpense.BankExpense * -1)}
                    </Typography> </>
                  }
                </Box>
              </Box>

              <Divider
                orientation="vertical"
                variant="middle"
                sx={{ mx: "14px" }}
                flexItem
              />

              <Box sx={{ width: "60%" }}>
                <Box sx={{ mb: "20px" }}>
                  <Typography sx={{ color: "#D84141", mb: "15px" }}>
                    Expenses
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                      <Typography sx={{ fontSize: "11px", color: "#21C50A" }}>
                        Cash
                      </Typography>
                      <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                        <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                        {handleNumber(reportData.TotalExpense.CashExpense * -1)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: "11px", color: "#21C50A" }}>
                        Bank
                      </Typography>
                      <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                        <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                        {handleNumber(reportData.TotalExpense.BankExpense * -1)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: "11px", color: "#21C50A" }}>
                        Credit
                      </Typography>
                      <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                        <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                        {handleNumber(reportData.TotalExpense.CreditExpense * -1)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: "11px", color: "#21C50A" }}>
                        Total
                      </Typography>
                      <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                        <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                        {handleNumber(reportData.TotalExpense.GrandTotal * -1)}
                      </Typography>
                    </Box>
                  </Box>
                  <div
                    style={{
                      display: "flex",
                      height: "6px",
                      width: "100%",
                      backgroundColor: "#00000029",
                      borderRadius: "15px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#21C50A",
                        height: "100%",
                        width:
                          calPersentage(
                            reportData.TotalExpense.CashExpense,
                            reportData.TotalExpense.CashExpense +
                              reportData.TotalExpense.BankExpense +
                              reportData.TotalExpense.CreditExpense
                          ) + "%",
                        borderRadius: "10px 0px 0px 10px",
                      }}
                    ></div>
                    <div
                      style={{
                        backgroundColor: "#054BD8",
                        height: "100%",
                        width:
                          calPersentage(
                            reportData.TotalExpense.BankExpense,
                            reportData.TotalExpense.CashExpense +
                              reportData.TotalExpense.BankExpense +
                              reportData.TotalExpense.CreditExpense
                          ) + "%",
                      }}
                    ></div>
                    <div
                      style={{
                        backgroundColor: "#FFAA00",
                        height: "100%",
                        width:
                          calPersentage(
                            reportData.TotalExpense.CreditExpense,
                            reportData.TotalExpense.CashExpense +
                              reportData.TotalExpense.BankExpense +
                              reportData.TotalExpense.CreditExpense
                          ) + "%",
                        borderRadius: "0px 10px 10px 0px",
                      }}
                    ></div>
                  </div>
                </Box>

                <Paper
                  sx={{
                    backgroundColor: "#FCF1F8",
                    display: "flex",
                    px: "20px",
                    py: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#D91E1F",
                      width: "130px",
                      lineHeight: "1",
                    }}
                  >
                    Expenses Summary
                  </Typography>
                  <Box sx={{ mr: "37px" }}>
                    <Typography>Gross</Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                      {handleNumber(reportData.TotalExpense.Gross)}
                    </Typography>
                  </Box>
                  <Box sx={{ mr: "37px" }}>
                    <Typography>Discount</Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                      {handleNumber(reportData.TotalExpense.Discount)}
                    </Typography>
                  </Box>
                  <Box sx={{ mr: "37px" }}>
                    <Typography>{VAT?"VAT":GST?"TAX":"Tax Amount"}</Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                      {handleNumber(reportData.TotalExpense.TotalTax)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>Total</Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>{CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}</span>{" "}
                      {handleNumber(reportData.TotalExpense.GrandTotal)}
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Paper>
          </Box>

          <Box sx={{ mb: "15px" }} id="others">
            <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: "12px" }}>Others</Typography>
            <Box
              sx={{
                display: "flex",
                mb: "15px",
                justifyContent: "space-between",
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: "#FBFBFB !important",
                  border: "1px solid #E1EAEF",
                  borderRadius: "14px",
                  width: "100%",
                  py: "15px",
                  px: "20px",
                }}
              >
                <Typography
                  sx={{
                    color: "#026CBC",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Journals
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ mr: "50px", display: "flex" }}>
                    <Typography sx={{ color: "#21C50A" }}>Cash :</Typography>
                    <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>
                        {" "}
                        &nbsp; {CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}{" "}
                      </span>{" "}
                      {handleNumber(reportData.TotalJournal.CashJournal)}
                    </Typography>
                  </Box>
                  <Box sx={{ mr: "50px", display: "flex" }}>
                    <Typography sx={{ color: "#054BD8" }}>Bank :</Typography>
                    <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>
                        {" "}
                        &nbsp; {CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}{" "}
                      </span>{" "}
                      {handleNumber(reportData.TotalJournal.BankJournal)}
                    </Typography>
                  </Box>
                  <Box sx={{ mr: "15px", display: "flex" }}>
                    <Typography>Total :</Typography>
                    <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                      <span style={{ fontSize: "10px" }}>
                        {" "}
                        &nbsp; {`${CurrencySymbol?CurrencySymbol:CountryName==="India"?"Rs":"SAR"}  `}
                      </span>{" "}
                      {handleNumber(reportData.TotalJournal.GrandTotal)}
                    </Typography>
                  </Box>
                </Box>
                {/* <span style="font-size: 12px;"></span> */}
                <div
                  style={{
                    display: "flex",
                    height: "6px",
                    width: "100%",
                    backgroundColor: "#00000029",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#21C50A",
                      height: "100%",
                      width:
                        calPersentage(
                          reportData.TotalJournal.CashJournal,
                          reportData.TotalJournal.BankJournal +
                            reportData.TotalJournal.CashJournal
                        ) + "%",
                      borderRadius: "10px 0px 0px 10px",
                    }}
                  ></div>
                  {/* <div style={{ backgroundColor : "#054BD8", height: "100%", width: calPersentage(40, 100) + "%", }}></div> */}
                  <div
                    style={{
                      backgroundColor: "#FFAA00",
                      height: "100%",
                      width:
                        calPersentage(
                          reportData.TotalJournal.BankJournal,
                          reportData.TotalJournal.BankJournal +
                            reportData.TotalJournal.CashJournal
                        ) + "%",
                      borderRadius: "0px 10px 10px 0px",
                    }}
                  ></div>
                </div>
              </Paper>

              {/* <Paper
              elevation={0}
              sx={{
                backgroundColor: "#FBFBFB !important",
                border: "1px solid #E1EAEF",
                borderRadius: "14px",
                width: "50%",
                px: '20px',
                py: '15px'
              }}
            >
              <Typography sx={{ fontSize: "15px", fontWeight: "bold" }} >Stock Value</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: "15px", }}>User</Typography>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>645</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "15px", }}>Damage</Typography>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>645</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "15px", }}>Exess</Typography>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>645</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "15px", }}>Shortage</Typography>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>645</Typography>
                </Box>
              </Box>
            </Paper> */}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: "#FBFBFB !important",
                  border: "1px solid #E1EAEF",
                  borderRadius: "14px",
                  width: "49%",
                  px: "15px",
                  py: "7px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mb: "10px" }}>
                  <Typography
                    sx={{ fontSize: "15px", fontWeight: "bold", mr: "10px" }}
                  >
                    Top Customer
                  </Typography>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    {/* <ThemeProvider theme={theme}> */}
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={filterOptions.customer}
                        onChange={handleCustomer}
                        sx={{
                          // Custom styles here
                          backgroundColor: "#EEE",
                          borderRadius: "30px !important",
                          fontSize: "11px",
                          // color: "white",
                          border: "none",
                          width: "100px",
                          height: "24.56px",

                          "& .MuiSelect-select": {
                            // Styles for the select element
                            border: "none",
                            // color: "blue",
                          },

                          "& .MuiSelect-icon": {
                            // Styles for the select icon
                            color: "black",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            // Styles for the focused outline of the outlined variant
                            border: "none",
                          },
                        }}
                      >
                        <MenuItem
                          sx={{
                            // backgroundColor: 'black',
                            display: "block !important",
                            pl: "5px !important",
                            fontSize: "15px",
                          }}
                          value={5}
                        >
                          Top 5
                        </MenuItem>
                        <MenuItem
                          sx={{
                            // backgroundColor: 'black',
                            display: "block !important",
                            pl: "5px !important",
                            fontSize: "15px",
                          }}
                          value={10}
                        >
                          Top 10
                        </MenuItem>
                        <MenuItem
                          sx={{
                            // backgroundColor: 'black',
                            display: "block !important",
                            pl: "5px !important",
                            fontSize: "15px",
                            // color: "white",
                          }}
                          value={20}
                        >
                          Top 20
                        </MenuItem>
                      </Select>
                    {/* </ThemeProvider> */}
                  </FormControl>
                </Box>

                <Box sx={{ flexGrow: 1, maxHeight: 400, overflowY: "scroll", pr: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}></Grid>
                        <Grid
                          sx={{ display: "flex", alignItems: "center" }}
                          item
                          xs={8}
                        >
                          {/* <Item>xs=4</Item> */}
                          <Typography>{ }</Typography>
                        </Grid>
                        <Grid
                          sx={{ display: "flex", alignItems: "center" }}
                          item
                          xs={2}
                        >
                          {/* <Item>xs=4</Item> */}
                          <Typography sx={{ color: "#A2A2A2", fontSize: "13px", textAlign: "right", width: "100%"}}>Amount</Typography>
                        </Grid>
                    {topItems.customerItems.map((data) => (
                      <>
                        <Grid item xs={2}>
                          <Avatar src={data?.customer_image} ></Avatar>
                        </Grid>
                        <Grid
                          sx={{ display: "flex", alignItems: "center" }}
                          item
                          xs={8}
                        >
                          {/* <Item>xs=4</Item> */}
                          <Typography sx={{ fontSize: "14px", fontWeight: "500"}}>{data?.customer_name}</Typography>
                        </Grid>
                        <Grid
                          sx={{ display: "flex", alignItems: "center" }}
                          item
                          xs={2}
                        >
                          {/* <Item>xs=4</Item> */}
                          <Typography sx={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "100%"}}>{formatNumber(data?.balance)}</Typography>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Box>
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  backgroundColor: "#FBFBFB !important",
                  border: "1px solid #E1EAEF",
                  borderRadius: "14px",
                  width: "49%",
                  px: "15px",
                  py: "7px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mb: "15px" }}>
                  <Typography
                    sx={{ fontSize: "15px", fontWeight: "bold", mr: "10px" }}
                  >
                    Top Products
                  </Typography>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    {/* <ThemeProvider theme={theme}> */}
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={filterOptions.product}
                        onChange={handleProduct}
                        sx={{
                          // Custom styles here
                          backgroundColor: "#EEE",
                          borderRadius: "30px !important",
                          fontSize: "11px",
                          // color: "white",
                          border: "none",
                          width: "100px",
                          height: "24.56px",

                          "& .MuiSelect-select": {
                            // Styles for the select element
                            border: "none",
                          },

                          "& .MuiSelect-icon": {
                            // Styles for the select icon
                            color: "black",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            // Styles for the focused outline of the outlined variant
                            border: "none",
                          },
                        }}
                      >
                        <MenuItem
                          sx={{
                            // backgroundColor: 'black',
                            display: "block !important",
                            pl: "5px !important",
                            fontSize: "15px",
                          }}
                          value={5}
                        >
                          Top 5
                        </MenuItem>
                        <MenuItem
                          sx={{
                            // backgroundColor: 'black',
                            display: "block !important",
                            pl: "5px !important",
                            fontSize: "15px",
                          }}
                          value={10}
                        >
                          Top 10
                        </MenuItem>
                        <MenuItem
                          sx={{
                            // backgroundColor: 'black',
                            display: "block !important",
                            pl: "5px !important",
                            fontSize: "15px",
                            // color: "white",
                          }}
                          value={20}
                        >
                          Top 20
                        </MenuItem>
                      </Select>
                    {/* </ThemeProvider> */}
                  </FormControl>
                </Box>

                <Box sx={{ flexGrow: 1, maxHeight: 400, overflowY: "scroll", pr: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={1}>
                        {/* <Item>xs=8</Item> */}
                        {/* <Avatar sx={{ bgcolor: deepOrange[500] }}>N</Avatar> */}
                        <Typography></Typography>
                      </Grid>
                      <Grid
                        sx={{ display: "flex", alignItems: "center" }}
                        item
                        xs={5}
                      >
                        <Typography>{}</Typography>
                      </Grid>
                      <Grid
                        sx={{ display: "flex", alignItems: "center" }}
                        item
                        xs={2}
                      >
                        <Typography sx={{ textAlign: "right", color: "#A2A2A2",fontSize: "13px", fontWeight: "400", width: "100%" }}>Unit Sold</Typography>
                      </Grid>
                      <Grid
                        sx={{ display: "flex", alignItems: "center" }}
                        item
                        xs={4}
                      >
                        <Typography sx={{ textAlign: "right", color: "#A2A2A2", fontSize: "13px", fontWeight: "400", width: "100%" }}>Amount</Typography>
                      </Grid>
                    {topItems.productItems.map((data, i) => (
                      <>
                        <Grid item xs={1}>
                          {/* <Item>xs=8</Item> */}
                          {/* <Avatar sx={{ bgcolor: deepOrange[500] }}>N</Avatar> */}
                          <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>{i + 1}</Typography>
                        </Grid>
                        <Grid
                          sx={{ display: "flex", alignItems: "center" }}
                          item
                          xs={5}
                        >
                          <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>{data?.ProductName}</Typography>
                        </Grid>
                        <Grid
                          sx={{ display: "flex", alignItems: "center" }}
                          item
                          xs={2}
                        >
                          <Typography sx={{ textAlign: "right", fontSize: "14px", fontWeight: "500", width: "100%" }}>{data?.unit_sold}</Typography>
                        </Grid>
                        <Grid
                          sx={{ display: "flex", alignItems: "center" }}
                          item
                          xs={4}
                        >
                          <Typography sx={{ textAlign: "right", fontSize: "14px", fontWeight: "500", width: "100%" }}>{handleNumber(data?.amount_sum)}</Typography>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default DailyReport;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "15px",
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#333333",
  // color :'black',
  "& .MuiSelect-select": { padding: "5px" },
  "& .MuiOutlinedInput-notchedOutline": {
    border:
      theme.palette.mode === "light"
        ? "1px solid #F8F8F8"
        : "1px solid #777777",
    borderRadius: "6px",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border:
      theme.palette.mode === "light"
        ? "1px solid #F8F8F8"
        : "1px solid #777777",
    borderRadius: "6px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border:
      theme.palette.mode === "light"
        ? "1px solid #F8F8F8"
        : "1px solid #777777",
    borderRadius: "6px",
  },
}));

const TopBox = styled(Box)(() => ({
  backgroundColor: "#DEF0FC",
  border: "1px solid #BFD7E3",
  borderRadius: "4px",
  padding: "7px 12px"
}));

const GrayText = styled(Typography)(() => ({
  fontSize: "14px", fontWeight: "regular", color:"#333333"
}))
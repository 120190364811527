
import axios from "axios";
export const EinvoiceURL = "http://localhost:8003/";






export const get_egs_list = async function (body) {
  
  try {
    const { data } = await axios.get("api/v11/eInvoice/egs-api/", {
      params:body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};

export const get_e_invoice_list = async function (body) {
  

  try {
    const { data } = await axios.post("api/v11/eInvoice/zatca-transaction-list/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
  };
  
  
  
export const get_e_invoice_view_api = async function (body) {
  
  try {
    const { data } = await axios.get("api/v11/eInvoice/zatca-transaction-list/", {
      params:body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
  };
  
  
  
  
  
  
  
  
  
  
  export const get_e_invoice_re_submit_api = async function (body) {

    try {
      const { data } = await axios.post("api/v11/eInvoice/re-submit-invoice-api/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
};

  export const get_e_invoice_submit_api = async function (body) {

    try {
      const { data } = await axios.post("api/v11/eInvoice/submit-invoice-api/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
};
  


export const get_invoice_validate_api = async function (body) {

  try {
    const { data } = await axios.post("api/v11/eInvoice/invoice-validate-api/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};


export const get_invoice_download_api = async function (body) {
  try {
    const response = await axios.get("api/v11/eInvoice/get-invoice-xml/", {
      params: body,
      responseType: 'blob', // Ensure the response is treated as a binary Blob (for file download)
    });

    return response;
  } catch (error) {
    console.log("Error on API", error);
    return error;
  }
};

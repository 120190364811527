import React, { useState, useEffect } from "react";
import { Box, Paper, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ClearButton,
  ListButton,
  SaveButton,
  LoadingButton,
} from "../../Components/Utils/StyledButtons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CustomVoucher,
  InvoiceAutoComplete,
  InvoiceBarCodeTextBox,
  InvoiceDateBox,
  InvoiceLedgerSearch,
  InvoiceTextBox,
  InvoiceVoucherNoTextBox,
} from "../../Components/CommonComponents";
import { openSnackbar } from "../../features/SnackBarslice";
import MyGrid from "./Details";
import { useSelector, useDispatch } from "react-redux";
import {
  billwise_datas,
  get_VoucherNo,
  get_pricelist_list_new_api,
  get_product_by_barcode_api,
  get_single_pricelist_new_api,
  get_stock_of_products_api,
  ledger_details_api,
  place_of_supply_api,
  product_search_new_api,
} from "../../Api/CommonApi/CommonApis";
import {
  PurchaseReturnValidation,
  SalesInvoiceCalc,
  SalesLineCalc,
} from "./functions";
import { debounce } from "../../Function/comonFunction";
import NotesTextArea from "../../Components/Utils/TextArea";
import SummaryDetails from "./SummaryDetails";
import {
  Call_PurchaseReturn_details,
  CreatePurchaseReturn,
  LedgerDetails,
  PurchaseReturnDetails,
  PurchaseReturnEdit,
  PurchaseReturnList,
} from "../../Api/Purchase/PurchaseReturnApi";
import { CallEmployees } from "../../Api/Reports/CommonReportApi";
import { create_user_address, list_user_address } from "../../Api/User/User";
import { AddressModal } from "../../Components/CommonForms";

const CreateParchaseReturn = () => {
  const [t, i18n] = useTranslation("common");
  const navigate = useNavigate();
  const [IsButtonClick, setIsButtonClick] = useState(false);
  let today = new Date();
  today.setDate(today.getDate());
  let DefaultDate = today.toISOString().substr(0, 10);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [is_manual_roundoff, setIs_manual_roundoff] = useState(false);
  const { Country, State } = useSelector((state) => state.companyDetails);
  const [IsBillwise, setBillwise] = useState(false);
  const [openCustomVoucher, setopenCustomVoucher] = useState(false);
  const [OpenBillWise, setOpenBillWise] = useState(false);
  const [IsBilling, setIsBilling] = useState(false);

  const PurchaseAccount = 70;

  const {
    PriceDecimalPoint,
    EnableBranch,
    EnableProductBatchWise,
    GST,
    VAT,
    CurrencySymbol,
    EnableSalesManInSales,
    PurchasePriceInSales,
    EnableBillwise,
    show_brand_InsearchSale,
    show_stock_InsearchSale,
    show_purchasePrice_InsearchSale,
    show_productcode_InsearchSale,
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);

  let GeneralSettingsData = {
    EnableSalesManInSales,
    PriceDecimalPoint,
    PurchasePriceInSales,
    EnableBillwise,
    PurchasePriceInSales,
    show_brand_InsearchSale,
    show_stock_InsearchSale,
    show_purchasePrice_InsearchSale,
    show_productcode_InsearchSale,
  };

  const [dataState, setDataState] = useState({
    Seperator: null,
    Suffix: null,
    SuffixSeparator: null,
    PreFix: null,
    InvoiceNo: null,
    VoucherNo: "",
    deletedData: [],
    AccountLedgerData: [],
    CashList: [],
    BankList: [],
    CountryData: [],
    EmployeeData: [],
    TaxCategoryData: [],
    UserTableData: [],
    WarehouseData: [],
    PlaceOfSupplyData: [],
    PriceCategoryData: [],
    ProductList: [],
    ProductCodeList: [],
    BillWiseDatas: [],
  });

  const [state, setState] = useState({
    AccountLedger: null,
    CashAccount: null,
    BankAccount: null,
    Employee: null,
    Warehouse: null,
    PriceCategory: null,
    Treatment: null,
    PlaceOfSupply: null,
    Date: DefaultDate,
    VenderInvoiceDate: DefaultDate,
    RefferenceBillNo: "",
    RefferenceBillDate: DefaultDate,
    BillDiscPercent: 0,
    BillDiscAmt: 0,
    CashAmount: 0,
    BankAmount: 0,
    Notes: "",
    BillingAddress:null,
    //Total
    Warehouse: null,
    TotalQty: 0,
    TotalGrossAmt: 0,
    TotalDiscount: 0,
    TotalTax: 0,
    NetTotal: 0,
    TotalTaxableAmount: 0,
    TaxTaxableAmount: 0,
    NonTaxTaxableAmount: 0,
    GrandTotal: 0,
    LedgerValue: "",
    RoundOff: "0",
    VAT_Treatment: "",
  });

  const [tableSettings, setTableSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: true,
    Product: true,
    Description: true,
    Unit: true,
    Qty: true,
    FQty: true,
    Rate: true,
    Tax: true,
    InclusivePrice: true,
    GrossAmount: true,
    DiscountPerc: true,
    DiscountAmount: true,
    TaxAmount: true,
    Amount: true,
    Activity: true,
  });
  const [focusSettings, setFocusSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: true,
    Product: true,
    Description: true,
    Unit: true,
    Qty: true,
    FQty: true,
    Rate: true,
    Tax: true,
    InclusivePrice: true,
    GrossAmount: true,
    DiscountPerc: true,
    DiscountAmount: true,
    TaxAmount: true,
    Amount: true,
    Activity: true,
  });
  let DataListItem = {
    ProductCode: null,
    BarCode: "",
    Product: null,
    Description: "",
    Unit: null,
    UnitList: [],
    Stock: "",
    Qty: "",
    FQty: "",
    Rate: "",
    AvgCost: "",
    Tax: null,
    InclusivePrice: "",
    GrossAmount: "",
    DiscountPerc: "",
    DiscountAmount: "",
    // ExciseTax: "",
    TaxAmount: "",
    Amount: "",
    MRP: "",
    PurchasePrice: "",
    BatchCode: "",
    MinimumSalesPrice: "",
    AverageCost: "",
    TaxableAmount: 0,
    NonTaxableAmount: 0,
  };
    
  const[BillingAddress,setBillingAddress] = useState({
    Attention : "", //Name
    PostalCode : "", //Pincode
    Address1 : "", //Building
    Address2 : "", //Area
    City : "",
    District : "",
    country : null,
    state : null,
    Mobile : "",
    CountryData:[],
    StateData:[],
    BillingAddressList:[],
  }) 
  
  const location = useLocation();
  const is_customer =
    state.AccountLedger?.AccountGroupUnder !== 9 &&
    state.AccountLedger?.AccountGroupUnder !== 8;
  const params = new URLSearchParams(window.location.search);
  const uniq_id = location.state?.uniq_id ?? params.get("unq_id");
  const is_edit = location.state?.is_edit ?? params.get("is_edit");

  const TreatmentData = [
    { value: "0", name: "Registered Business - Regular", is_gst: true },
    { value: "1", name: "Registered Business - Composition", is_gst: true },
    { value: "2", name: "Consumer", is_gst: true, default: true },
    { value: "4", name: "Overseas", is_gst: true },
    { value: "5", name: "Special Economic Zone", is_gst: true },
    { value: "6", name: "Deemed Export", is_gst: true },
    { value: "0", name: "Business to Business(B2B)", is_vat: true },
    { value: "1", name: "Unregistered Business", is_vat: true, default: true },
    { value: "7", name: "Import", is_vat: true },
  ];

  const GST_TreatmentData = TreatmentData.filter((i) => i.is_gst === true);
  const VAT_TreatmentData = TreatmentData.filter((i) => i.is_vat === true);

  const [DataList, setDataLists] = useState([DataListItem]);

  const [defaultValue, setDefaultValue] = useState({
    AccountLedger: null,
    CashAccount: null,
    BankAccount: null,
    PriceCategory: null,
    Warehouse: null,
    Treatment: null,
    PlaceOfSupply: null,
    // ShippingTax: null,
  });

  // Change table Custamization
  const ChangeTableCustomization = (type, name) => {
    let value = null;
    if (type === "tableSettings") {
      value = !tableSettings[name];
      setTableSettings({
        ...tableSettings,
        [name]: value,
      });
    } else {
      value = !focusSettings[name];
      setFocusSettings({
        ...focusSettings,
        [name]: value,
      });
    }
  };

  const onDetailsInputChange = async (e, name) => {
    if (e && name) {
      let value = e.target.value;
      if ((name === "Product" || name === "ProductCode") && value) {
        const ProductList = await Call_ProductSearchFunction({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state?.Warehouse.WarehouseID,
          is_product_image: false,
          length: value?.length,
          product_name: value,
          type: "Purchase",
        });
        setDataState({ ...dataState, ProductList: ProductList });
      }
    }
  };

  const debouncedOnDetailsInputChange = debounce(onDetailsInputChange, 300);

  const Call_ProductSearchFunction = async (payload) => {
    let data = [];
    const Response = await product_search_new_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ? Response.message : "API error",
          severity: "error",
        })
      );
    }
    return data;
  };
  const Call_PriceList_list_Function = async (payload) => {
    let data = [];
    const Response = await get_pricelist_list_new_api(payload);
    console.log(Response, "Response");
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  const Call_Single_PriceList_Function = async (payload) => {
    let data = null;
    const Response = await get_single_pricelist_new_api(payload);
    console.log(Response, "Response");
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = null;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  const onDetailsAutoChange = async (v, index, name) => {
    console.log(v, name, "333333333");
    if (v && name && v?.id) {
      let Data = DataList;
      let lineItem = Data[index];

      if (name === "Product" || name === "ProductCode") {
        let Product = v;
        let UnitList = await Call_PriceList_list_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          product_id: Product.id,
          type_data: "purchase", //______________----------------------------------------------------------------------------------------------------------------------------
        });

        let Unit = UnitList.find((i) => i.DefaultUnit === true);
        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.Warehouse.WarehouseID,
          type: "purchase"
        });
        let Rate = 0;
        let Qty = 1;
        let FQty = 0;
        let DiscountPerc = 0;
        let DiscountAmount = 0;

        if (UnitDetails) {
          Rate = Number(UnitDetails.Rate);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.BarCode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
          lineItem.is_inclusive = UnitDetails.is_inclusive;
          lineItem.Stock = UnitDetails.Stock;

          lineItem.SalesPrice = UnitDetails.SalesPrice;
          lineItem.SalesPrice1 = UnitDetails.SalesPrice1;
          lineItem.SalesPrice2 = UnitDetails.SalesPrice2;
          lineItem.SalesPrice3 = UnitDetails.SalesPrice3;
        }
        let Tax = null;
        if(state.Treatment.value =="0"){
          Tax = dataState.TaxCategoryData.find((i) => i.TaxID === Product.ProductTaxID);
        }
        else{
          Tax = dataState.TaxCategoryData.find((i) => i.TaxName === "None");
        }
      
        lineItem.Product = Product;
        lineItem.ProductCode = Product;
        lineItem.Description = Product.Description;
        lineItem.Stock = Product.Stock;
        lineItem.Qty = Qty;
        lineItem.FQty = FQty;
        lineItem.Tax = Tax;
        lineItem.DiscountPerc = DiscountPerc;
        lineItem.DiscountAmount = DiscountAmount;

        lineItem.Unit = Unit;
        lineItem.UnitList = UnitList;

        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      } else if (name === "Unit") {
        let Unit = v;

        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.Warehouse.WarehouseID,
        });
        let Rate = 0;

        if (UnitDetails) {
          Rate = Number(UnitDetails.PurchasePrice);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.BarCode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
        }
        lineItem.Unit = Unit;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem
        );

        Data[index] = calculated_lineItem;
        setDataLists([...Data]);
      } else if (name === "Tax") {
        let Tax = v;
        lineItem.Tax = Tax;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      }
    }
  };

  const onDetailsChange = async (e, index) => {
    if (e) {
      let value = e.target.value;
      let name = e.target.name;
      let Data = DataList;
      console.log(
        name,
        value,
        "name,value:LLLLLLLLLLLLLLL:::::::::::::::::::::::::::"
      );
      let lineItem = Data[index];
      lineItem[name] = value;

      // handling Line Discount
      let Amount = lineItem.GrossAmount //+ lineItem.TaxAmount
      if (name === "DiscountAmount") {
        lineItem.DiscountPerc = 100 * (Number(value) / Number(Amount));
      } else if (name === "DiscountPerc") {
        lineItem.DiscountAmount = (Number(value) / 100) * Number(Amount);
      }

      let calculated_lineItem = await SalesLineCalc(dataState, state, lineItem);

      Data[index] = calculated_lineItem;
      setDataLists([...DataList]);
    }
  };

  const AddLine = () => {
    let Data = DataList;
    let newData = DataListItem;
    Data.push(newData);
    setDataLists([...Data]);
  };

  const RemoveLine = (index) => {
    let Data = DataList;
    let deletedData = [];
    if (index || index === 0) {
      if (index > -1 && index < Data.length) {
        if (is_edit) {
          deletedData.push(Data[index]);
        }

        Data.splice(index, 1);
      }
    }
    let newState = SalesInvoiceCalc(dataState, state, Data);
    setState({ ...newState, deletedData: deletedData });

    setDataLists([...Data]);
  };

  const [Errors, setErrors] = useState({
    field: null,
    message: null,
    error: false,
    indexList: [],
  });

  const onMasterChange = (e) => {
    if (e) {
      const { name, value } = e.target;

      //checking the field is included in MasterCalcFields
      const isCalcField = MasterCalcFields.includes(name);

      if (isCalcField) {
        state[name] = value;

        //handling bill disc
        if (name === "BillDiscAmt") {
          state.BillDiscPercent =
            100 * (Number(value) / Number(state.NetTotal));
        } else if (name === "BillDiscPercent") {
          state.BillDiscAmt = (Number(value) / 100) * Number(state.NetTotal);
        }

        let newState = SalesInvoiceCalc(
          dataState,
          state,
          DataList,
          is_manual_roundoff
        );
        setState({ ...newState });
      } else {
        setState((prevState) => ({ ...prevState, [name]: value }));
      }
    }
  };


  const MasterCalcFields = [
    "BillDiscPercent",
    "BillDiscAmt",
    "CashAmount",
    "BankAmount",
    "RoundOff",
  ];

  const handleSubmit = async (e, IsBillWise = true) => {
    console.log(IsBillwise, ";;;;;;;;;;;;;;;;;;;;;;;;", IsBillWise);
    setErrors({
      field: null,
      message: null,
      error: false,
      indexList: [],
    });

    let { error, message, field, indexList } = await PurchaseReturnValidation(
      dataState,
      state,
      DataList,
      GeneralSettingsData
    );
    console.log(error, "errrrrrrrrrrrrrrrrrorrrorooroooooo");
    if (error) {
      let errors = {
        error,
        message,
        field,
        indexList,
      };
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          severity: "warning",
        })
      );
      setErrors({ ...state, errors });
    } else {
      let Details = [];
      DataList.map((i) => {
        if (i.Product) {
          let item = {
            id: i.id,
            ProductID: i.Product.ProductID,
            Qty: i.Qty,
            UnitPrice: i.Rate,
            InclusivePrice: i.InclusivePrice,
            RateWithTax: i.Rate,
            Description: i.Description ?? "",
            DiscountAmount: i.DiscountAmount,
            GrossAmount: i.GrossAmount,
            TaxableAmount: i.TaxableAmount,
            TaxAmount: i.TaxAmount,
            NetAmount: i.Amount,
            FreeQty: i.FQty,
            Tax: i.Tax,
            // CostPerPrice : i.Rate,
            PriceListID: i.Unit.PriceListID,
            DiscountPerc: i.DiscountPerc,
            // VATPerc : i.Rate,
            BatchCode: i.BatchCode,
            Description: i.Description,
            ProductTaxID: i.Tax.TaxID,
            NonTaxableAmount: i.NonTaxableAmount,
            PurchasePrice: i.PurchasePrice,
          };
          Details.push(item);
        }
      });

      let payload = {
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        PriceRounding: PriceDecimalPoint,
        Details: Details,
        is_manual_roundoff: is_manual_roundoff,
        uniq_id: uniq_id, //uniq_id

        ReffNo: state.ReffNo,
        DeliveryDate: state.RefferenceBillDate,
        // ------
        Date: state.Date,
        VenderInvoiceDate: state.VenderInvoiceDate,
        Seperator: dataState.Seperator,
        Suffix: dataState.Suffix,
        SuffixSeparator: dataState.SuffixSeparator,
        PreFix: dataState.PreFix,
        InvoiceNo: dataState.InvoiceNo,
        VoucherNo: dataState.VoucherNo,
        // ---------
        BillwiseDetails: dataState.BillWiseDatas,
        LedgerID: state.AccountLedger.LedgerID,
        PriceCategoryID: state.PriceCategory.PriceCategoryID,
        SalesAccount: 86,
        EmployeeID: state.Employee,
        CustomerName: state.AccountLedger.LedgerName,
        Treatment: state.Treatment.value,
        State_of_Supply: state.PlaceOfSupply.id,
        Country_of_Supply: state.PlaceOfSupply.Country,
        BillDiscPercent: state.BillDiscPercent,
        BillDiscAmt: state.BillDiscAmt,
        CashAmount: state.CashAmount,
        BankAmount: state.BankAmount,
        Notes: state.Notes,
        RoundOff: state.RoundOff,
        VAT_Treatment: state.VAT_Treatment,
        WarehouseID: state.Warehouse.WarehouseID,
        // Total
        TotalQty: state.TotalQty,
        TotalGrossAmt: state.TotalGrossAmt,
        TotalDiscount: state.TotalDiscount,
        TotalTax: state.TotalTax,
        NetTotal: state.NetTotal,
        TotalTaxableAmount: state.TotalTaxableAmount,
        TaxTaxableAmount: state.TaxTaxableAmount,
        NonTaxTaxableAmount: state.NonTaxTaxableAmount,
        GrandTotal: state.GrandTotal,
        RefferenceBillDate: state.Date,
        PurchaseAccount: PurchaseAccount,
        RefferenceBillNo: state.RefferenceBillNo,
        BillingAddress:state.BillingAddress?.id,

      };

      let responseData = null;
      if (IsBillwise === true && IsBillWise === true) {
        setOpenBillWise(true);
      } else {
        if (is_edit && uniq_id) {
          setIsButtonClick(true);
          responseData = await PurchaseReturnEdit(payload);
        } else {
          setIsButtonClick(true);
          responseData = await CreatePurchaseReturn(payload);
        }
        if (responseData?.StatusCode === 6000) {
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Created Successfully",
              severity: "success",
            })
          );
          clearData();
        } else if (responseData?.StatusCode === 6001) {
          setIsButtonClick(false);
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Something went wrong!",
              severity: "warning",
            })
          );
        } else if (responseData?.StatusCode === 6002) {
          setIsButtonClick(false);
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Something went wrong!",
              severity: "error",
            })
          );
        } else {
          setIsButtonClick(false);
          dispatch(
            openSnackbar({
              open: true,
              message:
                responseData?.message + " : " + responseData?.error ??
                "Something went wrong!",
              severity: "error",
            })
          );
        }
      }
    }
  };

  // Fetching Data For MAster
  const fetchData = async () => {
    let ResponseView = null;
    let AccountLedgerData = [];
    let CashList = [];
    let BankList = [];
    let CountryData = [];
    let EmployeeData = [];
    let PriceCategoryData = [];
    let TaxCategoryData = [];
    let UserTableData = [];
    let WarehouseData = [];
    let Seperator = "";
    let Suffix = "";
    let SuffixSeparator = "";
    let PreFix = "";
    let InvoiceNo = "";
    let VoucherNo = "";
    let PlaceOfSupplyData = [];
    let BillWiseDatas = [];
    let Employee = null;
    let RefferenceBillNo = "";
    let BillDiscPercent = 0;
    let BillDiscAmt = 0;
    let Notes = "";
    let PriceCategory = null;
    let Date = "";
    let VenderInvoiceDate = "";
    let RoundOff = "";

    const Data = await PurchaseReturnDetails({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
    });

    if (Data.StatusCode === 6000) {
      const PlaceOfSupplyResponse = await place_of_supply_api(Country);
      if (PlaceOfSupplyResponse.StatusCode === 6000) {
        PlaceOfSupplyData = PlaceOfSupplyResponse.data;
      }

      AccountLedgerData = Data.AccountLedgerData;
      let CashAndBankData = Data.CashAndBankData;
      if (CashAndBankData) {
        CashList = CashAndBankData.filter((i) => i.AccountGroupUnder === 9);
        BankList = CashAndBankData.filter((i) => i.AccountGroupUnder === 8);
      }

      CountryData = Data.CountryData;
      EmployeeData = null;
      PriceCategoryData = Data.PriceCategoryData;
      TaxCategoryData = Data.TaxCategoryData;
      UserTableData = Data.UserTableData;
      WarehouseData = Data.WarehouseData;
      
      Date =  DefaultDate;
      VenderInvoiceDate = DefaultDate;

      let VoucherNoGenerateTableData = Data.VoucherNoGenerateTableData;
      Seperator = VoucherNoGenerateTableData.Seperater;
      Suffix = VoucherNoGenerateTableData.Suffix;
      SuffixSeparator = VoucherNoGenerateTableData.SuffixSeparator;
      PreFix = VoucherNoGenerateTableData.PreFix;
      InvoiceNo = VoucherNoGenerateTableData.InvoiceNo;
      VoucherNo = VoucherNoGenerateTableData.VoucherNo;

      //Default Values
      let AccountLedger = AccountLedgerData.find((i) => i.is_default === true);

      let Warehouse = WarehouseData.find(
        (i) => i.WarehouseID === UserTableData.DefaultWarehouse
      );
      let Treatment = VAT
        ? VAT_TreatmentData.find((i) => i.default === true)
        : GST
        ? GST_TreatmentData.find((i) => i.default === true)
        : null;
      let PlaceOfSupply = PlaceOfSupplyData.find((i) => i.id === State);
      PriceCategory = PriceCategoryData.find((i) => (i.PriceCategoryID = 1));
      let CashAccount = CashList[0];
      let BankAccount = BankList[0];
      setDefaultValue((prev) => {
        return {
          ...prev,
          AccountLedger: AccountLedger,
          CashAccount: CashAccount,
          BankAccount: BankAccount,
          PriceCategory: PriceCategory,
          Warehouse: Warehouse,
          Treatment: Treatment,
          PlaceOfSupply: PlaceOfSupply,
          // ShippingTax:ShippingTax,
        };
      });

      if (is_edit && uniq_id) {
        ResponseView = await PurchaseReturnList({
          BranchID: BranchID,
          CompanyID: CompanyID,
          uniq_id: uniq_id,
        });
        console.log(ResponseView.data, "resssspppppppppppppoooooooooooo");
        if (ResponseView) {
          AccountLedger = await Call_LedgerDetials(
            ResponseView.data.AccountLedger
          );
          Treatment = ResponseView.data.Treatment;
          let PlaceOfSupplyName = ResponseView.data.Place_of_Supply;
          PlaceOfSupply = PlaceOfSupplyData.find(
            (i) => i.Name === PlaceOfSupplyName
          );
          RefferenceBillNo = ResponseView.data.RefferenceBillNo;
          CashAccount = ResponseView.data.CashAccount;
          BankAccount = ResponseView.data.BankAccount;
          BillWiseDatas = ResponseView.data.BillwiseDetails;
          Employee = null;
          VoucherNo = ResponseView.data.VoucherNo;
          BillDiscPercent = ResponseView.data.BillDiscPercent;
          BillDiscAmt = ResponseView.data.BillDiscAmt;
          Notes = ResponseView.data.Notes;
          Date = ResponseView.data.VoucherDate;
          VenderInvoiceDate = ResponseView.data.VenderInvoiceDate
          PriceCategory = PriceCategoryData.find(
            (i) => i.PriceCategoryID === ResponseView.data.PriceCategoryID
          );
          Warehouse = ResponseView.data.Warehouse;
          RoundOff = ResponseView.data.RoundOff;

          setIs_manual_roundoff(ResponseView.data.is_manual_roundoff);

          let TotalQty = Number(ResponseView.data.TotalQty);
          let noOfPages = Math.ceil(TotalQty / 100);
          // let noOfPages = 1;
          let data = [];
          for (let i = 1; i <= noOfPages; i++) {
            let DetailsReponse = await Call_PurchaseReturn_details({
              BranchID: BranchID,
              CompanyID: CompanyID,
              uniq_id: uniq_id,
              page: i,
            });
            console.log(DetailsReponse, "-------------->>Details response ");
            if (DetailsReponse) {
              data = [...data, ...DetailsReponse.data];
            }
          }
          console.log(data, "----------------->>Printing data");
          setDataLists([...data]);
        }
      }

      setState((prev) => {
        return {
          ...prev,
          ...ResponseView,
          AccountLedger,
          Warehouse,
          Treatment,
          PlaceOfSupply,
          CashAccount,
          BankAccount,
          PriceCategory,
          RefferenceBillNo,
          Employee,
          BillDiscPercent,
          BillDiscAmt,
          Notes,
          Date,
          VenderInvoiceDate,
          RoundOff
        };
      });

      setDataState((prev) => {
        return {
          ...prev,
          AccountLedgerData,
          CashList,
          BankList,
          CountryData,
          EmployeeData,
          PriceCategoryData,
          TaxCategoryData,
          UserTableData,
          WarehouseData,
          Seperator,
          Suffix,
          SuffixSeparator,
          PreFix,
          InvoiceNo,
          VoucherNo,
          PlaceOfSupplyData,
          BillWiseDatas,
        };
      });


      setBillingAddress({...BillingAddress,CountryData,StateData:PlaceOfSupplyData})

    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Data?.message ?? "get salesreturn Api Error",
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const onMasterAutoChange = async (e, v, name) => {
    if (v && name) {
      const isCalcField = MasterCalcFields.includes(name);
      let value = v;
      if (name === "AccountLedger") {
        let data = await Call_LedgerDetials(value);
        let Treatment = VAT_TreatmentData.find(
          (i) => i.value === data.Treatment
        );

        let PriceCategory = dataState.PriceCategoryData.find(
          (i) => i.PriceCategoryID === 1
        );
        if (data.PriceCategoryID) {
          PriceCategory = dataState.PriceCategoryData.find(
            (i) => i.PriceCategoryID === data.PriceCategoryID
          );
        }
        
        if (value.Treatment === null){

        Treatment = VAT
        ? VAT_TreatmentData.find((i) => i.default === true)
        : GST
        ? GST_TreatmentData.find((i) => i.default === true)
        : null;
          
        } 

        let Data = DataList;
        let Tax = null;
        console.log(value, Data, "----------->>def");
        Data.map((lineItem, index) => {
            if (lineItem.Product) {  // Ensure lineItem.Product is not null or undefined
                if (value.Treatment === "0" || value.Treatment === "7") {
                    Tax = dataState.TaxCategoryData.find((i) => i.TaxID === lineItem.Product.ProductTaxID);
                }
                else if (value.Treatment === null){
                  Tax = dataState.TaxCategoryData.find((i) => i.TaxName === "None");
                }
                else {
                    Tax = dataState.TaxCategoryData.find((i) => i.TaxName === "None");
                }
                lineItem.Tax = Tax;
            } else {
                console.warn(`Product is null or undefined for line item at index ${index}`);
            }
        });
    
        // setState({ ...state, [name]: value });


        // setState({ ...newState });

        setDataLists([...Data]);
        setState({
          ...state,
          [name]: data,
          Treatment,
          ShippingAddress: null,
          BillingAddress: null,
          PriceCategory,
        });
      }

      else if (name === "Warehouse"){
        let Data = DataList
        let payload = DataList.map(object => object?.Unit?.PriceListID);
        let StockData = await Call_Stock_api({
          CompanyID: CompanyID,
          BranchID: BranchID,
          PriceListIDList : payload ,
          WarehouseID:value.WarehouseID
        })

        Data.map((lineItem,index)=>{
          if (lineItem.Product){
            lineItem.Stock =  StockData.find((i)=> i.PriceListID === lineItem.Unit.PriceListID)?.Stock ?? 0
            Data[index] = lineItem
          }
        })

        setDataLists([...Data]);
        setState({...state,[name]:value})
        
      }
      else if (name === "Treatment") {
        let Data = DataList;
    
        let Tax = null;
        console.log(value, Data, "----------->>def");
        Data.map((lineItem, index) => {
            if (lineItem.Product) {  // Ensure lineItem.Product is not null or undefined
                if (value.value === "0") {
                    Tax = dataState.TaxCategoryData.find((i) => i.TaxID === lineItem.Product.ProductTaxID);
                } else {
                    Tax = dataState.TaxCategoryData.find((i) => i.TaxName === "None");
                }
                lineItem.Tax = Tax;
               
            } else {
                console.warn(`Product is null or undefined for line item at index ${index}`);
            }
        });
    
        setDataLists([...Data]);
        setState({ ...state, [name]: value });
    }
    
      else if (isCalcField) {
        state[name] = value;
        
        let newState = SalesInvoiceCalc(
          dataState,
          state,
          DataList,
          is_manual_roundoff
        );
        setState({ ...newState });
      } else {
        setState({ ...state, [name]: value });
      }

    }

  };


    //get states of a give country api  
    const Call_States_of_Country_api = async(payload) =>{
      let data = null
      const Response = await place_of_supply_api(payload)
      console.log(Response,"Response");
      if (Response.StatusCode === 6000){
        data = Response.data
      }
      else if (Response.StatusCode === 6001){
        data = null
      }
      else{
        dispatch(
          openSnackbar({
            open: true,
            message: Response?.message ?? "Api Error",
            severity: "error",
          })
        );
      }
      
      return data
    }
    
    

      
  const Call_Stock_api = async(payload) =>{
    let data = null
    const Response = await get_stock_of_products_api(payload)
    console.log(Response,"Response");
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
  }
  

  const onMasterInputChange = async (search, name) => {
    if (search && name) {
      let length = search.length;
      let lineItem = DataListItem;

      if (name === "AccountLedger") {
        const LedgerList = await LedgerDetails({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          ledger_name: search,
          length: length,
          load_data: false,
          type_invoice: "PurchaseInvoice",
        });
        console.log(LedgerList);
        if (LedgerList.StatusCode === 6000) {
          let AccountLedgerData = [...LedgerList.data];
          if (state.Treatment.value = "1" || state.Treatment.value === "7"){
          console.log("hello this is a new data~");   
          }
          setDataState({ ...dataState, AccountLedgerData: AccountLedgerData });
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: LedgerList?.message ?? "Api Error",
              severity: "error",
            })
          );
        }}

        else if (name == "Treatment"){
         const value  =  state.Treatment.value;
         if ( value == "1" || value == "7"){
          lineItem.Tax = 30
         }
        }
        

      // } else if (name === "Employee") {
      //   const EmployeeList = await Call_Employees_api({
      //     CompanyID: CompanyID,
      //     BranchID: BranchID,
      //     CreatedUserID: user_id,
      //     PriceRounding: Number(PriceDecimalPoint || 2),
      //     search: search,
      //     list_type: "sales",
      //   });
      //   const uniqueSalesmansData = EmployeeList.filter((salesman) => {
      //     // Check if the salesman id is not already in EmployeesList
      //     return !dataState.EmployeeData.some(
      //       (employee) => employee.id === salesman.id
      //     );
      //   });
        //  let EmployeeData = [...dataState.EmployeeData, ...uniqueSalesmansData];
        //  setDataState({ ...dataState, EmployeeData: EmployeeData });
      //  }
    }
  };
  const Call_LedgerDetials = async (data) => {
    console.log(data, "Printing ===============>>>");
    const LedgerDetails = await ledger_details_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      ledger_id: data?.id,
      PriceRounding: PriceDecimalPoint,
    });

    if (LedgerDetails.StatusCode === 6000) {
      let AccountLedger = data; //state.AccountLedger
      AccountLedger["Treatment"] = LedgerDetails.data.Treatment;
      AccountLedger["TaxNo"] = LedgerDetails.data.TaxNo;
      AccountLedger["Mobile"] = LedgerDetails.data.Mobile;
      AccountLedger["BillingAddress"] = LedgerDetails.data.BillingAddress;
      AccountLedger["RouteName"] = LedgerDetails.data.RouteName;
      AccountLedger["Balance"] = LedgerDetails.data.Balance;
      AccountLedger["PriceCategoryID"] = LedgerDetails.data.PriceCategoryID;

      // setState({...state,AccountLedger:AccountLedger})
      Call_BillwiseDetails(AccountLedger);
      return AccountLedger;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: LedgerDetails?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
  };
  const Call_Employees_api = async (payload) => {
    let data = [];
    const Response = await CallEmployees(payload);
    console.log(Response, "Response");
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Seles Man Search Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  const Call_BillwiseDetails = async (data) => {
    try {
      if (!uniq_id && !is_edit) {
        const billWiseDataResponse = await billwise_datas({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          LedgerID: data?.LedgerID ?? 0,
          PaymentVoucherNo: dataState.VoucherNo,
          PaymentVoucherType: "SE",
          PriceRounding: PriceDecimalPoint,
          call_type: "list_type",
        });

        setDataState((prevState) => ({
          ...prevState,
          BillWiseDatas:
            billWiseDataResponse.StatusCode === 6000
              ? billWiseDataResponse.data
              : [],
        }));
        if (billWiseDataResponse?.data?.length) {
          setBillwise(true);
        } else {
          setBillwise(false);
        }
      } else {
        console.log("Passs");
      }
    } catch (error) {
      console.error("Error fetching billwise details:", error);
      setDataState({
        ...dataState,
        BillWiseDatas: [],
      });
    }
  };

  const addByBarCode = async (text) => {
    let Data = DataList;
    let index = Data.length;
    let lineItem = DataListItem;
    console.log(lineItem, "lineItem--------------");
    let Product = await Call_Barcode_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      BarCode: text,
    });

    if (Product) {
      let UnitList = await Call_PriceList_list_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        product_id: Product.id,
        type_data: "PR", // ------------------------------------------------------------------------------------------------------------------------
      });

      let Unit = UnitList.find((i) => i.DefaultUnit === true);
      let PriceListID = Unit.PriceListID;

      let UnitDetails = await Call_Single_PriceList_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        PriceListID: PriceListID,
        PriceRounding: Number(PriceDecimalPoint || 2),
        WarehouseID: state.Warehouse.WarehouseID,
      });

      let Rate = 0;
      let Qty = 1;
      let FQty = 0;
      let DiscountPerc = 0;
      let DiscountAmount = 0;

      if (UnitDetails) {
        Rate = Number(UnitDetails.Rate);

        lineItem.MRP = UnitDetails.MRP;
        lineItem.Rate = Rate;
        lineItem.PurchasePrice = UnitDetails.PurchasePrice;
        lineItem.BarCode = UnitDetails.BarCode;
        lineItem.BatchCode = UnitDetails.BatchCode;
        lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
        // lineItem.AverageCost = UnitDetails.AverageCost
      }

      let Tax = dataState.TaxCategoryData.find(
        (i) => i.TaxID === Product.ProductTaxID
      );

      lineItem.Product = Product;
      lineItem.ProductCode = Product;
      lineItem.Description = Product.Description;
      lineItem.Stock = Product.Stock;
      lineItem.Qty = Qty;
      lineItem.FQty = FQty;
      lineItem.Tax = Tax;
      lineItem.DiscountPerc = DiscountPerc;
      lineItem.DiscountAmount = DiscountAmount;

      lineItem.Unit = Unit;
      lineItem.UnitList = UnitList;

      let calculated_lineItem = await SalesLineCalc(dataState, state, lineItem);

      Data.push(calculated_lineItem);

      setDataLists([...Data]);
    }
  };

    
  const openBillingAddressModal  = async() =>{
    setIsBilling(true)
    let res = await Create_BillingAddress(BillingAddress,"list")
    if (res){
        setBillingAddress({...BillingAddress,BillingAddressList:res})
    }
  }
  

    
  //==============CREATE/UPDATE BILLING ADDRESS==================
  const Create_BillingAddress = async(payload,method) =>{
    
    if (method === "list"){
      payload.CompanyID = CompanyID
      payload.BranchID=BranchID
      payload.LedgerID=state.AccountLedger.LedgerID
      payload.Type = "BillingAddress"	
      const list_resposne = await list_user_address({
      CompanyID : CompanyID,
      BranchID:BranchID,
      LedgerID:state.AccountLedger.LedgerID,
      Type : "BillingAddress"	,
      })
      if (list_resposne.StatusCode===6000){
        return list_resposne.data
      }
    }
    else if(
      !payload.Attention||
      !payload.PostalCode||
      !payload.Address1||
      !payload.Address2||
      !payload.City||
      !payload.District||
      !payload.country||
      !payload.state||
      !payload.Mobile
    ){
      dispatch(
        openSnackbar({
          open: true,
          message: "Please fill all mandatory fields",
          severity: "error",
        })
      );
    }
    else if (method==="create"){
      
      payload.CompanyID = CompanyID
      payload.BranchID=BranchID
      payload.LedgerID=state.AccountLedger.LedgerID
      payload.type_data = "create"
      payload.AddressType = "BillingAddress"
      
      payload.country = payload.country.id
      payload.state = payload.state.id

    
      const responseData = await create_user_address(payload)
        
      if (responseData.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Created Successfully",
            severity: "success",
          })
        );
        
        setBillingAddress({...BillingAddress,BillingAddressList:responseData.data})
        return true
      } else if (responseData.StatusCode === 6001) {
  
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "warning",
          })
        );
        return false
      } 
      else if (responseData.StatusCode === 6002) {
     
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
        return false
      } 
      else {
  
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
        return false
      }
    }
    
  }
  
  

  const Call_Barcode_api = async (payload) => {
    let data = null;
    const Response = await get_product_by_barcode_api(payload);
    console.log(Response, "Response");
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = null;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

   useEffect(() => {
    const CalculateData = () => {
      let newState = SalesInvoiceCalc(dataState,state, DataList,is_manual_roundoff);
      setState({ ...newState });
    };
    CalculateData();
  }, [DataList]);
  
  const clearData = async () => {
    if (is_edit) {
      navigate("/create-purchase-return");
    }
    setIsButtonClick(false);

    //new voucher number
    let voucher_datas = await get_VoucherNo({
      CompanyID: CompanyID,
      UserID: user_id,
      BranchID: BranchID,
      VoucherType: "PR",
    });

    setDataState({
      ...dataState,
      ...voucher_datas,
    });

    setState({
      ...state,
      AccountLedger: defaultValue.AccountLedger,
      CashAccount: defaultValue.CashAccount,
      BankAccount: defaultValue.BankAccount,
      // Employee :defaultValue.Employee,
      PriceCategory: defaultValue.PriceCategory,
      Warehouse: defaultValue.Warehouse,
      Treatment: defaultValue.Treatment,
      PlaceOfSupply: defaultValue.PlaceOfSupply,
      Date: DefaultDate,
      VenderInvoiceDate:DefaultDate,
      RefferenceBillNo: "",
      ShippingCharge: 0,
      ShippingTax: defaultValue.ShippingTax,
      shipping_tax_amount: 0,
      BillDiscPercent: 0,
      BillDiscAmt: 0,
      CashAmount: 0,
      BankAmount: 0,
      Notes: "",
      DueDate: DefaultDate,
      DeliveryDate: DefaultDate,
      //Total
      TotalQty: 0,
      TotalGrossAmt: 0,
      TotalDiscount: 0,
      TotalTax: 0,
      NetTotal: 0,
      TotalTaxableAmount: 0,
      TaxTaxableAmount: 0,
      NonTaxTaxableAmount: 0,
      GrandTotal: 0,
    });

    setDataLists([DataListItem]);
  };
console.log("Treatment data is adding-=====================>>>>>",state.Treatment);
console.log("setting up the data===============>>",dataState.AccountLedgerData);
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%", overflow: "scroll" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                cmr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              {is_edit
                ? t("Update Purchase Return")
                : t("Create Purchase Return")}
            </Typography>
          </FlexBox>
          <FlexBox className="right">
            <ClearButton
              onClick={() => {
                clearData();
              }}
              t={t}
            />

            <ListButton
              onClick={() => {
                navigate("/list-purchase-return");
              }}
              t={t}
            />

            {IsButtonClick ? (
              <LoadingButton t={t} />
            ) : (
              <SaveButton t={t} onClick={handleSubmit} />
            )}
          </FlexBox>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
            height: "250px",
          }}
        >
          <div class="sales-grid-item ">
            {/* customer search */}
            <InvoiceLedgerSearch
              name={"AccountLedger"}
              label={"Select Supplier"}
              optionLabel={"LedgerName"}
              optionLabel2={"LedgerCode"}
              OptionList={dataState.AccountLedgerData}
              Value={state.AccountLedger}
              OnChange={onMasterAutoChange}
              OnInputChange={onMasterInputChange}
              openBillingAddressModal={openBillingAddressModal}
              BillingAddress={state.BillingAddress}
              is_customer={is_customer}
            />
          </div>

          <div class="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                VAT Treatment:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"Treatment"}
                  label={"Select a Treatment"}
                  optionLabel={"name"}
                  List={VAT ? VAT_TreatmentData : GST ? GST_TreatmentData : []}
                  Value={state.Treatment}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Warehouse:{" "}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"Warehouse"}
                  label={"Select a Warehouse"}
                  optionLabel={"WarehouseName"}
                  List={dataState.WarehouseData}
                  Value={state.Warehouse}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Place Of Supply:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"PlaceOfSupply"}
                  label={"Select a Place Of Supply"}
                  optionLabel={"Name"}
                  List={dataState.PlaceOfSupplyData}
                  Value={state.PlaceOfSupply}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Ref Bill No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  name={"RefferenceBillNo"}
                  label={"Type a RefBillNo"}
                  placeholder={"Ref Bill No"}
                  Value={state.RefferenceBillNo}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>
          </div>

          <div class="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Invoice No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceVoucherNoTextBox
                  name={"VoucherNo"}
                  label={"Type a VoucherNo"}
                  is_edit={is_edit}
                  Value={dataState.VoucherNo}
                  openCustomVoucher={openCustomVoucher}
                  setCustomVoucher={setopenCustomVoucher}
                />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              {/* <CustomVoucher
                open={openCustomVoucher}
                setCustomVoucher={setopenCustomVoucher}
                state={dataState}
                handleChange={handleChange}
                CustomVoucherSet={CustomVoucherSet}
              /> */}

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Date:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox
                  name={"Date"}
                  label={"Select a Date"}
                  Value={state.Date}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Vendor Invoice Date:{" "}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox
                  name={"VenderInvoiceDate"}
                  label={"Select a Date"}
                  Value={state.VenderInvoiceDate}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: EnableSalesManInSales ? "flex" : "none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            ></div>
          </div>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <MyGrid
            dataState={dataState}
            state={state}
            DataList={DataList}
            setDataLists={setDataLists}
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            focusSettings={focusSettings}
            setFocusSettings={setFocusSettings}
            handleInputChange={onDetailsInputChange}
            handleCompleteChanges={onDetailsAutoChange}
            handleChanges={onDetailsChange}
            AddLine={AddLine}
            RemoveLine={RemoveLine}
            PriceDecimalPoint={PriceDecimalPoint}
            Errors={Errors}
            debouncedOnDetailsInputChange={debouncedOnDetailsInputChange}
            ChangeTableCustomization={ChangeTableCustomization}
            GeneralSettingsData={GeneralSettingsData}
          />
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{ cursor: "pointer" }} onClick={AddLine}>
            <span
              style={{
                whiteSpace: "nowrap",
                color: "#0A9EF3",
                margin: 0,
                fontSize: "13px",
              }}
            >
              + Add Line{" "}
            </span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px", // Adjust the gap between the span and BarcodeTextBox
              height: "40px",
            }}
          >
            <span style={{ whiteSpace: "nowrap", fontSize: "13px" }}>
              Add By Barcode :{" "}
            </span>
            <InvoiceBarCodeTextBox
              onEnter={addByBarCode}
              placeholder={" Add By Barcode"}
            />
          </div>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          {/* ------------Payment And Notes--------------- */}
          <div>
            <NotesTextArea
              value={state.Notes ? state.Notes : ""}
              name={"Notes"}
              placeholder={t("Notes (Optional)")}
              onChange={onMasterChange}
            />
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Discount(%):
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Discount:
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  type={"number"}
                  name={"BillDiscPercent"}
                  label={"Type a BillDiscPercent"}
                  Value={state.BillDiscPercent}
                  OnChange={onMasterChange}
                />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  type={"number"}
                  name={"BillDiscAmt"}
                  label={"Type a BillDiscAmt"}
                  Value={state.BillDiscAmt}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>
            <Typography
              style={{ marginTop: "10px" }}
              variant="overline"
              display="block"
              gutterBottom
            >
              {/* <AddToItemsButton /> */}
            </Typography>
          </div>

          {/* TOTAL AMOUNTS AND VALUES */}

          <SummaryDetails onChange = {onMasterChange}  state={state} is_manual_roundoff={is_manual_roundoff} setIs_manual_roundoff={setIs_manual_roundoff}  />
 
        </Box>

         
        {/* =======SHIPPING ADDRESS MODAL======== */}
        {/* {EnableShippingCharge === true?(
        <AddressModal type={"Shipping"}  state={state} setState={setState} open={IsShipping} setOpen={setIsShipping} List = {ShippingAddress?.ShippingAddressList} name={"Shipping Address"} Address={ShippingAddress} setAddress={setShippingAddress} Call_States_of_Country_api={Call_States_of_Country_api} api = {Create_ShippingAddress} />
        ):null}
        =======BILLING ADDRESS MODAL========= */}
        <AddressModal type={"Billing"}  state={state} setState={setState} open={IsBilling} setOpen={setIsBilling} List = {BillingAddress?.BillingAddressList} name={"Billing Address"} Address={BillingAddress} setAddress={setBillingAddress} Call_States_of_Country_api={Call_States_of_Country_api} api = {Create_BillingAddress} />
        {/* ============HISTORY MODALS============== */}
        {/* <HistoryModal Type = {"Sales"} heading = {"Sales History"} List = {dataState.SalesHistory} open = {IsSalesHistory} setOpen = {setIsSalesHistory} Call_Product_History_api={Call_Product_History_api} dataState={dataState} setDataState={setDataState} />
        <HistoryModal Type = {"Purchase"}  heading = {"Purchase History"} List = {dataState.PurchaseHistory} open = {IsPurchaseHistory} setOpen = {setIsPurchaseHistory} Call_Product_History_api={Call_Product_History_api} dataState={dataState} setDataState={setDataState}  />
       */}
      </Paper>
    </>
  );
};

export default CreateParchaseReturn;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "69%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));

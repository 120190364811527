import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
} from "../../../../../Components/ReportTable/ReportTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatNumber } from "../../../../../generalFunction";
import { useTranslation } from "react-i18next";

const ExpandTable = function (props) {
  // const [expanded, setExpanded] = useState("panel" + props.index +1)
  const openReportLink = function(
    id, 
    ledgerName
  ) {

    let fromDate = props.date.fromDate.format("YYYY-MM-DD")
    let toDate = props.date.toDate.format("YYYY-MM-DD")
    const url = `https://viknbooks.com/dashboard/ledger-report?LedgerID=${id}&ToDate=${toDate}&fromDate=${fromDate}&LedgerName=${ledgerName}`
    window.open(url, '_blank');
  }

  return (
    <VBTableCellBody colspan="4" sx={{ padding: 0, paddingBottom: "10px" }}>
      <Accordion
        // expanded={props.expanded === `panel${props.index+1}`}
        expanded={props.expanded.includes(`panel${props.index + 1}`)}
        onChange={props.handleChange(`panel${props.index + 1}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            pl: 0,
            minHeight: "auto",
            backgroundColor: "#e4ebf3",
            "&.MuiAccordionSummary-root": {
              minHeight: "10px", // Adjust the minHeight as needed
            },
            ".css-o4b71y-MuiAccordionSummary-content.Mui-expanded": {
              my: "0px",
            },
            ".MuiAccordionSummary-content": {
              my: 0,
            },
            // '.Mui-expanded .MuiAccordionSummary-content': {
            //   // Customize the margin only when expanded
            //   my: 0,
            // },
          }}
        >
          {/* {props.name} */}
          <Table sx={{ width: "100%" }}>
            <TableBody>
              <TableRow>
                <VBTableCellBody sx={{ width: "25%" }}>
                  {" "}
                  {props.index}
                </VBTableCellBody>
                <VBTableCellBody sx={{ width: "34%" }}>
                  {props.name}
                </VBTableCellBody>
                <VBTableCellBody sx={{ width: "19%", textAlign: "right" }}>
                  {formatNumber(props.TotalDebit)}
                </VBTableCellBody>
                <VBTableCellBody sx={{ width: "18%", textAlign: "right"  }}>
                  {formatNumber(props.TotalCredit)}
                </VBTableCellBody>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <VBTableContainer sx={{ width: "100%" }}>
            <TableBody>
              {props.data.map((e, i) => (
                <TableRow 
                  sx={{ cursor: e.AccountGroupName === "Opening Stock"? "" :"pointer"}}
                  onClick={() => openReportLink(e.LedgerID, e.LedgerName)}
                >
                  <VBTableCellBody sx={{ width: "25%" }}>
                    <span> {i + 1} </span>
                  </VBTableCellBody>
                  <VBTableCellBody sx={{ width: "34%" }}>
                    {e.LedgerName}{" "}
                  </VBTableCellBody>
                  <VBTableCellBody sx={{ width: "19%", textAlign: "right"  }}>
                    {formatNumber(e.TotalDebit)}
                  </VBTableCellBody>
                  <VBTableCellBody sx={{ width: "21%", textAlign: "right"  }}>
                    {formatNumber(e.TotalCredit)}
                  </VBTableCellBody>
                </TableRow>
              ))}
            </TableBody>
          </VBTableContainer>
        </AccordionDetails>
      </Accordion>
    </VBTableCellBody>
  );
};
const TrialBalanceTable = ({ filterData, expanded, handleChangeExpand, isLoadiing, date }) => {
  const [grandTotal, setGrandTotal] = useState({
    totalCredit: "",
    totalDebit: "",
  });
  const [t, i18n] = useTranslation("common");
  const calculateTotals = () => {
    const { totalDebit, totalCredit } = filterData.trileBalanceList.reduce(
      (accumulator, currentItem) => {
        return {
          totalDebit: accumulator.totalDebit + currentItem.TotalDebit,
          totalCredit: accumulator.totalCredit + currentItem.TotalCredit,
        };
      },
      { totalDebit: 0, totalCredit: 0 }
    );
    setGrandTotal({ totalDebit, totalCredit });
  };

  useEffect(() => {
    calculateTotals();
  }, [filterData.trileBalanceList]);

  return (
    <VBTableContainer>
      <VBTableHeader>
        <VBTableCellHeader>{t("Serial No")} </VBTableCellHeader>
        <VBTableCellHeader>{t("Ledger Name")} </VBTableCellHeader>
        <VBTableCellHeader sx={{textAlign: "right" }}>{t("Debit")} </VBTableCellHeader>
        <VBTableCellHeader sx={{textAlign: "right" }}>{t("Credit")} </VBTableCellHeader>
      </VBTableHeader>
      {isLoadiing ? <VBSkeletonLoader /> : <TableBody>
        {filterData.trileBalanceList.map((e, i) => (
          <TableRow>
            <ExpandTable
              expanded={expanded}
              handleChange={handleChangeExpand}
              index={i + 1}
              name={e.AccountGroupName}
              data={e.data}
              TotalCredit={e.TotalCredit}
              TotalDebit={e.TotalDebit}
              date={date}
            />
          </TableRow>
        ))}
        <TableRow></TableRow>
      </TableBody>}
      <VBTableFooter>
        <TableRow>
          <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
          <VBTableCellFooter></VBTableCellFooter>
          <VBTableCellFooter sx={{ textAlign: "right" }} >{formatNumber(grandTotal.totalDebit)} </VBTableCellFooter>
          <VBTableCellFooter sx={{ textAlign: "right" }} >{formatNumber(grandTotal.totalCredit)} </VBTableCellFooter>
        </TableRow>
      </VBTableFooter>
    </VBTableContainer>
  );
};

export default TrialBalanceTable;

import { Box, Button, List, ListItem, Paper, TableBody, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FilterLable from '../../../../Components/Utils/FilterLable'
import ExportBtn from '../../../../Components/Utils/ExportBtn'
import FilterBtn from '../../../../Components/Utils/FilterBtn'
import { VBSkeletonLoader, VBTableCellBody, VBTableCellFooter, VBTableCellHeader, VBTableContainer, VBTableFooter, VBTableHeader, download_excel } from '../../../../Components/ReportTable/ReportTable'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import ViknDrawer from '../../../../Components/ViknDrawer/ViknDrawer'
import VBDatePiker from '../../../../Components/Utils/VBDatePiker'
import moment from 'moment'
import ViknAutoComplete from '../../../../Components/Utils/ViknAutoComplete'
import { CallUsers } from '../../../../Api/Reports/CommonReportApi'
import { openSnackbar } from '../../../../features/SnackBarslice'
import { useDispatch, useSelector } from 'react-redux'
import { callTaxPurchaseReport } from '../../../../Api/Reports/TaxsApi'
import { formatNumber, getCookie } from '../../../../generalFunction'
import PrintBtn from '../../../../Components/Utils/PrintBtn'
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Icons } from '../../../../Assets/AssetLog'
import { BASE_URL, MEDIA_URL } from '../../../../generalVeriable'
import axios from 'axios'
import Pagenation from '../../../../Components/Utils/Pagenation'


let cookies = getCookie("VBID")

const PurchaseReport = () => {
    const [t] = useTranslation("common");
    const dispatch = useDispatch()
    const appSetting = useSelector((state) => state.appSettingsSclice);
    const generalSettings = useSelector(state => state.generalSettingsSclice.generalSettings)
    const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
    const [page_no, setPage_no] = useState(1)
    // const [progress_id, setProgress_id] = useState(null);
    // const [file_path, setFilePath] = useState(null);
    // const [progress, setProgress] = useState(0);
    // const [is_loader, setLoader] = useState(false);
    const { CountryName } = useSelector((state) => state.companyDetails);
    const [filterOptions, setFilterOptions] = useState({
      fromDate: moment(),
      toDate: moment(),
      user: null
    });
    const [filterData, setFilterData] = useState({
      usersList: [],
      reportList: [],
      Total_TaxableValue: 0,
      Total_TotalQty: 0,
      Total_TotalTaxableAmount: 0,
      Total_TotalTax: 0,
      Total_SGSTAmount: 0,
      Total_CGSTAmount: 0,
      Total_IGSTAmount: 0,
      Total_KFCAmount: 0
    });

    const [isLoading, setIsLoading] = useState(false)

    // Handle Functions
    const searchReport = function () {
      fetchReport();
    };

    const handleDefaultFilter = function () {
      setFilterOptions({
        fromDate: moment(),
        toDate: moment(),
        user: null
      })
    }

    function ExportPurchaseGSTExcel() {
      const purchaseGstExcelExportUrl = BASE_URL + "purchases/purchase-gst-report-excel/";
      // const CompanyID = CompanyDetails.id
      // const BranchID = CompanyDetails.BranchID
      const PriceRounding = Number(generalSettings.PriceDecimalPoint)
      const UserID = filterOptions.user?.id || ""
      const FromDate = filterOptions.fromDate.format("YYYY-MM-DD")
      const ToDate = filterOptions.toDate.format("YYYY-MM-DD")
      fetch(
        `${purchaseGstExcelExportUrl}?CompanyID=${CompanyID}&BranchID=${BranchID}&FromDate=${FromDate}&ToDate=${ToDate}&PriceRounding=${PriceRounding}&UserID=${UserID}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${cookies}`,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            download_excel(response.url);
          } else {
            console.log("ERROR");
          }
        })
        .catch((err) => {
          console.log("err");
        });
    } 

    function ExportPurchaseTaxGroupExcel() {
      const purchaseTaxGroupExcelExportUrl = BASE_URL + "purchases/purchase-taxgroup-report-excel/";
      // const CompanyID = CompanyDetails.id
      // const BranchID = CompanyDetails.BranchID
      const PriceRounding = Number(generalSettings.PriceDecimalPoint)
      // const UserID = UserDetails.user_id
      const FromDate = filterOptions.fromDate.format("YYYY-MM-DD")
      const ToDate = filterOptions.toDate.format("YYYY-MM-DD")
      // const url = `${purchaseTaxGroupExcelExportUrl}?CompanyID=${CompanyID}&BranchID=${BranchID}&FromDate=${FromDate}&ToDate=${ToDate}&PriceRounding=${PriceRounding}`
      // download_excel(url)
      fetch(
        `${purchaseTaxGroupExcelExportUrl}?CompanyID=${CompanyID}&BranchID=${BranchID}&FromDate=${FromDate}&ToDate=${ToDate}&PriceRounding=${PriceRounding}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${cookies}`,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            download_excel(response.url);
          } else {
            console.log("ERROR");
          }
        })
        .catch((err) => {
          console.log("err");
        });
    }

    // Fetch API

    const fetchReport = async function() {
      setIsLoading(true)
      const payload = {
        FromDate: filterOptions.fromDate.format("YYYY-MM-DD"),
        ToDate: filterOptions.toDate.format("YYYY-MM-DD"),
        UserID: "",
        CompanyID:CompanyID,
        BranchID:BranchID,
        PriceRounding:Number(PriceDecimalPoint),
        CreatedUserID:user_id,
      }
      if (filterOptions.user) {
        payload.UserID = filterOptions.user.id
      }
      const res = await callTaxPurchaseReport(payload)
      if (res.StatusCode === 6000) {
        setFilterData({
          ...filterData,
          reportList: res.new_data,
          Total_TaxableValue: res.Total_TaxableValue,
          Total_TotalQty: res.Total_TotalQty,
          Total_TotalTaxableAmount: res.Total_TotalTaxableAmount,
          Total_TotalTax: res.Total_TotalTax,
          Total_SGSTAmount: res.Total_SGSTAmount,
          Total_CGSTAmount: res.Total_CGSTAmount,
          Total_IGSTAmount: res.Total_IGSTAmount,
          Total_GrandTotal: res.Total_GrandTotal,
        })
      } else if(res.StatusCode > 6000) {
        dispatch(
          openSnackbar({
            open : true,
            message : res.message || res.error || res.messages || "Some Thing Went Wrong",
            severity : 'warning',
          })
        )
      }
      setIsLoading(false)
    }


    // Fetch Apis
    const filterApis = async function () {
      let payload = {
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
      };
      const data = await CallUsers(payload);
      if (data?.StatusCode === 6000) {
        setFilterData({ ...filterData, usersList: data.data });
      }
    };

    const getDataForPage = () => {
      const startIndex = (page_no - 1) * appSetting.itemPerPage;
      const endIndex = startIndex + appSetting.itemPerPage;
      const alldata = filterData.reportList.slice(0, filterData.reportList.length -1)
      return alldata.slice(startIndex, endIndex) || [];
    };

    useEffect(() => {
      filterApis()
    }, [])

    
  return (
    <>
    <Paper 
      sx={{ 
        height: "100%", 
        // pb: 4,
        width: "100%" }}
      >
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500", mr: 2 }}
              variant="h2"
            >
             {CountryName === "India" ? t("GST Purchase Report") : t("VAT Purchase Report")}
             
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between",}}>
            <PrintBtn />
            <Button
              variant="text"
              startIcon={<img src={Icons.Export} alt="export" />}
              sx={{ textTransform: "none", fontWeight: "400", mx: 2 }}
              disableElevation
              onClick={ExportPurchaseGSTExcel}
            >
              {t("Export")}
            </Button>
            <Button
              startIcon={<SwapVertIcon />} 
              sx={{ 
                backgroundColor: "#0FAB29", 
                borderRadius: 5, 
                color: 'white', 
                px: 2
              }}
              onClick={() => ExportPurchaseTaxGroupExcel()}
            >{t("Export Tax Group")}</Button>
            {/* <FlexBox>
            <Pagenation /> */}
            <Pagenation 
              totalItem={filterData.reportList.length}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </Box>
        </Box>
        {/* Filter Area  */}
        <Box
          // This also change into custom component
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // height: "54px",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FilterLable name={"Date"} value={filterOptions.fromDate.format("DD-MM-YYYY")+ " to " +filterOptions.toDate.format("DD-MM-YYYY")} />
          {filterOptions.user && 
            <FilterLable 
              name={t("User")} 
              value={filterOptions.user.name}
              handleDelete={() => setFilterOptions({ ...filterOptions, user: null})} 
            />
          }
        </Box>
        <VBTableContainer>
            <VBTableHeader>
              <VBTableCellHeader >{t("Serial No")}</VBTableCellHeader>
              <VBTableCellHeader >{t(" Date")}</VBTableCellHeader>
              <VBTableCellHeader >{t("Voucher Number")}</VBTableCellHeader>
              <VBTableCellHeader >{t("Reference Bill No")}</VBTableCellHeader>
              <VBTableCellHeader >{t("Vendor Invoice Date")}</VBTableCellHeader>
              <VBTableCellHeader >{t("Particulars")}</VBTableCellHeader>
              <VBTableCellHeader >{t("GSTIN/UIN")}</VBTableCellHeader>
              <VBTableCellHeader >{t("Voucher Type")}</VBTableCellHeader>
              <VBTableCellHeader >{t("Tax Type")}</VBTableCellHeader>
              <VBTableCellHeader sx={{ textAlign:"right" }} >{t("Taxable Amount")}</VBTableCellHeader>
              <VBTableCellHeader sx={{ textAlign:"right" }} >{t("SGST")}</VBTableCellHeader>
              <VBTableCellHeader sx={{ textAlign:"right" }} >{t("CGST")}</VBTableCellHeader>
              <VBTableCellHeader sx={{ textAlign:"right" }} >{t("IGST")}</VBTableCellHeader>
              <VBTableCellHeader sx={{ textAlign:"right" }} >{t("Cess")}</VBTableCellHeader>
              <VBTableCellHeader sx={{ textAlign:"right" }} >{t("Total Tax Amount")}</VBTableCellHeader>
              <VBTableCellHeader sx={{ textAlign:"right" }} >{t("Grand Total")}</VBTableCellHeader>

            </VBTableHeader>
            {isLoading && <VBSkeletonLoader />}
            {!isLoading && <TableBody>
              {getDataForPage().map((e, i) => (
                <TableRow>
                  <VBTableCellBody>{i + 1} </VBTableCellBody>
                  <VBTableCellBody>{e.Date} </VBTableCellBody>
                  <VBTableCellBody>{e.VoucherNo} </VBTableCellBody>
                  <VBTableCellBody>{e.RefferenceBillNo} </VBTableCellBody>
                  <VBTableCellBody>{e.VenderInvoiceDate} </VBTableCellBody>
                  <VBTableCellBody>{e.Particulars} </VBTableCellBody>
                  <VBTableCellBody>{e.party_gstin} </VBTableCellBody>
                  <VBTableCellBody>{e.VoucherType} </VBTableCellBody>
                  <VBTableCellBody>{e.TaxType} </VBTableCellBody>
                  <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(e.TotalTaxableAmount)} </VBTableCellBody>
                  <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(e.SGSTAmount)} </VBTableCellBody>
                  <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(e.CGSTAmount)} </VBTableCellBody>
                  <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(e.IGSTAmount)} </VBTableCellBody>
                  <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(e.CESSAmount)} </VBTableCellBody>
                  <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(e.TotalTax)} </VBTableCellBody>
                  <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(e.GrandTotal)} </VBTableCellBody>
                </TableRow>
              ))}
              <TableRow></TableRow>
            </TableBody>}
          {!isLoading && 
          <VBTableFooter>
            <TableRow>
              <VBTableCellFooter>{t('Total')}</VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(filterData.Total_TotalTaxableAmount)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(filterData.Total_SGSTAmount)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(filterData.Total_CGSTAmount)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(filterData.Total_IGSTAmount)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(filterData.Total_TotalQty)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(filterData.Total_TotalTax)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(filterData.Total_GrandTotal)}</VBTableCellFooter>
            </TableRow>
          </VBTableFooter>}
        </VBTableContainer>
    </Paper>

    <ViknDrawer
        open={openFilter}
        handleClose={() => setOpenFilter(false)}
        onFilter={() => searchReport()}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>

            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.fromDate}
                toDate={filterOptions.toDate}
                fromOnChange={ value => setFilterOptions({ ...filterOptions, fromDate: value}) }
                toOnChange={ value => setFilterOptions({ ...filterOptions, toDate: value}) }
                // fromReadOnly={true}
                // toReadOnly={true}
              />
            </ListItem>

            <ListItem>
            <ViknAutoComplete
                value={filterOptions.user}
                placeholder={"User"}
                options={filterData.usersList}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) =>
                  setFilterOptions({...filterOptions, user: newValue})
                }
              />
            </ListItem>

          </List>
        }
      />
    </>
  )
}

export default PurchaseReport

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
    alignItems: "center",
    // width: "22%",
  }));
  
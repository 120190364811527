import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Box,List,ListItem,Paper,Typography,styled} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {  VBSkeletonLoader, VBTableCellBody,  VBTableCellBodyColor,  VBTableCellHeader,  VBTableContainer,  VBTableHeader, VBTableRowNull, download_excel,} from "../../../../Components/ReportTable/ReportTable";
import FilterLable from "../../../../Components/Utils/FilterLable";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import moment from "moment";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknRadioGroup from "../../../../Components/Utils/ViknRadioGroup";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import {  callDayBook } from "../../../../Api/Reports/AccountApi";
import { formatNumber } from "../../../../generalFunction";
import { useTranslation } from "react-i18next";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { BASE_URL } from "../../../../generalVeriable";
import LoaderLite from "../../../../Components/Utils/LoaderLite";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { UserRolePermission } from "../../../../Function/Editions";

const Dayreport = () => {
  let Export_view = UserRolePermission("Day Book", "print_permission");
  const [t, i18n] = useTranslation("common");
  const dispatch = useDispatch()
  const appSetting = useSelector((state) => state.appSettingsSclice);
  console.log(appSetting);
  const [reportData, setReportData] = useState({
    StatusCode: 0,
    summary_final_all:[],
    summary_final_modi:[],
    summary_final_Delt:[],
    detailed_final:[],
    summary_final:[],
    
  });
  const [loading, setLoading] = useState(false);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id,access } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [page_no, setPage_no] = useState(1);
  const [filterOptions, setFilterOptions] = useState({
    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      Date: moment(),
      // ToDate: moment(),
    },
    reportType: "summary",
    reportList: [
      { name: "summary", label: "Summary" },
      { name: "detailed", label: "Detailed" },
    ],
  });
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState("Generating Report...");

  //Handle functions

  const closeFilter = () => setOpenFilter(false);

  const searchReport = function () {
    fetchReport();
  };

  const handlefilterList = () => {
    let list = [];
    let filterdata = filterOptions
    if (filterdata.date.value?.Date !== "") {
        list.push({ name: t("Date"), value:String( filterdata.date.Date.format("DD-MM-YYYY")) });
    }
    if (filterdata.reportType !== "") {
        list.push({ name: t("Report Type"), value:filterdata.reportType});
    }
    
    return list;
  };

  const handleChange = (e, name) => {
    let { value } = e.target;
    setFilterOptions({ ...filterOptions, [name]: value });
  };

  const handleDefaultFilter = async () => {
    setFilterOptions({
      ...filterOptions,
      reportType: "summary",
      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Today" },
        Date: moment(),
        ToDate: moment(),
      },

    })
  }

  // API Fetch data
  const fetchReport = async function () {
    setLoading(true)
    const data = await callDayBook({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      Date: filterOptions.date.Date.format("YYYY-MM-DD"),
      page_no: page_no,
      page_size: appSetting.itemPerPage,
    },dispatch);
    console.log(data);
    if (data?.StatusCode === 6000) {
      setReportData(data);
      // setPage()
    } else if (data?.StatusCode === 6001){
      setReportData(
        {
          StatusCode: 0,
          summary_final_all:[],
          summary_final_modi:[],
          summary_final_Delt:[],
          detailed_final:[],
          summary_final:[],
          
        }
      )
      dispatch(
        openSnackbar({
          open : true,
          message : data.message,
          severity : 'warning',
        })
      )
    }
    setLoading(false)
    closeFilter()
  };
  const getDataForPage = () => {
    const startIndex = (page_no - 1) * 15;
    const endIndex = startIndex + 15;
    return reportData.detailed_final?.slice(startIndex, endIndex) || [];
  };

  const ExportDailyReportExcel = (e) => {
    let PriceRounding = Number(PriceDecimalPoint);
    let Date= filterOptions.date.Date.format("YYYY-MM-DD")
    let reportType= filterOptions.reportType

    fetch(
      `${BASE_URL+"ledgerPosting/day-book-excel/"}?CompanyID=${CompanyID}&BranchID=${BranchID}&Date=${Date}&PriceRounding=${PriceRounding}&Type=${reportType === "summary" ? 1 : 2}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          download_excel(response.url);
        } else {
          console.log("ERROR");
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };
  return (
    <>
      {progress > 0 ?
      <LoaderLite message={((progress<=100)?("Generating Report... " + progress + "%"):"Creating File... ")} progress={progress} fullscreen={true} />
      :null}
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t("Day Book")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}

            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className="right">
            {Export_view ? 
          <ReportDownload
            download_type={"PDF"}
            VoucherType={filterOptions.reportType === "summary"?1:2 }
            invoice_type={"day_book"}
            ToDate={filterOptions.date.Date.format("YYYY-MM-DD")}
            ExportToXLS={ExportDailyReportExcel}
            // setProgress ={setProgress}// not in this report
            // setProgressMessage={setProgressMessage}//not in this report
            />
            : null}
            {/* <PrintBtn /> */}
            {filterOptions.reportType !=="summary" &&
            <Pagenation
              totalItem={reportData.detailed_final?.length}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
            }
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
            alignItems:"center"
          }}
        >
            <div style={{fontSize:"16px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>

            {t("Applied Filters: ")}
            </div>
        {handlefilterList(filterOptions).map((val, i) => (
        <FilterLable
          name={val.name}
          value={val.value}
          handleDelete={val.handleDelete?val.handleDelete:null}
        />
      ))}
        </Box>

        {/* Report Table */}
        
        <VBTableContainer>
          {filterOptions.reportType==="summary"?
          <VBTableHeader>
            <VBTableCellHeader>{t("Particular")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Trans Count")} </VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t("Amount")}</VBTableCellHeader>
          </VBTableHeader>:<VBTableHeader>
            <VBTableCellHeader>{t("Particular")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Ledger Name")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Trans Count")}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t("Amount")}</VBTableCellHeader>
          </VBTableHeader>
          }
          {loading&&
            <VBSkeletonLoader/>
          }
            <TableBody>
              {
                filterOptions.reportType==="summary"?
                reportData.summary_final?.map((data, i) => (
                <TableRow>
                  <VBTableCellBodyColor children ={data.particular} color={
                    data.particular === "Total Active Transactions"?"#ff1744"
                    :data.particular === "Modified Transactions"?"#ff1744"
                    :data.particular === "Deleted Transactions"?"#ff1744"
                    :null} />
                  <VBTableCellBody>{formatNumber(data.trans_count)}</VBTableCellBody>
                  <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(Number(data.Amount))}</VBTableCellBody>
                </TableRow>)):
                getDataForPage()?.map((data, i) => (
                <TableRow>
                <VBTableCellBody>{data.particular}</VBTableCellBody>
                <VBTableCellBody>{data.LedgerName}</VBTableCellBody>
                <VBTableCellBody>{formatNumber(data.trans_count)}</VBTableCellBody>
                <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(Number(data.Amount))}</VBTableCellBody>
              </TableRow>
                ))}
                <VBTableRowNull length = {reportData.summary_final?.length}/>
            </TableBody>
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={searchReport}
        handleDefaultFilter={()=>handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.date.Date}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      Date: value,
                    },
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      ToDate: value,
                    },
                  })
                }
              />
            </ListItem>


            <ListItem>
              <ViknRadioGroup
                handleChange={handleChange}
                radios={filterOptions.reportList.map((i) => ({
                  label: t(i.label),
                  value: i.name,
                }))}
                value={filterOptions.reportType}
                valueName={"reportType"}
              />
            </ListItem>
          </List>
        }
      />
    </>
  );
};

export default Dayreport;


const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap:"22px",
  },
}));
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CloseIcon from "@mui/icons-material/Close";
import VBInputField from "../../../../Components/Utils/VBInputField";

const BankDetails = ({ uploadDetail, setUploadDetail }) => {
  const [open, setOpen] = useState(false);
  const [editBank, setEditBank] = useState({
    position: "",
    address: null,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditBank({
      position: "",
      address: null,
    });
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 2,
        }}
      >
        <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
          Bank Details
        </Typography>

        {uploadDetail.bankList.length !== 0 && (
          <BlueButton onClick={handleOpen} startIcon={<AddIcon />}>
            Save to Add
          </BlueButton>
        )}
      </Box>
      <Divider />

      {uploadDetail.bankList.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
            mt: 10,
          }}
        >
          <img src="../images/BankObj.png" alt="dumm" />
          <Typography sx={{ fontSize: "22px", fontWeight: "600", mt: 3 }}>
            No Bank Connected
          </Typography>
          <Typography
            sx={{ fontSize: "18px", fontWeight: "400", color: "#8A8A8A" }}
          >
            Connect a bank account to manage transactions.
          </Typography>
          <BlueButton onClick={handleOpen} startIcon={<AddIcon />}>
            Save to Add
          </BlueButton>
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1, mt: 5 }}>
          <Grid container spacing={2}>
            {uploadDetail.bankList.map((en, i) => (
              <Grid item xs={4}>
                <Item>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      p: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                      Bank {i + 1}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ p: 2 }}>
                    <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                      <span style={{ color: "#b8b8b8" }}> Bank Name: </span>
                      {en.BankName1},<br />
                      <span style={{ color: "#b8b8b8" }}> Billing No: </span>
                      {en.buildingNo},<br />
                      <span style={{ color: "#b8b8b8" }}> Account No: </span>
                      {en.AccountNo1},<br />
                      <span style={{ color: "#b8b8b8" }}> IBAN: </span>
                      {en.IBANOrIFSCCode1}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "end", p: 1 }}>
                    <Button
                      variant="text"
                      startIcon={<DeleteOutlineIcon />}
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        const updatedBankList = uploadDetail.bankList.filter(
                          (item, index) => index !== i && item
                        );
                        setUploadDetail({
                          ...uploadDetail,
                          bankList: updatedBankList,
                        });
                      }}
                    >
                      Remove
                    </Button>
                    <Button
                      variant="text"
                      startIcon={<BorderColorIcon />}
                      sx={{
                        color: "#0A9EF3",
                        fontSize: "16px",
                        fontWeight: "500",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        setEditBank({
                          position: i,
                          address: en,
                        });
                        setOpen(true);
                      }}
                    >
                      Edit
                    </Button>
                  </Box>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <BankModal
        open={open}
        handleClose={handleClose}
        uploadDetail={uploadDetail}
        setUploadDetail={setUploadDetail}
        editBank={editBank}
      />
    </>
  );
};

export default BankDetails;

const BlueButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#0A9EF3",
  textTransform: "none",
  fontSize: "15px",
  ":hover": {
    backgroundColor: "#0A9EF3",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F9F9F9",
  ...theme.typography.body2,
  boxShadow: "none",
  border: "1px solid #EEEEEE",
  borderRadius: "#EEEEEE",
}));





//=============================================================================\\
//-------------------------------Add Bank -------------------------------------\\
//=============================================================================\\

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 2,
};

const BankModal = function ({
  open,
  handleClose,
  uploadDetail,
  setUploadDetail,
  editBank,
}) {
  let bankDetails = {
    bankName: "",
    accountName: "",
    accountNo: "",
    IBAN: "",
  };

  const [bankData, setBankData] = useState(bankDetails);

  const handleCloseModal = function () {
    setBankData(bankDetails);
    handleClose();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (editBank.position || editBank.position === 0) {
      // console.log(editBank.position);

      const updatedAddressList = uploadDetail.bankList.map((item, index) =>
        index === editBank.position ? bankData : item
      );
      setUploadDetail({
        ...uploadDetail,
        bankList: updatedAddressList,
      });
    } else {
      console.log(editBank.position);
      setUploadDetail({
        ...uploadDetail,
        bankList: [...uploadDetail.bankList, bankData],
      });
    }
    setBankData(bankDetails);
    handleClose();
  };

  useEffect(() => {
    editBank.address && setBankData(editBank.address);
  }, [editBank.address]);

  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={onSubmit}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
              Enter Bank Details
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <BlueButton sx={{ px: 3 }} type="submit">
                Save
              </BlueButton>
              <IconButton sx={{ color: "#0A9EF3" }} onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider sx={{ mb: 2 }} />

          <Box
            sx={{
              maxHeight: 500,
              overflowY: "scroll",
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
              "::-webkit-scrollbar": { display: "none" },
            }}
          >
            <Box sx={{ flexGrow: 1, mb: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <VBInputField
                    type="text"
                    required
                    label="Bank Name"
                    placeholder="Type here"
                    value={bankData.bankName}
                    onChange={(e) =>
                      setBankData({
                        ...bankData,
                        bankName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <VBInputField
                    type="text"
                    required
                    label="Building No"
                    placeholder="Type here"
                    value={bankData.buildingNo}
                    onChange={(e) =>
                      setBankData({
                        ...bankData,
                        buildingNo: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <VBInputField
                    type="text"
                    required
                    label="Account No"
                    placeholder="Type here"
                    value={bankData.accountNo}
                    onChange={(e) =>
                      setBankData({
                        ...bankData,
                        accountNo: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <VBInputField
                    type="text"
                    required
                    label="IBAN"
                    placeholder="Type here"
                    value={bankData.IBAN}
                    onChange={(e) =>
                      setBankData({
                        ...bankData,
                        IBAN: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

import {
  Box,
  Divider,
  Fade,
  List,
  ListItem,
  Paper,
  Typography,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import styled from "@emotion/styled";
import moment from "moment";
import ConsolidateTable from "./Components/ConsolidateTable";
import FilterLable from "../../../../Components/Utils/FilterLable";
import DetailsTable from "./Components/DetailsTable";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
// import VBSelect from "../../../../Components/Utils/VBSelect";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import ViknRadioGroup from "../../../../Components/Utils/ViknRadioGroup";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { CallTaxSummaryV11 } from "../../../../Api/Reports/TaxsApi";
import { useDispatch, useSelector } from "react-redux";
import Pagenation from "../../../../Components/Utils/Pagenation";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { useEffect } from "react";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import LoaderLite from "../../../../Components/Utils/LoaderLite";
import { CallBranchsList } from "../../../../Api/Reports/CommonReportApi";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import { identity } from "lodash";
import { useTranslation } from "react-i18next";
import { UserRolePermission } from "../../../../Function/Editions";

const VAT_Treatments = [
  { value: "0", name: "Business to Business(B2B)" },
  { value: "1", name: "Business to Customer(B2C)" },
  { value: "2", name: "Import" },
];

const VoucherType = [
  { value: "SI", name: "Sales Invoice" },
  { value: "PI", name: "Purchase Invoice" },
  { value: "SR", name: "Sales Return" },
  { value: "PR", name: "Purchase Return" },
  { value: "EX", name: "Expense" },
];

const VATReport = () => {
  let Export_view = UserRolePermission("VAT Report", "print_permission");
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const generalSettings = useSelector((state) => state.appSettingsSclice);
  // console.log(generalSettings);
  // State Handling

  //checking admin or not
  const [page_no, setPage_no] = useState(1);
  const [progress, setProgress] = useState(0);
  const [toggleReport, setToggleReport] = useState(true);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { PriceDecimalPoint, EnableBranch } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );
  const { user_id } = useSelector((state) => state.user);
  const isAdmin = BranchID === 1 ? true : false;
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [filterOptions1, setFilterOptions1] = useState({
    fromDate: moment(),
    toDate: moment(),
  });

  const [filterOptions2, setFilterOptions2] = useState({
    fromDate: moment(),
    toDate: moment(),
    voucherType: null,
    vatTreatment: null,
  });

  const [BranchData, setBranchData] = useState({
    BranchList: [{ BranchID: 1 }],
    SelectedBranches: [],
    IsSelectedAllBranch: false,
  });

  const [reportData1, setReportData1] = useState({
    sales_amount: {
      Taxable: 0,
      NonTaxable: 0,
      TaxAmount: 0,
      NetAmount: 0,
    },
    sales_return_amount: {
      Taxable: 0,
      NonTaxable: 0,
      TaxAmount: 0,
      NetAmount: 0,
    },
    total_received_amount: {
      Taxable: 0,
      NonTaxable: 0,
      TaxAmount: 0,
      NetAmount: 0,
    },
    purchase_amount: {
      Taxable: 0,
      NonTaxable: 0,
      TaxAmount: 0,
      NetAmount: 0,
    },
    purchase_return_amount: {
      Taxable: 0,
      NonTaxable: 0,
      TaxAmount: 0,
      NetAmount: 0,
    },
    expenses_amount: {
      Taxable: 0,
      NonTaxable: 0,
      TaxAmount: 0,
      NetAmount: 0,
    },
    total_paid_amount: {
      Taxable: 0,
      NonTaxable: 0,
      TaxAmount: 0,
      NetAmount: 0,
    },
    Recieved: 0,
    Paid: 0,
    TotalPayable: 0,
  });

  const [reportData2, setReportData2] = useState({
    TaxableAmount_total: 0,
    NonTaxableAmount_total: 0,
    TaxAmount_total: 0,
    NetAmount_total: 0,
    count: 0,
    sales_data: [],
  });

  const [filterData, setFilterData] = useState({
    voucherTypeList: [],
    vatTreatmentList: [],
  });

  // Handle Function
  const searchReport = function () {
    fetchReport();
  };
  
  
  
  
  const handleChangeType = function () {
    // toggleReport
    //   ? setFilterOptions1({
    //       fromDate: moment(),
    //       toDate: moment(),
    //     })
    //   : setFilterOptions2({
    //       fromDate: moment(),
    //       toDate: moment(),
    //       voucherType: null,
    //       vatTreatment: null,
    //     });

    let BranchIDList = BranchData.BranchList.filter((i) => {
      if (i.BranchID === BranchID) {
        return i;
      }
    });

    let SelectedBranches = [];
    SelectedBranches.push(BranchIDList[0]);

    setBranchData({
      ...BranchData,
      // SelectedBranches,
      // IsSelectedAllBranch: false,
    });
  };
  

  const handleDefaultFilter = function () {
    // toggleReport
    //   ? setFilterOptions1({
    //       fromDate: moment(),
    //       toDate: moment(),
    //     })
    //   : setFilterOptions2({
    //       fromDate: moment(),
    //       toDate: moment(),
    //       voucherType: null,
    //       vatTreatment: null,
    //     });
    
    
    setFilterOptions1({
      ...filterOptions1,
      fromDate:moment(),
      toDate:moment(),
    })

    let BranchIDList = BranchData.BranchList.filter((i) => {
      if (i.BranchID === BranchID) {
        return i;
      }
    });

    let SelectedBranches = [];
    SelectedBranches.push(BranchIDList[0]);

    setBranchData({
      ...BranchData,
      SelectedBranches,
      IsSelectedAllBranch: false,
    });
  };
  const handleAutoComplete = function (e, value, name) {
    setBranchData({
      ...BranchData,
      IsSelectedAllBranch: false,
      [name]: value,
    });
  };

  const branchCheckChange = (name) => {
    let SelectedBranches = [];
    if (!BranchData[name] === true) {
      BranchData.BranchList.map((i) => {
        if (SelectedBranches.includes(i) === false) {
          SelectedBranches.push(i);
        }
      });

      setBranchData({
        ...BranchData,
        SelectedBranches,
        [name]: !BranchData[name],
      });
    } else {
      setBranchData({
        ...BranchData,
        [name]: !BranchData[name],
      });
    }
  };
  // API Fetching

  const fetchReport = async function (array) {
    setIsLoading(true);
    const payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      // FromDate: toggleReport
      //   ? filterOptions1.fromDate.format("YYYY-MM-DD")
      //   : filterOptions2.fromDate.format("YYYY-MM-DD"),
      FromDate:filterOptions1.fromDate.format("YYYY-MM-DD"),
      // ToDate: toggleReport
      //   ? filterOptions1.toDate.format("YYYY-MM-DD")
      //   : filterOptions2.toDate.format("YYYY-MM-DD"),
      ToDate:filterOptions1.toDate.format("YYYY-MM-DD"),
      report_type: toggleReport ? 0 : 1,
      page: page_no,
      items_per_page: generalSettings.itemPerPage,
      voucher_type:
        filterOptions2.voucherType?.value || filterOptions2.voucherType,
      vat_treatment:
        filterOptions2.vatTreatment?.value || filterOptions2.vatTreatment,
    };

    //if all branch selected
    if (isAdmin === true && BranchData.IsSelectedAllBranch === true) {
      payload.IsAllBranch = true;
    }

    //if any branch selected
    let SelectedBranches = array ? array : BranchData.SelectedBranches;
    if (SelectedBranches.length !== 0 && isAdmin) {
      let BranchList = [];
      SelectedBranches.map((i) => {
        i.BranchID
          ? BranchList.push(i.BranchID)
          : console.log(`no branchid${i}`);
      });

      payload.BranchID = BranchList;
    }

    const res = await CallTaxSummaryV11(payload);
    console.log(res);
    if (res.StatusCode === 6000) {
      toggleReport
        ? setReportData1({
            ...reportData1,
            ...res,
          })
        : setReportData2({
            ...reportData2,
            ...res,
          });
    } else if (res.StatusCode === 6001) {
      dispatch(
        openSnackbar({
          open: true,
          message: res.message,
          severity: "warning",
        })
      );
    }
    setIsLoading(false);
  };

  const get_initialData = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      show_all: true,
    };
    const res = await CallBranchsList(payload);
    if (res.StatusCode === 6000) {
      let BranchIDList = res.data.filter((i) => {
        if (i.BranchID === BranchID) {
          return i;
        }
      });

      let SelectedBranches = [];
      SelectedBranches.push(BranchIDList[0]);
      setBranchData({
        ...BranchData,
        BranchList: res.data,
        SelectedBranches,
      });
    } else if (res.StatusCode === 6001) {
      dispatch(
        openSnackbar({
          open: true,
          message: res.message,
          severity: "warning",
        })
      );
    }
  };

  useEffect(() => {
    fetchReport();
  }, [page_no]);

  useEffect(() => {
    console.log(EnableBranch, "EnableBranch", "BranchID", BranchID);
    if ((EnableBranch === true ||EnableBranch === "True") && BranchID === 1) {
      get_initialData();
    }
  }, []);

  console.log(BranchData.SelectedBranches, "BranchData.SelectedBranches");

  const getBranchesExport = () => {
    //if any branch selected
    let BranchList = [];
    let SelectedBranches = BranchData.SelectedBranches
      ? BranchData.SelectedBranches
      : [{ BranchID: 1 }];
    if (SelectedBranches.length !== 0 && isAdmin) {
      SelectedBranches.map((i) => {
        i.BranchID
          ? BranchList.push(i.BranchID)
          : console.log(`no branchid${i}`);
      });
    }
    else{
      BranchList=[BranchID?BranchID:1]
    }
    return BranchList;
  };
  return (
    <>
      {progress > 0 ? (
        <LoaderLite
          message={
            progress <= 100
              ? "Generating Report... " + progress + "%"
              : "Creating File... "
          }
          progress={progress}
          fullscreen={true}
        />
      ) : null}
      <Paper
        sx={{
          height: "100%",
          // pb: 4,
          width: "100%",
        }}
      >
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox>
            <Typography
              sx={{color:"#001746", fontSize: "23px", marginRight: "20px", fontWeight: "500" }}
              variant="h2"
            >
              {/* {true ? "Tax Summary" : "Vat Summary"} */}
              {t("VAT Report")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <Box sx={{ display: "flex", alignItems: "center" }}>
          {Export_view ? 
            <ReportDownload
              invoice_type={toggleReport ? "vat_report" : "vat_report_summary"}
              VoucherType={
                filterOptions2.voucherType
                  ? filterOptions2.voucherType.value
                  : null
              }
              PartyID={
                filterOptions2.vatTreatment
                  ? filterOptions2.vatTreatment.value
                  : null
              }
              download_type={"PDF"}
              FromDate={
                // toggleReport
                  // ? filterOptions1.fromDate.format("YYYY-MM-DD")
                  // : filterOptions2.fromDate.format("YYYY-MM-DD")
                  filterOptions1.fromDate.format("YYYY-MM-DD")
              }
              ToDate={
                // toggleReport
                //   ? filterOptions1.toDate.format("YYYY-MM-DD")
                //   : filterOptions2.toDate.format("YYYY-MM-DD")
                filterOptions1.toDate.format("YYYY-MM-DD")
              }
              BranchID={getBranchesExport()}
              // PriceRounding={state.PriceRounding}
              is_celery={true}
              setProgress={setProgress}
              // ExportToXLS={ExportTaxSummaryReportExcel}
            />
            : null}
            {/* <ExportBtn /> */}
            {/* <FlexBox> */}
            {/* <PrintBtn /> */}
            {/* </FlexBox> */}
            {!toggleReport && (
              <Pagenation
                totalItem={reportData2.count}
                page_no={page_no}
                setPage_no={setPage_no}
              />
            )}
          </Box>
        </Box>
        <Box
          // This also change into custom component
          sx={{
            px: "26px",
            py: "10px",
            overflowX: "scroll",
            // height: "54px",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div
            style={{
              fontSize: "15px",
              fontWeight: "400",
              marginRight: "5px",
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
              color:"#001746", 
            }}
          >
            {t("Applied Filters: ")}
          </div>
          {toggleReport && (
            <FilterLable
              name={t("Date")}
              value={
                filterOptions1.fromDate.format("DD-MM-YYYY") +
                " to " +
                filterOptions1.toDate.format("DD-MM-YYYY")
              }
            />
          )}

          {!toggleReport && (
            // <FilterLable
            //   name={"Date"}
            //   value={
            //     filterOptions2.fromDate.format("DD/MM/YYYY") +
            //     " - " +
            //     filterOptions2.toDate.format("DD/MM/YYYY")
            //   }
            // />
            <FilterLable
            name={t("Date")}
            value={
              filterOptions1.fromDate.format("DD-MM-YYYY") +
              " to " +
              filterOptions1.toDate.format("DD-MM-YYYY")
            }
          />
          )}
          {/* 
          {BranchData.IsSelectedAllBranch === true && isAdmin === true && (
            <FilterLable
              name={t("Branch Name")}
              value={"All Branches"}
              handleDelete={() =>
                setBranchData({
                  ...BranchData,
                  IsSelectedAllBranch: !BranchData.IsSelectedAllBranch,
                  SelectedBranches: [],
                })
              }
            />
          )} */}

          {BranchData.SelectedBranches.length !== 0 &&
            // BranchData.IsSelectedAllBranch === false &&
            EnableBranch &&
            BranchID === 1 &&
            isAdmin === true && (
              <FilterLable
                name={t("Branch Name")}
                value={BranchData.SelectedBranches.map((e, i) => (
                  <Chip
                    key={i}
                    label={e.NickName}
                    variant="outlined"
                    sx={{
                      p: "1px",
                      fontSize: "10px",
                      height: "22px",
                      mr: "4px",
                      ".MuiChip-label": {
                        px: "8px",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "15px",
                        color: "#ff00006b",
                      },
                    }}
                    onDelete={() => {
                      let SelectedBranches = BranchData.SelectedBranches.filter(
                        (l) => l.BranchID !== e.BranchID
                      );
                      setBranchData({
                        ...BranchData,
                        IsSelectedAllBranch: false,
                        SelectedBranches: SelectedBranches,
                      });

                      fetchReport(SelectedBranches);
                    }}
                  />
                ))}
                handleDelete={() => {
                  let get_BranchID = BranchData.BranchList.filter((i) => {
                    if (i.BranchID === BranchID) {
                      return i;
                    }
                  });

                  let SelectedBranches = [];
                  SelectedBranches.push(get_BranchID[0]);
                  setBranchData({
                    ...BranchData,
                    SelectedBranches,
                    IsSelectedAllBranch: false,
                  });
                  fetchReport(SelectedBranches);
                }}
              />
            )}

          {!toggleReport && filterOptions2.voucherType && (
            <FilterLable
              name={t("Voucher Type")}
              value={filterOptions2.voucherType.name}
              handleDelete={() =>
                setFilterOptions2({ ...filterOptions2, voucherType: null })
              }
            />
          )}

          {!toggleReport && filterOptions2.vatTreatment && (
            <FilterLable
              name={t("VAT Treatment")}
              value={filterOptions2.vatTreatment.name}
              handleDelete={() =>
                setFilterOptions2({ ...filterOptions2, vatTreatment: null })
              }
            />
          )}
        </Box>
        {toggleReport ? (
          <ConsolidateTable data={reportData1} isLoading={isLoading} />
        ) : (
          <DetailsTable
            data={reportData2}
            isLoading={isLoading}
            EnableBranch={EnableBranch}
          />
        )}
      </Paper>

      <ViknDrawer
        open={openFilter}
        handleClose={() => setOpenFilter(false)}
        onFilter={() => searchReport()}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <ViknRadioGroup
                value={toggleReport}
                handleChange={(e) => {
                  setToggleReport(e.target.value === "true");
                  setPage_no(1);
                  handleChangeType();
                }}
                radios={[
                  { label: t("Consolidate"), value: true },
                  { label: t("Details"), value: false },
                ]}
              />
            </ListItem>
            <Divider />

            <ListItem>
              {/* <VBDatePiker
                label="Vat Period"
                fromDate={
                  toggleReport
                    ? filterOptions1.fromDate
                    : filterOptions2.fromDate
                }
                toDate={
                  toggleReport ? filterOptions1.toDate : filterOptions2.toDate
                }
                fromOnChange={(e) =>
                  toggleReport
                    ? setFilterOptions1({
                        ...filterOptions1,
                        fromDate: e,
                      })
                    : setFilterOptions2({
                        ...filterOptions2,
                        fromDate: e,
                      })
                }
                toOnChange={(e) =>
                  toggleReport
                    ? setFilterOptions1({
                        ...filterOptions1,
                        toDate: e,
                      })
                    : setFilterOptions2({
                        ...filterOptions2,
                        toDate: e,
                      })
                }
              /> */}

              {toggleReport ? (
                <VBDatePiker
                  label={t("VAT Period")}
                  fromDate={filterOptions1.fromDate}
                  toDate={filterOptions1.toDate}
                  fromOnChange={(e) => {
                    setFilterOptions1({
                      ...filterOptions1,
                      fromDate: e,
                    });
                  }}
                  toOnChange={(e) => {
                    setFilterOptions1({
                      ...filterOptions1,
                      toDate: e,
                    });
                  }}
                />
              ) : (
                // <VBDatePiker
                //   label="Vat Period"
                //   fromDate={filterOptions2.fromDate}
                //   toDate={filterOptions2.toDate}
                //   fromOnChange={(e) => {
                //     setFilterOptions2({
                //       ...filterOptions2,
                //       fromDate: e,
                //     });
                //   }}
                //   toOnChange={(e) => {
                //     setFilterOptions2({
                //       ...filterOptions2,
                //       toDate: e,
                //     });
                //   }}
                // />
                <VBDatePiker
                label="Vat Period"
                fromDate={filterOptions1.fromDate}
                toDate={filterOptions1.toDate}
                fromOnChange={(e) => {
                  setFilterOptions1({
                    ...filterOptions1,
                    fromDate: e,
                  });
                }}
                toOnChange={(e) => {
                  setFilterOptions1({
                    ...filterOptions1,
                    toDate: e,
                  });
                }}
              />
              )}
            </ListItem>

            {isAdmin === true && EnableBranch && BranchID === 1 && (
              <ListItem>
                <ViknCheckboxGroup
                  radios={[
                    {
                      label: t("All Branches"),
                      checked: BranchData.IsSelectedAllBranch,
                      onChange: () => branchCheckChange("IsSelectedAllBranch"),
                    },
                  ]}
                />
              </ListItem>
            )}

            {isAdmin === true && EnableBranch && BranchID === 1 && (
              <ListItem>
                <ViknAutoComplete
                  disabled={BranchData.IsSelectedAllBranch}
                  multiple
                  value={BranchData.SelectedBranches}
                  placeholder={"Select Branches"}
                  options={BranchData.BranchList}
                  getOptionLabel={(option) => option.NickName}
                  onChange={(e, newValue) =>
                    handleAutoComplete(e, newValue, "SelectedBranches")
                  }
                />
              </ListItem>
            )}

            <Fade in={!toggleReport}>
              <ListItem>
                <ViknAutoComplete
                  placeholder={"Voucher Type"}
                  value={filterOptions2.voucherType}
                  options={VoucherType}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, newValue) =>
                    setFilterOptions2({
                      ...filterOptions2,
                      voucherType: newValue,
                    })
                  }
                />
              </ListItem>
            </Fade>
            <Fade in={!toggleReport}>
              <ListItem>
                <ViknAutoComplete
                  placeholder={"Vat Treatment"}
                  value={filterOptions2.vatTreatment}
                  options={VAT_Treatments}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, newValue) =>
                    setFilterOptions2({
                      ...filterOptions2,
                      vatTreatment: newValue,
                    })
                  }
                />
              </ListItem>
            </Fade>
          </List>
        }
      />
    </>
  );
};

export default VATReport;

const FlexBox = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  // width: "22%",
}));

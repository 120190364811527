import React from 'react'
import { VBSkeletonLoader, VBTableCellBody, VBTableCellHeader, VBTableContainer, VBTableHeader } from '../../../../../Components/ReportTable/ReportTable'
import { TableBody, TableRow } from '@mui/material'
import { formatNumber } from '../../../../../generalFunction'
import { useTranslation } from 'react-i18next'

const ConsolidateTable = (props) => {
  const [t, i18n] = useTranslation("common");

  return (
    <VBTableContainer>
    <VBTableHeader>
      <VBTableCellHeader colSpan={2}>{t("Expense")} </VBTableCellHeader>
      <VBTableCellHeader colSpan={2}>{t("Income")} </VBTableCellHeader>
    </VBTableHeader>
    {props.isLoading ? <VBSkeletonLoader /> : <TableBody>
      <TableRow>

        <VBTableCellBody sx={{ fontWeight: "bold"}} > Group Name</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> Amount</VBTableCellBody>

        <VBTableCellBody sx={{ fontWeight: "bold"}}> Group Name</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right"}}> Amount</VBTableCellBody>
       

      </TableRow>
      {props.data.map((e, i) => (
        <TableRow>
          <VBTableCellBody sx={{ color: e.expence.name === "Total" ? 'red': "auto", fontWeight: e.expence.name === "Total"? "600" : "400" }} > {e.expence.name}</VBTableCellBody>
          <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6", textAlign: "right" }}> {formatNumber(e.expence.balance)}</VBTableCellBody>
          <VBTableCellBody sx={{ color: e.expence.name === "Total" ? 'red': "auto", fontWeight: e.expence.name === "Total"? "600" : "400" }}> {e.income.name}</VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(e.income.balance)}</VBTableCellBody>
        </TableRow>
      ))}
      <TableRow></TableRow>
    </TableBody>}
  </VBTableContainer>
  )
}

export default ConsolidateTable
import axios from "axios";
import { openSnackbar } from "../../features/SnackBarslice";

const bankListAPI = async function (body) {
  try {
    const { data } = await axios.post("api/v10/banks/banks/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in Bank List API");
  }
};

const bankDeleteAPI = async function (body, id) {
  try {
    const { data } = await axios.post("api/v10/banks/delete/bank/" + id + "/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const bankCreateAPI = async function (body, dispatch) {
  try {
    const data = axios({
      method: "post",
      url: "api/v10/banks/create-bank/",
      data: body,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    console.log("error on Bank Create API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const BankViewAPI = async function (body, id) {
  try {
    const { data } = await axios.post("api/v10/banks/view/bank/" + id + "/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const BankUpdateAPI = async function (body, id) {
  try {
    const { data } = await axios.post("api/v10/banks/edit/bank/" + id + "/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export {
  bankListAPI,
  bankDeleteAPI,
  bankCreateAPI,
  BankViewAPI,
  BankUpdateAPI,
};

import axios from "axios";

export const SalesOrderDetails = async function (body) {
    try {
      const { data } = await axios.post("api/v11/salesOrders/get-details/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
};  


export const CreateSalesOrder = async function (body) {
  try {
    const { data } = await axios.post("api/v11/salesOrders/sales-order/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("Error on Sales Order Create API");
    return error
  }
}

export const list_sales_order_api = async function (body) {
  
  try {
    const { data } = await axios.get("api/v11/salesOrders/sales-order/", {
      params:body,
    });
    return data;
  } catch (error) {
    console.log("error on Sales Return  API", error);
    return error
  }
};

export const edit_salesOrder_api = async function (body) {
  
  try {
    const { data } = await axios.put("api/v11/salesOrders/sales-order/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};

// Get Sales Return Master Api
export const view_salesorder_api = async function (body) {
  
  try {
    const { data } = await axios.get("api/v11/salesOrders/sales-order/", {
      params:body,
    });
    return data;
  } catch (error) {
    console.log("error on Sales Return Master view  API", error);
    return error
  }
};
import React, { useEffect, useState } from "react";
import { Box, List, ListItem, Paper, Typography, styled } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
  VBTableRowNull,
} from "../../../../Components/ReportTable/ReportTable";
import FilterLable from "../../../../Components/Utils/FilterLable";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import moment from "moment";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { date_range } from "../../../../Function/comonFunction";
import {
  CallBranchsList,
  CallLedgerListByGroupLimit,
  CallUsers,
} from "../../../../Api/Reports/CommonReportApi";
import { handleCashLedgers } from "../../../../Function/comonFunction";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import { useDispatch, useSelector } from "react-redux";
import {
  CallDebitNote,
  CallExpenseReport,
  CallExpenseReportDetails,
} from "../../../../Api/Reports/AccountApi";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { useTranslation } from "react-i18next";
import {
  VIKNBOOKS_FRONT_URL,
} from "../../../../generalVeriable";
import { formatNumber } from "../../../../generalFunction";
import ViknRadioGroup from "../../../../Components/Utils/ViknRadioGroup";
import ExportToExcelButton from "../../../../Components/Utils/ExportToExcelButton";
import { callSearchAccountLedgers } from "../../../../Api/Reports/PurchaseApi";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { UserRolePermission } from "../../../../Function/Editions";

const DebitNoteReport = () => {
  let Export_view = UserRolePermission("Debit Note", "print_permission");
  const dispatch = useDispatch();
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [t] = useTranslation("common");
  const [reportData, setReportData] = useState({
    StatusCode: 0,
    total_rows: 0,
    data: [],
    Total: {
      tot_sales: 0,
      tot_returns: 0,
      Amount: 0,
      CGSTAmount: 0,
      Discount: 0,
      GrandTotal: 0,
      IGSTAmount: 0,
      SGSTAmount: 0,
      VATAmount: 0,
      VoucherNo: "Total",
    },
  });
  const [loading, setLoading] = useState(false);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,EnableBranch,VAT,GST} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const PriceRounding = Number(PriceDecimalPoint);

  const [openFilter, setOpenFilter] = useState(false);
  const [Ledger, setLedger] = useState([0])
  const [progress, setProgress] = useState(0);
  const [isLoadingLedger, setIsLoadingLedger] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [page_no, setPage_no] = useState(1);
  const [filterOptions, setFilterOptions] = useState({
    userList: [],
    userObj: { customer_name: "" },

    ledgerList: [],
    ledgerValue: [],
    CashLedgers:[0],

    BranchList:[],
    BranchObj:{BranchName:''},

    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment(),
    },
    filterType: "productwise",
    filterList : [
      {name: "productwise", label: t("Product Wise")},
      {name: "ledgerwise", label: t("Ledger Wise")},
    ],

    reportType: "summary",
    reportList: [
      { name: "summary", label: t("Summary") },
      { name: "detailed", label: t("Detailed") },
    ],
  });
  console.log(filterOptions);
  //Handle functions

  const closeFilter = () => setOpenFilter(false);

  const searchReport = function () {
    fetchReport();
  };
  const handleChange = (e, name) => {
    let { value } = e.target;
    if (name === "reportType") {
      setFilterOptions({
        ...filterOptions,
        [name]: value,
        ledgerList: [],
        ledgerValue: [],
      });
    } else {
      setFilterOptions({ ...filterOptions, [name]: value });
    }
  };
  const handleDeletefilter = (name, index) => {
    console.log(name, index);
    let ledgerValue = [...filterOptions.ledgerValue];
    if (name === "ledgerValue") {
      ledgerValue = ledgerValue.filter((item, idx) => idx !== index);
      setFilterOptions((prevState) => ({
        ...prevState,
        ledgerValue: ledgerValue,
      }));
    } else {
      setFilterOptions((prevState) => ({ ...prevState, [name]: {} }));
    }
    fetchReport(name, ledgerValue);
  };
  const handlefilterList = () => {
    let list = [];
    let filterdata = filterOptions;
    if (filterdata.date.FromDate && filterdata.date.ToDate) {
      let date = String(
        filterOptions.date.FromDate.format("DD-MM-YYYY") +
          " to " +
          filterOptions.date.ToDate.format("DD-MM-YYYY")
      );
      list.push({ name: t("Date"), value: date });
    }
    if (filterdata.reportType !== "") {
      list.push({ name: t("Mode"), value: filterdata.reportType });
    }
    if (filterdata.filterType!==''){
      list.push({
        name : t("Filter Type"),
        value : filterdata.filterType
      })
    }
    if (filterdata.BranchObj?.BranchName !== undefined && filterdata.BranchObj?.BranchName !== '') {
      list.push({
        name: t("Branch"),
        value: filterdata.BranchObj.BranchName,
        // handleDelete: () => handleDeletefilter("BranchObj"),

      })
    }
    if (filterdata.ledgerValue && filterdata.ledgerValue.length) {
      filterdata.ledgerValue.forEach((ledger, index) => {
        if (ledger.LedgerName !== "") {
          list.push({
            name: t("Ledger Name"),
            value: ledger.LedgerName,
            handleDelete: () => handleDeletefilter("ledgerValue", index),
          });
        }
      });
    }

    if (
      filterdata.userObj.customer_name !== undefined &&
      filterdata.userObj.customer_name !== ""
    ) {
      list.push({
        name: t("User"),
        value: filterdata.userObj.customer_name,
        handleDelete: () => handleDeletefilter("userObj"),
      });
    }

    return list;
  };
  const handleDefaultFilter = async () => {
    setFilterOptions(prev => ({
      ...prev,
      userObj: { customer_name: "" },

      ledgerValue: [],
      BranchObj: Branch1,

      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Custom" },
        FromDate: moment(),
        ToDate: moment(),
      },
      reportType: "summary",
      filterType: "productwise",
    }));
  };
  // search Party or customer

  const handleAutoComplete = function (e, value, name) {
    if (value) {
      if (name === "date") {
        console.log(e, value);
        if (value.name === "Custom") {
          setFilterOptions(prev => ({
            ...prev,
            date: {
              ...filterOptions.date,
              isActive: true,
              value: value,
            },
          }));
        } else {
          let date = date_range(value.name);
          setFilterOptions(prev => ({
            ...prev,
            date: {
              ...filterOptions.date,
              isActive: false,
              value: value,
              ToDate: date.todate,
              FromDate: date.fromdate,
            },
          }));
        }
      } else {
        setFilterOptions(prev => ({ ...prev, [name]: value }));
      }
    }
  };
  // API Fetch data
  const fetchReport = async function (name, ledgerValue) {
    setLoading(true);
    let filterOptionsObj = filterOptions;
    let UserID = filterOptions.userObj?.UserID
      ? filterOptions.userObj.UserID
      : 0;
    if (name === "ledgerValue") {
      filterOptionsObj = {
        ledgerValue: ledgerValue,
      };
    } else if (name === "userObj") {
      UserID = 0;
    }
    let CashLedgers = await handleCashLedgers(filterOptionsObj, true);

    if (filterOptions.reportType === "summary") {
      const data = await CallDebitNote(
        {
          CompanyID:CompanyID,
          BranchID:BranchID,
          PriceRounding:Number(PriceDecimalPoint),
          page_no: page_no,
          IsProductWise : filterOptions.filterType === "productwise" ? true : false,
          page_size: appSetting.itemPerPage,
          UserID: UserID,
          ReportType : filterOptions.reportType,
          FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
          ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
          BranchID: filterOptions.BranchObj?.BranchID ? filterOptions.BranchObj.BranchID : 1,
          CashLedgers,
        },
        dispatch
      );
      console.log(data);
      if (data?.StatusCode === 6000) {
        setReportData(data);
      } else if (data?.StatusCode === 6001) {
        dispatch(
          openSnackbar({
            open: true,
            message: data.message,
            severity: "warning",
          })
        );
        setReportData({
          StatusCode: 0,
          total_rows: 0,
          data: [],
          total: {
            tot_sales: 0,
            tot_returns: 0,
            Amount: 0,
            CGSTAmount: 0,
            Discount: 0,
            GrandTotal: 0,
            IGSTAmount: 0,
            SGSTAmount: 0,
            VATAmount: 0,
            VoucherNo: "Total",
          },
        });
      }
    } else {
      const data = await CallDebitNote({
        CompanyID:CompanyID,
        BranchID:BranchID,
        PriceRounding:Number(PriceDecimalPoint),
        CreatedUserID:user_id,
        page_no: page_no,
        page_size: appSetting.itemPerPage,
        UserID: filterOptions.userObj?.UserID
          ? filterOptions.userObj.UserID
          : 0,
        FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
        ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
        ReportType : filterOptions.reportType,
        CashLedgers,
        BranchID: filterOptions.BranchObj?.BranchID ? filterOptions.BranchObj.BranchID : 1,
        IsProductWise : filterOptions.filterType === "productwise" ? true : false,

      });
      console.log(data);
      if (data?.StatusCode === 6000) {
        setReportData(data);
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: data.message,
            severity: "warning",
          })
        );
        setReportData({
          StatusCode: 0,
          total_rows: 0,
          data: [],
          total: {
            tot_sales: 0,
            tot_returns: 0,
            Amount: 0,
            CGSTAmount: 0,
            Discount: 0,
            GrandTotal: 0,
            IGSTAmount: 0,
            SGSTAmount: 0,
            VATAmount: 0,
            VoucherNo: "Total",
          },
        });
      }
    }
    closeFilter();
    setLoading(false);
  };
  const handleSearchLedger = async (e, value, name) => {
    if (value) {
      // setIsLoadingLedger(true);
      // if (filterOptions.reportType === "summary") {
      //   let ledgerList = await CallLedgerListByGroupLimit({
      //     items_per_page: 10,
      //     page_no: 1,
      //     search: value,
      //     GroupUnder: 29,
      //   });

      //   let ledger_data = [];
      //   if (ledgerList.data) {
      //     ledger_data = ledgerList.data;
      //   }
      //   setFilterOptions({ ...filterOptions, ledgerList: ledger_data });
      // } else {
        let length = value.length;
        let LedgersList = await callSearchAccountLedgers({
          CompanyID:CompanyID,
          BranchID:BranchID,
          PriceRounding:Number(PriceDecimalPoint),
          CreatedUserID:user_id,
          length: length,
          product_name: value,
          param: "name",

        });
        let ledgerList = [];
        if (LedgersList.data) {
          ledgerList = LedgersList.data;
          
        }
        setFilterOptions(prev => ({
          ...prev,
          ledgerList,
        }));
      setIsLoadingLedger(false);
    } else {
      setFilterOptions({ ...filterOptions, ledgerValue: [] });
    }
  };
  //handle Ledgers
  const handleSearchLedgers = async (e, value) => {
    if (value) {
    }
  };
  let Branch1 ;
  const filterApis = async function () {
    setIsLoadingUsers(true);
    const userResponse = await CallUsers({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      items_per_page: 10,
      page_no: 1,
      search: "",
      PartyType: "1",
    });
    let userList = [];
    if (userResponse && userResponse.StatusCode === 6000) {
      userList = userResponse.data;
    }

    const BranchResponse = await CallBranchsList({CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,});
    let BranchList = [];
    if (BranchResponse && BranchResponse.StatusCode === 6000) {
      BranchList = BranchResponse.data;
      Branch1 = BranchList.filter((e) => e.BranchID === 1 )
      console.log(Branch1,'>>>>>>>>>>>>>>>>KKKKKKKKKKKKKKKKK');
    }

    setIsLoadingLedger(true);
    // const LedgerResponse = await CallLedgerListByGroupLimit({
    //   items_per_page: 10,
    //   page_no: 1,
    //   search: "",
    //   GroupUnder:29,
    // });
    // let ledgerList = [];
    // if (LedgerResponse?.StatusCode === 6000) {
    //   ledgerList = LedgerResponse.data;
    // }

    setFilterOptions(prev =>({
      ...prev,
      //   ledgerList,
      userList,
      BranchList,
      BranchObj: Branch1[0],
    }));
    setIsLoadingLedger(false);
    setIsLoadingUsers(false);
  };

  const handleCashLedgerss = async (exp) => {
    let CashLedgers = [0];
    let ledgerValues = filterOptions.ledgerValue;
    const LedgerIDs = ledgerValues.map(item => item.LedgerID);
    CashLedgers.push(...LedgerIDs);
    if (exp) {
      setLedger(CashLedgers)
    }
    return CashLedgers; 
  }


  useEffect(() => {
    filterApis();
  }, []);

  useEffect(() => {
    fetchReport();
  }, [page_no,filterOptions.reportType === "detailed"]);
    const handleTableRowClick = (data) => {
      const destinationURL = `${VIKNBOOKS_FRONT_URL}dashboard/debit-note-single`;
      const finalURL = `${destinationURL}?id=${data.id}`;
      window.open(finalURL, '_blank');
      // window.location.href = finalURL;

    };
  const getDataForPage = () => {
    const startIndex = (page_no - 1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData?.new_data;
    return alldata?.slice(startIndex, endIndex) || [];
  };

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t("Debit Note Report")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className="right">
            {/* <ExportBtn /> */}
            {Export_view ? 
            <ReportDownload
              download_type={"PDF"}
              invoice_type={"Debit_Note_report"}
              BranchID={filterOptions.BranchObj?.BranchID ? filterOptions.BranchObj.BranchID : 1}
              FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
              ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
              RouteLedgers={Ledger}
              handleRouteList={
                filterOptions.ledgerValue.length ?
                handleCashLedgerss: 0
              }
              LedgerID={filterOptions.LedgerObj?.LedgerID?filterOptions.LedgerObj.LedgerID : 0}
              UserID={filterOptions.userObj?.UserID?filterOptions.userObj.UserID : 0}
              is_celery={true}
              setProgress={setProgress}
              filterValue = {filterOptions.filterType === "productwise" ? true : false}
              value = {filterOptions.reportType}
            />
            : null}
            {/* <PrintBtn /> */}
            <Pagenation
              totalItem={reportData?.Count}
              page_no={page_no}
              setPage_no={setPage_no}
            />
          </FlexBox>
        </Box>
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
            alignItems: "center",
          }}
        >
          <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
            {t("Applied Filters: ")}
          </div>
          {handlefilterList(filterOptions).map((val, i) => (
            <FilterLable
              name={val.name}
              value={val.value}
              handleDelete={val.handleDelete ? val.handleDelete : null}
            />
          ))}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("Serial No")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Date")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher No")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Ledger Name")}</VBTableCellHeader>
            {filterOptions.reportType === "detailed" && (
              <VBTableCellHeader>{t("Particulars")} </VBTableCellHeader>
            )}
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Gross")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Taxable")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Discount")}{" "}
            </VBTableCellHeader>
            {VAT ? (
              <VBTableCellHeader sx={{ textAlign: "right" }}>
                {t("VAT Amount")}
              </VBTableCellHeader>
            ) : null}
            {GST
              ? [
                  <VBTableCellHeader sx={{ textAlign: "right" }}>
                    {t("IGST Amount")}
                  </VBTableCellHeader>,
                  <VBTableCellHeader sx={{ textAlign: "right" }}>
                    {t("SGST Amount")}
                  </VBTableCellHeader>,
                  <VBTableCellHeader sx={{ textAlign: "right" }}>
                    {t("CGST Amount")}
                  </VBTableCellHeader>,
                ]
              : null}

            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Total")}
            </VBTableCellHeader>
          </VBTableHeader>
          {loading ? (
            <VBSkeletonLoader />
          ) : (
            <TableBody>
              {reportData.data.map((data, i) => (
                <TableRow
                onClick={()=>{handleTableRowClick(data)}}
                >
                  <VBTableCellBody sx={{cursor:"pointer"}}>
                    {(page_no - 1) * appSetting.itemPerPage + i + 1}
                  </VBTableCellBody>
                  <VBTableCellBody sx={{cursor:"pointer"}}>{data.Date}</VBTableCellBody>
                  <VBTableCellBody sx={{cursor:"pointer"}}>{data.VoucherNo}</VBTableCellBody>

                  <VBTableCellBody sx={{cursor:"pointer"}}>{data.LedgerName}</VBTableCellBody>
                  {filterOptions.reportType === "detailed" && (
                    <VBTableCellBody sx={{cursor:"pointer"}}>{data.Particular}</VBTableCellBody>
                  )}
                  <VBTableCellBody sx={{ cursor:"pointer", textAlign: "right" }}>
                    {" "}
                    {formatNumber(Number(data.GrossAmount))}
                  </VBTableCellBody>
                  <VBTableCellBody sx={{ cursor:"pointer", textAlign: "right" }}>
                    {formatNumber(Number(data.Taxable))}
                  </VBTableCellBody>
                  <VBTableCellBody sx={{ cursor:"pointer", textAlign: "right" }}>
                    {formatNumber(Number(data.Discount))}
                  </VBTableCellBody>
                  {VAT && (
                    <VBTableCellBody sx={{ cursor:"pointer", textAlign: "right" }}>
                      {formatNumber(Number(data.Vat))}
                    </VBTableCellBody>
                  )}
                  {GST
                    ? [
                        <VBTableCellBody sx={{ cursor:"pointer", textAlign: "right" }}>
                          {formatNumber(Number(data.IGSTAmount))}
                        </VBTableCellBody>,
                        <VBTableCellBody sx={{ cursor:"pointer", textAlign: "right" }}>
                          {formatNumber(Number(data.SGSTAmount))}
                        </VBTableCellBody>,
                        <VBTableCellBody sx={{ cursor:"pointer", textAlign: "right" }}>
                          {formatNumber(Number(data.CGSTAmount))}
                        </VBTableCellBody>,
                      ]
                    : null}

                  <VBTableCellBody sx={{ cursor:"pointer", textAlign: "right" }}>
                    {formatNumber(Number(data.Total))}
                  </VBTableCellBody>
                </TableRow>
              ))}
              <TableRow></TableRow>
            </TableBody>
          )}
          <VBTableFooter>
            <TableRow>
              <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              {filterOptions.reportType === "detailed" && (
                <VBTableCellFooter></VBTableCellFooter>
              )}
              <VBTableCellFooter sx={{ textAlign: "right" }}>
              {formatNumber(Number(reportData?.Total?.GrossAmount || 0))}
              </VBTableCellFooter>
              <VBTableCellFooter sx={{ textAlign: "right" }}>
                {formatNumber(Number(reportData?.Total?.taxableAmount || 0))}
              </VBTableCellFooter>
              <VBTableCellFooter sx={{ textAlign: "right" }}>
                {formatNumber(Number(reportData?.Total?.Discount || 0))}
              </VBTableCellFooter>
              {VAT && (
                <VBTableCellFooter
                  sx={{ textAlign: "right", cursor: "pointer" }}
                >
                  {formatNumber(Number(reportData?.Total?.Vat || 0))}
                </VBTableCellFooter>
              )}
              {GST
                ? [
                    <VBTableCellFooter sx={{ textAlign: "right" }}>
                      {formatNumber(Number(reportData?.Total?.IGSTAmount || 0))}
                    </VBTableCellFooter>,
                    <VBTableCellFooter sx={{ textAlign: "right" }}>
                      {formatNumber(Number(reportData?.Total?.SGSTAmount || 0))}
                    </VBTableCellFooter>,
                    <VBTableCellFooter sx={{ textAlign: "right" }}>
                      {formatNumber(Number(reportData?.Total?.CGSTAmount || 0))}
                    </VBTableCellFooter>,
                  ]
                : null}
              <VBTableCellFooter sx={{ textAlign: "right" }} align="right">
                {formatNumber(
                  Number(
                    reportData?.Total?.Total
                      ? reportData?.Total?.Total
                      : 0
                  )
                )}
              </VBTableCellFooter>
            </TableRow>
          </VBTableFooter>
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={searchReport}
        setPage_no={setPage_no}
        handleDefaultFilter={() => handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            {/* <ListItem>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Filter Name
                </InputLabel>
                <VBInputField />
              </FormControl>
            </ListItem> */}
            {/* <ListItem>
              <ViknAutoComplete
                value={filterOptions.date.value}
                disableClearable
                options={[
                  { name: "Custom" },
                  { name: "Today" },
                  { name: "This Week" },
                  { name: "This Month" },
                  { name: "Last 90 Days" },
                  { name: "This Year" },
                  { name: "Last Year" },
                ]}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) =>
                  handleAutoComplete(e, newValue, "date")
                }
              />
            </ListItem> */}

            <ListItem>
              <VBDatePiker
                handleAutoComplete={handleAutoComplete}
                dateValue={filterOptions.date.value}
                isDateRange={true}
                fromDate={filterOptions.date.FromDate}
                toDate={filterOptions.date.ToDate}
                // fromReadOnly={filterOptions.date.value.name !== "Custom"}
                // toReadOnly={filterOptions.date.value.name !== "Custom"}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      FromDate: value,
                      value: { name: "Custom" },
                    },
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      ToDate: value,
                      value: { name: "Custom" },
                    },
                  })
                }
              />
            </ListItem>
            <ListItem>
              <ViknRadioGroup
                handleChange={handleChange}
                radios={filterOptions.reportList.map((i) => ({
                  label: i.label,
                  value: i.name,
                }))}
                value={filterOptions.reportType}
                valueName={"reportType"}
              />
            </ListItem>
            <ListItem>
              <ViknRadioGroup
                labelName = {t('Report Type')}
                handleChange={handleChange}
                radios={filterOptions.filterList.map((i) => ({
                  label: i.label,
                  value: i.name,
                }))}
                value={filterOptions.filterType}
                valueName={"filterType"}
              />
            </ListItem>
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                placeholder={t("User")}
                filterName={t("User")}
                loading={isLoadingUsers}
                options={filterOptions.userList ? filterOptions.userList : []}
                getOptionLabel={(option) => option.customer_name || ""}
                name="userObj"
                onInputChange={(event, value, reason) => {
                  //   if (reason === "input") {
                  //     handleSearchEmployee(event,value);
                  // }
                  if (reason === "clear") {
                    setFilterOptions({ ...filterOptions, userObj: {} });
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "userObj", -1)}
                value={filterOptions.userObj}
              />
            </ListItem>

            {EnableBranch ? 
            <ListItem>
              <ViknAutoComplete 
                placeholder={t("Select Branch")}
                label = {t("Branch")}
                value={filterOptions.BranchObj}
                onChange= {(e,v) => handleAutoComplete(e,v,'BranchObj')}
                options={filterOptions.BranchList ? filterOptions.BranchList : []}
                getOptionLabel={(option) => option.BranchName || "" }
              
              />
            </ListItem>
            :null}

            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                multiple
                placeholder={t("Ledger Name")}
                filterName={t("Ledger Name")}
                loading={isLoadingLedger}
                options={
                  filterOptions.ledgerList ? filterOptions.ledgerList : []
                }
                getOptionLabel={(option) => option.LedgerName || ""}
                name="ledgerValue"
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    (option.LedgerName && option.LedgerName.toLowerCase().includes(inputValue.toLowerCase())) ||
                    (option.LedgerCode && option.LedgerCode.toLowerCase().includes(inputValue.toLowerCase()))
                  )
                }
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.LedgerCode}</Box>
                    <Box sx={{fontSize: "14px",}}>{option.LedgerName}</Box>
                  </Box>
                )}
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    handleSearchLedger(event, value);
                  }
                  if (reason === "clear") {
                    handleSearchLedger();
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "ledgerValue", -1)}
                value={filterOptions.ledgerValue}
              />
            </ListItem>
          </List>
        }
      />
    </>
  );
};

export default DebitNoteReport;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));

import {
  Autocomplete,
  CircularProgress,
  TextField,
  styled,
} from "@mui/material";
import React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const customRenderInput = (params, placeholder, loading) => (
  <FilterInput
    {...params}
    variant="outlined"
    placeholder={placeholder || "pHolder"}
    InputProps={{
      ...params.InputProps,
      endAdornment: (
        <React.Fragment>
          {loading ? <CircularProgress color="inherit" size={20} /> : null}
          {params.InputProps.endAdornment}
        </React.Fragment>
      ),
    }}
  />
);

const ViknSearchAutoComplete = (props) => {
  return props.filterName ? (
    <FormControl style={{ width: "100%" }}>
      <FormLabel
        id="demo-controlled-radio-buttons-group"
        style={{
          fontSize: "14px",
          fontWeight: "400",
          marginBottom: "9px",
          lineHeight: "15px",
        }}
      >
        {props.filterName}
      </FormLabel>
      <StyledAutocomplete
        {...props}
        autoHighlight
        loading={props.loading}
        renderInput={(params) =>
          customRenderInput(params, props.placeholder, props.loading)
        }
      />
    </FormControl>
  ) : (
    <StyledAutocomplete
      {...props}
      autoHighlight
      loading={props.loading}
      renderInput={(params) =>
        customRenderInput(params, props.placeholder, props.loading)
      }
    />
  );
};

export default ViknSearchAutoComplete;

const FilterInput = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  width: "100%",
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "none",
    // borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    // border: "none",
    fontSize: 12,
    width: "100%",
    padding: "4px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
  },
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme, sx }) => {
  return {
    width: "100%",
    border: "none",
    "& .MuiInputBase-root": {
      padding: "1px",
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      // Styles for the input element
      // backgroundColor: 'lightgray',
      width: "100%",
      borderRadius: "4px",
      // color: theme.palette.mode === 'dark' ? "grey" : 'white',
      backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
      ...sx,
    },
    "& .MuiInputLabel-root": {
      // Styles for the label element
      color: "blue",
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      // Styles for the focused outline of the outlined variant
      // borderColor: 'green',
      border: "none",
    },
    "& .MuiAutocomplete-option": {
      // Styles for each option in the dropdown men

      padding: theme.spacing(1),
      // backgroundColor: 'white',
      "&:hover": {
        //   backgroundColor: 'lightblue',
      },
    },
    "& .MuiSvgIcon-root": {
      // color: 'white'
    },
  };
});

import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {
  FormControl,
  InputBase,
  InputLabel,
  Typography,
  styled,
} from "@mui/material";
import TextField from "@mui/material/TextField";

const ViknAutoComplete = (props) => {
  const customRenderInput = (params, placeholder, error) => (
    <FilterInput
      error={error || false}
      {...params}
      variant="outlined"
      placeholder={placeholder}
      required={props.required ? props.required : false}
    />
  );

  return (
    <FormControl sx={{ width: "100%" }} variant="standard">
      {props.label && (
        <Typography
          component={"label"}
          sx={{ fontSize: "14px", color: "#000000", mb: "8px" }}
        >
          {props.label}
        </Typography>
      )}
      <StyledAutocomplete
        // value={props.value}
        // onChange={(event, newValue) => setValue(newValue)}
        // options={options}
        // getOptionLabel={(option) => option.label}
        // isOptionEqualToValue={(option, value) => option.name === value.name}
        {...props}
        renderInput={(params) => customRenderInput(params, props.placeholder, props.error, props.required)}
      />
    </FormControl>
  );
};

export default ViknAutoComplete;

const FilterInput = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  width: "100%",
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "none",
    // borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    // border: "none",
    fontSize: 12,
    width: "100%",
    padding: "4px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
  },
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme, sx }) => {
  return {
    width: "100%",
    border: "none",
    "& .MuiInputBase-root": {
      padding: "1px",
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      // Styles for the input element
      // backgroundColor: 'lightgray',
      width: "100%",
      borderRadius: "4px",
      // color: theme.palette.mode === 'dark' ? "grey" : 'white',
      backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
      ...sx,
    },
    "& .MuiInputLabel-root": {
      // Styles for the label element
      color: "blue",
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      // Styles for the focused outline of the outlined variant
      // borderColor: 'green',
      border: "none",
    },
    "& .MuiAutocomplete-option": {
      // Styles for each option in the dropdown men

      padding: theme.spacing(1),
      // backgroundColor: 'white',
      "&:hover": {
        //   backgroundColor: 'lightblue',
      },
    },
    "& .MuiSvgIcon-root": {
      // color: 'white'
    },
  };
});

import { TextField } from "@mui/material";

export function BarcodeTextBox(props) {
    let { handleChanges, name, value, textFieldRef,handleKeyDown } = props;
    return (
        
      <TextField
        className="StyledTextField "
        fullWidth
        label=""
        // id="fullWidth"
        name={name}
        value={value}
        onChange={(e) => {
          handleChanges(e);
        }}
        style={{ borderRadius: "0" }}
        sx={{fontSize:"13px",backgroundColor: "#ebebeb"}}
        focused
        onKeyDown={(e) => {
          handleKeyDown(e);
        }}
        // autoFocus={autoFocus}
        // ref={textFieldRef}
        placeholder="Type barcode here"
        InputProps={{
          inputProps: {
            style: { padding: 0,fontSize:"13px",paddingLeft:"10px",height:"35px"}, 
          },
        }}
      />
    );
  }
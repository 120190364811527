import { Navigate } from "react-router-dom";
import { activateFunction } from "../../../Function/Editions";
// import { Suspense, lazy } from "react";

// const SalesReport = lazy(() => import("../../../Pages/Reports/Sales/SalesReport/SalesReport"));
// const SalesOrderReport = lazy(() => import("../../../Pages/Reports/Sales/SalesOrderReport/SalesOrderReport"));
// const SalesRegisterReport = lazy(() => import("../../../Pages/Reports/Sales/SalesRegistorReport/SalesRegisterReport"));
// const SalesIntegratedReport = lazy(() => import("../../../Pages/Reports/Sales/SalesIntegratedReport/SalesIntegratedReport"));
// const SalesReturnRegistorReport = lazy(() => import("../../../Pages/Reports/Sales/SalesReturnRegistorReport/SalesReturnRegistorReport"));
// const SalesSummaryReport = lazy(() => import("../../../Pages/Reports/Sales/SalesSummaryReport/SalesSummaryReport"));
import SalesReport from "../../../Pages/Reports/Sales/SalesReport/SalesReport";
import SalesOrderReport from "../../../Pages/Reports/Sales/SalesOrderReport/SalesOrderReport";
import SalesRegisterReport from "../../../Pages/Reports/Sales/SalesRegistorReport/SalesRegisterReport";
import SalesIntegratedReport from "../../../Pages/Reports/Sales/SalesIntegratedReport/SalesIntegratedReport";
import SalesReturnRegistorReport from "../../../Pages/Reports/Sales/SalesReturnRegistorReport/SalesReturnRegistorReport";
import SalesSummaryReport from "../../../Pages/Reports/Sales/SalesSummaryReport/SalesSummaryReport";

function transformString(str) {
  const words = str.split('-');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  const transformedStr = capitalizedWords.join(' ');
  return transformedStr;
}

// Higher Order Component for protected routes
const ProtectedRoute = ({ children, authCondition, path }) => {
  let isAuthCondition = authCondition;
  document.title = transformString(path) || "Vikn Books"
  return isAuthCondition ? children : <Navigate to="/dashboard" />;
};

const route = [
  { path: "sales-report", element: <SalesReport />, isAuth: activateFunction("SalesReport") },
  { path: "sales-order-report", element: <SalesOrderReport />, isAuth: activateFunction("SalesOrderReport") },
  { path: "sales-summery-report", element: <SalesSummaryReport />, isAuth: activateFunction("SalesSummary") },
  { path: "sales-register-report", element: <SalesRegisterReport />, isAuth: activateFunction("SalesRegisterReport") },
  {path: "sales-return-register-report",element: <SalesReturnRegistorReport />,isAuth: activateFunction("SalesReturnRegisterReport") },
  { path: "sales-integrated-report", element: <SalesIntegratedReport />, isAuth: activateFunction("SalesIntegratedReport") },
];

const saleRoute = route.map((route) => ({
  ...route,
  element:
  // (
    // <Suspense fallback={<div>Loading...</div>}>
    <ProtectedRoute path={route.path} authCondition={route.isAuth}>{route.element}</ProtectedRoute>,
    // </Suspense>
    // )
}));

export default saleRoute;

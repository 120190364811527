import {
  Box,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import SearchReport from "../../../../Components/Utils/SearchReport";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import PrintBtn from "../../../../Components/Utils/PrintBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import FilterLable from "../../../../Components/Utils/FilterLable";
import {
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import { CallStockTransferRegisterReport } from "../../../../Api/Reports/InventoryApi";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import {
  CallUsers,
  CallWarehouses,
} from "../../../../Api/Reports/CommonReportApi";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "../../../../generalFunction";
import { openSnackbar } from "../../../../features/SnackBarslice";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { goToInvoice } from "../../../../Function/comonFunction";
import { UserRolePermission } from "../../../../Function/Editions";
import { useTranslation } from "react-i18next";

const StockTransferRegisterReport = () => {
  let Export_view = UserRolePermission("Stock Transfer Register", "print_permission");
  const dispatch = useDispatch()
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [page_no, setPage_no] = useState(1)
  const [reportData, setReportData] = useState([]);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [clearFilter, setClearFilter] = useState(false)
  const [t, i18n] = useTranslation("common");
  const [filterOptions, setFilterOptions] = useState({
    wareHouseTo: null,
    wareHouseFrom: null,
    user: null,
    fromDate: moment(),
    toDate: moment(),
  });

  const [filterData, setFilterData] = useState({
    wareHouseFromList: [],
    wareHouseToList: [],
    userList: [],
  });

  //Handle functions
  const closeFilter = () => setOpenFilter(false);

  const handleDefaultFilter = function() {
    setFilterOptions({
      wareHouseTo: null,
      wareHouseFrom: null,
      user: null,
      fromDate: moment(),
      toDate: moment(),
    });
    setClearFilter(!clearFilter)
    setPage_no(1)
  }

  const searchReport = function () {
    fetchReport();
  };

  // API Fetch data
  const fetchReport = async function () {
    const payload = {
      FromDate: filterOptions.fromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.toDate.format("YYYY-MM-DD"),
      UserID: "0",
      WareHouseFrom: 0,
      WareHouseTo: 0,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id
    };
    if (filterOptions.wareHouseFrom) payload.WareHouseFrom = filterOptions.wareHouseFrom.WarehouseID;
    if (filterOptions.wareHouseTo) payload.WareHouseTo = filterOptions.wareHouseTo.WarehouseID;
    if (filterOptions.user) payload.UserID = filterOptions.user.UserID;
    const data = await CallStockTransferRegisterReport(payload);
    console.log(data);
    if (data.StatusCode === 6000) {
      setReportData(data.data);
    } else {
      dispatch(
        openSnackbar({
          open : true,
          message : data?.message || 'Data Not Found',
          severity : 'error',
        })
      )
      setReportData([])
    }
  };

  const filterApis = async function () {
    const resUser = await CallUsers({
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id
    });

    const resWarehouse = await CallWarehouses({
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      show_all:true
    });

    setFilterData({
      wareHouseFromList: resWarehouse.data,
      wareHouseToList: resWarehouse.data,
      userList: resUser.data,
    });
  };

  const getDataForPage = () => {
    const startIndex = (page_no - 1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData.slice(0, reportData.length -1)
    return alldata.slice(startIndex, endIndex) || [];
  };

  useEffect(() => {
    //   fetchExpensesReport()
    filterApis();
  }, []);

  useEffect(() => {
    fetchReport()
  }, [clearFilter])
  
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500", mr: 2 }}
              variant="h2"
            >
              {t("Stock Transfer Register Report")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </Box>

          <FlexBox>
            {/* <ExportBtn /> */}
            {Export_view ? 
            <ReportDownload
            download_type={"PDF"}
            invoice_type={"stock_transfer_report"}
            FromDate={filterOptions.fromDate.format("YYYY-MM-DD")}
            ToDate={filterOptions.toDate.format("YYYY-MM-DD")}
            UserID={filterOptions.user ? filterOptions.user.UserID : "0"}
            WareHouseFrom={filterOptions.wareHouseFrom ? filterOptions.wareHouseFrom.WarehouseID : "0"}
            WareHouseTo={filterOptions.wareHouseTo ? filterOptions.wareHouseTo.WarehouseID : "0"}
          />
          : null}
            {/* <PrintBtn /> */}
            <Pagenation 
              totalItem={reportData.length - 1}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          // This also change into custom component
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // height: "54px",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FilterLable
            name={t("Date")}
            value={filterOptions.fromDate.format("DD/MM/YYYY") + " - " + filterOptions.toDate.format("DD/MM/YYYY") }
          />

          {filterOptions.user && <FilterLable
              name={t("User")}
              value={filterOptions.user.name}
              handleDelete={() => setFilterOptions({...filterOptions, user: null})}
            />
          }
          {filterOptions.wareHouseFrom && <FilterLable
              name={t("Warehouse From")}
              value={filterOptions.wareHouseFrom.name}
              handleDelete={() => setFilterOptions({...filterOptions, wareHouseFrom: null})}
            />
          }
          {filterOptions.wareHouseTo && <FilterLable
              name={t("Warehouse To")}
              value={filterOptions.wareHouseTo.name}
              handleDelete={() => setFilterOptions({...filterOptions, wareHouseTo: null})}
            />
          }
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("Serial No")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher No")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher Date")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Warehouse From")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Warehouse To")} </VBTableCellHeader>
            <VBTableCellHeader  sx={{ textAlign: "right" }}>{t("Total Qty")} </VBTableCellHeader>
            <VBTableCellHeader  sx={{ textAlign: "right" }}>{t("Grand Total")} </VBTableCellHeader>
            {/* <VBTableCellHeader>Qty Out </VBTableCellHeader>
            <VBTableCellHeader>Balance </VBTableCellHeader> */}
          </VBTableHeader>
          <TableBody>
            {getDataForPage().map((data, i) => (
              <TableRow sx={{ cursor: "pointer"}} onClick={() => goToInvoice(data.id, "ST")}>
                <VBTableCellBody>{(page_no - 1) * appSetting.itemPerPage + i + 1}</VBTableCellBody>
                <VBTableCellBody>{data.VoucherNo}</VBTableCellBody>
                <VBTableCellBody>{data.Date}</VBTableCellBody>
                <VBTableCellBody>{data.WarehouseFrom}</VBTableCellBody>
                <VBTableCellBody>{data.WarehouseTo}</VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right" }}>{data.TotalQty}</VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right" }}>{formatNumber(data.GrandTotal)}</VBTableCellBody>
              </TableRow>
            ))}
            <TableRow></TableRow>
          </TableBody>
          <VBTableFooter >
          <TableRow>

            <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
            <VBTableCellFooter>{"  "}</VBTableCellFooter>
            <VBTableCellFooter>{"  "}</VBTableCellFooter>
            <VBTableCellFooter>{"  "}</VBTableCellFooter>
            <VBTableCellFooter>{"  "}</VBTableCellFooter>
            <VBTableCellFooter  sx={{ textAlign: "right" }}>{reportData[reportData.length - 1]?.TotalQty} </VBTableCellFooter>
            <VBTableCellFooter sx={{ textAlign: "right" }}>{formatNumber(reportData[reportData.length - 1]?.GrandTotal)}</VBTableCellFooter>
          </TableRow>
        </VBTableFooter>
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={() => searchReport()}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.fromDate}
                toDate={filterOptions.isAsofDate ? null : filterOptions.toDate}
                fromOnChange={(value) =>
                  setFilterOptions({ ...filterOptions, fromDate: value })
                }
                toOnChange={(value) =>
                  setFilterOptions({ ...filterOptions, toDate: value })
                }
              />
            </ListItem>

            <ListItem>
              <ViknAutoComplete
                label={t("User")}
                placeholder={t("Search for User")}
                value={filterOptions.user}
                onChange={(e, newValue) =>
                  setFilterOptions((prev) => ({ ...prev, user: newValue }))
                }
                options={filterData.userList}
                getOptionLabel={(option) => option?.name}
                // isOptionEqualToValue={(option, value) => option?.name === value?.name}
              />
            </ListItem>

            <ListItem>
              <ViknAutoComplete
                label={t("Warehouse From")}
                placeholder={"Search for Warehouse From"}
                value={filterOptions.wareHouseFrom}
                onChange={(e, newValue) =>
                  setFilterOptions((prev) => ({
                    ...prev,
                    wareHouseFrom: newValue,
                  }))
                }
                options={filterData.wareHouseFromList}
                getOptionLabel={(option) => option?.name}
                // isOptionEqualToValue={(option, value) => option?.name === value?.name}
              />
            </ListItem>

            <ListItem>
              <ViknAutoComplete
                label={t("Warehouse To")}
                placeholder={"Search for Warehouse To"}
                value={filterOptions.wareHouseTo}
                onChange={(e, newValue) =>
                  setFilterOptions((prev) => ({
                    ...prev,
                    wareHouseTo: newValue,
                  }))
                }
                options={filterData.wareHouseToList}
                getOptionLabel={(option) => option?.name}
                // isOptionEqualToValue={(option, value) => option?.name === value?.name}
              />
            </ListItem>
            {/* <Divider /> */}
          </List>
        }
      />
    </>
  );
};

export default StockTransferRegisterReport;

const FlexBox = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  // width: "26%",
}));

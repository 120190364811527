import React, { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  List,
  ListItem,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { VBSkeletonLoader, VBTableCellBody, VBTableCellFooter, VBTableCellHeader, VBTableContainer, VBTableFooter, VBTableHeader, VBTableRowNull } from '../../../../Components/ReportTable/ReportTable';
import FilterLable from '../../../../Components/Utils/FilterLable';
import SearchReport from '../../../../Components/Utils/SearchReport';
import FilterBtn from '../../../../Components/Utils/FilterBtn';
import ExportBtn from '../../../../Components/Utils/ExportBtn';
import PrintBtn from '../../../../Components/Utils/PrintBtn';
import Pagenation from '../../../../Components/Utils/Pagenation';
import { CallExpenseSummaryReport } from '../../../../Api/Reports/FinanceApi';
import moment from 'moment';
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from '../../../../Components/Utils/ViknAutoComplete';
import { date_range } from '../../../../Function/comonFunction';
import { CallBranchsList, CallProductCategories, CallProductGroups, CallProductSearch, CallUsers, CallWarehouses } from '../../../../Api/Reports/CommonReportApi';
import VBDatePiker from '../../../../Components/Utils/VBDatePiker';
import ViknSearchAutoComplete from '../../../../Components/Utils/ViknSearchAutoComplete';
import ViknCheckboxGroup from '../../../../Components/Utils/ViknCheckboxGroup';
import ViknRadioGroup from '../../../../Components/Utils/ViknRadioGroup';
import { callProductCodeSearch, callPurchaseRegister, callSearchAccountLedgers } from '../../../../Api/Reports/PurchaseApi';
import { useDispatch, useSelector } from 'react-redux';
import { Warehouse } from '@mui/icons-material';
import VBInputField from '../../../../Components/Utils/VBInputField';
import { useTranslation } from 'react-i18next';
import { openSnackbar } from '../../../../features/SnackBarslice';
import { formatNumber } from '../../../../generalFunction';
import { VIKNBOOKS_FRONT_URL } from '../../../../generalVeriable';
import ReportDownload from '../../../../Components/Utils/ReportDownload';
import { UserRolePermission } from '../../../../Function/Editions';

const PurchaseRegisterReport = () => {
  let Export_view = UserRolePermission("Purchase Register Report", "print_permission");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const dispatch = useDispatch()
  const [reportData, setReportData] = useState({
    data : [],
  })
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [isLoadingLedgers, setisLoadingLedgers] = useState(false);
  const [t, i18n] = useTranslation("common");
  const [isLoadingProductCode, setisLoadingProductCode] = useState(false);
  const [loading,setLoading] = useState (false);
  const [isLoadingProducts, setisLoadingProducts] = useState(false);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,EnableWarehouse, VAT, GST} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const Is_Warehouse = EnableWarehouse
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [page_no, setPage_no] = useState(1);
  const [filterOptions, setFilterOptions] = useState({
    Barcode : '',
    userList : [],
    UserObj : {customer_name : "" },
    WarehouseList : [],
    WarehouseObj : {WarehouseName :''},
    LedgerList : [],
    LedgerObj : {LedgerName :''},
    ProductCodeList : [],
    ProductCodeObj : {ProductCode : ''},
    productCategoryList : [],
    productCategoryObj : {ProductCategoryName :''},
    ProductGroupList : [],
    ProductGroupObj : {GroupName :''},
    ProductList : [],
    ProductObj : {ProductName : ''},
    filterType : 'allproducts',
    ReportType : [
      {name : 'allproducts',label:t('All Products')},
      {name : 'product',label:t('Product')},
      {name : 'productcategory',label:t('Product Category')},
      {name : 'productgroup' ,label:t('Product Group')},
    ],
    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment(),
    },
    filterList : [
      {name : 'purchase', label : t('Purchase')},
      {name : 'purchasereturn', label : t('Purchase Return')},
    ],
    filterValue : 'purchase',
  })

  //Handle functions

  const closeFilter = () => setOpenFilter(false);

  const searchReport = () => {
    fetchReport();
  }

  const handlechange = (e, name) => {
    let { value } = e.target
    setFilterOptions({...filterOptions,[name]: value})
  }

  const handlechangeFilter = (e, name) => {
    let { value } = e.target
    setFilterOptions({
      ...filterOptions,[name]: value,
      productCategoryObj: null,
      ProductGroupObj: null,
      ProductObj: null,
    })
  }
  const handleSearchLedgers = async (e,value) => {
    if (value) {
      setisLoadingLedgers(true);
      let length = value.length;
      let LedgersList = await callSearchAccountLedgers({
        CompanyID : CompanyID,
        BranchID : BranchID,
        PriceRounding : Number(PriceDecimalPoint),
        CreatedUserID: user_id,
        product_name : value,
        length : length,
        param : 'name',
      },dispatch);
      let LedgerList = [];
      if (LedgersList?.data){
        LedgerList = LedgersList.data
      }
      setFilterOptions({...filterOptions,LedgerList : LedgersList.data});
      setisLoadingLedgers(false)
    } else {
      setFilterOptions({...filterOptions,LedgerObj : {LedgerName : ''}});
    }
  };

  const handleSearchProduct  = async (e,value) => {
    if (value) {
      setisLoadingProducts(true);
      let length = value.length;
      let ProductLists = await CallProductSearch({
        CompanyID : CompanyID,
        BranchID : BranchID,
        PriceRounding : Number(PriceDecimalPoint),
        CreatedUserID: user_id,
        product_name : value,
        length : length,
        type : 'report',
      });
      let ProductList = [];
      if (ProductLists.StatusCode === 6000){
        ProductList = ProductLists.data
      }
      setFilterOptions({...filterOptions,ProductList : ProductLists.data})
      setisLoadingProducts(false)
    } else {
      setFilterOptions({...filterOptions,ProductObj : {ProductName : ''}});
    }
  };

  const handleSearchProductCode = async (e, value) => {
    if (value) {
      setisLoadingProductCode(true);
      let length = value.length;
      let ProductCodeLists = await callProductCodeSearch ({
        CompanyID : CompanyID,
        BranchID : BranchID,
        PriceRounding : Number(PriceDecimalPoint),
        CreatedUserID: user_id,
        length : length,
        product_name : value,
      })
      let ProductCodeList = [];
      if (ProductCodeLists.data) {
        ProductCodeList = ProductCodeLists.data
      }
      setFilterOptions({
        ...filterOptions,ProductCodeList : ProductCodeLists.data,
        productCategoryObj: null,
        ProductGroupObj: null,
        ProductObj: null,
      })
      setisLoadingProductCode (false)
    } else {
      setFilterOptions({...filterOptions,ProductCodeObj : {ProductCode : ''}})
    }
  };
  const handleDeletefilter = (name, index) => {
    console.log(name, index);
    if (name === "routeListValue") {
      let routeListValue = [...filterOptions.routeListValue];
      routeListValue = routeListValue.filter((item, idx) => idx !== index);
      setFilterOptions((prevState) => ({
        ...prevState,
        routeListValue: routeListValue,
      }));
    } else {
      setFilterOptions((prevState) => ({ ...prevState, [name]: '' }));
    }
  };
  const handleFilterList = () => {
    let list = [];
    let filterdata = filterOptions
    console.log(filterdata,'>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    if (filterdata.date.FromDate &&filterdata.date.ToDate ) {
      let date = String(filterOptions.date.FromDate.format("DD-MM-YYYY") + " to " + filterOptions.date.ToDate.format("DD-MM-YYYY"))
      list.push({ name: t("Date"), value: date });
    }
    if (filterdata.filterType !== undefined && filterdata.filterType !== null) {
      list.push({
        name : t('Filter Type'),
        value : filterdata.filterType,
      })
    }
    if (filterdata && filterdata.UserObj && filterdata.UserObj.customer_name !== undefined && filterdata.UserObj.customer_name !== ''){
      list.push({
        name: t('User'),
        value: filterdata.UserObj.customer_name,
        handleDelete: () => handleDeletefilter('UserObj')
      })
    }
    if (filterdata && filterdata.WarehouseObj?.WarehouseName !== undefined && filterdata.WarehouseObj.WarehouseName !== '') {
      list.push({
        name : t('Warehouse'),
        value : filterdata.WarehouseObj.WarehouseName,
        handleDelete: () => handleDeletefilter('WarehouseObj')
      })
    }
    if (filterdata && filterdata?.Barcode !== undefined && filterdata?.Barcode !== '') {
      list.push({
        name : t('Barcode'),
        value : filterdata?.Barcode? filterdata.Barcode : '',
        handleDelete : () => handleDeletefilter('Barcode')
      })
    }
    if (filterdata && filterdata?.LedgerObj?.LedgerName !== undefined && filterdata?.LedgerObj?.LedgerName !== '') {
      list.push({
        name : t('Ledger'),
        value : filterdata?.LedgerObj?.LedgerName,
        handleDelete : () => handleDeletefilter('LedgerObj')
      })
    }
    if (filterdata && filterdata?.ProductCodeObj?.ProductCode !== undefined && filterdata?.ProductCodeObj?.ProductCode !== '') {
      list.push({
        name : t('Product Code'),
        value : filterdata?.ProductCodeObj?.ProductCode ,
        handleDelete : () => handleDeletefilter('ProductCodeObj')
      })
    }
    if (filterdata && filterdata?.productCategoryObj?.ProductCategoryName !== undefined && filterdata?.productCategoryObj?.ProductCategoryName !== '') {
      list.push({
        name : t('Product Category'),
        value : filterdata?.productCategoryObj?.ProductCategoryName ,
        handleDelete : () => handleDeletefilter('productCategoryObj')
      })
    }
    if (filterdata && filterdata?.ProductGroupObj?.GroupName !== undefined && filterdata?.ProductGroupObj?.GroupName !== '') {
      list.push({
        name : t('Product Group'),
        value : filterdata?.ProductGroupObj?.GroupName,
        handleDelete : () => handleDeletefilter('ProductGroupObj')
      })
    }
    if (filterdata && filterdata?.ProductObj?.ProductName !== undefined && filterdata?.ProductObj?.ProductName !== '') {
      list.push({
        name : t('Product'),
        value : filterdata?.ProductObj?.ProductName,
        handleDelete : () => handleDeletefilter('ProductObj')
      })
    }
    if (filterdata && filterdata?.filterList && filterdata?.filterList !== '') {
      list.push({
        name : t('Report Type'),
        value : filterdata?.filterValue,
      })
    }
    return list
  }
  const handleDefaultFilter = async () => {
    setFilterOptions({
      ...filterOptions,
      UserObj: { customer_name: "" },
      WarehouseObj : {WarehouseName: ''},
      Barcode : '',
      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Today" },
        FromDate: moment(),
        ToDate: moment(),
      },
      filterType: 'allproducts',
      productCategoryObj : {ProductCategoryName :''},
      ProductGroupObj : {GroupName :''},
      ProductObj : {ProductName : ''},
      LedgerObj : {LedgerName :''},
      ProductCodeObj : {ProductCode : ''},
      filterValue : "purchase",
    })
  }
  const handleAutoComplete = function (e, value, name) {
    if (value) {
      if (name === "date") {
        console.log(e, value);
        if (value.name === "Custom") {
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: true,
              value: value,
            },
          });
        } else {
          let date = date_range(value.name);
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: false,
              value: value,
              ToDate: date.todate,
              FromDate: date.fromdate,
            },
          });
        }
      } else {
        setFilterOptions({ ...filterOptions, [name]: value });
      }
    }
  };

const filterApis = async function () {
  setIsLoadingUser(true);
  const userResponse = await CallUsers({
    CompanyID : CompanyID,
    BranchID : BranchID,
    PriceRounding : Number(PriceDecimalPoint),
    items_per_page: 10,
    page_no: 1,
    search: "",
    PartyType: "1",
  });
  let userList = [];
  if (userResponse.StatusCode === 6000) {
    userList = userResponse.data;
  }
  const WarehouseLists = await CallWarehouses ({
    CompanyID : CompanyID,
    BranchID : BranchID,
    CreatedUserID: user_id,
    show_all:true
  });
  let WarehouseList = [];
  if (WarehouseLists.StatusCode === 6000){
    WarehouseList = WarehouseLists.data;
  }


  const productcategories = await CallProductCategories ({
    CompanyID : CompanyID,
    BranchID : BranchID,
    CreatedUserID: user_id,
  })
  let productCategoryList = [];
  if (productcategories.StatusCode === 6000) {
    productCategoryList = productcategories.data
  }
  const productgroup = await CallProductGroups({
    CompanyID : CompanyID,
    BranchID : BranchID,
    CreatedUserID: user_id,
  })
  let ProductGroupList = [];
  if (productgroup.StatusCode === 6000) {
    ProductGroupList = productgroup.data
  }

  setFilterOptions({
    ...filterOptions,
    userList,
    WarehouseList,
    productCategoryList,
    ProductGroupList,
  });
  setIsLoadingUser(false);

}

  // API Fetch data
const fetchReport = async function () {
  setLoading(true);
  const data = await callPurchaseRegister({
    CompanyID : CompanyID,
    BranchID : BranchID,
    PriceRounding : Number(PriceDecimalPoint),
    FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
    ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
    ProductID: filterOptions.ProductObj?.ProductID ? filterOptions.ProductObj.ProductID : 0,
    CategoryID: filterOptions.productCategoryObj?.ProductCategoryID ? filterOptions.productCategoryObj.ProductCategoryID : 0,
    GroupID:filterOptions.ProductGroupObj?.ProductGroupID ? filterOptions.ProductGroupObj.ProductGroupID : 0,
    WarehouseID :filterOptions.WarehouseObj?.WarehouseID?filterOptions.WarehouseObj.WarehouseID : 0 ,
    UserID : filterOptions.UserObj?.UserID?filterOptions.UserObj.UserID : 0,
    LedgerID : filterOptions.LedgerObj?.LedgerID?filterOptions.LedgerObj.LedgerID : 0,
    ProductCode : filterOptions.ProductCodeObj?.ProductCode?filterOptions.ProductCodeObj.ProductCode : 0,
    Barcode : filterOptions.Barcode? filterOptions.Barcode : '',
    ReportType : filterOptions?.filterValue
  },dispatch)
  if (data?.StatusCode === 6000) {
    setReportData(data)
  } else if (data?.StatusCode === 6001) {
    setReportData(data);
    dispatch(
      openSnackbar({
        open : true,
        message : data.message,
        severity : 'warning',
      })
    )
  }
  setLoading(false)
}
  const getDataForPage = () => {
    const startIndex = (page_no -1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData?.data?.slice(0,reportData?.data?.length -1)
    return alldata?.slice(startIndex, endIndex) || [];
  }

  useEffect(() => {
    filterApis()
  }, [])
  const handleTableRowClick = (data) => {
    let  destinationURL=""
    if (data.VoucherType ==='PI'){
      destinationURL = `${VIKNBOOKS_FRONT_URL}dashboard/purchase-invoice-single?id=${data.id}`;
    }else{
      destinationURL = `${VIKNBOOKS_FRONT_URL}dashboard/purchase-return-single?id=${data.id}`;
    }
    // const destinationURL = `${VIKNBOOKS_FRONT_URL}dashboard/purchase-return-single?id=${data.id}`;
    // const destinationURL = `${VIKNBOOKS_FRONT_URL}dashboard/purchase-invoice-single?id=${data.id}`;
    window.open(destinationURL, '_blank');
  };
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className='left'>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
             {t('Purchase Register Report')}
            </Typography>
            {/* <SearchReport /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className='right'>
          {Export_view ? 
          <ReportDownload
            download_type={"PDF"}
            invoice_type={"purchase_register_report"}
            FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
            ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
            WarehouseID={filterOptions.WarehouseObj?.WarehouseID?filterOptions.WarehouseObj.WarehouseID : 0}
            LedgerID={filterOptions.LedgerObj?.LedgerID?filterOptions.LedgerObj.LedgerID : 0}
            UserID={filterOptions.UserObj?.UserID?filterOptions.UserObj.UserID : 0}
            ProductID={ filterOptions.ProductObj?.ProductID ? filterOptions.ProductObj.ProductID : 0}
            CategoryID={filterOptions.productCategoryObj?.ProductCategoryID ? filterOptions.productCategoryObj.ProductCategoryID : 0}
            GroupID={filterOptions.ProductGroupObj?.ProductGroupID ? filterOptions.ProductGroupObj.ProductGroupID : 0}
            ProductCode={filterOptions.ProductCodeObj?.ProductCode?filterOptions.ProductCodeObj.ProductCode : 0}
            Barcode={filterOptions.Barcode? filterOptions.Barcode : ''}
            filterValue={filterOptions?.filterValue}
          />
          :null}
            {/* <PrintBtn /> */}
            <Pagenation 
              totalItem={reportData?.data?.length-1}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
        <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
          {t("Applied Filters: ")}
        </div>
        {handleFilterList(filterOptions).map((val, i) => (
          <FilterLable
            name={val.name}
            value={val.value}
            handleDelete={val.handleDelete?val.handleDelete:null}
          />
        ))}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t('Sl No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Date')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Invoice No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Product Code')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Product Name')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Barcode')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Quantity')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Unit Price')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Gross Amount')}</VBTableCellHeader>
            {VAT && <VBTableCellHeader sx={{textAlign:"right"}}>{t('VAT Amount')}</VBTableCellHeader>}
            {/* {GST && <VBTableCellHeader sx={{textAlign:"right"}}>{t('TAX Amount')}</VBTableCellHeader>} */}
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Discount')}</VBTableCellHeader> 
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Net Amount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Cost')}</VBTableCellHeader> 
          </VBTableHeader>
          {loading ? 
            <VBSkeletonLoader /> :
          <TableBody>
            {getDataForPage().map((data, i) => (
            <TableRow
            onClick={() =>
              handleTableRowClick(data, data.VoucherType, data.VoucherNo)
            }>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.index}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.Date}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.InvoiceNo}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.ProductCode}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.ProductName}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.Barcode}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.Qty)}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.UnitPrice)}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.GrossAmount)}</VBTableCellBody>
              {/* {(GST && data.GSTAmount) && <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.GSTAmount)}</VBTableCellBody>} */}
              {VAT && <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.VATAmount)}</VBTableCellBody>}
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.DiscountAmount)}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.NetAmount)}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.Cost)}</VBTableCellBody>
            </TableRow>
            ))}
            <TableRow></TableRow>
          </TableBody>
          }
          <VBTableFooter >
            <TableRow>
              <VBTableCellFooter >{t('Total')}</VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data?.length -1]?.Qty ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data?.length -1]?.UnitPrice ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data?.length -1]?.GrossAmount ?? 0)}</VBTableCellFooter>
              {/* {(GST && data.GSTAmount) && <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data?.length -1]?.GSTAmount ?? 0)}</VBTableCellFooter>} */}
              {VAT && <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data?.length -1]?.VATAmount ?? 0)}</VBTableCellFooter>}
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data?.length -1]?.DiscountAmount ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data?.length -1]?.NetAmount ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data?.length -1]?.Cost ?? 0)}</VBTableCellFooter>
            </TableRow>
          </VBTableFooter>
        </VBTableContainer>
      </Paper>


      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={searchReport}
        setPage_no = {setPage_no}
        handleDefaultFilter={()=>handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            {/* <ListItem>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Filter Name
                </InputLabel>
                <VBInputField />
              </FormControl>
            </ListItem> */}
            {/* <ListItem> */}
              {/* <ViknAutoComplete
                value={filterOptions.date.value}
                options={[
                  { name: "Custom" },
                  { name: "Today" },
                  { name: "This Week" },
                  { name: "This Month" },
                  { name: "Last 90 Days" },
                  { name: "This Year" },
                  { name: "Last Year" },
                ]}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) => handleAutoComplete(e, newValue)}
              /> */}
            {/* </ListItem> */}

            <ListItem>
              <VBDatePiker
                handleAutoComplete={handleAutoComplete}
                dateValue={filterOptions.date.value}
                isDateRange={true}
                  fromDate={filterOptions.date.FromDate}
                  toDate={filterOptions.date.ToDate}
                  fromOnChange={(value) =>
                    setFilterOptions({
                      ...filterOptions,
                      date: {
                        ...filterOptions.date,
                        FromDate: value,
                        value:{ name: "Custom" },
                      },
                    })
                  }
                  toOnChange={(value) =>
                    setFilterOptions({
                      ...filterOptions,
                      date: {
                        ...filterOptions.date,
                        ToDate: value,
                        value:{ name: "Custom" },
                      },
                    })
                  }
                />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknRadioGroup 
                radios={filterOptions.ReportType.map((i) => ({
                  label: i.label,
                  value: i.name,
                }))}
                handleChange={handlechangeFilter}
                value={filterOptions.filterType}
                valueName={'filterType'}
              />
            </ListItem>
            <ListItem>
              {filterOptions.filterType === 'product' &&  
              <ViknSearchAutoComplete 
              freeSolo
              placeholder = {t("Product")}
              loading = {isLoadingProducts}
              options = {filterOptions.ProductList ? filterOptions.ProductList : []}
              getOptionLabel={(option) => option.ProductName || "" }
              name = "ProductObj"
              filterOptions={(options, { inputValue }) =>
                options.filter((option) =>
                  (option.ProductName && option.ProductName.toLowerCase().includes(inputValue.toLowerCase())) ||
                  (option.ProductCode && option.ProductCode.toLowerCase().includes(inputValue.toLowerCase()))
                )
              }
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.ProductCode}</Box>
                  <Box sx={{fontSize: "14px",}}>{option.ProductName}</Box>
                </Box>
              )}
              onInputChange={(event, value, reason) => { 
                if (reason === "input") {
                  handleSearchProduct(event,value);
                }
                if(reason === "clear"){
                  setFilterOptions({ ...filterOptions, ProductObj: {} });
                }
                console.log(reason,'dddddddddddddddddddddddddddd');
              }}
              onChange={(e, v) => handleAutoComplete(e, v, "ProductObj")}
              value={filterOptions.ProductObj}
              />
              }
              {filterOptions.filterType === 'productcategory' &&  
              <ViknAutoComplete 
                placeholder={t('Product Category')}
                options = {filterOptions.productCategoryList ? filterOptions.productCategoryList : []}
                getOptionLabel={(option) => option.ProductCategoryName || "" } 
                value = {filterOptions.productCategoryObj}  
                onChange={(e, v) => handleAutoComplete(e, v, "productCategoryObj")}
              />
              }
              {filterOptions.filterType === 'productgroup' &&  
              <ViknAutoComplete 
                placeholder={t('productgroup')}
                options = {filterOptions.ProductGroupList? filterOptions.ProductGroupList.filter((option) => option.GroupName !== '') : []}
                getOptionLabel={(option) => option.GroupName || ''}
                value = {filterOptions.ProductGroupObj}
                onChange = {(e,v) => handleAutoComplete(e,v,"ProductGroupObj")}
              />
              }
            </ListItem>
            <Divider />
            <ListItem>
                <ViknRadioGroup 
                  labelName = {t('Report Type')}
                  radios = {
                    filterOptions.filterList.map((i) => (
                      {label: i.label, value: i.name}
                    ))
                  }
                  handleChange = {handlechange}
                  value = {filterOptions.filterValue}
                  valueName = {"filterValue"}
                />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete 
                freeSolo
                placeholder = {t("User")}
                filterName = {t("User")}
                loading = {isLoadingUser}
                options = {filterOptions.userList ? filterOptions.userList : []}
                getOptionLabel={(option) => option.customer_name || "" }
                name = "UserObj"
                onInputChange={(event, value, reason) => { 
                  // if (reason === "input") {
                  //   handleSearchUser(event,value);
                  // }
                  if(reason === "clear"){
                    setFilterOptions({ ...filterOptions, UserObj: {} });
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "UserObj")}
                value={filterOptions.UserObj}
                
              />
            </ListItem>
            <Divider />
            
          { Is_Warehouse ?            
           <ListItem>
              <ViknAutoComplete 
                placeholder = {t('Warehouse')}
                label = {t("Warehouse")}
                options = {filterOptions.WarehouseList ? filterOptions.WarehouseList : []}
                getOptionLabel={(option) => option.WarehouseName || "" } 
                value = {filterOptions.WarehouseObj}  
                onChange={(e, v) => handleAutoComplete(e, v, "WarehouseObj")}
              />
            </ListItem> : null}
            <Divider />
            <ListItem>
              <VBInputField 
                label = {t('Barcode')}
                placeholder = {t("Barcode")}
                value = {filterOptions.Barcode}
                onChange={(e,name) => handlechangeFilter (e,'Barcode')}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete 
                freeSolo
                placeholder = {t("Ledgers")}
                filterName = {t("Ledgers")}
                loading = {isLoadingLedgers}
                options = {filterOptions?.LedgerList ? filterOptions.LedgerList : []}
                getOptionLabel={(option) => option?.LedgerName || "" }
                name = "LedgerObj"
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    (option.LedgerName && option.LedgerName.toLowerCase().includes(inputValue.toLowerCase())) ||
                    (option.LedgerCode && option.LedgerCode.toLowerCase().includes(inputValue.toLowerCase()))
                  )
                }
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.LedgerCode}</Box>
                    <Box sx={{fontSize: "14px",}}>{option.LedgerName}</Box>
                  </Box>
                )}
                onInputChange={(event, value, reason) => { 
                  if (reason === "input") {
                    handleSearchLedgers(event,value);
                  }
                  if(reason === "clear"){
                    setFilterOptions({ ...filterOptions, LedgerObj: {} });
                  }
                  console.log(reason,'dddddddddddddddddddddddddddd');
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "LedgerObj")}
                value={filterOptions.LedgerObj}
                
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete 
                freeSolo
                placeholder = {t("Product Code")}
                filterName = {t("Product Code")}
                loading = {isLoadingProductCode}
                options = {filterOptions.ProductCodeList ? filterOptions.ProductCodeList : []}
                getOptionLabel={(option) => option.ProductCode || "" }
                name = "ProductCodeObj"
                onInputChange={(event, value, reason) => { 
                  if (reason === "input") {
                    handleSearchProductCode(event,value);
                  }
                  if(reason === "clear"){
                    setFilterOptions({ ...filterOptions, ProductCodeObj: {} });
                  }
                  console.log(reason,'dddddddddddddddddddddddddddd');
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "ProductCodeObj")}
                value={filterOptions.ProductCodeObj}
                
              />
            </ListItem>
          </List>
        }
      />
    </>
  )
}

export default PurchaseRegisterReport

const FlexBox = styled(Box)(() => ({
  display: "flex",
  // justifyContent: "space-between",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap:"22px",
  },
}));
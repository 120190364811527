import {
  Box,
  Button,
  Drawer,
  IconButton,
  InputBase,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import ViknBlueButton from "../Utils/ViknBlueButton";
import { useTranslation } from "react-i18next";

const ViknDrawer = (props) => {
  const [t, i18n] = useTranslation("common");
  const handleFilterSearch = function (e) {
    // e pass chauna varuta anu. entalum avshum undangil component call chayda edtu call chaydal mati
    props?.onFilter(e)
    props?.handleClose(e)
    if(props?.setPage_no){
    props?.setPage_no(1)
    }
  }
  return (
    <Drawer anchor={"right"} open={props.open} onClose={props.handleClose}>
      <Box sx={{ width: 450 ,marginBottom:"50px",}} role="presentation">
        <Box sx={{ position: "relative" ,width: 450}}>
        <Box
          sx={{
            position: "fixed",
            backgroundColor: "white",
            zIndex: "5",
            borderRadius: "0px",
            width: 450
          }}
        >
          <Paper
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              p: "8px",
              pr: "0px",
              borderRadius: "0px",
            }}
          >
            <Typography>{t("Filters")}</Typography>
            <Box sx={{ alignItems: "center" }}>
              <ClearButton variant="text" onClick={props.handleDefaultFilter}>{t("Clear")}</ClearButton>
              <IconButton onClick={props.handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Paper>
          {/* <Divider /> */}
        </Box>

        {props.filterCompnt}

        <Paper
          sx={{
            position: "fixed",
            bottom: "0px",
            width: 450,
            p: "10px",
            borderRadius: "0px",
            boxShadow:
              "0px -2px 1px -1px rgba(0,0,0,0.2), 0px -1px 1px 0px rgba(0,0,0,-0.86), 0px -1px 3px 0px rgba(0,0,0,0.12)",
          }}
        >
          {/* <Divider /> */}
          <ViknBlueButton onClick={handleFilterSearch} name={t("Apply Filter")} />
        </Paper>
        </Box>
      </Box>
    </Drawer>
  );
};

export default ViknDrawer;

const ClearButton = styled(Button)(({ theme}) => ({
  fontSize: "12px",
  textTransform: "none",
  fontWeight: "normal",
  // color: theme.palette.mode === "light" ? "white" : "black",
}));

const AutoCompleteFilterInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 12,
    width: "100%",
    padding: "6px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // "&:focus": {
    //   // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   // borderColor: theme.palette.primary.main,
    //   border: "1px solid",
    // },
  },
}));

const FilterInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  width: "100%",
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 12,
    width: "100%",
    padding: "6px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    //   border: "1px solid",
    // },
  },
}));

import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState } from "react";
import VBInputField from "../../../../Components/Utils/VBInputField";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";

// Replace this with the Vikn theme fiele color
const VIKNBLUE = "#0A9EF3";

function ProductDetail({ dataDetail, setDataDetail }) {
  const [showImage, setShowImage] = useState([]);
  const [isHover, setIsHover] = useState(false);

  const handleImage = function (e) {
    let ing = e.target?.files;
    if (ing) {
      let newImages = [];
      // let payloadImage = [];
      for (const file of ing) {
        newImages = [URL.createObjectURL(file), ...newImages];
        // payloadImage = [file, ...payloadImage];
      }
      setShowImage([...showImage, ...newImages]);
    }
  };

  const handleAddImage = function () {
    let btn = document.getElementById("uploadPhoto");
    btn.click();
  };

  return (
    <>
      <Box sx={{ px: 4, pt: 4 }}>
        <Typography
          sx={{ color: "#001746", fontSize: "13px", fontWeight: "500", mb: 2 }}
        >
          Product Images
        </Typography>

        <Box sx={{ flexGrow: 1, width: "75%", mb: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Paper
                elevation={0}
                sx={{
                  border: 2,
                  borderColor: "#D1D1D1",
                  borderStyle: "dashed",
                  // border: "5px solid transparent",
                  // borderImage:
                  //   "repeating-linear-gradient(to right, black 0%, black 10%, transparent 10%, transparent 20%) 1",
                  borderRadius: "6px",
                  backgroundColor: "#FAFAFA",
                  p: 3,

                  display: "flex",
                  alignItems: "flex-start",
                  gap: 2,
                }}
              >
                <input
                  type="file"
                  id="uploadPhoto"
                  style={{ display: "none" }}
                  accept="image/*"
                  multiple={true}
                  onChange={handleImage}
                />
                <Button
                  variant="contained"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#F5F5F5",
                    border: 1,
                    borderColor: "#D1D1D1",
                    boxShadow: "none",
                    height: "148px",
                    minWidth: "140px",
                    color: "#525252",
                    fontSize: "10px",
                    ":hover": {
                      backgroundColor: "#F5F5F5",
                    },
                  }}
                  onClick={handleAddImage}
                >
                  {/* Click to upload */}
                  <img src="../images/addImage.png" alt="btn" />
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 2,
                    overflowX: "scroll",
                    "-ms-overflow-style": "none",
                    scrollbarWidth: "none",
                    "::-webkit-scrollbar": {
                      display: "none",
                    },
                    width: "100%",
                  }}
                >
                  {showImage.map((e, i) => (
                    <Badge
                      onMouseEnter={() => setIsHover(i)}
                      onMouseLeave={() => setIsHover(null)}
                      sx={{
                        "&:hover": {
                          "& .MuiBadge-badge": {
                            display: "block",
                          },
                        },

                        "& .MuiBadge-badge": {
                          display: "none",
                          top: "45%",
                          left: "50%",
                          transform: "translate(-35%, -35%)",
                          zIndex: 0,
                        },
                      }}
                      badgeContent={
                        <IconButton
                          sx={{ color: "#EEEEEE" }}
                          onClick={() =>
                            setShowImage((prev) =>
                              prev.filter((io) => io !== e)
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src={e}
                        sx={{
                          filter: isHover === i && "blur(1.5px)",
                          height: "148px",
                          width: "140px",
                          borderRadius: "3px",
                          // zIndex: 100
                        }}
                      />
                    </Badge>
                  ))}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={4} alignContent={"center"}>
              <Grid
                container
                rowSpacing={2}
                direction="row"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12}>
                  <VBInputField
                    label="Product Code"
                    type="text"
                    value={dataDetail.ProductCode}
                    onChange={(e) => {
                      setDataDetail({
                        ...dataDetail,
                        ProductCode: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <VBInputField
                    label="Display Name"
                    type="text"
                    value={dataDetail.DisplayName}
                    onChange={(e) => {
                      setDataDetail({
                        ...dataDetail,
                        DisplayName: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, width: "75%", mb: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {/* AutoComplete ⭐⭐⭐ */}
              {/* <VBInputField
                label="Brand"
                type="text"
                value={dataDetail.creditPeriod}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    creditPeriod: e.target.value,
                  });
                }}
              /> */}
              <ViknAutoComplete
                disablePortal
                label="Brand"
                id="custom-input-demo"
                value={dataDetail.brand}
                options={dataDetail.brandList}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => {
                  setDataDetail({
                    ...dataDetail,
                    brand: v,
                  });
                }}
                renderInput={(params) => (
                  <VBInputField type="text" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <ViknAutoComplete
                disablePortal
                label="Product Group"
                id="custom-input-demo"
                value={dataDetail.productGroup}
                options={dataDetail.priductGroupList}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => {
                  setDataDetail({
                    ...dataDetail,
                    productGroup: v,
                  });
                }}
                renderInput={(params) => (
                  <VBInputField label="Product Group" type="text" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <VBInputField
                label="Product Name"
                type="text"
                value={dataDetail.ProductName}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    ProductName: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <VBInputField
                label="Cost With Expanse"
                type="text"
                value={dataDetail.CostWithExpense}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    CostWithExpense: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <VBInputField
                label="Warranty"
                type="text"
                value={dataDetail.WarrantyType}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    WarrantyType: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <VBInputField
                label="Average Cost"
                type="number"
                value={dataDetail.AverageCost}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    AverageCost: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <VBInputField
                label="Description"
                type="text"
                row={4}
                value={dataDetail.Description}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    Description: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Box sx={{ m: 3 }} />
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="This Is Service"
                control={
                  <Checkbox
                    checked={dataDetail.is_Service}
                    onChange={(e) => {
                      setDataDetail({
                        ...dataDetail,
                        is_Service: !dataDetail.is_Service,
                      });
                    }}
                    size="small"
                    //  disabled={radio.disabled}
                  />
                }
              />

              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="Manual Product Codes"
                control={
                  <Checkbox
                    // checked={en.IsShipping}
                    onChange={(e) => {}}
                    size="small"
                    //  disabled={radio.disabled}
                  />
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Divider variant="fullWidth" sx={{ mb: 5 }} />

      <Box sx={{ px: 4 }}>
        <Typography
          sx={{ color: "#001746", fontSize: "13px", fontWeight: "500", mb: 5 }}
        >
          Pricing and Inventory Information
        </Typography>
        <Box sx={{ flexGrow: 1, width: "75%", mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <VBInputField
                label="Purchase Price"
                type="number"
                value={dataDetail.DefaultSalesPrice}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    DefaultSalesPrice: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <VBInputField
                label="Sales Price"
                type="number"
                value={dataDetail.MinimumSalesPrice}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    MinimumSalesPrice: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <VBInputField
                label="Minimum Price"
                type="number"
                value={dataDetail.MinimumSalesPrice}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    MinimumSalesPrice: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <VBInputField
                label="MRP"
                type="number"
                value={dataDetail.DefaultMRP}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    DefaultMRP: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {/* AutoComplete $$$$ */}
              <VBInputField
                label="VAT"
                type="text"
                // value={dataDetail.creditPeriod}
                // onChange={(e) => {
                //   setDataDetail({
                //     ...dataDetail,
                //     creditPeriod: e.target.value,
                //   });
                // }}
              />
            </Grid>
            <Grid item xs={4}>
              <VBInputField
                label="Exicse Tax"
                type="text"
                //   value={dataDetail.creditPeriod}
                //   onChange={(e) => {
                //     setDataDetail({
                //       ...dataDetail,
                //       creditPeriod: e.target.value,
                //     });
                //   }}
              />
            </Grid>

            <Grid item xs={4}>
              <ViknAutoComplete
                disablePortal
                label="Unit"
                id="custom-input-demo"
                value={dataDetail.unit}
                options={dataDetail.unitList}
                getOptionLabel={(option) => option.UnitName}
                onChange={(e, v) => {
                  setDataDetail({
                    ...dataDetail,
                    unit: v,
                  });
                }}
                renderInput={(params) => (
                  <VBInputField label="Product Group" type="text" {...params} />
                )}
                
              />
            </Grid>
            <Grid item xs={4}>
              <VBInputField
                label="Barcode"
                type="text"
                //   value={dataDetail.creditPeriod}
                //   onChange={(e) => {
                //     setDataDetail({
                //       ...dataDetail,
                //       creditPeriod: e.target.value,
                //     });
                //   }}
              />
            </Grid>
          </Grid>
        </Box>
        <Button variant="text" sx={{ color: VIKNBLUE }}>
          + Multi Barcode
        </Button>
      </Box>

      <Divider variant="fullWidth" sx={{ mb: 5 }} />

      <Box sx={{ px: 4 }}>
        <Typography
          sx={{ color: "#001746", fontSize: "16px", fontWeight: "500", mb: 2 }}
        >
          Stock Details
        </Typography>
        <Box sx={{ flexGrow: 1, width: "75%", mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <VBInputField
                label="Stock Minimum"
                type="text"
                //   value={dataDetail.creditPeriod}
                //   onChange={(e) => {
                //     setDataDetail({
                //       ...dataDetail,
                //       creditPeriod: e.target.value,
                //     });
                //   }}
              />
            </Grid>
            <Grid item xs={4}>
              <VBInputField
                label="Stock Maximum"
                type="text"
                //   value={dataDetail.creditPeriod}
                //   onChange={(e) => {
                //     setDataDetail({
                //       ...dataDetail,
                //       creditPeriod: e.target.value,
                //     });
                //   }}
              />
            </Grid>
            <Grid item xs={4}>
              <VBInputField
                label="Stock Order"
                type="text"
                //   value={dataDetail.creditPeriod}
                //   onChange={(e) => {
                //     setDataDetail({
                //       ...dataDetail,
                //       creditPeriod: e.target.value,
                //     });
                //   }}
              />
            </Grid>

            <Grid item xs={4}>
              <VBInputField
                label="Inventory Type"
                type="text"
                //   value={dataDetail.creditPeriod}
                //   onChange={(e) => {
                //     setDataDetail({
                //       ...dataDetail,
                //       creditPeriod: e.target.value,
                //     });
                //   }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1, p: 4, backgroundColor: "#FAFAFA" }}>
        <Grid container rowSpacing={2} sx={{ width: "85%" }}>
          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Weighing Scale"
              control={
                <Checkbox
                  // checked={en.IsShipping}
                  // onChange={(e) => {}}
                  size="small"
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Raw Material"
              control={
                <Checkbox
                  // checked={en.IsShipping}
                  // onChange={(e) => {}}
                  size="small"
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Finished Product"
              control={
                <Checkbox
                  // checked={en.IsShipping}
                  // onChange={(e) => {}}
                  size="small"
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Sales"
              control={
                <Checkbox
                  // checked={en.IsShipping}
                  // onChange={(e) => {}}
                  size="small"
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Purchase"
              control={
                <Checkbox
                  // checked={en.IsShipping}
                  // onChange={(e) => {}}
                  size="small"
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Favourite"
              control={
                <Checkbox
                  // checked={en.IsShipping}
                  // onChange={(e) => {}}
                  size="small"
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Active"
              control={
                <Checkbox
                  // checked={en.IsShipping}
                  // onChange={(e) => {}}
                  size="small"
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Inclusive"
              control={
                <Checkbox
                  // checked={en.IsShipping}
                  // onChange={(e) => {}}
                  size="small"
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="BOM"
              control={
                <Checkbox
                  // checked={en.IsShipping}
                  // onChange={(e) => {}}
                  size="small"
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ProductDetail;

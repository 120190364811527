import axios from "axios";
// import { openSnackbar } from "../../features/SnackBarslice";

export const sales_details_api = async function (body) {

    try {
      const { data } = await axios.post("api/v11/sales/get-details/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
};
  

export const create_sales_api = async function (body) {
  
    try {
      const { data } = await axios.post("api/v11/sales/sales-invoice/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
};


export const list_sales_api = async function (body) {
  
  try {
    const { data } = await axios.get("api/v11/sales/sales-invoice/", {
      params:body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};


export const view_sales_api = async function (body) {
  
  try {
    const { data } = await axios.get("api/v11/sales/sales-invoice/", {
      params:body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};

export const get_sales_details_api = async function (body) {
  
  try {
    const { data } = await axios.get("api/v11/sales/get-sales-invoice-details/", {
      params:body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};


export const edit_sales_api = async function (body) {
  
  try {
    const { data } = await axios.put("api/v11/sales/sales-invoice/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};



export const delete_sales_api = async function (body) {
  
  try {
    const { data } = await axios.delete("api/v11/sales/sales-invoice/", {
      data:body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};

export const delete_contacts_api = async function (body) {
  
  try {
    const { data } = await axios.post("api/v10/parties/delete/party/1/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};
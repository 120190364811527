import Bank from "../../Pages/Accounts/Bank";

const AccountsRoutes = [
  {
    path: "banks",
    element: <Bank/>,
    isAuth: true,
  },
];

export default AccountsRoutes;

import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import FilterLable from "../../../../Components/Utils/FilterLable";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import { CallExpenseSummaryReport } from "../../../../Api/Reports/FinanceApi";
import moment from "moment";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { date_range } from "../../../../Function/comonFunction";
import { CallBranchsList } from "../../../../Api/Reports/CommonReportApi";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import { useSelector } from "react-redux";
import { formatNumber } from "../../../../generalFunction";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { useTranslation } from "react-i18next";
import { UserRolePermission } from "../../../../Function/Editions";
// import { ViknMultiAutoComplete } from "../../../../Components/Utils/ViknMultiAutoComplete";

const ExpensesSummary = () => {
  let Export_view = UserRolePermission("Expense Summary", "print_permission");
  // const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  // const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  // const { user_id, } = useSelector((state) => state.user);
  const [t] = useTranslation("common");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [page_no, setPage_no] = useState(1);
  const [reportData, setReportData] = useState([]);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment(),
    },
    branch: {
      name: "Branch",
      isActive: false,
      value: [],
      branchList: [],
    },

  });

  const [filterData, setFilterData] = useState({
    branchList: [],
  })

  const [filterValues, setFilterValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  //Handle functions
  const closeFilter = () => setOpenFilter(false);

  const handleAutoComplete = function (e, value) {
    console.log(value);
    if (value) {
      let date = date_range(value.name);
      console.log(date);
      setFilterOptions({
        ...filterOptions,
        date: {
          ...filterOptions.date,
          isActive: true,
          value: value,
          ToDate: date.todate,
          FromDate: date.fromdate,
        },
      });
    } else {
      let date = date_range();
      setFilterOptions({
        ...filterOptions,
        date: {
          ...filterOptions.date,
          isActive: false,
          value: { name: "Today" },
          ToDate: date.todate,
          FromDate: date.fromdate,
        },
      });
    }
  };

  const filterReport = function () {
    fetchExpensesReport()
  };

  const handleDefaultFilter = function() {
    setFilterOptions({
      ...filterOptions,
      date: {
        ...filterOptions.date,
        value: { name: "Today" },
        FromDate: moment(),
        ToDate: moment(),
      },
      branch: {
        ...filterOptions.branch,
        value: [],
      },
    })
  }

  // API Fetch data
  const fetchExpensesReport = async function () {
    setIsLoading(true)
    console.log(filterOptions.branch.value, "Branch List is her");
    const payload = {
      FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
      BranchIDs: [0],
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      CreatedUserID: user_id
    }
    if (filterOptions.branch.value.length !== 0) payload.BranchIDs = [0, ...filterOptions.branch.value.map(e => e.BranchID)]
    const data = await CallExpenseSummaryReport(payload);
    console.log(data);
    if (data?.StatusCode === 6000) {
      setReportData(data.data);
    }
    setIsLoading(false)
  };

  const filterApis = async function () {
    const data = await CallBranchsList({
      items_per_page: 15,
      page_no: 1,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      CreatedUserID: user_id
    });
    console.log(data.data);
    if (data?.StatusCode === 6000) {
      // setFilterOptions({
      //   ...filterOptions,
      //   branch: {
      //     ...filterOptions.branch,
      //     branchList: data.data,
      //   },
      // });
      setFilterData({
        ...filterData,
        branchList: data.data,
      });
    }
  };

  const getDataForPage = () => {
    const startIndex = (page_no - 1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData.slice(0, reportData.length -1)
    return alldata.slice(startIndex, endIndex) || [];
  };

  useEffect(() => {
    fetchExpensesReport();
    filterApis();
  }, []);

  console.log(filterOptions);

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t("Expenses Summary")}
            </Typography>
            {/* <SearchReport /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <Box sx={{ display: "flex", justifyContent: "space-around"}}>
            {/* <ExportBtn /> */}
            {Export_view ? 
            <ReportDownload
            download_type={"PDF"}
            invoice_type={"expense_summary"}
            ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
            FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
            UserID={user_id}
            RouteLedgers={filterOptions.branch.value.length !== 0 ? [0, ...filterOptions.branch.value.map(e => e.BranchID)]: [0]}
          />
          : null}
            {/* <PrintBtn /> */}
            <Pagenation 
              totalItem={reportData.length}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </Box>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // // height: "54px",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
                  <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
          {t("Applied Filters: ")}
        </div>
          {/* {filterOptions.date.isActive && (
            <FilterLable
              name={"Date"}
              value={filterOptions.date.value.name}
              handleDelete={() => handleDelete("date")}
            />
          )} */}
          <FilterLable
            name={"Date Range"}
            value={
              filterOptions.date.value.name === "Custom"
                ? filterOptions.date.FromDate.format("DD/MM/YYYY") +
                  " - " +
                  filterOptions.date.ToDate.format("DD/MM/YYYY")
                : filterOptions.date.value.name
            }
          />
          {filterOptions.branch.value.length !== 0 && <FilterLable
              name={"Branchs"}
              value={filterOptions.branch.value.map((e, i)=> 
                <Chip 
                key={i} 
                label={e.BranchName} 
                variant="outlined" 
                sx={{ p: "1px", fontSize: "10px", height: "22px", mr: "4px", 
                  ".MuiChip-label": {
                    px: '8px',
                  },
                  ".MuiSvgIcon-root": {
                    fontSize: "15px",
                    color: "#ff00006b",
                  }
                }}
                onDelete={() =>  
                  setFilterOptions({
                    ...filterOptions,
                    branch: {...filterOptions.branch, value: filterOptions.branch.value.filter(l => l.BranchID !== e.BranchID)}
                  })
                }
                />
              )}
              handleDelete={() => setFilterOptions({...filterOptions, branch: {...filterOptions.branch, value: []}})}
            />
          }
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("Serial No")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Item")} </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>{t("Amount")}</VBTableCellHeader>
          </VBTableHeader>
          {isLoading ? <VBSkeletonLoader /> : [<TableBody>
            {getDataForPage().map((data, i) => (
              <TableRow>
                <VBTableCellBody>{i + 1}</VBTableCellBody>
                <VBTableCellBody>{data.LedgerName}</VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(data.Amount)}</VBTableCellBody>
              </TableRow>
            ))}
            <TableRow></TableRow>
          </TableBody>,
          <VBTableFooter>
            <TableRow>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter>{reportData[reportData.length - 1]?.LedgerName}</VBTableCellFooter>
              <VBTableCellFooter sx={{ textAlign: "right"}}>
                {formatNumber(reportData[reportData.length - 1]?.Amount)}
              </VBTableCellFooter>
            </TableRow>
          </VBTableFooter>]}
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={filterReport}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            {/* <ListItem>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Filter Name
                </InputLabel>
                <VBInputField />
              </FormControl>
            </ListItem> */}
            

            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.date.FromDate}
                toDate={filterOptions.date.ToDate}
                fromOnChange={(e) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {...filterOptions.date, FromDate: e, value: { name: "Custom" }}
                  })
                }
                toOnChange={(e) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {...filterOptions.date, ToDate: e, value: { name: "Custom" }}
                  })
                }
                // fromReadOnly={filterOptions.date.value.name !== "Custom"}
                // toReadOnly={filterOptions.date.value.name !== "Custom"}
              />
            </ListItem>
            <ListItem>
              <ViknAutoComplete
                value={filterOptions.date.value}
                options={[
                  { name: "Custom" },
                  { name: "Today" },
                  { name: "This Week" },
                  { name: "This Month" },
                  { name: "Last Month" },
                  { name: "Last 90 Days" },
                  { name: "This Year" },
                  { name: "Last Year" },
                ]}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                onChange={(e, newValue) => handleAutoComplete(e, newValue)}
              />
            </ListItem>
            <Divider /> 
            <ListItem>
              <ViknAutoComplete
                multiple
                placeholder={"Branch"}
                value={filterOptions.branch.value}
                options={filterData.branchList}
                getOptionLabel={(option) => option?.BranchName}
                onChange={(e, v) => setFilterOptions({...filterOptions, branch: {...filterOptions.branch, value: v}})}
              />
              {/* <ViknMultiAutoComplete
                // options={filterOptions.branch?.branchList}
                // getOptionLabel={(option) => option.BranchName}
                options={["soman", "Gopam", "Doisa"]}
              /> */}
            </ListItem>

            {/* <Divider /> */}
          </List>
        }
      />
    </>
  );
};

export default ExpensesSummary;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // width: "28%",
}));

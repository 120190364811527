import axios from "axios";
import { URL } from "../generalVeriable";
import { getCookie } from "../generalFunction";

let cookies = getCookie("VBID")
axios.defaults.baseURL = URL
axios.defaults.headers.post["Content-Type"] = "application/json";
if (cookies) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + cookies;
}

    
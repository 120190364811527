import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { callUserRoll } from "../Api/generalSettings/generalSettings";
import { get_user_role_data } from "../Components/Utils/UserRoleJsons";

const initialState = localStorage.getItem("userRoleSettings")
  ? JSON.parse(localStorage.getItem("userRoleSettings"))
  : get_user_role_data;

// export const callUserRollTunk = createAsyncThunk(
//     "fetchuserRollSettings",
//     async () => {
//       const res = await callUserRoll();
//       return res.data;
//     }
//   );
  const userRoleSettingsSlice = createSlice({
    name: "userRoleSettings",
    initialState,
    reducers: {
      userRoleSettingsSuccess: (state, action) => {
        console.log(action.payload);
        action.payload.map((i, index) => {
          state[index] = { ...i };
        });
  
        localStorage.setItem("userRoleSettings", JSON.stringify(action.payload));
      },
      userRoleSettingsLogoutSuccess: (state, action) => {
        state = initialState;
        localStorage.removeItem("userRoleSettings");
      },
    },

    // extraReducers: (builder) => {
    //   builder.addCase(callUserRollTunk.pending, (state, action) => {
    //     // console.log(action, "Pending Satate");
    //     // state = {loading: true}
    //   });
    //   builder.addCase(callUserRollTunk.fulfilled, (state, action) => {
    //     console.log(action, "Fillfilled Satate");
    //     // Object.keys(action.payload).forEach((key) => {
    //     // });
    //     state = {...action.payload};
    //     localStorage.setItem("userRoleSettings", JSON.stringify(action.payload));
    //   });
    //   builder.addCase(callUserRollTunk.rejected, (state, action) => {
    //     console.log(action, "Reject Satate");
    //     state = get_user_role_data
    //   });
    // },
  });
  
export const { userRoleSettingsSuccess, userRoleSettingsLogoutSuccess } = userRoleSettingsSlice.actions;
export default userRoleSettingsSlice.reducer;

import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  Paper,
  Typography,
  styled,
  Chip,
  Divider,
  TableRow,
  TableBody,
  Button,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CreateNewButton } from "../../../Components/Utils/StyledButtons";
import { DeleteButton } from "../../../Components/Utils/StyledButtons";
import { useSelector,useDispatch } from "react-redux";
import { json, useNavigate } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { blue } from "@mui/material/colors";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "../../../Components/ReportTable/ReportTable";

import {
  stock_order_api,
  stock_order_delete_api,
} from "../../../Api/Inventory/StockOrderApi";
import { formatNumber } from "../../../generalFunction";
import { MenuOptions } from "../../../Components/CommonComponents";
import Pagenation from "../../../Components/Utils/Pagenation";
import ConfirmBox from "../../../Components/Utils/ConfirmBox";
import { openSnackbar } from "../../../features/SnackBarslice";



const StockOrderList = () => {
  const [t, i18n] = useTranslation("common");

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { user_id } = useSelector((state) => state.user);
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { PriceDecimalPoint, QtyDecimalPoint } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );

  const PriceRounding = Number(PriceDecimalPoint);
  const QtyRounding = Number(QtyDecimalPoint);
  const [state, setState] = useState({
    dataList: [],
    data_count: null,
    search: null,
    selectedItems: [],
    isSelectedAll: false,
  });

  const OptionList = [
    { name: "Edit", permission: true },
    { name: "Delete", permission: true },
  ];
  // const OptionOnClick = (e, name, uniq_id) => {
  //   if (e) {
  //     let permission = OptionList.find((i) => i.name === name).permission;
  //     if (name === "Edit" && permission) {
  //       navigate("/create-stock-order", {
  //         state: { is_edit: true, uniq_id: uniq_id },
  //       });
  //     }
  //   }
  // };
  const OptionOnClick =  (e,name,uniq_id) => {

    if (e){
        let permission = OptionList.find((i)=> i.name === name)?.permission
        if (name === "Edit" && permission) {
            navigate("/create-stock-order",{state: { is_edit: true, uniq_id: uniq_id }})
        }
        else if(name === "Delete" && permission){
          setState({...state,selectedItems:[uniq_id]})
          setConfirmBox(true)
        }
    }
  } 
  

  const [IsConfirmBox, setConfirmBox] = useState(false);

  const noOfItems = appSetting.itemPerPage;

  const [page_no, setPage_no] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    let payload = {
      CreatedUserID: user_id,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: PriceRounding,
      QtyRounding: QtyRounding,
      search: state.search,
      page_no: page_no,
      VoucherType: "STO",
      items_per_page: noOfItems,
    };
    let responseData = await stock_order_api({ ...payload });

    if (responseData.StatusCode === 6000) {
      let data_count = responseData.count;
      setState({
        ...state,
        dataList: responseData.data,
        isSelectedAll: false,
        selectedItems: [],
        data_count: data_count,
      });
    }
  };

  const deleteData = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      id_list: state.selectedItems,
    };

    const response = await stock_order_delete_api({...payload});    
    if (response.StatusCode === 6000) {
      fetchData();
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: response?.message ?? "Something went wrong!",
          severity: "error",
        })
      );
    }
  };

  const confirmDelete = () => {
    setConfirmBox(true);
  };

  const selectItems = (type, id) => {
    let data = [...state.selectedItems];
    let isSelectedAll = state.isSelectedAll;
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (state.isSelectedAll) {
        data = [];
      } else {
        state.dataList.map((i) => {
          data.push(i.id);
        });
      }
    } else {
      let index = data.findIndex((item) => item === id);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
    }

    setState({ ...state, selectedItems: data, isSelectedAll: isSelectedAll });
  };

  useEffect(() => {
    fetchData();
  }, [state.search, page_no]);


  return (
    <>
        <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                mr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              {t("Stock Order List")}
            </Typography>
          </FlexBox>

          <FlexBox className="right">
            <DeleteButton onClick={confirmDelete} t={t} />

            <CreateNewButton
              onClick={() => {
                navigate("/create-stock-order");
              }}
              t={t}
            />

            <Pagenation
              totalItem={state.data_count}
              page_no={page_no}
              setPage_no={setPage_no}
            />
          </FlexBox>
        </Box>

        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader
              sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
            >
              <Checkbox
                checked={state.isSelectedAll} // The checked prop determines whether this checkbox is checked.
                onChange={() => {
                  selectItems("all");
                }}
                sx={{
                  padding: "2px",
                  margin: 0,
                  color: blue[400],
                  "&.Mui-checked": {
                    color: blue[400],
                  },
                  "& .MuiSvgIcon-root": { fontSize: "20px" },
                }}
              />
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "left" }}>
              {t("Serial No")}
            </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher No")}</VBTableCellHeader>
            <VBTableCellHeader>{t("User")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Date")}</VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Total Qty")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Grand Total")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Is Transferred	")}{" "}
            </VBTableCellHeader>

            <VBTableCellHeader sx={{ textAlign: "right" }}>
              <BorderColorIcon
                color="#0A9EF3"
                sx={{ color: "#0A9EF3", width: "20px" }}
              />
            </VBTableCellHeader>
          </VBTableHeader>

          {loading ? (
            <VBSkeletonLoader />
          ) : (
            <TableBody>
              {state.dataList.map((data, i) => (
                <TableRow
                // onClick={() => navigate(filterOptions.filterType==="return" || data.GrandTotal < 0 ? VIKNBOOKS_FRONT_URL+"/dashboard/sales-return-preview" :  VIKNBOOKS_FRONT_URL+"/dashboard/sale-invoice-single", { state: { invoice_type: "sales_invoice", id: data.id }, unq_id: data.id })}
                //   onClick={()=>{handleTableRowClick(data)}}
                >
                  <VBTableCellBody
                    sx={{
                      cursor: "pointer",
                      textAlign: "left",
                      padding: "0px",
                      paddingLeft: "5px",
                    }}
                  >
                    <Checkbox
                      checked={
                        state.selectedItems.includes(data.id) ? true : false
                      } // The checked prop determines whether this checkbox is checked.
                      onChange={() => {
                        selectItems("not", data.id);
                      }}
                      sx={{
                        padding: "2px",
                        margin: 0,
                        color: blue[400],
                        "&.Mui-checked": {
                          color: blue[400],
                        },
                        "& .MuiSvgIcon-root": { fontSize: "20px" },
                      }}
                    />
                  </VBTableCellBody>
                  <VBTableCellBody
                    sx={{ cursor: "pointer", textAlign: "left" }}
                  >
                    {(page_no - 1) * appSetting.itemPerPage + i + 1}
                  </VBTableCellBody>
                  <VBTableCellBody sx={{ cursor: "pointer" }}>
                    {data.VoucherNo}
                  </VBTableCellBody>
                  <VBTableCellBody sx={{ cursor: "pointer" }}>
                    {data.Username}
                  </VBTableCellBody>
                  <VBTableCellBody
                    className="truncated-text "
                    sx={{ cursor: "pointer" }}
                  >
                    {data.Date}
                  </VBTableCellBody>
                  <VBTableCellBody
                    sx={{ cursor: "pointer", textAlign: "right" }}
                  >
                    {formatNumber(data.TotalQty)}
                  </VBTableCellBody>
                  <VBTableCellBody
                    sx={{ cursor: "pointer", textAlign: "right" }}
                  >
                    {formatNumber(data.GrandTotal)}
                  </VBTableCellBody>
                  <VBTableCellBody
                    sx={{ cursor: "pointer", textAlign: "right" }}
                  >
                    <Checkbox
                      checked={data.IsTaken}
                      sx={{
                        padding: "2px",
                        margin: 0,
                        color: blue[400],
                        "&.Mui-checked": {
                          color: blue[400],
                        },
                        "& .MuiSvgIcon-root": { fontSize: "20px" },
                      }}
                    />{" "}
                  </VBTableCellBody>
                  <VBTableCellBody
                    sx={{
                      cursor: "pointer",
                      color:
                        data.billStatus === "paid"
                          ? "green"
                          : data.billStatus === "partially paid"
                          ? "blue"
                          : data.billStatus === "unpaid"
                          ? "red"
                          : null,
                    }}
                  >
                    {data.billStatus}
                  </VBTableCellBody>
                  <VBTableCellBody
                    sx={{ cursor: "pointer", textAlign: "right" }}
                  >
                    <MenuOptions
                      OptionList={OptionList}
                      OptionOnClick={OptionOnClick}
                      uniq_id={data.id}
                    />
                  </VBTableCellBody>
                </TableRow>
              ))}
              <VBTableRowNull
                length={
                  state.data_count ? state.data_count : state.dataList.length
                }
              />
            </TableBody>
          )}
        </VBTableContainer>
        <ConfirmBox
        heading={"Confirm to delete?"}
        message={"Once you delete, you will not get it back!"}
        open={IsConfirmBox}
        setOpen={setConfirmBox}
        confirmFunction={deleteData}
      />
      </Paper>
    </>
  );
};

export default StockOrderList;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));

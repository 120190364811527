import { Button, styled } from "@mui/material";
import React from "react";

const ViknBlueButton = (props) => {
  return <StyledButton {...props} >{props.name || "Blue Button"} </StyledButton>;
};

export default ViknBlueButton;

const StyledButton = styled(Button)(({ theme }) => ({
  // Custom styles for the button
  width: "100%",
  color: "white", // Change this color to them color provider ⚠️⚠️⚠️:warning:
  fontSize: "12px",
  textAlign:"center",
  borderRadius: "6px",
  textTransform: "none",
  justifyContent: "center", // Align text to the left
  backgroundColor: "#0A9EF3", // Change this color to them color provider
  "&:hover": {
    backgroundColor: "#0A9EF3",
  },

  "& .MuiButton-endIcon": {
    marginLeft: theme.spacing(1), // Space between text and icon
    fontSize: "12px", // Font size of the endIcon
  },
}));

import React from 'react'
import { VBSkeletonLoader, VBTableCellBody, VBTableCellHeader, VBTableContainer, VBTableHeader } from '../../../../../Components/ReportTable/ReportTable'
import { TableBody, TableRow } from '@mui/material'
import { formatNumber } from '../../../../../generalFunction'

const openingStock = [
    {name: "Stock in hand", subTotal: "1000.00"}
]

const purchaseAccount = [
    {name: "Purchase", subTotal: "1500.00"},
    {name: "Less Purchase Returns Outwards", subTotal: "(400.00)"},
]

const directExpenses = [
    {name: "Shipping Charges", subTotal: "150.00"},
    {name: "Loading And Unloading", subTotal: "100.00"},
]

const saleAccount = [
    {name: "Sales", subTotal: "5000.00"},
    {name: "Less Sales Return Inwards", subTotal: "(500.00)"},
]

const closingStock = [
    {name: "Stock In Hands", subTotal: "850.00"},
]
const Horizontal = ({isLoading, mode}) => {
  return (
    <VBTableContainer>
    <VBTableHeader>
      <VBTableCellHeader >PARTICULAR </VBTableCellHeader>
      <VBTableCellHeader sx={{ textAlign: "right", }}>SUBTOTAL </VBTableCellHeader>
      <VBTableCellHeader sx={{ textAlign: "right", }}>TOTAL </VBTableCellHeader>

      <VBTableCellHeader >PARTICULAR </VBTableCellHeader>
      <VBTableCellHeader sx={{ textAlign: "right", }}>SUBTOTAL </VBTableCellHeader>
      <VBTableCellHeader sx={{ textAlign: "right", }}>TOTAL </VBTableCellHeader>
    </VBTableHeader>
    {isLoading ? <VBSkeletonLoader /> : <TableBody>
      <TableRow>
        <VBTableCellBody sx={{ fontWeight: "bold"}} > Opening Stock</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> 1000.00</VBTableCellBody>

        <VBTableCellBody sx={{ fontWeight: "bold"}} > Sales Account</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> 1500.00</VBTableCellBody>
      </TableRow>
        { mode === "Details" && (
            openingStock.map((e, i) => (<TableRow>
              <VBTableCellBody > {e.name}</VBTableCellBody>
              <VBTableCellBody sx={{ textAlign: "right", }}>{e.subTotal} </VBTableCellBody>
              <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> </VBTableCellBody>
        
              <VBTableCellBody sx={{ fontWeight: "bold"}} > Sales Account</VBTableCellBody>
              <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
              <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> 1500.00</VBTableCellBody>
            </TableRow>))
        ) }

      <TableRow>
        <VBTableCellBody sx={{ fontWeight: "bold"}} > Purchase Accounts</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> 1000.00</VBTableCellBody>

        <VBTableCellBody sx={{ fontWeight: "bold"}} > Closing Stock</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> 850.00</VBTableCellBody>
      </TableRow>

      <TableRow>
        <VBTableCellBody sx={{ fontWeight: "bold"}} > Direct Expenses</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> 250.00</VBTableCellBody>

        <VBTableCellBody sx={{ fontWeight: "bold"}} > </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> </VBTableCellBody>
      </TableRow>

      <TableRow>
        <VBTableCellBody > </VBTableCellBody>
        <VBTableCellBody > </VBTableCellBody>
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6" }}> </VBTableCellBody>

        <VBTableCellBody > </VBTableCellBody>
        <VBTableCellBody > </VBTableCellBody>
        <VBTableCellBody > </VBTableCellBody>
      </TableRow>

      <TableRow>
        <VBTableCellBody sx={{ fontWeight: "bold"}} > Gross Profit c/o</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> 3000.00</VBTableCellBody>

        <VBTableCellBody sx={{ fontWeight: "bold"}} > </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> </VBTableCellBody>
      </TableRow>

      <TableRow sx={{ "& td": { borderBottom: "1px solid black"} }}>
        <VBTableCellBody sx={{ fontWeight: "bold"}} > </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> 5350.00</VBTableCellBody>

        <VBTableCellBody sx={{ fontWeight: "bold"}} > </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> </VBTableCellBody>
      </TableRow>

      <TableRow >
        <VBTableCellBody sx={{ fontWeight: "bold"}} > </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> </VBTableCellBody>

        <VBTableCellBody sx={{ fontWeight: "bold"}} > Gross Profit b/f</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> </VBTableCellBody>
      </TableRow>

      <TableRow>
        <VBTableCellBody > </VBTableCellBody>
        <VBTableCellBody > </VBTableCellBody>
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6" }} > </VBTableCellBody>

        <VBTableCellBody > </VBTableCellBody>
        <VBTableCellBody > </VBTableCellBody>
        <VBTableCellBody > </VBTableCellBody>
      </TableRow>

      <TableRow >
        <VBTableCellBody sx={{ fontWeight: "bold"}} > Indirect Expenses</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> 1880.00</VBTableCellBody>

        <VBTableCellBody sx={{ fontWeight: "bold"}} > Indirect Incomes</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> 560.00</VBTableCellBody>
      </TableRow>

      <TableRow >
        <VBTableCellBody sx={{ fontWeight: "bold", color: "green"}} > Net Profit</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", color: "green" }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6", color: "green" }}> 1680.00.00</VBTableCellBody>

        <VBTableCellBody sx={{ fontWeight: "bold", color: "green"}} > </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", color: "green" }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6", color: "green" }}> </VBTableCellBody>
      </TableRow>

      <TableRow >
        <VBTableCellBody sx={{ fontWeight: "bold"}} > Total</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> 3560.00</VBTableCellBody>

        <VBTableCellBody sx={{ fontWeight: "bold"}} > Total</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", }}> </VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> 3560.00</VBTableCellBody>
      </TableRow>

      {/* {props.data.map((e, i) => (
        <TableRow>
          <VBTableCellBody sx={{ color: e.expence.name === "Total" ? 'red': "auto", fontWeight: e.expence.name === "Total"? "600" : "400" }} > {e.expence.name}</VBTableCellBody>
          <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6", textAlign: "right" }}> {formatNumber(e.expence.balance)}</VBTableCellBody>
          <VBTableCellBody sx={{ color: e.expence.name === "Total" ? 'red': "auto", fontWeight: e.expence.name === "Total"? "600" : "400" }}> {e.income.name}</VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(e.income.balance)}</VBTableCellBody>
        </TableRow>
      ))} */}
      <TableRow></TableRow>
    </TableBody>}
  </VBTableContainer>
  )
}

export default Horizontal
import React from 'react'
import { VBSkeletonLoader, VBTableCellBody, VBTableCellHeader, VBTableContainer, VBTableHeader } from '../../../../../Components/ReportTable/ReportTable'
import { TableBody, TableRow } from '@mui/material'
import { formatNumber } from '../../../../../generalFunction'
import { useTranslation } from 'react-i18next'

const ConsolidateTable = ({reportData, tableType, isLoading}) => {
  const [t] = useTranslation("common");
  return (
 tableType === "Horizontal" ?     
  <VBTableContainer>
    <VBTableHeader>
      <VBTableCellHeader sx={{ width: "50%"}} colSpan={2}>{t("Liabilities")} </VBTableCellHeader>
      <VBTableCellHeader sx={{ width: "50%"}} colSpan={2}>{t("Assets")} </VBTableCellHeader>
    </VBTableHeader>
    {isLoading ? <VBSkeletonLoader /> : <TableBody>
      <TableRow>

        <VBTableCellBody sx={{ fontWeight: "bold"}} > {t("Group Name")}</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> {t("Amount")}</VBTableCellBody>

        <VBTableCellBody sx={{ fontWeight: "bold"}}> {t("Group Name")}</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right"}}> {t("Amount")}</VBTableCellBody>
       
      </TableRow>
      {reportData.map((e, i) => ( 
        <TableRow>
        <VBTableCellBody sx={{ color: e?.liabilities?.group_name === "Total" ? 'red': "auto", fontWeight: e?.liabilities?.group_name === "Total"? "600" : "400" }} > {e?.liabilities?.group_name}</VBTableCellBody>
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6", textAlign: "right" }}> {formatNumber(e?.liabilities?.balance)}</VBTableCellBody>
        <VBTableCellBody sx={{ color: e.assets.group_name === "Total" ? 'red': "auto", fontWeight: e?.assets?.group_name === "Total"? "600" : "400" }}> {e.assets.group_name}</VBTableCellBody>
        <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(e.assets.balance)}</VBTableCellBody>
        </TableRow>
      ))}

      <TableRow></TableRow>
    </TableBody>}
  </VBTableContainer>
  : 
  <VBTableContainer>
    <VBTableHeader>
      <VBTableCellHeader sx={{ width: "50%", borderRight: "1px solid #e6e6e6"}} >{t("Accounts")} </VBTableCellHeader>
      <VBTableCellHeader sx={{ width: "50%", textAlign: "right"}} >{t("Amount")} </VBTableCellHeader>
    </VBTableHeader>
    {isLoading ? <VBSkeletonLoader /> : <TableBody>
      {reportData.map((e, i) => ( 
        <TableRow>
          {/* <VBTableCellBody sx={{ fontWeight: "light"}}> Group Name</VBTableCellBody> */}
        {/* <VBTableCellBody sx={{ color: e.liabilities.group_name === "Total" ? 'red': "auto", fontWeight: e.liabilities.group_name === "Total"? "600" : "400" }} > {e.liabilities.group_name}</VBTableCellBody>
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6", textAlign: "right" }}> {formatNumber(e.liabilities.balance)}</VBTableCellBody> */}
        <VBTableCellBody sx={{ color: e.assets.group_name === "Total" ? 'red': "auto", fontWeight: e.assets.group_name === "Total"? "600" : "400", borderRight: "1px solid #e6e6e6" }}> {e.assets.group_name}</VBTableCellBody>
        <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(e.assets.balance)}</VBTableCellBody>
        </TableRow>
      ))}

      <TableRow></TableRow>
    </TableBody>}
  </VBTableContainer>
  )
}

export default ConsolidateTable
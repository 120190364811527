import moment from "moment";
import React, { useEffect, useState } from "react";
import { date_range } from "../../../../Function/comonFunction";
import {
  Box,
  Divider,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import SearchReport from "../../../../Components/Utils/SearchReport";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import PrintBtn from "../../../../Components/Utils/PrintBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import FilterLable from "../../../../Components/Utils/FilterLable";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
  VBTableRowNull,
} from "../../../../Components/ReportTable/ReportTable";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { CallSalesIntegratedReport, callInteratedReport } from "../../../../Api/Reports/SalesApi";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import { CallUsers } from "../../../../Api/Reports/CommonReportApi";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../../generalFunction";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { UserRolePermission } from "../../../../Function/Editions";

const SalesIntegratedReport = () => {
  let Export_view = UserRolePermission("Sales Integrated Report", "print_permission");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [reportData, setReportData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [t, i18n] = useTranslation("common");
  const [page_no, setPage_no] = useState(1);
  const [filterOptions, setFilterOptions] = useState({
    userList : [],
    UserObj : {customer_name : "" },

    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment()
    },
    branch: {
      name: "Branch",
      isActive: false,
      value: "",
    },
  });

  //Handle functions

  const closeFilter = () => setOpenFilter(false);

  const handleAutoComplete = function (e, value, name) {
    if (value) {
      if (name === "date") {
        console.log(e, value);
        if (value.name === "Custom") {
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: true,
              value: value,
            },
          });
        } else {
          let date = date_range(value.name);
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: false,
              value: value,
              ToDate: date.todate,
              FromDate: date.fromdate,
            },
          });
        }
      } else {
        setFilterOptions({ ...filterOptions, [name]: value });
      }
    }
  };


  const handleFilterList = () => {
    let list = [];
    let filterdata = filterOptions
    console.log(filterdata,'>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    if (filterdata.date.FromDate &&filterdata.date.ToDate ) {
      let date = String(filterOptions.date.FromDate.format("DD-MM-YYYY") + " to " + filterOptions.date.ToDate.format("DD-MM-YYYY"))
      list.push({ name: t("Date"), value: date });
    }
    if (filterdata && filterdata.UserObj && filterdata.UserObj.customer_name !== undefined && filterdata.UserObj.customer_name !== ''){
      list.push({
        name: t('User'),
        value: filterdata.UserObj.customer_name,
        handleDelete: () => handleDeletefilter('UserObj')
      })
    }
    return list
  }
  console.log(filterOptions,'fiiiiiiiiiiiiiiiiis');
  // API Fetch data
  const fetchReport = async function () {
    setLoading(true);
    const data = await CallSalesIntegratedReport({
      CompanyID : CompanyID,
      BranchID : BranchID,
      CreatedUserID: user_id,
      PriceRounding : Number(PriceDecimalPoint),
      FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
      UserID : filterOptions.UserObj?.UserID?filterOptions.UserObj.UserID : 0,

    });
    console.log(data);
    if (data?.StatusCode === 6000) {
      setReportData(data);
      // setPage()
    }
    setLoading(false);
  };
  const searchReport = function () {
    fetchReport();
  };
  const handleDefaultFilter = async () => {
    setFilterOptions({
      ...filterOptions,
      UserObj:{customer_name:''},
      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Today" },
        FromDate: moment(),
        ToDate: moment(),
      },
    })
  }

  const handleDeletefilter = (name, index) => {
    console.log(name, index);
    if (name === "routeListValue") {
      let routeListValue = [...filterOptions.routeListValue];
      routeListValue = routeListValue.filter((item, idx) => idx !== index);
      setFilterOptions((prevState) => ({
        ...prevState,
        routeListValue: routeListValue,
      }));
    } else {
      setFilterOptions((prevState) => ({ ...prevState, [name]: {} }));
    }
    // handlefilterList()
  };

  const filterApis = async function () {
    setIsLoadingUser(true);
    const userResponse = await CallUsers({
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      items_per_page: 10,
      page_no: page_no,
      search: "",
      PartyType: "1",
    });
    let userList = [];
    if (userResponse?.StatusCode === 6000) {
      userList = userResponse.data;
    }
    setFilterOptions({
      ...filterOptions,
      userList,
    });
    setIsLoadingUser(false);
  }

  const getDataForPage = () => {
    const startIndex = (page_no -1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData?.new_data?.slice(0,reportData?.new_data?.length -1)
    return alldata?.slice(startIndex, endIndex) || [];
  }

  useEffect(() => {
    filterApis();
  }, []);

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className='left'>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t('Sales Integrated Report')}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className='right'>
            {Export_view ? 
            <ReportDownload
              download_type={"PDF"}
              invoice_type={"sales_integrated_report"}
              ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
              FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
              UserID={filterOptions.UserObj?.UserID?filterOptions.UserObj.UserID : 0}
            />
            : null}
            {/* <PrintBtn /> */}
            <Pagenation 
              totalItem={reportData?.new_data?.length}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
        <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
          {t("Applied Filters: ")}
        </div>
        {handleFilterList(filterOptions).map((val, i) => (
          <FilterLable
            name={val.name}
            value={val.value}
            handleDelete={val.handleDelete?val.handleDelete:null}
          />
        ))}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t('Serial No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Date')}</VBTableCellHeader>
            {filterOptions.UserObj?.UserID ?
            <VBTableCellHeader>{t('Users')}</VBTableCellHeader> : null}
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Cash Sales')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Credit Sales')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Bank Sales')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Net Sales')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Cash Return')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Debit Return')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Bank Return')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Net Return')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Actual Sales')}</VBTableCellHeader>
          </VBTableHeader>
          {Loading ? 
          <VBSkeletonLoader /> : 
          <TableBody>
            {getDataForPage().map((data, i) => (
              <TableRow>
                <VBTableCellBody>{data.index}</VBTableCellBody>
                <VBTableCellBody>{data.Date}</VBTableCellBody>
                {filterOptions.UserObj?.UserID ?
                <VBTableCellBody>{data.User}</VBTableCellBody> : null}
                <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.CashSales)}</VBTableCellBody>
                <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.CreditSales)}</VBTableCellBody>
                <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.BankSales)}</VBTableCellBody>
                <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.NetSales)}</VBTableCellBody>
                <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.CashReturn)}</VBTableCellBody>
                <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.DebitReturn)}</VBTableCellBody>
                <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.BankReturn)}</VBTableCellBody>
                <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.NetReturn)}</VBTableCellBody>
                <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.ActualSales)}</VBTableCellBody>
              </TableRow>
            ))}
          {/* <VBTableRowNull length = {reportData?.new_data?.length}/> */}
          <TableRow></TableRow>
          </TableBody>
          }
          <VBTableFooter>
            <TableRow>
            <VBTableCellFooter >{t("Total")}</VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              {filterOptions.UserObj?.UserID ?
              <VBTableCellFooter ></VBTableCellFooter> : null}
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].CashSales) ?? 0)} </VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].CreditSales) ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].BankSales) ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].NetSales) ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].CashReturn) ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].DebitReturn) ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].BankReturn) ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].NetReturn) ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].ActualSales) ?? 0)}</VBTableCellFooter>
            </TableRow>
          </VBTableFooter>
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={searchReport}
        handleDefaultFilter={()=>handleDefaultFilter()}
        setPage_no = {setPage_no}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            {/* <ListItem>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Filter Name
                </InputLabel>
                <VBInputField />
              </FormControl>
            </ListItem> */}


            <ListItem>
              <VBDatePiker 
                handleAutoComplete={handleAutoComplete}
                dateValue={filterOptions.date.value}
                isDateRange={true}
                  fromDate={filterOptions.date.FromDate}
                  toDate={filterOptions.date.ToDate}
                  fromOnChange={(value) => 
                    setFilterOptions({
                      ...filterOptions,
                      date:{
                        ...filterOptions.date,
                        FromDate: value,
                        value:{ name: "Custom" },
                      },
                    })
                  }
                  toOnChange={(value) => 
                    setFilterOptions({
                      ...filterOptions,
                      date:{
                        ...filterOptions.date,
                        ToDate: value,
                        value:{ name: "Custom" },
                      }
                    })
                  }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete 
                freeSolo
                placeholder = {t("User")}
                filterName = {t("User")}
                loading = {isLoadingUser}
                options = {filterOptions.userList ? filterOptions.userList : []}
                getOptionLabel={(option) => option.customer_name || "" }
                name = "UserObj"
                onInputChange={(event, value, reason) => { 
                  // if (reason === "input") {
                  //   handleSearchUser(event,value);
                  // }
                  if(reason === "clear"){
                    setFilterOptions({ ...filterOptions, UserObj: {} });
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "UserObj")}
                value={filterOptions.UserObj}
                
              />
            </ListItem>
            <Divider />
          </List>
        }
      />
    </>
  );
};

export default SalesIntegratedReport;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap:"22px",
  },
}));

import { Paper } from '@mui/material'
import PropTypes from 'prop-types';
import LoaderLite from './LoaderLite';

function PaperLoading({ isLoading, children }) {
    return (
        <Paper sx={{ height: "100%", width: "100%" }}>
            {isLoading ? <LoaderLite fullscreen={true} /> : children }
        </Paper>
    )
}

export default PaperLoading

PaperLoading.propTypes = {
    isLoading: PropTypes.bool,
    children: PropTypes.element
}

PaperLoading.defaultProps = {
    isLoading: false
};
import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("branchSettings")
    ? JSON.parse(localStorage.getItem("branchSettings"))
    : {
        EnableBranch: false,
        suppliersForAllBranches: false,
        customersForAllBranches: false,
        productsForAllBranches: false,
        
    };

const branchSettingsSlice = createSlice({
    name: "branchSettings",
    initialState,
    reducers: {
        branchSettingsSuccess: (state, action) => {
            Object.keys(action.payload).forEach((key) => {
                state[key] = action.payload[key];
              });
            // state=action.payload
            localStorage.setItem("branchSettings", JSON.stringify(action.payload));
        },
        branchSettingsLogoutSuccess: (state, action) => {
            state.EnableBranch = null;
            state.suppliersForAllBranches = null;
            state.customersForAllBranches = null;
            state.productsForAllBranches = null;

            localStorage.removeItem("branchSettings");
        },
    },
});

export const { branchSettingsSuccess, branchSettingsLogoutSuccess } =
    branchSettingsSlice.actions;

// export const selectFinancialYearFromDate = (state) =>
//   state.branchSettings.customerForAllBranches;
// export const selectFinancialYeasrToDate = (state) =>
//   state.branchSettings.suppliersForAllBranches;

export default branchSettingsSlice.reducer;

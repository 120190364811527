import React, { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  List,
  ListItem,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { VBSkeletonLoader, VBTableCellBody, VBTableCellFooter, VBTableCellHeader, VBTableContainer, VBTableFooter, VBTableHeader, VBTableRowNull } from '../../../../Components/ReportTable/ReportTable';
import FilterLable from '../../../../Components/Utils/FilterLable';
import SearchReport from '../../../../Components/Utils/SearchReport';
import FilterBtn from '../../../../Components/Utils/FilterBtn';
import ExportBtn from '../../../../Components/Utils/ExportBtn';
import PrintBtn from '../../../../Components/Utils/PrintBtn';
import Pagenation from '../../../../Components/Utils/Pagenation';
import { CallExpenseSummaryReport } from '../../../../Api/Reports/FinanceApi';
import moment from 'moment';
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from '../../../../Components/Utils/ViknAutoComplete';
import { date_range } from '../../../../Function/comonFunction';
import { CallBranchsList } from '../../../../Api/Reports/CommonReportApi';
import VBDatePiker from '../../../../Components/Utils/VBDatePiker';
import ViknCheckboxGroup from '../../../../Components/Utils/ViknCheckboxGroup';
import { callPurchaseOrderReport } from '../../../../Api/Reports/PurchaseApi';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openSnackbar } from '../../../../features/SnackBarslice';
import { formatNumber } from '../../../../generalFunction';
import ReportDownload from '../../../../Components/Utils/ReportDownload';
import { VIKNBOOKS_FRONT_URL } from '../../../../generalVeriable';
import { UserRolePermission } from '../../../../Function/Editions';

const PurchaseOrderReport = () => {
  let Export_view = UserRolePermission("Purchase Order Report", "print_permission");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const dispatch = useDispatch()
  const [reportData, setReportData] = useState([])
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [page_no, setPage_no] = useState(1);
  const [t, i18n] = useTranslation("common");
  const [filterValues, setFilterValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment(),
    },
    branch: {
      name: "Branch",
      isActive: false,
      value: ""
    },
    reportType : {
      invoice: { isActive:true, name: "Invoice"},
      cancelled: { isActive:false, name: "Cancelled"},
      pending: { isActive:false, name: "Pending"},

    }


  })

  //Handle functions

  const closeFilter = () => setOpenFilter(false);

  const handleDefaultFilter = async () => {
    setFilterOptions({
      ...filterOptions,
      reportType : {
        invoice: { isActive:true, name: "Invoice"},
        cancelled: { isActive:false, name: "Cancelled"},
        pending: { isActive:false, name: "Pending"},
      },
      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Today" },
        FromDate: moment(),
        ToDate: moment(),
      },
    })
    setFilterValues([
      {
        name :"Report Type",
        value :"Invoice",
        isActive : filterOptions.reportType.invoice.isActive,
      }
    ])
  }

  const handleAutoComplete = function (e, value, name) {
    if (value) {
      if (name === "date") {
        console.log(e, value);
        if (value.name === "Custom") {
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: true,
              value: value,
            },
          });
        } else {
          let date = date_range(value.name);
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: false,
              value: value,
              ToDate: date.todate,
              FromDate: date.fromdate,
            },
          });
        }
      } else {
        setFilterOptions({ ...filterOptions, [name]: value });
      }
    }
  };


  const deleteFilter = function (value,index) {
    console.log(value,"seeettbvaaaalue");
    if (value.name === "Report Type") {
      let newValue = filterValues.filter((item,i) => i !== index);
      setFilterValues(newValue)
      handleReportType(value.value)
    }
  }
  const handleReportType = function (key){
    switch (key) {
      case "Invoice":
        setFilterOptions({
          ...filterOptions,
          reportType : {
            ...filterOptions.reportType,
            invoice: {
              ...filterOptions.reportType.invoice,
              isActive:!filterOptions.reportType.invoice.isActive,
            }
          },
        });
        !filterOptions.reportType.invoice.isActive && 
          setFilterValues([
            ...filterValues,
            {
              name :"Report Type",
              value :"Invoice",
              isActive :!filterOptions.reportType.invoice.isActive,
            }
          ])
        filterOptions.reportType.invoice.isActive &&
          setFilterValues(
            filterValues.filter((item,i) => item.value !== "Invoice")
          );
        break;
      case "Cancelled":
        setFilterOptions({
          ...filterOptions,
          reportType: {
            ...filterOptions.reportType,
            cancelled: {
              ...filterOptions.reportType.cancelled,
              isActive: !filterOptions.reportType.cancelled.isActive,
            },
          },
        });
        !filterOptions.reportType.cancelled.isActive &&
          setFilterValues([
            ...filterValues,
            {
              name: "Report Type",
              value: "Cancelled",
              isActive: !filterOptions.reportType.cancelled.isActive,
            },
          ]);
        filterOptions.reportType.cancelled.isActive &&
          setFilterValues(
            filterValues.filter((item, i) => item.value !== "Cancelled")
          );
        break;
      case "Pending":
        setFilterOptions({
          ...filterOptions,
          reportType: {
            ...filterOptions.reportType,
            pending: {
              ...filterOptions.reportType.pending,
              isActive: !filterOptions.reportType.pending.isActive,
            },
          },
        });
        !filterOptions.reportType.pending.isActive &&
          setFilterValues([
            ...filterValues,
            {
              name: "Report Type",
              value: "Pending",
              isActive: !filterOptions.reportType.invoice.isActive,
            },
          ]);
        filterOptions.reportType.pending.isActive &&
          setFilterValues(
            filterValues.filter((item, i) => item.value !== "Pending")
          );
        break;
      default:
        break;

    }
  }
  // API Fetch data
  let reportTyoe = [];
  if (filterOptions.reportType.invoice.isActive) {
    reportTyoe.push("I");
  }
  if (filterOptions.reportType.cancelled.isActive) {
    reportTyoe.push("C");
  }
  if (filterOptions.reportType.pending.isActive) {
    reportTyoe.push("N");
  }
  const fetchReport = async function () {
    setLoading(true);
    const data = await callPurchaseOrderReport({
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
      ReportTypes: reportTyoe,
      page_no: page_no,
    },dispatch);
    console.log(data);
    if (data?.StatusCode === 6000) {
      setReportData(data.data);
    } else if (data?.StatusCode === 6001){
      dispatch(
        openSnackbar({
          open : true,
          message : data.message,
          severity : 'warning',
        })
      )
      setReportData(data.data);
    } else if (data?.StatusCode === 6002){
      setReportData();
      dispatch(
        openSnackbar({
          open : true,
          message : data.message,
          severity : 'error',
        })
      )
    }
    setLoading(false)
  };

  const filterApis = async function () {
    // const data = await callPurchaseOrderReport()
  }
  
  const getDataForPage = () => {
    const startIndex = (page_no - 1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData?.slice(0,reportData?.length -1)
    return alldata?.slice(startIndex, endIndex) || [];
  };

  useEffect(() => {
    filterApis();
    setFilterValues([
      ...filterValues,
      {
        name :"Report Type",
        value :"Invoice",
        isActive : filterOptions.reportType.invoice.isActive,
      }
    ])
  }, [])
  const handleTableRowClick = (data) => {
    const destinationURL = `${VIKNBOOKS_FRONT_URL}dashboard/purchase-order-single?id=${data.id}`;
    window.open(destinationURL, '_blank');
  };
  console.log(reportData,'reeepeepp');
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left" >
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t("Purchase Order Report")}
            </Typography>
            {/* <SearchReport /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className="right">
            {Export_view ? 
            <ReportDownload
              download_type={"PDF"}
              invoice_type={"purchase_order_report"}
              FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
              ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
              filterValue={reportTyoe}
            />
            :null}
            {/* <PrintBtn /> */}
            <Pagenation 
              totalItem={reportData?.length-1}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
            {t("Applied Filters: ")}
          </div>
          {filterOptions.date?<FilterLable name = {"Date"} value = {filterOptions.date.FromDate.format("DD/MM/YYYY") + " - " + filterOptions.date.ToDate.format("DD/MM/YYYY")} />:null}
          {filterValues.map((vlaue, i) => (
            <FilterLable
              name={vlaue.name}
              value={vlaue.value}
              handleDelete={() => deleteFilter(vlaue, i)}
            />
          ))}
          
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t('Serial No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Voucher No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Voucher Date')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Ledger Name')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Supplier Name')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Net Amount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Total Tax')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Grand Total')}</VBTableCellHeader>
          </VBTableHeader>
          {loading ? 
            <VBSkeletonLoader/>  :
          <TableBody>
            {getDataForPage().map((data,i) => (
            <TableRow
            onClick={() =>
              handleTableRowClick(data, data.VoucherType, data.VoucherNo)
            }
            >
              <VBTableCellBody sx={{cursor:"pointer"}}>{(page_no - 1)* appSetting.itemPerPage + i + 1}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.VoucherNo}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.Date}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.LedgerName}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.LedgerName}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.NetTotal)}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.TotalTax)}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.GrandTotal)}</VBTableCellBody>
            </TableRow>
            ))}
          <TableBody></TableBody>
          </TableBody>
          }
          <VBTableFooter >
            <TableRow>
              <VBTableCellFooter >{t('Total')}</VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter align="right">{formatNumber(reportData?.[reportData.length -1]?.NetTotal ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter align="right">{formatNumber(reportData?.[reportData.length-1]?.TotalTax ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter align="right">{formatNumber(reportData?.[reportData.length -1]?.GrandTotal ?? 0)}</VBTableCellFooter>
            </TableRow>
          </VBTableFooter>
        </VBTableContainer>
      </Paper>


      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={() => fetchReport()}
        setPage_no = {setPage_no}
        handleDefaultFilter={()=>handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            {/* <ListItem>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Filter Name
                </InputLabel>
                <VBInputField />
              </FormControl>
            </ListItem> */}

            <ListItem>
              <VBDatePiker
                handleAutoComplete={handleAutoComplete}
                dateValue={filterOptions.date.value}
                isDateRange={true}
                fromDate = {filterOptions.date.FromDate}
                toDate = {filterOptions.date.ToDate}
                fromOnChange = {(value) => 
                  setFilterOptions({
                    ...filterOptions,
                    date : {
                      ...filterOptions.date,
                      FromDate:value,
                      value:{ name: "Custom" },
                    }
                  })
                }
                toOnChange = {(value) => 
                  setFilterOptions ({
                    ...filterOptions,
                    date : {
                      ...filterOptions.date,
                      ToDate : value,
                      value:{ name: "Custom" },
                    }

                  })
                }

              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknCheckboxGroup 
                radios = {[
                  {
                    label : t("invoiced"),
                    checked : filterOptions.reportType.invoice.isActive,
                    onChange : () => handleReportType("Invoice"),
                  },
                  {
                    label: t("Cancelled"),
                    checked: filterOptions.reportType.cancelled.isActive,
                    onChange: () => handleReportType("Cancelled"),
                  },
                  {
                    label: t("Pending"),
                    checked: filterOptions.reportType.pending.isActive,
                    onChange: () => handleReportType("Pending"),
                  },
                ]}
              />
            </ListItem>
            <Divider />
          </List>
        }
      />
    </>
  )
}

export default PurchaseOrderReport

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap:"22px",
  },
}));
import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import VBInputField from "../../../../Components/Utils/VBInputField";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { CallRoutes } from "../../../../Api/Reports/CommonReportApi";
import { useSelector } from "react-redux";
import { price_category } from "../../../../Api/CommonApi/CommonApis";



const Transactions = ({ uploadDetail, setUploadDetail ,gstTreatmentList,vatTreatmentList,
  dataLists,setDataLists,
}) => {
  const { CompanyID, BranchID, CountryCode } = useSelector((state) => state.companyDetails);
  const {
    PriceDecimalPoint,
    VAT,
    GST,
    Tax1,
    Tax2,
    Tax3,
    EnableCreditPeriod,
    EnableCreditLimit,
    EnableRoute,
    PriceCategory,
    EnableVbassist,
    UpdationDelayCount,
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id } = useSelector((state) => state.user);


  // const [labels, setLabels] = useState({
  //   idLabel: "",
  //   codeLabel: ""
  // })


  // State for Route and Category
  const [state, setState] = useState({
    Route: null,
    Category: null,
  });

  const callDatas = async function () {
    const routRes = await CallRoutes({
      BranchID: BranchID,
      CompanyID: CompanyID,
      CreatedUserID: user_id,
      PartyType: "customer",
    });

    const resCategory = await price_category({
      BranchID: BranchID,
      CompanyID: CompanyID,
      CreatedUserID: user_id,
    });

    let routlist,
      categorylist = [];

    if (routRes.StatusCode === 6000) {
      routlist = routRes.data;
    }
    if (resCategory.StatusCode === 6000) {
      categorylist = resCategory.data;
    }

    setDataLists({
      routList: routlist,
      categoryList: categorylist,
    });
  };

  useEffect(() => {
    callDatas();
  }, []);

  // useEffect(() => {
  //   if (CountryCode === "INR") {
  //     setLabels({
  //       idLabel: "PAN No",
  //       codeLabel: "IFSC Code"
  //     })
  //   } else if (CountryCode === "UAE") {
  //     setLabels({
  //       ...labels,
  //       idLabel: "PAN No",
  //     })
  //   } else if (CountryCode === "SAR") {
  //     setLabels({
  //       ...labels,
  //       idLabel: "Iqama No",
  //     })
  //   } else {
  //     setLabels({
  //       idLabel: "ID No",
  //       codeLabel: "IBAN"
  //     })
  //   }
  // }, [CountryCode])
  
  const fetchData = async () => {
    let routes = null;
    let  treatment = null;
    let priceCategory = null;

    // Check if routList has items to avoid undefined errors
    if (dataLists.routList && dataLists.routList.length > 0) {
      routes = dataLists.routList[0]; // Get the first item from the list
    } else {
      console.log("routList is empty or undefined");
    }

    // Find the item in categoryList with PriceCategoryID = 1
    priceCategory = dataLists.categoryList.find((i) => i.PriceCategoryID === 1);
    treatment = 


    setUploadDetail((prev) => ({
      ...prev,
      priceCategory,
      routes,
    }));

  };

  // Call fetchData when dataLists changes
  useEffect(() => {
    fetchData();
  }, [dataLists])

console.log(state.Category,"===========>>");
  return (
    <>
      <Typography sx={{ fontSize: "22px", fontWeight: "600", mb: 1 }}>
        Transactions Details
      </Typography>
      <Divider sx={{ mb: 3 }} />

      <form id="FormTwoCos" >
      <Box sx={{ flexGrow: 1, minWidth: 700, width: "50%" }}>
        <Grid container spacing={{ xs: 2 }} sx={{ mb: 10 }}>
          {EnableCreditPeriod && (
            <Grid item xs={6}>
              <VBInputField
                label="Credit Period"
                type="number"
                value={uploadDetail.creditPeriod}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    creditPeriod: e.target.value,
                  });
                }}
              />
            </Grid>
          )}
          {EnableCreditLimit && (
            <Grid item xs={6}>
              <VBInputField
                label="Credit Limit:"
                type="number"
                value={uploadDetail.creditLimit}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    creditLimit: e.target.value,
                  });
                }}
              />
            </Grid>
          )}
          {PriceCategory && (
            <Grid item xs={6}>
              <ViknAutoComplete
                label="Price Category"
                placeholder={"  "}
                options={dataLists.categoryList}
                getOptionLabel={(e) => e.PriceCategoryName}
                value={uploadDetail.priceCategory}
                onChange={(e, n) => {
                  setUploadDetail({
                    ...uploadDetail,
                    priceCategory: n,
                  });
                }}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <VBInputField
              label={"ID No"}
              value={uploadDetail.PanNumber}
              onChange={(e) => {
                setUploadDetail({
                  ...uploadDetail,
                  PanNumber: e.target.value,
                });
              }}
            />
          </Grid>
          {EnableRoute && (
            <Grid item xs={6}>
              <ViknAutoComplete
                label="Routes"
                placeholder={"  "}
                options={dataLists.routList}
                getOptionLabel={(e) => e.RouteName}
                value={uploadDetail.routes}
                onChange={(e, n) => {
                  setUploadDetail({
                    ...uploadDetail,
                    routes: n,
                  });
                }}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <VBInputField
              label="Registration"
              value={uploadDetail.CRNo}
              onChange={(e) => {
                setUploadDetail({
                  ...uploadDetail,
                  CRNo: e.target.value,
                });
              }}
            />
          </Grid>
          {(VAT || GST) && (
            <Grid item xs={6}>
              <ViknAutoComplete
                label={GST ? "Tax Treatment" : "VAT Treatment"}
                placeholder={" "}
                required={true}
                options={GST ? gstTreatmentList : vatTreatmentList}
                getOptionLabel={(e) => e.name}
                value={uploadDetail.treatment}
                onChange={(e, n) => {
                  setUploadDetail({
                    ...uploadDetail,
                    [GST ? "GST_Treatment" : "VAT_Treatment"]: n?.value,treatment: n,
                  });
                }}
              />
            </Grid>
          )}
          {VAT && uploadDetail.treatment?.value === "0" && (
            <Grid item xs={6}>
              <VBInputField
                label="VAT No:"
                required
                value={uploadDetail.VATNumber}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    VATNumber: e.target.value,
                  });
                }}
              />
            </Grid>
          )}
          {GST && !["2", "3", "4"].includes(uploadDetail.treatment?.value) && (
            <Grid item xs={6}>
              <VBInputField
                label="GST No:"
                required
                value={uploadDetail.GSTNumber}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    GSTNumber: e.target.value,
                  });
                }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      </form>
    </>
  );
};

export default Transactions;

import "./sales_style.css";

import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  List,
  ListItem,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  ClearButton,
  CreateButton,
  ImportButton,
  ListButton,
  UpdateButton,
  SaveButton,
  LoadingButton,
  AddToItemsButton,
} from "../../Components/Utils/StyledButtons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CustomVoucher,
  InvoiceAutoComplete,
  InvoiceBarCodeTextBox,
  InvoiceDateBox,
  InvoiceLedgerSearch,
  InvoiceTextBox,
  InvoiceVoucherNoTextBox,
  SelectShippingAddress,
} from "../../Components/CommonComponents";
import MyGrid from "./Details";
import moment from "moment";
import NotesTextArea from "../../Components/Utils/TextArea";
import { sales_details_api, create_sales_api,view_sales_api,get_sales_details_api, edit_sales_api } from "../../Api/Sales/SalesApis";
import { openSnackbar } from "../../features/SnackBarslice";
import { get_formset_values_api, get_pricelist_list_new_api, get_product_by_barcode_api, get_product_history_api, get_single_pricelist_new_api, get_stock_of_products_api, get_VoucherNo, ledger_details_api, ledger_list_api, place_of_supply_api, product_search_api, product_search_new_api } from "../../Api/CommonApi/CommonApis";
import { SalesInvoiceCalc, SalesLineCalc,Sales_Validation } from "./functions";
import SummaryDetails from "./SummaryDetails";
import { use } from "i18next";
import { Details, NoEncryption, Warehouse } from "@mui/icons-material";
import { AddressModal, BillingAddressModal, HistoryModal, ShippingAddressModal } from "../../Components/CommonForms";
import { create_user_address, list_user_address } from "../../Api/User/User";
import LoaderLite from "../../Components/Utils/LoaderLite";
import { CallEmployees } from "../../Api/Reports/CommonReportApi";

import {debounce} from "../../Function/comonFunction" 
import { get_e_invoice_submit_api } from "../../Api/EInvoice/EInvoiceApis";

const SalesInvoice = () => {
  const [t, i18n] = useTranslation("common");
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  // Determine is_edit and uniq_id from URL params or location state
  const is_edit = location.state?.is_edit ?? params.get('is_edit');
  const uniq_id = location.state?.uniq_id ?? params.get('unq_id');
  
  const [apiResult,setApiResult] = useState(null)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [IsButtonClick, setIsButtonClick] = useState(false);
  
  const [IsShipping, setIsShipping] = useState(false);
  const [IsBilling, setIsBilling] = useState(false);
  
  const [IsSalesHistory, setIsSalesHistory] = useState(false);
  const [IsPurchaseHistory, setIsPurchaseHistory] = useState(false);
  
  
  const [is_manual_roundoff,setIs_manual_roundoff] = useState(false)

  const [progress, setProgress] = useState(0);
  
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { 
    PriceDecimalPoint,
     EnableBranch,
     EnableProductBatchWise,
     GST,
     VAT,
     CurrencySymbol,
     EnableBillwise,
     EnableWarehouse,
     EnableSalesManInSales,
     PriceCategory,
     EnableDeliveryDateInSales,
     EnableShippingCharge,
     
     PurchasePriceInSales,
     show_brand_InsearchSale,
     show_stock_InsearchSale,
     show_purchasePrice_InsearchSale,
     show_productcode_InsearchSale,
     EnableEinvoiceKSA
     } = useSelector((state) => state.generalSettingsSclice.generalSettings);
     
     //USER Role
     let user_role_settings = useSelector((state) => state.userRollSettings);
     let sales_permission = user_role_settings.filter((i) => i.name === "Sales Invoice");
     let sales_discount_perm = sales_permission[0].discount;
     let sales_discount_limit = sales_permission[0].discount_limit? sales_permission[0].discount_limit: 100;
     let sales_save = sales_permission[0].save_permission;
     let sales_purchase_price_perm = sales_permission[0].purchase_price;
     
     let sales_history_permission = user_role_settings.filter((i) => i.name === "Sales History");
     let purchase_history_permission = user_role_settings.filter((i) => i.name === "Purchase History");

     let sales_history_view_permission = sales_history_permission[0].view_permission
     let purchase_history_view_permission = purchase_history_permission[0].view_permission
     
     
     
     let GeneralSettingsData = {
       PriceDecimalPoint,
       EnableBranch,
       EnableProductBatchWise,
       GST,
       VAT,
       CurrencySymbol,
       EnableBillwise,
       EnableWarehouse,
       EnableSalesManInSales,
       PriceCategory,
       EnableDeliveryDateInSales,
       EnableShippingCharge,
       
       PurchasePriceInSales,
       show_brand_InsearchSale,
       show_stock_InsearchSale,
       show_purchasePrice_InsearchSale,
       show_productcode_InsearchSale,
       //userorrll
       sales_discount_limit,
       sales_discount_perm,
       sales_save,
       sales_purchase_price_perm
     }
        

     console.log(GeneralSettingsData.EnableProductBatchWise,"printing GS Enable batch wise=================>>");
     
  const { user_id } = useSelector((state) => state.user);
  const {Country,State} = useSelector((state) => state.companyDetails)
  
  
  let today = new Date();
  today.setDate(today.getDate());
  let DefaultDate = today.toISOString().substr(0, 10);
  
  const TreatmentData = [
    { value: "0", name: "Registered Business - Regular",is_gst:true },
    { value: "1", name: "Registered Business - Composition",is_gst:true },
    { value: "2", name: "Consumer",is_gst:true,default:true },
    { value: "4", name: "Overseas",is_gst:true },
    { value: "5", name: "Special Economic Zone",is_gst:true },
    { value: "6", name: "Deemed Export",is_gst:true },
    { value: "0", name: "Business to Business(B2B)",is_vat:true },
    { value: "1", name: "Business to Customer(B2C)",is_vat:true,default:true},
    ]
    
    const GST_TreatmentData = TreatmentData.filter((i)=> i.is_gst === true)
    const VAT_TreatmentData = TreatmentData.filter((i)=> i.is_vat === true)
    
  const [dataState,setDataState] = useState({
    Seperator: null,
    Suffix: null,
    SuffixSeparator: null,
    PreFix: null,
    InvoiceNo: null,
    VoucherNo: "",
    deletedData: [],
    AccountLedgerData : [],
    CashList : [],
    BankList : [],
    CountryData : [],
    EmployeeData : [],
    PriceCategoryData : [],
    TaxCategoryData : [],
    UserTableData : [],
    WarehouseData : [],
    PlaceOfSupplyData:[],
    ProductList: [],
    ProductCodeList: [],
    SalesHistory:[],
    PurchaseHistory:[]
  })
  
  const [defaultValue,setDefaultValue] = useState({
      AccountLedger:null,
      CashAccount:null,
      BankAccount:null,
      PriceCategory:null,
      Warehouse:null,
      Treatment:null,
      PlaceOfSupply:null,
      ShippingTax:null
  })

  
  //when you adding any field here add in clearData function tooo if needed
  const [state, setState] = useState({
    AccountLedger:null,
    CashAccount:null,
    BankAccount:null,
    Employee :null,
    PriceCategory:null,
    Warehouse:null,
    Treatment:null,
    PlaceOfSupply:null,
    Date:DefaultDate,
    RefNo:"",    
    ShippingCharge:0,
    ShippingTax:null,
    shipping_tax_amount:0,
    BillDiscPercent:0,
    BillDiscAmt:0,
    CashAmount:0,
    BankAmount:0,
    Notes:"",
    ShippingAddress:null,
    BillingAddress:null,
    RoundOff:"0",
    DueDate:DefaultDate,
    DeliveryDate:DefaultDate,
    //Total
    TotalQty:0,
    TotalGrossAmt:0,
    TotalDiscount:0,
    TotalTax:0,
    NetTotal:0,
    TotalTaxableAmount:0,
    TaxTaxableAmount:0,
    NonTaxTaxableAmount:0,
    GrandTotal:0

  });
  
  
  const[ShippingAddress,setShippingAddress] = useState({
    Attention : "", //Name
    PostalCode : "", //Pincode
    Address1 : "", //Building
    Address2 : "", //Area
    City : "",
    District : "",
    country : null,
    state : null,
    Mobile : "",
    CountryData:[],
    StateData:[],
    ShippingAddressList:[],
  }) 
  
  
  const[BillingAddress,setBillingAddress] = useState({
    Attention : "", //Name
    PostalCode : "", //Pincode
    Address1 : "", //Building
    Address2 : "", //Area
    City : "",
    District : "",
    country : null,
    state : null,
    Mobile : "",
    CountryData:[],
    StateData:[],
    BillingAddressList:[],
  }) 
  
  
  const [Errors,setErrors] = useState( {
    field: null,
    message: null,
    error: false,
    indexList: [],
  })
  
  
  //Declare Fields those want to call the calculation (Total amount calculations) 
  const MasterCalcFields = ["BillDiscPercent","BillDiscAmt","CashAmount","BankAmount","ShippingTax","shipping_tax_amount","ShippingCharge","RoundOff"]
  
  //Mandatory Data for details change
  const MandatoryMasterFields = ["AccountLedger","PriceCategory","Warehouse","Treatment"]
  const missingMasterMandatory = MandatoryMasterFields.find((field) => !state[field]);
  
  
  //when you adding any field here add in AddLine and clearData function tooo
  let DataListItem = {
    ProductCode: null,
    BarCode: "",
    Product: null,
    Description: "",
    Unit: null,
    UnitList: [],
    Stock: "",
    Qty: "",
    FQty: "",
    Rate: "",
    AvgCost: "",
    Tax:null,
    InclusivePrice: "",
    GrossAmount: "",
    DiscountPerc: "",
    DiscountAmount: "",
    // ExciseTax: "",
    TaxAmount: "",
    Amount: "",
    MRP:"",
    PurchasePrice:"",
    MinimumSalesPrice:"",
    AverageCost:"",
    TaxableAmount:0,
    NonTaxableAmount:0,
    
    is_inclusive:false,
    SalesPrice:0,
    SalesPrice1:0,
    SalesPrice2:0,
    SalesPrice3:0,
    
    BatchCode:null

  }
  const [DataList, setDataLists] = useState([DataListItem]);
  

  const [tableSettings, setTableSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: false,
    Product: true,
    Description: false,
    Unit: true,
    Stock: false,
    Qty: true,
    FQty: false,
    Rate: true,
    AvgCost: false,
    Tax: true,
    InclusivePrice: false,
    GrossAmount: true,
    DiscountPerc: false,
    DiscountAmount: true,
    // ExciseTax: true,
    TaxAmount:true,
    Amount: true,
    Activity: true,
    
    BatchCode:true
  });
  const [focusSettings, setFocusSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: false,
    Product: true,
    Description: true,
    Unit: true,
    Stock: false,
    Qty: true,
    FQty: true,
    Rate: true,
    AvgCost: true,
    Tax: true,
    InclusivePrice: true,
    GrossAmount: true,
    DiscountPerc: true,
    DiscountAmount: true,
    // ExciseTax: true,
    TaxAmount:true,
    Amount: true,
    Activity: true,
    
    BatchCode:true
  });
  
  
  
function customRound(x) {
  
  if (EnableEinvoiceKSA){    
    const rounded = Math.round(x * 100) / 100;

    if (rounded - x === 0.5) {
      return rounded;
    }

    return rounded;
  }
  else{
    return Number(x)
  }
}

  
  const [openCustomVoucher, setopenCustomVoucher] = useState(false)
  
  const is_customer = state.AccountLedger?.AccountGroupUnder !== 9 && state.AccountLedger?.AccountGroupUnder !== 8;
  
  //=============== INITIAL APIS AND DATA =======================
  
  const fetchData = async () => {
    setProgress(100);
    let ResponseView = null
    
    let AccountLedgerData = []
    let CashList = []
    let BankList = []
    let CountryData = []
    let EmployeeData = []
    let PriceCategoryData = []
    let TaxCategoryData = []
    let UserTableData = []
    let WarehouseData = []
    let Seperator = ""
    let Suffix = ""
    let SuffixSeparator = ""
    let PreFix = ""
    let InvoiceNo = ""
    let VoucherNo =  ""
    let PlaceOfSupplyData = []
    
    
    let formSetData = await Call_formset_api({
        CompanyID:CompanyID,
        BranchID: BranchID,
        UserID: user_id,
        VoucherType: "SI",
        SettingsName: "",
        SettingsValue: "",
        Type: "List"
    
    })

    
    let is_name = formSetData?.find((field) => field.SettingsName === "is_name")?.SettingsValue ?? true;
    let is_amount = formSetData?.find((field) => field.SettingsName === "is_amount")?.SettingsValue ?? true;
    let is_gross_amount = formSetData?.find((field) => field.SettingsName === "is_gross_amount")?.SettingsValue ?? true;
    let is_rate = formSetData?.find((field) => field.SettingsName === "is_rate")?.SettingsValue ?? true;
    let is_delete = formSetData?.find((field) => field.SettingsName === "is_delete")?.SettingsValue ?? true;
    let is_unit = formSetData?.find((field) => field.SettingsName === "is_unit")?.SettingsValue ?? true;
    let is_average_cost = formSetData?.find((field) => field.SettingsName === "is_average_cost")?.SettingsValue ?? false;
    let is_discount_amount = formSetData?.find((field) => field.SettingsName === "is_discount_amount")?.SettingsValue ?? true;
    let is_tax = formSetData?.find((field) => field.SettingsName === "is_tax")?.SettingsValue ?? true;
    let is_discount_percentage = formSetData?.find((field) => field.SettingsName === "is_discount_percentage")?.SettingsValue ?? false;
    let is_stock = formSetData?.find((field) => field.SettingsName === "is_stock")?.SettingsValue ?? false;
    let is_purchase_price = formSetData?.find((field) => field.SettingsName === "is_purchase_price")?.SettingsValue ?? false;
    let is_qty = formSetData?.find((field) => field.SettingsName === "is_qty")?.SettingsValue ?? true;
    let is_product_code = formSetData?.find((field) => field.SettingsName === "is_product_code")?.SettingsValue ?? true;
    let is_free_qty = formSetData?.find((field) => field.SettingsName === "is_free_qty")?.SettingsValue ?? false;
    let is_inclusive_tax = formSetData?.find((field) => field.SettingsName === "is_inclusive_tax")?.SettingsValue ?? false;
    let is_description = formSetData?.find((field) => field.SettingsName === "is_description")?.SettingsValue ?? false;
    let is_barcode = formSetData?.find((field) => field.SettingsName === "is_barcode")?.SettingsValue ?? false;
    
    setTableSettings({
      ...tableSettings,
      index: true,
      ProductCode: is_product_code,
      BarCode: is_barcode,
      Product: is_name,
      Description: is_description,
      Unit: is_unit,
      Stock: is_stock,
      Qty: is_qty,
      FQty: is_free_qty,
      Rate: is_rate,
      AvgCost: is_average_cost,
      Tax: is_tax,
      InclusivePrice: is_inclusive_tax,
      GrossAmount: is_gross_amount,
      DiscountPerc: is_discount_percentage,
      DiscountAmount: is_discount_amount,
      TaxAmount: is_tax,
      Amount: is_amount,
      Activity: true,
    });
    
    
    
    
    const Data = await sales_details_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
    })
    
    if (Data.StatusCode === 6000){
      
      const PlaceOfSupplyResponse = await place_of_supply_api(Country)
      if (PlaceOfSupplyResponse.StatusCode === 6000){
        PlaceOfSupplyData = PlaceOfSupplyResponse.data
      }
      
      
      AccountLedgerData = Data.AccountLedgerData
      let CashAndBankData = Data.CashAndBankData
      if (CashAndBankData){
        CashList = CashAndBankData.filter((i)=> i.AccountGroupUnder === 9 )
        BankList = CashAndBankData.filter((i)=> i.AccountGroupUnder === 8 )
      }
      
      CountryData = Data.CountryData
      EmployeeData = Data.EmployeeData
      PriceCategoryData = Data.PriceCategoryData 
      TaxCategoryData = Data.TaxCategoryData
      UserTableData = Data.UserTableData
      WarehouseData = Data.WarehouseData
      
      let VoucherNoGenerateTableData = Data.VoucherNoGenerateTableData
      Seperator = VoucherNoGenerateTableData.Seperater
      Suffix = VoucherNoGenerateTableData.Suffix
      SuffixSeparator = VoucherNoGenerateTableData.SuffixSeparator
      PreFix = VoucherNoGenerateTableData.PreFix
      InvoiceNo = VoucherNoGenerateTableData.InvoiceNo
      VoucherNo =  VoucherNoGenerateTableData.VoucherNo
      
      
      //Default Values
      let AccountLedger = AccountLedgerData.find((i)=> i.is_default === true)
      let Warehouse = WarehouseData.find((i)=> i.WarehouseID === UserTableData.DefaultWarehouse)
      let Treatment = VAT ? VAT_TreatmentData.find((i) => i.default === true) : GST ? GST_TreatmentData.find((i) => i.default === true) :null
      let PlaceOfSupply = PlaceOfSupplyData.find((i)=> i.id === State)   
      let PriceCategory = PriceCategoryData.find((i)=> i.PriceCategoryID = 1)
      let CashAccount = CashList[0]
      let BankAccount = BankList[0]
      let ShippingTax = TaxCategoryData.find((i)=> i.TaxName === "None")
      
      // AccountLedger = AccountLedgerData.find((i)=> i.is_default === true)
      // Warehouse = WarehouseData.find((i)=> i.WarehouseID === UserTableData.DefaultWarehouse)
      // Treatment = VAT ? VAT_TreatmentData.find((i) => i.default === true) : GST ? GST_TreatmentData.find((i) => i.default === true) :null
      // PlaceOfSupply = PlaceOfSupplyData.find((i)=> i.id === State)   
      // PriceCategory = PriceCategoryData.find((i)=> i.PriceCategoryID = 1)
      // CashAccount = CashList[0]
      // BankAccount = BankList[0]
      // ShippingTax = TaxCategoryData.find((i)=> i.TaxName === "None")
      
      setDefaultValue((prev)=>{
        return({...prev,
          AccountLedger:AccountLedger,
          CashAccount:CashAccount,
          BankAccount:BankAccount,
          PriceCategory:PriceCategory,
          Warehouse:Warehouse,
          Treatment:Treatment,
          PlaceOfSupply:PlaceOfSupply,
          ShippingTax:ShippingTax,
        })
      })
      

      //calling single view api
      if (is_edit && uniq_id){
        ResponseView = await Call_Sales_Master_View({BranchID:BranchID,CompanyID:CompanyID,"uniq_id":uniq_id})
        if (ResponseView){
          let {AccountLedger,BillingAddress_object} = await Call_LedgerDetials(ResponseView.AccountLedger)
          Warehouse = ResponseView.Warehouse
          Treatment = ResponseView.Treatment
          PlaceOfSupply = ResponseView.PlaceOfSupply   
          PriceCategory = ResponseView.PriceCategory
          CashAccount = ResponseView.CashAccount
          BankAccount = ResponseView.BankAccount
          ShippingTax = ResponseView.ShippingTax
          
          VoucherNo = ResponseView.VoucherNo
          
          setIs_manual_roundoff(ResponseView.is_manual_roundoff)
          
          // Calling details in mutiple for reduce 
          let TotalQty = Number(ResponseView.TotalQty)
          // setProgress(TotalQty);
          let noOfPages = Math.ceil(TotalQty / 100);

          let data = []
          for (let i=1;i<=noOfPages;i++){
            let DetailsReponse = await Call_sales_details({BranchID:BranchID,CompanyID:CompanyID,"uniq_id":uniq_id,page:i})
            if (DetailsReponse){
              data = [...data,...DetailsReponse]
            }
          }
          setProgress(0);
          setDataLists([...data])
          
        }
      }
      

      setState((prev)=>{
        return({...prev,
          ...ResponseView,
          AccountLedger,
          Warehouse,
          Treatment,
          PlaceOfSupply,
          PriceCategory,
          CashAccount,
          BankAccount,
          ShippingTax
        })      
      })
      
      setDataState((prev)=>{
        return({...prev,
          AccountLedgerData,
          CashList,
          BankList,
          CountryData,
          EmployeeData,
          PriceCategoryData,
          TaxCategoryData,
          UserTableData,
          WarehouseData,
          Seperator,
          Suffix,
          SuffixSeparator,
          PreFix,
          InvoiceNo,
          VoucherNo,
          PlaceOfSupplyData,
        })
      })
      
      
      setShippingAddress({...ShippingAddress,CountryData,StateData:PlaceOfSupplyData})
      setBillingAddress({...BillingAddress,CountryData,StateData:PlaceOfSupplyData})
      setProgress(0);
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Data?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
  }
  
  //======================CALLING APIS==========================
  
  //Ledger Searching api 
  const Call_LedgerDetials = async (data) =>{
    
    
    const LedgerDetails = await ledger_details_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      ledger_id:data.id
    })
    
    if (LedgerDetails.StatusCode === 6000){
      
      let AccountLedger = data//state.AccountLedger      
      AccountLedger["Treatment"] = LedgerDetails.data.Treatment
      AccountLedger["TaxNo"] = LedgerDetails.data.TaxNo
      AccountLedger["Mobile"] = LedgerDetails.data.Mobile
      AccountLedger["BillingAddress"] = LedgerDetails.data.BillingAddress
      AccountLedger["RouteName"] = LedgerDetails.data.RouteName
      AccountLedger["Balance"] = LedgerDetails.data.Balance
      AccountLedger["PriceCategoryID"] = LedgerDetails.data.PriceCategoryID
      // AccountLedger["BillingAddress_object"]:LedgerDetails
      
      let BillingAddress_object = LedgerDetails.data.BillingAddress_object
      // setState({...state,AccountLedger:AccountLedger})

      
      return {AccountLedger,BillingAddress_object}
      
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: LedgerDetails?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    
  }
  
  // Product Searching api
  const Call_ProductSearchFunction = async (payload) =>{
    let data = []
    const Response = await product_search_new_api(payload)
    console.log(Response,"Response");
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = []
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
    
  }
  
  // Product UnitList(PriceList) list api
  const Call_PriceList_list_Function = async (payload) =>{
    let BatchList = []
    let data = []
    const Response = await get_pricelist_list_new_api(payload)
    console.log(Response,"Response");
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = []
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
    
  }
  
  //Detials of selected unit(pricelist) api
  const Call_Single_PriceList_Function = async (payload) =>{
    let data = null
    const Response = await get_single_pricelist_new_api(payload)
    console.log(Response,"Response");
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
    
  }
  
  // Sales details view
  const Call_sales_details = async(payload) => {
    let data = null
    const Response = await get_sales_details_api(payload)
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
    
  }
  
  
  //Single View api
  const Call_Sales_Master_View = async (payload) =>{
    let data = []
    const Response = await view_sales_api(payload)
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = []
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
    
  }
  
  //BarCode Api calling
  const Call_Barcode_api = async(payload) =>{
    let data = null
    const Response = await get_product_by_barcode_api(payload)
    console.log(Response,"Response");
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
  }
  
  
  //get states of a give country api  
  const Call_States_of_Country_api = async(payload) =>{
    let data = null
    const Response = await place_of_supply_api(payload)
    console.log(Response,"Response");
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
  }
  
  

    const Call_Employees_api = async(payload) =>{
      let data = []
      const Response = await CallEmployees(payload)
      console.log(Response,"Response");
      if (Response.StatusCode === 6000){
        data = Response.data
      }
      else if (Response.StatusCode === 6001){
        data = []
      }
      else{
        dispatch(
          openSnackbar({
            open: true,
            message: Response?.message ?? "Api Error",
            severity: "error",
          })
        );
      }
      
      return data
    }
    
    
      
    //get formset values (customize table)
    const Call_formset_api = async(payload) =>{
      let data = null
      const Response = await get_formset_values_api(payload)
      console.log(Response,"Response");
      if (Response.StatusCode === 6000){
        data = Response.datas
      }
      else if (Response.StatusCode === 6001){
        data = null
      }
      else{
        dispatch(
          openSnackbar({
            open: true,
            message: Response?.message ?? "Api Error",
            severity: "error",
          })
        );
      }
      
      return data
    }
    
    
    const Call_Stock_api = async(payload) =>{
      let data = null
      const Response = await get_stock_of_products_api(payload)
      console.log(Response,"Response");
      if (Response.StatusCode === 6000){
        data = Response.data
      }
      else if (Response.StatusCode === 6001){
        data = null
      }
      else{
        dispatch(
          openSnackbar({
            open: true,
            message: Response?.message ?? "Api Error",
            severity: "error",
          })
        );
      }
      
      return data
    }
    
    
        
    const Call_Product_History_api = async(payload) =>{
      let data = []
      let ProductIDs = DataList.filter((i)=>i.Product?.ProductID)
      if (ProductIDs.length > 0){          
        let LastProduct =  ProductIDs[ProductIDs.length - 1].Product?.ProductID
        payload.CompanyID = CompanyID
        payload.BranchID = BranchID
        payload.CreatedUserID = user_id
        payload.LedgerID = state.AccountLedger.LedgerID
        payload.PriceRounding = 2
        payload.ProductIDs = [LastProduct]
        payload.CompanyID = CompanyID
      }
      
      const Response = await get_product_history_api(payload)
      console.log(Response,"Response");
      if (Response.StatusCode === 6000){
        data = Response.history_datas
      }
      else if (Response.StatusCode === 6001){
        data = []
        dispatch(
          openSnackbar({
            open: true,
            message: "No More Data!",
            severity: "warning",
          })
        );
      }
      else{
        dispatch(
          openSnackbar({
            open: true,
            message: Response?.message ?? "Api Error",
            severity: "error",
          })
        );
      }
      
      return data
    }
    
    
    
  //==============CREATE/UPDATE SHIPPING ADDRESS=================
  const Create_ShippingAddress = async(payload,method) =>{
    
    
    
    if (method === "list"){
      payload.CompanyID = CompanyID
      payload.BranchID=BranchID
      payload.LedgerID=state.AccountLedger.LedgerID
      payload.Type = "ShippingAddress"	
      const list_resposne = await list_user_address({
        CompanyID : CompanyID,
        BranchID:BranchID,
        LedgerID:state.AccountLedger.LedgerID,
        Type : "ShippingAddress"	
      })
      if (list_resposne.StatusCode===6000){
        return list_resposne.data
      }
    }
    else if(
      !payload.Attention||
      !payload.PostalCode||
      !payload.Address1||
      !payload.Address2||
      !payload.City||
      !payload.District||
      !payload.country||
      !payload.state||
      !payload.Mobile
    ){
      dispatch(
        openSnackbar({
          open: true,
          message: "Please fill all mandatory fields",
          severity: "error",
        })
      );
    }
    else if (method==="create"){
      
      payload.CompanyID = CompanyID
      payload.BranchID=BranchID
      payload.LedgerID=state.AccountLedger.LedgerID
      payload.type_data = "create"
      
      payload.country = payload.country.id
      payload.state = payload.state.id

    
      const responseData = await create_user_address(payload)
        
      if (responseData.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Created Successfully",
            severity: "success",
          })
        );
        
        setShippingAddress({...ShippingAddress,ShippingAddressList:responseData.data})
        return true
      } else if (responseData.StatusCode === 6001) {
  
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "warning",
          })
        );
        return false
      } 
      else if (responseData.StatusCode === 6002) {
     
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
        return false
      } 
      else {
  
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
        return false
      }
    }
    
    
    
    
  }
  
  //==============CREATE/UPDATE BILLING ADDRESS==================
  const Create_BillingAddress = async(payload,method) =>{
    
    if (method === "list"){
      payload.CompanyID = CompanyID
      payload.BranchID=BranchID
      payload.LedgerID=state.AccountLedger.LedgerID
      payload.Type = "BillingAddress"	
      const list_resposne = await list_user_address({
      CompanyID : CompanyID,
      BranchID:BranchID,
      LedgerID:state.AccountLedger.LedgerID,
      Type : "BillingAddress"	,
      })
      if (list_resposne.StatusCode===6000){
        return list_resposne.data
      }
    }
    else if(
      !payload.Attention||
      !payload.PostalCode||
      !payload.Address1||
      !payload.Address2||
      !payload.City||
      !payload.District||
      !payload.country||
      !payload.state||
      !payload.Mobile
    ){
      dispatch(
        openSnackbar({
          open: true,
          message: "Please fill all mandatory fields",
          severity: "error",
        })
      );
    }
    else if (method==="create"){
      
      payload.CompanyID = CompanyID
      payload.BranchID=BranchID
      payload.LedgerID=state.AccountLedger.LedgerID
      payload.type_data = "create"
      payload.AddressType = "BillingAddress"
      
      payload.country = payload.country.id
      payload.state = payload.state.id

    
      const responseData = await create_user_address(payload)
        
      if (responseData.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Created Successfully",
            severity: "success",
          })
        );
        
        setBillingAddress({...BillingAddress,BillingAddressList:responseData.data})
        return true
      } else if (responseData.StatusCode === 6001) {
  
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "warning",
          })
        );
        return false
      } 
      else if (responseData.StatusCode === 6002) {
     
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
        return false
      } 
      else {
  
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
        return false
      }
    }
    
  }
  
  
  //================E-invoice phase2===========================
  
  
    
  const submitInvoice = async(data,type_of_egs) => {  
      let payload = {
        CompanyID: CompanyID,
        BranchID: BranchID,
        invoice_id:data.master_id,
        InvoiceName:data.InvoiceName,
        VoucherNumber:data.VoucherNumber,
        type_of_egs:type_of_egs,
        submit_type:"submit"
      }
      
      let res = await get_e_invoice_submit_api({...payload});
        let status_code = res.status_code
      
        if (res.StatusCode === 6000){
          setApiResult(res.data)
          return status_code
        }
        else{
          return status_code
        }
    
  }
  
  
  
  
  
  
  //==============CREATE INVOICE API=============================
  
  const handleSubmit = async() =>{
    setErrors({
      field: null,
      message: null,
      error: false,
      indexList: [],
    })

    let { error, message, field, indexList } = await Sales_Validation(dataState,state,DataList,GeneralSettingsData);
    
    if (error){
      let errors = {
        error,
        message,
        field,
        indexList,
      };
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          severity: "error",
        })
      );
      setErrors({ ...Errors, ...errors });
    }
    else{
      setIsButtonClick(true)
      let Details = []
      DataList.map((i)=>{
        if (i.Product){          
          let item = {
            id:i.id,
            ProductID : i.Product.ProductID,
            Qty : i.Qty,
            UnitPrice : i.Rate,
            InclusivePrice : i.InclusivePrice,
            // RateWithTax : i.Rate,
            DiscountAmount : i.DiscountAmount,
            GrossAmount : i.GrossAmount,
            TaxableAmount : i.TaxableAmount,
            TaxAmount : i.TaxAmount,
            NetAmount : i.Amount,
            FreeQty : i.FQty,
            // CostPerPrice : i.Rate,
            PriceListID : i.Unit.PriceListID,
            DiscountPerc : i.DiscountPerc,
            // VATPerc : i.Rate,
            BatchCode : i?.BatchCode?.BatchCode,
            Description : i.Description,
            ProductTaxID : i.Tax.TaxID,
            NonTaxableAmount:i.NonTaxableAmount,
            PurchasePrice:i.PurchasePrice
          }
          Details.push(item)
        }
        
      })
      
      
      
      
      let payload = {
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        Details:Details,
        is_manual_roundoff:is_manual_roundoff,
        uniq_id:uniq_id,//uniq_id
        //=============
        Seperator: dataState.Seperator,
        Suffix: dataState.Suffix,
        SuffixSeparator: dataState.SuffixSeparator,
        PreFix: dataState.PreFix,
        InvoiceNo: dataState.InvoiceNo,
        VoucherNo: dataState.VoucherNo,
        //===============
        LedgerID:state.AccountLedger.LedgerID,
        AccountLedgerBalance : state.AccountLedger?.Balance??0,
        CustomerName:state.AccountLedger.LedgerName,
        CashID:state.CashAccount.LedgerID,
        BankID:state.BankAccount.LedgerID,
        EmployeeID :state.Employee?.EmployeeID,
        PriceCategoryID:state.PriceCategory.PriceCategoryID,
        WarehouseID:state.Warehouse.WarehouseID,
        Treatment:state.Treatment.value,
        Country_of_Supply:state.PlaceOfSupply.Country,
        State_of_Supply:state.PlaceOfSupply.id,
        Date:state.Date,
        RefNo:state.RefNo,    
        ShippingCharge:state.ShippingCharge,
        ShippingTax:state.ShippingTax?.TaxID,
        shipping_tax_amount:state.shipping_tax_amount,
        BillDiscPercent:state.BillDiscPercent,
        BillDiscAmt:state.BillDiscAmt,
        CashAmount:state.CashAmount,
        BankAmount:state.BankAmount,
        Notes:state.Notes,
        
        BillingAddress:state.BillingAddress?.id,
        ShippingAddress:state.ShippingAddress?.id,
        
        RoundOff:state.RoundOff,
        DueDate:state?.DueDate,
        DeliveryDate:state.DeliveryDate,
        //Total
        TotalQty:state.TotalQty,
        TotalGrossAmt:state.TotalGrossAmt,
        TotalDiscount:state.TotalDiscount,
        TotalTax:state.TotalTax,
        NetTotal:state.NetTotal,
        TotalTaxableAmount:state.TotalTaxableAmount,
        TaxTaxableAmount:state.TaxTaxableAmount,
        NonTaxTaxableAmount:state.NonTaxTaxableAmount,
        GrandTotal:state.GrandTotal,

      }
      let responseData = null
      if (is_edit && uniq_id){
        responseData = await edit_sales_api(payload)
      }
      else{
        responseData = await create_sales_api(payload)
      }
      
      if (responseData.StatusCode === 6000) {        
        if (EnableEinvoiceKSA && !is_edit){
          
          let EInvoiceData = responseData.EInvoiceData
          let master_id = EInvoiceData.master_id
          let VoucherNumber = responseData.VoucherNumber
          let InvoiceName = responseData.InvoiceName
          let type_of_egs = responseData.type_of_egs 
          let EinvoiceStatus = responseData.EinvoiceStatus 
          
          if (EInvoiceData){
            //b2b
            if (state.Treatment.value === "0"){
              
              if (EinvoiceStatus===1 || EinvoiceStatus==='1'){
                dispatch(
                  openSnackbar({
                    open: true,
                    message: "Created Successfully" + " And Einvoice Submitted Successfully",
                    severity: "success",
                  })
                );
              }
              else if (EinvoiceStatus===3 || EinvoiceStatus==='3'){
                dispatch(
                  openSnackbar({
                    open: true,
                    message: "Created Successfully" + " And Einvoice Submitted With Warnings Successfully",
                    severity: "success",
                  })
                );
              }
              else {
                dispatch(
                  openSnackbar({
                    open: true,
                    message: "Created Successfully" + " And Einvoice Submitted Is Failed!",
                    severity: "warning",
                  })
                );
              }
              
              
            }
            //b2c
            else{
              dispatch(
                openSnackbar({
                  open: true,
                  message: "Created Successfully" + " And Einvoice Drafted",
                  severity: "success",
                })
              );
            }
          }
          else{
            dispatch(
              openSnackbar({
                open: true,
                message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
                severity: "error",
              })
            );
          }

        }
        else{
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Created Successfully",
              severity: "success",
            })
          );
        }
        
        clearData();
      } else if (responseData.StatusCode === 6001) {
        setIsButtonClick(false)
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "warning",
          })
        );
      } 
      else if (responseData.StatusCode === 6002) {
        setIsButtonClick(false)
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
      } 
      else {
        setIsButtonClick(false)
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
      }
      
    }
    
    
  }
  
  
  
  //===============HANDLE CHANGE FUNCTIONS=======================
  
  
  
  const onMasterChange = (e) => {
    if(e){
      const { name, value } = e.target;
      
      //checking the field is included in MasterCalcFields
      const isCalcField = MasterCalcFields.includes(name);
      
      if (isCalcField){
        state[name] = value
        
        
        //handling bill disc 
        if (name === "BillDiscAmt"){
          state.BillDiscPercent = 100 * (Number(value)/Number(state.NetTotal))
        }
        else if (name === "BillDiscPercent"){
          state.BillDiscAmt = (Number(value)/100) * Number(state.NetTotal)
        }
        
            
        let newState = SalesInvoiceCalc(dataState,state, DataList,is_manual_roundoff,customRound);
        setState({ ...newState });
      }
      else{
        setState((prevState) => ({ ...prevState, [name]: value }));
      } 
    }
    
  }
  
  const onMasterInputChange = async (search,name) => {
    if(search && name){
      if (name === "AccountLedger") {
            
    const LedgerList = await ledger_list_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      PriceRounding:Number(PriceDecimalPoint || 2),
      voucher_type:"SI",
      search:search,
      items_per_page:10,
      page_no:1
    })
    console.log(LedgerList);
    if (LedgerList.StatusCode === 6000){
      let AccountLedgerData = [...LedgerList.data]
      setDataState({...dataState,AccountLedgerData:AccountLedgerData})
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: LedgerList?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    
    
      }
      else if (name === "Employee"){
        const EmployeeList = await Call_Employees_api({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding:Number(PriceDecimalPoint || 2),
          search:search,
          list_type:"sales",

        })
        
        const uniqueSalesmansData = EmployeeList.filter((salesman) => {
          // Check if the salesman id is not already in EmployeesList
          return !dataState.EmployeeData.some((employee) => employee.id === salesman.id);
        });
          let EmployeeData = [...dataState.EmployeeData,...uniqueSalesmansData]
          setDataState({...dataState,EmployeeData:EmployeeData})
        
      }
    }
  }
  
  const onMasterAutoChange = async(e, v, name) => {
    if(v && name){
      const isCalcField = MasterCalcFields.includes(name);
      let value = v
      if (name === "AccountLedger"){
        let {AccountLedger,BillingAddress_object} = await Call_LedgerDetials(value)
        let data =AccountLedger
        console.log(data,BillingAddress_object,"data,BillingAddress_object");
        
        
        let Treatment = VAT_TreatmentData.find((i)=> i.value === data.Treatment)
        
        let PriceCategory = dataState.PriceCategoryData.find((i)=>i.PriceCategoryID === 1)
        if (data.PriceCategoryID){
          PriceCategory = dataState.PriceCategoryData.find((i)=>i.PriceCategoryID === data.PriceCategoryID)
        }
        
        setState({...state,[name]:data,Treatment,ShippingAddress:null,BillingAddress:BillingAddress_object,PriceCategory})
      }
      
      else if (name === "PriceCategory"){
        let Data = DataList
        Data.map((lineItem,index)=>{
          if (value?.PriceCategoryID === 1){
            lineItem.Rate = lineItem.SalesPrice
          }
          else if (value?.PriceCategoryID === 2){
            lineItem.Rate = lineItem.SalesPrice1
          }
          else if (value?.PriceCategoryID === 3){
            lineItem.Rate = lineItem.SalesPrice2
          }
          else if (value?.PriceCategoryID === 4){
            lineItem.Rate = lineItem.SalesPrice3
          }
          
          let calculated_lineItem =  SalesLineCalc(dataState,state,lineItem,customRound)
          Data[index] = calculated_lineItem
        }) 
        
        setDataLists([...Data]);
        setState({...state,[name]:value})
      }
      
      
      else if (name === "Warehouse"){
        let Data = DataList
        let payload = DataList.map(object => object?.Unit?.PriceListID);
        let StockData = await Call_Stock_api({
          CompanyID: CompanyID,
          BranchID: BranchID,
          PriceListIDList : payload ,
          WarehouseID:value.WarehouseID
        })
        
        Data.map((lineItem,index)=>{
          if (lineItem.Product){            
            lineItem.Stock =  StockData.find((i)=> i.PriceListID === lineItem.Unit.PriceListID)?.Stock ?? 0
            Data[index] = lineItem
          }
        }) 
        
        setDataLists([...Data]);
        setState({...state,[name]:value})
        
        
      }
      
      else if(isCalcField){
        state[name] = value
        let newState = SalesInvoiceCalc(dataState,state, DataList,is_manual_roundoff,customRound);
        setState({ ...newState });
      }
      else{
        setState({...state,[name]:value})
      }
    }  
  }
  
  const onDetailsInputChange = async (e, name) => {
    if (e && name) {
      let value = e.target.value;
      if ((name === "Product" || name === "ProductCode") && value) {
        const ProductList = await Call_ProductSearchFunction({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state?.Warehouse?.WarehouseID,
          is_product_image: false,
          length: 10,
          product_name: value,
          type: "Sales"
        });
        setDataState({ ...dataState, ProductList: ProductList });
      }
    }
  };
  
  const onDetailsAutoChange = async(v, index, name) => {
    console.log(v, name,"333333333");
    if(v && name && v?.id){
      
      let Data = DataList;
      let lineItem = Data[index] 
        
      if (name === "Product" || name === "ProductCode"){
        
        let Product = v
        let UnitList = await Call_PriceList_list_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          product_id : Product.id,
          type_data : "SI"
        })
        
        let Unit = UnitList.find((i) => i.DefaultUnit === true)
        let PriceListID = Unit.PriceListID
        
        
        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID:PriceListID,
          PriceRounding:Number(PriceDecimalPoint || 2),
          WarehouseID:state?.Warehouse?.WarehouseID
        })
        
        let Rate = 0 
        let Qty = 1
        let FQty = 0
        let DiscountPerc = 0
        let DiscountAmount = 0
        

        
        if (UnitDetails){
          Rate = Number(UnitDetails.Rate)
          
          lineItem.MRP = UnitDetails.MRP
          lineItem.Rate = Rate
          lineItem.PurchasePrice = UnitDetails.PurchasePrice
          lineItem.BarCode = UnitDetails.BarCode
          lineItem.BatchCode = UnitDetails.BatchCode
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice
          lineItem.AverageCost = UnitDetails.AverageCost
          lineItem.is_inclusive = UnitDetails.is_inclusive
          lineItem.Stock = UnitDetails.Stock
          
          lineItem.SalesPrice = UnitDetails.SalesPrice
          lineItem.SalesPrice1 = UnitDetails.SalesPrice1
          lineItem.SalesPrice2 = UnitDetails.SalesPrice2
          lineItem.SalesPrice3 = UnitDetails.SalesPrice3

          if (state?.PriceCategory?.PriceCategoryID === 1){
            lineItem.Rate = UnitDetails.SalesPrice
          }
          else if (state?.PriceCategory?.PriceCategoryID === 2){
            lineItem.Rate = UnitDetails.SalesPrice1
          }
          else if (state?.PriceCategory?.PriceCategoryID === 3){
            lineItem.Rate = UnitDetails.SalesPrice2
          }
          else if (state?.PriceCategory?.PriceCategoryID === 4){
            lineItem.Rate = UnitDetails.SalesPrice3
          }
        }
        
        
        if (EnableProductBatchWise === true){
          if (UnitDetails.BatchCode){
            lineItem.Rate = UnitDetails.BatchCode.SalesPrice
            lineItem.PurchasePrice = UnitDetails.BatchCode.PurchasePrice
            lineItem.Stock = lineItem.BatchCode.Stock
          }
        }
        
        
        let Tax = dataState.TaxCategoryData.find((i) => i.TaxID === Product.ProductTaxID)
        // if (state.Treatment.value === "1"){
        //   Tax =  dataState.TaxCategoryData.find((i) => i.TaxName === "None")
        // }

        

        lineItem.Product = Product
        lineItem.ProductCode = Product
        lineItem.Description = Product.Description
        // lineItem.Stock = Product.Stock
        lineItem.Qty = Qty
        lineItem.FQty = FQty
        lineItem.Tax = Tax
        lineItem.DiscountPerc = DiscountPerc
        lineItem.DiscountAmount = DiscountAmount

        lineItem.Unit = Unit
        lineItem.UnitList = UnitList
        
        let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem,customRound)
        
        Data[index] = calculated_lineItem
        
        setDataLists([...Data]);
 
      }
      else if (name === "Unit"){
        let Unit = v
        
        let PriceListID = Unit.PriceListID
        
        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID:PriceListID,
          PriceRounding:Number(PriceDecimalPoint || 2),
          WarehouseID:state?.Warehouse?.WarehouseID
        })
        let Rate = 0 

        if (UnitDetails){
          Rate = Number(UnitDetails.Rate)
  
          lineItem.MRP = UnitDetails.MRP
          lineItem.Rate = Rate
          lineItem.PurchasePrice = UnitDetails.PurchasePrice
          lineItem.BarCode = UnitDetails.BarCode
          lineItem.BatchCode = UnitDetails.BatchCode
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice
          lineItem.AverageCost = UnitDetails.AverageCost
          lineItem.Stock = UnitDetails.Stock
          
          lineItem.SalesPrice1 = UnitDetails.SalesPrice1
          lineItem.SalesPrice2 = UnitDetails.SalesPrice2
          lineItem.SalesPrice3 = UnitDetails.SalesPrice3
          
          if (state?.PriceCategory?.PriceCategoryID === 1){
            lineItem.Rate = UnitDetails.SalesPrice
          }
          else if (state?.PriceCategory?.PriceCategoryID === 2){
            lineItem.Rate = UnitDetails.SalesPrice1
          }
          else if (state?.PriceCategory?.PriceCategoryID === 3){
            lineItem.Rate = UnitDetails.SalesPrice2
          }
          else if (state?.PriceCategory?.PriceCategoryID === 4){
            lineItem.Rate = UnitDetails.SalesPrice3
          }
          
          
          if (EnableProductBatchWise === true){
            if (lineItem.BatchCode){
              lineItem.Rate = lineItem.BatchCode.SalesPrice
              lineItem.PurchasePrice = lineItem.BatchCode.PurchasePrice
              lineItem.Stock = lineItem.BatchCode.Stock
            }
          }

        }
        lineItem.Unit = Unit
        let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem,customRound,customRound)
        
        Data[index] = calculated_lineItem
        setDataLists([...Data]);
        
      }
      else if (name === "Tax"){
        let Tax = v
        lineItem.Tax = Tax
        let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem,customRound)
        
        Data[index] = calculated_lineItem
        
        setDataLists([...Data]);
      }
      
      else if (name === "BatchCode"){
        let BatchCode = v
        lineItem.BatchCode = BatchCode
        lineItem.Rate = BatchCode.SalesPrice
        lineItem.PurchasePrice = BatchCode.PurchasePrice
        lineItem.Stock = BatchCode.Stock
        let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem,customRound)
        
        Data[index] = calculated_lineItem
        
        setDataLists([...Data]);
      }
 
    }  
  }
  
  const onDetailsChange = async(e,index) =>{
    
    if (e){
      let value = e.target.value;
      let name = e.target.name;
      let Data = DataList;
      
      let lineItem = Data[index]
      lineItem[name] = value
      
      
      //handling disc 
      let Amount = lineItem.GrossAmount //+ lineItem.TaxAmount
      if (name === "DiscountAmount"){
        lineItem.DiscountPerc = 100 * (Number(value)/Number(Amount))
      }
      else if (name === "DiscountPerc"){
        lineItem.DiscountAmount = (Number(value)/100) * Number(Amount)
      }
      
      
      
      let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem,customRound)
      
      Data[index] = calculated_lineItem
      setDataLists([...DataList])
    }
  }
  
  const addByBarCode = async(text) =>{
    
    let Data = DataList;
    let index = Data.length
    let lineItem =  DataListItem
    console.log(lineItem,"lineItem--------------");
    let Product = await Call_Barcode_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      BarCode: text,
    })
    
    if (Product){
      let UnitList = await Call_PriceList_list_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        product_id : Product.id,
        type_data : "SI"
      })
      
      let Unit = UnitList.find((i) => i.DefaultUnit === true)
      let PriceListID = Unit.PriceListID
      
      
      let UnitDetails = await Call_Single_PriceList_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        PriceListID:PriceListID,
        PriceRounding:Number(PriceDecimalPoint || 2),
        WarehouseID:state?.Warehouse?.WarehouseID
      })
      
      let Rate = 0 
      let Qty = 1
      let FQty = 0
      let DiscountPerc = 0
      let DiscountAmount = 0
      
  
      
      if (UnitDetails){
        Rate = Number(UnitDetails.Rate)
        
        lineItem.MRP = UnitDetails.MRP
        lineItem.Rate = Rate
        lineItem.PurchasePrice = UnitDetails.PurchasePrice
        lineItem.BarCode = UnitDetails.BarCode
        lineItem.BatchCode = UnitDetails.BatchCode
        lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice
        lineItem.AverageCost = UnitDetails.AverageCost
        
        
        
        lineItem.SalesPrice = UnitDetails.SalesPrice
        lineItem.SalesPrice1 = UnitDetails.SalesPrice1
        lineItem.SalesPrice2 = UnitDetails.SalesPrice2
        lineItem.SalesPrice3 = UnitDetails.SalesPrice3

        if (state?.PriceCategory?.PriceCategoryID === 1){
          lineItem.Rate = UnitDetails.SalesPrice
        }
        else if (state?.PriceCategory?.PriceCategoryID === 2){
          lineItem.Rate = UnitDetails.SalesPrice1
        }
        else if (state?.PriceCategory?.PriceCategoryID === 3){
          lineItem.Rate = UnitDetails.SalesPrice2
        }
        else if (state?.PriceCategory?.PriceCategoryID === 4){
          lineItem.Rate = UnitDetails.SalesPrice3
        }
  
      }
      
      let Tax = dataState.TaxCategoryData.find((i) => i.TaxID === Product.ProductTaxID)
  
      lineItem.Product = Product
      lineItem.ProductCode = Product
      lineItem.Description = Product.Description
      lineItem.Stock = Product.Stock
      lineItem.Qty = Qty
      lineItem.FQty = FQty
      lineItem.Tax = Tax
      lineItem.DiscountPerc = DiscountPerc
      lineItem.DiscountAmount = DiscountAmount
  
      lineItem.Unit = Unit
      lineItem.UnitList = UnitList
      
      let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem,customRound)
      
      Data.push(calculated_lineItem)
      
      setDataLists([...Data]);
      
    }
    
  }
  
  
  const AddLine = () => {
    let Data = DataList;
    let newData = DataListItem;
    Data.push(newData);
    setDataLists([...Data]);
  };
  
  
  const RemoveLine = (index) => {
    let Data = DataList;
    let deletedData = [];
    if (index || index === 0) {
      if (index > -1 && index < Data.length) {
        if (is_edit) {
          deletedData.push(Data[index]);
        }
        Data.splice(index, 1);
      }
    }
    
    let newState = SalesInvoiceCalc(dataState,state, Data,is_manual_roundoff,customRound);
    setState({ ...newState,deletedData:deletedData });
    
    setDataLists([...Data]);
  };
  
  
  const clearData = async () => {
    
    if (is_edit){
      navigate("/create-sales")
    }
    setIsButtonClick(false)
    
    //new voucher number
    let voucher_datas = await get_VoucherNo({
      CompanyID: CompanyID,
      UserID: user_id,
      BranchID: BranchID,
      VoucherType: "SI",
    });
    
    setDataState({
      ...dataState,
      ...voucher_datas
    })
  
    setState(({
      ...state,
      AccountLedger:defaultValue.AccountLedger,
      CashAccount:defaultValue.CashAccount,
      BankAccount:defaultValue.BankAccount,
      // Employee :defaultValue.Employee,
      PriceCategory:defaultValue.PriceCategory,
      Warehouse:defaultValue.Warehouse,
      Treatment:defaultValue.Treatment,
      PlaceOfSupply:defaultValue.PlaceOfSupply,
      Date:DefaultDate,
      RefNo:"",    
      ShippingCharge:0,
      ShippingTax:defaultValue.ShippingTax,
      shipping_tax_amount:0,
      BillDiscPercent:0,
      BillDiscAmt:0,
      CashAmount:0,
      BankAmount:0,
      Notes:"",
      DueDate:DefaultDate,
      DeliveryDate:DefaultDate,
      //Total
      TotalQty:0,
      TotalGrossAmt:0,
      TotalDiscount:0,
      TotalTax:0,
      NetTotal:0,
      TotalTaxableAmount:0,
      TaxTaxableAmount:0,
      NonTaxTaxableAmount:0,
      GrandTotal:0
    }))
    
    setDataLists([DataListItem,]);
    
  };
  
  
  
  const ChangeTableCustomization = (type, name) => {
    let value = null;

    if (type === "tableSettings") {
      value = !tableSettings[name];
      setTableSettings({ ...tableSettings, [name]: value });
    } else {
      value = !focusSettings[name];
      setFocusSettings({ ...focusSettings, [name]: value });
    }
  };
  
  
  console.log(tableSettings,"tableSettings");
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }
  
  
  const openBillingAddressModal  = async() =>{
    setIsBilling(true)
    let res = await Create_BillingAddress(BillingAddress,"list")
    if (res){
        setBillingAddress({...BillingAddress,BillingAddressList:res})
    }
  }
  
  
  const CustomVoucherSet = (e) => {
    let PreFix = dataState.PreFix;
    let Seperator = dataState.Seperator;
    let InvoiceNo = dataState.InvoiceNo;
    let SuffixSeparator = dataState.SuffixSeparator?dataState.SuffixSeparator:"";
    let Suffix = dataState.Suffix?dataState.Suffix:"";
    let VoucherNo = String(PreFix) + String(Seperator) + String(InvoiceNo) +String(SuffixSeparator) +String(Suffix);
    setDataState({ ...dataState, VoucherNo: VoucherNo });
    setopenCustomVoucher(false);
  }
  
  //============useEffects============
  
  useEffect  (()=>{
    fetchData()
  },[])
  
  useEffect(() => {
    const CalculateData = () => {
      let newState = SalesInvoiceCalc(dataState,state, DataList,is_manual_roundoff,customRound);
      setState({ ...newState });
    };
    CalculateData();
  }, [DataList]);
  
  //giving a little time waiting for onDetailsInputChange function
  const debouncedOnDetailsInputChange = debounce(onDetailsInputChange, 300);
  
  
  
  //=============KEYBOARD SHORTCUTS===================== 
  const shortCutKeyPress = useCallback (async(event) => {
    //sales history
    if (event.altKey && (event.key === 'q' || event.keyCode === 81) && sales_history_view_permission){
      if (DataList.length > 0){        
        let ProductIDs = DataList.filter((i)=>i.Product?.ProductID)
        if (ProductIDs.length > 0){              
          let SalesHistoryResponse = await Call_Product_History_api({
            Type:"Sales",
            items_per_page:5,
            page_no:1
          })
          
          setDataState({...dataState,SalesHistory:SalesHistoryResponse})
        }
      }
      setIsSalesHistory(true)
    }
    //purchase history
    else if (event.altKey && (event.key === 'w' || event.keyCode === 87 || event.keyCode === 119) && purchase_history_view_permission){
      if (DataList.length > 0){        
        let ProductIDs = DataList.filter((i)=>i.Product?.ProductID)
        if (ProductIDs.length > 0){              
          let PurchaseHistoryResponse = await Call_Product_History_api({
            Type:"Purchase",
            items_per_page:5,
            page_no:1
          })
          
          setDataState({...dataState,PurchaseHistory:PurchaseHistoryResponse})
        }
      }
      setIsPurchaseHistory(true)
    }
    //closing modal
    else{
      setIsSalesHistory(false)
      setIsPurchaseHistory(false)
    }
    
  },[state.AccountLedger,DataList])
  
  useEffect(() => {
    window.addEventListener('keydown', shortCutKeyPress);
    return () => {
      window.removeEventListener('keydown', shortCutKeyPress);
    };
  }, [shortCutKeyPress ]);
  
  
  //======================================================
  
  
  
  
  
  
  console.log(EnableShippingCharge,"EnableShippingCharge");
  
  
console.log(state,"~~~state");
console.log(dataState,"************dataState");
console.log(DataList,"============DataList");
console.log(ShippingAddress,"77777777777777ShippingAddress");
console.log(Errors,"erros");
  return (
    <>
      <Paper sx={{ height: "800px", width: "100%", overflow: "scroll" }}>
        {/*=================================== PART ONE==================================== */}

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                mr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              {is_edit ? t("Update Sales Invoice") : t("Create Sales Invoice")}
            </Typography>
          </FlexBox>

          <FlexBox className="right">
            <ClearButton onClick={() => { clearData()}} t={t} />

            <ListButton
              onClick={() => {
                navigate("/list-sales");
              }}
              t={t}
            />

            {IsButtonClick ? <LoadingButton t={t} /> : <SaveButton onClick={handleSubmit} t={t} />}
          </FlexBox>
        </Box>

        {/*=================================== PART TWO==================================== */}

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
            height: "250px",
          }}
        >
          {/* SEC - 1 */}
          <div className="sales-grid-item  give-10px-border-radious ">
            {/* customer search */}
            <InvoiceLedgerSearch name = {"AccountLedger"} label = {"Select Customer"} optionLabel = {"LedgerName"} optionLabel2 = {"LedgerCode"} OptionList = {dataState.AccountLedgerData} Value = {state.AccountLedger}  OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} openBillingAddressModal={openBillingAddressModal} BillingAddress={state.BillingAddress} is_customer={is_customer} />
          </div>

          {/* SEC - 2 */}

          <div className="sales-grid-item no-border-and-shadow">
            {/* shipping Address */}
            {is_customer && EnableShippingCharge === true?(
              <>
            <SelectShippingAddress state={state} setState={setState} setShippingAddress={setShippingAddress} ShippingAddress={ShippingAddress} Create_ShippingAddress={Create_ShippingAddress}  Value={state.ShippingAddress} open={IsShipping} setOpen={setIsShipping} />
             
             <div
              style={{
                display: EnableBillwise?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "150px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                DueDate:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox name = {"DueDate"} label = {"Select a Date"} Value={state.DueDate} OnChange={onMasterChange} width={155} />
              </Typography>
            </div>
              </>
            ):null}
            
            
            
          </div>

          {/* SEC - 3 */}
          <div className="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display: EnableWarehouse?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Warehouse:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"Warehouse"} label = {"Select a Warehouse"} optionLabel = {"WarehouseName"} List = {dataState.WarehouseData} Value = {state.Warehouse} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                VAT Treatment:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"Treatment"} label = {"Select a Treatment"} optionLabel = {"name"} List = {VAT?VAT_TreatmentData: GST?GST_TreatmentData:[]} Value = {state.Treatment} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Place Of Supply:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"PlaceOfSupply"} label = {"Select a Place Of Supply"} optionLabel = {"Name"} List = {dataState.PlaceOfSupplyData} Value = {state.PlaceOfSupply} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange}  />
              </Typography>
            </div>

            <div
              style={{
                display: EnableSalesManInSales?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Sales Man:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"Employee"} label = {"Select a Employee"} optionLabel = {"FirstName"} List = {dataState.EmployeeData} Value = {state.Employee} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>
            </div>

            {/* <button onClick={()=>{
              let data = DataList[0]
              let List = []
              for (let i=0;i<=300;i++){
                List.push(data)
              }
              setDataLists(List)
            }}>Click</button> */}

            <div
              style={{
                display: PriceCategory?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Price Category:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"PriceCategory"} label = {"Select a Price Category"} optionLabel = {"PriceCategoryName"} List = {dataState.PriceCategoryData} Value = {state.PriceCategory} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>
            </div>
          </div>

          {/* SEC - 4 */}
          <div className="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Invoice No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceVoucherNoTextBox name = {"VoucherNo"} label = {"Type a VoucherNo"} Value={dataState.VoucherNo} openCustomVoucher={openCustomVoucher} setCustomVoucher = {setopenCustomVoucher}  />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <CustomVoucher 
              open = {openCustomVoucher}
              setCustomVoucher = {setopenCustomVoucher}
              state={dataState}
              handleChange={handleChange}
              CustomVoucherSet = {CustomVoucherSet}
            />
              
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Date:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox name = {"Date"} label = {"Select a Date"} Value={state.Date} OnChange={onMasterChange} />
              </Typography>
            </div>
            
            <div
              style={{
                display: EnableDeliveryDateInSales?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Delivery Date:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox name = {"DeliveryDate"} label = {"Select a DeliveryDate"} Value={state.DeliveryDate} OnChange={onMasterChange} />
              </Typography>
            </div>
   
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Ref Bill No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox name = {"RefNo"} label = {"Type a RefNo"} Value={state.RefNo} OnChange={onMasterChange} placeholder={" Ref Bill No"} />
              </Typography>
            </div>
          </div>
        </Box>
        {/*=================================== PART THREE==================================== */}

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          {/* ==========TABLE========== */}
          <MyGrid
            dataState = {dataState}
            state={state}
            DataList={DataList}
            setDataLists={setDataLists}
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            focusSettings={focusSettings}
            setFocusSettings={setFocusSettings}
            handleInputChange={onDetailsInputChange}
            handleCompleteChanges = {onDetailsAutoChange}
            handleChanges={onDetailsChange}
            AddLine = {AddLine}
            RemoveLine = {RemoveLine}
            PriceDecimalPoint={PriceDecimalPoint}
            Errors={Errors}
            missingMasterMandatory={missingMasterMandatory}
            openSnackbar={openSnackbar}
            ChangeTableCustomization={ChangeTableCustomization}
            debouncedOnDetailsInputChange={debouncedOnDetailsInputChange}
            GeneralSettingsData={GeneralSettingsData}
          />
        </Box>
        
         {/*=================================== PART FOUR==================================== */}
        
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{ cursor: "pointer" }} onClick={AddLine}>
            <span style={{ whiteSpace: "nowrap", color: "#0A9EF3", margin: 0,fontSize:"13px" }}>
              + Add Line{" "}
            </span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px", // Adjust the gap between the span and BarcodeTextBox
              height: "40px",
            }}
          >
            <span style={{ whiteSpace: "nowrap",fontSize:"13px" }}>Add By Barcode : </span>
            <InvoiceBarCodeTextBox onEnter ={addByBarCode} placeholder={"Add By Barcode"}/>
          </div>
        </Box>


        {/*=================================== PART Five==================================== */}

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          {/* ------------Payment And Notes--------------- */}
          <div>

            {/* -----------CASH PAYMENT---------------- */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Cash Account:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Amount:
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"CashAccount"} label = {"Select a CashAccount"} optionLabel = {"LedgerName"} List = {dataState.CashList} Value = {state.CashAccount} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox type={"number"} name = {"CashAmount"} label = {"Type a CashAmount"} Value={state.CashAmount} OnChange={onMasterChange} />
              </Typography>
            </div>

            {/* ---------BANK PAYMENT-------------- */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Bank Account:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Amount:
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"BankAccount"} label = {"Select a BankAccount"} optionLabel = {"LedgerName"} List = {dataState.BankList} Value = {state.BankAccount} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox  type={"number"} name = {"BankAmount"} label = {"Type a BankAmount"} Value={state.BankAmount} OnChange={onMasterChange} />
              </Typography>
            </div>

            {/* -------------------NOTES-------------*/}
            <br></br>
            <NotesTextArea
              value={state.Notes ? state.Notes : ""}
              name={"Notes"}
              placeholder={t("Notes (Optional)")}
              onChange={onMasterChange}
            />
          </div>

          {/* SHIPPING AND DISCOUNT */}
          <div>
            {/* Shipping */}

            <div
              style={{
                // display: EnableShippingCharge?"flex":"none",
                display:"none",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Shipping Charge:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Shipping Tax:
              </Typography>
            </div>

            <div
              style={{
                // display: EnableShippingCharge?"flex":"none",
                display:"none",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox type={"number"} name = {"ShippingCharge"} label = {"Type a ShippingCharge"} Value={state.ShippingCharge} OnChange={onMasterChange} />
              
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                  <InvoiceAutoComplete name = {"ShippingTax"} label = {"Select a ShippingTax"} optionLabel = {"TaxName"} List = {dataState.TaxCategoryData} Value = {state.ShippingTax} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>
            </div>
            {/* BILL DISCOUNT */}

            <br></br>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Discount(%):
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Discount:
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox type={"number"} name = {"BillDiscPercent"} label = {"Type a BillDiscPercent"} Value={state.BillDiscPercent} OnChange={onMasterChange} />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
              <InvoiceTextBox type={"number"} name = {"BillDiscAmt"} label = {"Type a BillDiscAmt"} Value={state.BillDiscAmt} OnChange={onMasterChange} />
              </Typography>
            </div>
            <Typography
              style={{ marginTop: "10px" }}
              variant="overline"
              display="block"
              gutterBottom
            >
              {/* <AddToItemsButton /> */}
            </Typography>
          </div>

          {/* TOTAL AMOUNTS AND VALUES */}

           <SummaryDetails onChange = {onMasterChange}  state={state} is_manual_roundoff={is_manual_roundoff} setIs_manual_roundoff={setIs_manual_roundoff} EnableShippingCharge={EnableShippingCharge} />
        </Box>
        
        {/* =======SHIPPING ADDRESS MODAL======== */}
        {EnableShippingCharge === true?(
        <AddressModal type={"Shipping"}  state={state} setState={setState} open={IsShipping} setOpen={setIsShipping} List = {ShippingAddress?.ShippingAddressList} name={"Shipping Address"} Address={ShippingAddress} setAddress={setShippingAddress} Call_States_of_Country_api={Call_States_of_Country_api} api = {Create_ShippingAddress} />
        ):null}
        {/* =======BILLING ADDRESS MODAL========= */}
        <AddressModal type={"Billing"}  state={state} setState={setState} open={IsBilling} setOpen={setIsBilling} List = {BillingAddress?.BillingAddressList} name={"Billing Address"} Address={BillingAddress} setAddress={setBillingAddress} Call_States_of_Country_api={Call_States_of_Country_api} api = {Create_BillingAddress} />
        {/* ============HISTORY MODALS============== */}
        <HistoryModal Type = {"Sales"} heading = {"Sales History"} List = {dataState.SalesHistory} open = {IsSalesHistory} setOpen = {setIsSalesHistory} Call_Product_History_api={Call_Product_History_api} dataState={dataState} setDataState={setDataState} />
        <HistoryModal Type = {"Purchase"}  heading = {"Purchase History"} List = {dataState.PurchaseHistory} open = {IsPurchaseHistory} setOpen = {setIsPurchaseHistory} Call_Product_History_api={Call_Product_History_api} dataState={dataState} setDataState={setDataState}  />
      
      </Paper>
      
      {progress > 0 ? (
        <LoaderLite message={"Please wait......." } fullscreen={true} />
      ) : null}
    </>
  );
};

export default SalesInvoice;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "69%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));

import { Box, TextField, Slide, FormControl, FormLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ViknAutoComplete from "./ViknAutoComplete";
import { useTranslation } from "react-i18next";


const VBDatePiker = function (props) {
  const [t, i18n] = useTranslation("common");
  return (
    <>
        <FormControl fullWidth>
      {!props?.notLabel && <FormLabel sx={{ fontSize: "14px", mb: 1}}>{props.label ? props.label : t("Date Period")}</FormLabel>}
      {props.isDateRange &&
      <Box sx={{mb:1}}>
        <ViknAutoComplete
          placeholder={"Date Range"}
          value={props.dateValue}
          disableClearable
          options={[
            { name: "Custom" },
            { name: "Today" },
            { name: "This Week" },
            { name: "This Month" },
            { name: "Last 90 Days" },
            { name: "This Year" },
            { name: "Last Year" },
          ]}
          getOptionLabel={(option) => option.name}
          onChange={(e, newValue) =>
            props.handleAutoComplete(e, newValue, "date")
          }
        />
      </Box>
      }
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Slide direction="right" in={props.fromDate} unmountOnExit>
        <Box sx={{ mr: "4px" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              // label="Basic date picker"
              // formatDate={(date) => moment(date).format('DD-MM-YYYY')}
              //   value={moment()}
              // disabled
              readOnly={props?.fromReadOnly}
              value={props?.fromDate}
              onChange={props?.fromOnChange}
              format="DD-MM-YYYY"
              textField={(params) => <TextField {...params} />}
              sx={{
                ...props.sx,
                "& .MuiInputBase-root": {
                  bgcolor: "black",
                  borderRadius: "6px",
                  flexDirection: "row-reverse",
                  "& .MuiInputBase-input": {
                    py: "10px",
                    fontSize: "12px",
                    color: "white",
                    "& .Mui-disabled": {
                      color: "white",
                    },
                  },
                  "& .MuiButtonBase-root ": {
                    padding: "1px",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                    color: "white",
                  },
           
                },
              }}
      
            />
          </LocalizationProvider>
          {/* </ThemeProvider> */}
        </Box>
        </Slide>

        {/* {props.toDate && ( */}
        <Slide direction="left" in={props.toDate} unmountOnExit>
          <Box sx={{ ml: "4px" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                // label="Basic date picker"
                // formatDate={(date) => moment(date).format('DD-MM-YYYY')}
                // value={moment()}
                // readOnly={false}
                value={props?.toDate}
                onChange={props?.toOnChange}
                readOnly={props?.toReadOnly}
                format="DD-MM-YYYY"
                textField={(params) => <TextField {...params} />}
                sx={{
                  "& .MuiInputBase-root": {
                    bgcolor: "black",
                    borderRadius: "6px",
                    flexDirection: "row-reverse",
                    "& .MuiInputBase-input": {
                      py: "10px",
                      fontSize: "12px",
                      color: "white",
                      "& .Mui-disabled": {
                        color: "white",
                      },
                    },
                    "& .MuiButtonBase-root ": {
                      padding: "1px",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "18px",
                      color: "white",
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          </Slide>
        {/* )} */}
      </Box>
      </FormControl>
    </>
  );
};

export default VBDatePiker;

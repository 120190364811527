import {
  Box,
  Button,
  Paper,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductDetail from "./Components/ProductDetail";
import MultiUnits from "./Components/MultiUnits";
import { get_VoucherNo, unit_list } from "../../../Api/CommonApi/CommonApis";
import { useSelector } from "react-redux";
import { ProductGroupAPI } from "../../../Api/Products/ProductApis";
import { CallBrands } from "../../../Api/Reports/CommonReportApi";

// Replace this with the Vikn theme fiele color
const VIKNBLUE = "#0A9EF3";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {/* {value === index && <Box sx={{ p: 3 }}>{children}</Box>} */}
      <Box>{children}</Box>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreateProduct = () => {
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);

  const [value, setValue] = useState(0);

  const [dataDetail, setDataDetail] = useState({
    ProductCode: "",
    ProductName: "",
    DisplayName: "",
    Description: "",
    InventoryType: "",
    VatID: 0,
    BrandID: 0,
    StockMinimum: 0,
    StockReOrder: 0,
    StockMaximum: 0,
    MarginPercent: 0,
    ProductGroupID: 0,
    MinimumSalesPrice: 0,
    CostWithExpense: 0,
    ManualProductCode: false,
    DefaultPurchasePrice: 0,
    DefaultMRP: 0,
    WarrantyType: "Year",
    Warranty: 1,
    AverageCost: 0,
    is_Service: false,
    productGroup: null,
    brand: null,
    unit: null,
    priductGroupList: [],
    unitList: [],
    brandList: [],
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const callPreFetch = async function () {
    await get_VoucherNo({
      BranchID,
      CompanyID,
      UserID: user_id,
      VoucherType: "PC",
    }).then((e) => {
      if (e.StatusCode === 6000) {
        setDataDetail((prev) => ({ ...prev, ProductCode: e.VoucherNo }));
      }
    });

    await ProductGroupAPI({
      BranchID,
      CompanyID,
      CreatedUserID: user_id,
    }).then((e) => {
      if (e.StatusCode === 6000) {
        setDataDetail((prev) => ({
          ...prev,
          priductGroupList: e.data,
          productGroup: e.data?.[0] || null,
        }));
      }
    });

    await unit_list({
      BranchID,
      CompanyID,
      CreatedUserID: user_id,
    }).then((e) => {
      if (e.StatusCode === 6000) {
        setDataDetail((prev) => ({
          ...prev,
          unitList: e.data,
          unit: e.data?.[0] || null,
        }));
      }
    });

    await CallBrands({
      BranchID,
      CompanyID,
      CreatedUserID: user_id,
    }).then((e) => {
      if (e.StatusCode === 6000) {
        setDataDetail((prev) => ({
          ...prev,
          brandList: e.data,
          brand: e.data?.[0] || null,
        }));
      }
    });
  };

  useEffect(() => {
    callPreFetch();
  }, []);

  return (
    <Paper sx={{ width: "100%" }}>
      <Box
        sx={{
          p: 3,
          borderColor: "#EBEBEB",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "36px",
              color: "#001746",
            }}
          >
            Create Product
          </Typography>
          <Button
            sx={{
              fontSize: "15px",
              textTransform: "none",
              px: 2,
              color: VIKNBLUE,
            }}
            variant="text"
          >
            View List
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Button
            sx={{
              fontSize: "15px",
              textTransform: "none",
              px: 2,
              color: VIKNBLUE,
            }}
            variant="text"
          >
            Cancel
          </Button>
          <BlueButton sx={{ px: 4 }} onClick={() => {}}>
            Save
          </BlueButton>
        </Box>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: VIKNBLUE,
              height: "4px",
            },
            ".MuiButtonBase-root": {
              textTransform: "none",
              width: "160px",
            },
            "& .Mui-selected": {
              color: `${VIKNBLUE} !important`,
            },
          }}
        >
          <Tab
            label="Product Details"
            sx={{ width: "288px", textTransform: "none" }}
            {...a11yProps(0)}
          />
          <Tab
            label="Multi Units"
            sx={{ width: "288px", textTransform: "none" }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <ScrollBox>
        <CustomTabPanel value={value} index={0}>
          <ProductDetail
            dataDetail={dataDetail}
            setDataDetail={setDataDetail}
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <MultiUnits dataDetail={dataDetail} setDataDetail={setDataDetail} />
        </CustomTabPanel>
      </ScrollBox>
    </Paper>
  );
};

export default CreateProduct;

const BlueButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#0A9EF3",
  textTransform: "none",
  fontSize: "15px",
  borderRadius: "6px",
  ":hover": {
    backgroundColor: "#0A9EF3",
  },
}));

const ScrollBox = styled(Box)(() => ({
  height: "calc(100vh - 208px)",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "&::-webkit-scrollbar-track": {
    display: "none",
  },
}));

// CompanyID: 8218b767-6897-48d7-a559-5f0b09fd7a56
// BranchID: 1
// CreatedUserID: 829
// ProductName: conn
// ProductCode: PC1142
// DisplayName: conn
// Description:
// ProductGroupID: 2
// BrandID: 2
// InventoryType: StockItem
// VatID: 11
// MinimumSalesPrice: 0
// StockMinimum: 0.00
// StockReOrder: 0.00
// StockMaximum: 0.00
// MarginPercent: 0
// ProductImage:
// ProductImage2:
// ProductImage3:
// ProductImage4:
// ProductImage5:
// Active: true
// IsRawMaterial: false
// IsWeighingScale: false
// IsFinishedProduct: false
// IsSales: true
// IsPurchase: true
// WeighingCalcType: Amount
// PLUNo: 0
// IsFavourite: false
// HSNCode:
// IsKFC: false
// GST: null
// Tax1: null
// Tax2: null
// Tax3: null
// WarrantyType: Year
// Warranty: 1
// is_Service: false
// is_inclusive: false
// PriceListDetails: [{"UnitName":"","UnitID":3,"SalesPrice":0,"PurchasePrice":0,"MultiFactor":1,"ReverseFactor":"","Barcode":"","SalesPrice1":"0","SalesPrice2":"0","SalesPrice3":"0","MRP":0,"UnitInSales":true,"UnitInPurchase":true,"UnitInReports":true,"DefaultUnit":true,"detailID":0},{"UnitName":"","UnitID":"","SalesPrice":0,"PurchasePrice":0,"MultiFactor":"","ReverseFactor":"1","Barcode":"","SalesPrice1":"0","SalesPrice2":"0","SalesPrice3":"0","MRP":0,"UnitInSales":false,"UnitInPurchase":false,"UnitInReports":false,"DefaultUnit":false,"detailID":0}]
// BarcodeDetails: [{"barcode":"","unit":1}]
// IsBOM: false
// BomList: [{"Barcode":"","ProductCode":{"ProductCode":""},"ProductName":{"ProductName":""},"Qty":1,"UnitPrice":0,"UnitList":[{"UnitName":""}],"NetAmount":0}]
// deleted_bom: []
// PurchasePrice: 0
// ExciseTaxID: null
// AverageCost: 0
// CostWithExpense: 0

import React, { useEffect, useState } from 'react'
import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { VBSkeletonLoader, VBTableCellBody, VBTableCellFooter, VBTableCellHeader, VBTableContainer, VBTableFooter, VBTableHeader, VBTableRowNull } from '../../../../Components/ReportTable/ReportTable';
import FilterLable from '../../../../Components/Utils/FilterLable';
import FilterBtn from '../../../../Components/Utils/FilterBtn';
import ExportBtn from '../../../../Components/Utils/ExportBtn';
import Pagenation from '../../../../Components/Utils/Pagenation';
import moment from 'moment';
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import {  CallBranchsList, CallParties, CallUsers } from '../../../../Api/Reports/CommonReportApi';
import ViknCheckboxGroup from '../../../../Components/Utils/ViknCheckboxGroup'
import ViknRadioGroup from '../../../../Components/Utils/ViknRadioGroup';
import ViknSearchAutoComplete from '../../../../Components/Utils/ViknSearchAutoComplete';
import VBDatePiker from '../../../../Components/Utils/VBDatePiker';
import { callPurchaseReport } from '../../../../Api/Reports/PurchaseApi';
import { useDispatch, useSelector } from 'react-redux';
import  VBInputField  from '../../../../Components/Utils/VBInputField'
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../../generalFunction';
import { openSnackbar } from '../../../../features/SnackBarslice';
import ReportDownload from '../../../../Components/Utils/ReportDownload';
import { VIKNBOOKS_FRONT_URL } from '../../../../generalVeriable';
import { date_range } from '../../../../Function/comonFunction';
import { UserRolePermission } from '../../../../Function/Editions';
import ViknAutoComplete from '../../../../Components/Utils/ViknAutoComplete';
const PurchaseReport = () => {
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [reportData, setReportData] = useState({
    data: [],
  })

  let Export_view = UserRolePermission("Purchase Report", "print_permission");
  console.log(Export_view,'vivviiiiiiiiviiiieeweweeweeeeeeeeeeeeeee');
  const dispatch = useDispatch()
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,EnableBranch} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [page_no, setPage_no] = useState(1);
  const [t, i18n] = useTranslation("common");
  const [isLoadingSupplier, setIsLoadingSupplier] = useState(false);
  const [loading, setLoading] = useState(false)
  const [Branchids, setBranchids] = useState([])
  const isAdmin = BranchID === 1 ? true : false;
  const [filterOptions, setFilterOptions] = useState({
    
    userList : [],
    UserObj : {customer_name : ""},
    SupplierList : [],
    SupplierObj : {spplier_name : ""},
    reffBillNo : '',
    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment(),
    },
    branch: {
      name: "Branch",
      isActive: false,
      value: ""
    },
    WithoutDateOption: {
      WithoutDate :{ isActive : false,}
    },

    BranchList: [],
    SelectedBranches:[],
    IsSelectedAllBranch:false,

    PurchaseType : 'purchase',
    PurchaseList : [
      {name : 'purchase',label: 'Purchase'},
      {name : 'return', label: 'Return'},
      {name : 'both', label:'Both'},
    ],
    filterValue: 'all',
    filterList : [
      {name : 'all', label: 'All'},
      {name : 'cash', label: 'Cash'},
      {name : 'credit', label: 'Credit'},
    ],
  });

  //Handle functions
  const handleChangeCheckbox = () => {
    setFilterOptions({
      ...filterOptions,
      WithoutDateOption: {
        WithoutDate:{
          isActive : !filterOptions.WithoutDateOption.WithoutDate.isActive
        }
      },
    });
  };
  const handlechange = (e,name) => {
    let { value } = e.target
    setFilterOptions({...filterOptions,[name]:value})
  }

  const handleDefaultFilter = async () => {
    let BranchIDList = filterOptions.BranchList.filter((i) => {
      if (i.BranchID === BranchID) {
        return i;
      }
    });
    let SelectedBranches = [];
    SelectedBranches.push(BranchIDList[0]);

    setFilterOptions({
      ...filterOptions,
      UserObj: { customer_name: "" },
      SupplierObj : {spplier_name: ""},
      reffBillNo : '',
      WithoutDateOption: {
        WithoutDate :{ isActive : false,}
      },
      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Today" },
        FromDate: moment(),
        ToDate: moment(),
      },
      PurchaseType: "purchase",
      filterValue: "all",
      SelectedBranches,
      IsSelectedAllBranch:false
    })
  }
  const handleReffBillchange = (e) => {
    setFilterOptions({
      ...filterOptions,
      reffBillNo : e.target.value
    })
  }

  const handleSearchParty = async (e, value, name) => {
    if (value) {
      setIsLoadingSupplier(true);
      const partyResponse = await CallParties({
        CompanyID : CompanyID,
        BranchID : BranchID,
        PriceRounding : Number(PriceDecimalPoint),
        CreatedUserID: user_id,
        items_per_page: 10,
        page_no: 1,
        search: value,
        PartyType: "2",
      });
      let SupplierList = [];
      if (partyResponse?.StatusCode === 6000) {
        SupplierList = partyResponse?.data;
      }
      setFilterOptions({ ...filterOptions, SupplierList });
      setIsLoadingSupplier(false);
    } else {
      setFilterOptions({ ...filterOptions, SupplierObj: { spplier_name: "" } });
    }
  };

  // search user
  // const handleSearchUser =  async(e, value,name) =>{
  //   if (value){
  //     setIsLoadingUser(true)
  //     let UserLists =  await CallUsers({
  //       items_per_page: 10,
  //       page_no: 1,
  //       search: value,
  //     });

  //     let User_Data = [];
  //     if (UserLists.data) {
  //       User_Data = UserLists.data;
  //     }
  //     setFilterOptions({...filterOptions,userList:User_Data,});
  //     setIsLoadingUser(false)
  //   }else{
  //     setFilterOptions({...filterOptions,UserObj: {customer_name:""},
  //     });
  //   }
  // }

  console.log(filterOptions);
  const closeFilter = () => setOpenFilter(false);


  const handleDeletefilter = (name, index) => {
    console.log(name, index);
    if (name === "routeListValue") {
      let routeListValue = [...filterOptions.routeListValue];
      routeListValue = routeListValue.filter((item, idx) => idx !== index);
      setFilterOptions((prevState) => ({
        ...prevState,
        routeListValue: routeListValue,
      }));
    } else {
      setFilterOptions((prevState) => ({ ...prevState, [name]: {} }));
    }
  };

  const handleAutoComplete = function (e, value, name) {
    if (value) {
      if (name === "date") {
        console.log(e, value);
        if (value.name === "Custom") {
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: true,
              value: value,
            },
          });
        } else {
          let date = date_range(value.name);
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: false,
              value: value,
              ToDate: date.todate,
              FromDate: date.fromdate,
            },
          });
        }
      } else {
        setFilterOptions({ ...filterOptions, [name]: value });
      }
    }
  };


  // API Fetch data
  const fetchReport = async function (array) {
    let BranchIDList = [BranchID]
    let SelectedBranches = array ? array : filterOptions.SelectedBranches;
    if (SelectedBranches.length !== 0 && isAdmin) {
      let BranchList = [];
      console.log(SelectedBranches,"SelectedBranches*************");
      SelectedBranches.map((i) => {
        i.BranchID
          ? BranchList.push(i.BranchID)
          : console.log(`no branchid${i}`);
      });

      BranchIDList = BranchList;
    }
    setLoading (true)
    const data = await callPurchaseReport({
      CompanyID : CompanyID,
      BranchID : BranchIDList,
      // BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
      UserID: filterOptions.UserObj?.UserID ? filterOptions.UserObj.UserID : 0,
      // EmployeeID: filterOptions.salesmanObj?.EmployeeID
      //   ? filterOptions.salesmanObj.EmployeeID
      //   : 0,
      CustomerID: filterOptions.SupplierObj?.LedgerID
        ? filterOptions.SupplierObj.LedgerID
        : 0,
      page_no: page_no,
      page_size: appSetting.itemPerPage,
      ReffNo :filterOptions.reffBillNo,
      is_date : filterOptions.WithoutDateOption.WithoutDate.isActive,
      filterValue: filterOptions.filterValue,
      filter_type: filterOptions.PurchaseType,
      purchaseType : filterOptions.PurchaseType
    },dispatch);
    console.log(data);
    if (data?.StatusCode === 6000) {
      setReportData(data);
      // setPage()
    } else if (data?.StatusCode === 6001) {
      setReportData(data);
      dispatch(
        openSnackbar({
          open : true,
          message : data.message,
          severity : 'warning',
        })
      )
    }
    setLoading(false);
  };


  // const fetchExpensesReport = async function () {
  //   const data = await CallExpenseSummaryReport({
  //     FromDate: moment().format('YYYY-MM-DD'),
  //     ToDate: "2024-02-09"
  //   });
  //   console.log(data);
  //   if (data?.StatusCode === 6000) {
  //     setReportData(data.data)
  //   }
  // }
  const searchReport = function () {
    fetchReport();
  };
  const filterApis = async function () {
    setIsLoadingUser(true);
    const userResponse = await CallUsers({
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      items_per_page: 10,
      page_no: 1,
      search: "",
      PartyType: "1",
    });
    let userList = [];
    if (userResponse.StatusCode === 6000) {
      userList = userResponse.data;
    }

    setIsLoadingSupplier(true);
    const supplierResponse = await CallParties({
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      items_per_page: 10,
      page_no: 1,
      search: "",
      PartyType: "2",
    });
    let SupplierList = [];
    if (supplierResponse.StatusCode === 6000) {
      SupplierList = supplierResponse.data;
    } else {
      console.log('error on supplier Api');
    }

    let SelectedBranches = [];
    const BranchsListRes = await CallBranchsList({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      show_all: true,
    });
    if (BranchsListRes?.StatusCode === 6000) {
      let BranchIDList = BranchsListRes.data.filter((i) => {
        if (i.BranchID === BranchID) {
          return i;
        }
      });

      SelectedBranches.push(BranchIDList[0]);
    } else if (BranchsListRes.StatusCode === 6001) {
      console.log("ERROR - 6001")
    }

    setFilterOptions({
      ...filterOptions,
      userList,
      SupplierList,
      SelectedBranches,
      BranchList : BranchsListRes.data,
    });
    setIsLoadingUser(false);
    setIsLoadingSupplier(false);
  }

  const handleBranchIds = function ()  {
    let BranchIds = filterOptions.SelectedBranches.map((i) => i.BranchID)
    setBranchids(BranchIds)
  }

  const getDataForPage = () => {
    const startIndex = (page_no - 1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData.data?.slice(0,reportData.data.length -1)
    return alldata?.slice(startIndex, endIndex) || [];
  };

  useEffect(() => {
    // fetchExpensesReport()
    filterApis()
  }, [])
  console.log("customernameeeee",filterOptions,"customernameeeee");
  console.log(reportData.data,  "reportdaaaa");
  console.log(reportData.data,  "reportdaaaa");
  const handleTableRowClick = (id, VoucherType, VoucherNo,BranchID) => {
    let destinationURL;
    let URL;
  
    switch (VoucherType) {
      case "PI":
        URL= `${VIKNBOOKS_FRONT_URL}dashboard/purchase-invoice-single`;
        destinationURL = `${URL}?id=${id}&BranchID=${BranchID}`;
        break;
      case "PR":
        URL = `${VIKNBOOKS_FRONT_URL}dashboard/purchase-return-single`;
        destinationURL = `${URL}?id=${id}&BranchID=${BranchID}`;
        break;
      
      default:
        URL = `${VIKNBOOKS_FRONT_URL}dashboard/purchase-return-single`;
        destinationURL = `${URL}?id=${id}&BranchID=${BranchID}`;
    }
  
    window.open(destinationURL, '_blank');
  };

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className='left'> 
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {filterOptions.PurchaseType === 'return' ? t('Purchase Return Report') : t('Purchase Report') }
            </Typography>
            {/* <SearchReport /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className='right'>
          {Export_view ? 
          <ReportDownload
            download_type={"PDF"}
            invoice_type={filterOptions.PurchaseType === 'return' ? "purchase_return" : filterOptions.PurchaseType === "purchase" ? "purchase" : "purchase_both" }
            filterValue={ filterOptions.filterValue}
            FromDate={ filterOptions.date.FromDate.format("YYYY-MM-DD")}
            ToDate={ filterOptions.date.ToDate.format("YYYY-MM-DD")}
            UserID={ filterOptions.UserObj?.UserID ? filterOptions.UserObj.UserID : 0}     
            EmployeeID={0}
            CustomerID={ filterOptions.SupplierObj?.LedgerID? filterOptions.SupplierObj.LedgerID: 0}
            ReffNo ={filterOptions.reffBillNo}
            BranchID = {`[${filterOptions.SelectedBranches.map((i) => i.BranchID)}]`} 
            // columns={reportData}
          />
          : null}
            {/* <PrintBtn /> */}
            <Pagenation 
              totalItem={reportData.data?.length -1}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
            {t("Applied Filters: ")}
          </div>
          {/* {filterOptions.UserObj.customer_name !== "" && filterOptions.UserObj.customer_name ? (<FilterLable 
            name={"User"}
            value={filterOptions.UserObj.customer_name}
            // handleDelete={() => handleDeletefilter("UserObj")}
          />):null} */}
          {filterOptions.date?<FilterLable name = {t("Date")} value = {filterOptions.date.FromDate.format("DD-MM-YYYY") + " to " + filterOptions.date.ToDate.format("DD-MM-YYYY")} />:null}
          {filterOptions.PurchaseType?<FilterLable name = {t("Purchase Type")} value = {filterOptions.PurchaseType} />:null}
          {filterOptions.filterValue?<FilterLable name = {t("Filter List")} value = {filterOptions.filterValue} />:null}
          {filterOptions.WithoutDateOption.WithoutDate.isActive?<FilterLable name = {t("Without Date")} value = {"True"} />:false}
          {filterOptions.reffBillNo?<FilterLable name = {t("Reff Bill No")} value = {filterOptions.reffBillNo} />:null}
          {filterOptions.UserObj?.customer_name &&(
            <FilterLable
              name={t("User")}
              value={filterOptions.UserObj.customer_name}
              handleDelete={() => handleDeletefilter("UserObj")}
            />
          )}

          {filterOptions.SupplierObj?.PartyName && (
            <FilterLable
              name={t("Supplier")}
              value={filterOptions.SupplierObj.PartyName}
              handleDelete={() => handleDeletefilter("SupplierObj")}
            />
          )}

          {filterOptions.SelectedBranches.length !== 0 &&
            // BranchData.IsSelectedAllBranch === false &&
            EnableBranch &&
            BranchID === 1 &&
            isAdmin === true && (
              <FilterLable
                name={t("Branch Name")}
                value={filterOptions.SelectedBranches.map((e, i) => (
                  <Chip
                    key={i}
                    label={e.NickName}
                    variant="outlined"
                    sx={{
                      p: "1px",
                      fontSize: "10px",
                      height: "22px",
                      mr: "4px",
                      ".MuiChip-label": {
                        px: "8px",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "15px",
                        color: "#ff00006b",
                      },
                    }}
                    onDelete={() => {
                      let SelectedBranches = filterOptions.SelectedBranches.filter(
                        (l) => l.BranchID !== e.BranchID
                      );
                      setFilterOptions({
                        ...filterOptions,
                        IsSelectedAllBranch: false,
                        SelectedBranches: SelectedBranches,
                      });

                      fetchReport(SelectedBranches);
                    }}
                  />
                ))}
                handleDelete={() => {
                  let get_BranchID = filterOptions.BranchList.filter((i) => {
                    if (i.BranchID === BranchID) {
                      return i;
                    }
                  });

                  let SelectedBranches = [];
                  SelectedBranches.push(get_BranchID[0]);
                  setFilterOptions({
                    ...filterOptions,
                    SelectedBranches,
                    IsSelectedAllBranch: false,
                  });
                  fetchReport(SelectedBranches);
                }}
              />
            )}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t('Serial No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Voucher No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Voucher Date')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Reference Bill No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Reference Bill Date')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Ledger Name')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Gross Amount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Total Tax')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Bill Discount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Grand Total')}</VBTableCellHeader>
          </VBTableHeader>
          {loading ?
          <VBSkeletonLoader/>
          :  
          <TableBody>
          {getDataForPage().map((data, i) => (
            <TableRow
            onClick={() =>
              handleTableRowClick(data.id, data.VoucherType, data.VoucherNo,data.BranchID)
            }
            >
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.index}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.VoucherNo}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.Date}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.ReferenceNo}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.ReferenceDate}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer"}}>{data.LedgerName}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.GrossAmount)}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.TotalTax)}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.BillDiscount)}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.GrandTotal)}</VBTableCellBody>
            </TableRow>
          ))}
          <TableBody></TableBody>
          <VBTableRowNull length = {reportData.data?.length}/>
          </TableBody>
          }
          <VBTableFooter >
            <TableRow>
              <VBTableCellFooter >{t('Total')}</VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}} >
              {formatNumber(reportData?.data?.[reportData.data.length - 1]?.GrossAmount ?? 0)}
              </VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}} >
                {formatNumber(reportData?.data?.[reportData.data.length -1]?.TotalTax ?? 0)}
              </VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>
                {formatNumber(reportData?.data?.[reportData.data.length -1]?.BillDiscount ?? 0)}
              </VBTableCellFooter>
              <VBTableCellFooter align="right" sx={{textAlign:"right"}}>
                {formatNumber(reportData?.data?.[reportData.data.length -1]?.GrandTotal ?? 0)}
              </VBTableCellFooter>
            </TableRow>
          </VBTableFooter>
        </VBTableContainer>
      </Paper>


      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter} 
        onFilter={searchReport}
        setPage_no = {setPage_no}
        handleDefaultFilter={()=>handleDefaultFilter()}

        filterCompnt={
          <List sx={{ pt: "58px" }}>
            {/* <ListItem>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Filter Name
                </InputLabel>
                <VBInputField />
              </FormControl>
            </ListItem> */}
            <ListItem>
              <VBDatePiker
                handleAutoComplete={handleAutoComplete}
                dateValue={filterOptions.date.value}
                isDateRange={true}
                fromDate={filterOptions.date.FromDate}
                toDate={filterOptions.date.ToDate}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      FromDate: value,
                      value:{ name: "Custom" },
                    },
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      ToDate: value,
                      value:{ name: "Custom" },
                    },
                  })
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete 
                freeSolo
                placeholder = {t("User")}
                filterName = {t("User")}
                loading = {isLoadingUser}
                options = {filterOptions.userList ? filterOptions.userList : []}
                getOptionLabel={(option) => option.customer_name || ""}
                name = "UserObj"
                onInputChange={(event, value, reason) => { 
                  // if (reason === "input") {
                  //   handleSearchUser(event,value);
                  // }
                  if(reason === "clear"){
                    setFilterOptions({ ...filterOptions, UserObj: {} });
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "UserObj")}
                value={filterOptions.UserObj}
                
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete 
                freeSolo
                placeholder = {t("Supplier")}
                filterName = {t("Supplier")}
                loading = {isLoadingSupplier}
                options = {filterOptions.SupplierList ? filterOptions.SupplierList : []}
                getOptionLabel={(option) => option.PartyName || ""}
                name = "SupplierObj"
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    (option.PartyName && option.PartyName.toLowerCase().includes(inputValue.toLowerCase())) ||
                    (option.PartyCode && option.PartyCode.toLowerCase().includes(inputValue.toLowerCase()))
                  )
                }
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.PartyCode}</Box>
                    <Box sx={{fontSize: "14px",}}>{option.PartyName}</Box>
                  </Box>
                )}
                onInputChange={(event, value, reason) => { 
                  if (reason === "input") {
                    handleSearchParty(event,value);
                  }
                  if(reason === "clear"){
                    setFilterOptions({ ...filterOptions, SupplierObj: {} });
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "SupplierObj",)}
                value={filterOptions.SupplierObj}
                
              />
            </ListItem>
            <Divider />
            {isAdmin === true && EnableBranch && BranchID === 1 && (
              <>
              <ListItem>
                <ViknAutoComplete
                  disabled={filterOptions.IsSelectedAllBranch}
                  multiple
                  value={filterOptions.SelectedBranches}
                  placeholder={"Select Branches"}
                  options={filterOptions.BranchList}
                  getOptionLabel={(option) => option.NickName}
                  onChange={(e, newValue) =>
                    handleAutoComplete(e, newValue, "SelectedBranches")
                  }
                />
              </ListItem>
            <Divider />
            </>
            )}
            <ListItem>
              <VBInputField 
                label = {t("Reff Bill No")}
                onChange = {handleReffBillchange}
                value = {filterOptions.reffBillNo}
                onInputChange = {(event, value, reason) => { 
                  if(reason === "clear"){
                    setFilterOptions({ 
                      ...filterOptions, 
                      reffBillNo: '' ,
                    });
                  }
                }}
              />
            </ListItem>
            <Divider />
            <ListItem >
              <ViknRadioGroup 
                radios={
                  filterOptions.PurchaseList.map((i) => (
                    {label: i.label, value: i.name,}
                  ))
                }
                handleChange={handlechange}
                value = {filterOptions.PurchaseType}
                valueName = {"PurchaseType"}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknRadioGroup 
                radios = {
                  filterOptions.filterList.map((i) => (
                    {label: i.label, value: i.name}
                  ))
                }
                handleChange = {handlechange}
                value = {filterOptions.filterValue}
                valueName = {"filterValue"}
              />
            </ListItem>
            <Divider />
            <ListItem >
              <ViknCheckboxGroup 
              radios={
                [{
                  label : t("Without Date"),
                  checked: filterOptions.WithoutDateOption.WithoutDate.isActive,
                  onChange: () => handleChangeCheckbox(),
                }]
              }
              />
            </ListItem>
          </List>
        }
      />
    </>
  )
}

export default PurchaseReport

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap:"22px",
  },
}));
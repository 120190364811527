import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default function VBTextField(props) {
  let {
    handleChanges,
    name,
    value,
    index,
    textFieldRef,
    autoFocus,
    numeric,
    disabled,
  } = props;
  return (
    <TextField
      disabled={disabled ? true : false}
      type={numeric ? "number" : "text"}
      className="VBTextField"
      fullWidth
      label=""
      // id="fullWidth"
      name={name}
      value={value}
      onChange={(e) => {
        handleChanges(e, index);
      }}
      style={{ borderRadius: "4px", background: "#F3F6F9",color:"black" }}
      sx={{
        fontSize: "13px",
        border: "1px solid rgba(224, 224, 224, 1)",
        borderRadius: "4px",
        height: "40px",
        color:"black",
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "#000000",
          "& .MuiInputBase-root":{
            backgroundColor:"#F3F6F9"
          }
      },
      }}
      focused
      autoFocus={autoFocus}
      ref={textFieldRef}
      InputProps={{
        inputProps: {
          style: {
            // padding: 0,
            //  fontSize: "13px",
            //   paddingLeft: "10px",

            padding: "9.5px 13px",
          },
        },
      }}
    />
  );
}

import axios from "axios";
import { openSnackbar } from "../../features/SnackBarslice";
import { download_excel } from "../../Components/ReportTable/ReportTable";
import { store } from "../../app/store";

const state = store.getState();
const { PriceDecimalPoint } = state.generalSettingsSclice.generalSettings;

// Day book API
const callDayBook = async function (body,dispatch) {
  try {
    const { data } = await axios.post("api/v10/ledgerPosting/day-book/", {
      
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in callDayBook ");
    dispatch(
      openSnackbar({
        open : true,
        message : error.response?.data?.message || 'Error Occurred In API',
        severity : 'error',
      })
    )
  }
};

// Cash Book API
const CallCashBook = async function (body,dispatch) {
  try {
    const { data } = await axios.post("api/v10/ledgerPosting/cash-book/", {
       
      PriceRounding: Number(PriceDecimalPoint),
      ...body,
    });
    return data;
  } catch (error) {
    console.log(error,"error in CallCashBook");
    console.log("error in CallCashBook");
    dispatch(
      openSnackbar({
        open : true,
        message : error.response?.data?.message || 'Error Occurred In API',
        severity : 'error',
      })
    )
  }
};

// Bank Book API
const CallBankBook = async function (body,dispatch) {
  try {
    const { data } = await axios.post("api/v11/Bank/bank-book/",{
       
      PriceRounding: Number(PriceDecimalPoint),
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in CallBankBook");
    dispatch(
      openSnackbar({
        open : true,
        message : error.response?.data?.message || 'Error Occurred In API',
        severity : 'error',
      })
    )
  }
};

// Bank Statment API
const CallBankStatment = async function (body,dispatch) {
  try {
    const { data } = await axios.post("api/v11/Bank/bank-statement/",{
       
      PriceRounding: Number(PriceDecimalPoint),
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in CallBankBook");
    dispatch(
      openSnackbar({
        open : true,
        message : error.response?.data?.message || 'Error Occurred In API',
        severity : 'error',
      })
    )
  }
};

// Bank Reconciation Statment API
const CallBankReconciliation = async function (body,dispatch) {
  try {
    const { data } = await axios.post("api/v11/Bank/bank-Reconsilation/",{
       
      PriceRounding: Number(PriceDecimalPoint),
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in CallBankBook");
    dispatch(
      openSnackbar({
        open : true,
        message : error.response?.data?.message || 'Error Occurred In API',
        severity : 'error',
      })
    )
  }
};

// Expeence Report API
const CallExpenseReport = async function (body,dispatch) {
  try {
    const { data } = await axios.post(
      "api/v11/expense/expense-report-summery/",{
         
        
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallExpenseReport");
    dispatch(
      openSnackbar({
        open : true,
        message : error.response?.data?.message || 'Error Occurred In API',
        severity : 'error',
      })
    )
  }
};
// Expeence Report API
const CallExpenseReportDetails = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/expense/expense-report/",{
         
        
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallExpenseReport");
  }
};

// Reciept Report API
const CallRecieptReport = async function (body,dispatch) {
  try {
    const { data } = await axios.post(
      "api/v10/receipts/report-receipts/", {

        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallRecieptReport",error);
    dispatch(
      openSnackbar({
        open : true,
        message : error.response?.data?.message || 'Error Occurred In API',
        severity : 'error',
      })
    )
  }
  
};

// Payment Report API
const CallPaymentReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/payments/report-payments/", {
        
        
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallPaymentReport");
  }
};

// Ledger Report API
const CallLedgerReport = async function (body) {
  try {
    const { data } = await axios.post("api/v11/reports/ledger-report/", 
    {
      
      
      
      ...body,
    }
    );
    return data;
  } catch (error) {
    console.log("error in CallLedgerReport");
  }
};

// Daily Report API
const CallDailyReportCard = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/sales/daily-report-cards/",
      {
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallDailyReportCard");
  }
};

// Daily Report API
const CallDailyReportStatistic = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/sales/daily-report-statistic/",
      body
    );
    return data;
  } catch (error) {
    console.log("error in CallDailyReportCard ");
  }
};

// Daily Report API
const CallDailyReportTopItems = async function (body) {
  try {
    const { data } = await axios.post(
      "/api/v10/sales/daily-report-topItems/",
      {
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallDailyReportTopItems ");
  }
};

// Billwise Profit Report
const CallBillwiseProfitReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/sales/bill-wise-profit/report/",{
      
      
      ...body,
      });
    return data;
  } catch (error) {
    console.log("error in CallBillwiseProfitReport ");
  }
};

// Bank Reconciliation Report API
const CallBankListReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/bankReconciliationStatement/bank-list/",
      body
    );
    return data;
  } catch (error) {
    console.log("error in CallBankListReport ");
  }
};

// Bank Reconciliation Report API
const CallBankReconcilationReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/bankReconciliationStatement/bank-reconsiliation-report/",
      body
    );
    return data;
  } catch (error) {
    console.log("error in CallBankReconcilationReport ");
  }
};

// BillWise Report API
const CallBillwiseReport = async function (body) {
  try {
    const { data } = await axios.post("api/v10/sales/bill-wise/report/",{
      
      
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in CallBillwiseReport ");
  }
};

// Faera Report API
const CallFaeraReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/pos_apis/faera-report-list/",
      {
         
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallFaeraReport ");
  }
};

// Faera Report API Single
const CallFaeraReportSingle = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/pos_apis/faera-report-single/",
      {
         
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallFaeraReport ");
  }
};

// QuickReport Report API
const CallQuickReport = async function (body) {
  try {
    const { data } = await axios.post("api/v10/sales/quick/report/", 
    {
      
      ...body,
    }
    );
    return data;
  } catch (error) {
    console.log("error in CallQuickReport ");
  }
};

// Debtor Ageing report API
const CallDebtorAgeingReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/ageingReport/debtor/ageing-report/",
      {
         
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallDebtorAgeingReport ");
  }
};

// Creditor Ageing report API
const CallCreditorAgeingReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/ageingReport/creditor/ageing-report/",
      {
         
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallCreditorAgeingReport ");
  }
};

//  DebitNote API 
const CallDebitNote = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/reports/debitNoteReport/",
      {
        
        ...body,
      }
    )
    return data;
  } catch (error) {
    console.log('error in CallDebitNoteReport');
  }
}

// CreditNote API
const CallCreditNote = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/reports/creditNoteReport/",
      {
        
        ...body,
      }
    )
    return data;
  } catch (error) {
    console.log('error in CallDebitNoteReport');
  }
}

const CallVbReportList = async function(body) {
  try {
    const { data } = await axios.post(
      "api/v11/vb_assist/report-list/",
      {
        ...body,
      }
    )
    return data;
  } catch (error) {
    console.log('error in CallVbReportList');
  }
}

const CallVbListItem = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/vb_assist/list_item/",
      {
        ...body,
      }
    )
    return data;
  } catch (error) {
    console.log("error in CallVbListItem");
  }
}

const CallVbMailCreate = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/vb_assist/update-status/",
      {
        ...body,
      }
    )
    return data;
  } catch (error) {
    console.log("error in CallVbMailCreate");
  }
}

const CallUpdateVbassist = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/vb_assist/update_vbassist/",
      {
        ...body,
      }
    )
    return data;
  } catch (error) {
    console.log("error in CallUpdateVbassist");
  }
}

export {
  callDayBook,
  CallCashBook,
  CallBankBook,
  CallExpenseReport,
  CallRecieptReport,
  CallPaymentReport,
  CallLedgerReport,
  CallDailyReportCard,
  CallDailyReportStatistic,
  CallDailyReportTopItems,
  CallBillwiseProfitReport,
  CallBankReconcilationReport,
  CallBankListReport,
  CallBillwiseReport,
  CallFaeraReport,
  CallQuickReport,
  CallDebtorAgeingReport,
  CallCreditorAgeingReport,
  CallExpenseReportDetails,
  CallFaeraReportSingle,
  CallBankStatment,
  CallBankReconciliation,
  CallDebitNote,
  CallCreditNote,
  CallVbReportList,
  CallVbListItem,
  CallVbMailCreate,
  CallUpdateVbassist,
};

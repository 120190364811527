import moment from "moment";
import React, { useEffect, useState } from "react";
import { date_range } from "../../../../Function/comonFunction";
import {
  Box,
  Divider,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import SearchReport from "../../../../Components/Utils/SearchReport";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import PrintBtn from "../../../../Components/Utils/PrintBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import FilterLable from "../../../../Components/Utils/FilterLable";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
  VBTableRowNull,
} from "../../../../Components/ReportTable/ReportTable";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { CallSalesIntegratedReport, callInteratedReport } from "../../../../Api/Reports/SalesApi";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import { CallParties, CallRoutes, CallUsers } from "../../../../Api/Reports/CommonReportApi";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ViknRadioGroup from "../../../../Components/Utils/ViknRadioGroup";
import { CallBillwiseReport } from "../../../../Api/Reports/AccountApi";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { formatNumber } from "../../../../generalFunction";
import { VIKNBOOKS_FRONT_URL } from "../../../../generalVeriable";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { UserRolePermission } from "../../../../Function/Editions";

const BillwiseReport = () => {
  let Export_view = UserRolePermission("Bill Wise Report", "print_permission");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [Loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const [reportData, setReportData] = useState([]);
  const [isLoadingRoutes, setIsLoadingRoutes] = useState(false)
  const [routeName, setRouteName] = useState("")
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,EnableRoute} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [t, i18n] = useTranslation("common");
  const [isLoadingParty, setisLoadingParty] = useState(false)
  const [page_no, setPage_no] = useState(1);
  const [filterOptions, setFilterOptions] = useState({

    RouteList : [],
    routeObj : [],

    partyList : [],
    partyObj : {PartyName :''},

    filtertype : 'customer',
    filtertypes : [
      {name : t('Customer'),value : 'customer'},
      EnableRoute ? { name: t('Route'), value: 'route' } : null,
    ],

    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment()
    },
  });

  //Handle functions

  const closeFilter = () => setOpenFilter(false);

  const handleAutoComplete = function (e, value,name) {
    console.log(value);
    setFilterOptions({...filterOptions,[name]:value})
  }

  const handleFilterList = () => {
    let list = [];
    let filterdata = filterOptions
    console.log(filterdata,'>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    if (filterdata.date.FromDate &&filterdata.date.ToDate ) {
      let date = String(filterOptions.date.FromDate.format("DD-MM-YYYY") + " to " + filterOptions.date.ToDate.format("DD-MM-YYYY"))
      list.push({ name: t("Date"), value: date });
    }
    if (filterdata.partyObj && filterdata.partyObj.PartyName !== '' && filterdata.partyObj.PartyName !== undefined) {
      list.push({
        name : t('Customer'),
        value : filterdata.partyObj.PartyName,
        handleDelete: () => handleDeletefilter('partyObj')
      })
    }
    if (filterdata.routeObj && filterdata.routeObj.RouteName !== undefined && filterdata.routeObj.RouteName !== ''){
      list.push({
        name : t('Route'),
        value : filterdata.routeObj.RouteName,
        handleDelete : () => handleDeletefilter('routeObj')
      })
    }
    return list
  }
  const handlechange = (e, name) => {
    let { value } = e.target
    setFilterOptions({
      ...filterOptions,[name]: value,
      routeObj:null,
      partyObj:null,
    
    })
    
  }
  // API Fetch data
  const fetchReport = async function () {
    setLoading(true)
    const data = await CallBillwiseReport({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
      RouteID : filterOptions?.routeObj?.RouteID ? filterOptions.routeObj.RouteID : 0,
      CustomerID : filterOptions?.partyObj?.PartyID ? filterOptions.partyObj.PartyID : 0
    });
    console.log(data);
    if (data?.StatusCode === 6000) {
      setReportData(data);
      // setPage()
    } else if (data?.StatusCode === 6001){
      setReportData();
      dispatch(
        openSnackbar({
          open : true,
          message : data.message,
          severity : 'warning',
        })
      )
    } else if (data?.StatusCode === 6002){
      dispatch(
        openSnackbar({
          open : true,
          message : data.message,
          severity : 'error',
        })
      )
    }
    setLoading(false)
  };
  const searchReport = function () {
    fetchReport();
  };
    // handle search parties 
    // const handleSearchParty = async (e,value) => {
    //   if (value) {
    //     setisLoadingParty(true);
    //     const partyResponse = await CallParties({
    //       PartyType : 1,
    //       search : value
    //     })
    //     let partyList = [];
    //     if (partyResponse.StatusCode === 6000){
    //       partyList = partyResponse.data
    //     }
    //     setFilterOptions({...filterOptions,partyList})
    //     setisLoadingParty(false);
    //   } else {
    //     setFilterOptions({...filterOptions,partyObj: {PartyName : ''}});
    //   }
    // }
  const handleDefaultFilter = async () => {
    setFilterOptions({
      ...filterOptions,
      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Today" },
        FromDate: moment(),
        ToDate: moment(),
      },
      routeObj : [],
  
      partyObj : {PartyName :''},
  
      filtertype : 'customer',
      filtertypes : [
        {name : 'Customer',value : 'customer'},
        {name : 'Route' , value:'route'},
      ],
    })
  }

  const handleDeletefilter = (name, index) => {
      setFilterOptions((prevState) => ({ ...prevState, [name]: '' }));
  }
  const filterApis = async function () {
    const PartyResponse = await CallParties({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,
      PartyType:1,
    })
    let partyList = [];
    if (PartyResponse?.StatusCode === 6000){
      partyList = PartyResponse?.data
    }
    setFilterOptions({
      ...filterOptions,
      partyList,
    })
  }

  const fetchRoutes = async function() {
    setIsLoadingRoutes(true)
    const routeResponse = await CallRoutes({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,
      items_per_page: 10,
      page_no: 1,
      search: routeName,
    })
    let RouteList = [];
    if (routeResponse?.StatusCode === 6000){
      RouteList = routeResponse?.data
      setIsLoadingRoutes(false);
    }
    setFilterOptions({
      ...filterOptions,
      RouteList,
    })
  }

  const getDataForPage = () => {
    const startIndex = (page_no -1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData?.data?.slice(0,reportData?.data?.length -1)
    return alldata?.slice(startIndex, endIndex) || [];
  }

  useEffect(() => {
    filterApis();
  }, []);
  const handleTableRowClick = (data) => {
    if (data && data.id && data.id !=='') {
      const destinationURL = `${VIKNBOOKS_FRONT_URL}dashboard/sale-invoice-single?id=${data.id}`;
      window.open(destinationURL, '_blank');
    } else {
      console.log('not acccess this beacuse id = null');
    }

  };

  useEffect(() => {
    fetchRoutes()
  }, [routeName])


  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className='left'>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t('Billwise Report')}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className='right'>
            {Export_view ? 
            <ReportDownload
              download_type={"PDF"}
              invoice_type={"billwise_report"}
              FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
              ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
              CustomerID={filterOptions?.partyObj?.PartyID ? filterOptions.partyObj.PartyID : 0}
              RouteID={filterOptions?.routeObj?.RouteID ? filterOptions.routeObj.RouteID : 0}
            />
            : null}
            {/* <PrintBtn /> */}
            <Pagenation 
              totalItem={reportData?.data?.length}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
        <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
          {t("Applied Filters: ")}
        </div>
        {handleFilterList(filterOptions).map((val, i) => (
          <FilterLable
            name={val.name}
            value={val.value}
            handleDelete={val.handleDelete?val.handleDelete:null}
          />
        ))}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t('Serial No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Type')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Date')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Invoice No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('P.O.#')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Terms')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Due Date')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Class')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Aging')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Open Balance')}</VBTableCellHeader>
          </VBTableHeader>
          {Loading ? 
          <VBSkeletonLoader/> :
          <TableBody>
            {getDataForPage().map((data, i) => (
              <TableRow
              onClick={() =>
                handleTableRowClick(data, data.VoucherType, data.VoucherNo)
              }
              >
                <VBTableCellBody>{data.index}</VBTableCellBody>
                <VBTableCellBody sx={data.InvoiceNo === "" ? { fontSize: '13.2px', fontWeight: 500,cursor:"pointer" } : (data.InvoiceNo === "-" ? { fontSize: '13.2px' ,color :'green',cursor:"pointer" } : {})}>{data.Type}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.Date}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.InvoiceNo}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.PO}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.Terms}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.DueDate}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.Class}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.aging}</VBTableCellBody>
                <VBTableCellBody sx={data.InvoiceNo === '-' ? {color:'green',textAlign: 'right',cursor:"pointer"}:{textAlign:'right',cursor:"pointer"}}>{formatNumber(data.OpenBalance)}</VBTableCellBody>
              </TableRow>
            ))}
          {/* <VBTableRowNull length = {reportData?.new_data?.length}/> */}
          <TableRow></TableRow>
          </TableBody>
          }
          <VBTableFooter>
            <TableRow>
            <VBTableCellFooter >{t("Total")}</VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData?.data && reportData?.data[reportData.data.length - 1]?.OpenBalance) ?? 0 )}</VBTableCellFooter>
            </TableRow>
          </VBTableFooter>
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={searchReport}
        handleDefaultFilter={()=>handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            {/* <ListItem>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Filter Name
                </InputLabel>
                <VBInputField />
              </FormControl>
            </ListItem> */}


            <ListItem>
              <VBDatePiker 
                  fromDate={filterOptions.date.FromDate}
                  toDate={filterOptions.date.ToDate}
                  fromOnChange={(value) => 
                    setFilterOptions({
                      ...filterOptions,
                      date:{
                        ...filterOptions.date,
                        FromDate: value
                      },
                    })
                  }
                  toOnChange={(value) => 
                    setFilterOptions({
                      ...filterOptions,
                      date:{
                        ...filterOptions.date,
                        ToDate: value
                      }
                    })
                  }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknRadioGroup 
                radios={filterOptions.filtertypes
                  .filter(item => item !== null) 
                  .map(item => ({
                    label: item?.name,
                    value: item?.value,
                  }))
                }
                handleChange={(e) => handlechange(e, 'filtertype')}
                value = {filterOptions.filtertype}
                valueName = {'filtertype'}
              />
            </ListItem>
            {filterOptions.filtertype === 'customer' &&
            <ListItem>
              {/* <ViknSearchAutoComplete 
                freeSolo
                placeholder = {t("Customer")}
                filterName={t("Customer")}
                loading={isLoadingParty}
                options={filterOptions.partyList ? filterOptions.partyList : []}
                getOptionLabel={(option) => option.PartyName || ""}
                name="partyObj"
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    handleSearchParty(event, value);
                  }
                  if (reason === "clear") {
                    handleSearchParty();
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "partyObj")}
                value={filterOptions.partyObj}
              /> */}
              <ViknAutoComplete 
                placeholder = {t('Customer')}
                options = {filterOptions?.partyList ? filterOptions.partyList : []}
                getOptionLabel = {(option) => option.PartyName || '' }
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    (option.PartyName && option.PartyName.toLowerCase().includes(inputValue.toLowerCase())) ||
                    (option.PartyCode && option.PartyCode.toLowerCase().includes(inputValue.toLowerCase()))
                  )
                }
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.PartyCode}</Box>
                    <Box sx={{fontSize: "14px",}}>{option.PartyName}</Box>
                  </Box>
                )}
                value = {filterOptions.partyObj}
                onChange = {(e,v) => handleAutoComplete(e,v,'partyObj')}
              />
            </ListItem>
            }
            {filterOptions.filtertype === 'route' && EnableRoute &&
            <ListItem>
              <ViknSearchAutoComplete 
                freeSolo
                placeholder = {t('Route')}
                loading={isLoadingRoutes}
                options = {filterOptions?.RouteList ? filterOptions.RouteList : []}
                getOptionLabel = {(option) => option.RouteName || '' }
                value = {filterOptions.routeObj}
                onChange={(e, v) => handleAutoComplete(e, v, "routeObj")}
                onInputChange={(event, value, reason) => {
                  console.log(value, reason);
                  if (reason === "input") {
                    setRouteName(value);
                  }
                  if (reason === "clear") {
                    setFilterOptions({ ...filterOptions, ledgerName: null });
                    setRouteName("");
                  }
                }}
              />
            </ListItem>
            }
            <Divider />
          </List>
        }
      />
    </>
  );
};

export default BillwiseReport;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap:"22px",
  },
}));

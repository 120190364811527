import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import {
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
} from "../../../../../Components/ReportTable/ReportTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatNumber } from "../../../../../generalFunction";
import { t } from "i18next";

const ExpandTable = function (props) {
  // const [expanded, setExpanded] = useState("panel" + props.index +1)
  const [t] = useTranslation("common");

  return (
    <VBTableContainer>
      <TableBody>
      <VBTableCellBody
        // colspan="4"
        sx={{ padding: 0, paddingBottom: "10px"}}

      >
    <Accordion
      // expanded={props.expanded === `panel${props.index+1}`}
      expanded={props.expanded.includes(`panel${props.index + 1}`)}
      onChange={props.handleChange(`panel${props.index + 1}`)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          pl: 0,
          minHeight: "auto",
          backgroundColor: "#e4ebf3",
          "&.MuiAccordionSummary-root": {
            minHeight: "10px", // Adjust the minHeight as needed
          },
          ".css-o4b71y-MuiAccordionSummary-content.Mui-expanded": {
            my: "0px",
          },
          ".MuiAccordionSummary-content": {
            my: 0,
          },
          //   '.Mui-expanded .MuiAccordionSummary-content': {
          //     // Customize the margin only when expanded
          //     my: 0,
          //   },
        }}
      >
        {/* {props.name} */}
        <Table sx={{ width: "100%" }}>
          <TableBody>
             <TableRow>
               <VBTableCellBody sx={{width: "20%", borderBottom: "none" }} >{t(props.name)} {props.listdata.length || 0}</VBTableCellBody>
               <VBTableCellBody sx={{ borderBottom: "none" }} >{t("Cash")} : {(props.total.cash_amount || 0).toFixed(props.PriceRounding)}</VBTableCellBody>
               <VBTableCellBody sx={{ borderBottom: "none" }} >{t("Bank")} : {(props.total.bank_amount || 0).toFixed(props.PriceRounding)}</VBTableCellBody>
               <VBTableCellBody sx={{ borderBottom: "none" }} >{t("Credit")} : {props.total.credit_sales?(props.total.credit_sales).toFixed(props.PriceRounding):0.00}</VBTableCellBody>
               <VBTableCellBody sx={{ borderBottom: "none" }} >{t("Total")} : {((props.total.cash_amount) || 0).toFixed(props.PriceRounding) || 0}</VBTableCellBody>
             </TableRow>
           </TableBody>
        </Table>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("SI NO")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher No")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher Date")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Created Date")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Customer")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Net Amount")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Discount")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Amount")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Notes")} </VBTableCellHeader>
          </VBTableHeader>
          <TableBody>
            {props.listdata.map((e, i) => (
            <TableRow>
              <VBTableCellBody > {i + 1} </VBTableCellBody>
              <VBTableCellBody > {e.VoucherNo} </VBTableCellBody>
              <VBTableCellBody > {e.Date} </VBTableCellBody>
              <VBTableCellBody > {e.CreatedDate} </VBTableCellBody>
              <VBTableCellBody > {e.CustomerName} </VBTableCellBody>
              <VBTableCellBody > {formatNumber(e.NetTotal)} </VBTableCellBody>
              <VBTableCellBody > {formatNumber(e.TotalDiscount)} </VBTableCellBody>
              <VBTableCellBody > {formatNumber(e.GrandTotal)} </VBTableCellBody>
              <VBTableCellBody > {e.note ? e.note : "-"} </VBTableCellBody>
            </TableRow>
            ))}
          </TableBody>
        </VBTableContainer>
      </AccordionDetails>
    </Accordion>
    </VBTableCellBody>
    </TableBody>
    </VBTableContainer>
  );
};

const DetailsTable = ({ filterData, expanded, handleChangeExpand }) => {
  const [t] = useTranslation("common");
  const {CurrencySymbol,CountryName } = useSelector((state) => state.companyDetails);
  // console.log(CurrencySymbol,"CurrencySymbol",CountryName,CountryName);
  // console.log(filterData, "12@@@@@@@@@@@@@@@@@@@@@@@@@################$$$$$$$$$$%%%%%%%%%%");
  return (
    <Box>
      <Box sx={{display: 'flex', justifyContent: "space-between", p: 2}}>
        <Typography>{t("Total Received")} {"  "}
          <span style={{color: "#bfbfbf", fontWeight: "bold"}}>{CurrencySymbol ? CurrencySymbol : "   "}: {formatNumber(filterData.total_received)}</span>
        </Typography>
        <Box sx={{display: 'flex', justifyContent: "space-between"}}>
          <Typography sx={{ mr: 1}}>{t("Total Cash")} {"  "}
          <span style={{color: "#bfbfbf", fontWeight: "bold"}}>{CurrencySymbol ? CurrencySymbol : "   "}: {formatNumber(filterData.total_cash)}</span></Typography>
          <Typography>{t("Total Bank")} {"  "}
          <span style={{color: "#bfbfbf", fontWeight: "bold"}}>{CurrencySymbol ? CurrencySymbol : "   "}: {formatNumber(filterData.total_bank)}</span></Typography>
        </Box>
      </Box>
      <ExpandTable
        expanded={expanded}
        handleChange={handleChangeExpand}
        index={1}
        name={"Sales Invoice"}
        total={filterData.sales_total}
        listdata={filterData.sales_data}
      />

      <ExpandTable
        expanded={expanded}
        handleChange={handleChangeExpand}
        index={2}
        name={"Sales Return"}
        total={filterData.return_total}
        listdata={filterData.return_data}
      />

      <ExpandTable
        expanded={expanded}
        handleChange={handleChangeExpand}
        index={3}
        name={"Sales Order"}
        total={filterData.order_total}
        listdata={filterData.order_data}
      />

      <ExpandTable
        expanded={expanded}
        handleChange={handleChangeExpand}
        index={4}
        name={"Receipt"}
        total={filterData.reciept_total}
        listdata={filterData.reciept_data}
      />

      <ExpandTable
        expanded={expanded}
        handleChange={handleChangeExpand}
        index={5}
        name={"Payment"}
        total={filterData.payment_total}
        listdata={filterData.payment_data}
      />
      <ExpandTable
        expanded={expanded}
        handleChange={handleChangeExpand}
        index={6}
        name={"Expenses"}
        total={filterData.expense_total}
        listdata={filterData.expense_data}
      />
    </Box>
  );
};

export default DetailsTable;

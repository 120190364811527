import axios from "axios";
import { openSnackbar } from "../../../features/SnackBarslice";

export const create_stock_management = async function (body) {

    try {
      const { data } = await axios.post("api/v11/stockManagements/create-stockManagements/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on get_VoucherNo API", error);
      return error
    }
  };
  
  
  export const update_stock_management = async function (body) {

    try {
      const { data } = await axios.put("api/v11/stockManagements/create-stockManagements/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on get_VoucherNo API", error);
      return error
    }
  };
  
  
  
  export const list_stock_management = async function (body) {

    try {
      const { data } = await axios.get("api/v11/stockManagements/list-stockManagements/", {
        params:body,
      });
      return data;
    } catch (error) {
      console.log("error on get_VoucherNo API", error);
      return error
    }
  };
  
  export const delete_stock_management = async function (body) {

    try {
      const { data } = await axios.delete("api/v11/stockManagements/delete-stockManagements/", {
        data:body,
      });
      return data;
    } catch (error) {
      console.log("error on get_VoucherNo API", error);
      return error
    }
  };
  
  
  //import_products
  
  
  export const import_products = async function (body) {

    try {
      const { data } = await axios.post("api/v11/products/import-products/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on get_VoucherNo API", error);
      return error
    }
  };
import React from 'react'
import { VBTableCellBody, VBTableCellFooter, VBTableCellHeader, VBTableContainer, VBTableFooter, VBTableHeader } from '../../../../../Components/ReportTable/ReportTable'
import { TableBody, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../../../generalFunction';
import { goToInvoice } from '../../../../../Function/comonFunction';

const DetailsTable = ({ data, isLoading,EnableBranch }) => {
  const [t] = useTranslation("common");
  console.log(data);
  return (
    <VBTableContainer>
    <VBTableHeader>
      {EnableBranch && (
      <VBTableCellHeader sx={{ borderRight: "1px solid #c7c7c7"}} >{t("Branch Name")}</VBTableCellHeader>
      )}
      <VBTableCellHeader sx={{ borderRight: "1px solid #c7c7c7"}} >{t("Voucher No")}</VBTableCellHeader>
      <VBTableCellHeader sx={{ borderRight: "1px solid #c7c7c7"}} >{t("Voucher Date")}</VBTableCellHeader>
      <VBTableCellHeader sx={{ borderRight: "1px solid #c7c7c7"}} >{t("Ledger")}</VBTableCellHeader>
      <VBTableCellHeader sx={{ borderRight: "1px solid #c7c7c7"}} >{t("VAT No")}</VBTableCellHeader>
      <VBTableCellHeader sx={{ borderRight: "1px solid #c7c7c7"}} >{t("Ref. No")}</VBTableCellHeader>
      <VBTableCellHeader sx={{ borderRight: "1px solid #c7c7c7"}} >{t("Ref. Date")}</VBTableCellHeader>
      <VBTableCellHeader sx={{ borderRight: "1px solid #c7c7c7"}} >{t("Tax Type")}</VBTableCellHeader>
      <VBTableCellHeader sx={{ borderRight: "1px solid #c7c7c7"}} >{t("Taxable")}</VBTableCellHeader>
      <VBTableCellHeader sx={{ borderRight: "1px solid #c7c7c7"}} >{t("Non Taxable")}</VBTableCellHeader>
      <VBTableCellHeader sx={{ borderRight: "1px solid #c7c7c7"}} >{t("Tax Amount")}</VBTableCellHeader>
      <VBTableCellHeader >{t("Net Amount")}</VBTableCellHeader>

    </VBTableHeader>
    <TableBody>
      {data.sales_data.map((i, e) =>  
      <TableRow onClick={() => { goToInvoice(i.id, i.VoucherType ) }} key={e+1}>
        {EnableBranch && (
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6"}}> {i.BranchName ?? "-"}</VBTableCellBody>
        )}
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6"}}> {i.VoucherNo ?? "-"}</VBTableCellBody>
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6"}}> {i.VoucherDate ?? "-"}</VBTableCellBody>
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6"}}> {i.Ledger ?? "-"}</VBTableCellBody>
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6"}}> {i.VatNo ?? "-"}</VBTableCellBody>
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6"}}> {i.RefNo ?? "-"}</VBTableCellBody>
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6"}}> {i.RefDate ?? "-"}</VBTableCellBody>
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6"}}> {i.TaxType ?? "-"}</VBTableCellBody>
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6"}}> {formatNumber(i.TaxableAmount) ?? "-"}</VBTableCellBody>
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6"}}> {formatNumber(i.NonTaxableAmount) ?? "-"}</VBTableCellBody>
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6"}}> {formatNumber(i.TaxAmount) ?? "-"}</VBTableCellBody>
        <VBTableCellBody> {formatNumber(i.NetAmount) ?? "-"}</VBTableCellBody>

      </TableRow>)}
      <TableRow></TableRow>
    </TableBody>
    <VBTableFooter >
      <TableRow>
        <VBTableCellFooter >{t('Total')}</VBTableCellFooter>
        <VBTableCellFooter ></VBTableCellFooter>
        <VBTableCellFooter ></VBTableCellFooter>
        <VBTableCellFooter ></VBTableCellFooter>
        <VBTableCellFooter ></VBTableCellFooter>
        <VBTableCellFooter ></VBTableCellFooter>
        <VBTableCellFooter ></VBTableCellFooter>
        {EnableBranch && (
        <VBTableCellFooter ></VBTableCellFooter>
        )}
        <VBTableCellFooter >{formatNumber(data.TaxableAmount_total)}</VBTableCellFooter>
        <VBTableCellFooter >{formatNumber(data.NonTaxableAmount_total)}</VBTableCellFooter>
        <VBTableCellFooter >{formatNumber(data.TaxAmount_total)}</VBTableCellFooter>
        <VBTableCellFooter >{formatNumber(data.NetAmount_total)}</VBTableCellFooter>
      </TableRow>
    </VBTableFooter>
    </VBTableContainer>
  )
}

export default DetailsTable
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, BASE_V11_URL, MEDIA_URL, URL } from "../../generalVeriable";
// import styled from "styled-components";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import CachedIcon from '@mui/icons-material/Cached';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import axios from 'axios';
import ActionButton from "./ActionButton";
// import { openSnackbar } from "../../../../features/SnackBarslice";
import { download_excel } from "../ReportTable/ReportTable";
import ExportMenu from "./ExportMenu";
import { store } from "../../app/store";
import { handleCashLedgers } from "../../Function/comonFunction";

// import { message } from "antd";

const ReportDownload = (props) => {
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id,access } = useSelector((state) => state.user);
  const { language} = useSelector((state) => state.language);
  const [t, i18n] = useTranslation("common");
  const dispatch = useDispatch()
  console.log(props.CustomerID);
  const [showLoader, setLoader] = useState(false);

  const [is_celery_loader, setCelery_Loader] = useState(false);
  const [progress_id, setProgress_id] = useState(null);
  const [file_path, setFilePath] = useState(null);

  const state = store.getState();
  const PriceRounding = Number(PriceDecimalPoint);
  const lang  = language
  // useEffect(() => {
  //   setTimeout(() => {
  //     props.setPrint(false);
  //   }, 10000);
  // }, []);
  const invoice_type = "sales_invoice";
  let columns = "";
  if (props.columns) {
    columns = JSON.stringify(props.columns);
  }
  let VoucherType = props.VoucherType;

  if (!VoucherType) {
    VoucherType = props.VoucherType;
  }

  const ExportTo = async (download_type, Voucher, is_celery) => {
    let RouteLedgers = null
    if(props?.handleRouteList){
      RouteLedgers = await props.handleRouteList()
      RouteLedgers =`[${RouteLedgers}]`
      console.log(RouteLedgers,"RouteLedgers");
      // if ( RouteLedgers == [0]){
      // }
    }else if (props?.isCashLedgers){
      RouteLedgers = await handleCashLedgers(props.filterOptions)
      RouteLedgers =`[${RouteLedgers}]`
      console.log(RouteLedgers,"RouteLedgers");
    }
    // console.log("8787878787878787878788888888888888888888888is_celery8",is_celery);
    if (Voucher) {
      VoucherType = Voucher;
    }
    setLoader(true);
    if (props.is_send_mail === true) {
      alert("report has been send to your email");
    }
    if (is_celery){ // if is celery is passed true then new api report willbe worked other wise old method
      await fetch(
        `${BASE_V11_URL+"reports/download-report/"}?columns=${columns}&ReffNo=${
          props.ReffNo
        }&CompanyID=${CompanyID}&FromDate=${
          props.FromDate ? props.FromDate : ""
        }&ToDate=${
          props.ToDate ? props.ToDate : ""
        }&BranchID=${props.BranchID ? props.BranchID : BranchID}&PriceListID=${props.PriceListID}&UserID=${
          props.UserID
        }&LedgerID=${props.LedgerID}&key=${
          props.tab
        }&download_type=${download_type}&ManualOpeningStock=${
          props.ManualOpeningStock
        }&ManualClosingStock=${props.ManualClosingStock}&EmployeeID=${
          props.EmployeeID
        }&invoice_type=${
          props.invoice_type
        }&PriceRounding=${PriceRounding}&ManualOpeningBalance=${
          props.ManualOpeningBalance
        }&value=${props.value}&VoucherType=${VoucherType}&WarehouseID=${
          props.WarehouseID
        }&WareHouseFrom=${props.WareHouseFrom}&WareHouseTo=${
          props.WareHouseTo
        }&RouteID=${props.RouteID}&ProductFilter=${
          props.ProductFilter
        }&ProductCode=${props.ProductCode}&StockFilter=${props.StockFilter}&ProductID=${props.ProductID}&Barcode=${
          props.Barcode
        }&CategoryID=${props.CategoryID}&BrandID=${props.BrandID}&GroupID=${
          props.GroupID
        }&lang=${lang}&CashLedgers=${props.CashLedgers}&CustomerID=${props.CustomerID}&PartyID=${props.PartyID}&AsOfDate=${props.AsOfDate}&BatchCode=${props.BatchCode}&SerialNo=${props.SerialNo}&ItemCode=${props.ItemCode}&SupplierID=${props.SupplierID}&filterValue=${props.filterValue}&templateType=${props.templateType}&LedgerList=${
          props.LedgerList ? props.LedgerList : []
        }&RouteLedgers=${RouteLedgers?RouteLedgers:props.RouteLedgers ? props.RouteLedgers : []}&is_manualOpening=${props.is_manualOpening}&is_send_mail=${props.is_send_mail}&PartyType=${props.PartyType?props.PartyType:null}&is_all_branch=${props.is_all_branch}&CustomTemplate=${props.CustomTemplate}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        }
      ).then((response) => response.json())
        .then((response) =>{
        // message.loading('Generating Report please wait...',3);
        setProgress_id(response.task_id)
        setFilePath(response.file_id)
        setCelery_Loader(true)
  
        })
        .catch((err) => {
          setLoader(false);
        });
    }else{
    await fetch(
      `${BASE_URL+"reportQuerys/download-report/"}?columns=${columns}&ReffNo=${
        props.ReffNo
      }&CompanyID=${CompanyID}&FromDate=${
        props.FromDate ? props.FromDate : ""
      }&ToDate=${
        props.ToDate ? props.ToDate : ""
      }&BranchID=${props.BranchID ? props.BranchID : BranchID}&PriceListID=${props.PriceListID}&UserID=${
        props.UserID
      }&LedgerID=${props.LedgerID}&key=${
        props.tab
      }&download_type=${download_type}&ManualOpeningStock=${
        props.ManualOpeningStock
      }&ManualClosingStock=${props.ManualClosingStock}&EmployeeID=${
        props.EmployeeID
      }&invoice_type=${
        props.invoice_type
      }&PriceRounding=${PriceRounding}&ManualOpeningBalance=${
        props.ManualOpeningBalance
      }&value=${props.value}&VoucherType=${VoucherType}&WarehouseID=${
        props.WarehouseID
      }&WareHouseFrom=${props.WareHouseFrom}&WareHouseTo=${
        props.WareHouseTo
      }&RouteID=${props.RouteID}&ProductFilter=${
        props.ProductFilter
      }&StockFilter=${props.StockFilter}&ProductID=${props.ProductID}&Barcode=${
        props.Barcode
      }&CategoryID=${props.CategoryID}&BrandID=${props.BrandID}&GroupID=${
        props.GroupID
      }&lang=${lang}&CashLedgers=${props.CashLedgers}&CustomerID=${
        props.CustomerID
      }&PartyID=${props.PartyID}&filterValue=${
        props.filterValue
      }&ProductCode=${props.ProductCode}&templateType=${props.templateType}&LedgerList=${
        props.LedgerList ? props.LedgerList : []
      }&RouteLedgers=${
        props.RouteLedgers ? props.RouteLedgers : []
      }&is_send_mail=${props.is_send_mail}&PartyType=${props.PartyType?props.PartyType:null}&IsZeroBalnce=${props.IsZeroBalnce}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      }
    )
      .then(async (response) => {
        if (response.status === 200) {
          if (props.is_send_mail) {
            await send_mail_report(response.url);
          } else {
            await download_excel(response.url);
          }
          await setLoader(false);
        } else {
          console.log("ERROR");
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
    }
  };
  // ============celery progres========
  const callCeleryProgressAPI = () => {
    if (is_celery_loader){
      fetch(URL+"celery-progress/"+progress_id+'/')
    .then((response) => response.json())
    .then((responseData)=> {
      console.log("progress===>",responseData);
      let percent = Math.floor(responseData.progress.percent)
      if (responseData.state === "SUCCESS"){
      // message.success('Generating Report Completed');

      props.setProgress(0);
      setCelery_Loader(false)
      setLoader(false);
      DownloadReport();
      }
      else if (responseData.state === "FAILURE"){
        if (responseData.result === 'At least one sheet must be visible'){
          // message.warning('No result found!');
        }
        else{
          // message.error('Some error occured, please try agian later!');
        }

        setCelery_Loader(false)
        setLoader(false);
        props.setProgress(0);
      }
      else{
        props.setProgress(percent);
        props?.setProgressMessage?props.setProgressMessage(responseData?.progress?.description):console.log(responseData?.progress?.description);
        console.log(percent,"setProgresssetProgresssetProgresssetProgresssetProgress");
      }
    }).catch((error) => {
      // Handle errors if needed
      console.error('API call error: ', error);
      // Set the loading state to false in case of an error
      setCelery_Loader(false);
      setLoader(false);
      props.setProgress(0);
    })
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (is_celery_loader === false) {
        // Stop calling the API when loader becomes false
        clearInterval(intervalId);
        return;
      }

      callCeleryProgressAPI();
    }, 3000); // Call the API every 3 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [is_celery_loader]);

  // ==== end celery =============

  // ==== Download =====
  const DownloadReport = async (e) => {
    let get_file_path = await fetch(BASE_V11_URL+"reports/generated-report/",{
      method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access}`,
          // "accept": "application/json"
        },
        body: JSON.stringify({
          token: file_path,
        }),
      }).then((response) => response.json());
      if (get_file_path.StatusCode === 6000){
        fetch(
          `${MEDIA_URL}${get_file_path.file_path}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${access}`,
            },
          }
        )
          .then((response) => {
            if (response.status === 200) {
              download_excel(response.url);
            } else {
              console.log("ERROR");
            }
          })
          .catch((err) => {
            console.log("err");
          });
      }
  };

  // ----------
  const send_mail_report = async (url) => {
    if (url) {
      fetch(`${BASE_URL+"reportQuerys/mail-report"}/`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access}`,
          // "accept": "application/json"
        },
        body: JSON.stringify({
          UserID: user_id,
          CompanyID: CompanyID,
          BranchID: BranchID,
          token: url,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.StatusCode === 6000) {
            console.log("successsssss");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return props.is_send_mail ? (
    <ActionButton
      is_send_mail={props.is_send_mail}
      icon={
        props.is_send_mail ? (
          <EmailOutlinedIcon />
        ) : showLoader ? (
          <CachedIcon />
        ) : (
          <SwapVertIcon />
        )
      }
      label={
        props.is_send_mail
          ? t("Send Email")
          : props.download_type === "PDF"
          ? t("PDF")
          : t("Excel")
      }
      color={props.color ? props.color : "#000"}
      background="transparent"
      onClick={() => {
        showLoader ? console.log("DESABLES") : ExportTo(props.download_type);
      }}
    />
  ) : (
    <ExportMenu
      is_send_mail={props.is_send_mail}
      icon={
        props.is_send_mail ? (
          <EmailOutlinedIcon />
        ) : showLoader ? (
          <CachedIcon />
        ) : (
          <SwapVertIcon />
        )
      }
      label={
        props.is_send_mail
          ? t("Send Email")
          : props.download_type === "PDF"
          ? t("PDF")
          : t("Excel")
      }
      color={props.color ? props.color : "#000"}
      background="transparent"
      showLoader={showLoader}
      ExportTo={ExportTo}
      ExportToXLS={props.ExportToXLS}
      checking={props.checking}
      is_celery={props.is_celery}

      // onClick={() => {
      //   showLoader ? console.log("DESABLES") : ExportTo();
      // }}
    />
  );
};
export default ReportDownload;

// const StyledImportExportIcon = styled(SwapVertIcon)`
//   color: #000;
// `;
// const ReloadCircle = styled(CachedIcon)`
//   animation: rotation 1s linear infinite;
//   color: black;
//   @keyframes rotation {
//     from {
//       transform: rotate(0deg);
//     }
//     to {
//       transform: rotate(359deg);
//     }
//   }
// `;



import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  Paper,
  Typography,
  styled,
  Chip,
  Divider,
  TableRow,
  TableBody,
  Button,
  Checkbox,
} from "@mui/material";
import {
  CreateNewButton,
  DeleteButton,
} from "../../Components/Utils/StyledButtons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ConfirmBox from "../../Components/Utils/ConfirmBox";
import Pagenation from "../../Components/Utils/Pagenation";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "../../Components/ReportTable/ReportTable";
import { blue } from "@mui/material/colors";
import {
  SalesEstimateList,
  delete_sales_estimate_api,
} from "../../Api/Sales/SalesEstimateApis";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../features/SnackBarslice";
import { formatNumber } from "../../generalFunction";
import { MenuOptions } from "../../Components/CommonComponents";
import { VIKNBOOKS_FRONT_URL } from "../../generalVeriable";
import ComingSoon from "../SalesInvoice/ComingSoon";

const ListSaleEstimate = () => {
  const [t, i18n] = useTranslation("common");
  const [page_no, setPage_no] = useState(1);
  const { user_id } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const noOfItems = appSetting.itemPerPage;
  const [loading, setLoading] = useState(false);
  let user_role_settings = useSelector((state) => state.userRollSettings);
  let sales_permission = user_role_settings.filter(
    (i) => i.name === "Sales Estimate"
  );
  let sales_view = sales_permission[0].view_permission;
  const {
    GST,
    VAT,
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);


  const OptionList = [
    { name: "Edit", permission: true },
    { name: "Delete", permission: true },
  ];

  const OptionOnClick = (e, name, uniq_id) => {
    if (e) {
      let permission = OptionList.find((i) => i.name === name)?.permission;
      if (name === "Edit" && permission) {
        navigate("/create-sales-estimate", {
          state: { is_edit: true, uniq_id: uniq_id },
        });
      } else if (name === "Delete" && permission) {
        setState({ ...state, selectedItems: [uniq_id] });
        setConfirmBox(true);
      }
    }
  };

  const [state, setState] = useState({
    dataList: [],
    data_count: null,
    search: null,
    selectedItems: [],
    isSelectedAll: false,
  });
  const confirmDelete = () => {
    setConfirmBox(true);
  };
  const [IsConfirmBox, setConfirmBox] = useState(false);
  let navigate = useNavigate();

  const deleteData = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      IdList: state.selectedItems,
    };

    let responseData = await delete_sales_estimate_api({ ...payload });
    console.log(responseData, "responseeeeeeeeeDataaaaaaaaaa");
    if (responseData.StatusCode === 6000) {
      fetchData();

      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message,
          severity: "success",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message ?? "Something went wrong!",
          severity: "error",
        })
      );
    }
  };

  const selectItems = (type, id) => {
    let data = [...state.selectedItems];
    let isSelectedAll = state.isSelectedAll;
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (state.isSelectedAll) {
        data = [];
      } else {
        state.dataList.map((i) => {
          data.push(i.id);
        });
      }
    } else {
      let index = data.findIndex((item) => item === id);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
    }

    setState({ ...state, selectedItems: data, isSelectedAll: isSelectedAll });
  };

  const fetchData = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      search: state.search,
      page: page_no,
      NoOfItems: noOfItems,
    };
    let responseData = await SalesEstimateList({ ...payload });
    console.log(responseData, "responseData==========================");
    if (responseData.StatusCode === 6000) {
      let data_count = responseData.count;
      setState({
        ...state,
        dataList: responseData.data,
        isSelectedAll: false,
        selectedItems: [],
        data_count: data_count,
      });
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message:
            responseData?.message + " : " + responseData?.error ??
            "Something went wrong!",
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, [state.search, page_no]);

  const SingleView = (id) => {
    if (sales_view) {
      const url = `${VIKNBOOKS_FRONT_URL}dashboard/sales-estimate-preview?id=${id}&unq_id=${id}`;
      const newTab = window.open(url);
      // const newTab = window.open(url, "_blank");
    }
  };
  return (
    <>
      {VAT ? (
        <Paper sx={{ height: "100%", width: "100%" }}>
          <Box
            sx={{
              px: "26px",
              py: "15px",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "2px solid #F5F5F5",
            }}
          >
            <FlexBox className="left">
              <Typography
                sx={{
                  fontSize: "25px",
                  fontWeight: "500",
                  mr: 2,
                  color: "#001746",
                }}
                variant="h2"
              >
                {t("Sales Estimate")}
              </Typography>
            </FlexBox>

            <FlexBox className="right">
              <DeleteButton onClick={confirmDelete} t={t} />

              <CreateNewButton
                onClick={() => {
                  navigate("/create-sales-estimate");
                }}
                t={t}
              />

              <Pagenation
                totalItem={state.data_count}
                page_no={page_no}
                setPage_no={setPage_no}
              />
            </FlexBox>
          </Box>

          {/* ==============TABLE=============== */}

          <VBTableContainer>
            <VBTableHeader>
              <VBTableCellHeader
                sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
              >
                <Checkbox
                  checked={state.isSelectedAll} // The checked prop determines whether this checkbox is checked.
                  onChange={() => {
                    selectItems("all");
                  }}
                  sx={{
                    padding: "2px",
                    margin: 0,
                    color: blue[400],
                    "&.Mui-checked": {
                      color: blue[400],
                    },
                    "& .MuiSvgIcon-root": { fontSize: "20px" },
                  }}
                />
              </VBTableCellHeader>
              <VBTableCellHeader sx={{ textAlign: "left" }}>
                {t("SI No")}
              </VBTableCellHeader>
              <VBTableCellHeader>{t("Created User")}</VBTableCellHeader>

              <VBTableCellHeader>{t("Voucher No")}</VBTableCellHeader>
              <VBTableCellHeader>{t("Date")} </VBTableCellHeader>
              <VBTableCellHeader>{t("Ledger Name")}</VBTableCellHeader>
              <VBTableCellHeader sx={{ textAlign: "right" }}>
                {t("Gross Amount")}{" "}
              </VBTableCellHeader>
              <VBTableCellHeader sx={{ textAlign: "right" }}>
                {t("Total Tax")}{" "}
              </VBTableCellHeader>
              <VBTableCellHeader sx={{ textAlign: "right" }}>
                {t("Grand Total")}{" "}
              </VBTableCellHeader>
              <VBTableCellHeader>{t("Is Ordered")} </VBTableCellHeader>
              <VBTableCellHeader sx={{ textAlign: "right", color: "white" }}>
                .
              </VBTableCellHeader>
            </VBTableHeader>

            {loading ? (
              <VBSkeletonLoader />
            ) : (
              <TableBody>
                {state.dataList.map((data, i) => (
                  <TableRow
                  // onClick={() => navigate(filterOptions.filterType==="return" || data.GrandTotal < 0 ? VIKNBOOKS_FRONT_URL+"/dashboard/sales-return-preview" :  VIKNBOOKS_FRONT_URL+"/dashboard/sale-invoice-single", { state: { invoice_type: "sales_invoice", id: data.id }, unq_id: data.id })}
                  //   onClick={()=>{handleTableRowClick(data)}}
                  >
                    <VBTableCellBody
                      sx={{
                        // cursor: "pointer",
                        textAlign: "left",
                        padding: "0px",
                        paddingLeft: "5px",
                      }}
                    >
                      <Checkbox
                        checked={
                          state.selectedItems.includes(data.id) ? true : false
                        } // The checked prop determines whether this checkbox is checked.
                        onChange={() => {
                          selectItems("not", data.id);
                        }}
                        sx={{
                          padding: "2px",
                          margin: 0,
                          color: blue[400],
                          "&.Mui-checked": {
                            color: blue[400],
                          },
                          "& .MuiSvgIcon-root": { fontSize: "20px" },
                        }}
                      />
                    </VBTableCellBody>
                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ cursor: "pointer", textAlign: "left" }}
                    >
                      {(page_no - 1) * appSetting.itemPerPage + i + 1}
                    </VBTableCellBody>
                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      {data.CreatedUser}
                    </VBTableCellBody>

                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      {data.VoucherNo}
                    </VBTableCellBody>
                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      {data.Date}
                    </VBTableCellBody>
                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      className="truncated-text "
                      sx={{ cursor: "pointer" }}
                    >
                      {data.LedgerName}
                    </VBTableCellBody>
                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ cursor: "pointer", textAlign: "right" }}
                    >
                      {formatNumber(data.TotalGrossAmt)}
                    </VBTableCellBody>
                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ cursor: "pointer", textAlign: "right" }}
                    >
                      {formatNumber(data.TotalTax)}
                    </VBTableCellBody>
                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ cursor: "pointer", textAlign: "right" }}
                    >
                      {formatNumber(data.GrandTotal)}
                    </VBTableCellBody>
                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ cursor: "pointer", textAlign: "center" }}
                    >
                      <Checkbox
                        checked={data.Status === "O"} // Checked if status is 'O', unchecked if 'N'
                        sx={{
                          padding: "2px",
                          margin: 0,
                          color: blue[400],
                          "&.Mui-checked": {
                            color: blue[400],
                          },
                          "& .MuiSvgIcon-root": { fontSize: "20px" },
                        }}
                      />
                    </VBTableCellBody>
                    <VBTableCellBody
                      sx={{ cursor: "pointer", textAlign: "right" }}
                    >
                      <MenuOptions
                        OptionList={OptionList}
                        OptionOnClick={OptionOnClick}
                        uniq_id={data.id}
                        // disable={true}
                        disable={data.IsNewSalesEstimate === false ? true: false}

                      />
                    </VBTableCellBody>
                  </TableRow>
                ))}
                <VBTableRowNull
                  length={
                    state.data_count ? state.data_count : state.dataList.length
                  }
                />
              </TableBody>
            )}
          </VBTableContainer>

          <ConfirmBox
            heading={"Confirm to delete?"}
            message={"Once you delete, you will not get it back!"}
            open={IsConfirmBox}
            setOpen={setConfirmBox}
            confirmFunction={deleteData}
          />
        </Paper>
      ) : (
        <ComingSoon />
      )}
    </>
  );
};

export default ListSaleEstimate;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));

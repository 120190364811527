import { FormControl, FormLabel, InputBase } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const VBInputField = (props) => {
  return (
    <FormControl style={{ width: "100%" }} ref={props?.InputProps?.ref} >
      {props.label && (
        <FormLabel
          id="demo-controlled-radio-buttons-group"
          sx={{ fontSize: "14px", fontWeight: "400", mb: 1, ...props.sx }}
        >
          {props.label}
        </FormLabel>
      )}
      <InputField {...props?.inputProps} {...props} />
    </FormControl>
  );
};

export default VBInputField;

const InputField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  width: "100%",
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 12,
    width: "100%",
    padding: "6px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    //   border: "1px solid",
    // },
  },
  "&.MuiInputBase-root": {
    marginTop: "3px",
  },
}));

import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchReport from "../../../../Components/Utils/SearchReport";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import PrintBtn from "../../../../Components/Utils/PrintBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import FilterLable from "../../../../Components/Utils/FilterLable";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import { CallBranchsList, CallRoutes } from "../../../../Api/Reports/CommonReportApi";
import {
  CallOutstandingReport,
} from "../../../../Api/Reports/FinanceApi";
import moment from "moment";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "../../../../generalFunction";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import { UserRolePermission } from "../../../../Function/Editions";

const OutstandingReport = () => {
  let Export_view = UserRolePermission("Outstanding Report", "print_permission");
  // const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  // const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  // const { user_id, } = useSelector((state) => state.user);
  const [t] = useTranslation("common");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const { generalSettings } = useSelector((state) => state.generalSettingsSclice);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [page_no, setPage_no] = useState(1)
  const [showPrint, setPrint] = useState(false);
  const [reportData, setReportData] = useState([]);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [routeName, setRouteName] = useState("")
  const [isLoadingRoutes, setIsLoadingRoutes] = useState(false)
  const [filterOptions, setFilterOptions] = useState({
    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      ToDate: moment().format("YYYY-MM-DD"),
    },
    FromDate: moment(),
    branch: {
      name: "Branch",
      isActive: false,
      value: "",
      branchList: [],
    },
    routs: [],
    CashLedgers: [0],
    isDebitor: false,
    isCreditor: false,
    isZeroBalance: false,
    isSupplier: false,
    isCustomer: false,
    isBoth: true,
    // IsZeroBalnce: false,
  });

  const [filterData, setFilterData] = useState({
    routeList: [],
  })

  const [isLoading, setIsLoading] = useState(false)

  //Handle functions
  
  const radiosValue = [
    // "Zero Balance",
    "Creditor",
    "Debitor",
  ].map((option) => {
    const isChecked = filterOptions[`is${option.replace(" ", "")}`];

    return {
      checked: isChecked !== undefined ? isChecked : false,
      onChange: () =>
        setFilterOptions({
          ...filterOptions,
          isCreditor: false,
          isDebitor: false,
          // isZeroBalance: false,
          [`is${option.replace(" ", "")}`]:
            !filterOptions[`is${option.replace(" ", "")}`],
        }),
      label: option,
    };
  });

  const radiosValue1 = [
    {
      checked: filterOptions.isZeroBalance,
      onChange: () =>
        setFilterOptions({
          ...filterOptions,
          isZeroBalance: !filterOptions.isZeroBalance,
          // IsZeroBalnce: !filterOptions.IsZeroBalnce,
        }),
      label: t("Zero Balance"),
    }
  ];

  const radiosValue2 = [
    "Customer",
    "Supplier",
    "Both",
  ].map((option) => {
    const isChecked = filterOptions[`is${option.replace(" ", "")}`];

    return {
      checked: isChecked !== undefined ? isChecked : false,
      onChange: () =>
        setFilterOptions({
          ...filterOptions,
          // isStockLevel: false,
          // isAsofDate: false,
          isSupplier: false,
          isCustomer: false,
          isBoth: false,
          [`is${option.replace(" ", "")}`]:
            !filterOptions[`is${option.replace(" ", "")}`],
        }),
      label: option,
    };
  });

  const closeFilter = () => setOpenFilter(false);

  const onFilter = function () {
    fetchOutstandingReport();
  };

  const handleDefaultFilter = function() {
    setFilterOptions({
      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Today" },
        ToDate: moment().format("YYYY-MM-DD"),
      },
      FromDate: moment(),
      branch: {
        name: "Branch",
        isActive: false,
        value: "",
        branchList: [],
      },
      routs: [],
      isDebitor: false,
      isCreditor: false,
      isZeroBalance: false,
      isSupplier: false,
      isCustomer: false,
      isBoth: true,
    })
  }

  const openReportLink = function(
    id, 
    ledgerName
  ) {

    let toDate = filterOptions.FromDate.format("YYYY-MM-DD")
    navigate(`/ledger-report?LedgerID=${id}&ToDate=${toDate}&LedgerName=${ledgerName}`)
    // const url = `https://viknbooks/ledger-report?LedgerID=${id}&ToDate=${toDate}&LedgerName=${ledgerName}`
    // window.open(url, '_blank');
  }


  // API Fetch data
  const fetchOutstandingReport = async function () {
    setIsLoading(true)
    const payload = {
      // FromDate: filterOptions.date.FromDate,
      ToDate: filterOptions.FromDate.format("YYYY-MM-DD"),
      // items_per_page: appSetting,
      page_no: page_no,
      Type: null,
      RouteLedgers: [0],
      PartyType: "both",
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      IsZeroBalnce: filterOptions.isZeroBalance,
    }
    if (filterOptions.routs.length !== 0) {
      payload.RouteLedgers = filterOptions.routs.map(e => e.RouteID)
    }
    if (filterOptions.isBoth) payload.PartyType = "both"
    if (filterOptions.isCustomer) payload.PartyType = "customer"
    if (filterOptions.isSupplier) payload.PartyType = "supplier"

    // if (filterOptions.isZeroBalance) payload.Type = "zero_balance"
    if (filterOptions.isCreditor) payload.Type = "creditors"
    if (filterOptions.isDebitor) payload.Type = "debitors"

    const res = await CallOutstandingReport(payload);
    
    if (res?.StatusCode === 6000) {
      setReportData(res.data)
    }else {
      dispatch(
        openSnackbar({
          open : true,
          message : res.message,
          severity : 'warning',
        })
      )
      setReportData([])
    }
    setIsLoading(false)
  };

  async function fetchRoutes() {
    setIsLoadingRoutes(true)
    const routeResponse = await CallRoutes({
      items_per_page: 10,
      page_no: 1,
      search: routeName,
      PartyType: "1",
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID: user_id
    });
    if (routeResponse?.StatusCode === 6000) {
      setFilterData(prev => ({...prev, routeList: routeResponse.data}));
      setIsLoadingRoutes(false);
    }
  }

  const filterApis = async function () {
    const data = await CallBranchsList({
      items_per_page: 15,
      page_no: 1,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID: user_id
    });
    // console.log(data.data);
    if (data?.StatusCode === 6000) {
      setFilterOptions({
        ...filterOptions,
        branch: {
          ...filterOptions.branch,
          branchList: data.data,
        },
      });
    }
    fetchRoutes();
  };

  const getDataForPage = () => {
    const startIndex = (page_no - 1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData.slice(0, reportData.length -1)
    return alldata.slice(startIndex, endIndex) || [];
  };

  useEffect(() => {
    // fetchExpensesReport();
    filterApis();
  }, []);

  useEffect(() => {
    fetchRoutes()
  }, [routeName])
  

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500", mr: 2 }}
              variant="h2"
            >
              {t("Outstanding Report")}
            </Typography>
            {/* <SearchReport /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            {/* <ExportBtn /> */}
            {/* <PrintBtn /> */}
            {/* <Box> */}
            {Export_view ? 
            <ReportDownload
              download_type={"PDF"}
              VoucherType={
                filterOptions.isCreditor
                  ? "creditors"
                  : filterOptions.isDebitor
                  ? "debitors"
                  // : filterOptions.isZeroBalance
                  // ? "zero_balance"
                  : null
              }
              invoice_type={"OutStanding"}
              showPrint={showPrint}
              setPrint={setPrint}
              RouteLedgers={[0, ...filterOptions.routs.map(e => e.RouteID)]}
              ToDate={filterOptions.FromDate.format("YYYY-MM-DD")}
              // BranchID={CompanyDetails.BranchID}
              // UserID={UserDetails.user_id}
              PriceRounding={appSetting.PriceDecimalPoint}
              PartyType={filterOptions.isCustomer?"customer":filterOptions.isSupplier?"supplier":"both"}
              IsZeroBalnce={filterOptions.isZeroBalance ? true : false}
            />
            : null}
            <Pagenation 
              totalItem={reportData.length}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
            {/* </Box> */}
          </Box>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // height: "54px",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
                  <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
          {t("Applied Filters: ")}
        </div>
          <FilterLable
              name={t("Date")}
              value={filterOptions.FromDate.format("DD/MM/YYYY")}
            />
          {(filterOptions.isZeroBalance || filterOptions.isCreditor || filterOptions.isDebitor) && (
            <FilterLable
              name={t("Type")}
              value={(filterOptions.isZeroBalance && "Zero Balance") || (filterOptions.isCreditor && "Credit") || (filterOptions.isDebitor && "Debit")}
              handleDelete={() => setFilterOptions({...filterOptions, isZeroBalance: false, isCreditor: false, isDebitor: false})}
            />
          )}
          {(filterOptions.isBoth || filterOptions.isSupplier || filterOptions.isCustomer) && (
            <FilterLable
              name={t("Type")}
              value={(filterOptions.isBoth && "Both")|| (filterOptions.isSupplier && "Supplier")|| (filterOptions.isCustomer && "Customer")}
              handleDelete={() => setFilterOptions({...filterOptions, isBoth: false, isSupplier: false, isCustomer: false})}
            />
          )}
          {filterOptions.routs.length !== 0 && <FilterLable
              name={t("Routes Name")}
              value={filterOptions.routs.map((e, i)=> 
                <Chip 
                key={i} 
                label={e.RouteName} 
                variant="outlined" 
                sx={{ p: "1px", fontSize: "10px", height: "22px", mr: "4px", 
                  ".MuiChip-label": {
                    px: '8px',
                  },
                  ".MuiSvgIcon-root": {
                    fontSize: "15px",
                    color: "#ff00006b",
                  }
                }}
                onDelete={() =>  
                  setFilterOptions({
                    ...filterOptions,
                    routs: filterOptions.routs.filter(l => l.RouteID !== e.RouteID)
                  })
                }
                />
              )}
              handleDelete={() => setFilterOptions({...filterOptions, routs: []})}
            />
          }

        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("Serial No")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Ledger Code")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Ledger Name")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Party Type")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Route Name")} </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Debit")} </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Credit")} </VBTableCellHeader>
          </VBTableHeader>
          {isLoading ? <VBSkeletonLoader /> : [<TableBody>
            {getDataForPage().map((data, i) => (
              <TableRow 
                sx={{cursor: "pointer"}}
                onClick={() => openReportLink(data.LedgerID, data.LedgerName)}
              >
                <VBTableCellBody>{data.index + 1}</VBTableCellBody>
                <VBTableCellBody>{data.LedgerCode}</VBTableCellBody>
                <VBTableCellBody>{data.LedgerName}</VBTableCellBody>
                <VBTableCellBody>{data.PartyType}</VBTableCellBody>
                <VBTableCellBody>{data.RouteName}</VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(data.Debit)}</VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(data.Credit)}</VBTableCellBody>
              </TableRow>
            ))}
          <TableRow></TableRow>
          </TableBody>,
          <VBTableFooter>
          <TableRow>
            <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
            <VBTableCellFooter></VBTableCellFooter>
            <VBTableCellFooter></VBTableCellFooter>
            <VBTableCellFooter></VBTableCellFooter>
            <VBTableCellFooter></VBTableCellFooter>
            <VBTableCellFooter sx={{ textAlign: "right"}}>{formatNumber(reportData[reportData.length - 1]?.Debit)}</VBTableCellFooter>
            <VBTableCellFooter sx={{ textAlign: "right"}}>
              {formatNumber(reportData[reportData.length - 1]?.Credit)}
            </VBTableCellFooter>
          </TableRow>
          </VBTableFooter>]}
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={onFilter}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
            <VBDatePiker
              fromDate={filterOptions.FromDate}
              fromOnChange={(value) =>
                setFilterOptions({
                  ...filterOptions,
                  FromDate: value,
                })
              }
            />
            </ListItem>
            <ListItem>
              <ViknCheckboxGroup 
                radios={radiosValue1}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknCheckboxGroup 
                radios={radiosValue}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknCheckboxGroup 
                radios={radiosValue2}
              />
            </ListItem>
            {generalSettings.EnableRoute && <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                multiple
                placeholder={"Routes Name"}
                loading={isLoadingRoutes}
                value={filterOptions.routs}
                options={filterData.routeList}
                getOptionLabel={(option) => option.RouteName}
                onChange={(e, newValue) => setFilterOptions({...filterOptions, routs: newValue})}
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    setRouteName(value);
                  }
                  if (reason === "clear") {
                    setFilterOptions({ ...filterOptions, ledgerName: null });
                    setRouteName("");
                  }
                }}
              />
            </ListItem>}

            <ListItem>
              {/* <VBDatePiker
                fromDate={filterOptions.date.FromDate}
                toDate={filterOptions.date.ToDate}
              /> */}
            </ListItem>

            {/* <Divider /> */}
          </List>
        }
      />
    </>
  );
};

export default OutstandingReport;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // width: "28%",
}));

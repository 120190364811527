import { Navigate } from "react-router-dom";
import { activateFunction } from "../../../Function/Editions";
import { store } from "../../../app/store";
// import { lazy,Suspense } from "react";

// const BranchTransferReport = lazy(() => import("../../../Pages/Reports/Inventory/BranchTransferReport/BranchTransferReport"));
// const OpeningStockReport = lazy(() => import("../../../Pages/Reports/Inventory/OpeningStockReport/OpeningStockReport"));
// const ProductSerialisationReport = lazy(() => import("../../../Pages/Reports/Inventory/ProductSerialisationReport/ProductSerialisationReport"));
// const ProductSummaryReport = lazy(() => import("../../../Pages/Reports/Inventory/ProductSummaryReport/ProductSummaryReport"));
// const StockLedgerReport = lazy(() => import("../../../Pages/Reports/Inventory/StockLedgerReport/StockLedgerReport"));
// const StockReport = lazy(() => import("../../../Pages/Reports/Inventory/StockReport/StockReport"));
// const StockTransferRegisterReport = lazy(() => import("../../../Pages/Reports/Inventory/StockTransferRegisterReport/StockTransferRegisterReport"));
// const StockValueReport = lazy(() => import("../../../Pages/Reports/Inventory/StockValueReport/StockValueReport"));
// const BatchReport = lazy(() => import("../../../Pages/Reports/Inventory/BatchReport/BatchReport"));
import BranchTransferReport from "../../../Pages/Reports/Inventory/BranchTransferReport/BranchTransferReport";
import OpeningStockReport from "../../../Pages/Reports/Inventory/OpeningStockReport/OpeningStockReport";
import ProductSerialisationReport from "../../../Pages/Reports/Inventory/ProductSerialisationReport/ProductSerialisationReport";
import ProductSummaryReport from "../../../Pages/Reports/Inventory/ProductSummaryReport/ProductSummaryReport";
import StockLedgerReport from "../../../Pages/Reports/Inventory/StockLedgerReport/StockLedgerReport";
import StockReport from "../../../Pages/Reports/Inventory/StockReport/StockReport";
import StockTransferRegisterReport from "../../../Pages/Reports/Inventory/StockTransferRegisterReport/StockTransferRegisterReport";
import StockValueReport from "../../../Pages/Reports/Inventory/StockValueReport/StockValueReport";
import BatchReport from "../../../Pages/Reports/Inventory/BatchReport/BatchReport";

const state = store.getState();
const { EnableProductBatchWise,EnableFaeraSettings } = state.generalSettingsSclice.generalSettings;
// const { BusinessTypeName } = state.companyDetails;

function transformString(str) {
  const words = str.split('-');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  const transformedStr = capitalizedWords.join(' ');
  return transformedStr;
}

const ProtectedRoute = function ({ children, authCondition, path }) {
  let isAuthCondition = authCondition;
  document.title = transformString(path) || "Vikn Books"
  return isAuthCondition ? children : <Navigate to="/dashboard" />;
};

const route = [
  { path: "stock-report", element: <StockReport />, isAuth: activateFunction("StockReport")  },
  { path: "stock-value-report", element: <StockValueReport />, isAuth: activateFunction("StockValueReport")  },
  { path: "product-summary", element: <ProductSummaryReport />, isAuth: activateFunction("ProductSummary")  },
  { path: "stock-ledger-report", element: <StockLedgerReport />, isAuth: activateFunction("StockLedgerReport")  },
  { path: "opening-stock-report", element: <OpeningStockReport />, isAuth: activateFunction("OpeningStock")  },
  { path: "batch-report", element: <BatchReport />, isAuth:  activateFunction("BatchReport")
  // EnableProductBatchWise 
},
  {path: "stock-transfer-register-report",element: <StockTransferRegisterReport />,isAuth: activateFunction("StockTransferRegister") },
  { path: "branch-transfer-report", element: <BranchTransferReport />, isAuth: activateFunction("BranchTransfer")  },
  {path: "product-serialisation-report",element: <ProductSerialisationReport />,isAuth: activateFunction("ProductSerialisationReport") },
];

const inventoryRoute = route.map((route) => ({
  ...route,
  element:
  // (
    // <Suspense fallback={<div>Loading...</div>}>
       <ProtectedRoute path={route.path} authCondition={route.isAuth}>{route.element}</ProtectedRoute>,
      //  </Suspense>
      // )
}));

export default inventoryRoute;

import {
  Box,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import SearchReport from "../../../../Components/Utils/SearchReport";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import PrintBtn from "../../../../Components/Utils/PrintBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import FilterLable from "../../../../Components/Utils/FilterLable";
import {
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableFooter,
  VBTableCellFooter,
  VBSkeletonLoader,
} from "../../../../Components/ReportTable/ReportTable";
import { CallBranchTransferReport } from "../../../../Api/Reports/InventoryApi";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import { CallLedgerListById } from "../../../../Api/Reports/CommonReportApi";
import { formatNumber } from "../../../../generalFunction";
import { useTranslation } from "react-i18next";
import ExportToExcelButton from "../../../../Components/Utils/ExportToExcelButton";
import { goToInvoice } from "../../../../Function/comonFunction";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { UserRolePermission } from "../../../../Function/Editions";

const BranchTransferReport = () => {
  let Export_view = UserRolePermission("Branch Transfer Report", "print_permission");
  const [t] = useTranslation("common");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [reportData, setReportData] = useState([]);
  const [showPrint, setPrint] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [page_no, setPage_no] = useState(1)
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [clearFilter, setClearFilter] = useState(false)
  const [filterOptions, setFilterOptions] = useState({
    ledger: null,
    isSalesBT: true,
    isPurchaseBT: false,
    isBoth: false,
    typeLable: "Sales BT",
    fromDate: moment(),
    toDate: moment(),
  });

  const [filterData, setFilterData] = useState({
    ledgerList: [],
  });

  const [search, setSearch] = useState({
    ledger: ""
  });

  const [isLoading, setIsLoading] = useState({
    ledger: false,
    report: false,
  });

  //Handle functions
  const closeFilter = () => setOpenFilter(false);

  const handleDefaultFilter = function () {
    setFilterOptions({
      ledger: null,
      isSalesBT: true,
      isPurchaseBT: false,
      isBoth: false,
      typeLable: "Sales BT",
      fromDate: moment(),
      toDate: moment(),
    });
    setPage_no(1)
    setClearFilter(!clearFilter)
    // fetchReport();
  }

  const radiosValue = [
    t("Sales BT"),
    t("Purchase BT"),
    t("Both"),
    // "Stock Level",
    // "As of Date"
  ].map((option) => {
    const isChecked = filterOptions[`is${option.replace(" ", "")}`];

    return {
      checked: isChecked !== undefined ? isChecked : false,
      onChange: () =>
        setFilterOptions({
          ...filterOptions,
          // isStockLevel: false,
          // isAsofDate: false,
          isPurchaseBT: false,
          isSalesBT: false,
          isBoth: false,
          typeLable: option,
          [`is${option.replace(" ", "")}`]:
            !filterOptions[`is${option.replace(" ", "")}`],
        }),
      label: option,
    };
  });

  const searchReport = function () {
    fetchReport();
  };

  // API Fetch data
  const fetchReport = async function () {
    setIsLoading({ ...isLoading, report: true})
    const payload = {
      FromDate: filterOptions.fromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.toDate.format("YYYY-MM-DD"),
      RouteLedgers: [0],
      EmployeeID: 0,
      CustomerID: 0,
      UserID: 0,
      filterValue: "all",
      filter_type: (() => {
        return filterOptions.isPurchaseBT ? "purchase" :
               filterOptions.isSalesBT ? "sales" :
               filterOptions.isBoth ? "both" :
               "both";
      })(),
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id
    }

    if (filterOptions.ledger) {
      payload.CustomerID = filterOptions.ledger.LedgerID
    }

    const data = await CallBranchTransferReport(payload);
    console.log(data);
    if (data.StatusCode === 6000) {
      setReportData(data.data);
    }
    setIsLoading({ ...isLoading, report: false})
  };

  const searchLedger = async function () {
    setIsLoading({
      ...isLoading,
      ledger: true
    })
    let res = await CallLedgerListById({
      type_invoice: "BT",
      load_data: true,
      ledger_name: "",
      length: 0,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id
    });
    setFilterData({...filterData, ledgerList: res.data})
    setIsLoading({
      ...isLoading,
      ledger: false
    })
  };

  const getDataForPage = () => {
    const startIndex = (page_no - 1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData?.slice(0,reportData?.length -1)
    return alldata?.slice(startIndex, endIndex) || [];
  };

  useEffect(() => {
    fetchReport();
  }, [clearFilter])
  

  useEffect(() => {
    searchLedger()
  }, [search.ledger])

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center"}}>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500", mr: 2 }}
              variant="h2"
            >
              {t("Branch Transfer Report")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </Box>

          <FlexBox>
            {/* <ExportBtn /> */}
            {/* <ExportToExcelButton
              ToDate={filterOptions.toDate.format("YYYY-MM-DD")}
              FromDate={filterOptions.fromDate.format("YYYY-MM-DD")}
              // UserID={user_id}
              report_type={"bank_reconslation"}
              download_type={"EXCEL"}
              LedgerID={filterOptions.ledger ? filterOptions.ledger.LedgerID : 0}
              tab={(() => {
                return filterOptions.isPurchaseBT ? "purchase" :
                       filterOptions.isSalesBT ? "sales" :
                       filterOptions.isBoth ? "both" :
                       "both";
              })()}
            /> */}
            {Export_view ? 
            <ReportDownload
            download_type={"PDF"}
            invoice_type="branch_transfer"
            filterValue={
              (() => {
                return filterOptions.isPurchaseBT ? "purchase" :
                       filterOptions.isSalesBT ? "sales" :
                       filterOptions.isBoth ? "both" :
                       "both";
              })()
            }
            showPrint={showPrint}
            setPrint={setPrint}
            FromDate={filterOptions.fromDate.format("YYYY-MM-DD")}
            ToDate={filterOptions.toDate.format("YYYY-MM-DD")}
            BranchID={BranchID}
            UserID={0}
            EmployeeID={0}
            CustomerID={filterOptions.ledger ? filterOptions.ledger.LedgerID: 0}
            CompanyID={CompanyID}
            PriceRounding={Number(PriceDecimalPoint)}
            RouteLedgers={ `[0]`}
          />
          : null}
            {/* <PrintBtn /> */}
            <Pagenation              
              totalItem={reportData?.length-1}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          // This also change into custom component
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // height: "54px",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >   
        <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
        {t("Applied Filters: ")}
        </div>
          <FilterLable
            name={t("Date")}
            value={filterOptions.fromDate.format("DD-MM-YYYY") + " to " + filterOptions.toDate.format("DD-MM-YYYY") }
          />
          <FilterLable
            name={t("Type")}
            value={filterOptions.typeLable}
          />
          {filterOptions.ledger && <FilterLable
              name={t("ledger")}
              value={filterOptions.ledger.LedgerName}
              handleDelete={() => setFilterOptions({...filterOptions, ledger: null})}
            />
          }
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("Serial No")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher No")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher Date")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Ledger Name")} </VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t("Gross Amount")} </VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t("Bill Discount")} </VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t("Grand Total")} </VBTableCellHeader>
            {/* <VBTableCellHeader>Grand Total </VBTableCellHeader> */}
            {/* <VBTableCellHeader>Qty Out </VBTableCellHeader>
            <VBTableCellHeader>Balance </VBTableCellHeader> */}
          </VBTableHeader>
          {isLoading.report ? 
            <VBSkeletonLoader/>  : 
            [<TableBody>
            {getDataForPage().map((data, i) => (
              <TableRow
                sx={{ cursor: data.id === "nan" || data.id === "" ? "auto" : "pointer" }} 
                onClick={() => data.id === "nan" || data.id === "" ? null : goToInvoice(data.id, data.VoucherType === "SI" ? "SBT" : "PBT", data.VoucherNo,)}
              >
                <VBTableCellBody>{(page_no - 1) * appSetting.itemPerPage + i + 1}</VBTableCellBody>
                <VBTableCellBody>{data.VoucherNo}</VBTableCellBody>
                <VBTableCellBody>{data.Date}</VBTableCellBody>
                <VBTableCellBody>{data.LedgerName}</VBTableCellBody>
                <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.GrossAmount)}</VBTableCellBody>
                <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.BillDiscount)}</VBTableCellBody>
                <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.GrandTotal)}</VBTableCellBody>
                {/* <VBTableCellBody>{data.UnitPrice}</VBTableCellBody>
                <VBTableCellBody>{data.Qty}</VBTableCellBody>
                <VBTableCellBody>{data.FreeQty}</VBTableCellBody> */}
                {/* <VBTableCellBody>{data.QtyIn}</VBTableCellBody> */}
                {/* <VBTableCellBody>{data.QtyOut}</VBTableCellBody>
                <VBTableCellBody>{data.QtyIn}</VBTableCellBody> */}
              </TableRow>
            ))}
            <TableRow> </TableRow>

          </TableBody>,
          <VBTableFooter>
            <TableRow>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              {/* <VBTableCellFooter></VBTableCellFooter> */}
              <VBTableCellFooter>
              {t('Total')} 
              </VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData.length - 1]?.GrossAmount)} </VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData.length - 1]?.BillDiscount)} </VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData.length - 1]?.GrandTotal)} </VBTableCellFooter>
              {/* <VBTableCellFooter align="right">{reportData[1]?.Amount}</VBTableCellFooter> */}
            </TableRow>
          </VBTableFooter>]}
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={searchReport}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.fromDate}
                toDate={filterOptions.toDate}
                fromOnChange={(value) =>
                  setFilterOptions({ ...filterOptions, fromDate: value })
                }
                toOnChange={(value) =>
                  setFilterOptions({ ...filterOptions, toDate: value })
                }
              />
            </ListItem>
            {/* <Divider /> */}
            <ListItem>
              {/* <ViknAutoComplete
                label={"Product"}
                placeholder={"Search for Product"}
                value={filterOptions.ledger}
                onChange={(e, newValue) =>
                  setFilterOptions((prev) => ({ ...prev, product: newValue }))
                }
                options={filterData.ledgerList}
                getOptionLabel={(option) => option?.name}
                // isOptionEqualToValue={(option, value) => option?.name === value?.name}
              /> */}
              <ViknSearchAutoComplete
                freeSolo
                loading={isLoading.ledger}
                placeholder={t("Search for Ledger")}
                filterName={t("Ledger")}
                onChange={(e, newValue) =>
                  setFilterOptions((prev) => ({ ...prev, ledger: newValue }))
                }
                onInputChange={(event, value, reason) => {
                  console.log(event, reason);
                  if (reason === "input") {
                    setSearch({...search, ledger: value});
                  }
                }}
                options={filterData.ledgerList}
                getOptionLabel={(option) => option?.LedgerName}
                value={filterOptions.ledger}
              />
            </ListItem>
            <ListItem>
              <ViknCheckboxGroup radios={radiosValue} />
            </ListItem>
          </List>
        }
      />
    </>
  );
};

export default BranchTransferReport;

const FlexBox = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  // width: "26%",
}));

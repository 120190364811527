import { Outlet } from "react-router-dom";
import ReportsList from "../../Pages/Reports/ReportsList";
import accountRoute from "./routes/accountRoute";
import financeRouter from "./routes/financeRoute";
import inventoryRoute from "./routes/inventoryRout";
import purchaseRoute from "./routes/purchaseRoute";
import saleRoute from "./routes/salesRoute";
import taxRoute from "./routes/taxsRoute";

const ReportRoute = {
  path: "/",
  element: <Outlet />,
  children: [
    {path: "", element: <ReportsList />, index: true},
    ...financeRouter,
    ...inventoryRoute,
    ...accountRoute,
    ...taxRoute,
    ...saleRoute,
    ...purchaseRoute,
  ],
};
export default ReportRoute;

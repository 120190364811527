import { Button, styled } from "@mui/material";
import React from "react";
import { Icons } from "../../Assets/AssetLog";
import { useTranslation } from "react-i18next";

const FilterBtn = (props) => {
  const [t] = useTranslation("common");
  return (
    <StyledButton {...props} startIcon={<img src={Icons.Filter} alt="filter" />}>
      {t("Filter")}
    </StyledButton>
  );
};

export default FilterBtn;

const StyledButton = styled(Button)(() => ({
  fontSize: "15px",
  backgroundColor: "#F9F9F9",
  border: "1px solid #EEEEEE",
  textTransform: "none",
  paddingLeft: "11px",
  paddingRight: "32px",
  fontWeight: "400",
  borderRadius: "6px",
  "& .MuiButton-startIcon": {
    mr: "14px",
  },
}));

import { Navigate } from "react-router-dom";
// import SalesInvoice from "../../Pages/Inventory/SalesInvoice/SalesInvoice"
import { store } from "../../app/store";
import { activateFunction } from "../../Function/Editions";
import { elements } from "chart.js";
import { Outlet } from "react-router-dom";
import SalesInvoice from "../../Pages/SalesInvoice/SalesInvoice";
import SalesInvoiceList from "../../Pages/SalesInvoice/SalesInvoiceList";
import SalesReturn from "../../Pages/SalesReturn/SalesReturn";
import SalesReturnList from "../../Pages/SalesReturn/SalesReturnList";
import ComingSoon from "../../Pages/SalesInvoice/ComingSoon"
import CreateSaleEstimate from "../../Pages/SalesEstimate/CreateSaleEstimate";
import ListSaleEstimate from "../../Pages/SalesEstimate/ListSaleEstimate";
import SalesOrder from "../../Pages/SalesOrder/SalesOrder";
import SalesOrderList from "../../Pages/SalesOrder/SalesOrderList";
const state = store.getState();


const ProtectedRoute = ({ children, authCondition }) => {
    let isAuthCondition = authCondition;
    return isAuthCondition ? children : <Navigate to="/dashboard" />;
  };


const route = [
    {path:"create-sales",element:<SalesInvoice/>,isAuth:true,},
    {path:"list-sales",element:<SalesInvoiceList/>,isAuth:true,},
    {path:"coming-soon",element:<ComingSoon/>,isAuth:true,},
    {path:"create-sales-return",element:<SalesReturn/>,isAuth:true,},
    {path:"list-sales-return",element:<SalesReturnList/>,isAuth:true,},
    {path:"create-sales-estimate",element:<CreateSaleEstimate/>,isAuth:true,},
    {path:"list-sales-estimate",element:<ListSaleEstimate/>,isAuth:true,},
    {path:"create-sales-order",element:<SalesOrder />,isAuth:true,},
    {path:"list-sales-order",element:<SalesOrderList />,isAuth:true,},

    
]


const Routes  = route.map((route)=>({
    ...route,
    element: <ProtectedRoute authCondition={route.isAuth}>{route.element}</ProtectedRoute>,
}))

const salesRoutes = {
    path:"/",
    element: <Outlet />,
  children: [
    ...Routes
  ],
}


export default salesRoutes
import { Navigate } from "react-router-dom";
import { activateFunction } from "../../../Function/Editions";
// import { Suspense,lazy } from "react";


// const PurchaseReport = lazy(() => import("../../../Pages/Reports/Purchase/PurchaseReport/PurchaseReport"));
// const PurchaseOrderReport = lazy(() => import("../../../Pages/Reports/Purchase/PurchaseOrderReport/PurchaseOrderReport"));
// const PurchaseRegisterReport = lazy(() => import("../../../Pages/Reports/Purchase/PurchaseRegisterReport/PurchaseRegisterReport"));
// const PurchaseReturnRegisterReport = lazy(() => import("../../../Pages/Reports/Purchase/PurchaseReturnRegisterReport/PurchaseReturnRegisterReport"));
// const SupplierVSProductReport = lazy(() => import("../../../Pages/Reports/Purchase/SupplierVSProductReport/SupplierVSProductReport"));
// const PurchaseIntegratedReport = lazy(() => import("../../../Pages/Reports/Purchase/PurchseIntegratedReport/PurchaseIntegratedReport"));
import PurchaseReport from "../../../Pages/Reports/Purchase/PurchaseReport/PurchaseReport";
import PurchaseOrderReport from "../../../Pages/Reports/Purchase/PurchaseOrderReport/PurchaseOrderReport";
import PurchaseRegisterReport from "../../../Pages/Reports/Purchase/PurchaseRegisterReport/PurchaseRegisterReport";
import PurchaseReturnRegisterReport from "../../../Pages/Reports/Purchase/PurchaseReturnRegisterReport/PurchaseReturnRegisterReport";
import SupplierVSProductReport from "../../../Pages/Reports/Purchase/SupplierVSProductReport/SupplierVSProductReport";
import PurchaseIntegratedReport from "../../../Pages/Reports/Purchase/PurchseIntegratedReport/PurchaseIntegratedReport";


function transformString(str) {
  const words = str.split('-');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  const transformedStr = capitalizedWords.join(' ');
  return transformedStr;
}

// Higher Order Component for protected routes
const ProtectedRoute = ({ children, authCondition, path }) => {
  let isAuthCondition = authCondition;
  document.title = transformString(path) || "Vikn Books"
  return isAuthCondition ? children : <Navigate to="/" />;
};

const route = [
  { path: "purchase-report", element: <PurchaseReport />,  isAuth: activateFunction("PurchaseReport") },
  { path: "purchase-order-report", element: <PurchaseOrderReport />,  isAuth: activateFunction("PurchaseOrderReport") },
  { path: "purchase-register-report", element: <PurchaseRegisterReport />,  isAuth: activateFunction("PurchaseRegisterReport") },
  { path: "purchase-return-register-report", element: <PurchaseReturnRegisterReport />,  isAuth: activateFunction("PurchaseReturnRegisterReport") },
  { path: "supplier-vs-product-report", element: <SupplierVSProductReport />,  isAuth: activateFunction("SupplierVsProductReport") },
  { path: "purchase-integrated-report", element: <PurchaseIntegratedReport />,  isAuth: activateFunction("PurchaseIntegratedReport") },
];

const purchaseRoute = route.map((route) => ({
  ...route,
  element:
  // (
    // <Suspense fallback={<div>Loading...</div>}>
      <ProtectedRoute path={route.path} authCondition={route.isAuth}>{route.element}</ProtectedRoute>,
    // </Suspense>
  // )
}));

export default purchaseRoute;

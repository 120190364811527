function roundOffNumber(value, decimalPlaces) {

    const roundedValue = Math.round(value,2);
    
    return roundedValue
  }
  
  export function SalesInvoiceCalc(dataState, state, DataList,is_manual_roundoff) {
    let TotalTaxableAmount = 0;
    let TotalGrossAmt = 0;
    let TotalDiscount = 0;
    let TotalTax = 0;
    let NetTotal = 0;
    let AdditionalCost = 0;
    let GrandTotal = 0;
    let RoundOff = Number(state.RoundOff) || 0 ;
    let CashReceived = 0;
    let CashAmount = 0;
    let BankAmount = 0;
    let BillDiscPercent = 0;
    let BillDiscAmt = Number(state.BillDiscAmt) || 0;
    let VATAmount = 0;
    let SGSTAmount = 0;
    let CGSTAmount = 0;
    let IGSTAmount = 0;
    let Balance = 0;
    let OldLedgerBalance = 0;
    let ShippingCharge = Number(state.ShippingCharge) || 0;
    let shipping_tax_amount = Number(state.shipping_tax_amount) || 0;
    let TaxTaxableAmount = 0;
    let NonTaxTaxableAmount = 0;
  
    let DetailsAmountTotal = 0;
    console.log(DataList,"Datttttaaaaaaaaaaaaaaaaaa:::::::::::::::<<<<<<<<<<<<<<<<<<<");
    let DataListLength = DataList.filter((i) => i.Product != null)?.length ?? 0;
    let TotalQty = DataListLength;
  
    let BillDiscAmt_Split = 0;
    if (BillDiscAmt && TotalQty) {
      BillDiscAmt_Split = BillDiscAmt / TotalQty;
    }
  
    //Looping dataList
    for (let index = 0; index < DataList.length; index++) {
      const item = DataList[index];
  
      let GrossAmount = Number(item.GrossAmount) || 0;
      let DiscountAmount = Number(item.DiscountAmount) || 0;
      let TaxAmount = Number(item.TaxAmount) || 0;
      let Qty = Number(item.Qty) || 0;
      let Tax = item.Tax
      let SalesTax = Number(Tax?.SalesTax ?? 0);
      let Amount = Number(item.Amount);
  
      TotalGrossAmt += GrossAmount || 0;
      TotalDiscount += DiscountAmount || 0;
      TotalTaxableAmount += GrossAmount - DiscountAmount;
  
      if (TaxAmount > 0) {
        TaxTaxableAmount += GrossAmount - DiscountAmount;
      } else {
        NonTaxTaxableAmount += GrossAmount - DiscountAmount;
      }
  
      //bill disc amount is splitting equally to every details then substraction that with gross amount then finding total tax
      TotalTax += (GrossAmount - (DiscountAmount + BillDiscAmt_Split)) * SalesTax / 100;
  
      DetailsAmountTotal += Amount;
    }
  
    //after
    TotalDiscount += BillDiscAmt;
  
    GrandTotal += TaxTaxableAmount + NonTaxTaxableAmount;
    GrandTotal += TotalTax;
    NetTotal += DetailsAmountTotal + ShippingCharge;
    GrandTotal += ShippingCharge + shipping_tax_amount;
    GrandTotal -= BillDiscAmt;
  


    if (is_manual_roundoff === false){
      let roundedValue= roundOffNumber(GrandTotal, 2);
      console.log(roundedValue,"------------------>>>> Rounded value");
      RoundOff = roundedValue - GrandTotal
      GrandTotal = roundedValue
    }
    else{
      GrandTotal += RoundOff
    }
  
  
    // Update the state
    state.TotalQty = TotalQty;
    state.TotalGrossAmt = TotalGrossAmt;
    state.TotalDiscount = TotalDiscount;
    state.TotalTax = TotalTax;
    state.NetTotal = NetTotal;
    state.TotalTaxableAmount = TotalTaxableAmount;
    state.TaxTaxableAmount = TaxTaxableAmount;
    state.NonTaxTaxableAmount = NonTaxTaxableAmount;
    state.RoundOff = RoundOff
    state.GrandTotal = GrandTotal;
  
    return state;
  }
  
  // Line Calculation
  export function SalesLineCalc(dataState, state, lineItem) {
    let Qty = Number(lineItem.Qty) || 0;
    let Rate = Number(lineItem.Rate) || 0;
    let DiscountAmount = Number(lineItem.DiscountAmount) || 0;
    let Tax = lineItem.Tax;
    let TaxID = lineItem.TaxID;
    let SalesTax = Number(Tax?.SalesTax);
  
    let UnitTaxAmount = (Rate / 100) * SalesTax;
    let InclusivePrice = Rate + UnitTaxAmount;
    let GrossAmount = Rate * Qty;
  
    let TaxableAmount = GrossAmount - DiscountAmount;
    let TaxAmount = GrossAmount - DiscountAmount;
    TaxAmount = (TaxAmount / 100) * SalesTax;
    let Amount = (GrossAmount - DiscountAmount) + TaxAmount;
  
    //update item
    lineItem.TaxableAmount = TaxableAmount;
    lineItem.TaxAmount = TaxAmount;
    lineItem.InclusivePrice = InclusivePrice;
    lineItem.GrossAmount = GrossAmount;
    lineItem.Amount = Amount;
  
    return lineItem;
  }
  
  
  // validation 
  export const PurchaseReturnValidation = (dataState,state,DataList,GeneralSettingsData) => {
  
    let field = null;
    let message = null;
    let error = null;
    let indexList = [];
  
    let Data = DataList
    let length = Data.length;
  
    let is_non_taxable_amount = false
    let is_taxable_amount = false
  
    if (Data.length === 0) {
      error = true;
      message = "At least need one valid row";
    }
    else if (Data.length === 1 && !Data[0].Product) {
      message = "At least need one valid row"
    }
  
    //looping the dataList and checking all mandatory values are included
    Data.map((obj,i) => {
      if (!obj.Product) {
        if (
          i + 1 === length &&
          !obj.ProductCode &&
          !obj.BarCode &&
          !obj.Product &&
          // !obj.Description &&
          !obj.Unit &&
          // !obj.UnitList &&
          !obj.Stock &&
          !obj.Qty &&
          obj.Qty <= 0 &&
          // !obj.FQty &&
          !obj.Rate &&
          // !obj.AvgCost &&
          !obj.Tax &&
          !obj.InclusivePrice &&
          !obj.GrossAmount &&
          // !obj.DiscPerc &&
          !obj.DiscountAmount &&
          !obj.TaxAmount &&
          !obj.Amount &&
          !obj.MRP 
          // !obj.PurchasePrice &&
          // !obj.BatchCode &&
          // !obj.MinimumSalesPrice &&
          // !obj.AverageCost 
        ) {
        }  else {
          indexList.push(i);
        }
      } else if (!obj.Product) {
        indexList.push(i);
      } else if (!obj.Unit) {
        indexList.push(i);
      } else if (!obj.Qty && Number(obj.Qty) <= 0) {
        indexList.push(i);
      } else if (!obj.Rate && Number(obj.Rate)<= 0 ) {
        indexList.push(i);
      }
      else if (!obj.Tax) {
        indexList.push(i);
      }
      else if (!obj.InclusivePrice) {
        indexList.push(i);
      }
      else if (!obj.GrossAmount) {
        indexList.push(i);
      }
      else if (!obj.TaxAmount && obj?.Tax?.TaxName !== "None" ) {
        indexList.push(i);
      }
      else if (!obj.Amount) {
        indexList.push(i);
      }
  
      if (obj.Product){
        //checking is there any non tax amount and tax amount
        if (obj.Tax.TaxName === "None"){
          is_non_taxable_amount = true
        }
        else{
          is_taxable_amount = true
        }
        }
    });
  
    let newIndexList = indexList.map(i => i + 1) 
    
    if (indexList.length > 0) {
      message = "Data missing Lines" + newIndexList;
      console.log(indexList,'lllLLLLLLLLLLPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP');
      error = true;
    }
  
      //checking values of state
      if (!state.AccountLedger && !error) {
        error = true;
        message = "Provide valid AccountLedger";
        } 
        else if (!state.Treatment && !error) {
            error = true;
            message = "Provide valid Treatment";
        }
        else if (!state.PlaceOfSupply && !error) {
            error = true;
            message = "Provide valid PlaceOfSupply";
        }
        else if (!state.Date && !error) {
            error = true;
            message = "Provide valid Date";
        }
        else if (!state.TotalGrossAmt && !error) {
            error = true;
            message = "Provide valid TotalGrossAmt";
        }
        else if (!state.TotalTax && !error && is_taxable_amount) {
            error = true;
            message = "Provide valid TotalTax";
        }
  
        else if (!state.NetTotal && !error) {
            error = true;
            message = "Provide valid NetTotal";
        }
  
        else if (!state.TotalTaxableAmount && !error) {
            error = true;
            message = "Provide valid TotalTaxableAmount";
        }
        else if (!state.TaxTaxableAmount && !error && is_taxable_amount) {
            error = true;
            message = "Provide valid TaxTaxableAmount";
        }
        else if (!state.NonTaxTaxableAmount && !error && is_non_taxable_amount) {
            error = true;
            message = "Provide valid NonTaxTaxableAmount";
        }
  
        else if (!state.GrandTotal && !error) {
            error = true;
            message = "Provide valid GrandTotal";
        }
  
      console.log(error,";ppppppppppppppppppppppppppppperrrororo");
      return {error, message, field, indexList};
  }
import {
  Box,
  Divider,
  List,
  ListItem,
  Paper,
  Typography,
  styled,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import FilterLable from "../../../../Components/Utils/FilterLable";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import VBSelect from "../../../../Components/Utils/VBSelect";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import moment from "moment";
import { date_range } from "../../../../Function/comonFunction";
import { CallTrialBalance } from "../../../../Api/Reports/FinanceApi";
import { useSelector,useDispatch } from "react-redux";
import VBSwitch from "../../../../Components/Utils/VBSwitch";
import TrialBalanceTable from "./Components/TrialBalanceTable";
import { ClosingTrialBalanceTable } from "./Components/ClosingTrialBalanceTable";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { useTranslation } from "react-i18next";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { CallBranchsList } from "../../../../Api/Reports/CommonReportApi";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { UserRolePermission } from "../../../../Function/Editions";

const TrialBalance = () => {
  let Export_view = UserRolePermission("Trial Balance", "print_permission");
  // Declear state
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [page_no, setPage_no] = useState(1);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,EnableBranch} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const isAdmin = BranchID === 1 ? true : false;
  const [isLoadingBranch, setIsLoadingBranch] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    FromDate: moment(),
    ToDate: moment(),
    select: "AVERAGE",
    dateRange: "Today",
    balanceSelect: "Trial Balance",

    BranchList: [],
    SelectedBranches: [],
    IsSelectedAllBranch: false,
  });
  const [filterData, setFilterData] = useState({
    trileBalanceList: [],
  });
  const [expanded, setExpanded] = useState([]);
  const [expandOneAtATime, setExpandOneAtATime] = useState(true); // State for toggle button
  const [isLoadiing, setIsLoadiing] = useState(false)

  // Handle fundtions
  const closeFilter = () => setOpenFilter(false);
  const handleChangeExpand = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) =>
      isExpanded && expandOneAtATime
        ? [panel]
        : isExpanded
        ? [...prevExpanded, panel]
        : prevExpanded.filter((item) => item !== panel)
    );
  };

  const handleToggleExpand = () => {
    setExpandOneAtATime(!expandOneAtATime);
    setExpanded([]); // Reset expanded accordions when toggling
  };

  const filterReport = function () {
    fetchReport();
  };

  const handleDefaultFilter = function() {
    let BranchIDList = filterOptions.BranchList.filter((i) => {
      if (i.BranchID === BranchID) {
        return i;
      }
    });

    let SelectedBranches = [];
    SelectedBranches.push(BranchIDList[0]);
    setFilterOptions({
      FromDate: moment(),
      ToDate: moment(),
      select: "AVERAGE",
      dateRange: "Today",
      balanceSelect: "Trial Balance",
      SelectedBranches,
      IsSelectedAllBranch: false,
    })
  }
  const branchCheckChange = (name) => {
    let SelectedBranches = [];
    if (!filterOptions[name] === true) {
      filterOptions.BranchList.map((i) => {
        if (SelectedBranches.includes(i) === false) {
          SelectedBranches.push(i);
        }
      });

      setFilterOptions({
        ...filterOptions,
        SelectedBranches,
        [name]: !filterOptions[name],
      });
    } else {
      setFilterOptions({
        ...filterOptions,
        [name]: !filterOptions[name],
      });
    }
  };
  const handleAutoComplete = function (e, value, name) {
    setFilterOptions({
      ...filterOptions,
      IsSelectedAllBranch: false,
      [name]: value,
    });
  };
  // Fetch data
  const fetchReport = async function (array) {
    let BranchList = [BranchID]
    let IsAllBranch = false
     //if all branch selected
    if (isAdmin === true && filterOptions.IsSelectedAllBranch === true) {
      IsAllBranch = true;
    }

    //if any branch selected
    let SelectedBranches = array ? array : filterOptions.SelectedBranches;
    if (SelectedBranches.length !== 0 && isAdmin) {
      BranchList = []
      SelectedBranches.map((i) => {
        i.BranchID
          ? BranchList.push(i.BranchID)
          : console.log(`no branchid${i}`);
      });
    }
    const res = await CallTrialBalance({
      FromDate: filterOptions.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.ToDate.format("YYYY-MM-DD"),
      templateType: filterOptions.balanceSelect,
      items_per_page: 17,
      dateRange: filterOptions.dateRange,
      filterMethod: filterOptions.select,
      page_no: page_no,
      CompanyID: CompanyID,
      BranchID: BranchList,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
    });
    

    if (res.StatusCode === 6000) {
      const groupedAccountGroups = res.data.reduce((acc, curr) => {
        if (!acc[curr.AccountGroupName]) {
          acc[curr.AccountGroupName] = {
            AccountGroupName: curr.AccountGroupName,
            TotalCredit: 0,
            TotalDebit: 0,
            OpeningCredit: 0,
            OpeningDebit: 0,
            ClosingCredit: 0,
            ClosingDebit: 0,
            data: [],
          };
        }else {
          acc[curr.AccountGroupName].data.push(curr);
        }
        // acc[curr.AccountGroupName].TotalCredit += curr.TotalCredit;
        // acc[curr.AccountGroupName].TotalDebit += curr.TotalDebit;
        
        
        if (curr.LedgerName === "") {
          // acc[curr.AccountGroupName].OpeningCredit += curr.OpeningCredit;
          // acc[curr.AccountGroupName].OpeningDebit += curr.OpeningDebit;
          // acc[curr.AccountGroupName].ClosingCredit += curr.ClosingCredit;
          // acc[curr.AccountGroupName].ClosingDebit += curr.ClosingDebit;
          acc[curr.AccountGroupName].TotalCredit += curr.TotalCredit;
          acc[curr.AccountGroupName].TotalDebit += curr.TotalDebit;
        }
        return acc; 
      }, {});

    //   const groupedAccountGroups = res.data.reduce((acc, curr) => {
    //     if (!acc[curr.AccountGroupName]) {
    //         acc[curr.AccountGroupName] = {
    //             AccountGroupName: curr.AccountGroupName,
    //             TotalCredit: curr.TotalCredit,
    //             TotalDebit: curr.TotalDebit,
    //             OpeningCredit: curr.OpeningCredit,
    //             OpeningDebit: curr.OpeningDebit,
    //             ClosingCredit: curr.ClosingCredit,
    //             ClosingDebit: curr.ClosingDebit,
    //             data: [curr], // Create a new array with the current data
    //         };
    //     } else {
    //         acc[curr.AccountGroupName].data.push(curr); // Add current data to existing group
    //     }
    //     return acc;
    // }, {});
    

      // const closingAccountGroup = res.data.reduce((acc, curr) => {
      //   if (!acc[curr.AccountGroupName]) {
      //     acc[curr.AccountGroupName] = {
      //       AccountGroupName: curr.AccountGroupName,
      //       TotalCredit: 0,
      //       TotalDebit: 0,
      //       data: [],
      //     };
      //   }
      //   acc[curr.AccountGroupName].data.push(curr);
      //   acc[curr.AccountGroupName].TotalCredit += curr.TotalCredit;
      //   acc[curr.AccountGroupName].TotalDebit += curr.TotalDebit;
      //   return acc;
      // }, {});

      const result = Object.values(groupedAccountGroups);
      setFilterData({ ...filterData, trileBalanceList: result });
      setIsLoadiing(false)
    }


  };
  const filterApis = async function () {
    setIsLoadingBranch(true);
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      show_all: true,
    };
    const res = await CallBranchsList(payload);
    let ledgerList = [];
    if (res.StatusCode === 6000) {
      let BranchIDList = res.data.filter((i) => {
        if (i.BranchID === BranchID) {
          return i;
        }
      });

      let SelectedBranches = [];
      SelectedBranches.push(BranchIDList[0]);
      setFilterOptions({
        ...filterOptions,
        BranchList: res.data,
        SelectedBranches,
      });
    } else if (res.StatusCode === 6001) {
      dispatch(
        openSnackbar({
          open: true,
          message: res.message,
          severity: "warning",
        })
      );
    }
    setIsLoadingBranch(false);
  };

  useEffect(() => {
    filterApis();
  }, []);
  useEffect(() => {
    setIsLoadiing(true)
    fetchReport();
  }, [filterOptions.balanceSelect]);
console.log(filterOptions,"filteroption===>");
  return (
    // <div style={{ padding:"10px", height: "100%", width: "99%" }}>
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        {/* Top header */}
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t("Trial Balance")}
            </Typography>
            {/* <SearchReport /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <Box>
            {/* <ExportBtn /> */}
            {/* <PrintBtn />
            <Pagenation /> */}
            {Export_view ? 
            <ReportDownload
            download_type={"PDF"}
            invoice_type={"trial_balance"}
            FromDate={filterOptions.FromDate.format("YYYY-MM-DD")}
            ToDate={filterOptions.ToDate.format("YYYY-MM-DD")}
            UserID={user_id}
            filterValue={filterOptions.select}
            templateType={filterOptions.balanceSelect}
            checking={filterOptions.balanceSelect}
            BranchID = {`[${filterOptions.SelectedBranches.map((i) => i.BranchID)}]`}
            />
            : null}
          </Box>
        </Box>

        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
            {t("Applied Filters: ")}
          </div>
          <FilterLable name={"Date Range"} value={filterOptions.dateRange} />
          <FilterLable name={"Average"} value={filterOptions.select} />
          <FilterLable name={"Balance"} value={filterOptions.balanceSelect} />
          {filterOptions.SelectedBranches.length !== 0 &&
            // BranchData.IsSelectedAllBranch === false &&
            EnableBranch &&
            BranchID === 1 &&
            isAdmin === true && (
              <FilterLable
                name={t("Branch Name")}
                value={filterOptions.SelectedBranches.map((e, i) => (
                  <Chip
                    key={i}
                    label={e.NickName}
                    variant="outlined"
                    sx={{
                      p: "1px",
                      fontSize: "10px",
                      height: "22px",
                      mr: "4px",
                      ".MuiChip-label": {
                        px: "8px",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "15px",
                        color: "#ff00006b",
                      },
                    }}
                    onDelete={() => {
                      let SelectedBranches = filterOptions.SelectedBranches.filter(
                        (l) => l.BranchID !== e.BranchID
                      );
                      setFilterOptions({
                        ...filterOptions,
                        IsSelectedAllBranch: false,
                        SelectedBranches: SelectedBranches,
                      });

                      fetchReport(SelectedBranches);
                    }}
                  />
                ))}
                handleDelete={() => {
                  let get_BranchID  = filterOptions.BranchList.filter((i) => {
                    if (i.BranchID === BranchID) {
                      return i;
                    }
                  });

                  let SelectedBranches = [];
                  SelectedBranches.push(get_BranchID[0]);
                  setFilterOptions({
                    ...filterOptions,
                    SelectedBranches,
                    IsSelectedAllBranch: false,
                  });
                  fetchReport(SelectedBranches);
                }}
              />
            )}


        </Box>

        {filterOptions.balanceSelect === "Trial Balance" ? (
          <TrialBalanceTable
            date={{fromDate: filterOptions.FromDate, toDate: filterOptions.ToDate}}
            isLoadiing={isLoadiing}
            expanded={expanded}
            handleChangeExpand={handleChangeExpand}
            filterData={filterData}
          />
        ) : (
          <ClosingTrialBalanceTable
            isLoadiing={isLoadiing}
            expanded={expanded}
            handleChangeExpand={handleChangeExpand}
            filterData={filterData}
            date={{fromDate: filterOptions.FromDate, toDate: filterOptions.ToDate}}
          />
        )}
      </Paper>

      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={filterReport}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.FromDate}
                toDate={filterOptions.ToDate}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    FromDate: value,
                    dateRange: "Custom"
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    ToDate: value,
                    dateRange: "Custom"
                  })
                }
                // fromReadOnly={filterOptions.dateRange !== "Custom"}
                // toReadOnly={filterOptions.dateRange !== "Custom"}
              />
            </ListItem>

            <ListItem>
              <VBSelect
                value={filterOptions.dateRange}
                options={[
                  "Custom",
                  "Today",
                  "This Week",
                  "This Month",
                  "Last 90 Days",
                  "This Year",
                  "Last Year",
                ]}
                handleChange={(e) =>
                  setFilterOptions({
                    ...filterOptions,
                    dateRange: e.target.value,
                    FromDate: date_range(e.target.value).fromdate,
                    ToDate: date_range(e.target.value).todate,
                  })
                }
              />
            </ListItem>
            <Divider />

            <ListItem>
              <VBSelect
                label={t("Average")}
                value={filterOptions.select}
                options={["AVERAGE", "LIFO", "FIFO"]}
                handleChange={(e) =>
                  setFilterOptions({ ...filterOptions, select: e.target.value })
                }
              />
            </ListItem>
            <ListItem>
              <VBSelect
                label={t("Balance")}
                value={filterOptions.balanceSelect}
                options={["Trial Balance", "Closing Trial Balance"]}
                handleChange={(e) =>
                  setFilterOptions({
                    ...filterOptions,
                    balanceSelect: e.target.value,
                  })
                }
              />
            </ListItem>
            <Divider />
            {isAdmin === true && EnableBranch && BranchID === 1 && (
              <ListItem>
                <ViknCheckboxGroup
                  radios={[
                    {
                      label: t("All Branches"),
                      checked: filterOptions.IsSelectedAllBranch,
                      onChange: () => branchCheckChange("IsSelectedAllBranch"),
                    },
                  ]}
                />
              </ListItem>
            )}

            {isAdmin === true && EnableBranch && BranchID === 1 && (
              <ListItem>
                <ViknAutoComplete
                  disabled={filterOptions.IsSelectedAllBranch}
                  multiple
                  value={filterOptions.SelectedBranches}
                  placeholder={"Select Branches"}
                  options={filterOptions.BranchList}
                  getOptionLabel={(option) => option.NickName}
                  onChange={(e, newValue) =>
                    handleAutoComplete(e, newValue, "SelectedBranches")
                  }
                />
              </ListItem>
            )}

            <Divider />
            <ListItem>
              <VBSwitch
                checked={!expandOneAtATime}
                onChange={handleToggleExpand}
                label={
                  expandOneAtATime
                    ? "Now Expand One at a Time"
                    : "Now Expand Multiple"
                }
              />
            </ListItem>
          </List>
        }
      />
    </>
    // </div>
  );
};

export default TrialBalance;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // width: "21%",
}));

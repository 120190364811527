import { useSelector } from "react-redux";
import { store } from "../app/store";
import { Essential, Lite, Standard } from "../Edition/edition";
const state = store.getState();
const { EnableProductBatchWise,GST,VAT,EnableFaeraSettings } = state.generalSettingsSclice.generalSettings;
const { Edition } = state.companyDetails;
const edtions = [
    
]
export const GetEditionPermission = (
  Edition,
  Value,
  GST,
  VAT,
  EnableProductBatchWise
) => {
  let result = true;

  // eg : LoyaltyCustomer is removing from edition lite essential and standard
  if (Edition === "Lite" || Edition === "Essential" || Edition === "Standard") {
    if (
      Value === "LoyaltyCustomer" ||
      Value === "Loyalty Customer" ||
      Value === "LoyalityProgram" ||
      Value === "Loyalty Program" ||
      Value === "Loyalty" ||
      // Value === "PriceCategory" ||
      // Value === "Price Category" ||
      // Value === "Route" ||
      Value === "BankReconciliation" ||
      Value === "Bank Reconciliation" ||
      Value === "BankReconciliationReport" ||
      Value === "Purchase Return Register Report" ||
      Value === "PurchaseReturnRegisterReport" ||
      Value === "ProductReport" ||
      Value === "Product Report" ||
      // Value === "StockAdjustment" ||
      Value === "ShortageStockReport" ||
      // Value === "ShortageStock" ||
      Value === "Shortage Stock Report" ||
      Value === "ExcessStockReport" ||
      // Value === "ExcessStock" ||
      Value === "Excess Stock Report" ||
      Value === "VanReport" ||
      Value === "VanStockReport" ||
      Value === "ActivityLog" ||
      Value === "VanSalesSettings"||
      Value === "Manufacture"
    ) {
      result = false;
    }
    console.log(result);
  }

  if (Edition === "Lite" || Edition === "Essential") {
    if (
      Value === "DashboardChart" ||
      Value === "ProductSummary" ||
      Value === "OutstandingReport" ||
      Value === "Outstanding Report" ||
      Value === "List" ||
      Value === "ListReport" ||
      // Value === "DayReport" ||
      // Value === "CashBook" ||
      // Value === "Cash Book" ||
      // Value === "BankBook" ||
      // Value === "Bank Book" ||
      Value === "Sales Order" ||
      // Value === "SalesEstimate" ||
      // Value === "Sales Estimate" ||
      Value === "SalesSummary" ||
      Value === "Sales Summary Report" ||
      Value === "BillwiseProfit" ||
      Value === "Bill Wise Report" ||
      // Value === "PurchaseOrder" ||
      // Value === "Purchase Order" ||
      Value === "Purchase Estimate" ||
      // Value === "Purchase Order Report" ||
      // Value === "PurchaseOrderReport" ||
      Value === "Warehouse" ||
      Value === "Shelf" ||
      Value === "WorkOrder" ||
      Value === "Work Order" ||
      Value === "StockTransfer" ||
      Value === "Stock Transfer" ||
      Value === "Inventory" ||
      // Value === "OpeningStockReport" ||
      // Value === "Opening Stock Report" ||
      Value === "StockTransferRegister" ||
      Value === "SupplierVsProductReport" ||
      Value === "Supplier Vs Product Report" ||
      Value === "ProductAnalysisReport" ||
      Value === "Product Analysis Report" ||
      Value === "BatchWiseReport" ||
      Value === "Batch Wise Report" ||
      Value === "PriceChecker" ||
      Value === "Sales GST Report" ||
      Value === "Purchase GST Report" ||
      // Value === "GSTR1" ||
      // Value === "GSTR1 Report" ||
      // Value === "GSTR2" ||
      Value === "GSTR3B" ||
      Value === "VATReport" ||
      Value === "Sales VAT Report" ||
      Value === "Purchase VAT Report" ||
      Value === "BatchReport" ||
      // Value === "ExpenseList" ||
      Value === "Route" ||
      Value === "Batch Report" ||
      Value === "faera report" ||
      Value === "Enable route"||
      Value === "StockOrder" ||
      Value === "StockManagement" ||
      Value === "Stock Management" ||
      Value === "PriceCategory" ||
      Value === "Price Category" ||
      Value === "SalesRegisterReport" ||
      Value === "Sales Register Report" ||
      Value === "SalesIntegratedReport" ||
      Value === "Sales Integrated Report" ||
      Value === "SalesReturnRegisterReport" ||
      Value === "Sales Return Register Report" ||
      Value === "PurchaseRegisterReport" ||
      Value === "Purchase Register Report" ||
      Value === "PurchaseIntegratedReport" ||
      Value === "Purchase Integrated Report" ||
      Value === "CreditorAgeingreport"||
      Value === "DebtorAgeingreport" ||
      Value === "ExpenseSummary" ||
      Value === "QuickReport" ||
      Value === "BranchTransfer" ||
      Value ==="DeliveryNote"||
      Value === "EnableForeignCurrency"||
      Value === "EnableAdditionalExpense"||
      Value === "DebitNote" ||
      Value === "CreditNote"

    ) {
      if (Value === "BatchReport" || Value === "Batch Report") {
        if (EnableProductBatchWise === false) {
          result = false;
        }
      } else if (
        Value === "Sales GST Report" ||
        Value === "Purchase GST Report" ||
        // Value === "GSTR1" ||
        // Value === "GSTR1 Report" ||
        // Value === "GSTR2" ||
        Value === "GSTR3B"
      ) {
        if (GST === false) {
          result = false;
        }
      } else if (
        Value === "VATReport" ||
        Value === "Sales VAT Report" ||
        Value === "Purchase VAT Report"
      ) {
        if (VAT === false) {
          result = false;
        }
      } else if (Value === "faera report") {
        result = false;
      } else {
        result = false;
      }
    }
  }

  if (Edition === "Lite") {
    if (
      Value === "OpeningStock" ||
      Value === "Opening Stock" ||
      Value === "AccountGroup" ||
      Value === "Account Group" ||
      // Value === "Bank" ||
      // Value === "Banks" ||
      // Value === "LedgerReport" ||
      // Value === "Ledger Report" ||
      Value === "PurchaseTaxReport" ||
      Value === "SalesTaxReport" ||
      // Value === "PaymentReport" ||
      // Value === "Payment Report" ||
      // Value === "ReceiptReport" ||
      // Value === "Receipt Report" ||
      // Value === "StockReport" ||
      Value === "TrialBalance" ||
      Value === "Trial Balance" ||
      Value === "TradingProfitAndLossAccount" ||
      Value === "Profit And Loss Account" ||
      Value === "BalanceSheet" ||
      Value === "Balance Sheet" ||
      Value === "SalesOrder" ||
      Value === "SalesOrderReport" ||
      Value === "SalesEstimate" ||
      Value === "Sales Estimate" ||
      Value === "ExpenseReport" ||
      Value === "ExpenseList" ||
      Value === "MultiUnit" ||
      Value === "StockLedgerReport" ||
      Value === "StockValueReport" ||
      Value === "Stock Value Report" ||
      Value === "DailyReport" ||
      Value === "Stock Ledger Report" ||
      Value === "BOM"
      // Value === "Stock Report"
    ) {
      result = false;
    }
  }

  if (Value === "faera report" && EnableProductBatchWise === false) {
    result = false;
  }
  // if (Value === "VATReport" && GST ){
  //   result = false;
  // }

  return result;
};
export const UserRolePermission = (Name, Action) => {
  let user_role_settings = useSelector((state) => state.userRollSettings);
  let permission_val = true;
  if(user_role_settings){
    let permission_task = user_role_settings.filter((i) => i.name === Name);
    if (Action === "view_permission") {
      permission_val = permission_task[0]
        ? permission_task[0].view_permission
        : true;
    } else if (Action === "save_permission") {
      permission_val = permission_task[0]
        ? permission_task[0].save_permission
        : true;
    } else if (Action === "edit_permission") {
      permission_val = permission_task[0]
        ? permission_task[0].edit_permission
        : true;
    } else if (Action === "print_permission") {
      permission_val = permission_task[0]
        ? permission_task[0].print_permission
        : true;
    } else if (Action === "delete_permission") {
      permission_val = permission_task[0]
        ? permission_task[0].delete_permission
        : true;
    }
    else if (Action === "purchase_price"){
      permission_val = permission_task[0]
      ?permission_task[0].purchase_price
      :true;
    }
  }
  return permission_val;
};

export const activateFunction = function (name) {

  let is_Active = true
  if(Edition==="Lite" &&(Lite[name]===false || Lite[name]===true)){
      is_Active = Lite[name];
  }
  else if (Edition==="Essential"&&(Essential[name]===false || Essential[name]===true)){
  // else if ((Essential[name]===false || Essential[name]===true)){
    is_Active = Essential[name];

  }else if(Edition==="Standard"&&(Standard[name]===false || Standard[name]===true)){
  // }else if(Edition==="Standard"&& (Standard[name]===false || Standard[name]===true)){
    is_Active = Standard[name];
  }
  console.log("activateFunction===>",is_Active,"Edition===>",Edition,VAT,GST);
  return is_Active;

  // if (name === "branch transfer report" && name === "batch report") {
  //   return false;
  // }
  // return true;
};




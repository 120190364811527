import * as React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { InvoiceAutoComplete, InvoiceTextBox } from "./CommonComponents";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
} from "@mui/material";
import {
  CloseButton,
  CreateButton,
  CreateNewButton,
  CustomButton,
  DoneButton,
  ListButton,
  LoadingButton,
} from "./Utils/StyledButtons";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "./ReportTable/ReportTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  border: "none",
};

const historyModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  // boxShadow: 24,
  // p: 3,
  border: "none",
  padding: "20px",
  // height:"400px",
  overFlow:"scroll"
};

export function AddressModal(props) {
  const {
    type,
    state,
    setState,
    open,
    setOpen,
    List,
    name,
    Address,
    setAddress,
    Call_States_of_Country_api,
    api,
  } = props;

  const [IsCreateNew, setIsCreateNew] = React.useState(false);
  const [IsEdit, setIsEdit] = React.useState(false);

  const [t, i18n] = useTranslation("common");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (type === "Shipping") {
      setState({ ...state, ShippingAddress: null });
    } else if (type === "Billing") {
      setState({ ...state, BillingAddress: null });
    }
    setAddress({
      ...Address,
      Attention : "", //Name
      PostalCode : "", //Pincode
      Address1 : "", //Building
      Address2 : "", //Area
      City : "",
      District : "",
      country : null,
      state : null,
      Mobile : "",
    })
    setIsCreateNew(false);
    setOpen(false);
  };

  const onClickItem = async (index) => {
    let item = List[index];
    if (type === "Shipping") {
      setState({ ...state, ShippingAddress: item });
    } else if (type === "Billing") {
      setState({ ...state, BillingAddress: item });
    }
  };

  const onSelect = async (index) => {
    setOpen(false);
  };

  const onChange = (e) => {
    if (e) {
      const { name, value } = e.target;
      if (type === "Shipping") {
        setAddress({ ...Address, [name]: value });
      } else if (type === "Billing") {
        setAddress({ ...Address, [name]: value });
      }
    }
  };

  const onAutoCompleteChange = async (e, v, name) => {
    if (v && name) {
      let value = v;
      if (name === "country") {
        let StateData = [];
        let res = await Call_States_of_Country_api(v.id);
        if (res) {
          StateData = res;
        }
        setAddress({ ...Address, [name]: value, StateData });
      } else {
        setAddress({ ...Address, [name]: value });
      }
    }
  };

  const onSubmit = async () => {
    let res = await api(Address, "create");
    if (res === true) {
      setOpen(false);
      
      setAddress({
        ...Address,
        Attention : "", //Name
        PostalCode : "", //Pincode
        Address1 : "", //Building
        Address2 : "", //Area
        City : "",
        District : "",
        country : null,
        state : null,
        Mobile : "",
      })
    }
  };

  let data = List ? List : [];
  let Selected =
    type === "Shipping"
      ? state.ShippingAddress
      : type === "Billing"
      ? state.BillingAddress
      : null;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* =============SELECT ADDRESS SECTION=========== */}

          {IsCreateNew === false ? (
            <>
              {/* {data?.length > 0 ? ( */}
              <h4
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#001746",
                }}
              >
                Select {name}
              </h4>
              {/* ) : null} */}
              <div
                style={{
                  display: "flex",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                }}
              >
                {data.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      minWidth: "350px",
                      border: "1px solid #EFEFEF",
                      borderRadius: "10px",
                      margin: "5px",
                      flex: "0 0 auto",
                      height: "120px",
                      cursor: "pointer",
                      backgroundColor: Selected
                        ? Selected.id === item.id
                          ? "#70706712"
                          : null
                        : null,
                    }}
                    onClick={() => {
                      onClickItem(index);
                    }}
                  >
                    <div
                      style={{
                        padding: "0px 3px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ margin: "1px", fontSize: "13px" }}>TO :</p>
                      <p style={{ margin: "1px", fontSize: "13px" }}>
                        {item.Address1}
                      </p>
                    </div>
                    <div
                      style={{
                        padding: "0px 3px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ margin: "1px", fontSize: "13px" }}>
                        Address :
                      </p>
                      <p style={{ margin: "1px", fontSize: "13px" }}>
                        {item.Address2 +
                          "" +
                          item.City +
                          "" +
                          item.District +
                          "" +
                          item.PostalCode}
                      </p>
                    </div>
                    <div
                      style={{
                        padding: "0px 3px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ margin: "1px", fontSize: "13px" }}>
                        Mobile :
                      </p>
                      <p style={{ margin: "1px", fontSize: "13px" }}>
                        {item.Mobile}
                      </p>
                    </div>
                  </div>
                ))}

                {/* IF NO DATA */}
                {data?.length <= 0 ? (
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#001746",
                    }}
                  >
                    No Data!
                  </p>
                ) : null}
              </div>

              <br></br>
              <br></br>
              {/* SELECT AN ADDRESS */}
              <CustomButton
                name={"Select"}
                bg1={"#0A9EF3"}
                bg2={"#6AC9FF"}
                bg3={"#003D60"}
                color={"white"}
                width={"110px"}
                onClick={onSelect}
              />
              {/* CREATE NEW  */}
              <CreateNewButton
                // name={"Create New"}
                // bg1={"#57b697"}
                // bg2={"#57b697"}
                // bg3={"#57b697"}
                // color={"white"}
                // width={"170px"}
                t={t}
                onClick={() => {
                  setIsCreateNew(true);
                }}
              />
              <CloseButton t={t} onClick={handleClose} />
              <br></br>
            </>
          ) : (
            <>
              <h4
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#001746",
                }}
              >
                Create New {name}
              </h4>
              <Box
                sx={{
                  px: "26px",
                  py: "15px",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "2px solid #F5F5F5",
                  height: "auto",
                }}
              >
                {/* ============SECTION-1============== */}
                <div class="sales-grid-item no-border-and-shadow">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "0",
                      width: "450px",
                      padding: "5px",
                    }}
                  >
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      Name:
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      <InvoiceTextBox
                        name={"Attention"}
                        label={"Type a Name"}
                        Value={Address.Attention}
                        OnChange={onChange}
                      />
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "0",
                      width: "450px",
                      padding: "5px",
                    }}
                  >
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      Building, Company, Apartment:
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      <InvoiceTextBox
                        name={"Address1"}
                        label={"Type a Building, Company, Apartment"}
                        Value={Address.Address1}
                        OnChange={onChange}
                      />
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "0",
                      width: "450px",
                      padding: "5px",
                    }}
                  >
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      Town/City:
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      <InvoiceTextBox
                        name={"City"}
                        label={"Type a City"}
                        Value={Address.City}
                        OnChange={onChange}
                      />
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "0",
                      width: "450px",
                      padding: "5px",
                    }}
                  >
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      Country :
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      <InvoiceAutoComplete
                        name={"country"}
                        label={"Select a country"}
                        optionLabel={"Country_Name"}
                        List={Address.CountryData}
                        Value={Address.country}
                        OnChange={onAutoCompleteChange}
                        // OnInputChange = {onMasterInputChange}
                      />
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "0",
                      width: "450px",
                      padding: "5px",
                    }}
                  >
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      Mobile:
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      <InvoiceTextBox
                        name={"Mobile"}
                        label={"Type a Mobile"}
                        Value={Address.Mobile}
                        OnChange={onChange}
                      />
                    </Typography>
                  </div>
                </div>

                {/* ===============SECTION-2============= */}
                <div class="sales-grid-item no-border-and-shadow">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "0",
                      width: "450px",
                      padding: "5px",
                    }}
                  >
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      Pincode:
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      <InvoiceTextBox
                        name={"PostalCode"}
                        label={"Type a PostalCode"}
                        Value={Address.PostalCode}
                        OnChange={onChange}
                      />
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "0",
                      width: "450px",
                      padding: "5px",
                    }}
                  >
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      Area, Colony, Street, Sector, Village:
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      <InvoiceTextBox
                        name={"Address2"}
                        label={"Type a Area, Colony, Street, Sector, Village"}
                        Value={Address.Address2}
                        OnChange={onChange}
                      />
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "0",
                      width: "450px",
                      padding: "5px",
                    }}
                  >
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      District:
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      <InvoiceTextBox
                        name={"District"}
                        label={"District"}
                        Value={Address.District}
                        OnChange={onChange}
                      />
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "0",
                      width: "450px",
                      padding: "5px",
                    }}
                  >
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      State:
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      sx={{ fontSize: "13px", margin: 0 }}
                    >
                      <InvoiceAutoComplete
                        name={"state"}
                        label={"Select a state"}
                        optionLabel={"Name"}
                        List={Address.StateData}
                        Value={Address.state}
                        OnChange={onAutoCompleteChange}
                        // OnInputChange = {onMasterInputChange}
                      />
                    </Typography>
                  </div>
                </div>
              </Box>
            </>
          )}
          <br></br>

          {IsCreateNew === true ? (
            <>
              <CustomButton
                name={"Create"}
                bg1={"#0A9EF3"}
                bg2={"#6AC9FF"}
                bg3={"#003D60"}
                color={"white"}
                width={"110px"}
                onClick={onSubmit}
              />

              <ListButton
                t={t}
                onClick={() => {
                  setIsCreateNew(false);
                }}
              />
              <CloseButton t={t} onClick={handleClose} />
            </>
          ) : null}
          {/* CREATE */}
        </Box>
      </Modal>
    </div>
  );
}

export function HistoryModal(props) {
  const {Type,heading,List,open,setOpen,Call_Product_History_api, dataState ,setDataState } = props
  
  
  const [t, i18n] = useTranslation("common");
  // const [open, setOpen] = React.useState(true);

  const [loading, setLoading] = React.useState(false);
  const [PageNo, setPageNo] = React.useState(1);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setDataState({...dataState,SalesHistory:[],PurchaseHistory:[]})
    
  }
  
  
  const handleLoadMore = async() =>{
    let page_no = PageNo + 1
    setPageNo(page_no)
    let HistoryResponse = await Call_Product_History_api({
      Type:Type,
      items_per_page:5,
      page_no:page_no
    })
    if (Type === "Sales"){
      setDataState({...dataState,SalesHistory:[...dataState.SalesHistory,...HistoryResponse]})
    }
    else{
      setDataState({...dataState,PurchaseHistory:[...dataState.PurchaseHistory,...HistoryResponse]}) 
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={historyModalStyle}>
          
          <div style={{display:"flex",justifyContent:"space-between"}}>
          <h4
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#001746",
              margin:0
              
            }}
          >
            {heading}
          </h4>
          
          <h4
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#0A9EF3",
              cursor:"pointer",
              margin:0
              
            }}
            onClick={handleLoadMore}
          >
            Load More+
          </h4>
            
          </div>

          <VBTableContainer height = {"250px"} >
            <VBTableHeader>
              <VBTableCellHeader>{t("No")}</VBTableCellHeader>
              <VBTableCellHeader>{t("Ledger Name")}</VBTableCellHeader>
              <VBTableCellHeader>{t("Voucher No")}</VBTableCellHeader>
              <VBTableCellHeader>{t("Date")}</VBTableCellHeader>
              <VBTableCellHeader>{t("Qty")}</VBTableCellHeader>
              <VBTableCellHeader>{t("Unit")}</VBTableCellHeader>
              <VBTableCellHeader>{t("Price")}</VBTableCellHeader>
            </VBTableHeader>

            {loading ? (
              <VBSkeletonLoader />
            ) : (
              <TableBody>
                {List.map((data, i) => (
                  <TableRow>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                    {i+1}
                    </VBTableCellBody>
                    <VBTableCellBody  sx={{ 
                      cursor: "pointer",
                      maxWidth: "30px",
                      fontSize:"12px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                      }}>
                      {" "}
                      {data.LedgerName}{" "}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {" "}
                      {data.VoucherNo}{" "}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {" "}
                      {data.Date}{" "}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {" "}
                      {Number(data.Qty).toFixed(2)}{" "}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {" "}
                      {data.UnitName}{" "}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {" "}
                      {Number(data.UnitPrice).toFixed(2)}{" "}
                    </VBTableCellBody>
                    
                  </TableRow>
                ))}
                <VBTableRowNull
                length={
                  12//state.data_count ? state.data_count : state.dataList.length
                }
                />
              </TableBody>
            )}
          </VBTableContainer>
        </Box>
      </Modal>
    </div>
  );
}

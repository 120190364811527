import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputBase,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 850,
  backgroundColor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  padding: "10px",
  maxHeight: "70%",
  overflow: "scroll",
  // border: "2px solid #000",
  // px: "26px",
  // py: "21px",
};

const tableData = [{}];

const BillwiseModal = ({
  open,
  handleClose,
  billwiseDetails_datas,
  handleChangeDetails,
  get_from_due_sales,
}) => {
  const [t] = useTranslation("common");
  console.log(billwiseDetails_datas,"LLLLLLLLLLLL");
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography
            sx={{ fontSize: "16px", fontWeight: "500", lineHeight: "22px" }}
          >
            Billwise payment against invoice
          </Typography>
          <IconButton
            sx={{
              backgroundColor: "red",
              color: "white",
              fontSize: "10px",
              ":hover": { backgroundColor: "red" },
              p: "2px",
            }}
          >
            <CloseIcon onClick={()=> handleClose(false)} />
          </IconButton>
        </Box>

        <Box sx={{ mb: 2 }}>
          <TableContainer sx={{ maxWidth: "100%" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      fontSize: "11px",
                      lineHeight: "10px",
                      backgroundColor: "white",
                      borderTop: "0.5px solid black",
                      borderBottom: "0.5px solid black",
                    },
                    "& th:first-of-type": { borderLeft: "0.5px solid black" },
                    "& th:last-of-type": { borderRight: "0.5px solid black" },
                  }}
                >
                  <TableCell>Voucher Date </TableCell>
                  <TableCell>Invoice No</TableCell>
                  <TableCell>Voucher Type</TableCell>
                  <TableCell>Grand Total</TableCell>
                  <TableCell>Amount Due</TableCell>
                  <TableCell>Due Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Full Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billwiseDetails_datas.map((i,index) => (
                  <TableRow
                    key={i + 1}
                    sx={{
                      "&:last-child td, &:last-child th": { borderBottom: 0 },
                      "& td": {
                        borderRight: "1.5px solid white",
                        fontSize: "11px",
                        py: "3px",
                        px: "5px",
                      },
                      "& td:last-of-type": { borderRight: 0 },
                    }}
                  >
                    <TableCell  scope="row">
                      {i.VoucherDate}
                    </TableCell>
                    <TableCell>{i.InvoiceNo}</TableCell>
                    <TableCell>{i.VoucherType}</TableCell>
                    <TableCell>{i.InvoiceAmount}</TableCell>
                    <TableCell>
                      {i.show_negative
                        ? Number(-i.AmountDue)
                        : Number(i.AmountDue)}
                    </TableCell>
                    <TableCell>{i.DueDate}</TableCell>
                    <TableCell>
                      <InputBase
                        sx={{ ml: 1, flex: 1, fontSize: "11px", width: "50px" }}
                        placeholder=""
                        inputProps={{ "aria-label": "search google maps" }}
                        value={i.Amount}
                        onChange={(e) => handleChangeDetails(e, index)}
                        name="Amount"
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        inputProps={{ "aria-label": "Checkbox demo" }}
                        onChange={(e) => handleChangeDetails(e, index)}
                        name="full_amt"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Button
          sx={{
            color: "white",
            fontSize: "14px",
            boxShadow: "none",
            textTransform: "none",
            backgroundColor: "green",
            ":hover": { backgroundColor: "green" },
          }}
          onClick={() => get_from_due_sales()}
        >
          {t("Submit")}
        </Button>
      </Box>
    </Modal>
  );
};

export default BillwiseModal;

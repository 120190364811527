export function StockManagementCalc(state, DataList) {
  //Initializing Variables
  let TotalAdjustedQty = 0;
  let TotalExcessAmount = 0;
  let TotalShortageAmount = 0;
  let TotalDamageAmount = 0;
  let TotalUsedAmount = 0;
  let TotalAmount = 0;
  
  let Type = state.Type.name

  //Looping the details and finding values
  for (let index = 0; index < DataList.length; index++) {
    const item = DataList[index]; // Access the item object at each index
    TotalAdjustedQty +=Number(item.AdjustedStock || 0) 


    if (Number(item.Adjustment || 0) > 0) {
      TotalExcessAmount += Number(item.Adjustment || 0) * Number(item.Cost || 0);
    } else {
      TotalShortageAmount +=
        Number(item.Adjustment || 0) * Number(item.Cost || 0);
    }
    TotalDamageAmount += Number(item.Damage || 0) * Number(item.Cost || 0);
    TotalUsedAmount += Number(item.Used || 0) * Number(item.Cost || 0);
  }

  //making +ve 
  TotalShortageAmount = Math.abs(TotalShortageAmount)
  TotalDamageAmount = Math.abs(TotalDamageAmount)
  TotalUsedAmount = Math.abs(TotalUsedAmount)
  
  
  
  if (Type ==='Adjustment' ){
    TotalAmount = (TotalExcessAmount - TotalShortageAmount)
  }
  
  else if(Type === 'Damage'){
    TotalAmount = TotalDamageAmount
  }
  else if (Type === "Used"){
    TotalAmount = TotalUsedAmount
  }




  // Update the state
  state.TotalAdjustedQty = TotalAdjustedQty;
  state.TotalExcessAmount = TotalExcessAmount;
  state.TotalShortageAmount = TotalShortageAmount;
  state.TotalDamageAmount = TotalDamageAmount;
  state.TotalUsedAmount = TotalUsedAmount;
  state.TotalAmount = TotalAmount;
  return state;
}

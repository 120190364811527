import { Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ListIcon from "@mui/icons-material/List";
import DeleteIcon from '@mui/icons-material/Delete';

import { Icons } from "../../Assets/AssetLog";

export const CreateButton = (props) => {
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: "#0A9EF3",
        borderRadius: "6px",
        mx: 1,
        textTransform: "none",
        "&:hover": { backgroundColor: "#6AC9FF" },
        "&:active": { backgroundColor: "#003D60" },
        width: "106px",
        padding: "9px, 32px, 9px, 32px",
      }}
      onClick={props.onClick}
      // component={Link}
      // to={"/bank-reconciliation"}
    >
      {"Create"}
    </Button>
  );
};

export const UpdateButton = (props) => {
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: "#0A9EF3",
        borderRadius: "6px",
        mx: 1,
        textTransform: "none",
        "&:hover": { backgroundColor: "#6AC9FF" },
        "&:active": { backgroundColor: "#003D60" },
        width: "106px",
        padding: "9px, 32px, 9px, 32px",
      }}
      onClick={props.onClick}
      // component={Link}
      // to={"/bank-reconciliation"}
    >
      {"Update"}
    </Button>
  );
};

export const ImportButton = (props) => {
  return (
    <Button
      startIcon={<img src={Icons.ImportIcon} alt="import" />}
      sx={{
        color: "#636363 !important",
        mr: "10px !important",
        textTransform: "none",
        "&:hover": { backgroundColor: "#F2F2F2" },
        "&:active": { backgroundColor: "#0A9EF3",color:"white !important" },
      }}
      id="basic-button"
      // aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      // aria-expanded={open ? 'true' : undefined}
      onClick={props.onClick}
    >
      {props.t("Import")}
    </Button>
  );
};

export const ListButton = (props) => {
  return (
    <Button
      startIcon={<ListIcon color="black" sx={{ color: "black" }} />}
      sx={{
        color: "#636363 !important",
        mr: "10px !important",
        textTransform: "none",
        "&:hover": { backgroundColor: "#F2F2F2" },
        "&:active": { backgroundColor: "#0A9EF3",color:"white !important" },
      }}
      id="basic-button"
      // aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      // aria-expanded={open ? 'true' : undefined}
      onClick={props.onClick}
    >
      {props.t("View List")}
    </Button>
  );
};

export const ClearButton = (props) => {
  return (
    <Button
      // startIcon={<img src={Icons.ClearIcon} alt="import" />}
      sx={{
        color: "#0A9EF3 !important",
        mr: "10px !important",
        textTransform: "none",
        "&:hover": { backgroundColor: "#F2F2F2" },
        "&:active": { backgroundColor: "#0A9EF3",color:"white !important" },
      }}
      id="basic-button"
      // aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      // aria-expanded={open ? 'true' : undefined}
      onClick={props.onClick}
    >
      {props.t("Clear")}
    </Button>
  );
};


export const CloseButton = (props) => {
  return (
    <Button
      startIcon={<img src={Icons.ClearIcon} alt="import" />}
      sx={{
        border:"none",
        color: "#636363 !important",
        mr: "10px !important",
        textTransform: "none",
        "&:hover": { backgroundColor: "#F2F2F2" },
        "&:active": { backgroundColor: "#0A9EF3",color:"white !important" },
      }}
      id="basic-button"
      // aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      // aria-expanded={open ? 'true' : undefined}
      onClick={props.onClick}
    >
      {props.t("Close")}
    </Button>
  );
};

export const DeleteButton = (props) => {
  return (
    <Button
      startIcon={<DeleteIcon color="black" sx={{ 
        color: "black",
    }} />}
      sx={{
        color: "#636363 !important",
        mr: "10px !important",
        textTransform: "none",
        "&:hover": { backgroundColor: "#F2F2F2" },
        "&:active": { backgroundColor: "#0A9EF3",color:"white !important" },
      }}
      id="basic-button"
      // aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      // aria-expanded={open ? 'true' : undefined}
      onClick={props.onClick}
    >
      {props.t("Delete")}
    </Button>
  );
};



export const CreateNewButton = (props) => {
    return (
      <Button
        startIcon={<img src={Icons.AddIcon} alt="import" />}
        sx={{
          color: "#636363 !important",
          mr: "10px !important",
          textTransform: "none",
          "&:hover": { backgroundColor: "#F2F2F2" },
          "&:active": { backgroundColor: "#0A9EF3",color:"white !important" },
        }}
        id="basic-button"
        // aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        // aria-expanded={open ? 'true' : undefined}
        onClick={props.onClick}
      >
        {props.t("Create New")}
      </Button>
    );
  };
  
  
export const SaveButton = (props) => {
    return (
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#0A9EF3",
          borderRadius: "6px",
          mx: 1,
          textTransform: "none",
          "&:hover": { backgroundColor: "#6AC9FF" },
          "&:active": { backgroundColor: "#003D60" },
          width: "106px",
          padding: "9px, 32px, 9px, 32px",
          
        }}
        onClick={props.onClick}
        // component={Link}
        // to={"/bank-reconciliation"}
      >
        {"Save"}
      </Button>
    );
  };
  
  
  
  export const LoadingButton = (props) => {
    return (
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#0A9EF3",
          borderRadius: "6px",
          mx: 1,
          textTransform: "none",
          "&:hover": { backgroundColor: "#6AC9FF" },
          "&:active": { backgroundColor: "#003D60" },
          width: "106px",
          padding: "9px, 32px, 9px, 32px",
          
        }}
        onClick={props.onClick}
        // component={Link}
        // to={"/bank-reconciliation"}
      >
        {"Loading..."}
      </Button>
    );
  };
  

  export const AddToItemsButton = (props) => {
    
    let label = props.label?props.label:"Add To Items"
    return (
      <Button
        variant="contained"
        sx={{
          backgroundColor: "black",
          borderRadius: "6px",
          // mx: 1,
          textTransform: "none",
          "&:hover": { backgroundColor: "#8d8888" },
          "&:active": { backgroundColor: "#8d8888" },
          width: "150px",
          padding: "9px, 32px, 9px, 32px",
          
        }}
        onClick={props.onClick}
        // component={Link}
        // to={"/bank-reconciliation"}
      >
        {label}
      </Button>
    );
  };
  
  
  export const DoneButton = (props) => {
    return (
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#0A9EF3",
          borderRadius: "6px",
          mx: 1,
          textTransform: "none",
          "&:hover": { backgroundColor: "#6AC9FF" },
          "&:active": { backgroundColor: "#003D60" },
          width: "106px",
          padding: "9px, 32px, 9px, 32px",
          
        }}
        onClick={props.onClick}
        // component={Link}
        // to={"/bank-reconciliation"}
      >
        {"Done"}
      </Button>
    );
  };
  
  
  export const CustomButton = (props) => {
    
    const{name,bg1,bg2,bg3,color,width,onClick} = props
    
    return (
      <Button
        variant="contained"
        sx={{
          backgroundColor: bg1?bg1:"#0A9EF3",
          borderRadius: "6px",
          mx: 1,
          textTransform: "none",
          "&:hover": { backgroundColor: bg2?bg2:"#6AC9FF" },
          "&:active": { backgroundColor: bg3?bg3:"#003D60" },
          width: width?width:"106px",
          padding: "9px, 32px, 9px, 32px",
          color:{color}
          
        }}
        onClick={props.onClick}
        // component={Link}
        // to={"/bank-reconciliation"}
      >
        {name}
      </Button>
    );
  };
  
  
import { configureStore } from "@reduxjs/toolkit";
import generalSettingsSclice from "../features/generalSettingsSclice";
import appSettingsSclice from "../features/appSettingSclice";
import SnackBarslice from "../features/SnackBarslice";
import financialYear from "../features/financialYearSlice";
import companyDetails from "../features/companyDetailsSlice";
import userTable from "../features/userTableSlice";
import user from "../features/userSlice";
import versionDetails from "../features/versionSlice";
import language from "../features/languageSlice";
import branchSettings from "../features/branchSettingsSlice";
import userRollSettings from "../features/userRoleSclice";
import rollPermessions from "../features/rollPremessionSclice";
import companyUrl from "../features/companyUrlSettingsSlice";

export const store = configureStore({
    reducer: {
        generalSettingsSclice,
        appSettingsSclice,
        SnackBarslice,
        financialYear,
        companyDetails,
        userTable,
        user,
        versionDetails,
        language,
        branchSettings,
        userRollSettings,
        rollPermessions,
        companyUrl,

    }
})
import { Navigate } from "react-router-dom";
import { activateFunction } from "../../../Function/Editions";
// import { Suspense, lazy } from "react";

// const TrialBalance = lazy(() => import("../../../Pages/Reports/Financial/TrialBalance/TrialBalance"));
// const ProfitAndLossAccount = lazy(() => import("../../../Pages/Reports/Financial/ProfitAndLossAccount/ProfitAndLossAccount"));
// const BalanceSheet = lazy(() => import("../../../Pages/Reports/Financial/BalanceSheet/BalanceSheet"));
// const OutstandingReport = lazy(() => import("../../../Pages/Reports/Financial/OutstandingReport/OutstandingReport"));
// const ExpensesSummary = lazy(() => import("../../../Pages/Reports/Financial/ExpensesSummary/ExpensesSummary"));
import TrialBalance from "../../../Pages/Reports/Financial/TrialBalance/TrialBalance";
import ProfitAndLossAccount from "../../../Pages/Reports/Financial/ProfitAndLossAccount/ProfitAndLossAccount";
import ProfitAndLossAccount1 from "../../../Pages/Reports/Financial/ProfitAndLossAccount/ProfitAndLossAccount_1";
import BalanceSheet from "../../../Pages/Reports/Financial/BalanceSheet/BalanceSheet";
import OutstandingReport from "../../../Pages/Reports/Financial/OutstandingReport/OutstandingReport";
import ExpensesSummary from "../../../Pages/Reports/Financial/ExpensesSummary/ExpensesSummary";

function transformString(str) {
  const words = str.split('-');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  const transformedStr = capitalizedWords.join(' ');
  return transformedStr;
}

// Higher Order Component for protected routes

const ProtectedRoute = ({ children, authCondition, path }) => {
    let isAuthCondition = authCondition;
    // console.log(transformString(path));
    document.title = transformString(path) || "Vikn Books"
    return isAuthCondition ? children : <Navigate to="/reports" />;
};

const route = [
  { path: "trial-balance", element: <TrialBalance />, isAuth: activateFunction("TrialBalance") },
  { path: "profit-and-loss-account", element: <ProfitAndLossAccount/>, isAuth: activateFunction("Profit And Loss Account") },
  { path: "profit-and-loss-account1", element: <ProfitAndLossAccount1/>, isAuth: activateFunction("Profit And Loss Account") },
  { path: "balance-sheet", element: <BalanceSheet />, isAuth: activateFunction("BalanceSheet") },
  { path: "outstanding-report", element: <OutstandingReport />, isAuth: activateFunction("OutstandingReport") },
  { path: "expense-summary", element: <ExpensesSummary />, isAuth: activateFunction("ExpenseSummary") },
];

const financeRouter = route.map((route) => ({
  ...route,
  element:
  //  (
    // <Suspense fallback={<div>Loading...</div>}>
  <ProtectedRoute path={route.path} authCondition={route.isAuth}>{route.element}</ProtectedRoute>,
  // </Suspense>
      // )
}));


export default financeRouter;
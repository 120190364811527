import React, { useEffect, useState } from "react";
import { Box, Divider, List, ListItem, Paper, Typography, styled } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
  VBTableRowNull,
} from "../../../../Components/ReportTable/ReportTable";
import FilterLable from "../../../../Components/Utils/FilterLable";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import PrintBtn from "../../../../Components/Utils/PrintBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import moment from "moment";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import {
  CallEmployees,
  CallUsers,
} from "../../../../Api/Reports/CommonReportApi";
import { callSalesOrderReport } from "../../../../Api/Reports/SalesApi";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import SearchReport from "../../../../Components/Utils/SearchReport";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { VIKNBOOKS_FRONT_URL, } from "../../../../generalVeriable";
import { formatNumber } from "../../../../generalFunction";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import { date_range } from "../../../../Function/comonFunction";
import { UserRolePermission } from "../../../../Function/Editions";

const SalesOrderReport = () => {
  let Export_view = UserRolePermission("Sales Order Report", "print_permission");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [isLoadingSalesman, setIsLoadingSalesman] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [page_no, setPage_no] = useState(1);
  const [t, i18n] = useTranslation("common");
  const [filterData, setFilterData] = useState({
    usersList: [],
    salesManList: [],
  });
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,EnableSalesManInSales} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const Is_SalesMan = EnableSalesManInSales
  const [openFilter, setOpenFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment(),
    },
    salesmanList: [],
    salesmanObj: { FirstName: "" },
    reportType: {
      invoice: { isActive: true, name: "Invoice" },
      cancelled: { isActive: false, name: "Cancelled" },
      pending: { isActive: false, name: "Pending" },
    },
    user: null,
    salesMan: null,
  });

  const [filterValues, setFilterValues] = useState([]);

  //Handle functions

  const closeFilter = () => setOpenFilter(false);

  const handleDate = function (e, type) {
    if (type === "from") {
    } else if (type === "to") {
      setFilterOptions({
        ...filterOptions,
        date: {
          ...filterOptions.date,
          ToDate: "",
        },
      });
    }
  };

  const handleDefaultFilter = async () => {
    setFilterOptions({
      ...filterOptions,
      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Today" },
        FromDate: moment(),
        ToDate: moment(),
      },
      reportType: {
        invoice: { isActive: true, name: "Invoice" },
        cancelled: { isActive: false, name: "Cancelled" },
        pending: { isActive: false, name: "Pending" },
      },
      user: null,
      salesMan: null,
      salesmanObj: { FirstName: "" },

    })
    setFilterValues([
      {
        name :"Report Type",
        value :"Invoice",
        isActive : filterOptions.reportType.invoice.isActive,
      },
    ])
  }

  const handleReportType = function (key) {
    switch (key) {
      case "Invoice":
        setFilterOptions({
          ...filterOptions,
          reportType: {
            ...filterOptions.reportType,
            invoice: {
              ...filterOptions.reportType.invoice,
              isActive: !filterOptions.reportType.invoice.isActive,
            },
          },
        });
        !filterOptions.reportType.invoice.isActive &&
          setFilterValues([
            ...filterValues,
            {
              name: "Report Type",
              value: "Invoice",
              isActive: !filterOptions.reportType.invoice.isActive,
            },
          ]);
        filterOptions.reportType.invoice.isActive &&
          setFilterValues(
            filterValues.filter((item, i) => item.value !== "Invoice")
          );

        break;
      case "Cancelled":
        setFilterOptions({
          ...filterOptions,
          reportType: {
            ...filterOptions.reportType,
            cancelled: {
              ...filterOptions.reportType.cancelled,
              isActive: !filterOptions.reportType.cancelled.isActive,
            },
          },
        });
        !filterOptions.reportType.cancelled.isActive &&
          setFilterValues([
            ...filterValues,
            {
              name: "Report Type",
              value: "Cancelled",
              isActive: !filterOptions.reportType.cancelled.isActive,
            },
          ]);
        filterOptions.reportType.cancelled.isActive &&
          setFilterValues(
            filterValues.filter((item, i) => item.value !== "Cancelled")
          );
        break;
      case "Pending":
        setFilterOptions({
          ...filterOptions,
          reportType: {
            ...filterOptions.reportType,
            pending: {
              ...filterOptions.reportType.pending,
              isActive: !filterOptions.reportType.pending.isActive,
            },
          },
        });
        !filterOptions.reportType.pending.isActive &&
          setFilterValues([
            ...filterValues,
            {
              name: "Report Type",
              value: "Pending",
              isActive: !filterOptions.reportType.invoice.isActive,
            },
          ]);
        filterOptions.reportType.pending.isActive &&
          setFilterValues(
            filterValues.filter((item, i) => item.value !== "Pending")
          );
        break;
      default:
        break;
    }
  };

  const deleteFilter = function (value, index) {
    console.log(value);
    if (value.name === "Report Type") {
      let newValue = filterValues.filter((item, i) => i !== index);
      setFilterValues(newValue);
      handleReportType(value.value);
    } else if (value.name === "Sales Man") {
      handleAutoComplete(null, null, "Sales Man");
    } else if (value.name === "User") {
      handleAutoComplete(null, null, "User");
    } else if (value.name === "Date") {
      let newValue = filterValues.filter((item, i) => i !== index);
      setFilterValues(newValue);
      setFilterOptions({
        ...filterOptions,
        date: {
          ...filterOptions.date,
          ToDate: moment(),
          FromDate: moment(),
        },
      });
    }
    // console.log(filterValues);
  };

  const handleAutoComplete = function (e, value, name) {
    // console.log(e, value);
    if (name === "Sales Man") {
      setFilterOptions({
        ...filterOptions,
        salesMan: value,
        salesmanObj:value,
      });
      if (filterOptions.salesMan) {
        // console.log(value.customer_name, filterValues);
        value &&
          setFilterValues(
            filterValues.map((item, i) =>
              item.name === "Sales Man"
                ? {
                    name: "Sales Man",
                    value: value.FirstName,
                  }
                : item
            )
          );
      } else {
        value &&
          setFilterValues([
            ...filterValues,
            {
              name: "Sales Man",
              value: value.FirstName,
              isActive: !filterOptions.reportType.invoice.isActive,
            },
          ]);
      }

      !value &&
        setFilterValues(
          filterValues.filter((item, i) => item.name !== "Sales Man")
        );
    }else if (name === "date") {
      console.log(e, value);
      if (value.name === "Custom") {
        setFilterOptions({
          ...filterOptions,
          date: {
            ...filterOptions.date,
            isActive: true,
            value: value,
          },
        });
      } else {
        let date = date_range(value.name);
        setFilterOptions({
          ...filterOptions,
          date: {
            ...filterOptions.date,
            isActive: false,
            value: value,
            ToDate: date.todate,
            FromDate: date.fromdate,
          },
        });
      }
    }else {
      setFilterOptions({
        ...filterOptions,
        user: value,
      });
      if (filterOptions.user) {
        // console.log(value.customer_name, filterValues);
        value &&
          setFilterValues(
            filterValues.map((item, i) =>
              item.name === "User"
                ? {
                    name: "User",
                    value: value.customer_name,
                    isActive: !filterOptions.reportType.invoice.isActive,
                  }
                : item
            )
          );
      } else {
        value &&
          setFilterValues([
            ...filterValues,
            {
              name: "User",
              value: value.customer_name,
              isActive: !filterOptions.reportType.invoice.isActive,
            },
          ]);
      }

      !value &&
        setFilterValues(filterValues.filter((item, i) => item.name !== "User"));
    }
  };
  let reportTyoe = [];
  if (filterOptions.reportType.invoice.isActive) {
    reportTyoe.push("I");
  }
  if (filterOptions.reportType.cancelled.isActive) {
    reportTyoe.push("C");
  }
  if (filterOptions.reportType.pending.isActive) {
    reportTyoe.push("N");
  }
  // API Fetch data
  const fetchReport = async function () {
    setLoading(true);
    const data = await callSalesOrderReport({
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
      ReportTypes: reportTyoe,
      UserID: filterOptions.user?.UserID || 0,
      EmployeeID: filterOptions.salesMan?.EmployeeID || 0,
    },dispatch);
    console.log(data);
    if (data?.StatusCode === 6000) {
      setReportData(data.data);
    } else if (data?.StatusCode === 6001) {
      setReportData();
      dispatch(
        openSnackbar({
          open : true,
          message : data?.message,
          severity : 'warning',
        })
      )
    }
    setLoading(false)
  };

  // search Employee
  const handleSearchEmployee = async (e, value, name) => {
    if (value) {
      setIsLoadingSalesman(true);
      let salesmanList = await CallEmployees({
        CompanyID : CompanyID,
        BranchID : BranchID,
        PriceRounding : Number(PriceDecimalPoint),
        CreatedUserID: user_id,
        items_per_page: 10,
        page_no: 1,
        search: value,
      });

      let salesman_data = [];
      if (salesmanList.data) {
        salesman_data = salesmanList.data;
      }
      setFilterOptions({ ...filterOptions, salesmanList: salesman_data });
      setIsLoadingSalesman(false);
    } else {
      setFilterOptions({ ...filterOptions, salesmanObj: { FirstName: "" } });
    }
  };

  const filterApis = async function () {
    const data = await CallUsers({
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
    });
    const employList = await CallEmployees({
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      CreatedUserID: user_id,
    });
    setFilterOptions({
      ...filterOptions,
      salesmanList : employList.data
    });
    setFilterData({ usersList: data.data, salesManList: employList.data });
  };

  const getDataForPage = () => {
    const startIndex = (page_no -1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData?.slice(0,reportData?.length -1)
    return alldata?.slice(startIndex, endIndex) || [];
  }

  useEffect(() => {
    // fetchExpensesReport()
    filterApis();
    setFilterValues([
      ...filterValues,
      {
        name :"Report Type",
        value :"Invoice",
        isActive : filterOptions.reportType.invoice.isActive,
      },
    ])
  }, []);
  const handleTableRowClick = (id, VoucherType, VoucherNo) => {
    let destinationURL;
    let URL;
  
    URL = `${VIKNBOOKS_FRONT_URL}dashboard/sales-order-preview`;
    destinationURL = `${URL}?&id=${id}`;
    window.open(destinationURL, '_blank');
  };
  console.log(filterOptions);

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t('Sales Order Report')}
            </Typography>
            {/* <SearchReport onClick={() => fetchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className="right">
            {Export_view ? 
            <ReportDownload
              download_type={"PDF"}
              invoice_type={"sales_order_report"}
              FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
              ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
              filterValue={reportTyoe}
              UserID={filterOptions.user?.UserID || 0}
              EmployeeID={filterOptions.salesMan?.EmployeeID || 0}
            />
            : null}
            {/* <PrintBtn /> */}
            <Pagenation
              totalItem={reportData?.length}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // height: "54px",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        > 
        <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center'}}>
          {t("Applied Filters: ")}
        </div>
            <FilterLable
              name= "Date"
              value= {filterOptions.date.FromDate.format('DD/MM/YYYY') + ' - ' + filterOptions.date.ToDate.format('DD/MM/YYYY')}
            />
          {filterValues.map((vlaue, i) => (
            <FilterLable
              name={vlaue.name}
              value={vlaue.value}
              handleDelete={() => deleteFilter(vlaue, i)}
            />
          ))}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t('Serial No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Voucher No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Voucher Date')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Ledger Name')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Net Amount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Total Tax')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Grand Total')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Notes')}</VBTableCellHeader>
          </VBTableHeader>
          {Loading ? 
          <VBSkeletonLoader /> :
          <TableBody>
            {getDataForPage().map((data, i) => (
              <TableRow
              onClick={() =>
                handleTableRowClick(data.id, data.VoucherType, data.VoucherNo)
              }
              >
                <VBTableCellBody sx={{cursor:"pointer"}}>{(page_no - 1)* appSetting.itemPerPage + i + 1}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.VoucherNo}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.Date}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.LedgerName}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.NetTotal)}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.TotalTax)}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.GrandTotal)}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{data.Notes}</VBTableCellBody>
              </TableRow>
            ))}
          {/* <VBTableRowNull length = {reportData?.length}/> */}
          <TableRow></TableRow>
          </TableBody>
          }
          <VBTableFooter >
            <TableRow>
              <VBTableCellFooter >{t('Total')}</VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.NetTotal ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.TotalTax ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.GrandTotal ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}></VBTableCellFooter>
            </TableRow>
          </VBTableFooter>
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        setPage_no = {setPage_no}
        onFilter={() => fetchReport()}
        handleDefaultFilter={()=>handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem></ListItem>

            <ListItem>
              <VBDatePiker
                handleAutoComplete={handleAutoComplete}
                dateValue={filterOptions.date.value}
                isDateRange={true}
                fromDate={filterOptions.date.FromDate}
                toDate={filterOptions.date.ToDate}
                fromOnChange={(value) => {
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      FromDate: value,
                      value:{ name: "Custom" },
                    },
                  });
                  // setFilterValues((prevFilterValues) => {
                  //   const dateValue = {
                  //     name: "Date",
                  //     value:
                  //     value.format("DD/MM/YYYY") +
                  //       " - " +
                  //       filterOptions.date.ToDate.format("DD/MM/YYYY"),
                  //   };

                  //   const index = prevFilterValues.findIndex(
                  //     (filter) => filter.name === "Date"
                  //   );

                  //   if (index !== -1) {
                  //     // If a filter with name "Date" exists, update it
                  //     return prevFilterValues.map((filter, i) =>
                  //       i === index ? dateValue : filter
                  //     );
                  //   } else {
                  //     // If no filter with name "Date" exists, add it
                  //     return [...prevFilterValues, dateValue];
                  //   }
                  // });
                }}
                toOnChange={(value) => {
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      ToDate: value,
                      value:{ name: "Custom" },
                    },
                  });
                  // setFilterValues((prevFilterValues) => {
                  //   const dateValue = {
                  //     name: "Date",
                  //     value:
                  //       filterOptions.date.FromDate.format("DD/MM/YYYY") +
                  //       " - " +
                  //       value.format("DD/MM/YYYY"),
                  //   };

                  //   const index = prevFilterValues.findIndex(
                  //     (filter) => filter.name === "Date"
                  //   );

                  //   if (index !== -1) {
                  //     // If a filter with name "Date" exists, update it
                  //     return prevFilterValues.map((filter, i) =>
                  //       i === index ? dateValue : filter
                  //     );
                  //   } else {
                  //     // If no filter with name "Date" exists, add it
                  //     return [...prevFilterValues, dateValue];
                  //   }
                  // });
                }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknCheckboxGroup
                radios={[
                  {
                    label: t("Invoiced"),
                    checked: filterOptions.reportType.invoice.isActive,
                    onChange: () => handleReportType("Invoice"),
                  },
                  {
                    label: t("Cancelled"),
                    checked: filterOptions.reportType.cancelled.isActive,
                    onChange: () => handleReportType("Cancelled"),
                  },
                  {
                    label: t("Pending"),
                    checked: filterOptions.reportType.pending.isActive,
                    onChange: () => handleReportType("Pending"),
                  },
                ]}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknAutoComplete
                value={filterOptions.user}
                placeholder={t("User")}
                options={filterData.usersList}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) =>
                  handleAutoComplete(e, newValue, "User")
                }
              />
            </ListItem>
            <Divider />
            {Is_SalesMan ?
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                placeholder={t("SalesMan")}
                filterName={t("SalesMan")}
                loading={isLoadingSalesman}
                options={
                  filterOptions.salesmanList ? filterOptions.salesmanList : []
                }
                getOptionLabel={(option) => option.FirstName || ""}
                name="salesmanObj"
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    handleSearchEmployee(event, value);
                  }
                  if (reason === "clear") {
                    handleSearchEmployee();
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "Sales Man", -1)}
                value={filterOptions.salesmanObj}
              />


              {/* <ViknAutoComplete
                value={filterOptions.salesMan}
                placeholder={t("Sales Man")}
                options={filterData.salesManList}
                getOptionLabel={(option) => option.FirstName}
                onChange={(e, newValue) =>
                  handleAutoComplete(e, newValue, "Sales Man")
                }
              /> */}
            </ListItem>
            :null}
            <Divider />
          </List>
        }
      />
    </>
  );
};

export default SalesOrderReport;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap:"22px",
  },
}));

import { Navigate } from "react-router-dom";
// import SalesInvoice from "../../Pages/Inventory/SalesInvoice/SalesInvoice"
import { store } from "../../app/store";
import { Outlet } from "react-router-dom";
import PurchaseInvoice from "../../Pages/PurchaseInvoice/PurchaseInvoice";
import CreateParchaseReturn from "../../Pages/ParchaseReturn/CreateParchaseReturn";
import ListParchaseReturn from "../../Pages/ParchaseReturn/ListParchaseReturn";



const ProtectedRoute = ({ children, authCondition }) => {
    let isAuthCondition = authCondition;
    return children
    // return isAuthCondition ? children : <Navigate to="/dashboard" />;
  };


const route = [
    {path:"create-purchase",element:<PurchaseInvoice/>,isAuth:true,},
    {path:"create-purchase-return",element:<CreateParchaseReturn/>,isAuth:true,},
    {path:"list-purchase-return",element:<ListParchaseReturn/>,isAuth:true,}



]


const Routes  = route.map((route)=>({
    ...route,
    element: <ProtectedRoute authCondition={route.isAuth}>{route.element}</ProtectedRoute>,
}))

const purchaseRoutes = {
    path:"/",
    element: <Outlet />,
  children: [
    ...Routes
  ],
}


export default purchaseRoutes
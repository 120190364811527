import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    itemPerPage: 15,
    itemSkipPage: 10,
    themeDark: true,
    isPriceRounding: true,
    lang: "ar",
    currencyFormat: "IN",
    language: ""
}

const appSetting = createSlice({
    name: "appSettings",
    initialState: initialState,
    reducers: {
      appSettingState: (state, action) => {
        Object.keys(action.payload).forEach((key) => {
          state[key] = action.payload[key];
        });
      },
    },
})

export const { appSettingState } = appSetting.actions;
export default appSetting.reducer;
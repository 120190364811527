import React from 'react'
import { VBSkeletonLoader, VBTableCellBody, VBTableCellHeader, VBTableContainer, VBTableHeader } from '../../../../../Components/ReportTable/ReportTable'
import { TableBody, TableRow } from '@mui/material'
import { formatNumber } from '../../../../../generalFunction'
import { useTranslation } from 'react-i18next'

export const VerticalTable = ({reportData1, reportData2, isLoading}) => {
  const [t] = useTranslation("common");
  return (
    <VBTableContainer>
    <VBTableHeader>
      <VBTableCellHeader sx={{ width: "50%", borderRight: "1px solid #e6e6e6"}} >{t("Accounts")} </VBTableCellHeader>
      <VBTableCellHeader sx={{ width: "50%", textAlign: "right"}} >{t("Amount")} </VBTableCellHeader>
    </VBTableHeader>
    {isLoading ? <VBSkeletonLoader /> : 
    <TableBody>
      <TableRow>
      <VBTableCellBody sx={{fontWeight: "600", borderRight: "1px solid #e6e6e6"}} colspan="2">{t("ASSETS")}</VBTableCellBody>
      </TableRow>
      {reportData1.map((e, i) => ( 
        <TableRow>
          {/* <VBTableCellBody sx={{ fontWeight: "light"}}> Group Name</VBTableCellBody> */}
        {/* <VBTableCellBody sx={{ color: e.liabilities.group_name === "Total" ? 'red': "auto", fontWeight: e.liabilities.group_name === "Total"? "600" : "400" }} > {e.liabilities.group_name}</VBTableCellBody>
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6", textAlign: "right" }}> {formatNumber(e.liabilities.balance)}</VBTableCellBody> */}
        <VBTableCellBody sx={{ color: e.assets.group_name === "Total" ? 'red': "auto", fontWeight: e.assets.group_name === "Total"? "600" : "400", borderRight: "1px solid #e6e6e6" }}> {e.assets.group_name}</VBTableCellBody>
        <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(e.assets.balance)}</VBTableCellBody>
        </TableRow>
      ))}

      <TableRow>
        <VBTableCellBody sx={{fontWeight: "600", borderRight: "1px solid #e6e6e6"}} colspan="2">{t("LIABILITIES & EQUITIES")}</VBTableCellBody>
      </TableRow>
      {reportData2?.map((e, i) => ( 
        <TableRow>
        <VBTableCellBody sx={{ color: e?.liabilities?.group_name === "Total" ? 'red': "auto", fontWeight:(e?.liabilities?.group_name === "Total" && e?.liabilities?.group_name === "Net Profit") ? "600" : "400" , borderRight: "1px solid #e6e6e6" }} > {e?.liabilities?.group_name}</VBTableCellBody>
        <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6", textAlign: "right" }}> {formatNumber(e?.liabilities?.balance)}</VBTableCellBody>
        {/* <VBTableCellBody sx={{ color: e.assets.group_name === "Total" ? 'red': "auto", fontWeight: e.assets.group_name === "Total"? "600" : "400", borderRight: "1px solid #e6e6e6" }}> {e.assets.group_name}</VBTableCellBody>
        <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(e.assets.balance)}</VBTableCellBody> */}
        </TableRow>
      ))}

      <TableRow></TableRow>
    </TableBody>}
  </VBTableContainer>
  )
}

import CreateCustomer from "../../Pages/Contacts/CreateCustomer/CreateCustomer";
import CreateSupplier from "../../Pages/Contacts/CreateSupplier/CreateSupplier";
import CreateEmployee from "../../Pages/Contacts/CreateEmployee/CreateEmployee";
import ListEmployee from "../../Pages/Contacts/CreateEmployee/ListEmployee";
import ListCustomer from "../../Pages/Contacts/ListCustomer/ListCustomer";
import ListSupplier from "../../Pages/Contacts/ListSupplier/ListSupplier";

const CustomerRoute = [
  {
    path: "create-customer",
    element: <CreateCustomer />,
    isAuth: true,
  },
  {
    path: "create-supplier",
    element: <CreateSupplier />,
    isAuth: true,
  },
  {
    path: "create-employee",
    element: <CreateEmployee />,
    isAuth: true,
  },
  {
    path: "list-employee",
    element: <ListEmployee />,
    isAuth: true,
  },
  {
    path: "edit-employee",
    element: <CreateEmployee />,
    isAuth: true,
  },
  {
    path: "customer-list",
    element: <ListCustomer />,
    isAuth: true,
  },
  {
    path: "supplier-list",
    element: <ListSupplier />,
    isAuth: true,
  },
];

export default CustomerRoute;

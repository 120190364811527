import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { callUserRoll } from "../Api/generalSettings/generalSettings";

const initialState = {
  userPermission: {
    "Debit Note": {
      id: "",
      parent: "",
      name: "Debit Note",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Settings: {
      id: "",
      parent: "",
      name: "Settings",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Route: {
      id: "",
      parent: "",
      name: "Route",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Supplier Vs Product Report": {
      id: "",
      parent: "",
      name: "Supplier Vs Product Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Credit Note": {
      id: "",
      parent: "",
      name: "Credit Note",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Debtor Ageing Report": {
      id: "",
      parent: "",
      name: "Debtor Ageing Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Loyalty Customer": {
      id: "",
      parent: "",
      name: "Loyalty Customer",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Payment Report": {
      id: "",
      parent: "",
      name: "Payment Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Sales Report": {
      id: "",
      parent: "",
      name: "Sales Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Expense Report": {
      id: "",
      parent: "",
      name: "Expense Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Purchase Estimate": {
      id: "",
      parent: "",
      name: "Purchase Estimate",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "GSTR1 Report": {
      id: "",
      parent: "",
      name: "GSTR1 Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Bill Wise Report": {
      id: "",
      parent: "",
      name: "Bill Wise Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Batch Report": {
      id: "",
      parent: "",
      name: "Batch Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Purchase Order Report": {
      id: "",
      parent: "",
      name: "Purchase Order Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Ledger Report": {
      id: "",
      parent: "",
      name: "Ledger Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Opening Stock Report": {
      id: "",
      parent: "",
      name: "Opening Stock Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Purchase Invoice": {
      id: "",
      parent: "",
      name: "Purchase Invoice",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Purchase Return Register Report": {
      id: "",
      parent: "",
      name: "Purchase Return Register Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Container4: {
      id: "",
      parent: "",
      name: "Container4",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Stock Transfer": {
      id: "",
      parent: "",
      name: "Stock Transfer",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Purchase Order": {
      id: "",
      parent: "",
      name: "Purchase Order",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Expense Summary": {
      id: "",
      parent: "",
      name: "Expense Summary",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Journal: {
      id: "",
      parent: "",
      name: "Journal",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Sales VAT Report": {
      id: "",
      parent: "",
      name: "Sales VAT Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "VAT Report": {
      id: "",
      parent: "",
      name: "VAT Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Daily Report": {
      id: "",
      parent: "",
      name: "Daily Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Transaction Types": {
      id: "",
      parent: "",
      name: "Transaction Types",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Sales Order Report": {
      id: "",
      parent: "",
      name: "Sales Order Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Purchase VAT Report": {
      id: "",
      parent: "",
      name: "Purchase VAT Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Sales Register Report": {
      id: "",
      parent: "",
      name: "Sales Register Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "BT Request": {
      id: "",
      parent: "",
      name: "BT Request",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Product Category": {
      id: "",
      parent: "",
      name: "Product Category",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Banks: {
      id: "",
      parent: "",
      name: "Banks",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Receipt Report": {
      id: "",
      parent: "",
      name: "Receipt Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Product Serialisation Report": {
      id: "",
      parent: "",
      name: "Product Serialisation Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Purchase Integrated Report": {
      id: "",
      parent: "",
      name: "Purchase Integrated Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Units: {
      id: "",
      parent: "",
      name: "Units",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Tax Summary": {
      id: "",
      parent: "",
      name: "Tax Summary",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Price Category": {
      id: "",
      parent: "",
      name: "Price Category",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Bank Statement": {
      id: "",
      parent: "",
      name: "Bank Statement",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Opening Stock": {
      id: "",
      parent: "",
      name: "Opening Stock",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Sales GST Report": {
      id: "",
      parent: "",
      name: "Sales GST Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    TransactionNos: {
      id: "",
      parent: "",
      name: "TransactionNos",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "VB Assist Report": {
      id: "",
      parent: "",
      name: "VB Assist Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Container6: {
      id: "",
      parent: "",
      name: "Container6",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    POSSettings: {
      id: "",
      parent: "",
      name: "POSSettings",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Cash Book": {
      id: "",
      parent: "",
      name: "Cash Book",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Branch Transfer Report": {
      id: "",
      parent: "",
      name: "Branch Transfer Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Sales Integrated Report": {
      id: "",
      parent: "",
      name: "Sales Integrated Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Product: {
      id: "",
      parent: "",
      name: "Product",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Purchase Register Report": {
      id: "",
      parent: "",
      name: "Purchase Register Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Sales Summary Report": {
      id: "",
      parent: "",
      name: "Sales Summary Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Outstanding Report": {
      id: "",
      parent: "",
      name: "Outstanding Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Balance Sheet": {
      id: "",
      parent: "",
      name: "Balance Sheet",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Work Order": {
      id: "",
      parent: "",
      name: "Work Order",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Bank Book": {
      id: "",
      parent: "",
      name: "Bank Book",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Sales BT": {
      id: "",
      parent: "",
      name: "Sales BT",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Brands: {
      id: "",
      parent: "",
      name: "Brands",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Sales Return": {
      id: "",
      parent: "",
      name: "Sales Return",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Container1: {
      id: "",
      parent: "",
      name: "Container1",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Purchase GST Report": {
      id: "",
      parent: "",
      name: "Purchase GST Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Container3: {
      id: "",
      parent: "",
      name: "Container3",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Loyalty Program": {
      id: "",
      parent: "",
      name: "Loyalty Program",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    UserRoles: {
      id: "",
      parent: "",
      name: "UserRoles",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Receipt Voucher": {
      id: "",
      parent: "",
      name: "Receipt Voucher",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Day Book": {
      id: "",
      parent: "",
      name: "Day Book",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Stock Management": {
      id: "",
      parent: "",
      name: "Stock Management",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Employee: {
      id: "",
      parent: "",
      name: "Employee",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "VAT Return": {
      id: "",
      parent: "",
      name: "VAT Return",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Users: {
      id: "",
      parent: "",
      name: "Users",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Quick Report": {
      id: "",
      parent: "",
      name: "Quick Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Average Cost": {
      id: "",
      parent: "",
      name: "Average Cost",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Stock Value Report": {
      id: "",
      parent: "",
      name: "Stock Value Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Warehouse: {
      id: "",
      parent: "",
      name: "Warehouse",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Bill Wise Profit Report": {
      id: "",
      parent: "",
      name: "Bill Wise Profit Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    FaeraSettings: {
      id: "",
      parent: "",
      name: "FaeraSettings",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Tax Category": {
      id: "",
      parent: "",
      name: "Tax Category",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Profit And Loss Account": {
      id: "",
      parent: "",
      name: "Profit And Loss Account",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Excess Stock Report": {
      id: "",
      parent: "",
      name: "Excess Stock Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Product Summary Report": {
      id: "",
      parent: "",
      name: "Product Summary Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Container5: {
      id: "",
      parent: "",
      name: "Container5",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Faera Report": {
      id: "",
      parent: "",
      name: "Faera Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Branches: {
      id: "",
      parent: "",
      name: "Branches",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Expense: {
      id: "",
      parent: "",
      name: "Expense",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Product Report": {
      id: "",
      parent: "",
      name: "Product Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Shortage Stock Report": {
      id: "",
      parent: "",
      name: "Shortage Stock Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Product Analysis Report": {
      id: "",
      parent: "",
      name: "Product Analysis Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Trial Balance": {
      id: "",
      parent: "",
      name: "Trial Balance",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Customer: {
      id: "",
      parent: "",
      name: "Customer",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Stock Transfer Register": {
      id: "",
      parent: "",
      name: "Stock Transfer Register",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Supplier: {
      id: "",
      parent: "",
      name: "Supplier",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Manufacturing: {
      id: "",
      parent: "",
      name: "Manufacturing",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Bank Reconciliation": {
      id: "",
      parent: "",
      name: "Bank Reconciliation",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Account Group": {
      id: "",
      parent: "",
      name: "Account Group",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    Container2: {
      id: "",
      parent: "",
      name: "Container2",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Sales Order": {
      id: "",
      parent: "",
      name: "Sales Order",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Batch Wise Report": {
      id: "",
      parent: "",
      name: "Batch Wise Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Stock Report": {
      id: "",
      parent: "",
      name: "Stock Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Sales Estimate": {
      id: "",
      parent: "",
      name: "Sales Estimate",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Purchase Report": {
      id: "",
      parent: "",
      name: "Purchase Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Cost With Expense": {
      id: "",
      parent: "",
      name: "Cost With Expense",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Purchase BT": {
      id: "",
      parent: "",
      name: "Purchase BT",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Sales Return Register Report": {
      id: "",
      parent: "",
      name: "Sales Return Register Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Purchase Return": {
      id: "",
      parent: "",
      name: "Purchase Return",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Stock Ledger Report": {
      id: "",
      parent: "",
      name: "Stock Ledger Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Creditor Ageing Report": {
      id: "",
      parent: "",
      name: "Creditor Ageing Report",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Product Groups": {
      id: "",
      parent: "",
      name: "Product Groups",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Sales Invoice": {
      id: "",
      parent: "",
      name: "Sales Invoice",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Payment Voucher": {
      id: "",
      parent: "",
      name: "Payment Voucher",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Account Ledger": {
      id: "",
      parent: "",
      name: "Account Ledger",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
    "Stock Order": {
      id: "",
      parent: "",
      name: "Stock Order",
      view_permission: false,
      save_permission: false,
      edit_permission: false,
      delete_permission: false,
      print_permission: false,
      block_date: false,
      UserType: "",
      CompanyID: "",
      BranchID: 1,
      UpdatedDate: "",
      UpdatedUserID: 0,
      PreDate: 0,
      PostDate: 0,
      unit_price: false,
      discount: false,
      discount_limit: "",
    },
  },
  acts: {
    isLoading: false,
    isFailedApi: false,
  },
};

export const callUserRollTunk = createAsyncThunk(
  "fetchuserRollSettings",
  async () => {
    const res = await callUserRoll();
    return res.data;
  }
);

const rollPermessionSlice = createSlice({
  name: "rollPermessions",
  initialState,
  reducers: {
    rollPremessionSuccess: (state, action) => {
      // console.log(action.payload);
      // action.payload.map((i, index) => {
      //   state[index] = { ...i };
      // });

      localStorage.setItem("userRoleSettings", JSON.stringify(action.payload));
    },
    rollPremessionLogout: (state, action) => {
      state = initialState;
      localStorage.removeItem("userRoleSettings");
    },
  },

  extraReducers: (builder) => {
    builder.addCase(callUserRollTunk.pending, (state, action) => {
      // console.log(action, "Pending Satate");
      state.acts.isLoading = true;
    });
    builder.addCase(callUserRollTunk.fulfilled, (state, action) => {
      let obj = action.payload.reduce((acc, cur) => {
        // cur.id = "";
        // cur.parent = "";
        // // cur.name = "Debit Note"
        // cur.view_permission = false;
        // cur.save_permission = false;
        // cur.edit_permission = false;
        // cur.delete_permission = false;
        // cur.print_permission = false;
        // cur.block_date = false;
        // cur.UserType = "";
        // cur.CompanyID = "";
        // cur.BranchID = 1;
        // cur.UpdatedDate = "";
        // cur.UpdatedUserID = 0;
        // cur.PreDate = 0;
        // cur.PostDate = 0;
        // cur.unit_price = false;
        // cur.discount = false;
        // cur.discount_limit = "";

        acc[cur.name] = cur;
        return acc;
      }, {});

      state.userPermission = obj
      state.acts.isLoading = false;
      
      localStorage.setItem("userRoleSettings", JSON.stringify(action.payload));
    });
    builder.addCase(callUserRollTunk.rejected, (state, action) => {
      console.log(action, "Reject Satate");
      // state = get_user_role_data
      state.acts.isFailedApi = true;
    });
  },
});

export const { rollPremessionSuccess, rollPremessionLogout } =
  rollPermessionSlice.actions;
export default rollPermessionSlice.reducer;

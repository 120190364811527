import React from 'react'
import { VBTableCellBody, VBTableCellFooter, VBTableCellHeader, VBTableContainer, VBTableFooter, VBTableHeader } from '../../../../../Components/ReportTable/ReportTable'
import { TableBody, TableRow } from '@mui/material'
import { formatNumber } from '../../../../../generalFunction'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const LedgerwiseTable = ({data, total, periods, page}) => {
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [t, i18n] = useTranslation("common");
  return (
    <VBTableContainer>
    <VBTableHeader>
      <VBTableCellHeader>{t("SI NO")}</VBTableCellHeader>
      <VBTableCellHeader>{t("Supplier Name")} </VBTableCellHeader>
      <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Current")} </VBTableCellHeader>
      <VBTableCellHeader sx={{ textAlign: "right"}}>{periods.first} - {periods.second} {t("days")} </VBTableCellHeader>
      <VBTableCellHeader sx={{ textAlign: "right"}}>{Number(periods.second) + 1} - {periods.third} {t("days")} </VBTableCellHeader>
      <VBTableCellHeader sx={{ textAlign: "right"}}>{Number(periods.third) + 1} - {periods.fourth} {t("days")} </VBTableCellHeader>
      <VBTableCellHeader sx={{ textAlign: "right"}}>{Number(periods.fourth) + 1} - {periods.fifth} {t("days")} </VBTableCellHeader>
      <VBTableCellHeader sx={{ textAlign: "right"}}>{Number(periods.fifth) + 1} - {periods.sixth} {t("days")} </VBTableCellHeader>
      <VBTableCellHeader sx={{ textAlign: "right"}}>{periods.sixth + " > days	"}</VBTableCellHeader>
      <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Total")} </VBTableCellHeader>
    </VBTableHeader>
    <TableBody>
      {data.map((e, i) => (
        <TableRow>
          <VBTableCellBody> {(page - 1) * appSetting.itemPerPage + i + 1} </VBTableCellBody>
          <VBTableCellBody> {e.DisplayName} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}> {formatNumber(e.Current)} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}> {formatNumber(e.First_DueAmout)} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}> {formatNumber(e.Second_DueAmout)} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}> {formatNumber(e.Third_DueAmout)} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}> {formatNumber(e.Fourth_DueAmout)} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}> {formatNumber(e.Fifth_DueAmout)} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}> {formatNumber(e.Sixth_DueAmout)} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}> {formatNumber(e.Total)} </VBTableCellBody>
        </TableRow>
      ))}
      <TableRow></TableRow>
    </TableBody>
    <VBTableFooter>
      <TableRow>
        <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
        <VBTableCellFooter></VBTableCellFooter>
        <VBTableCellFooter sx={{ textAlign: "right"}} > {formatNumber(total.TotalCurrent)} </VBTableCellFooter>
        <VBTableCellFooter sx={{ textAlign: "right"}} > {formatNumber(total.TotalFirst)}</VBTableCellFooter>
        <VBTableCellFooter sx={{ textAlign: "right"}} > {formatNumber(total.TotalSecond)} </VBTableCellFooter>
        <VBTableCellFooter sx={{ textAlign: "right"}} > {formatNumber(total.TotalThird)} </VBTableCellFooter>
        <VBTableCellFooter sx={{ textAlign: "right"}} > {formatNumber(total.TotalFourth)} </VBTableCellFooter>
        <VBTableCellFooter sx={{ textAlign: "right"}} > {formatNumber(total.TotalFifth)} </VBTableCellFooter>
        <VBTableCellFooter sx={{ textAlign: "right"}} > {formatNumber(total.TotalSixth)} </VBTableCellFooter>
        <VBTableCellFooter sx={{ textAlign: "right"}} > {formatNumber(total.Total)} </VBTableCellFooter>
      </TableRow>
    </VBTableFooter>
  </VBTableContainer>
  )
}


export default LedgerwiseTable


import axios from "axios";


export const PurchaseReturnDetails = async function (body) {
    try {
        const { data } = await axios.post("api/v11/purchaseReturns/get-details/", {
            ...body,
        })
        return data;
    } catch (error) {
        console.log("Error on Purchase Return Get Details Api",error);
        return error
    }
}


export const LedgerDetails = async function (body) {
    try {
        const { data } = await axios.post("api/v11/accountLedgers/ledgerListByID/", {
            ...body,
        })
        return data;
    } catch (error) {
        console.log("Error on getting ledger Api",error);
        return error
    }
}

export const CreatePurchaseReturn = async function (body) {
    try {
        const { data } = await axios.post("api/v11/purchaseReturns/purchase-return/", {
            ...body,
        })
        return data;
    } catch (error) {
        console.log("Error on Purchase Return Api",error);
        return error
    }
}

export const ListPurchaseReturn = async function (body) {
    try {
        const { data } = await axios.get("api/v11/purchaseReturns/purchase-return/", {
            params:body,
        })
        return data;
    } catch (error) {
        console.log("Error on Purchase Return Api",error);
        return error
    }
}

export const PurchaseReturnList = async function (body) {
    try {
        const { data } = await axios.get("api/v11/purchaseReturns/purchase-return/", {
            params:body,
        })
        return data;
    } catch (error) {
        console.log("Error on Purchase Return  Api",error);
        return error
    }
}

export const PurchaseReturnEdit = async function (body) {
    try {
        const { data } = await axios.put("api/v11/purchaseReturns/purchase-return/", {
            ...body,
        })
        return data;
    } catch (error) {
        console.log("Error on Purchase Return  Api",error);
        return error
    }
}


export const Call_PurchaseReturn_details= async function (body) {
    try {
        const { data } = await axios.get("api/v11/purchaseReturns/purchase-return-details/", {
            params:body,
        })
        return data;
    } catch (error) {
        console.log("Error on Sales EStimate Api",error);
        return error
    }
}




export const delete_purchase_return_api= async function (body) {
    try {
        const { data } = await axios.delete("api/v11/purchaseReturns/purchase-return/", {
            data:body,
        })
        return data;
    } catch (error) {
        console.log("Error on Purchase Return  Api",error);
        return error
    }
}
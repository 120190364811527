import React, { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  List,
  ListItem,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { VBSkeletonLoader, VBTableCellBody, VBTableCellFooter, VBTableCellHeader, VBTableContainer, VBTableFooter, VBTableHeader, VBTableRowNull } from '../../../../Components/ReportTable/ReportTable';
import FilterLable from '../../../../Components/Utils/FilterLable';
import SearchReport from '../../../../Components/Utils/SearchReport';
import FilterBtn from '../../../../Components/Utils/FilterBtn';
import ExportBtn from '../../../../Components/Utils/ExportBtn';
import PrintBtn from '../../../../Components/Utils/PrintBtn';
import Pagenation from '../../../../Components/Utils/Pagenation';
import { CallExpenseSummaryReport } from '../../../../Api/Reports/FinanceApi';
import moment from 'moment';
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from '../../../../Components/Utils/ViknAutoComplete';
import { date_range } from '../../../../Function/comonFunction';
import { CallBranchsList, CallEmployees, CallUsers } from '../../../../Api/Reports/CommonReportApi';
import ViknSearchAutoComplete from '../../../../Components/Utils/ViknSearchAutoComplete';
import VBDatePiker from '../../../../Components/Utils/VBDatePiker';
import { callPurchaseInteratedReport, callSearchAccountLedgers } from '../../../../Api/Reports/PurchaseApi';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CallRecieptReport } from '../../../../Api/Reports/AccountApi';
import { openSnackbar } from '../../../../features/SnackBarslice';
import { formatNumber } from '../../../../generalFunction';
import ReportDownload from '../../../../Components/Utils/ReportDownload';
import { VIKNBOOKS_FRONT_URL } from '../../../../generalVeriable';
import { UserRolePermission } from '../../../../Function/Editions';

const ReciptReport = () => {
  let Export_view = UserRolePermission("Receipt Report", "print_permission");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const dispatch = useDispatch()
  const [reportData, setReportData] = useState({
    data:[],
    TotalData:{},
  })
  const [t, i18n] = useTranslation("common");
  const [progress,setProgress] = useState(0)
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [page_no, setPage_no] = useState(1);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [Ledger, setLedger] = useState([0]);
  const [isLoadingLedgers, setIsLoadingLedgers] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [isLoadingSalesman, setIsLoadingSalesman] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    userList : [],
    UserObj : {customer_name : ""},
    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment(),
    },

    LedgerList: [],
    LedgerListValue:[],
    CashLedgers:[0],

    VoucherType :{ key: "All", value: "All" },
    voucher_types: [
      { key: "All", value: "All" },
      { key: "CASH RECEIPT", value: "CR" },
      { key: "BANK RECEIPT", value: "BR" },
    ],

    salesmanList : [],
    salesmanObj : {LedgerName : ""}

  })


  // handle function 
  
  const closeFilter = () => setOpenFilter(false);

  const searchReport = function()  {
    page_no === 1 ? fetchReport()
    : setPage_no(() => 1)
  }

  //default filter list
  const handleDefaultFilter = async () => {
    setFilterOptions({
      ...filterOptions,
      UserObj:{customer_name:''},
      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Today" },
        FromDate: moment(),
        ToDate: moment(),
      },
      LedgerList: [],
      LedgerListValue:[],
      CashLedgers:[0],
      VoucherType :{ key: "All", value: "All" },
      salesmanObj: { LedgerName:''}

    })
  }

    // API Fetch data
    const fetchReport = async function () {
      setLoading(true);
      let CashLedgers = await handleCashLedgers()
      const data = await CallRecieptReport({
        CompanyID:CompanyID,
        BranchID:BranchID,
        PriceRounding:Number(PriceDecimalPoint),
        CreatedUserID:user_id,
        FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
        ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
        UserID: filterOptions.UserObj?.UserID ? filterOptions.UserObj.UserID : 0,
        items_per_page : appSetting.itemPerPage,
        page : page_no,
        VoucherType:filterOptions?.VoucherType.value,
        CashLedgers,
        EmployeeID : filterOptions.salesmanObj?.EmployeeID ? filterOptions.salesmanObj.EmployeeID : 0,
      },dispatch);
      console.log(data,'dtaddddddddddaatatatatat');
      if (data?.StatusCode === 6000) {
        setReportData({
          TotalData: data.TotalData,
          count: data.count,
          data: data.data
        });
        if (data?.count === 0) {
          dispatch(
            openSnackbar({
              open: true,
              message: 'Data Not Found During This Time!',
              severity : 'warning',
            })  
          )
        }
        // setPage()
      } else if (data?.StatusCode === 6001){
        setReportData({
          data:[],
          TotalData:{},
        });
        dispatch(
          openSnackbar({
            open: true,
            message: data.message,
            severity : 'warning',
          })  
        )
      }
      setLoading(false)
    };

    const handleAutoComplete = function (e, value, name) {
      if (value) {
        if (name === "date") {
          console.log(e, value);
          if (value.name === "Custom") {
            setFilterOptions({
              ...filterOptions,
              date: {
                ...filterOptions.date,
                isActive: true,
                value: value,
              },
            });
          } else {
            let date = date_range(value.name);
            setFilterOptions({
              ...filterOptions,
              date: {
                ...filterOptions.date,
                isActive: false,
                value: value,
                ToDate: date.todate,
                FromDate: date.fromdate,
              },
            });
          }
        } else {
          setFilterOptions({ ...filterOptions, [name]: value });
        }
      }
    };
    const handleFilterType = (value, name) => {
      if (name === 'VoucherType') {
        setFilterOptions((prevState) => ({
          ...prevState,
          VoucherType: { key: value.key, value: value.value }
        }));
        console.log(value.value, 'eeeeeeeeeeeeeeeeeeee');
      }
    }

    const handleFilterList = () => {
      let list = [];
      let filterdata = filterOptions
      console.log(filterdata,'>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      if (filterdata.date.FromDate &&filterdata.date.ToDate ) {
        let date = String(filterOptions.date.FromDate.format("DD-MM-YYYY") + " to " + filterOptions.date.ToDate.format("DD-MM-YYYY"))
        list.push({ name: t("Date"), value: date });
      }
      if (filterdata && filterdata?.VoucherType !== undefined && filterdata.VoucherType !== ''){
        list.push({
          name: t('FilterType'),
          value : filterdata?.VoucherType?.key
        })
      }

      if (filterdata && filterdata.UserObj && filterdata.UserObj.customer_name !== undefined && filterdata.UserObj.customer_name !== ''){
        list.push({
          name: t('User'),
          value: filterdata.UserObj.customer_name,
          handleDelete: () => handleDeletefilter('UserObj')
        })
      }

      if (filterdata.LedgerListValue && filterdata.LedgerListValue.length) {
        filterdata.LedgerListValue.forEach((ledger, index) => {
            if (ledger.LedgerName !== "") {
                list.push({
                    name: t("Ledger"),
                    value: ledger.LedgerName,
                    handleDelete: () => handleDeletefilter("LedgerListValue", index)
                });
            }
        });
      }

      if (filterdata && filterdata.salesmanObj && filterdata.salesmanObj.LedgerName !== undefined && filterdata.salesmanObj.LedgerName !== ''){
        list.push({
          name: t('Sales Man'),
          value: filterdata.salesmanObj.LedgerName,
          handleDelete: () => handleDeletefilter('salesmanObj')
        })
      }
      
      return list;
    }
    // handle delete 
    const handleDeletefilter = (name, index) => {
      console.log(name, index);
      if (name === "LedgerListValue") {
        let LedgerListValue = [...filterOptions.LedgerListValue];
        LedgerListValue = LedgerListValue.filter((item, idx) => idx !== index);
        setFilterOptions((prevState) => ({
          ...prevState,
          LedgerListValue: LedgerListValue,
        }));
      } else {
        setFilterOptions((prevState) => ({ ...prevState, [name]: '' }));
      }
    };
  //handle Ledgers 
  const handleSearchLedgers = async (e,value) => {
    if (value) {
      setIsLoadingLedgers(true);
      let length = value.length;
      let LedgersList = await callSearchAccountLedgers({
        CompanyID:CompanyID,
        BranchID:BranchID,
        PriceRounding:Number(PriceDecimalPoint),
        CreatedUserID:user_id,
        length : length,
        product_name : value,
        param : 'name',
      })
      let LedgerList = [];
      if (LedgersList.data) {
        LedgerList = LedgersList.data
      }
      setFilterOptions({
        ...filterOptions,
        LedgerList,
      })
      setIsLoadingLedgers(false)
    }
  }

  const handleCashLedgers = async (exp) => {
    let CashLedgers = [0];
    let LedgerListValue = filterOptions.LedgerListValue;
    const LedgerIDs = LedgerListValue.map(item => item.LedgerID);
    CashLedgers.push(...LedgerIDs);
    if(exp){
      setLedger(CashLedgers)
    }
    return CashLedgers; 
  }

  // search Employee 
  const handleSearchEmployee = async (e, value, name) => {
    if (value) {
      setIsLoadingSalesman(true);
      let SalesmanList = await CallEmployees({
        CompanyID : CompanyID,
        CreatedUserID : user_id,
        BranchID : BranchID,
        PriceRounding : Number(PriceDecimalPoint),
        items_per_page : 10,
        page_no: 1,
        search: value,
      })

      let salesman_data = [];
      if (SalesmanList.data) {
        salesman_data = SalesmanList.data;
      }
      setFilterOptions({
        ...filterOptions,
        salesmanList:salesman_data,
      })
      setIsLoadingSalesman(false);
    } else {
      setFilterOptions({
        ...filterOptions,
        salesmanObj : {LedgerName:''}
      })
    }
  }

  // filter API's
  const filterApis = async function () {
    setIsLoadingUser (true);
    const userResponse = await CallUsers({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      search: "",
      PartyType: "1",
    });
    let userList = [];
    if (userResponse?.StatusCode === 6000){
      userList = userResponse.data;
    }

    setIsLoadingSalesman(true);
    const SalesmanResponse = await CallEmployees({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,
      items_per_page: 10,
      page_no: 1,
      search: "",
    });
    let salesmanList = [];
    if (SalesmanResponse?.StatusCode === 6000) {
      salesmanList = SalesmanResponse.data;
    }
    setFilterOptions({
      ...filterOptions,
      userList,
      salesmanList,
    });
    setIsLoadingUser (false);
    setIsLoadingSalesman(false);

  }
  const getDataForPage = () => {
    const startIndex = (page_no -1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData?.data
    return alldata.slice(startIndex, endIndex) || [];
  }
  useEffect(() => {
    filterApis();
  }, [])

  useEffect(() => {
    fetchReport()
  },[page_no])

  const handleTableRowClick = (id, VoucherType, VoucherNo) => {
    let destinationURL;
    let URL;
    let is_print = true ;
    URL = `${VIKNBOOKS_FRONT_URL}dashboard/receipt-single?boo=true &is_print=$${is_print}`;
    destinationURL = `${URL}?&id=${id}`;
    window.open(destinationURL, '_blank');
  };
  return (
    <>
      <Paper sx={{width:'100%', height:'100%'}} >
        <Box 
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className='left'>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t('Receipt Report')}
            </Typography>
            {/* <SearchReport /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>
          <FlexBox className='right'>
            {Export_view ? 
            <ReportDownload
              download_type={"PDF"}
              invoice_type={"receipt_report"}
              ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
              FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
              VoucherType={filterOptions?.VoucherType.value}
              RouteLedgers={Ledger}
              UserID={filterOptions.UserObj?.UserID ? filterOptions.UserObj.UserID : 0}
              is_celery={true}
              setProgress={setProgress}
              handleRouteList={filterOptions.LedgerListValue.length ? handleCashLedgers : 0}
            />
            : null}
            {/* <PrintBtn /> */}
            <Pagenation 
              totalItem={reportData?.count}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
        <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
          {t("Applied Filters: ")}
        </div>
          {handleFilterList(filterOptions).map((value,index) => (
            <FilterLable
            name = {value.name}
            value = {value.value}
            handleDelete = {value.handleDelete?value.handleDelete:null}
            />
          ))}

        </Box>
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t('Serial No')}</VBTableCellHeader>
            <VBTableCellHeader> {t('Voucher No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Date')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Ledger Name')}</VBTableCellHeader>
            <VBTableCellHeader>{t('User')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Payment Mode')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Voucher Type')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Amount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Discount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Net Amount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"center"}}>{t('Notes')}</VBTableCellHeader>

          </VBTableHeader>
          {Loading ? 
          <VBSkeletonLoader /> :
          <TableBody>
          {reportData.data.map((data, i) => (
            <TableRow
            onClick={() =>
              handleTableRowClick(data.id, data.VoucherType, data.VoucherNo)
            }
            >
              <VBTableCellBody  sx={{cursor:"pointer"}}>{(page_no - 1) * appSetting.itemPerPage + i + 1}</VBTableCellBody>
              <VBTableCellBody  sx={{cursor:"pointer"}}>{data.VoucherNo}</VBTableCellBody>
              <VBTableCellBody  sx={{cursor:"pointer"}}>{data.Date}</VBTableCellBody>
              <VBTableCellBody  sx={{cursor:"pointer"}}>{data.LedgerName}</VBTableCellBody>
              <VBTableCellBody  sx={{cursor:"pointer"}}>{data.User}</VBTableCellBody>
              <VBTableCellBody  sx={{cursor:"pointer"}}>{data.MasterLedgerName}</VBTableCellBody>
              <VBTableCellBody  sx={{cursor:"pointer"}}>{data.VoucherType}</VBTableCellBody>             
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.Amount)}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.Discount)}</VBTableCellBody>             
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.NetAmount)}</VBTableCellBody>
              <VBTableCellBody sx={{cursor:"pointer",textAlign:"center"}}>{data.Notes ? data.Notes : '-'}</VBTableCellBody>
            </TableRow>
          ))}
          <TableRow />
          {/* <VBTableRowNull length = {reportData?.new_data?.length}/> */}
          </TableBody>
          }
          <VBTableFooter >
            <TableRow>
              <VBTableCellFooter >{t('Total')}</VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.TotalData?.Amount ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.TotalData?.Discount ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.TotalData?.NetAmount ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}></VBTableCellFooter>

              {/* <VBTableCellFooter align="right">{reportData[1]?.Amount}</VBTableCellFooter> */}
            </TableRow>
          </VBTableFooter>
        </VBTableContainer>
      </Paper>
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={searchReport}
        handleDefaultFilter={()=>handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker 
                handleAutoComplete={handleAutoComplete}
                dateValue={filterOptions.date.value}
                isDateRange={true}
                fromDate={filterOptions.date.FromDate}
                toDate={filterOptions.date.ToDate}
                fromOnChange={(value) => 
                  setFilterOptions({
                    ...filterOptions,
                    date:{
                      ...filterOptions.date,
                      FromDate: value,
                      value:{ name: "Custom" }
                    },
                  })
                }
                toOnChange={(value) => 
                  setFilterOptions({
                    ...filterOptions,
                    date:{
                      ...filterOptions.date,
                      ToDate: value,
                      value:{ name: "Custom" }
                    }
                  })
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
            <ViknAutoComplete
              value={filterOptions.VoucherType}
              disableClearable
              options={filterOptions.voucher_types}
              getOptionLabel={(option) => option.key}
              onChange={(e, newValue) =>
                handleFilterType(newValue, "VoucherType")
              }
            />

            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                multiple
                placeholder={t("Ledger Name")}
                filterName={t("Ledger Name")}
                loading={isLoadingLedgers}
                options={filterOptions.LedgerList ? filterOptions.LedgerList : []}
                getOptionLabel={(option) => option.LedgerName || ""}
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    (option.LedgerName && option.LedgerName.toLowerCase().includes(inputValue.toLowerCase())) ||
                    (option.LedgerCode && option.LedgerCode.toLowerCase().includes(inputValue.toLowerCase()))
                  )
                }
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.LedgerCode}</Box>
                    <Box sx={{fontSize: "14px",}}>{option.LedgerName}</Box>
                  </Box>
                )}
                name="LedgerListValue"
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    handleSearchLedgers(event,value);
                  }if(reason === "clear"){
                    setFilterOptions({ ...filterOptions, LedgerListValue:[]});
                  }
                }}
                onChange={(e, v) =>
                  handleAutoComplete(e, v, "LedgerListValue",)
                }
                value={filterOptions.LedgerListValue}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete 
                freeSolo
                placeholder = {t("User")}
                filterName = {t("User")}
                loading = {isLoadingUser}
                options = {filterOptions.userList ? filterOptions.userList : []}
                getOptionLabel={(option) => option.customer_name || ""}
                name = "UserObj"
                value={filterOptions.UserObj}
                onInputChange={(event, value, reason) => { 
                  // if (reason === "input") {
                  //   handleSearchUser(event,value);
                  // }
                  if(reason === "clear"){
                    setFilterOptions({ ...filterOptions, UserObj: {} });
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "UserObj")}
                
              />
            </ListItem>
            <Divider />

            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                placeholder={t("Sales man")}
                filterName={t("Sales man")}
                loading={isLoadingSalesman}
                options={
                  filterOptions.salesmanList ? filterOptions.salesmanList : []
                }
                getOptionLabel={(option) => option.LedgerName || ""}
                name="salesmanObj"
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    handleSearchEmployee(event, value);
                  }
                  if (reason === "clear") {
                    handleSearchEmployee();
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "salesmanObj", -1)}
                value={filterOptions.salesmanObj}
              />
            </ListItem>
            <Divider />

          </List>
        }
      />
    </>
  )
}

export default ReciptReport

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap:"22px",
  },
}));
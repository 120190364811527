import axios from "axios";


export const SalesEstimateDetails = async function (body) {
    try {
        const { data } = await axios.post("/api/v11/salesEstimates/get-Details/", {
            ...body,
        })
        return data;
    } catch (error) {
        console.log("Error on Sales EStimate Api",error);
        return error
    }
}

export const LedgerDetails = async function (body) {
    try {
        const { data } = await axios.post("api/v11/accountLedgers/ledgerListByID/", {
            ...body,
        })
        return data;
    } catch (error) {
        console.log("Error on getting ledger Api",error);
        return error
    }
}


export const SalesEstimateCreate = async function (body) {
    try {
        const { data } = await axios.post("api/v11/salesEstimates/sales-Estimate/", {
            ...body,
        })
        return data;
    } catch (error) {
        console.log("Error on Sales EStimate Api",error);
        return error
    }
}


export const SalesEstimateList = async function (body) {
    try {
        const { data } = await axios.get("api/v11/salesEstimates/sales-Estimate/", {
            params:body,
        })
        return data;
    } catch (error) {
        console.log("Error on Sales EStimate Api",error);
        return error
    }
}


export const EditSalesEstimateList = async function (body) {
    try {
        const { data } = await axios.put("api/v11/salesEstimates/sales-Estimate/", {
            ...body
        })
        return data;
    } catch (error) {
        console.log("Error on Sales EStimate Api",error);
        return error
    }
}


export const delete_sales_estimate_api = async function (body) {
  
    try {
      const { data } = await axios.delete("api/v11/salesEstimates/sales-Estimate/", {
        data:body,
      });
      return data;
    } catch (error) {
      console.log("error on Sales EStimate  API", error);
      return error
    }
  };
  

  export const saleEstimate_details = async function (body) {
    try {   
      const { data } = await axios.get("api/v11/salesEstimates/sales-Estimate-details/", {
        params:body,
      });
      console.log(data,"hguhg");
      return data;
    } catch (error) {
      console.log("error on Sales Estimate Master view  API", error);
      return error
    }
  };
  

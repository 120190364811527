import React from 'react'
import { VBTableCellBody, VBTableCellFooter, VBTableCellHeader, VBTableContainer, VBTableFooter, VBTableHeader, VBTableRowNull } from '../../../../../Components/ReportTable/ReportTable'
import { TableBody, TableRow } from '@mui/material'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const LedgerwiseTable = ({data, total, periods, page}) => {
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [t, i18n] = useTranslation("common");

  return (
    <VBTableContainer>
    <VBTableHeader>
      <VBTableCellHeader>{t("SI NO")}</VBTableCellHeader>
      <VBTableCellHeader>{t("Customer Name")} </VBTableCellHeader>
      <VBTableCellHeader>{t("Current")} </VBTableCellHeader>
      <VBTableCellHeader>{periods.first} - {periods.second} days </VBTableCellHeader>
      <VBTableCellHeader>{Number(periods.second) + 1} - {periods.third} days </VBTableCellHeader>
      <VBTableCellHeader>{Number(periods.third) + 1} - {periods.fourth} days </VBTableCellHeader>
      <VBTableCellHeader>{Number(periods.fourth) + 1} - {periods.fifth} days </VBTableCellHeader>
      <VBTableCellHeader>{Number(periods.fifth) + 1} - {periods.sixth} days </VBTableCellHeader>
      <VBTableCellHeader>{periods.sixth + " > days	"}</VBTableCellHeader>
      <VBTableCellHeader>{t("Total")} </VBTableCellHeader>
    </VBTableHeader>
    <TableBody>
      {data.map((e, i) => (
        <TableRow>
          <VBTableCellBody> {(page - 1) * appSetting.itemPerPage + i + 1} </VBTableCellBody>
          <VBTableCellBody> {e.DisplayName} </VBTableCellBody>
          <VBTableCellBody> {e.Current} </VBTableCellBody>
          <VBTableCellBody> {e.First_DueAmout} </VBTableCellBody>
          <VBTableCellBody> {e.Second_DueAmout} </VBTableCellBody>
          <VBTableCellBody> {e.Third_DueAmout} </VBTableCellBody>
          <VBTableCellBody> {e.Fourth_DueAmout} </VBTableCellBody>
          <VBTableCellBody> {e.Fifth_DueAmout} </VBTableCellBody>
          <VBTableCellBody> {e.Sixth_DueAmout} </VBTableCellBody>
          <VBTableCellBody> {e.Total} </VBTableCellBody>
        </TableRow>
      ))}
      {<VBTableRowNull length={data?.length}/>}
    </TableBody>
    <VBTableFooter>
      <TableRow>
        <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
        <VBTableCellFooter></VBTableCellFooter>
        <VBTableCellFooter> {total.TotalCurrent} </VBTableCellFooter>
        <VBTableCellFooter> {total.TotalFirst} </VBTableCellFooter>
        <VBTableCellFooter> {total.TotalSecond} </VBTableCellFooter>
        <VBTableCellFooter> {total.TotalThird} </VBTableCellFooter>
        <VBTableCellFooter> {total.TotalFourth} </VBTableCellFooter>
        <VBTableCellFooter> {total.TotalFifth} </VBTableCellFooter>
        <VBTableCellFooter> {total.TotalSixth} </VBTableCellFooter>
        <VBTableCellFooter> {total.Total} </VBTableCellFooter>
      </TableRow>
    </VBTableFooter>
  </VBTableContainer>
  )
}


export default LedgerwiseTable


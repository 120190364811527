import { useTranslation } from "react-i18next";
import Pagenation from "../../../Components/Utils/Pagenation";
import { blue } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VIKNBOOKS_FRONT_URL } from "../../../generalVeriable";
import { MenuOptions } from "../../../Components/CommonComponents";
import { Navigate, useNavigate } from "react-router-dom";
import ConfirmBox from "../../../Components/Utils/ConfirmBox";
import { openSnackbar } from "../../../features/SnackBarslice";

import {
  callListEmployee,
  deleteEmployee,
} from "../../../Api/Contacts/contacts";

import {
  Box,
  Paper,
  TableBody,
  TableRow,
  Typography,
  Checkbox,
  IconButton,
} from "@mui/material";

import {
  CreateNewButton,
  DeleteButton,
} from "../../../Components/Utils/StyledButtons";

import {
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
} from "../../../Components/ReportTable/ReportTable";

function ListEmployee() {
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("common");
  const appSetting = useSelector((state) => state.appSettingsSclice);

  const [page_no, setPage_no] = useState(1);
  const [IsConfirmBox, setConfirmBox] = useState(false);
  const [response, setResponse] = useState({
    count: null,
    data: [],
    selectedItems: [],
    isSelectedAll: false,
  });

  let user_role_settings = useSelector((state) => state.userRollSettings);
  let permissions = user_role_settings.filter((i) => i.name === "Employee");

  let createPermission = permissions[0].save_permission;
  let editPermission = permissions[0].edit_permission;
  let deletePermission = permissions[0].delete_permission;
  let viewPermission = permissions[0].view_permission;

  const OptionList = [
    { name: "Edit", permission: editPermission },
    { name: "Delete", permission: deletePermission },
  ];

  const confirmDelete = () => {
    setConfirmBox(true);
  };

  const OptionOnClick = (e, name, uniq_id) => {
    if (e) {
      let permission = OptionList.find((i) => i.name === name)?.permission;
      if (name === "Edit" && permission) {
        navigate("/edit-employee", {
          state: { is_edit: true, uniq_id: uniq_id },
        });
        console.log("navigate to  edit");
      } else if (name === "Delete" && permission) {
        setResponse({ ...response, selectedItems: [uniq_id] });
        setConfirmBox(true);
      }
    }
  };

  const selectItems = (type, id) => {
    let data = [...response.selectedItems];
    let isSelectedAll = response.isSelectedAll;
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (response.isSelectedAll) {
        data = [];
      } else {
        response.data.map((i) => {
          data.push(i.id);
        });
      }
    } else {
      let index = data.findIndex((item) => item === id);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
    }
    setResponse({
      ...response,
      selectedItems: data,
      isSelectedAll: isSelectedAll,
    });
  };

  // const SingleView = (id) => {
  //   if (viewPermission) {
  //     //   const url = `${VIKNBOOKS_FRONT_URL}dashboard/sales-return-preview?id=${id}&unq_id=${id}`;
  //     //   const newTab = window.open(url, "_blank");
  //     console.log("navigate to single view page");
  //   }
  // };
  const deleteData = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      selecte_ids: response.selectedItems,
    };

    let responseData = await deleteEmployee(
      { ...payload },
      ...response.selectedItems
    );
    if (responseData.StatusCode === 6000) {
      fetchEmployeeData();

      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message,
          severity: "success",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message ?? "Something went wrong!",
          severity: "error",
        })
      );
    }
  };

  const fetchEmployeeData = async () => {
    const employee_list = await callListEmployee({
      PriceRounding: 4,
      CreatedUserID: user_id,
      BranchID: BranchID,
      CompanyID: CompanyID,
      page_no: page_no,
      items_per_page: 15,
      search: "",
    });

    if (employee_list.data.StatusCode == 6000) {
      setResponse((prevState) => ({
        ...prevState,
        data: employee_list.data.data,
        count: employee_list.data.count,
      }));
    }
  };

  useEffect(() => {
    fetchEmployeeData();
  }, [page_no]);

  return (
    <Paper sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          px: "26px",
          py: "15px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "2px solid #F5F5F5",
        }}
      >
        <Typography
          sx={{
            fontSize: "25px",
            fontWeight: "500",
            mr: 2,
            color: "#001746",
          }}
          variant="h2"
        >
          Employee
        </Typography>
        <Box sx={{ display: "flex" }}>
          <DeleteButton onClick={confirmDelete} t={t} />
          <CreateNewButton
            onClick={() => {
              if (createPermission) {
                navigate("/create-employee");
              } else {
                dispatch(
                  openSnackbar({
                    open: true,
                    message: "You don't have permission for create",
                    severity: "warning",
                  })
                );
              }
            }}
            t={t}
          />
          <Pagenation
            totalItem={response.count}
            page_no={page_no}
            setPage_no={setPage_no}
          />
        </Box>
      </Box>
      <VBTableContainer>
        <VBTableHeader>
          <VBTableCellHeader
            sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
          >
            <Checkbox
              checked={response.isSelectedAll} // The checked prop determines whether this checkbox is checked.
              onChange={() => {
                selectItems("all");
              }}
              sx={{
                padding: "2px",
                margin: 0,
                color: blue[400],
                "&.Mui-checked": {
                  color: blue[400],
                },
                "& .MuiSvgIcon-root": { fontSize: "20px" },
              }}
            />
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>
            {t("Serial No")}
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>
            {t("First name")}
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>
            {t("Gender")}
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>
            {t("Date of Joining")}
          </VBTableCellHeader>{" "}
          <VBTableCellHeader sx={{ textAlign: "left" }}>
            {t("Nationality")}
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>
            {t("Email")}
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}> </VBTableCellHeader>
        </VBTableHeader>
        <TableBody>
          {response.data.map((item, index) => (
            <TableRow key={index}>
              <VBTableCellBody
                sx={{
                  // cursor: "pointer",
                  textAlign: "left",
                  padding: "0px",
                  paddingLeft: "5px",
                }}
              >
                <Checkbox
                  checked={
                    response.selectedItems.includes(item.id) ? true : false
                  }
                  onChange={() => {
                    selectItems("not", item.id);
                  }}
                  sx={{
                    padding: "2px",
                    margin: 0,
                    color: blue[400],
                    "&.Mui-checked": {
                      color: blue[400],
                    },
                    "& .MuiSvgIcon-root": { fontSize: "20px" },
                  }}
                />
              </VBTableCellBody>
              <VBTableCellBody
                sx={{ textAlign: "left", cursor: "pointer" }}
                // onClick={() => SingleView(item.id)}
              >
                {(page_no - 1) * appSetting.itemPerPage + index + 1}
                {/* {index + 1} */}
              </VBTableCellBody>
              <VBTableCellBody
                sx={{ textAlign: "left" }}
                // onClick={() => SingleView(item.id)}
              >
                {item.FirstName}
              </VBTableCellBody>
              <VBTableCellBody
                sx={{ textAlign: "left" }}
                // onClick={() => SingleView(item.id)}
              >
                {item.Gender ? item.Gender : "-"}
              </VBTableCellBody>
              <VBTableCellBody
                sx={{ textAlign: "left" }}
                // onClick={() => SingleView(item.id)}
              >
                {item.DateOfJoining}
              </VBTableCellBody>
              <VBTableCellBody
                sx={{ textAlign: "left" }}
                // onClick={() => SingleView(item.id)}
              >
                {item.Nationality_Name}
              </VBTableCellBody>
              <VBTableCellBody
                sx={{ textAlign: "left" }}
                // onClick={() => SingleView(item.id)}
              >
                {item.Email}
              </VBTableCellBody>
              <VBTableCellBody
                sx={{
                  cursor: "pointer",
                  textAlign: "right",
                }}
              >
                <MenuOptions
                  OptionList={OptionList}
                  OptionOnClick={OptionOnClick}
                  uniq_id={item.id}
                />
                {/* <IconButton>
                  <MoreVertIcon />
                </IconButton> */}
              </VBTableCellBody>
            </TableRow>
          ))}

          <TableRow></TableRow>
        </TableBody>
      </VBTableContainer>
      <ConfirmBox
        heading={"Confirm to delete?"}
        message={"Once you delete, you will not get it back!"}
        open={IsConfirmBox}
        setOpen={setConfirmBox}
        confirmFunction={deleteData}
      />
    </Paper>
  );
}

export default ListEmployee;

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// Light theme
const lightTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Poppins',
      color: "black"
    }
  },
  components: {
    MuiPopover: {
      styleOverrides:{
        paper: {
          "& .MuiList-root .MuiButtonBase-root": {
            fontSize: "14px"
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          fontSize: '14px', // Adjust the font size as needed
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
        '&:nth-of-type(odd)': {
          backgroundColor: "#F9F9F9",
        },
      }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      }
    }
  },
  palette: {
    mode: 'light',
    background: {
      default: "#ebebeb",
    },
    primary: {
      main: "#101418",
    },
    secondary: {
      main: '#0A9EF3',
    },
    error: {
      main: red.A400,
    },
  },
});

// Dark theme
const darkTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Poppins',
      color: "white"
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#212121",
          boxShadow: '0px 4px 6px rgba(255, 255, 255, 0.1)',
        },
      }
    },
    MuiPopover: {
      styleOverrides:{
        paper: {
          "& .MuiList-root .MuiButtonBase-root": {
            fontSize: "14px",
            color: "white"
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          fontSize: '14px', // Adjust the font size as needed
          color: "white"
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    background: {
      default: "#3a3a3a",
    },
    primary: {
      main: "#101418",
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

export { lightTheme, darkTheme };
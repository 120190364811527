import React from 'react';

const ComingSoon = () => {
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f0f8ff',
            textAlign: 'center',
            fontFamily: 'Arial, sans-serif',
            color: '#333',
        },
        heading: {
            fontSize: '3rem',
            marginBottom: '1rem',
        },
        subheading: {
            fontSize: '1.5rem',
            marginBottom: '2rem',
            color: '#007acc',
        },
        betaLabel: {
            display: 'inline-block',
            backgroundColor: '#24d624',
            color: 'white',
            padding: '0.5rem 1rem',
            borderRadius: '5px',
            fontSize: '1rem',
            marginBottom: '2rem',
        },
        illustration: {
            width: '300px',
            height: 'auto',
            marginBottom: '2rem',
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.betaLabel}>Beta Version</div>
            <h1 style={styles.heading}>Coming Soon</h1>
            <h2 style={styles.subheading}>We're working hard to bring you something amazing!</h2>
        </div>
    );
}

export default ComingSoon;

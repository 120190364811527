import React from 'react'
import { VBTableCellBody, VBTableCellFooter, VBTableCellHeader, VBTableContainer, VBTableFooter, VBTableHeader } from '../../../../../Components/ReportTable/ReportTable'
import { TableBody, TableRow } from '@mui/material'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const BillwiseTable = ({data, total, page}) => {
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [t, i18n] = useTranslation("common");
  return (
    <VBTableContainer>
    <VBTableHeader>
      <VBTableCellHeader>{t("SI NO")}</VBTableCellHeader>
      <VBTableCellHeader>{t("Voucher No")} </VBTableCellHeader>
      <VBTableCellHeader>{t("Voucher Date")} </VBTableCellHeader>
      <VBTableCellHeader>{t("Supplier Name")} </VBTableCellHeader>
      <VBTableCellHeader>{t("Amount")} </VBTableCellHeader>
      <VBTableCellHeader>{t("Due Date")} </VBTableCellHeader>
      <VBTableCellHeader>{t("Received")} </VBTableCellHeader>
      <VBTableCellHeader>{t("Balance")} </VBTableCellHeader>
      <VBTableCellHeader>{t("Post Voucher Date")}	 </VBTableCellHeader>
      <VBTableCellHeader>{t("Post Due Date")}	 </VBTableCellHeader>
    </VBTableHeader>
    <TableBody>
      {data.map((e, i) => (
        <TableRow>
          <VBTableCellBody> {(page - 1) * appSetting.itemPerPage + i + 1} </VBTableCellBody>
          <VBTableCellBody> {e.VoucherNo} </VBTableCellBody>
          <VBTableCellBody> {e.Date} </VBTableCellBody>
          <VBTableCellBody> {e.LedgerName} </VBTableCellBody>
          <VBTableCellBody> {e.GrandTotal} </VBTableCellBody>
          <VBTableCellBody> {e.DueDate} </VBTableCellBody>
          <VBTableCellBody> {e.Payments} </VBTableCellBody>
          <VBTableCellBody> {e.Balance} </VBTableCellBody>
          <VBTableCellBody> {e.PastVoucherDate} </VBTableCellBody>
          <VBTableCellBody> {e.PastDueDate} </VBTableCellBody>
        </TableRow>
      ))}
      <TableRow></TableRow>
    </TableBody>
    <VBTableFooter>
      <TableRow>
        <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
        <VBTableCellFooter></VBTableCellFooter>
        <VBTableCellFooter></VBTableCellFooter>
        <VBTableCellFooter></VBTableCellFooter>
        <VBTableCellFooter>{total.AmountTotal}</VBTableCellFooter>
        <VBTableCellFooter></VBTableCellFooter>
        <VBTableCellFooter>{total.RecievedTotal}</VBTableCellFooter>
        <VBTableCellFooter> {total.BalanceTotal} </VBTableCellFooter>
        <VBTableCellFooter></VBTableCellFooter>
        <VBTableCellFooter></VBTableCellFooter>
      </TableRow>
    </VBTableFooter>
  </VBTableContainer>
  )
}

export default BillwiseTable
import axios from "axios";
import { openSnackbar } from "../../features/SnackBarslice";
import { store } from "../../app/store";

const state = store.getState();
const { PriceDecimalPoint } = state.generalSettingsSclice.generalSettings;

const callCreateCustomer = async function (body, dispatch) {
  try {
    const data = axios({
      method: "post",
      url: "api/v11/parties/create-party/",
      data: body,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};



const create_customer_edit_api = async function (body, dispatch) {
  try {
    const data = axios({
      method: "put",
      url: "api/v11/parties/create-party/",
      data: body,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};


const Get_code  = async function (body, dispatch) {
  try {
    const data = axios({
      method: "get",
      url: "api/v11/parties/create-party/",
      params: body,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const callCreateSupplier = async function (body, dispatch) {
  try {
    const data = axios({
      method: "post",
      url: "api/v11/parties/create-party/",
      data: body,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const callCreateEmployee = async function (body, dispatch) {
  try {
    const data = axios({
      method: "post",
      url: "api/v10/employees/create-employee/",
      data: body,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const callListEmployee = async function (body, dispatch) {
  try {
    const data = axios({
      method: "post",
      url: "api/v11/employees/employees-list/",
      data: body,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const deleteEmployee = async function (body, id) {
  try {
    const { data } = await axios.post(
      "api/v10/employees/delete/employee/" + id + "/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const GetEmployeeDetails = async function (body, id) {
  try {
    const { data } = await axios.post(
      "api/v10/employees/view/employee/" + id + "/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const UpdateEmployeeDetails = async function (body, id) {
  try {
    const { data } = await axios.post(
      "api/v10/employees/edit/employee/" + id + "/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export {
  callCreateCustomer,
  callCreateSupplier,
  callCreateEmployee,
  callListEmployee,
  deleteEmployee,
  GetEmployeeDetails,
  UpdateEmployeeDetails,
  Get_code,
  create_customer_edit_api
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : {
      access: "",
      user_id: "",
      role: "",
      username: "",
      isAuth: false,
      admin_login: false,
      is_update: false,
    };

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      console.log(action.payload);
      state.access = action.payload.access;
      state.user_id = action.payload.user_id;
      state.role = action.payload.role;
      state.username = action.payload.username;
      state.photo = action.payload.photo;
      state.isAuth = action.payload.isAuth;
      state.admin_login = action.payload.admin_login;
      state.is_update = action.payload.is_update;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    logoutSuccess: (state, action) => {
      state.access = null;
      state.user_id = null;
      state.username = null;
      state.photo = null;
      state.isAuth = false;
      state.admin_login = false;
      state.is_update = false;
      localStorage.setItem("user", JSON.stringify(action.payload));
      localStorage.removeItem("user");
      localStorage.removeItem("generalSettings");
      localStorage.removeItem("companyDetails");
      localStorage.removeItem("financialYear");
      localStorage.removeItem("userTable");
      // localStorage.removeItem("versions");
    },
  },
});

export const { loginSuccess, logoutSuccess } = userSlice.actions;

export const selectUserName = (state) => state.user.name;
export const selectUserEmail = (state) => state.user.email;
export const selectUserPhoto = (state) => state.user.photo;
export const accessToken = (state) => state.user.access;
export const selectAuthenticated = (state) => state.user.isAuth;
export const selectAdminLogin = (state) => state.user.admin_login;
export const selectIsUpdate = (state) => state.user.is_update;

export default userSlice.reducer;

import { Box, Zoom } from "@mui/material"

const VBZoomAnime = (props) => {
  return (
    <Zoom unmountOnExit style={{ transitionDelay: props.in ? '50ms' : '0ms' }} {...props}>
        <Box minWidth={"100%"}>
            {props.children}
        </Box>
    </Zoom>
  )
}

export default VBZoomAnime
import React from "react";
import { useTranslation } from "react-i18next";
// import styled from "styled-components";
import { Tooltip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const ActionButton = (props) => {
  const [t, i18n] = useTranslation("common");
  return (
    props.tool?
    <Tooltip title="Temporary unavailable; please try again later." arrow placement="bottom">
    <button
      width={props.width}
      background={props.background}
      type="button"
      onClick={props.onClick}
    >
      {props.icon}
      <p color={props.color}>{t(props.label)}</p>
      </button>
      </Tooltip>
      : 
      <button
      width={props.width}
      background={props.background}
      type="button"
      onClick={props.onClick}
    >
      {props.icon}
      <p color={props.color}>{t(props.label)}</p>
      
      </button>
    
  );
};

export default ActionButton;
// const Button = styled.button`
//   margin-right: 10px;
//   cursor: pointer;
//   padding: 3px 10px;

//   width: ${({ width }) => (width ? width + "px" : "120px")};
//   height: 35px;
//   color: #fff;
//   border: 0;
//   border-radius: 50px;
//   background: ${({ background }) => (background ? background : "#2951d1")};
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   transition: all ease-in-out 0.3s;
//   &:hover {
//     opacity: 0.8;
//   }
// `;

// const ButtonText = styled.p`
//   width: 60%;
//   color: ${({ color }) => (color ? color : "#fff")};
//   text-transform: capitalize;
// `;

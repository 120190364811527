import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ListItemIcon, ListItemText, Menu, MenuItem, Button, alpha,CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Icons } from "../../Assets/AssetLog";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 135,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const ExportMenu = (props) => {
  const [t, i18n] = useTranslation("common");
  const [status, setStatus] = useState(0);
  const [pdf, setPdf] = useState(false);
  const [excel, setExcel] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  console.log(props.checking, "props.checking<<<<");
  return (
    <div>
      <Button
        variant="text"
        startIcon={<img src={Icons.Export} alt="export" />}
        sx={{ textTransform: "none", fontWeight: "400" }}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
      >
        {t('Export')}
      </Button>
      {props.checking === "productSummaryReport" ? (
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setPdf(!pdf);
              if (excel === true) {
                setExcel(false);
              }
            }}
          >
            <ListItemIcon>
              <PictureAsPdfIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("PDF")} />
          </MenuItem>
          {pdf ? (
            <MenuItem
              onClick={() => {
                props.showLoader
                  ? console.log("DESABLES")
                  : props.ExportTo("PDF", "PI",props.is_celery);
              }}
            >
              <ListItemText primary={t("Purchase")} />
            </MenuItem>
          ) : null}
          {pdf ? (
            <MenuItem
              onClick={() => {
                props.showLoader
                  ? console.log("DESABLES")
                  : props.ExportTo("PDF", "SI",props.is_celery);
              }}
            >
              <ListItemText primary={t("Sales")} />
            </MenuItem>
          ) : null}

          <MenuItem
            onClick={() => {
              setExcel(!excel);
              if (pdf === true) {
                setPdf(false);
              }
            }}
          >
            <ListItemIcon>
              <ListAltIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Excel")} />
          </MenuItem>
          {excel ? (
            <MenuItem
              onClick={() => {
                props.showLoader
                  ? console.log("DESABLES")
                  : props.ExportToXLS
                  ? props.ExportToXLS()
                  : props.ExportTo("EXCEL", "PI",props.is_celery);
              }}
            >

              <ListItemText primary={t("Purchase")} />
            </MenuItem>
          ) : null}
          {excel ? (
            <MenuItem
              onClick={() => {
                props.showLoader
                  ? console.log("DESABLES")
                  : props.ExportToXLS
                  ? props.ExportToXLS()
                  : props.ExportTo("EXCEL", "SI",props.is_celery);
              }}
            >

              <ListItemText primary={t("Sales")} />
            </MenuItem>
          ) : null}

        </StyledMenu>
      ) : props.checking === "Trial Balance" ? (
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem

            onClick={() => {
              setPdf(!pdf);
              if (excel === true) {
                setExcel(false);
              }
            }}
          >
            <ListItemIcon>
              <PictureAsPdfIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("PDF")} />
          </MenuItem>
          {pdf ? (
            <MenuItem
              onClick={() => {
                props.showLoader
                  ? console.log("DESABLES")
                  : props.ExportTo("PDF", "trial_balance_detailed");
              }}
            >

              <ListItemText primary={t("Detailed")} />
            </MenuItem>
          ) : null}
          {pdf ? (
            <MenuItem
              onClick={() => {
                props.showLoader
                  ? console.log("DESABLES")
                  : props.ExportTo("PDF", "trial_balance_non_detailed");
              }}
            >

              <ListItemText primary={t("Consolidate")} />
            </MenuItem>
          ) : null}


          <MenuItem
            onClick={() => {
              setExcel(!excel);
              if (pdf === true) {
                setPdf(false);
              }
            }}
          >
            <ListItemIcon>
              <ListAltIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Excel")} />
          </MenuItem>
          {excel ? (
            <MenuItem
              onClick={() => {
                props.showLoader
                  ? console.log("DESABLES")
                  : props.ExportToXLS
                  ? props.ExportToXLS()
                  : props.ExportTo("EXCEL", "trial_balance_detailed");
              }}
            >
              <ListItemText primary={t("Detailed")} />
            </MenuItem>
          ) : null}
          {excel ? (
            <MenuItem
              onClick={() => {
                props.showLoader
                  ? console.log("DESABLES")
                  : props.ExportToXLS
                  ? props.ExportToXLS()
                  : props.ExportTo("EXCEL", "trial_balance_non_detailed");
              }}
            >
              <ListItemText primary={t("Consolidate")} />
            </MenuItem>
          ) : null}
        </StyledMenu>
      ) : (
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
        >
          <MenuItem
            onClick={() => {
              if (!props.showLoader) {
                setStatus(1)
                props.ExportTo("PDF", null, props.is_celery)
              } else {
                console.log("DISABLED")
              }
            }}
          >
            <ListItemIcon>
              <PictureAsPdfIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("PDF")} />
            {props.showLoader && status === 1 && (
                <CircularProgress size={24} sx={{ color: 'primary.main', marginLeft: 1 }} />
              )}
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (!props.showLoader) {
                setStatus(2)
                if (props.ExportToXLS) {
                  props.ExportToXLS()
                } else {
                  props.ExportTo("EXCEL", null, props.is_celery)
                }
              } else {
                console.log("DISABLED")
              }
            }}
          >
            <ListItemIcon>
              <ListAltIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Excel")} />
            {props.showLoader && status === 2 &&(
                <CircularProgress size={24} sx={{ color: 'primary.main', marginLeft: 1 }} />
              )}
          </MenuItem>
        </StyledMenu>
      )}
    </div>
  );
};
export default ExportMenu;

// const Button = styled.button`
//   &.preview {
//     background: #f5f5f5;
//     border: 1px solid #ccc;
//     color: #000;
//     margin-left: 5px;
//   }
//   border: 0;
//   cursor: pointer;
//   background: green;
//   color: #fff;
//   padding: 8px 25px;
//   border-radius: 3px;
// `;

// const ReloadCircle = styled(AutorenewIcon)`
//   animation: rotation 1s linear infinite;
//   color: black;
//   @keyframes rotation {
//     from {
//       transform: rotate(0deg);
//     }
//     to {
//       transform: rotate(359deg);
//     }
//   }
// `;
// const StyledMenu = styled(Menu)(({ theme }) => ({
//   paper: {
//     border: `1px solid ${theme.palette.divider}`,
//   },
// }));

// Define styles for the MenuItem component
// const MenuItem = styled(MenuItem)(({ theme }) => ({
//   '&:focus': {
//     backgroundColor: theme.palette.primary.main,
//     '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
//       color: theme.palette.common.white,
//     },
//   },
// }));

// const ExportButton = styled(Button)`
//   && {
//     display: flex;
//     align-items: center;
//     text-transform: capitalize;
//     background: transparent;
//     color: #000;
//     padding: 5px 10px;
//     padding-right: 20px;
//     border-radius: 50px;
//     margin-right: 10px;
//     box-shadow: none;
//     svg {
//       color: #000;
//       margin-right: 10px;
//       font-size: 20px;
//     }
//     &:hover {
//       box-shadow: none;
//       background: transparent;
//     }
//   }
// `;

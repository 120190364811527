import styled from "@emotion/styled";
import VBInputField from "../../../Components/Utils/VBInputField";
import VBSelect from "../../../Components/Utils/VBSelect";
import { useEffect, useState } from "react";
import VBDatePiker from "../../../Components/Utils/VBDatePiker";
import moment from "moment";
import ViknAutoComplete from "../../../Components/Utils/ViknAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import {
  callCreateEmployee,
  GetEmployeeDetails,
  UpdateEmployeeDetails,
} from "../../../Api/Contacts/contacts";
import { useLocation, useNavigate } from "react-router-dom";
import { openSnackbar } from "../../../features/SnackBarslice";

import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Divider,
} from "@mui/material";

import {
  department_list,
  designation_list,
  list_countries,
  list_states,
} from "../../../Api/CommonApi/CommonApis";

function CreateEmployee() {
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);

  const is_edit = location.state?.is_edit ?? params.get("is_edit");
  const uniq_id = location.state?.uniq_id ?? params.get("unq_id");

  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [state, setState] = useState("male");

  const navigate = useNavigate();

  const [dataList, setDataList] = useState({
    designationList: [],
    departmentList: [],
    nationalityList: [],
    stateList: [],
  });

  const [uploadData, setUploadData] = useState({
    employeeName: "",
    gender: "",
    designation: null,
    dateOfJoining: moment(),
    department: null,
    showInSales: false,
    isActive: false,
    phone: "",
    dateOfBirth: moment(),
    personalEmail: "",
    nationality: null,
    postalCode: "",
    state: null,
    residentialAddress: "",
  });

  const dataFuction = async () => {
    const designationListResponse = await designation_list({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      item_per_page: 15,
      page_no: 1,
    });

    const departmentListResponse = await department_list(
      {
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        item_per_page: 15,
        page_no: 1,
      },
      uniq_id
    );

    const countryListResponse = await list_countries();

    if (designationListResponse.StatusCode == 6000) {
      setDataList((prevState) => ({
        ...prevState,
        designationList: designationListResponse.data,
      }));
    }

    if (departmentListResponse.StatusCode == 6000) {
      setDataList((prevState) => ({
        ...prevState,
        departmentList: departmentListResponse.data,
      }));
    }

    if (countryListResponse.StatusCode == 6000) {
      setDataList((prevState) => ({
        ...prevState,
        nationalityList: countryListResponse.data,
      }));
    }

    if (is_edit && uniq_id) {
      const employeeDetails = await GetEmployeeDetails(
        {
          PriceRounding: 2,
          CreatedUserID: user_id,
          BranchID: BranchID,
          CompanyID: CompanyID,
        },
        uniq_id
      );

      const designation = designationListResponse.data.find(
        (designation) =>
          designation.DesignationID === employeeDetails.data.DesignationID
      );


      const department = departmentListResponse.data.find(
        (department) =>
          department.DepartmentID === employeeDetails.data.DepartmentID
      );

      const country = countryListResponse.data.find(
        (country) => country.id === employeeDetails.data.Nationality
      );

      const stateListResponse = await list_states(country.id);
      if (stateListResponse.StatusCode === 6000) {
        setDataList((prevState) => ({
          ...prevState,
          stateList: stateListResponse.data,
        }));
      }
      const state = stateListResponse.data.find(
        (state) => state.id === employeeDetails.data.State
      );

      if (employeeDetails.StatusCode === 6000) {
        setUploadData((prevState) => ({
          ...prevState,
          employeeName: employeeDetails.data.FirstName,
          gender: employeeDetails.data.Gender,
          designation: designation,
          dateOfJoining: moment(),
          department: department,
          showInSales: employeeDetails.data.ShowInSales,
          isActive: employeeDetails.data.IsActive,
          phone: employeeDetails.data.Phone,
          dateOfBirth: moment(),
          personalEmail: employeeDetails.data.Email,
          nationality: country,
          state: state,
          postalCode: employeeDetails.data.Post,
          residentialAddress: employeeDetails.data.Address1,
        }));
      }
    }
  };

  const callLocation = async (country_id) => {
    const stateListResponse = await list_states(country_id);

    if (stateListResponse.StatusCode == 6000) {
      setDataList((prevState) => ({
        ...prevState,
        stateList: stateListResponse.data,
      }));
    }
  };

  const handleFormSubmit = async (e) => {
    const payload = {
      FirstName: uploadData.employeeName,
      LastName: "",
      DesignationID: uploadData.designation.DesignationID,
      Category: "",
      DepartmentID: uploadData.department.DepartmentID,
      DateOfBirth: uploadData.dateOfBirth.format("YYYY-MM-DD"),
      Gender: uploadData.gender,
      BloodGroup: "",
      Nationality: uploadData.nationality ? uploadData.nationality.id : null,
      State: uploadData.state ? uploadData.state.id : null,
      Address1: uploadData.residentialAddress,
      Address2: "",
      Address3: "",
      Post: uploadData.postalCode,
      Phone: uploadData.phone,
      Mobile: "",
      Email: uploadData.personalEmail,
      PassportNo: "",
      PassportExpiryDate: moment().format("YYYY-MM-DD"),
      VisaDetails: "",
      VisaExpiryDate: moment().format("YYYY-MM-DD"),
      ProbationPeriod: 0,
      PeriodType: "",
      DateOfJoining: uploadData.dateOfJoining.format("YYYY-MM-DD"),
      Salary: 0,
      AccountHolderName: "",
      AccountNumber: "",
      AccountBranch: "",
      AccountIFSC: "",
      NoCasualLeave: 0,
      Qualification: "",
      EmergencyContactNumber: "",
      EmergencyEmail: "",
      EmergencyAddress: "",
      Notes: "",
      LedgerName: uploadData.employeeName,
      DataBase: "default1",
      BranchID: BranchID,
      EmployeeCardID: "",
      WorkLocation: "",
      JobType: "full-time",
      WorkEmail: "",
      OfficialEmail: "",
      City: "",
      CreatedUserID: user_id,
      CompanyID: CompanyID,
      ShowInSales: uploadData.showInSales,
      ShowInPurchase: false,
      ShowInPayment: false,
      ShowInReceipt: false,
      IsActive: uploadData.isActive,
    };

    if (is_edit) {
      let responseData = await UpdateEmployeeDetails(
        payload,
        uniq_id,
        dispatch
      );
      if (responseData.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message || "Employee edited successfully",
            severity: "success",
          })
        );
        navigate("/list-employee");
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "error",
          })
        );
      }
    } else {
      const response = await callCreateEmployee(payload, dispatch);
      if (response.data.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: response.data?.message || "Employee Created Successfully",
            severity: "success",
          })
        );
        handleClear();
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: response.data?.message || response.data.message,
            severity: "error",
          })
        );
      }
    }
  };

  const handleClear = () => {
    setUploadData((prevState) => ({
      ...prevState,
      employeeName: "",
      gender: "",
      designation: null,
      dateOfJoining: moment(),
      department: null,
      showInSales: false,
      isActive: false,
      phone: "",
      dateOfBirth: moment(),
      personalEmail: "",
      nationality: null,
      postalCode: "",
      state: null,
      residentialAddress: "",
    }));
  };

  useEffect(() => {
    dataFuction();
  }, []);

  return (
    <>
      <Paper sx={{ width: "100%", minWidth: "1264px" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleFormSubmit();
          }}
        >
          <Box
            sx={{
              borderBottom: "1px solid",
              p: 3,
              borderColor: "#EBEBEB",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "600",
                  lineHeight: "36px",
                }}
              >
                {is_edit ? "Update Employee " : "Create Employee"}
              </Typography>
              <Button
                sx={{ fontSize: "16px", textTransform: "none", px: 2 }}
                variant="text"
                onClick={() => navigate("/list-employee")}
              >
                View ist
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 1,
              }}
            >
              <Button
                sx={{ fontSize: "16px", textTransform: "none", px: 2 }}
                variant="text"
                onClick={() => handleClear()}
              >
                Cancel
              </Button>
              <BlueButton sx={{ px: 4 }} type="submit">
                Save
              </BlueButton>
            </Box>
          </Box>
          <Box
            sx={{
              p: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                textTransform: "none",
                mb: "16px",
              }}
            >
              Employee Details
            </Typography>
            <Box sx={{ width: "35%", minWidth: "" }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <VBInputField
                    label="Employee Name"
                    required
                    value={uploadData.employeeName}
                    onChange={(e) =>
                      setUploadData((prevState) => ({
                        ...prevState,
                        employeeName: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <VBSelect
                    label="Gender"
                    value={uploadData.gender}
                    options={["male", "female"]}
                    handleChange={(e) => {
                      setUploadData((prevState) => ({
                        ...prevState,
                        gender: e.target.value,
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <ViknAutoComplete
                    label="Designation"
                    required
                    placeholder="Type here"
                    value={uploadData.designation}
                    options={dataList.designationList}
                    onChange={(event, newValue) =>
                      setUploadData((prevState) => ({
                        ...prevState,
                        designation: newValue,
                      }))
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(e) => e.DesignationName}
                  />
                </Grid>
                <Grid item xs={4}>
                  <VBDatePiker
                    label="Date of Joining"
                    fromDate={uploadData.dateOfJoining}
                    fromOnChange={(e) =>
                      setUploadData((prevState) => ({
                        ...prevState,
                        dateOfJoining: e,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={8}>
                  <ViknAutoComplete
                    label="Department"
                    required
                    placeholder="Type here"
                    value={uploadData.department}
                    options={dataList.departmentList}
                    onChange={(event, newValue) =>
                      setUploadData((prevState) => ({
                        ...prevState,
                        department: newValue,
                      }))
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(e) => e.DepartmentName || ""}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                  <Box sx={{ display: "flex" }}>
                    <FormControlLabel
                      sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                      label="Show in sales"
                      control={
                        <Checkbox
                          size="small"
                          checked={uploadData.showInSales}
                          onChange={(e) =>
                            setUploadData((prevState) => ({
                              ...prevState,
                              showInSales: e.target.checked,
                            }))
                          }
                        />
                      }
                    />
                    <FormControlLabel
                      sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                      label="Active"
                      control={
                        <Checkbox
                          size="small"
                          checked={uploadData.isActive}
                          onChange={(e) =>
                            setUploadData((prevState) => ({
                              ...prevState,
                              isActive: e.target.checked,
                            }))
                          }
                        />
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ p: 3 }}>
            <Typography sx={{ mb: "16px" }}>Personal Details</Typography>
            <Box sx={{ width: "35%" }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <VBInputField
                    label="Phone"
                    value={uploadData.phone}
                    onChange={(e) =>
                      setUploadData((prevState) => ({
                        ...prevState,
                        phone: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <VBDatePiker
                    label="Date of Birth"
                    fromDate={uploadData.dateOfBirth}
                    fromOnChange={(e) =>
                      setUploadData((prevState) => ({
                        ...prevState,
                        dateOfBirth: e,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={8}>
                  <VBInputField
                    label="Personal Email"
                    type="email"
                    required
                    value={uploadData.personalEmail}
                    onChange={(e) =>
                      setUploadData((prevState) => ({
                        ...prevState,
                        personalEmail: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <ViknAutoComplete
                    label="Nationality"
                    Oman
                    value={uploadData.nationality}
                    options={dataList.nationalityList}
                    onChange={(event, newValue) => {
                      newValue?.id && callLocation(newValue.id);
                      setUploadData((prevState) => ({
                        ...prevState,
                        nationality: newValue,
                        state: null,
                      }));
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(e) => e.Country_Name}
                  />
                </Grid>
                <Grid item xs={8}>
                  <VBInputField
                    label="Postal Code"
                    value={uploadData.postalCode}
                    onChange={(e) =>
                      setUploadData((prevState) => ({
                        ...prevState,
                        postalCode: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <ViknAutoComplete
                    label="State"
                    value={uploadData.state}
                    options={dataList.stateList}
                    onChange={(event, newValue) => {
                      setUploadData((prevState) => ({
                        ...prevState,
                        state: newValue,
                      }));
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(e) => e.Name}
                  />
                </Grid>
                <Grid item xs={8}>
                  <VBInputField
                    label="Residential Address"
                    value={uploadData.residentialAddress}
                    onChange={(e) =>
                      setUploadData((prevState) => ({
                        ...prevState,
                        residentialAddress: e.target.value,
                      }))
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      </Paper>
    </>
  );
}

export default CreateEmployee;

const BlueButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#0A9EF3",
  textTransform: "none",
  fontSize: "15px",
  ":hover": {
    backgroundColor: "#0A9EF3",
  },
}));

import { Navigate } from "react-router-dom";
// import SalesInvoice from "../../Pages/Inventory/SalesInvoice/SalesInvoice"
import { store } from "../../app/store";
import { activateFunction } from "../../Function/Editions";
import { elements } from "chart.js";
import { Outlet } from "react-router-dom";
import EinvoiceInvoiceList from "../../Pages/EInvoice/EinvoiceList";
import EinvoiceHome from "../../Pages/EInvoice/EinvoiceHome";
import EinvoiceInvoiceList2 from "../../Pages/Einvoice2/EinvoiceList";


const state = store.getState();


const ProtectedRoute = ({ children, authCondition }) => {
    let isAuthCondition = authCondition;
    return isAuthCondition ? children : <Navigate to="/dashboard" />;
  };


const route = [

    {path:"e-invoice/",element:<EinvoiceHome/>,isAuth:true,},
    {path:"list-e-invoice/",element:<EinvoiceInvoiceList2/>,isAuth:true,},
    {path:"list-e-invoice-2/",element:<EinvoiceInvoiceList2/>,isAuth:true,}
    
]


const Routes  = route.map((route)=>({
    ...route,
    element: <ProtectedRoute authCondition={route.isAuth}>{route.element}</ProtectedRoute>,
}))

const einvoiceRoutes = {
    path:"/",
    element: <Outlet />,
  children: [
    ...Routes
  ],
}


export default einvoiceRoutes
import axios from "axios";
import { openSnackbar } from "../../features/SnackBarslice";

const callSalesReport = async function (body,dispatch) {
  try {
    const { data } = await axios.post("api/v11/reports/sales-report/", {
      
      ...body,
    });
    return data;
  } catch (error) {
    console.log('error on sales report API',error);
    dispatch(
      openSnackbar({
        open : true,
        message : error.response?.data?.message || 'Error Occurred In API',
        severity : 'error',
      })
    )
  }
};

const callSalesOrderReport = async function (body,dispatch) {
  try {
    const { data } = await axios.post("api/v10/salesOrders/report/salesOrder/", {
      
      ...body,
    });
    return data;
  } catch (error) {
    console.log('error on Salesorder API',error);
    dispatch(
      openSnackbar({
        open : true,
        message : error.response?.data?.message || 'Error Occurred In API',
        severity : 'error',
      })
    )
  }
};

const callSalesSummary = async function (body,dispatch) {
  try {
    const { data } = await axios.post("api/v11/sales/sales-summary/report/", {
      
      ...body,
    });
    return data;
  } catch (error) {
    console.log('error on Salessummary API',error);
    dispatch(
      openSnackbar({
        open : true,
        message : error.response?.data?.message || 'Error Occurred In API',
        severity : 'error',
      })
    )
  }
};


const callSalesRegister = async function (body,dispatch) {
  try {
    const { data } = await axios.post("api/v11/reports/sales-register/report-qry/", {
      
      ...body,
    });
    return data;
  } catch (error) {
    console.log('error on SalesRegister API',error);
    dispatch(
      openSnackbar({
        open : true,
        message : error.response?.data?.message || 'Error Occurred In API',
        severity : 'error',
      })
    )
  }
};

const callSaleReturn = async function (body,dispatch) {
  try {
    const { data } = await axios.post("api/v10/salesReturns/salesReturn-register/report-qry/", {
      
      ...body,
    });
    return data;
  } catch (error) {
    console.log('error on salesReturn API',error);
    dispatch(
      openSnackbar({
        open : true,
        message : error.response?.data?.message || 'Error Occurred In API',
        severity : 'error',
      })
    )
  }
};

const CallSalesIntegratedReport = async function (body,dispatch) {
  try {
    const { data } = await axios.post("api/v10/sales/sales/integrated-report/", {
      
      ...body,
    });
    return data;
  } catch (error) {
    console.log('error on SalesIntegrated API',error);
    dispatch(
      openSnackbar({
        open : true,
        message : error.response?.data?.message || 'Error Occurred In API',
        severity : 'error',
      })
    )
  }
};

export {
  CallSalesIntegratedReport,
  callSaleReturn,
  callSalesOrderReport,
  callSalesRegister,
  callSalesReport,
  callSalesSummary,
};

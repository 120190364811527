import React, { useEffect, useState } from "react";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "../../Components/ReportTable/ReportTable";
import {
  Box,
  Button,
  Checkbox,
  Paper,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { blue } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import Pagenation from "../../Components/Utils/Pagenation";
import styled from "@emotion/styled";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  get_e_invoice_list,
  get_e_invoice_submit_api,
  get_e_invoice_view_api,
  get_egs_list,
  get_invoice_download_api,
  get_invoice_validate_api,
  get_e_invoice_re_submit_api
} from "../../Api/EInvoice/EInvoiceApis";
import { InvoiceAutoComplete } from "../../Components/CommonComponents";
import { openSnackbar } from "../../features/SnackBarslice";
// import ViewInvoice from "./EinvoiceComp";
import ViewInvoice from "./EInvoiceComponents";
const EinvoiceInvoiceList = () => {
  const dispatch = useDispatch();
  
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const noOfItems = appSetting.itemPerPage;

  const [page_no, setPage_no] = useState(1);
  
  const [openView,setOpenView] = useState(false)
  const [SelectedInvoice,setSelectedInvoice] = useState(null)
  const [apiResult,setApiResult] = useState(null)

  

  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [loadingView, setLoadingView] = useState(false);
  const [openIndex, setOpenIndex] = useState(-1);
  const [state, setState] = useState({
    dataList: [],
    egsList: [],
    egs: null,
    selectedItems: [],
    type_of_egs:{name:"sandbox",value:0},
    count:0,
    // singleView:{}
  });
  
  const handleOpenIndex = async(index) =>{
    setOpenIndex(openIndex!==index?index:-1)
  }

  const ClickViewInvoice = async(id,InvoiceName,VoucherNumber,transaction_id) =>{
    console.log(state);
    let invoiceList = await get_invoice_view(transaction_id);
    console.log(invoiceList.Data,"=========================");
    
    // let dataList = invoiceList.Data;
    // setState({ ...state, egsList, egs: egs, dataList,count:invoiceList.count });
    setSelectedInvoice({id:id,InvoiceName:InvoiceName,VoucherNumber:VoucherNumber,singleView:invoiceList.Data,transaction_id:transaction_id})
    let correctedResponse = invoiceList.Data.full_response?.replace(/'/g, '"');
    try {
      setApiResult(correctedResponse ? JSON.parse(correctedResponse) : "");
    } catch (error) {
      console.error("JSON parsing error:", error);
      setApiResult(correctedResponse); // Handle the error case
    }
    
    setOpenView(true)
  }
  const selectItems = (type, id) => {
    let data = [...state.selectedItems];
    let isSelectedAll = state.isSelectedAll;
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (state.isSelectedAll) {
        data = [];
      } else {
        state.dataList.map((i) => {
          data.push(i.id);
        });
      }
    } else {
      let index = data.findIndex((item) => item === id);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
    }

    setState({ ...state, selectedItems: data, isSelectedAll: isSelectedAll });
  };

  const onAutoChange = (e, v, name) => {
    if(v && name){
      let value = v
      setState({...state,[name]:value})
    }
  };

  const onInputChange = () => {
    //pass
  };

  const egs_list = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      type_of_egs:state?.type_of_egs.value
    };
    let Data = [];
    let res = await get_egs_list({ ...payload });
    if (res.StatusCode === 6000) {
      Data = res.data;
    }

    return Data;
  };

  const get_invoice_list = async (transaction_id="",IsView=false) => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      // uniq_id: uniq_id,
      type_of_egs:state?.type_of_egs.value,
      page_number:page_no,
      items_per_page:15,
      IsView:IsView,
      id:transaction_id,
    };
    let Data = [];
    let count = state.count;
    let res = await get_e_invoice_list({ ...payload });
    if (res){
      
      if (res.StatusCode === 6000) {
        Data = res.data ?? [];
        count = res.count ?? [];
      }
    }

    return {Data,count};
  };
  
  
  
  
  const get_invoice_view = async (id) => {
    let payload = {
      transaction_id:id
    };
    let Data = [];
    let count = state.count;
    let res = await get_e_invoice_view_api({ ...payload });
    if (res){
      
      if (res.StatusCode === 6000) {
        Data = res.data ?? [];
        count = res.count ?? [];
      }
    }

    return {Data,count};
  };
  
  
  
  
  
  
  
  
  
  const read__xml = async(data) => {
    let egs_id = state.egs.id
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      egs_id:egs_id,
      invoice_id:data.id,
      InvoiceName:data.InvoiceName,
      VoucherNumber:data.VoucherNumber,
      type_of_egs:state?.type_of_egs.value,
      is_download:true
      // is_read:true
    }
    
    
    let res = await get_invoice_validate_api({...payload});
  }
  
  


  const download_xml = async (data) => {
    let payload = {
      CompanyID: CompanyID, // Pass the required data here
      BranchID: BranchID,
      transaction_id: data.transaction_id,
      type_of_egs: state?.type_of_egs?.value,
      is_download: true,
    };
  
    try {
      const res = await get_invoice_download_api({ ...payload });
  
      // Check if response is ok
      if (res && res.status === 200) {
        // Create a Blob from the response data
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/xml' }));
        const link = document.createElement('a');
        link.href = url;
  
        // Set filename (use transaction_id or any other identifier)
        const filename = `download.xml`;
        link.setAttribute('download', filename);
  
        // Trigger download
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        console.error("Failed to download the file. Status: ", res.status);
      }
    } catch (error) {
      console.error("Error in downloading XML file", error);
    }
  };


  // const download_xml = async (data) => {
  //   let payload = {
  //     CompanyID: CompanyID,
  //     BranchID: BranchID,
  //     transaction_id: data.transaction_id,
  //     type_of_egs: state?.type_of_egs.value,
  //     is_download: true,
  //   };
  
  //   try {
  //     // Ensure responseType is set to blob to handle file download
  //     let res = await get_invoice_download_api({ ...payload }, { responseType: 'blob' });
  
  //     // Create filename from Content-Disposition header or default to download.xml
  //     const contentDisposition = res.headers?.['content-disposition'];
  //     let filename = 'download.xml';  // Default filename
  
  //     if (contentDisposition) {
  //       const disposition = contentDisposition.split(';');
  //       disposition.forEach(part => {
  //         if (part.trim().startsWith('filename=')) {
  //           filename = part.split('=')[1].replace(/['"]/g, '');
  //         } else if (part.trim().startsWith('filename*=')) {
  //           filename = decodeURIComponent(part.split("''")[1]);
  //         }
  //       });
  //     }
  
  //     // Create a new blob from the response data
  //     const blob = new Blob([res.data], { type: res.headers?.['content-type'] || 'application/xml' });
  
  //     // Create a download link for the blob and trigger the download
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = filename;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  
  //   } catch (error) {
  //     console.error('Error downloading file:', error);
  
  //     // Optional: Log the error response for debugging
  //     if (error.response) {
  //       console.log("Server response:", error.response);
  //     }
  //   }
  // };
  
  
  
  
  const validate_xml = async(data) =>{
    setLoadingView(true)
    let egs_id = state.egs.id
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      egs_id:egs_id,
      invoice_id:data.id,
      InvoiceName:data.InvoiceName,
      VoucherNumber:data.VoucherNumber,
      type_of_egs:state?.type_of_egs.value
    }
    
    
    let res = await get_invoice_validate_api({...payload});
    
    
    if (res.StatusCode === 6000){
      setApiResult(res.data)
      dispatch(
        openSnackbar({
          open: true,
          message: res?.message??"Success",
          severity: "info",
        })
      );
    }
    else{
      setApiResult(res.data)
      dispatch(
        openSnackbar({
          open: true,
          message: res.message,
          severity: "error",
        })
      );
    }
    setLoadingView(false)
    
  }
  
  
  const submitInvoice = async(data,submit_type) => {
    setLoadingView(true)
    console.log(data,"<-data");
    
    
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      transaction_id:data.transaction_id,
      submit_type:submit_type,
      type_of_egs:state?.type_of_egs.value
    }
    
    let res = await get_e_invoice_submit_api({...payload});
    
    
    if (res.StatusCode === 6000){
      setApiResult(res.validationResults)
      let single = SelectedInvoice?.singleView
      if (single) {
        single.invoice_status = res.Status;        
      }
      setSelectedInvoice({...SelectedInvoice,singleView:single})
      dispatch(
        openSnackbar({
          open: true,
          message: res?.message??"Success",
          severity: "info",
        })
      );      
    }
    else{
      setApiResult(res.validationResults)
      dispatch(
        openSnackbar({
          open: true,
          message: res.message,
          severity: "error",
        })
      );
    }
    setLoadingView(false)
    
  }
  
  
  
  const resubmit = async (data,submit_type) =>{
    setLoadingView(true)
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      transaction_id:data.transaction_id,
      submit_type:submit_type,
      type_of_egs:state?.type_of_egs.value
    }
    

    console.log(data,"<-data");
    
  
    
    let res = await get_e_invoice_re_submit_api({...payload});
    
    
    if (res.StatusCode === 6000){
      setApiResult(res.validationResults)
      let single = SelectedInvoice?.singleView
      if (single) {
        single.invoice_status = res.Status;        
      }
      // setSelectedInvoice({...SelectedInvoice,singleView:single})
      dispatch(
        openSnackbar({
          open: true,
          message: res?.message??"Success",
          severity: "info",
        })
      );   
      setOpenView(false)   
    }
    else{
      setApiResult(res.validationResults)
      dispatch(
        openSnackbar({
          open: true,
          message: res.message,
          severity: "error",
        })
      );
    }
    setLoadingView(false)
  }
  
  

  useEffect(() => {
    const fetchData = async () => {
      // let egsList = await egs_list();
      // let egs = egsList[0];
      let invoiceList = await get_invoice_list();
      let dataList = invoiceList.Data;
      setState({ ...state,  dataList,count:invoiceList.count });
    };

    fetchData();
  }, [page_no]);
  
  
  console.log(state,"----staete");
  

  return (
    <Paper sx={{ height: "100%", width: "100%" }}>
      <Box
        sx={{
          px: "26px",
          py: "15px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "2px solid #F5F5F5",
        }}
      >
        <FlexBox className="left">
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: "500",
              mr: 2,
              color: "#001746",
            }}
            variant="h2"
          >
            {t("Zatca E-Invoice Report")}
          </Typography>

          {/* <InvoiceAutoComplete
            name={"egs"}
            label={"Select a Egs"}
            optionLabel={"egs_name"}
            List={state.egsList}
            Value={state.egs}
            OnChange={onAutoChange}
            OnInputChange={onInputChan
            ge}
          /> */}
          <p style={{color:"#0A9EF3"}}>Egs Name : {state?.egs?.egs_name}</p>
          
          
          <InvoiceAutoComplete
            name={"type_of_egs"}
            label={"Select a type_of_egs"}
            optionLabel={"name"}
            List={[{name:"sandbox",value:0},{name:"simulation",value:1},{name:"production",value:2}]}
            Value={state.type_of_egs}
            OnChange={onAutoChange}
            OnInputChange={onInputChange}
          />
          
        </FlexBox>

        <FlexBox className="right">
          <Pagenation
            totalItem={state.count}
            page_no={page_no}
            setPage_no={setPage_no}
          />
          {/* <SettingsIcon/> */}
        </FlexBox>
      </Box>

      <VBTableContainer>
        <VBTableHeader>
          <VBTableCellHeader
            sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
          >
            <Checkbox
              checked={state.isSelectedAll} // The checked prop determines whether this checkbox is checked.
              onChange={() => {
                selectItems("all");
              }}
              sx={{
                padding: "2px",
                margin: 0,
                color: blue[400],
                "&.Mui-checked": {
                  color: blue[400],
                },
                "& .MuiSvgIcon-root": { fontSize: "20px" },
              }}
            />
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>
            {t("Serial No")}
          </VBTableCellHeader>
          <VBTableCellHeader>{t("VoucherNumber")}</VBTableCellHeader>
          <VBTableCellHeader>{t("CustomerName")}</VBTableCellHeader>
          <VBTableCellHeader>{t("CreatedDate")}</VBTableCellHeader>
          <VBTableCellHeader>{t("InvoiceName")}</VBTableCellHeader>
          <VBTableCellHeader >{t("Gross Amount")}</VBTableCellHeader>
          <VBTableCellHeader >{t("Tax Amount")}</VBTableCellHeader>
          <VBTableCellHeader >{t("Grand Total")}</VBTableCellHeader>
          <VBTableCellHeader>{t("xml")}</VBTableCellHeader>
          <VBTableCellHeader>{t("Status")} </VBTableCellHeader>
          <VBTableCellHeader >
              Action
          </VBTableCellHeader>
        </VBTableHeader>

        {loading ? (
          <VBSkeletonLoader />
        ) : (
          // <TableBody>
          //   {state.dataList.map((data, i) => {
          //     <>
          //     <TableRow
          //     // onClick={() => navigate(filterOptions.filterType==="return" || data.GrandTotal < 0 ? VIKNBOOKS_FRONT_URL+"/dashboard/sales-return-preview" :  VIKNBOOKS_FRONT_URL+"/dashboard/sale-invoice-single", { state: { invoice_type: "sales_invoice", id: data.id }, unq_id: data.id })}
          //     //   onClick={()=>{handleTableRowClick(data)}}
          //     >
          //       <VBTableCellBody
          //         sx={{
          //           // cursor: "pointer",
          //           textAlign: "left",
          //           padding: "0px",
          //           paddingLeft: "5px",
          //         }}
          //       >
          //         <Checkbox
          //           checked={
          //             state.selectedItems.includes(data.id) ? true : false
          //           } // The checked prop determines whether this checkbox is checked.
          //           onChange={() => {
          //             selectItems("not", data.id);
          //           }}
          //           sx={{
          //             padding: "2px",
          //             margin: 0,
          //             color: blue[400],
          //             "&.Mui-checked": {
          //               color: blue[400],
          //             },
          //             "& .MuiSvgIcon-root": { fontSize: "20px" },
          //           }}
          //         />
          //       </VBTableCellBody>
          //       <VBTableCellBody
          //         sx={{  cursor: "pointer" }}
          //       >
          //          {(page_no - 1) * 15 + i + 1}
          //       </VBTableCellBody>
          //       <VBTableCellBody sx={{  cursor: "pointer" }}>
          //         {data?.VoucherNumber}
          //       </VBTableCellBody>

          //       <VBTableCellBody sx={{ cursor: "pointer" }}>
          //         {data?.CustomerName}
          //       </VBTableCellBody>
          //       <VBTableCellBody
          //         className="truncated-text "
          //         sx={{ cursor: "pointer" }}
          //       >
          //         {data?.CreatedDate}
          //       </VBTableCellBody>
          //       <VBTableCellBody sx={{ cursor: "pointer" }}>
          //         {data?.InvoiceName}
          //       </VBTableCellBody>
          //       <VBTableCellBody sx={{  cursor: "pointer" }}>
          //         {data?.GrossAmount}
          //       </VBTableCellBody>
          //       <VBTableCellBody sx={{  cursor: "pointer" }}>
          //         {data?.TaxAmount}
          //       </VBTableCellBody>
          //       <VBTableCellBody sx={{  cursor: "pointer" }}>
          //         {data?.GrandTotal}
          //       </VBTableCellBody>
          //       <VBTableCellBody sx={{  cursor: "pointer" }}>
          //       <Button onClick={()=>{download_xml({transaction_id:data.transaction_id})}}>Download</Button>
          //       </VBTableCellBody>
          //       <VBTableCellBody
          //         sx={{  cursor: "pointer",color:data.Status===0?"blue":data.Status ===1 || data.Status ==4?"green":data.Status === 2 || data.Status ==6?"red":data.Status ===3 || data.Status ==5 ?"orange":"black" }}
          //       >
          //         {data.Status===0?"Not Submitted":data.Status ===1?"Success":data.Status === 2?"Failed":data.Status ===3 ?"Success With Warnings":data.Status ==4?"Resubmit and success":data.Status ==5?"resubmit and success with warnings":data.Status ==6?"resubmit and failed":"Unknown Status"}    
          //       </VBTableCellBody>



          //       {(data.Status===0 || data.Status===1 || data.Status===2) ?
          //           <VBTableCellBody sx={{  cursor: "pointer" }}>
          //             <Button onClick={()=> ClickViewInvoice(data.id,data?.InvoiceName,data?.VoucherNumber,data.transaction_id) }>View</Button>  
          //           </VBTableCellBody>

          //           :


          //           <VBTableCellBody sx={{  cursor: "pointer" }}>
          //             <Button onClick={()=> handleOpenIndex(i) }>Open</Button>  
          //           </VBTableCellBody>
          //       }



          //     </TableRow>
          //     {openIndex ===i?

          //       {i.ReportList.map((data, j) => (
          //        <TableRow
          //        // onClick={() => navigate(filterOptions.filterType==="return" || data.GrandTotal < 0 ? VIKNBOOKS_FRONT_URL+"/dashboard/sales-return-preview" :  VIKNBOOKS_FRONT_URL+"/dashboard/sale-invoice-single", { state: { invoice_type: "sales_invoice", id: data.id }, unq_id: data.id })}
          //        //   onClick={()=>{handleTableRowClick(data)}}
          //        >
          //          <VBTableCellBody
          //            sx={{
          //              // cursor: "pointer",
          //              textAlign: "left",
          //              padding: "0px",
          //              paddingLeft: "5px",
          //            }}
          //          >
          //            <Checkbox
          //              checked={
          //                state.selectedItems.includes(data.id) ? true : false
          //              } // The checked prop determines whether this checkbox is checked.
          //              onChange={() => {
          //                selectItems("not", data.id);
          //              }}
          //              sx={{
          //                padding: "2px",
          //                margin: 0,
          //                color: blue[400],
          //                "&.Mui-checked": {
          //                  color: blue[400],
          //                },
          //                "& .MuiSvgIcon-root": { fontSize: "20px" },
          //              }}
          //            />
          //          </VBTableCellBody>
          //          <VBTableCellBody
          //            sx={{  cursor: "pointer" }}
          //          >
          //             { j + 1}
          //          </VBTableCellBody>
          //          <VBTableCellBody sx={{  cursor: "pointer" }}>
          //            {data?.VoucherNumber}
          //          </VBTableCellBody>
   
          //          <VBTableCellBody sx={{ cursor: "pointer" }}>
          //            {data?.CustomerName}
          //          </VBTableCellBody>
          //          <VBTableCellBody
          //            className="truncated-text "
          //            sx={{ cursor: "pointer" }}
          //          >
          //            {data?.CreatedDate}
          //          </VBTableCellBody>
          //          <VBTableCellBody sx={{ cursor: "pointer" }}>
          //            {data?.InvoiceName}
          //          </VBTableCellBody>
          //          <VBTableCellBody sx={{  cursor: "pointer" }}>
          //            {data?.GrossAmount}
          //          </VBTableCellBody>
          //          <VBTableCellBody sx={{  cursor: "pointer" }}>
          //            {data?.TaxAmount}
          //          </VBTableCellBody>
          //          <VBTableCellBody sx={{  cursor: "pointer" }}>
          //            {data?.GrandTotal}
          //          </VBTableCellBody>
          //          <VBTableCellBody sx={{  cursor: "pointer" }}>
          //          <Button onClick={()=>{download_xml({transaction_id:data.transaction_id})}}>Download</Button>
          //          </VBTableCellBody>
          //          <VBTableCellBody
          //            sx={{  cursor: "pointer",color:data.Status===0?"blue":data.Status ===1 || data.Status ==4?"green":data.Status === 2 || data.Status ==6?"red":data.Status ===3 || data.Status ==5 ?"orange":"black" }}
          //          >
          //            {data.Status===0?"Not Submitted":data.Status ===1?"Success":data.Status === 2?"Failed":data.Status ===3 ?"Success With Warnings":data.Status ==4?"Resubmit and success":data.Status ==5?"resubmit and success with warnings":data.Status ==6?"resubmit and failed":"Unknown Status"}    
          //          </VBTableCellBody>
   
   
   
          //          {(data.Status===0 || data.Status===1 || data.Status===2) ?
          //              <VBTableCellBody sx={{  cursor: "pointer" }}>
          //                <Button onClick={()=> ClickViewInvoice(data.id,data?.InvoiceName,data?.VoucherNumber,data.transaction_id) }>View</Button>  
          //              </VBTableCellBody>
   
          //              :
   
   
          //              <VBTableCellBody sx={{  cursor: "pointer" }}>
          //                <Button onClick={()=> handleOpenIndex(i) }>Open</Button>  
          //              </VBTableCellBody>
          //          }
   
   
   
          //        </TableRow>
          //      ))}
          //     }
          //     </>
          //   })}
          //   <VBTableRowNull
          //     length={
          //       100
          //       // state.data_count ? state.data_count : state.dataList.length
          //     }
          //   />
          // </TableBody>
          <TableBody>
          {state.dataList.map((data, i) => (
            <React.Fragment key={i}>
              <TableRow>
                <VBTableCellBody
                  sx={{
                    textAlign: "left",
                    padding: "0px",
                    paddingLeft: "5px",
                  }}
                >
                  <Checkbox
                    checked={state.selectedItems.includes(data.id) ? true : false}
                    onChange={() => selectItems("not", data.id)}
                    sx={{
                      padding: "2px",
                      margin: 0,
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {(page_no - 1) * 15 + i + 1}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data?.VoucherNumber}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data?.CustomerName}
                </VBTableCellBody>
                <VBTableCellBody className="truncated-text" sx={{ cursor: "pointer" }}>
                  {data?.CreatedDate}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data?.InvoiceName}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data?.GrossAmount}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data?.TaxAmount}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data?.GrandTotal}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  <Button onClick={() => download_xml({ transaction_id: data.transaction_id })}>
                    Download
                  </Button>
                </VBTableCellBody>
                <VBTableCellBody
                  sx={{
                    cursor: "pointer",
                    color:
                      data.Status === 0
                        ? "blue"
                        : data.Status === 1 || data.Status === 4
                        ? "green"
                        : data.Status === 2 || data.Status === 6
                        ? "red"
                        : data.Status === 3 || data.Status === 5
                        ? "orange"
                        : "black",
                  }}
                >
                  {data.Status === 0
                    ? "Not Submitted"
                    : data.Status === 1
                    ? "Success"
                    : data.Status === 2
                    ? "Failed"
                    : data.Status === 3
                    ? "Success With Warnings"
                    : data.Status === 4
                    ? "Resubmit and success"
                    : data.Status === 5
                    ? "Resubmit and success with warnings"
                    : data.Status === 6
                    ? "Resubmit and failed"
                    : "Unknown Status"}
                </VBTableCellBody>

                {(data.Status === 0 || data.Status === 1 || data.Status === 2) ? (
                  <VBTableCellBody sx={{ cursor: "pointer" }}>
                    <Button onClick={() => ClickViewInvoice(data.id, data?.InvoiceName, data?.VoucherNumber, data.transaction_id)}>
                      View
                    </Button>
                  </VBTableCellBody>
                ) : (
                  <VBTableCellBody sx={{ cursor: "pointer",display:"grid",placeitems:"center" }}>
                    <Button sx={{
                      transform: openIndex === i ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease-in-out",
                      borderRadius: "50%",        
                      width: "38px",
                      height: "38px",
                      minWidth: "38px",
                      }} onClick={() => handleOpenIndex(i)}>{"^"}</Button>
                  </VBTableCellBody>
                )}
              </TableRow>

              {openIndex === i &&
                data.ReportList.map((report, j) => (
                  <TableRow key={1} sx={{transition: "transform 0.3s ease-in-out",}}>
                    <VBTableCellBody
                      sx={{
                        textAlign: "right",
                        padding: "0px",
                        paddingLeft: "5px",
                      }}
                    >{"->"}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {j + 1}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {report?.VoucherNumber}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {report?.CustomerName}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {report?.CreatedDate}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {report?.InvoiceName}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {report?.GrossAmount}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {report?.TaxAmount}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {report?.GrandTotal}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      <Button onClick={() => download_xml({ transaction_id: report.transaction_id })}>
                        Download
                      </Button>
                    </VBTableCellBody>
                    <VBTableCellBody
                      sx={{
                        cursor: "pointer",
                        color:
                          report.Status === 0
                            ? "blue"
                            : report.Status === 1 || report.Status === 4
                            ? "green"
                            : report.Status === 2 || report.Status === 6
                            ? "red"
                            : report.Status === 3 || report.Status === 5
                            ? "orange"
                            : "black",
                      }}
                    >
                      {report.Status === 0
                        ? "Not Submitted"
                        : report.Status === 1
                        ? "Success"
                        : report.Status === 2
                        ? "Failed"
                        : report.Status === 3
                        ? "Success With Warnings"
                        : report.Status === 4
                        ? "Resubmit and success"
                        : report.Status === 5
                        ? "Resubmit and success with warnings"
                        : report.Status === 6
                        ? "Resubmit and failed"
                        : "Unknown Status"}
                    </VBTableCellBody>

                      <VBTableCellBody sx={{ cursor: "pointer" }}>
                        <Button sx={{fontSize:"11px"}} onClick={() => ClickViewInvoice(report.id, report?.InvoiceName, report?.VoucherNumber, report.transaction_id)}>
                          View
                        </Button>
                      </VBTableCellBody>
                    
                  </TableRow>
                ))}
            </React.Fragment>
          ))}
          <VBTableRowNull
            length={100 /* or state.data_count ? state.data_count : state.dataList.length */}
          />
        </TableBody>

        )}
      </VBTableContainer>
      
      
      <ViewInvoice open = {openView} setOpen={setOpenView} 
      SelectedInvoice={SelectedInvoice} submitInvoice={submitInvoice} 
      apiResult = {apiResult} validate_xml={validate_xml} 
      invoice_status={SelectedInvoice?.singleView?.invoice_status}
      loadingView={loadingView}
      resubmit={resubmit}
      />
      
      
    </Paper>
  );
};

export default EinvoiceInvoiceList;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));

import {
  Box,
  Collapse,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import SearchReport from "../../../../Components/Utils/SearchReport";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import PrintBtn from "../../../../Components/Utils/PrintBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import FilterLable from "../../../../Components/Utils/FilterLable";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import {
  CallProductSummaryReport,
  CallStockLedger,
  callBatchReport,
} from "../../../../Api/Reports/InventoryApi";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import ViknRadioGroup from "../../../../Components/Utils/ViknRadioGroup";
import VBZoomAnime from "../../../../Components/Utils/VBZoomAnime";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import {
  CallProductGroups,
  CallProductSearch,
  callBatchList,
} from "../../../../Api/Reports/CommonReportApi";
import { useDispatch, useSelector } from "react-redux";
import ExportToExcelButton from "../../../../Components/Utils/ExportToExcelButton";
import { formatNumber } from "../../../../generalFunction";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { useTranslation } from "react-i18next";
import { UserRolePermission } from "../../../../Function/Editions";

const BatchReport = () => {
  let Export_view = UserRolePermission("Batch Report", "print_permission");
  const [t] = useTranslation("common");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const dispatch = useDispatch()
  const [reportData, setReportData] = useState({date: [], count: 0});
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [clearFilter, setClearFilter] = useState(false)
  const [filterOptions, setFilterOptions] = useState({
    productGroup: null,
    productWise: null,
    batch: null,
    stockLevel: 1,
    isProductGroup: false,
    isProductWise: false,
    isBatch: true,
    isStockLevel: false,
    isAsofDate: false,
    fromDate: moment(),
    toDate: moment(),
  });

  const [filterData, setFilterData] = useState({
    productGroupList: [],
    productWiseList: [],
    batchList: [],
    stockLevelList: [
      { label: "All Stock", value: 1 },
      { label: "Positive Stock", value: 2 },
      { label: "Negative Stock", value: 3 },
      { label: "Zero Stock", value: 4 },
    ],
  });

  const [search, setSearch] = useState({
    batch: "",
    product: "",
    customer: "",
    supplier: "",
  });

  const [isLoading, setIsLoading] = useState({
    isLoadingBatch: false,
    isLoadingProduct: false,
    isLoadingReport: false,
  });

  //Handle functions
  const closeFilter = () => setOpenFilter(false);

  const handleDefaultFilter = function () {
    setFilterOptions({
      productGroup: null,
      productWise: null,
      batch: null,
      stockLevel: 1,
      isProductGroup: false,
      isProductWise: false,
      isBatch: true,
      isStockLevel: false,
      isAsofDate: false,
      fromDate: moment(),
      toDate: moment(),
    });
    setClearFilter(!clearFilter)
    setPage(1)
  }

  const searchReport = function () {
    fetchReport();
  };

  const radiosValue = [
    "Batch",
    "Product Group",
    "Product Wise",
    // "Stock Level",
    // "As of Date"
  ].map((option) => {
    const isChecked = filterOptions[`is${option.replace(" ", "")}`];

    return {
      checked: isChecked !== undefined ? isChecked : false,
      onChange: () =>
        setFilterOptions({
          ...filterOptions,
          // isStockLevel: false,
          // isAsofDate: false,
          isProductWise: false,
          isProductGroup: false,
          isBatch: false,
          [`is${option.replace(" ", "")}`]: true,
        }),
      label: option,
    };
  });

  // API Fetch data
  const fetchReport = async function () {
    setIsLoading({
      ...isLoading,
      isLoadingReport: true
    })
    const payload = {
      FromDate: filterOptions.isAsofDate ? "" : filterOptions.fromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.toDate.format("YYYY-MM-DD"),
      ProductGroupID: null,
      ProductID: null,
      BatchCode: null,
      page_no: page,
      items_per_page: appSetting.itemPerPage,
      StockFilter: Number(filterOptions.stockLevel),
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID: user_id
    };
    console.log(filterOptions.batch);
    if (filterOptions.productGroup)
      payload.ProductGroupID = filterOptions.productGroup.ProductGroupID;
    if (filterOptions.productWise)
      payload.ProductID = filterOptions.productWise.ProductID;
    if (filterOptions.batch) payload.BatchCode = filterOptions.batch.BatchCode;
    const data = await callBatchReport(payload);
    // console.log(data);
    if (data?.StatusCode === 6000) {
      setReportData({
        date: data.data,
        count: data.total_count
      });
    } else {
      dispatch(
        openSnackbar({
          open : true,
          message : data?.message,
          severity : 'warning',
        })
      )
      setReportData({
        date: [],
        count: 0
      });
    }
    setIsLoading({
      ...isLoading,
      isLoadingReport: false
    })
  };

  const filterApi = async function () {
    const res = await CallProductGroups({CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID: user_id});
    if (res.StatusCode === 6000) {
      setFilterData(prev => ({
        ...prev,
        productGroupList: res.data,
      }));
    }
  };

  const searchBatch = async function () {
    setIsLoading({
      ...isLoading,
      isLoadingBatch: true,
    });
    let res = await callBatchList({
      BatchCode: search.batch,
      length: search.batch.length,
      CompanyID: CompanyID,
        BranchID: BranchID,
        PriceRounding:Number(PriceDecimalPoint),
        CreatedUserID: user_id
    });
    // console.log(res);
    if (res.StatusCode === 6000) { 
      setFilterData(prev =>({
        ...prev,
        batchList: res.data,
      }));
    }
    setIsLoading({
      ...isLoading,
      isLoadingBatch: false,
    });
  };

  const searchProduct = async function () {
    setIsLoading({
      ...isLoading,
      isLoadingProduct: true,
    });
    let res = await CallProductSearch({
      product_name: search.product,
      length: search.product.length,
      type: "report",
      is_product_image: false,
      CompanyID: CompanyID,
        BranchID: BranchID,
        PriceRounding:Number(PriceDecimalPoint),
        CreatedUserID: user_id
    });
    if (res.StatusCode === 6000) { 
      setFilterData(prev => ({
        ...prev,
        productWiseList: res.data,
      }));
    }
    setIsLoading({
      ...isLoading,
      isLoadingProduct: false,
    });
  };

  useEffect(() => {
    fetchReport()
  }, [page, clearFilter])
  
  useEffect(() => {
    filterApi();
  }, []);

  useEffect(() => {
    searchBatch();
  }, [search.batch]);

  useEffect(() => {
    searchProduct();
  }, [search.product]);
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center"}}>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500", mr: 2 }}
              variant="h2"
            >
              {t("Batch Report")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </Box>

          <FlexBox>
            {Export_view ? 
          <ExportToExcelButton
            ToDate={filterOptions.toDate.format("YYYY-MM-DD")}
            FromDate={filterOptions.fromDate.format("YYYY-MM-DD")}
            UserID={user_id}
            report_type={"batch_report"}
            download_type={"PDF"}
            ProductGroupID={filterOptions.productGroup ? filterOptions.productGroup.ProductGroupID: null}
            ProductID={filterOptions.productWise ? filterOptions.productWise.ProductID : null}
            BatchCode={filterOptions.batch ? filterOptions.batch.BatchCode: null}
            StockFilter={Number(filterOptions.stockLevel)}
          />
          : null}
            {/* <ExportBtn /> */}
            {/* <PrintBtn /> */}
            <Pagenation 
            totalItem={reportData.count}
              page_no={page}
              setPage_no = {setPage}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          // This also change into custom component
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // height: "54px",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
           <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
            {t("Applied Filters: ")}
          </div>
          <FilterLable name={t("Date")} value={filterOptions.fromDate.format("DD-MM-YYYY") + (!filterOptions.isAsofDate ? " to " + filterOptions.toDate.format("DD-MM-YYYY"): " ")} />
          { filterOptions.batch && <FilterLable name={"Batch"} value={filterOptions.batch.BatchCode} handleDelete={() => setFilterOptions({...filterOptions, batch: null})} />}
          { filterOptions.productWise && <FilterLable name={t("Product Wise")} value={filterOptions.productWise.ProductName} handleDelete={() => setFilterOptions({...filterOptions, productWise: null})}/>}
          { filterOptions.productGroup && <FilterLable name={t("Product Group")} value={filterOptions.productGroup.GroupName} handleDelete={() => setFilterOptions({...filterOptions, productGroup: null})}/>}
          { filterOptions.isStockLevel && <FilterLable name={t("Stock Level")} value={filterData.stockLevelList[Number(filterOptions.stockLevel) - 1].label} handleDelete={() => setFilterOptions({...filterOptions, isStockLevel: false})}/>}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("Serial No")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Batch Code")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Date")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Product")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Product Group")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Mft Date")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Expiry Date")}</VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Purchase Price")} </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Sales Price")} </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Stock")} </VBTableCellHeader>
            {/* <VBTableCellHeader>Free Qty </VBTableCellHeader>
              <VBTableCellHeader>Qty In </VBTableCellHeader>
              <VBTableCellHeader>Qty Out </VBTableCellHeader>
              <VBTableCellHeader>Balance </VBTableCellHeader> */}
          </VBTableHeader>
          {isLoading.isLoadingReport ? <VBSkeletonLoader /> : <TableBody>
            {reportData.date.map((data, i) => (
              <TableRow>
                <VBTableCellBody> {(page - 1) * appSetting.itemPerPage + i + 1}</VBTableCellBody>
                <VBTableCellBody> {data.BatchCode}</VBTableCellBody>
                <VBTableCellBody> {data.Date}</VBTableCellBody>
                <VBTableCellBody> {data.ProductName}</VBTableCellBody>
                <VBTableCellBody> {data.ProductGroupName}</VBTableCellBody>
                <VBTableCellBody> {data.ManufactureDate}</VBTableCellBody>
                <VBTableCellBody> {data.ExpiryDate}</VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right"}}> {formatNumber(data.PurchasePrice)}</VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right"}}> {formatNumber(data.SalesPrice)}</VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right"}}> {`${data.Stock} (${data.base_unit})`}</VBTableCellBody>
                {/* <VBTableCellBody>{data.FreeQty}</VBTableCellBody>
                  <VBTableCellBody>{data.QtyIn}</VBTableCellBody>
                  <VBTableCellBody>{data.QtyOut}</VBTableCellBody>
                  <VBTableCellBody>{data.QtyIn}</VBTableCellBody> */}
              </TableRow>
            ))}
            <TableRow></TableRow>
          </TableBody>}
          {/* <VBTableFooter >
            <TableRow>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter >{reportData[1]?.LedgerName}</VBTableCellFooter>
              <VBTableCellFooter align="right">{reportData[1]?.Amount}</VBTableCellFooter>
            </TableRow>
          </VBTableFooter> */}
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={() => searchReport()}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              {/* Product Search AutoComplete */}
              <VBDatePiker
                fromDate={filterOptions.fromDate}
                toDate={filterOptions.isAsofDate ? null : filterOptions.toDate}
                fromOnChange={(value) =>
                  setFilterOptions({ ...filterOptions, fromDate: value })
                }
                toOnChange={(value) =>
                  setFilterOptions({ ...filterOptions, toDate: value })
                }
              />
            </ListItem>
            <ListItem>
              <ViknCheckboxGroup radios={radiosValue} />
            </ListItem>
            <ListItem>
              {/* <div> */}
              <VBZoomAnime in={filterOptions.isBatch}>
                {/* <ViknAutoComplete
                  placeholder={"Batch"}
                  value={filterOptions.product}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({ ...prev, product: newValue }))
                  }
                  options={filterData.productList}
                  getOptionLabel={(option) => option.label}
                /> */}
                <ViknSearchAutoComplete
                  freeSolo
                  placeholder={t("Batch")}
                  loading={isLoading.isLoadingBatch}
                  options={filterData.batchList}
                  getOptionLabel={(option) => option.BatchCode || ""}
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      setSearch({ ...search, 
                        batch: value, 
                        productGroup: null,
                        productWise: null,
                      });
                    }
                  }}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({ ...prev, batch: newValue, 
                      productGroup: null,
                      productWise: null, }))
                  }
                  value={filterOptions.batch}
                />
              </VBZoomAnime>

              <VBZoomAnime in={filterOptions.isProductWise}>
                {/* <ViknAutoComplete
                  value={filterOptions.productWise}
                  onChange={(e, newValue) => {
                    console.log(e.target);
                    setFilterOptions((prev) => ({
                      ...prev,
                      productCategory: newValue,
                    }));
                  }}
                  options={filterData.productWiseList}
                  getOptionLabel={(option) => option?.name}
                  // isOptionEqualToValue={(option, value) => option?.name === value?.name}
                /> */}
                <ViknSearchAutoComplete
                  freeSolo
                  placeholder={t("Product Wise")}
                  loading={isLoading.isLoadingProduct}
                  options={filterData.productWiseList}
                  getOptionLabel={(option) => option.ProductName || ""}
                  filterOptions={(options, { inputValue }) =>
                    options.filter((option) =>
                      (option.ProductName && option.ProductName.toLowerCase().includes(inputValue.toLowerCase())) ||
                      (option.ProductCode && option.ProductCode.toLowerCase().includes(inputValue.toLowerCase()))
                    )
                  }
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.ProductCode}</Box>
                      <Box sx={{fontSize: "14px",}}>{option.ProductName}</Box>
                    </Box>
                  )}
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      setSearch({ ...search, 
                        product: value
                      });
                    }
                  }}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({ ...prev, 
                      productWise: newValue, 
                      productGroup: null,
                      batch: null,  
                    }))
                  }
                  value={filterOptions.productWise}
                />
              </VBZoomAnime>

              <VBZoomAnime in={filterOptions.isProductGroup}>
                <ViknAutoComplete
                  placeholder={t("Product Group")}
                  value={filterOptions.productGroup}
                  onChange={(e, newValue) => {
                    // console.log(e);
                    setFilterOptions((prev) => ({
                      ...prev,
                      productGroup: newValue,
                      productWise: null,
                      batch: null,
                    }));
                  }}
                  options={filterData.productGroupList}
                  getOptionLabel={(option) => option.GroupName}
                  // isOptionEqualToValue={(option, value) => option?.GroupName === value?.GroupName}
                />
              </VBZoomAnime>
              {/* </div> */}
            </ListItem>
            <ListItem>
              <ViknCheckboxGroup
                radios={[
                  {
                    checked: filterOptions.isStockLevel,
                    onChange: () =>
                      setFilterOptions((prev) => ({
                        ...prev,
                        isStockLevel: !prev.isStockLevel,
                      })),
                    label: t("Stock Level"),
                  },
                ]}
              />
            </ListItem>
            <Collapse
              in={filterOptions.isStockLevel}
              timeout="auto"
              unmountOnExit
            >
              {" "}
              <ListItem>
                <ViknRadioGroup
                  handleChange={(e) =>
                    setFilterOptions({
                      ...filterOptions,
                      stockLevel: e.target.value,
                    })
                  }
                  radios={filterData.stockLevelList.map((i) => ({
                    label: i.label,
                    value: i.value,
                  }))}
                  value={filterOptions.stockLevel}
                />
              </ListItem>
            </Collapse>
            <ListItem>
              <ViknCheckboxGroup
                radios={[
                  {
                    checked: filterOptions.isAsofDate,
                    onChange: () =>
                      setFilterOptions((prev) => ({
                        ...prev,
                        isAsofDate: !prev.isAsofDate,
                      })),
                    label: t("As of Date"),
                  },
                ]}
              />
            </ListItem>
            {/* <Divider /> */}
          </List>
        }
      />
    </>
  );
};

export default BatchReport;

const FlexBox = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  // width: "26%",
}));

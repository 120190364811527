import axios from "axios";
// import { CompanyDetails } from "../../generalVeriable";

// Tax Summary Report API
const CallTaxSummaryVAT = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/sales/tax-summary/vat-report/",
      {
        //CompanyID: CompanyDetails.id,
       // BranchID: CompanyDetails.BranchID, //change this into LocalStorage
        //PriceRounding: 2,
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallTaxSummary");
  }
};

const CallTaxSummaryGST = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/sales/tax-summary/gst-report/",
      {
        //CompanyID: CompanyDetails.id,
       // BranchID: CompanyDetails.BranchID, //change this into LocalStorage
        //PriceRounding: 2,
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallTaxSummary");
  }
};

// Tax Summary Report API V11
const CallTaxSummaryV11 = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/sales/tax-summary/vat-report/",
      {
        //CompanyID: CompanyDetails.id,
       // BranchID: CompanyDetails.BranchID, //change this into LocalStorage
        //PriceRounding: 2,
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallTaxSummary");
  }
};

const CallVatReturn = async function (body) {
  try {
    const { data } = await axios.post("api/v10/sales/vat-return/report/", 
      {
        //CompanyID: CompanyDetails.id,
       // BranchID: CompanyDetails.BranchID, //change this into LocalStorage
        //PriceRounding: 2,
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallVatReturn");
  }
};

const callTaxSalesReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/sales/sales/gst-sale-report/",
      {
        //CompanyID: CompanyDetails.id,
       // BranchID: CompanyDetails.BranchID, //change this into LocalStorage
        //PriceRounding: 2,
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallTaxSummary");
  }
};

const callTaxPurchaseReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/purchases/gst-purchase-report/",
      {
        //CompanyID: CompanyDetails.id,
       // BranchID: CompanyDetails.BranchID, //change this into LocalStorage
        //PriceRounding: 2,
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallTaxSummary");
  }
};

export { CallTaxSummaryVAT, CallTaxSummaryGST, CallVatReturn, callTaxSalesReport, callTaxPurchaseReport, CallTaxSummaryV11 };

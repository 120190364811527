import { useEffect } from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from "react";
import VBSwitch from "../../../Components/Utils/VBSwitch";
import VBInputField from "../../../Components/Utils/VBInputField";
import VBSelect from "../../../Components/Utils/VBSelect";
import VBDatePiker from "../../../Components/Utils/VBDatePiker";
import moment from "moment";
import Transactions from "./Components/Transactions";
import Address from "./Components/Address";
import BankDetails from "./Components/BankDetails";
import { callCreateCustomer ,create_customer_edit,create_customer_edit_api,Get_code} from "../../../Api/Contacts/contacts";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../../features/SnackBarslice";
import { jsObjtoFormData } from "../../../generalFunction";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {<Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreateCustomer = () => {
  const [t, i18n] = useTranslation("common");
  const { CompanyID, BranchID, } = useSelector((state) => state.companyDetails);
  const { user_id, } = useSelector((state) => state.user);
  const dispatch = useDispatch()

  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  const is_edit = true;
  const uniq_id =  params.get("unq_id");

  // const is_edit = location.state?.is_edit ?? params.get('is_edit');
  // const uniq_id = location.state?.uniq_id ?? params.get('unq_id');
  const [dataLists, setDataLists] = useState({
    routList: [],
    categoryList: [],
    treatment: null,
  });

  const [open, setOpen] = useState(false);
  const [editAddress, setEditAddress] = useState({
    position: "",
    address: "",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () =>{
    setOpen(false);
    setEditAddress({
      position: "",
      address: "",
    })
  }
  let gstTreatmentList = [
    { value: "0", name: "Registered Business - Regular" },
    { value: "1", name: "Registered Business - Composition" },
    { value: "2", name: "Consumer" },
    { value: "4", name: "Overseas" },
    { value: "5", name: "Special Economic Zone" },
    { value: "6", name: "Deemed Export" },
  ];
  
  let vatTreatmentList = [
    { value: "0", name: "B2B Registered" },
    { value: "1", name: "B2B Unregistered" },
  ];
  const [uploadDetail, setUploadDetail] = useState({
    CustomerName: "",
    CustomerCode: "",
    DisplayName: "",
    OpeningBalance: "",
    PartyImage: null,
    PartyType: 1,
    VATNumber:"",
    GSTNumber:"",
    CrOrDr: "DR",
    WorkPhone: "",
    PartyCode: "",
    WebURL: "",
    Email: "",
    as_on_date: moment(),
    creditPeriod: "",
    PanNumber: "",
    creditLimit: "",
    CRNo: "",
    priceCategory: null,
    routes: null,
    vatNo: "",
    GST_Treatment: "",
    VAT_Treatment: "",
    treatment: null,
    AddresList: [],
    bankList: [],
  });
  const [value, setValue] = React.useState(0);
  const [showImage, setShowImage] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddImage = function () {
    let btn = document.getElementById("imgInput");
    btn.click();
  };

  const handleImage = function (e) {
    let ing = e.target?.files[0];
    if (ing) {
     setShowImage(URL.createObjectURL(ing));
      setUploadDetail({
        ...uploadDetail,
        PartyImage: ing,
      }); 
    }
  };

  const handleSubmitCustomer = function () {
    // document.getElementById("formOneId").click();
    // document.getElementById("formTwoId").click();
    // Reference to both forms
    const formOne = document.getElementById("FormOneCos");
    const formTwo = document.getElementById("FormTwoCos");

    // Function to handle the first form submission
    const handleFormOneSubmit = (e) => {
      e.preventDefault();
      formTwo.addEventListener("submit", handleFormTwoSubmit, { once: true }); // Ensure it only runs once
      formTwo.requestSubmit(); // Programmatically submit formTwo
    };

    // Function to handle the second form submission
    const handleFormTwoSubmit = (e) => {
      e.preventDefault();
      createCustomer(); // Call createCustomer() after formTwo submission
    };

    formOne.addEventListener("submit", handleFormOneSubmit, { once: true }); // Ensure it only runs once
    formOne.requestSubmit();

  };
  const createCustomer = async function () {
    console.log("uploadDetail------------------------------>");
    console.log(uploadDetail);
    console.log(uploadDetail.PartyType);
    const payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,

      CustomerName: uploadDetail.CustomerName,
      DisplayName: uploadDetail.DisplayName,
      OpeningBalance: uploadDetail.OpeningBalance || 0,
      PartyType: uploadDetail.PartyType,
      CrOrDr: uploadDetail.CrOrDr,
      WorkPhone: uploadDetail.WorkPhone,
      PartyCode: uploadDetail.PartyCode,
      CreditLimit: uploadDetail.creditLimit,
      CreditPeriod: uploadDetail.creditPeriod,
      priceCategory: uploadDetail.priceCategory.id,
      Routes: uploadDetail.routes.id,
      WebURL: uploadDetail.WebURL,
      Email: uploadDetail.Email,
      CRNo: uploadDetail.CRNo,
      PanNumber: uploadDetail.PanNumber,
      as_on_date: uploadDetail.as_on_date.format("YYYY-MM-DD"),
      GST_Treatment: uploadDetail.GST_Treatment,
      VAT_Treatment: uploadDetail.VAT_Treatment,
      Address: uploadDetail.AddresList,
      Bank: uploadDetail.bankList,
      vatNo:uploadDetail.vatNo,
      uniq_id:uniq_id,
      VATNumber:uploadDetail.VATNumber
    };

    console.log(uploadDetail);
    

    const formData = jsObjtoFormData(payload);
    let res = null
    if (is_edit && uniq_id){
      res = await create_customer_edit_api(formData, dispatch); 
    }else{
      res = await callCreateCustomer(formData, dispatch);
    }
    if (res.data.StatusCode === 6000) {
      console.log(res);
      dispatch(
        openSnackbar({
          open: true,
          message: res.data?.message || "Customer Created Successfully",
          severity: "success",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: res.data?.message || "Error Occurred In API",
          severity: "error",
        })
      );
    }
  };

  const fetchData = async ()=>{
    let PartyCode = ""
    let CustomerName= ""
    let DisplayName= ""
    let OpeningBalance= ""
    let PartyImage= null
    let PartyType= 1
    let CrOrDr= ""
    let WorkPhone= ""
    let WebURL= ""
    let Email= ""
    let creditPeriod= ""
    let PanNumber= ""
    let creditLimit= ""
    let CRNo= ""
    let VATNumber = ""
    let priceCategory= null
    let routes= null
    let treatment= null
    let AddresList= []
    let bankList= []


    let response = null;

    if (is_edit && uniq_id){

      const Data = await Get_code({
        CompanyID: CompanyID,
        BranchID: BranchID,
        PartyType: 1,
        uniq_id:uniq_id ,
        PriceRounding:2
      })

      response  = Data.data.data;
      if (response.StatusCode === 6000){
        let VAT_Treatment_id = response.data.VAT_Treatment
        treatment = vatTreatmentList.find((i)=> i.value === VAT_Treatment_id);
        setDataLists((prev) => ({
          ...prev, 
          treatment
          }
        ));
        PartyCode = response.data.PartyCode;
        CustomerName = response.data.PartyName;
        OpeningBalance = response.data.OpeningBalance;
        PartyType = response.data.PartyType;
        CrOrDr = response.data.CrOrDr;
        WorkPhone = response.data.WorkPhone;
        WebURL = response.data.WebURL;
        Email = response.data.Email;
        PanNumber = response.data.PanNumber;
        creditPeriod = response.data.CreditPeriod;
        creditLimit = response.data.CreditLimit;
        CRNo = response.data.CRNo;
        DisplayName = response.data.DisplayName;
        VATNumber = response.data.VATNumber;
        bankList = response.data.BankDetails;

        treatment = treatment;

        AddresList = response.data.AddressDetails;
        bankList = response.data.BankDetails;



      }
    }

    else{
      const Data = await Get_code({
        CompanyID: CompanyID,
        BranchID: BranchID,
        PartyType: 1
      })
      response = Data.data
      if (response.StatusCode === 6000){
        PartyCode = response.PartyCode
      }
    }
    console.log(treatment,"treatmenttreatmenttreatmenttreatment===>");
    setUploadDetail((prev) => ({
      ...prev, 
      PartyCode,
      CustomerName,
      OpeningBalance,
      CrOrDr,
      WorkPhone,
      WebURL,
      Email,
      PanNumber,
      creditPeriod,
      creditLimit,
      CRNo,
      DisplayName,
      bankList,
      VATNumber,
      treatment,
      AddresList,
  }));
  
  } 

  useEffect(() => {
    fetchData();
  }, []);

  console.log(uploadDetail.AddresList,"=================>>>>");
  return (
    <Paper sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: "1px solid",
          p: 3,
          borderColor: "#EBEBEB",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ fontSize: "24px", fontWeight: "600", lineHeight: "36px" }}
        >
           {is_edit ? t("Update Customer") : t("Create Customer")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Button
            sx={{ fontSize: "16px", textTransform: "none", px: 2 }}
            variant="text"
          >
            Cancel
          </Button>
          <BlueButton sx={{ px: 4 }} onClick={() => handleSubmitCustomer()}>
            {" "}
            Save{" "}
          </BlueButton>
        </Box>
      </Box>

      <form id="FormOneCos">

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 8,
            py: 2,
            mb: 4,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-end", gap: 6 }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#5C5C5C",
                  mb: 4,
                }}
              >
                Profile image
              </Typography>

              <Badge sx={{
                "&:hover": {
                  "& .MuiBadge-badge": {
                    display: "block",
                  }
                },

                "& .MuiBadge-badge": {
                  // visibility: "hidden",
                  display: "none",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                },
              }} badgeContent={showImage && <IconButton onClick={() => setShowImage(null)} ><DeleteIcon /></IconButton>}>
                <ImageButton
                  onClick={() => {
                    !showImage && handleAddImage();
                  }}
                  sx={{ cursor: showImage ? "default" : "pointer" }}
                >
                  {showImage ? (
                    <Avatar
                      alt="Remy Sharp"
                      src={showImage}
                      sx={{ height: "100%", width: "100%", }}
                    />
                  ) : (
                    <AddPhotoAlternateIcon />
                  )}
                </ImageButton>
              </Badge>

              <input
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                id="imgInput"
                onChange={(e) => handleImage(e)}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <VBInputField
                label="Customer Name"
                type="text"
                required
                placeholder="Type here"
                value={uploadDetail.CustomerName}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    CustomerName: e.target.value,
                  });
                }}
              />
              {/* <Box sx={{ mb: 2, minWidth: 350 }} /> */}
              <VBInputField
                label="Customer Code"
                placeholder="Type here"
                type="text"
                required
                value={uploadDetail.PartyCode}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    PartyCode: e.target.value,
                  });
                }}
              />
              <VBInputField
                label="Display Name"
                placeholder="Type here"
                value={uploadDetail.DisplayName || uploadDetail.CustomerName}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    DisplayName: e.target.value,
                  });
                }}
              />
            </Box>
          </Box>

          <VBSwitch
            label={"Active"}
            labelPlacement="start"
            labelColor={"black"}
            checked={uploadDetail.IsActive}
            onChange={() => {
              setUploadDetail({
                ...uploadDetail,
                IsActive: !uploadDetail.IsActive,
              });
            }}
          />
        </Box>

        <Box sx={{ flexGrow: 1, minWidth: 1000, px: 8, mb: 2 }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 4, md: 3 }}>
            <Grid item xs={2}>
              <VBInputField
                label="Opening Balance"
                placeholder="Type here"
                type="number"
                value={uploadDetail.OpeningBalance}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    OpeningBalance: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  pt: "18px",
                }}
              >
                <div style={{ width: "35%" }}>
                  <VBSelect
                    options={["DR", "CR"]}
                    value={uploadDetail.CrOrDr}
                    handleChange={(e) => {
                      setUploadDetail({
                        ...uploadDetail,
                        CrOrDr: e.target.value,
                      });
                    }}
                  />
                </div>
                <VBDatePiker
                  notLabel
                  fromDate={uploadDetail.as_on_date}
                  fromOnChange={(e) =>
                    setUploadDetail({
                      ...uploadDetail,
                      as_on_date: e,
                    })
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={2}>
              {/* <Item>3</Item> */}
              <VBInputField
                label="Work Phone"
                placeholder="Type here"
                type="number"
                value={uploadDetail.WorkPhone}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    WorkPhone: e.target.value,
                  });
                }}
              />
            </Grid>
            {/* <Grid item xs={2}>
            <Item>4</Item>
            <VBInputField
              label="Customer Code"
              placeholder="Type here"
              value={uploadDetail.PartyCode}
              onChange={(e) => {
                setUploadDetail({
                  ...uploadDetail,
                  PartyCode: e.target.value,
                });
              }}
            />
          </Grid> */}
          </Grid>
        </Box>

        <Box sx={{ px: 8, mb: 5 }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 4, md: 3 }}>
            <Grid item xs={3}>
              <VBInputField
                label="Email ID"
                type="email"
                placeholder="Type here"
                value={uploadDetail.Email}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    Email: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <VBInputField
                label="Website URL"
                type="url"
                value={uploadDetail.WebURL}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    WebURL: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </form>

      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Transactions"
            sx={{ width: "288px", textTransform: "none" }}
            {...a11yProps(0)}
          />
          <Tab
            label="Address"
            sx={{ width: "288px", textTransform: "none" }}
            {...a11yProps(1)}
          />
          <Tab
            label="Bank Details"
            sx={{ width: "288px", textTransform: "none" }}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <Box sx={{ px: 8 }}>
        <CustomTabPanel value={value} index={0}>
          <Transactions
            uploadDetail={uploadDetail}
            setUploadDetail={setUploadDetail}
            gstTreatmentList={gstTreatmentList}
            vatTreatmentList={vatTreatmentList}
            dataLists={dataLists}
            setDataLists={setDataLists}
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Address
            uploadDetail={uploadDetail}
            setUploadDetail={setUploadDetail}
            handleOpen = {handleOpen}
            open = {open}
            handleClose = {handleClose}
            setEditAddress= {setEditAddress}
            setOpen = {setOpen}
            editAddress ={editAddress}
            
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <BankDetails
            uploadDetail={uploadDetail}
            setUploadDetail={setUploadDetail}
          />
        </CustomTabPanel>
      </Box>
    </Paper>
  );
};

export default CreateCustomer;

const ImageButton = styled(IconButton)(() => ({
  backgroundColor: "#E8E8E8",
  fontSize: "35px",
  padding: 0,
  height: "112px",
  width: "112px",
}));

const BlueButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#0A9EF3",
  textTransform: "none",
  fontSize: "15px",
  ":hover": {
    backgroundColor: "#0A9EF3",
  },
}));

// {
//   PartyType: 1,
//   Emails: [{"email":"","Status":0,"id":null}],
//   UpdationDelay: 0,
//   IsVbAssist: false,
//   DeletedEmails: [{"id":""}],
//   PartyName: customer name:,
//   DisplayName: customer name ,
//   FirstName: first name:,
//   LastName: last name:,
//   OpeningBalance: 0,
//   CrOrDr: 0,
//   Attention: "",
//   Address1: ,
//   Building No:,
//   Building No:,
//   Building No:,
//   Building,
//   Address2: ,
//   Street Name:,
//   Street,
//   City: City,
//   State: f991d4e6-8cd6-4482-b2f1-8474cc62b0a3,
//   Country: 94e4ce66-26cc-4851-af1e-ecc068e80224,
//   CountryName: Saudi Arabia,
//   PostalCode: 369258,
//   OfficePhone: ,
//   WorkPhone: 963852741,
//   Mobile: 9876235617,
//   WebURL: robin.com,
//   Email: email@gmail.com,
//   IsActive: true,
//   PartyImage: ,
//   img: ,
//   IsBillwiseApplicable: false,
//   CreditPeriod: 15,
//   CreditLimit: 0,
//   PriceCategoryID: 1,
//   CurrencyID: 0,
//   InterestOrNot: true,
//   RouteID: 1,
//   VATNumber: 300000005544563,
//   GSTNumber: ,
//   Tax1Number: ,
//   Tax2Number: ,
//   Tax3Number: ,
//   PanNumber: Iqama No:,
//   CRNo: ,
//   BankName1: ,
//   AccountName1: ,
//   AccountNo1: ,
//   IBANOrIFSCCode1: ,
//   BankName2: ,
//   AccountName2: ,
//   AccountNo2: ,
//   IBANOrIFSCCode2: ,
//   DataBase: ,
//   CreatedUserID: 349,
//   BranchID: 1,
//   CompanyID: 924abda3-7706-47b0-a908-2e0013e75fbc,
//   Attention_Shipping: attention,
//   Address1_Shipping: Building,
//   Address2_Shipping: Street,
//   City_Shipping: city,
//   State_Shipping: 264f7d9d-021d-473f-86a1-3eefc650359f,
//   Country_Shipping: 83300f24-91ca-49ce-aa9c-b12dcbb9fe82,
//   PostalCode_Shipping: 432134,
//   Phone_Shipping: 963852741,
//   GST_Treatment: ,
//   VAT_Treatment: 0,
//   State_Code: ,
//   as_on_date: 2024-09-02,
//   PlaceOfSupply: ,
//   District: District,
//   AdditionalNo: Additional,
//   District_shipping: District,
//   AdditionalNo_shipping: Province,
//   PartyCode: 2511,
//   BankDetails: [],
// }

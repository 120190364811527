import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  ListItem,
  styled,
  Paper,
  List,
  TableBody,
  TableRow,
} from "@mui/material";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import moment from "moment";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import PrintBtn from "../../../../Components/Utils/PrintBtn";
import FilterLable from "../../../../Components/Utils/FilterLable";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import VBSelect from "../../../../Components/Utils/VBSelect";
import { date_range } from "../../../../Function/comonFunction";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  download_excel,
} from "../../../../Components/ReportTable/ReportTable";
import { useTranslation } from "react-i18next";
import { CallTaxSummaryVAT, CallTaxSummaryGST } from "../../../../Api/Reports/TaxsApi";
import { formatNumber, getCookie } from "../../../../generalFunction";
import { useDispatch, useSelector } from "react-redux";
import { downloadReport, exportTaxSummaryReportExcel } from "../../../../Function/exportFunctions";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import axios from "axios";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { UserRolePermission } from "../../../../Function/Editions";
let cookies = getCookie("VBID")

const TaxSummary = () => {
  let Export_view = UserRolePermission("Tax Summary", "print_permission");
  const dispatch = useDispatch()
  const companyName = useSelector((state) => state.companyDetails.CompanyName);
  const generalSettings = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const {Company_Type}  = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [t] = useTranslation("common");
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState("Generating Report...");
  const [reportdata, setReportdata] = useState({
    StatusCode: 0,
    due_Amount: {},
    inward_supplies: [],
    outward_supplies: [],
  })
  const [filterOptions, setFilterOptions] = useState({
    fromDate: moment(),
    toDate: moment(),
    dateRange: "Today",
  });

  console.log(generalSettings, Company_Type, "EEEEEEEEEEEEEEEEEEEEeeeeeeeeeeeeee");

  const [filterData, setFilterData] = useState({
    dateRangeList: [
      "Custom",
      "Today",
      "This Week",
      "This Month",
      // "Last 90 Days",
      "This Quarter",
      // "Last Year",
    ],
  });

  const [isLoading, setIsLoading] = useState(false)
  
  //Handle functions
  const handleAutoComplete = function (e) {
    setFilterOptions({ ...filterOptions, dateRange: e.target.value });
  };

  const closeFilter = () => setOpenFilter(false);

  const searchReport = function() {

    function checkDateInRange(targetDate) {
      // Get current date using Moment.js
      const currentDate = filterOptions.toDate;

      // Calculate three-month range
      const startDate = moment(currentDate).subtract(12, 'months');

      // Check if targetDate is within the range
      if (moment(targetDate).isBetween(startDate, currentDate, null, '[]')) {
          return true;
      } else {
          return false;
      }
    }

    // Example usage
    const dateToCheck = filterOptions.fromDate.format("YYYY-MM-DD"); // Date to check (format as needed)
    const result = checkDateInRange(dateToCheck);
    console.log(result); // Output: true or false

    if (result) {
      fetchReport();
    }else{
      dispatch(
        openSnackbar({
          open : true,
          message : "The date should be in the range of 1 year.",
          severity : 'warning',
        })
      )

    }
  }
  const handleDefaultFilter = function() {
    setFilterOptions({
      fromDate: moment(),
      toDate: moment(),
      dateRange: "Today",
    });
  }

  const exportFun = function(type) {
    // console.log(type);
  }

  //Fetch Api
  const fetchReport = async function () {
    setIsLoading(true)
    const payload = {
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      FromDate: filterOptions.fromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.toDate.format("YYYY-MM-DD"),
    }
    
    const res = Company_Type === "VAT" ? await CallTaxSummaryVAT(payload) : await CallTaxSummaryGST(payload);

    if (res?.StatusCode === 6000) {
      setReportdata({
        due_Amount: res.due_Amount,
        inward_supplies: res.inward_supplies,
        outward_supplies: res.outward_supplies,
      })
    } else { 
      dispatch(
        openSnackbar({
          open : true,
          message : res?.message || 'Data Not Found',
          severity : 'error',
        })
      )
      // setReportdata({
      //   StatusCode: 0,
      //   due_Amount: {},
      //   inward_supplies: [],
      //   outward_supplies: [],
      // })
    }
    setIsLoading(false)
  };

  useEffect(() => {
    fetchReport()
  }, [Company_Type])
  
  return (
    <>
      <Paper
        sx={{
          px: 2,
          py: 1,
          mb: 1, 
          borderRadius: "5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}} variant="h2">
          {t("Tax Summary")}
        </Typography>
        {/* <ExportBtn /> */}
        {Export_view ? 
        <ReportDownload
          download_type={"PDF"}
          invoice_type={Company_Type==="VAT"?"tax_summary_vat":Company_Type==="GST"? "tax_summary_gst":null}
          is_celery={true}
          setProgress={setProgress}
          FromDate={filterOptions.fromDate.format("YYYY-MM-DD")}
          ToDate={filterOptions.toDate.format("YYYY-MM-DD")}
          ExportToXLS={() => exportTaxSummaryReportExcel({FromDate: filterOptions.fromDate.format("YYYY-MM-DD"), ToDate: filterOptions.toDate.format("YYYY-MM-DD"), Type:Company_Type,
          CompanyID:CompanyID,
          BranchID:BranchID,
          PriceRounding:Number(PriceDecimalPoint),
          })}
          // ExportToXLS={ExportTaxSummaryReportExcel}
        />
        : null}
      </Paper>
      <Paper 
      sx={{ 
        // height: "100%", 
        pb: 4,
        width: "100%" }}
      >
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500", mr: 2 }}
              variant="h2"
            >
              {companyName}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          {/* <FlexBox>
          <ExportBtn />
          <PrintBtn />
          <Pagenation />
          </FlexBox> */}
        </Box>
        {/* Filter Area  */}
        <Box
          // This also change into custom component
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // // height: "54px",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
            {t("Applied Filters: ")}
          </div>
          <FilterLable
            name={t("Date")}
            value={(filterOptions.fromDate.format("DD/MM/YYYY") + " - ") + filterOptions.toDate.format("DD/MM/YYYY") }
          />
        </Box>
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader sx={{ fontWeight: "bold", borderRight: "1px solid #ffffff" }}>
              {t("Description")}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right", fontWeight: "bold", borderRight: "1px solid #ffffff" }}>
              {t("Taxable Amount")}
            </VBTableCellHeader>
            {Company_Type === "GST" ? (
              [
                <VBTableCellHeader
                  sx={{ textAlign: "right", fontWeight: "bold", borderRight: "1px solid #ffffff" }}
                >
                  {t("IGST")}
                </VBTableCellHeader>,
                <VBTableCellHeader
                  sx={{ textAlign: "right", fontWeight: "bold",borderRight: "1px solid #ffffff" }}
                >
                  {t("CGST")}
                </VBTableCellHeader>,
                <VBTableCellHeader
                  sx={{ textAlign: "right", fontWeight: "bold",borderRight: "1px solid #ffffff" }}
                >
                  {t("SGST")}
                </VBTableCellHeader>,
                <VBTableCellHeader
                  sx={{ textAlign: "right", fontWeight: "bold",borderRight: "1px solid #ffffff" }}
                >
                  {t("Total Tax")}
                </VBTableCellHeader>,
              ]
            ) : Company_Type === "VAT" ? (
              <VBTableCellHeader
                sx={{ textAlign: "right", fontWeight: "bold" }}
              >
                {t("VAT")}
              </VBTableCellHeader>
            ) : null}
          </VBTableHeader>
          {isLoading ? (
            <VBSkeletonLoader />
          ) : <TableBody>

            {/* 🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆 Outward Supplier 🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆 */}
            <TableRow>
              <VBTableCellBody
                colSpan="6"
                sx={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "black",
                }}
              >
                {t("Outward Supplies")}
              </VBTableCellBody>
            </TableRow>

            {reportdata.outward_supplies.map((e, i) => (
            <TableRow>
              {i === reportdata.outward_supplies.length - 1 ?
              <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{t("Total")}</VBTableCellBody> : 
              <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{t(e.Heading)}</VBTableCellBody> }
              <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6"}}>{formatNumber(e.TaxableAmount)}</VBTableCellBody>
              {/* <VBTableCellBody></VBTableCellBody> */}
              {Company_Type === "GST" ? (
                <>
                  <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6"}}>{formatNumber(e.IGST)}</VBTableCellBody>
                  <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6"}}>{formatNumber(e.CGST)}</VBTableCellBody>
                  <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6"}}>{formatNumber(e.SGST)}</VBTableCellBody>
                  <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6"}}>{formatNumber(e.TotalTax)}</VBTableCellBody>
                </>
              ) : (
                Company_Type === "VAT" && <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6"}}>{formatNumber(e.VAT)}</VBTableCellBody>
              )}
            </TableRow>
            ))}

            {/*🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆 Inward Supplier 🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆 */}
            <TableRow>
              <VBTableCellBody
                colSpan="6"
                sx={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "black",
                }}
              >
                {t("Inward Supplies")}
              </VBTableCellBody>
            </TableRow>

            {reportdata.inward_supplies.map((e, i) => (
            <TableRow>
              {i === reportdata.inward_supplies.length - 1 ?
              <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{t("Total")}</VBTableCellBody> : 
              <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>{t(e.Heading)}</VBTableCellBody> }
              <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6"}}>{formatNumber(e.TaxableAmount)}</VBTableCellBody>
              {/* <VBTableCellBody></VBTableCellBody> */}
              {Company_Type === "GST" ? (
                <>
                  <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6"}}>{formatNumber(e.IGST)}</VBTableCellBody>
                  <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6"}}>{formatNumber(e.CGST)}</VBTableCellBody>
                  <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6"}}>{formatNumber(e.SGST)}</VBTableCellBody>
                  <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6"}}>{formatNumber(e.TotalTax)}</VBTableCellBody>
                </>
              ) : (
                Company_Type === "VAT" && <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6"}}>{formatNumber(e.VAT)}</VBTableCellBody>
              )}
            </TableRow>
            ))}

            {/*🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆 Due Amount 🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆 */}
            <TableRow>
              <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6", backgroundColor: "#205d99", color: 'white'}}>{Company_Type === "GST" ? t("Net GST Due"): t("Net VAT Due")}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6", backgroundColor: "#205d99", color: 'white'}}> {formatNumber(reportdata.due_Amount.due_TaxableAmount)} </VBTableCellBody>
              {/* <VBTableCellBody></VBTableCellBody> */}
              {Company_Type === "GST" ? (
                <>
                  <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6", backgroundColor: "#205d99", color: 'white'}}>{formatNumber(reportdata.due_Amount.due_IGST)}</VBTableCellBody>
                  <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6", backgroundColor: "#205d99", color: 'white'}}>{formatNumber(reportdata.due_Amount.due_CGST)}</VBTableCellBody>
                  <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6", backgroundColor: "#205d99", color: 'white'}}>{formatNumber(reportdata.due_Amount.due_SGST)}</VBTableCellBody>
                  <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6", backgroundColor: "#205d99", color: 'white'}}>{formatNumber(reportdata.due_Amount.due_TotalTax)}</VBTableCellBody>
                </>
              ) : (
                Company_Type === "VAT" && <VBTableCellBody sx={{textAlign: 'right', borderRight: "1px solid #e6e6e6", backgroundColor: "#205d99", color: 'white'}}>{formatNumber(reportdata.due_Amount.due_VAT)}</VBTableCellBody>
              )}
            </TableRow>

            {/* <TableRow></TableRow> */}
          </TableBody>}
        </VBTableContainer>
      </Paper>
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={() => searchReport()}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.fromDate}
                toDate={filterOptions.toDate}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    fromDate: value,
                    dateRange: "Custom"
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    toDate: value,
                    dateRange: "Custom"
                  })
                }
                // fromReadOnly={true}
                // toReadOnly={true}
              />
            </ListItem>
            <ListItem>
              {/* Product Search AutoComplete */}
              <VBSelect
                value={filterOptions.dateRange}
                options={filterData.dateRangeList}
                handleChange={(e) => 
                  setFilterOptions({
                  ...filterOptions,
                  dateRange: e.target.value,
                  fromDate: date_range(e.target.value).fromdate,
                  toDate: date_range(e.target.value).todate,
                })}
              />
            </ListItem>
            {/* <Divider /> */}
          </List>
        }
      />
    </>
  );
};

export default TaxSummary;

const SpaceBetweenBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const FlexBox = styled(Box)(() => ({
  // justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  // width: "15%",
}));

import { IconButton, InputAdornment, OutlinedInput, TextField } from "@mui/material";
import React from "react";
import { Icons } from "../../Assets/AssetLog";

const SearchReport = ({placeholder, value, onChange, onClick }) => {
  return (
    <>
      <OutlinedInput
        sx={{
          p: "0px",
          width: "260px",
          "& .MuiInputBase-input": { py: "7px" }
        }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={onClick}>
          <img src={Icons.ReportSerarch} alt="" />
          </IconButton>
        </InputAdornment>}
        id="outlined-basic"
        variant="outlined"
        type="text"
      />
    </>
  );
};

export default SearchReport;

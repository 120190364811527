import axios from "axios";
import { store } from '../../app/store';

const state = store.getState();
const { PriceDecimalPoint } = state.generalSettingsSclice.generalSettings;

// Trial Balance API
const CallTrialBalance = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/ledgerPosting/trialBalance/",
      {
        ...body,
        
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallTrialBalance");
    return error
  }
};

// ProfitAndLossAccount API
const CallProfitAndLossAccount = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/ledgerPosting/profit-and-loss-web/",
      {
        ...body,
        
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallProfitAndLossAccount");
    return error
  }
};

// Balance Sheet API
const CallBalanceSheet = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/ledgerPosting/balancing-sheet/",
      {
        ...body,
        
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallBalanceSheet");
    return error
  }
};

// Balance Sheet API
const CallFinancialYear = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/financialYear/financial-years/",
      body
    );
    return data;
  } catch (error) {
    console.log("error in CallFinancialYear");
    return error
  }
};

// Outstanding Report API
const CallOutstandingReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/ledgerPosting/outstanding-report/",
      {
        ...body,
        
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallOutstandingReport");
    return error
  }
};

// Expense Summary Report API
const CallExpenseSummaryReport = async function (body) {
  try {
  const { data } = await axios.post("api/v10/sales/expense-summary-report/", {
    ...body,
  });
  return data;
  } catch (error) {
    console.log("error in CallExpenseSummaryReport");
    return error
  }
};

export {
  CallTrialBalance,
  CallProfitAndLossAccount,
  CallBalanceSheet,
  CallFinancialYear,
  CallOutstandingReport,
  CallExpenseSummaryReport,
};

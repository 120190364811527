import axios from "axios";

export const LedgerDetails = async function (body) {
    try {
        const { data } = await axios.post("api/v11/accountLedgers/ledgerListByID/", {
            ...body,
        })
        return data;
    } catch (error) {
        console.log("Error on getting ledger Api",error);
        return error
    }
}
// call initial Api
export const CallSalesReturnDetail = async function (body) {

    try {
      const { data } = await axios.post("api/v11/salesReturns/get-details/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
};

export const CallsalesToSalesreturn = async function (body) {
  try {
    const { data } = await axios.post("api/v11/salesReturns/salesInvoice-for-salesReturn/", {
      ...body,
    });
    return data
  } catch (error) {
    console.log("error on SalesTo SalesReturn API", error);
    return error
  }
}


export const CallToSalesreturn = async function (body) {
  try {
    const { data } = await axios.post("api/v11/salesReturns/sales-return/", {
      ...body,
    });
    return data
  } catch (error) {
    console.log("error on Sales return API", error);
    return error
  }
}

export const list_sales_return_api = async function (body) {
  
  try {
    const { data } = await axios.get("api/v11/salesReturns/sales-return/", {
      params:body,
    });
    return data;
  } catch (error) {
    console.log("error on Sales Return  API", error);
    return error
  }
};

export const delete_sales_return_api = async function (body) {
  
  try {
    const { data } = await axios.delete("api/v11/salesReturns/sales-return/", {
      data:body,
    });
    return data;
  } catch (error) {
    console.log("error on Sales Return  API", error);
    return error
  }
};

// Get Sales Return Master Api
export const view_salesreturn_api = async function (body) {
  
  try {
    const { data } = await axios.get("api/v11/salesReturns/sales-return/", {
      params:body,
    });
    return data;
  } catch (error) {
    console.log("error on Sales Return Master view  API", error);
    return error
  }
};

// Getting Sales Return details Api
export const get_salesreturn_details_api = async function (body) {
  console.log("Getting Sales Return DetailsLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL");
  try {
    const { data } = await axios.get("api/v11/salesReturns/get-sales-return-details/", {
      params:body,
    });
    return data;
  } catch (error) {
    console.log("error on  Sales Return details Api", error);
    return error
  }
};


export const edit_salesreturn_api = async function (body) {
  
  try {
    const { data } = await axios.put("api/v11/salesReturns/sales-return/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};
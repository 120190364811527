import React, { useState } from 'react';
import { Box, TextField, FormControl, FormLabel } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ViknAutoComplete from "./ViknAutoComplete";
import { useTranslation } from "react-i18next";
import moment from 'moment';

const VBDatetimepicker = (props) => {
  const [t, i18n] = useTranslation("common");

  // Initialize fromDate and toDate with the current date and time
  const [fromDate, setFromDate] = useState(props?.fromDate || moment());
  const [toDate, setToDate] = useState(props?.toDate || moment());

  const handleFromDateChange = (newValue) => {
    setFromDate(newValue);
    if (props?.fromOnChange) {
      props.fromOnChange(newValue);
    }
  };

  const handleToDateChange = (newValue) => {
    setToDate(newValue);
    if (props?.toOnChange) {
      props.toOnChange(newValue);
    }
  };

  return (
    <>
      <FormControl fullWidth>
        {!props?.notLabel && (
          <FormLabel sx={{ fontSize: "14px", mb: 1 }}>
            {props.label ? props.label : t("Date Period")}
          </FormLabel>
        )}
        {props.isDateRange && (
          <Box sx={{ mb: 1 }}>
            <ViknAutoComplete
              placeholder={"Date Range"}
              value={props.dateValue}
              disableClearable
              options={[
                { name: "Custom" },
                { name: "Today" },
                { name: "This Week" },
                { name: "This Month" },
                { name: "Last 90 Days" },
                { name: "This Year" },
                { name: "Last Year" },
              ]}
              getOptionLabel={(option) => option.name}
              onChange={(e, newValue) =>
                props.handleAutoComplete(e, newValue, "date")
              }
            />
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ mr: "4px", flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDateTimePicker
                readOnly={props?.fromReadOnly}
                value={fromDate}
                onChange={handleFromDateChange}
                format="DD-MM-YYYY HH:mm"
                renderInput={(params) => <TextField {...params} />}
                sx={{
                  "& .MuiInputBase-root": {
                    bgcolor: "black",
                    borderRadius: "6px",
                    flexDirection: "row-reverse",
                    "& .MuiInputBase-input": {
                      py: "10px",
                      fontSize: "12px",
                      color: "white",
                      "& .Mui-disabled": {
                        color: "white",
                      },
                    },
                    "& .MuiButtonBase-root ": {
                      padding: "1px",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "18px",
                      color: "white",
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ ml: "4px", flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDateTimePicker
                readOnly={props?.toReadOnly}
                value={toDate}
                onChange={handleToDateChange}
                format="DD-MM-YYYY HH:mm"
                renderInput={(params) => <TextField {...params} />}
                sx={{
                  "& .MuiInputBase-root": {
                    bgcolor: "black",
                    borderRadius: "6px",
                    flexDirection: "row-reverse",
                    "& .MuiInputBase-input": {
                      py: "10px",
                      fontSize: "12px",
                      color: "white",
                      "& .Mui-disabled": {
                        color: "white",
                      },
                    },
                    "& .MuiButtonBase-root ": {
                      padding: "1px",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "18px",
                      color: "white",
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </FormControl>
    </>
  );
};

export default VBDatetimepicker;

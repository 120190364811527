import styled from "@emotion/styled";
import { Checkbox, FormControlLabel, FormGroup, Paper } from "@mui/material";
import { blue } from '@mui/material/colors';
import { randomId } from '@mantine/hooks';
import FormLabel from '@mui/material/FormLabel';

const ViknCheckboxGroup = (props) => {
  return (
    // This component renders a group of checkboxes within a styled paper component.
    // Each checkbox is associated with a label and has an onChange handler.
    <StyledPaper elevation={0}  isBorder={props.isBorder ?? true}>
      {props.labelName && <FormLabel sx={{fontSize:'15px'}} id="demo-radio-buttons-group-label">{props.labelName}</FormLabel>}
      <FormGroup row={props.isNoWidth?true:false}>
        {props.radios.map((radio, i) => (
          <StyledFormControlLabel
            isNoWidth={props.isNoWidth??true}
            key={randomId(i)}
            control={
              <Checkbox
                checked={radio.checked} // The checked prop determines whether this checkbox is checked.
                onChange={radio.onChange} // The onChange prop is a function that will be called when the checkbox's state changes.
                size={props.size || "small"} // The size prop determines the size of the checkbox. In this case, it is small.
                disabled={radio.disabled}
                sx={{
                  color: blue[400],
                  '&.Mui-checked': {
                    color: blue[400],
                  },
                }}
              />
            }
            label={radio.label} // The label prop is the text that will be displayed next to the checkbox.
          />
        ))}
      </FormGroup>
    </StyledPaper>
  );
};

export default ViknCheckboxGroup;

const StyledPaper = styled(Paper)(({isBorder}) => ({
  paddingLeft: "9px",
  // paddingTop: "6px",
  paddingRight: "9px",
  width: "100%",
  border: isBorder ? "2px solid #F3F3F3" : "none",
  borderRadius: "6px"
}));


const StyledFormControlLabel = styled(FormControlLabel)(({isNoWidth}) => ({
  // fontSize: "14px",
  width: isNoWidth?"auto":"100%",
  "& .MuiTypography-root": {
    fontSize: "14px",
  },
}));

// ----+++  Required Props  +++----
// 1) radios: This is an array of objects, where each object represents a checkbox. Each checkbox is represented as:
//    {
//       checked: boolean, // A boolean value that determines whether the checkbox is checked.
//       label: "", // The label that will be displayed next to the checkbox.
//       onChange: function(), // This is a function that handles changes to the checkbox's state.
//    }
// isBorder={false}

export function SalesInvoiceCalc(dataState, state, DataList) {
 
  let GrandTotal = 0;
  let TotalProducts = 0;
  let TotalQty = 0;
  let Qty;



  let DataListLength = DataList.filter((i) => i.Product != null)?.length ?? 0;
  TotalProducts = DataListLength;

  //Looping dataList
  for (let index = 0; index < DataList.length; index++) {
    const item = DataList[index];
    Qty = Number(item.Qty) || 0;
    let Amt = Number(item.Amount) || 0;
    TotalQty += Qty;
    GrandTotal += Amt 

    

  }

  state.TotalProducts = TotalProducts;
  state.TotalQty = TotalQty;
  state.GrandTotal = GrandTotal;

  return state;
}

//Calculation of an line
export function SalesLineCalc(dataState, state, lineItem) {
  let Qty = Number(lineItem.Qty) || 0;
  let Rate = Number(lineItem.Rate) || 0;
  // let DiscountAmount = Number(lineItem.DiscountAmount) || 0
  // let Tax = lineItem.Tax
  // let TaxID = lineItem.TaxID
  // let SalesTax = Number(Tax.SalesTax)

  // let UnitTaxAmount = (Rate/100) * SalesTax
  // let InclusivePrice = Rate + UnitTaxAmount
  let Amount = Rate * Qty;

  // let TaxableAmount = GrossAmount - DiscountAmount
  // let TaxAmount = GrossAmount - DiscountAmount
  // TaxAmount = (TaxAmount/100) * SalesTax
  // let Amount = (GrossAmount - DiscountAmount) + TaxAmount

  // //update item
  // lineItem.TaxableAmount = TaxableAmount
  // lineItem.TaxAmount = TaxAmount
  // lineItem.InclusivePrice = InclusivePrice
  lineItem.Amount = Amount;
  // lineItem.Amount = Amount

  return lineItem;
}

//Validation
export const Sales_Validation = (dataState, state, DataList) => {
  let field = null;
  let message = null;
  let error = null;
  let indexList = [];

  let Data = DataList;
  let length = Data.length;

  let is_non_taxable_amount = false;
  let is_taxable_amount = false;

  //check is there atlease one valid row
  if (Data.length === 0) {
    error = true;
    message = "At least need one valid row";
  } else if (Data.length === 1 && !Data[0].Product) {
    error = true;
    message = "At least need one valid row";
  }

  //looping the dataList and checking all mandatory values are included
  Data.map((obj, i) => {
    if (!obj.Product) {
      if (
        i + 1 === length &&
        !obj.ProductCode &&
        !obj.BarCode &&
        !obj.Product &&
        // !obj.Description &&
        !obj.Unit &&
        // !obj.UnitList &&
        !obj.Stock &&
        !obj.Qty &&
        // !obj.FQty &&
        !obj.Rate &&
        // !obj.AvgCost &&
        !obj.Tax &&
        !obj.InclusivePrice &&
        !obj.GrossAmount &&
        // !obj.DiscPerc &&
        !obj.DiscountAmount &&
        !obj.TaxAmount &&
        !obj.Amount &&
        !obj.MRP
        // !obj.PurchasePrice &&
        // !obj.BatchCode &&
        // !obj.MinimumSalesPrice &&
        // !obj.AverageCost
      ) {
        //ignoring last line if product is not selected and changed any other data in that line
        console.log("IGNORE THE PART");
      } else {
        indexList.push(i);
      }
    } else if (!obj.ProductCode) {
      indexList.push(i);
    } else if (!obj.BarCode) {
      indexList.push(i);
    } else if (!obj.Product) {
      indexList.push(i);
    } else if (!obj.Unit) {
      indexList.push(i);
    } else if (!obj.Stock) {
      indexList.push(i);
    } else if (!obj.Qty) {
      indexList.push(i);
    } else if (!obj.Rate) {
      indexList.push(i);
    } else if (!obj.Tax) {
      indexList.push(i);
    } else if (!obj.InclusivePrice) {
      indexList.push(i);
    } else if (!obj.GrossAmount) {
      indexList.push(i);
    } else if (!obj.DiscountAmount) {
      indexList.push(i);
    } else if (!obj.TaxAmount) {
      indexList.push(i);
    } else if (!obj.Amount) {
      indexList.push(i);
    } else if (!obj.MRP) {
      indexList.push(i);
    }
  });

  //checking values of dataState
// checking values of state
if (!state.FromWarehouse && !error) {
  error = true;
  message = "Provide valid From WareHouse";
} else if (!state.FromWarehouse && !error) {
  error = true;
  message = "Provide valid From WareHouse";
} else if (!state.ToWarehouse && !error) {
  error = true;
  message = "Provide valid To WareHouse";
} else if (!state.GrandTotal && !error) {
  error = true;
  message = "Provide valid GrandTotal";
}


  return { error, message, field, indexList };
};

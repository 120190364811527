import React, { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  List,
  ListItem,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { VBSkeletonLoader, VBTableCellBody, VBTableCellFooter, VBTableCellHeader, VBTableContainer, VBTableFooter, VBTableHeader, VBTableRowNull } from '../../../../Components/ReportTable/ReportTable';
import FilterLable from '../../../../Components/Utils/FilterLable';
import SearchReport from '../../../../Components/Utils/SearchReport';
import FilterBtn from '../../../../Components/Utils/FilterBtn';
import ExportBtn from '../../../../Components/Utils/ExportBtn';
import PrintBtn from '../../../../Components/Utils/PrintBtn';
import Pagenation from '../../../../Components/Utils/Pagenation';
import { CallExpenseSummaryReport } from '../../../../Api/Reports/FinanceApi';
import moment from 'moment';
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from '../../../../Components/Utils/ViknAutoComplete';
import { date_range } from '../../../../Function/comonFunction';
import { CallBranchsList, CallUsers } from '../../../../Api/Reports/CommonReportApi';
import ViknSearchAutoComplete from '../../../../Components/Utils/ViknSearchAutoComplete';
import VBDatePiker from '../../../../Components/Utils/VBDatePiker';
import { callPurchaseInteratedReport } from '../../../../Api/Reports/PurchaseApi';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openSnackbar } from '../../../../features/SnackBarslice';
import { formatNumber } from '../../../../generalFunction';
import ReportDownload from '../../../../Components/Utils/ReportDownload';
import { UserRolePermission } from '../../../../Function/Editions';

const PurchaseIntegratedReport = () => {
  let Export_view = UserRolePermission("Purchase Integrated Report", "print_permission");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const dispatch = useDispatch();
  const [reportData, setReportData] = useState([])
  const [t, i18n] = useTranslation("common");
  const [Loading, setLoading] = useState(false);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [page_no, setPage_no] = useState(1);
  const [filterOptions, setFilterOptions] = useState({
    userList : [],
    UserObj : {customer_name : ""},
    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment(),
    },
  })

  //Handle functions

  const closeFilter = () => setOpenFilter(false);
  const handleAutoComplete = function (e, value, name) {
    if (value) {
      if (name === "date") {
        console.log(e, value);
        if (value.name === "Custom") {
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: true,
              value: value,
            },
          });
        } else {
          let date = date_range(value.name);
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: false,
              value: value,
              ToDate: date.todate,
              FromDate: date.fromdate,
            },
          });
        }
      } else {
        setFilterOptions({ ...filterOptions, [name]: value });
      }
    }
  };
  const handleDefaultFilter = async () => {
    setFilterOptions({
      ...filterOptions,
      UserObj:{customer_name:''},
      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Today" },
        FromDate: moment(),
        ToDate: moment(),
      },
    })
  }
  // API Fetch data
  const fetchReport = async function () {
    setLoading(true);
    const data = await callPurchaseInteratedReport({
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
      UserID: filterOptions.UserObj?.UserID ? filterOptions.UserObj.UserID : 0,

    },dispatch);
    console.log(data);
    if (data?.StatusCode === 6000) {
      setReportData(data);
      // setPage()
    } else if (data?.StatusCode === 6001){
      setReportData();
      dispatch(
        openSnackbar({
          open : true,
          message : data.message,
          severity : 'warning',
        })
      )
    }
    setLoading(false);
  };

  const handleFilterList = () => {
    let list = [];
    let filterdata = filterOptions
    console.log(filterdata,'>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    if (filterdata.date.FromDate &&filterdata.date.ToDate ) {
      let date = String(filterOptions.date.FromDate.format("DD-MM-YYYY") + " to " + filterOptions.date.ToDate.format("DD-MM-YYYY"))
      list.push({ name:t("Date"), value: date });
    }
    if (filterdata && filterdata.UserObj && filterdata.UserObj.customer_name !== undefined && filterdata.UserObj.customer_name !== ''){
      list.push({
        name: t('User'),
        value: filterdata.UserObj.customer_name,
        handleDelete: () => handleDeletefilter('UserObj')
      })
    }
    return list
  }

  const handleDeletefilter = (name, index) => {
    console.log(name, index);
    if (name === "routeListValue") {
      let routeListValue = [...filterOptions.routeListValue];
      routeListValue = routeListValue.filter((item, idx) => idx !== index);
      setFilterOptions((prevState) => ({
        ...prevState,
        routeListValue: routeListValue,
      }));
    } else {
      setFilterOptions((prevState) => ({ ...prevState, [name]: {} }));
    }
    // handlefilterList()
  };
  const searchReport = function () {
    fetchReport();
  };
  const filterApis = async function () {
    setIsLoadingUser(true);
    const userResponse = await CallUsers({
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      items_per_page: 10,
      page_no: 1,
      search: "",
      PartyType: "1",
    });
    let userList = [];
    if (userResponse.StatusCode === 6000) {
      userList = userResponse.data;
    }
    setFilterOptions({
      ...filterOptions,
      userList,
    });
    setIsLoadingUser(false);
  }

  const getDataForPage = () => {
    const startIndex = (page_no - 1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData.new_data?.slice(0,reportData.new_data.length -1)
    return alldata?.slice(startIndex, endIndex) || [];
  };

  useEffect(() => {
    filterApis();
  }, [])
  console.log(reportData,'hssssssssssssssssssssssssssss');
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className='left'>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t('Purchase Integrated Report')}
            </Typography>
            {/* <SearchReport /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className='right'>
            {Export_view ? 
            <ReportDownload
              download_type={"PDF"}
              invoice_type={"purchase_integrated_report"}
              ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
              FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
              UserID={ filterOptions.UserObj?.UserID ? filterOptions.UserObj.UserID : 0}
            />
            : null}
            {/* <PrintBtn /> */}
            <Pagenation 
              totalItem={reportData.new_data?.length}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
        <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
          {t("Applied Filters: ")}
        </div>
        {handleFilterList(filterOptions).map((val, i) => (
          <FilterLable
            name={val.name}
            value={val.value}
            handleDelete={val.handleDelete?val.handleDelete:null}
          />
        ))}
          
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t('Serial No')}</VBTableCellHeader>
            <VBTableCellHeader> {t('Date')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Users')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Cash Purchases')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Credit Purchases')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Bank Purchases')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Net Purchases')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Cash Return')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Debit Return')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Bank Return')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Net Return')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Actual Purchases')}</VBTableCellHeader>

          </VBTableHeader>
          {Loading ? 
          <VBSkeletonLoader /> :
          <TableBody>
          {getDataForPage().map((data, i) => (
            <TableRow>
              <VBTableCellBody>{data.index}</VBTableCellBody>
              <VBTableCellBody>{data.Date}</VBTableCellBody>
              <VBTableCellBody>{data.User}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.CashPurchases)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.CreditPurchases)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.BankPurchases)}</VBTableCellBody>             
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.NetPurchases)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.CashReturn)}</VBTableCellBody>             
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.DebitReturn)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.BankReturn)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.NetReturn)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.ActualPurchases)}</VBTableCellBody>
            </TableRow>
          ))}
          <TableRow></TableRow>
          </TableBody>
          }
          <VBTableFooter >
            <TableRow>
              <VBTableCellFooter >{t('Total')}</VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].CashPurchases) ?? 0)} </VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].CreditPurchases )?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].BankPurchases ) ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].NetPurchases )?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].CashReturn )?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].DebitReturn )?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].BankReturn) ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].NetReturn) ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber((reportData.new_data && reportData.new_data[reportData.new_data.length - 1].ActualPurchases) ?? 0)}</VBTableCellFooter>

              {/* <VBTableCellFooter align="right">{reportData[1]?.Amount}</VBTableCellFooter> */}
            </TableRow>
          </VBTableFooter>
        </VBTableContainer>
      </Paper>


      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        setPage_no = {setPage_no}
        onFilter={searchReport}
        handleDefaultFilter={()=>handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker 
                handleAutoComplete={handleAutoComplete}
                dateValue={filterOptions.date.value}
                isDateRange={true}
                fromDate={filterOptions.date.FromDate}
                toDate={filterOptions.date.ToDate}
                fromOnChange={(value) => 
                  setFilterOptions({
                    ...filterOptions,
                    date:{
                      ...filterOptions.date,
                      FromDate: value,
                      value:{ name: "Custom" },
                    },
                  })
                }
                toOnChange={(value) => 
                  setFilterOptions({
                    ...filterOptions,
                    date:{
                      ...filterOptions.date,
                      ToDate: value,
                      value:{ name: "Custom" },
                    }
                  })
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete 
                freeSolo
                placeholder = {t("User")}
                filterName = {t("User")}
                loading = {isLoadingUser}
                options = {filterOptions.userList ? filterOptions.userList : []}
                getOptionLabel={(option) => option.customer_name || ""}
                name = "UserObj"
                value={filterOptions.UserObj}
                onInputChange={(event, value, reason) => { 
                  // if (reason === "input") {
                  //   handleSearchUser(event,value);
                  // }
                  if(reason === "clear"){
                    setFilterOptions({ ...filterOptions, UserObj: {} });
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "UserObj")}
                
              />
            </ListItem>
            <Divider />
          </List>
        }
      />
    </>
  )
}

export default PurchaseIntegratedReport

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap:"22px",
  },
}));
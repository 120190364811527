import { Box, Divider, IconButton, List, ListItem, Paper, TableBody, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FilterBtn from '../../../../Components/Utils/FilterBtn'
import ExportBtn from '../../../../Components/Utils/ExportBtn';
import Pagenation from '../../../../Components/Utils/Pagenation';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import FilterLable from '../../../../Components/Utils/FilterLable';
import { VBSkeletonLoader, VBTableCellBody, VBTableCellFooter, VBTableCellHeader, VBTableContainer, VBTableFooter, VBTableHeader } from '../../../../Components/ReportTable/ReportTable';
import { formatNumber } from '../../../../generalFunction';
import ViknDrawer from '../../../../Components/ViknDrawer/ViknDrawer';
import VBDatePiker from '../../../../Components/Utils/VBDatePiker';
import ViknCheckboxGroup from '../../../../Components/Utils/ViknCheckboxGroup';
import ViknAutoComplete from '../../../../Components/Utils/ViknAutoComplete';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CallBankStatment } from '../../../../Api/Reports/AccountApi';
import { openSnackbar } from '../../../../features/SnackBarslice';
import { handleCashLedgers } from '../../../../Function/comonFunction';
import { callBankReconsilationList } from '../../../../Api/Reports/CommonReportApi';
import ReportDownload from '../../../../Components/Utils/ReportDownload';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { UserRolePermission } from '../../../../Function/Editions';

const BankStatment = () => {
  let Export_view = UserRolePermission("Bank Statement", "print_permission");
  const dispatch = useDispatch()
    const navigate = useNavigate();
  // Set State
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [page_no, setPage_no] = useState(1)
  const [t] = useTranslation("common");
  const [reportData, setReportData] = useState([]);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [progress, setProgress] = useState(0);
  const [filterOptions, setFilterOptions] = useState({
    ToDate: moment(),
    FromDate: moment(),
    bankValue: null,
  });

  const [filterData, setFilterData] = useState({
    routeList: [],
    bankList: [],
    reportList: [],
    count: 0,
    total: {
      Debit: 0,
      Credit: 0,
      Balance: 0,
    }
  })

  const [isLoading, setIsLoading] = useState(false)

  //Handle Functions

  const closeFilter = () => setOpenFilter(false);

  const onFilter = function () {
    fetchReport();
  };

  const handleDefaultFilter = function() {
    setFilterOptions({
      ToDate: moment(),
      FromDate: moment(),
    })
  }

  // API Fetch data
  const fetchReport = async function () {
    setIsLoading(true)
    // let CashLedgers = await handleCashLedgers(filterOptions)
    const data = await CallBankStatment({
      FromDate: filterOptions.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.ToDate.format("YYYY-MM-DD"),
      LedgerID: filterOptions.bankValue?.LedgerID || 0,
      items_per_page: 15,
      page_no: page_no,
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceDecimalPoint:PriceDecimalPoint,
      user_id:user_id,
    });
    console.log("fetchReport===>",data);
    if (data?.StatusCode === 6000) {
      setFilterData({
        ...filterData,
        reportList: data.data,
        count: data.count,
        total: {
          Debit: data.total.Debit,
          Credit: data.total.Credit,
          Balance: data.total.Balance,
        }
      });
    }
    setIsLoading(false)
    closeFilter()
  };

  const filterApis = async function () {
    const LedgerResponse = await callBankReconsilationList({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:PriceDecimalPoint,
      CreatedUserID:user_id,
    });
    if (LedgerResponse?.StatusCode === 6000) {
      setFilterData({
        ...filterData,
        bankList: LedgerResponse.data,
      });
    }
  };

  useEffect(() => {
    filterApis();
  }, []);

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox>
          <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon sx={{ color: "#0A9EF3"}} />
            </IconButton>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t("Bank Statment")}
            </Typography>
            {/* <SearchReport /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            {/* <ExportBtn /> */}
            {Export_view ? 
            <ReportDownload
              download_type={"PDF"}
              invoice_type={"Bank_Statement"}
              FromDate = {filterOptions.FromDate.format("YYYY-MM-DD")}
              ToDate={filterOptions.ToDate.format("YYYY-MM-DD")}
              LedgerID={filterOptions.bankValue?.LedgerID || 0}
              is_celery={true}
              setProgress={setProgress}
            />
            : null}
            {/* <PrintBtn /> */}
            {/* <Box> */}
            <Pagenation 
              totalItem={filterData.count}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
            {/* </Box> */}
          </Box>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // // height: "54px",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div  style={{fontSize:"16px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
            {t("Applied Filters: ")}
          </div>
          <FilterLable
            name={t("Date")}
            value={filterOptions.FromDate.format("DD/MM/YYYY")}
          />
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t('Serial No')}</VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher No")}</VBTableCellHeader>
            <VBTableCellHeader>{t('Date')}</VBTableCellHeader>
            <VBTableCellHeader>{t("Type")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Particular")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Notes")} </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Reference No")}</VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Debit")}</VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Credit")}</VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right"}}>{t("Balance")}</VBTableCellHeader>

          </VBTableHeader>
          {isLoading ? <VBSkeletonLoader /> : [<TableBody>
            
              {filterData.reportList.map((e, i) => ( <TableRow 
                sx={{cursor: "pointer"}}
              >
                {/* <VBTableCellBody>{data.index + 1}</VBTableCellBody> */}
                {/* <VBTableCellBody>{data.LedgerCode}</VBTableCellBody> */}
                {/* <VBTableCellBody>{data.LedgerName}</VBTableCellBody> */}
                {/* <VBTableCellBody>{data.PartyType}</VBTableCellBody> */}
                {/* <VBTableCellBody>{data.RouteName}</VBTableCellBody> */}
                {/* <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber()}</VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber()}</VBTableCellBody> */}
                <VBTableCellBody> {(page_no - 1)* appSetting.itemPerPage + i + 1}</VBTableCellBody>
                <VBTableCellBody>{e.VoucherNo} </VBTableCellBody>
                <VBTableCellBody>{e.ReConsilDate} </VBTableCellBody>
                <VBTableCellBody>{e.VoucherType} </VBTableCellBody>
                <VBTableCellBody>{e.Particulars} </VBTableCellBody>
                <VBTableCellBody>{e.Notes} </VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right"}}>{e.RefferenceBillNo} </VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(e.Receipts)}</VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(e.Payments)}</VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right"}}>{formatNumber(e.Balance)} </VBTableCellBody>

              </TableRow>))}
          <TableRow></TableRow>
          </TableBody> , 
          <VBTableFooter>
          <TableRow>
            <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
            <VBTableCellFooter></VBTableCellFooter>
            <VBTableCellFooter></VBTableCellFooter>
            <VBTableCellFooter></VBTableCellFooter>
            <VBTableCellFooter></VBTableCellFooter>
            <VBTableCellFooter></VBTableCellFooter>
            <VBTableCellFooter></VBTableCellFooter>
            <VBTableCellFooter sx={{ textAlign: "right"}}>{formatNumber(filterData.total.Debit)}</VBTableCellFooter>
            <VBTableCellFooter sx={{ textAlign: "right"}}>{formatNumber(filterData.total.Credit)}</VBTableCellFooter>
            <VBTableCellFooter sx={{ textAlign: "right"}}>{formatNumber(filterData.total.Balance)}</VBTableCellFooter>

          </TableRow>
          </VBTableFooter>
        ]}
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={onFilter}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
            <VBDatePiker
              fromDate={filterOptions.FromDate}
              toDate={filterOptions.ToDate}
              fromOnChange={(value) =>
                setFilterOptions({
                  ...filterOptions,
                  FromDate: value,
                })
              }
            />
            </ListItem>

            <Divider />

            {<ListItem>
              <ViknAutoComplete
                label={t("Bank")}
                placeholder={"Select Bank"}
                value={filterOptions.bankValue}
                options={filterData?.bankList}
                getOptionLabel={(option) => option.LedgerName}
                onChange={(e, newValue) => setFilterOptions({...filterOptions, bankValue: newValue})}
              />
            </ListItem>}

          </List>
        }
      />
    </>
  )
}

export default BankStatment

const FlexBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // width: "28%",
  }));
  
import { Navigate } from "react-router-dom";
import { store } from "../../../app/store";
// import { Suspense, lazy } from "react";
import { activateFunction } from "../../../Function/Editions";

// const TaxSummary = lazy(() => import("../../../Pages/Reports/Taxes/TaxSummary/TaxSummary"));
// const VATReturn = lazy(() => import("../../../Pages/Reports/Taxes/VATReturn/VATReturn"));
// const VATReport = lazy(() => import("../../../Pages/Reports/Taxes/VATReport/VATReport"));
// const SalesReport = lazy(() => import("../../../Pages/Reports/Taxes/SalesReport/SalesReport"));
// const PurchaseReport = lazy(() => import("../../../Pages/Reports/Taxes/PurchaseReport/PurchaseReport"));
import TaxSummary from "../../../Pages/Reports/Taxes/TaxSummary/TaxSummary";
import VATReturn from "../../../Pages/Reports/Taxes/VATReturn/VATReturn";
import VATReport from "../../../Pages/Reports/Taxes/VATReport/VATReport";
import SalesReport from "../../../Pages/Reports/Taxes/SalesReport/SalesReport";
import PurchaseReport from "../../../Pages/Reports/Taxes/PurchaseReport/PurchaseReport";

const state = store.getState();
const { EnableProductBatchWise,GST,VAT,EnableFaeraSettings } = state.generalSettingsSclice.generalSettings;


function transformString(str) {
  const words = str.split('-');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  const transformedStr = capitalizedWords.join(' ');
  return transformedStr;
}
  
  // Higher Order Component for protected routes
  const ProtectedRoute = ({ children, authCondition, path }) => {
    let isAuthCondition = authCondition;
    document.title = transformString(path) || "Vikn Books"
    return isAuthCondition ? children : <Navigate to="/dashboard" />;
  };
  
  const route = [
    { path: "tax-summery", element: <TaxSummary />, isAuth: activateFunction("TaxSummary") },
    // { path: "tax-return", element: <VATReturn />, isAuth: activateFunction("VATReturn") },
    { path: "vat-return" , element: <VATReturn />, isAuth: 
    // VAT === true && 
    activateFunction("VATReturn") === true },
    { path: "vat-report", element: <VATReport />, isAuth: 
    // VAT === true && 
    activateFunction("VATReport") === true },
    // { path: "sales-vat-report", element: <SalesReport />, isAuth: activateFunction("") },
    { path: "sales-gst-report", element: <SalesReport />, isAuth:
    // GST === true && 
    activateFunction("Sales GST Report")=== true },
    // { path: "vat-purchase-report", element: <PurchaseReport />, isAuth: activateFunction("") },
    { path: "gst-purchase-report", element: <PurchaseReport />, isAuth:
    //  GST === true &&
     activateFunction("Purchase GST Report") ===true },
  ];
  
  const taxRoute = route.map((route) => ({
    ...route,
    element:
    // (
      // <Suspense fallback={<div>Loading...</div>}>
        <ProtectedRoute path={route.path} authCondition={route.isAuth}>{route.element}</ProtectedRoute>,
      // </Suspense>
      // )

  }));
  
  export default taxRoute;
  
import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { blue } from '@mui/material/colors';
import { Paper, styled } from "@mui/material";

const ViknRadioGroup = (props) => {
  // const [value, setValue] = React.useState("female");

  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };

  return (
    // This component renders a group of radio buttons within a FormControl component.
    // Each radio button is associated with a label and has an onChange handler.
    <StyledPaper elevation={0}>

      <FormControl>
        {props.labelName && <FormLabel id="demo-controlled-radio-buttons-group">{props.labelName}</FormLabel>}
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={props.value} // The value prop determines which radio button is currently selected.
          onChange={e => props.handleChange(e, props.valueName?props.valueName:"")} // The onChange prop is a function that will be called when the radio button's state changes.
          // onChange={e => props.handleChange(e, props.valueName?props.valueName:"")} // The onChange prop is a function that will be called when the radio button's state changes.
        >
          {/* <FormControlLabel value="female" control={<Radio />} label="Female" />
        <FormControlLabel value="male" control={<Radio />} label="Male" /> */}
          {props.radios.map((item, i) => (
            <FormControlLabel
              value={item.value}
              disabled={item.disabled}
              control={<Radio sx={{
                color: blue[400],
                '&.Mui-checked': {
                  color: blue[400],
                },
              }}/>}
              label={item.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </StyledPaper>
  );
};
// Movies. react useContext

export default ViknRadioGroup;

const StyledPaper = styled(Paper)(() => ({
  paddingLeft: "9px",
  paddingRight: "9px",
  width: "100%",
  border: "2px solid #F3F3F3",
  borderRadius: "6px"
}));

// ----+++  Required Props  +++----
// 1) value: This is a variable that holds the current value.
// 2) onChange: This is a function that handles changes to the value.
// 3) radios: This is an object that defines the radio buttons. Each radio button is represented as:
//    [{
//       value: "", // The value that this radio button represents.
//       label: "", // The label that will be displayed next to the radio button.
//    }]

// <ViknRadioGroup 
//  value={value}
//  radios={[
//    {
//      value: "Purchase",
//      label: "Purchase",
//    },
//    {
//      value: "Sales",
//      label: "Sales",
//    }
//  ]}
//  handleChange={(e) => setValue(e.target.value)}
// />
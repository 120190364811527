import * as React from 'react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { TextField } from '@mui/material';

export default function NotesTextArea(props) {

  return (
    // <textarea style={{borderColor:"#aaa"}} id={props.id} name={props.name} rows="4" cols="50" placeholder='Notes (Optional)' onChange={props.onChange}>
    // {props.value}
    // </textarea>
    
    
    <StyledInput 
    fullWidth
    id="name"
    name="Notes"
    size="small"
    variant="outlined"
    value={props.value}
    placeholder={props.placeholder}
    multiline
    onChange={(e) => props.onChange(e)}
    />
  );
}


const StyledInput = styled(TextField)`
  input::-webkit-datetime-edit-fields-wrapper {
    font-size: 12px;
  }
  input::-webkit-calendar-picker-indicator {
    margin-left: 0px;
  }
  &.top-input {
    &.MuiFormControl-fullWidth {
      width: 50%;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3px;
  }
  input {
    color: #000;
    background: #fff;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    height: 100px;
    min-height: 116px;
    max-height: 116px;
    color: #000;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
    border: 2px solid #e4e4e4;
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #000;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #767676;
    opacity: 1;
    font-size: 12px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding-top: 5.5px;
    padding-bottom: 5.5px;
  }
  &.MuiFormControl-fullWidth {
    width: 100%;
  }
`;




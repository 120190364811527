import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ClearButton,
  ListButton,
  SaveButton,
  LoadingButton,
} from "../../../Components/Utils/StyledButtons";
import {
  InvoiceDateBox,
  InvoiceVoucherNoTextBox,
  InvoiceAutoComplete,
  InvoiceBarCodeTextBox,
  CustomVoucher,
} from "../../../Components/CommonComponents";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  get_VoucherNo,
  get_pricelist_list_new_api,
  get_single_pricelist_new_api,
  product_search_new_api,
  get_product_by_barcode_api,
} from "../../../Api/CommonApi/CommonApis";
import { CallWarehouses } from "../../../Api/Reports/CommonReportApi";
import { openSnackbar } from "../../../features/SnackBarslice";
import MyGrid from "./Details2";
import NotesTextArea from "../../../Components/Utils/TextArea";
import { SalesLineCalc, SalesInvoiceCalc } from "./function";
import SummaryDetails from "./SummaryDetails";
import {
  stock_order_create_api,
  stock_order_edit_api,
  stock_order_master_api,
  stock_order_prodduct_api
} from "../../../Api/Inventory/StockOrderApi";
import { Sales_Validation } from "./function";
import {debounce} from "../../../Function/comonFunction" 
import LoaderLite from "../../../Components/Utils/LoaderLite";


const StockOrder = () => {
  const [t, i18n] = useTranslation("common");

  // Initialization

  const location = useLocation();
  let uniq_id = null;
  let is_edit = null;
  is_edit = location.state?.is_edit ?? null;
  uniq_id = location.state?.uniq_id ?? null;
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  let today = new Date();
  today.setDate(today.getDate());
  let DefaultDate = today.toISOString().substr(0, 10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [IsButtonClick, setIsButtonClick] = useState(false);
  const [isLoading, setIsLoading] = useState({
    isLoadingProduct: false,
  });
  const [openCustomVoucher, setopenCustomVoucher] = useState(false);
  const [dataState, setDataState] = useState({
    Seperator: null,
    Suffix: null,
    SuffixSeparator: null,
    PreFix: null,
    InvoiceNo: null,
    VoucherNo: "",
    WarehouseList: [],
    filteredWarehouseList:[],
    ProductList: [],
    ProductCodeList: [],
    filteredFromWarehouseList:[],
    filteredToWarehouseList:[]
  });
  const [state, setState] = useState({
    Date: DefaultDate,
    FromWarehouse: null,
    ToWarehouse: null,
    Warehouse: null,
    TotalQty: 0,
    GrandTotal: 0,
    TotalProducts: 0,
    StockTransferDetails: [],
    Notes: "",
    TransferredByID: null,
    IsActive: true,
    BatchID: 1,
    MaxGrandTotal: 0,


  });

  const [defaultValue, setDefaultValue] = useState({
    FromWarehouse: null,
    Date: DefaultDate,
    ToWarehouse: null,
    DefaultWarehouseL: null,
  });
  // const { PriceDecimalPoint ,EnableProductBatchWise} = useSelector(
  //   (state) => state.generalSettingsSclice.generalSettings
  // );


  // let GeneralSettingsData = {
  //   PriceDecimalPoint,
  //   EnableProductBatchWise
  // }
     

  const { 
    PriceDecimalPoint,
     EnableBranch,
     EnableProductBatchWise,
     GST,
     VAT,
     CurrencySymbol,
     EnableBillwise,
     EnableWarehouse,
     EnableSalesManInSales,
     PriceCategory,
     EnableDeliveryDateInSales,
     EnableShippingCharge,
     
     PurchasePriceInSales,
     show_brand_InsearchSale,
     show_stock_InsearchSale,
     show_purchasePrice_InsearchSale,
     show_productcode_InsearchSale,
     } = useSelector((state) => state.generalSettingsSclice.generalSettings);
     

        let GeneralSettingsData = {
       PriceDecimalPoint,
       EnableBranch,
       EnableProductBatchWise,
       GST,
       VAT,
       CurrencySymbol,
       EnableBillwise,
       EnableWarehouse,
       EnableSalesManInSales,
       PriceCategory,
       EnableDeliveryDateInSales,
       EnableShippingCharge,
       
       PurchasePriceInSales,
       show_brand_InsearchSale,
       show_stock_InsearchSale,
       show_purchasePrice_InsearchSale,
       show_productcode_InsearchSale,
       //userorrll
      //  sales_discount_limit,
      //  sales_discount_perm,
      //  sales_save,
      //  sales_purchase_price_perm
     }

  // State creation

  const [DataList, setDataLists] = useState([
    {
      ProductCode: null,
      BarCode: "",
      Product: null,
      Unit: null,
      Qty: "",
      Amount: "",
      Rate: "",
      UnitList: [],
      unq_id:null
    },
  ]);

  const [tableSettings, setTableSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: true,
    Product: true,
    Unit: true,
    Qty: true,
    Amount: true,
    Rate: true,
    Activity: true,
  });

  const [focusSettings, setFocusSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: true,
    Product: true,
    Unit: true,
    Qty: true,
    Rate: true,
    Cost: true,
    Activity: true,
  });

  // Function For api Fetching
  const CustomVoucherSet = (e) => {
    let PreFix = dataState.PreFix;
    let Seperator = dataState.Seperator;
    let InvoiceNo = dataState.InvoiceNo;
    let SuffixSeparator = dataState.SuffixSeparator
      ? dataState.SuffixSeparator
      : "";
    let Suffix = dataState.Suffix ? dataState.Suffix : "";
    let VoucherNo =
      String(PreFix) +
      String(Seperator) +
      String(InvoiceNo) +
      String(SuffixSeparator) +
      String(Suffix);
    setDataState({ ...dataState, VoucherNo: VoucherNo });
    setopenCustomVoucher(false);
  };

  //
  //Single View api
  const Call_Stock_order_Master_View = async (payload) => {
    let data = [];
    const Response = await stock_order_master_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    //
    return data;
    //
  };
  const Call_stock_order_details = async(payload) => {
    let data = null
    const Response = await stock_order_prodduct_api(payload)
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
    
  }
  

  const fetchData = async () => {
    setProgress(100);

    const body = {
      CompanyID: CompanyID,
      CreatedUserID: user_id,
      BranchID: BranchID,
    };

    let WarehouseList = [];
    let FromWarehouse = null;
    let VoucherNo = "";
    let Seperator = "";
    let Suffix = "";
    let SuffixSeparator = "";
    let PreFix = "";
    let InvoiceNo = "";
    let ToWarehouse = null;
    let filteredWarehouseList =[]


    const WareouseRes = await CallWarehouses(body, dispatch);
    const voucherResponse = await get_VoucherNo(
      {
        CompanyID: CompanyID,
        UserID: user_id,
        BranchID: BranchID,
        VoucherType: "STO",
      },
      dispatch
    );
    let DefaultWarehouse  = []
    if (WareouseRes.StatusCode === 6000) {
      DefaultWarehouse = WareouseRes.DefaultWarehouse;
      WarehouseList = WareouseRes.data;
      FromWarehouse = WarehouseList.find(
        (i) => i.WarehouseID === DefaultWarehouse
      );



    }
   


    if (voucherResponse.StatusCode === 6000) {
      VoucherNo = voucherResponse.VoucherNo;
      Seperator = voucherResponse.Seperator;
      Suffix = voucherResponse.Suffix;
      SuffixSeparator = voucherResponse.SuffixSeparator;
      PreFix = voucherResponse.PreFix;
      InvoiceNo = voucherResponse.InvoiceNo;
      }

    //==================EDIT FUNCTIONALITY=================

    let Notes = ""
  
    if (is_edit && uniq_id) {
      try {
        const ResponseView = await Call_Stock_order_Master_View({
          PriceRounding: Number(PriceDecimalPoint || 2),
          CreatedUserID: user_id,
          CompanyID: CompanyID,
          uniq_id: uniq_id,
        });
        WarehouseList = WareouseRes.data;
        let WarehouseFromID = ResponseView.WarehouseFromID;
        let WarehouseToID = ResponseView.WarehouseToID;

        DefaultWarehouse = WarehouseList.find(
          (i) => i.WarehouseID === WarehouseFromID
        );

         Notes =  ResponseView.Notes

        FromWarehouse = DefaultWarehouse
        ToWarehouse = WarehouseList.find(
          (i) => i.WarehouseID === WarehouseToID
        );
       

        VoucherNo = ResponseView.VoucherNo;
        let TotalQty = Number(ResponseView.TotalQty)
        let noOfPages = Math.ceil(TotalQty / 100);
          let data = []
            for (let i=1;i<=noOfPages;i++){
              let DetailsReponse = await Call_stock_order_details({       
                PriceRounding: Number(PriceDecimalPoint || 2),
                CreatedUserID: user_id,
                CompanyID: CompanyID,
                uniq_id: uniq_id,
                page:i
              })
              if (DetailsReponse){
                data = [...data,...DetailsReponse]
                console.log("data is printing",data,"----------------------");
              }
            }
            setProgress(0);
            setDataLists([...data])
            console.log(DataList,"==============qewfwEGWE");




      } catch (error) {
        console.log("Error Occured");
      }
    }

    setDataState({
      ...dataState,
      WarehouseList,
      VoucherNo,
      Seperator,
      Suffix,
      SuffixSeparator,
      PreFix,
      InvoiceNo,
      filteredWarehouseList: WarehouseList.filter((warehouse) => warehouse.WarehouseID !== DefaultWarehouse),
      filteredFromWarehouseList: WarehouseList.filter(warehouse => warehouse.WarehouseID !== DefaultWarehouse),
      filteredToWarehouseList: WarehouseList.filter(warehouse => warehouse.WarehouseID !== DefaultWarehouse),
    });

    setProgress(0);


    setState({ ...state, FromWarehouse, ToWarehouse , Notes});
  };

  // Using Single Use Effect for Fetching All the api Wanted
  useEffect(() => {
    fetchData();
  }, []);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // Method For Changeing the value

  const onMasterAutoChange = async (e, v, name) => {
    if (v && name) {
      let updatedState = { ...state, [name]: v };
      let { FromWarehouse, ToWarehouse } = updatedState;

   // Check if the selected warehouse is the same for both FromWarehouse and ToWarehouse
        if (FromWarehouse?.WarehouseID === ToWarehouse?.WarehouseID) {
          if (name === "FromWarehouse") {
            updatedState.ToWarehouse = null; // Clear ToWarehouse if the same warehouse is selected
          } else {
            updatedState.FromWarehouse = null; // Clear FromWarehouse if the same warehouse is selected
          }
        }
      // Update filtered lists based on the selections
      let filteredFromWarehouseList = dataState.WarehouseList.filter(warehouse => {
        return warehouse.WarehouseID !== updatedState.ToWarehouse?.WarehouseID;
      });
      
      let filteredToWarehouseList = dataState.WarehouseList.filter(warehouse => {
        return warehouse.WarehouseID !== updatedState.FromWarehouse?.WarehouseID;
      });
  
      setState(updatedState);
      setDataState({ 
        ...dataState, 
        filteredFromWarehouseList, 
        filteredToWarehouseList 
      });
    }
  };
  
 

  const onMasterChange = (e) => {
    if (e) {
      const { name, value } = e.target;
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const onDetailsAutoChange = async (v, index, name) => {
    if (v && name  && v?.id) {
      let Data = DataList;
      let lineItem = Data[index];

      if (name === "Product" || name === "ProductCode") {
        let Product = v;
        let UnitList = await Call_PriceList_list_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          product_id: Product.id,
          type_data: "SI",
        });

        let Unit = UnitList.find((i) => i.DefaultUnit === true);
        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.FromWarehouse.WarehouseID,
        });

        let Rate = 0;
        let Qty = 1;
        let FQty = 0;
        let DiscPerc = 0;
        let DiscountAmount = 0;
        let Amount = 0;

        if (UnitDetails) {
          Rate = Number(UnitDetails.PurchasePrice);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.BarCode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
        }

        lineItem.Product = Product;
        lineItem.ProductCode = Product;
        lineItem.Description = Product.Description;
        lineItem.Stock = Product.Stock;
        lineItem.Qty = Qty;
        lineItem.FQty = FQty;
        // lineItem.Tax = Tax;
        lineItem.DiscPerc = DiscPerc;
        lineItem.DiscountAmount = DiscountAmount;
        lineItem.Amount = Amount;
        lineItem.Unit = Unit;
        lineItem.UnitList = UnitList;

        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      } else if (name === "Unit") {
        let Unit = v;

        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.FromWarehouse.WarehouseID,
        });
        let Rate = 0;

        if (UnitDetails) {
          Rate = Number(UnitDetails.PurchasePrice);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.BarCode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
        }

        lineItem.Unit = Unit;

        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem
        );

        Data[index] = calculated_lineItem;
        setDataLists([...Data]);
      } else if (name === "Tax") {
        let Tax = v;
        lineItem.Tax = Tax;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      }
    }
  };

  const RemoveLine = (index) => {
    let Data = DataList;
    let deletedData = [];

    if (index || index === 0) {
      if (index > -1 && index < Data.length) {
        if (is_edit) {
          deletedData.push(Data[index]);
        }

        Data.splice(index, 1);
      }
    }
    setState({ ...state, deletedData: deletedData });
    setDataLists([...Data]);
  };

  const AddLine = () => {
    let Data = DataList;
    let newData = {
      ProductCode: "",
      Product: null,
      Unit: null,
      Cost: "",
      Stock: "",
      Excess: "",
      Shortage: "",
      Used: "",
      Damage: "",
      Adjustment: "",
      UnitList: [],
    };
    Data.push(newData);
    setDataLists([...Data]);
  };

  const [Errors, setErrors] = useState({
    field: null,
    message: null,
    error: false,
    indexList: [],
  });

  const handleSubmit = async () => {
    setErrors({
      field: null,
      message: null,
      error: false,
      indexList: [],
    });

    let { error, message, field, indexList } = await Sales_Validation(
      dataState,
      state,
      DataList,
      GeneralSettingsData
    );

    if (error) {
      let errors = {
        error,
        message,
        field,
        indexList,
      };
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          severity: "error",
        })
      );
      setErrors({ ...state, errors });
    } else {
      let Details = [];
      DataList.map((i) => {
        if (i.Product) {
          let item = {
            unq_id:i.id,
            ProductID: i.Product.ProductID,
            ProductCode:i.Product.ProductCode,
            MaXRate: 0,
            detailID: 1,
            Qty: i.Qty,
            Rate: i.Rate,
            Amount: i.Amount,
            Stock:i.Stock,
            MaxAmount: 0,
            PriceListID: i.Unit.PriceListID,
          };
          Details.push(item);
        }
      });

      console.log();

      let payload = {
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        //=============
        Seperator: dataState.Seperator,
        Suffix: dataState.Suffix,
        SuffixSeparator: dataState.SuffixSeparator,
        PreFix: dataState.PreFix,
        InvoiceNo: dataState.InvoiceNo,
        VoucherNo: dataState.VoucherNo,
        Date: state.Date,
        Notes: state.Notes,
        TransferredByID: state.TransferredByID,
        TotalQty: state.TotalQty,
        GrandTotal: state.GrandTotal,
        IsActive: state.IsActive,
        BatchID: state.BatchID,
        MaxGrandTotal: state.MaxGrandTotal,

        //==============
        WarehouseFromID: state.FromWarehouse.WarehouseID,
        WarehouseToID: state.ToWarehouse.WarehouseID,
        StockTransferDetails: Details,
        unq_id : uniq_id,
        deleted_data:state.deletedData
      };


      let responseData = null
      if (is_edit && uniq_id){
        responseData = await stock_order_edit_api(payload)
      }
      else{
        responseData = await stock_order_create_api(payload);
      }
      

      if (responseData.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Created Successfully",
            severity: "success",
          })
        );
        clearData();
      } else if (responseData.StatusCode === 6001) {
        setIsButtonClick(false);
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "warning",
          })
        );
      } else if (responseData.StatusCode === 6002) {
        setIsButtonClick(false);
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "error",
          })
        );
      } else {
        setIsButtonClick(false);
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "error",
          })
        );
      }
    }
  };

  // Product UnitList(PriceList) list api
  const Call_PriceList_list_Function = async (payload) => {
    let data = [];
    const Response = await get_pricelist_list_new_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  //Detials of selected unit(pricelist) api
  const Call_Single_PriceList_Function = async (payload) => {
    let data = null;
    const Response = await get_single_pricelist_new_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = null;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  const onDetailsChange = async (e, index) => {
    if (e) {
      let value = e.target.value;
      let name = e.target.name;
      let Data = DataList;

      let lineItem = Data[index];
      lineItem[name] = value;
      let calculated_lineItem = await SalesLineCalc(dataState, state, lineItem);

      Data[index] = calculated_lineItem;
      setDataLists([...DataList]);
    }
  };



  const onDetailsInputChange = async (e, name) => {
    if (e && name) {
      let value = e.target.value;
      if ((name === "Product" || name === "ProductCode") && value) {
        const ProductList = await Call_ProductSearchFunction({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state?.Warehouse?.WarehouseID,
          is_product_image: false,
          length: 10,
          product_name: value,
          type: "StockTransfer"
        });
        setDataState({ ...dataState, ProductList: ProductList });
      }
    }
  };

  const debouncedOnDetailsInputChange = debounce(onDetailsInputChange, 300);

  const Call_ProductSearchFunction = async (payload) => {
    let data = [];
    const Response = await product_search_new_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  const addByBarCode = async (text) => {
    let Data = DataList;
    let index = Data.length;
    let lineItem = DataList;
    let Product = await Call_Barcode_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      BarCode: text,
      WarehouseID: state.FromWarehouse.WarehouseID
    });

    if (Product) {
      let UnitList = await Call_PriceList_list_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        product_id: Product.id,
        type_data: "SI",
      });

      let Unit = UnitList.find((i) => i.DefaultUnit === true);
      let PriceListID = Unit.PriceListID;

      let UnitDetails = await Call_Single_PriceList_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        PriceListID: PriceListID,
        PriceRounding: Number(PriceDecimalPoint || 2),
        WarehouseID: state.FromWarehouse.WarehouseID,
      });

      let Rate = 0;
      let Qty = 1;
      let FQty = 0;
      let DiscountPerc = 0;
      let DiscountAmount = 0;

      if (UnitDetails) {
        Rate = Number(UnitDetails.Rate);

        lineItem.MRP = UnitDetails.MRP;
        lineItem.Rate = Rate;
        lineItem.PurchasePrice = UnitDetails.PurchasePrice;
        lineItem.BarCode = UnitDetails.BarCode;
        lineItem.BatchCode = UnitDetails.BatchCode;
        lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
        lineItem.AverageCost = UnitDetails.AverageCost;
      }

      // let Tax = dataState.TaxCategoryData.find((i) => i.TaxID === Product.ProductTaxID)

      lineItem.Product = Product;
      lineItem.ProductCode = Product;
      lineItem.Description = Product.Description;
      lineItem.Stock = Product.Stock;
      lineItem.Qty = Qty;
      lineItem.FQty = FQty;
      // lineItem.Tax = Tax
      lineItem.DiscountPerc = DiscountPerc;
      lineItem.DiscountAmount = DiscountAmount;

      lineItem.Unit = Unit;
      lineItem.UnitList = UnitList;

      let calculated_lineItem = await SalesLineCalc(dataState, state, lineItem);

      Data.push(calculated_lineItem);

      setDataLists([...Data]);
    }
  };

  const Call_Barcode_api = async (payload) => {
    let data = null;
    const Response = await get_product_by_barcode_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = null;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  const clearData = async () => {
    if (is_edit) {
      navigate("/create-stock-order");
    }

    //new voucher number
    let voucher_datas = await get_VoucherNo({
      CompanyID: CompanyID,
      UserID: user_id,
      BranchID: BranchID,
      VoucherType: "STO",
    });

    setDataState({
      ...dataState,
      ...voucher_datas,
    });

    setState({
      ...state,
      TotalQty: 0,
      GrandTotal: 0,
      TotalProducts: 0,
      Notes: "",
      FromWarehouse: state.FromWarehouse,
      ToWarehouse: null,
      Date: DefaultDate,
    });

    setDataLists([DataList]);
  };

  useEffect(() => {
    const CalculateData = () => {
      let newState = SalesInvoiceCalc(dataState, state, DataList);
      setState({ ...newState });
    };
    CalculateData();
  }, [DataList]);


  const ChangeTableCustomization = (type, name) => {
    let value = null;

    if (type === "tableSettings") {
      value = !tableSettings[name];
      setTableSettings({ ...tableSettings, [name]: value });
    } else {
      value = !focusSettings[name];
      setFocusSettings({ ...focusSettings, [name]: value });
    }
  };

  const onMasterInputChange = async (search,name) => {
   
}

console.log(dataState,state,DataList,"-------------------------->> note is printing")
  

  return (
    <div>
      <>
        <Paper sx={{ height: "100%", width: "100%", overflow: "scroll" }}>
          {/* Header Created  */}
          <Box
            sx={{
              px: "26px",
              py: "15px",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "2px solid #F5F5F5",
            }}
          >
            <FlexBox className="left">
              <Typography
                sx={{
                  fontSize: "25px",
                  fontWeight: "500",
                  cmr: 2,
                  color: "#001746",
                }}
                variant="h2"
              >
                {is_edit ? t("Update Stock Order") : t("Create Stock Order")}
              </Typography>
            </FlexBox>

            <FlexBox className="right">
              <ClearButton
                onClick={() => {
                  clearData();
                }}
                t={t}
              />

              <ListButton
                onClick={() => {
                  navigate("/list-stock-order");
                }}
                t={t}
              />

              {IsButtonClick ? (
                <LoadingButton t={t} />
              ) : (
                <SaveButton onClick={handleSubmit} t={t} />
              )}
            </FlexBox>
          </Box>

          {/* order Section a */}

          <Box
            sx={{
              px: "26px",
              py: "15px",
              display: "flex",
              alignItems: "center",
              gap: "20px",
              borderBottom: "2px solid #F5F5F5",
              height: "150px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Invoice No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceVoucherNoTextBox
                  name={"VoucherNo"}
                  label={"Type a VoucherNo"}
                  PlaceHolder={"Invoivce Number"}
                  Value={dataState.VoucherNo}
                  openCustomVoucher={openCustomVoucher}
                  setCustomVoucher={setopenCustomVoucher}
                />
                <CustomVoucher
                open={openCustomVoucher}
                setCustomVoucher={setopenCustomVoucher}
                state={dataState}
                handleChange={handleChange}
                CustomVoucherSet={CustomVoucherSet}
              />

              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Date:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox
                  name={"Date"}
                  label={"Select a Date"}
                  Value={state.Date}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                flexShrink: "1",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Warehouse From:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name="FromWarehouse"
                  label="Select Warehouse From"
                  optionLabel="WarehouseName" // Ensure this matches the property name in your warehouse objects
                  List={dataState.filteredFromWarehouseList}
                  Value={state.FromWarehouse}
                  OnChange={onMasterAutoChange}
                  OnInputChange = {onMasterInputChange} 
                />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Warehouse To:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name="ToWarehouse"
                  label="Select Warehouse To"
                  optionLabel="WarehouseName" // Ensure this matches the property name in your warehouse objects
                  List={dataState.filteredToWarehouseList}                  Value={state.ToWarehouse}
                  OnChange={onMasterAutoChange}
                  OnInputChange = {onMasterInputChange} 
                />
              </Typography>
            </div>
          </Box>
          {/* Table Section  */}

          <Box
            sx={{
              px: "26px",
              py: "15px",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "2px solid #F5F5F5",
            }}
          >
            <MyGrid
              dataState={dataState}
              state={state}
              DataList={DataList}
              setDataLists={setDataLists}
              tableSettings={tableSettings}
              setTableSettings={setTableSettings}
              focusSettings={focusSettings}
              setFocusSettings={setFocusSettings}
              handleInputChange={onDetailsInputChange}
              handleCompleteChanges={onDetailsAutoChange}
              handleChanges={onDetailsChange}
              AddLine={AddLine}
              RemoveLine={RemoveLine}
              PriceDecimalPoint={PriceDecimalPoint}
              Errors={Errors}
              ChangeTableCustomization={ChangeTableCustomization}
              GeneralSettingsData={GeneralSettingsData}
              debouncedOnDetailsInputChange={debouncedOnDetailsInputChange}
              



            />
          </Box>

          <Box
            sx={{
              px: "26px",
              py: "15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "2px solid #F5F5F5",
            }}
          >
            <div style={{ cursor: "pointer" }} onClick={AddLine}>
              <span
                style={{
                  whiteSpace: "nowrap",
                  color: "#0A9EF3",
                  margin: 0,
                  fontSize: "13px",
                }}
              >
                + Add Line{" "}
              </span>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px", // Adjust the gap between the span and BarcodeTextBox
                height: "40px",
              }}
            >
              <span style={{ whiteSpace: "nowrap", fontSize: "13px" }}>
                Add By Barcode :{" "}
              </span>
              <InvoiceBarCodeTextBox onEnter={addByBarCode} placeholder={"Add By Barcode"}/>
            </div>
          </Box>

          <Box
            sx={{
              px: "26px",
              py: "15px",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "2px solid #F5F5F5",
            }}
          >
            <div>
              <NotesTextArea
                value={state.Notes ? state.Notes : ""}
                name={"Notes"}
                placeholder={t("Notes (Optional)")}
                onChange={onMasterChange}
              />
            </div>

            <div>
              <SummaryDetails state={state} />
            </div>
          </Box>
        </Paper>
      </>
      {progress > 0 ? (
        <LoaderLite message={"Please wait......." } fullscreen={true} />
      ) : null}
    </div>
  );
};

export default StockOrder;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "69%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));

import React from "react";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
} from "../../../../../Components/ReportTable/ReportTable";
import { TableBody, TableRow, Typography } from "@mui/material";
import { formatNumber } from "../../../../../generalFunction";

const incom = [
    {name: "Interst on Deposits", value: "150.00"},
    {name: "Descount Received", value: "250.00"},
    {name: "Commission Received", value: "100.00"},
    {name: "Bed Dept Removed", value: "50.00"},
    {name: "Inventory Gain/Surplus Stock", value: "10.00"},
]

const expenses = [
    {name: "Interst on Deposits", value: "150.00"},
    {name: "Descount Received", value: "250.00"},
    {name: "Commission Received", value: "100.00"},
    {name: "Bed Dept Removed", value: "50.00"},
    {name: "Inventory Gain/Surplus Stock", value: "10.00"},
]
const Vertical = ({ loadinig, mode }) => {
  return (
    <VBTableContainer>
      <VBTableHeader>
        <VBTableCellHeader sx={{ width: "45%" }}>PERTICULAR </VBTableCellHeader>
        <VBTableCellHeader sx={{ textAlign: "right", width: "5%" }}>
          SUBTOTAL{" "}
        </VBTableCellHeader>
        <VBTableCellHeader sx={{ width: "50%" }}>TOTAL </VBTableCellHeader>
      </VBTableHeader>
      {false ? (
        <VBSkeletonLoader />
      ) : "basic" !== mode ? (
        <TableBody>
          <TableRow>
            <VBTableCellBody sx={{}}> Sales</VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}> 5000.00</VBTableCellBody>
            <VBTableCellBody sx={{}}> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{}}> Less Returns Inward</VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}> 5000.00</VBTableCellBody>
            <VBTableCellBody sx={{}}> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{}}> Net Sales</VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}> </VBTableCellBody>
            <VBTableCellBody sx={{}}> 4500.00</VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{ fontWeight: 500}}> Less COS</VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}></VBTableCellBody>
            <VBTableCellBody sx={{}}> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{}}> Opening Inventory</VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}>
              1100.00
            </VBTableCellBody>
            <VBTableCellBody sx={{}}> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{}}> Add Purchase</VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}>
              1100.00
            </VBTableCellBody>
            <VBTableCellBody sx={{}}> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{}}>
              Add curriage inward (Purchase)
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}>
              1000.00
            </VBTableCellBody>
            <VBTableCellBody sx={{}}> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{ display: "flex", justifyContent: "space-between", alignItem: "center", width: "100%"}}> 
                <li>Shipping Charge</li>
                <Typography sx={{ fontSize: "13px", fontStyle: "italic"}} >(150.00) </Typography>
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}>
              
            </VBTableCellBody>
            <VBTableCellBody sx={{}}> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{ display: "flex", justifyContent: "space-between", alignItem: "center", width: "100%"}}> 
                <li>Loading & Unloading</li>
                <Typography sx={{ fontSize: "13px", fontStyle: "italic"}} >(100.00) </Typography>
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}>
              
            </VBTableCellBody>
            <VBTableCellBody sx={{}}> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{ }}>
              Cost of goods available for sales
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right", fontWeight: "500" }}> 1500.00</VBTableCellBody>
            <VBTableCellBody sx={{ fontWeight: "bold" }}></VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{ }}>
              Less returns outward
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right", fontWeight: "500" }}>400.00 </VBTableCellBody>
            <VBTableCellBody sx={{  }}></VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{}}>
              Less closing inventory
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right", fontWeight: "500" }}> 850.00</VBTableCellBody>
            <VBTableCellBody sx={{ }}></VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{ fontWeight: "bold" }}>
              Cost of goods solid
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}> </VBTableCellBody>
            <VBTableCellBody sx={{ fontWeight: "bold" }}>
              1500.00
            </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{ fontWeight: "bold", color: "green" }}>
              Gross profit
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}> </VBTableCellBody>
            <VBTableCellBody sx={{ fontWeight: "bold", color: "green" }}>
              3000.00
            </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{ fontWeight: "bold" }}>
              Add Other Incomes
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}> </VBTableCellBody>
            <VBTableCellBody sx={{ fontWeight: "bold" }}>
              560.00
            </VBTableCellBody>
          </TableRow>

          {incom.map((e, i) => <TableRow key={i+1}>
            <VBTableCellBody sx={{  }}>
              {e.name}
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}>{e.value} </VBTableCellBody>
            <VBTableCellBody sx={{ fontWeight: "bold" }}>
            </VBTableCellBody>
          </TableRow>)}

          <TableRow>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{ fontWeight: "bold" }}>
              Less Operating Expenses
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}> </VBTableCellBody>
            <VBTableCellBody sx={{ fontWeight: "bold" }}>
              1880.00
            </VBTableCellBody>
          </TableRow>

          {expenses.map((e, i) => <TableRow key={i+1}>
            <VBTableCellBody sx={{  }}>
              {e.name}
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}>{e.value} </VBTableCellBody>
            <VBTableCellBody sx={{ fontWeight: "bold" }}> </VBTableCellBody>
          </TableRow>)}

          <TableRow>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{ fontWeight: "bold", color: "green" }}>
              Net Profit
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}> </VBTableCellBody>
            <VBTableCellBody sx={{ fontWeight: "bold", color: "green" }}>
              1680.00
            </VBTableCellBody>
          </TableRow>
        </TableBody>
      ) : (
        <TableBody>
          <TableRow>
            <VBTableCellBody sx={{}}> Sales</VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}> </VBTableCellBody>
            <VBTableCellBody sx={{}}> 4500.00</VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{}}> Less COS</VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}> </VBTableCellBody>
            <VBTableCellBody sx={{}}> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{}}> Opening Inventory</VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}>
              1000.00
            </VBTableCellBody>
            <VBTableCellBody sx={{}}> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{}}> Add Purchase</VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}>
              1100.00
            </VBTableCellBody>
            <VBTableCellBody sx={{}}> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{}}>
              Add curriage inward (Purchase)
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}>
              1000.00
            </VBTableCellBody>
            <VBTableCellBody sx={{}}> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{}}> Less Closing Inventory</VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}>
              1000.00
            </VBTableCellBody>
            <VBTableCellBody sx={{}}> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{ fontWeight: "bold" }}>
              Cost of goods solid
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}> </VBTableCellBody>
            <VBTableCellBody sx={{ fontWeight: "bold" }}>
              1500.00
            </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{ fontWeight: "bold", color: "green" }}>
              Gross profit
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}> </VBTableCellBody>
            <VBTableCellBody sx={{ fontWeight: "bold", color: "green" }}>
              3000.00
            </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{ fontWeight: "bold" }}>
              Add Other Incomes
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}> </VBTableCellBody>
            <VBTableCellBody sx={{ fontWeight: "bold" }}>
              560.00
            </VBTableCellBody>
          </TableRow>
          <TableRow>
            <VBTableCellBody sx={{ fontWeight: "bold" }}>
              Less Operating Expenses
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}> </VBTableCellBody>
            <VBTableCellBody sx={{ fontWeight: "bold" }}>
              1880.00
            </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
            <VBTableCellBody> </VBTableCellBody>
          </TableRow>

          <TableRow>
            <VBTableCellBody sx={{ fontWeight: "bold", color: "green" }}>
              Net Profit
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: "right" }}> </VBTableCellBody>
            <VBTableCellBody sx={{ fontWeight: "bold", color: "green" }}>
              1680.00
            </VBTableCellBody>
          </TableRow>

          {/* {props.data.map((e, i) => (
        <TableRow>
          <VBTableCellBody sx={{ color: e.expence.group_name === "Total" ? 'red': "auto" }} > {e.expence.group_name}</VBTableCellBody>
          <VBTableCellBody > {e.expence.ledger_name}</VBTableCellBody>
          <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6", textAlign: "right" }}> {formatNumber(e.expence.balance)}</VBTableCellBody>

          <VBTableCellBody sx={{ color: e.expence.group_name === "Total" ? 'red': "auto" }}> {e.income.group_name}</VBTableCellBody>
          <VBTableCellBody > {e.income.ledger_name}</VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}> {formatNumber(e.income.balance)}</VBTableCellBody>
        </TableRow>
      ))} */}

          <TableRow></TableRow>
        </TableBody>
      )}
    </VBTableContainer>
  );
};

export default Vertical;
